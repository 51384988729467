import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { UsersListComponent } from './components/users-list/users-list.component';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { UserAddComponent } from './components/user-add/user-add.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { AuthoritiesListComponent } from './components/authorities/authorities-list/authorities-list.component';
import { AuthorityAddComponent } from './components/authorities/authority-add/authority-add.component';
import { RoleAddComponent } from './components/roles/role-add/role-add.component';
import { RolesListComponent } from './components/roles/roles-list/roles-list.component';
import { RoleEditComponent } from './components/roles/role-edit/role-edit.component';


@NgModule({
  declarations: [
    UsersListComponent,
    UserAddComponent,
    UserEditComponent,
    AuthoritiesListComponent,
    AuthorityAddComponent,
    RoleAddComponent,
    RolesListComponent,
    RoleEditComponent
  ],
  imports: [
    CommonModule,
    CommonsModule,
    UsersRoutingModule,
    ReactiveFormsModule
  ]
})
export class UsersModule { }
