import { Component, Input, OnInit } from '@angular/core';
import { DebtStageResponse, DebtStageStatus, DebtStageType, LoanDebtStepManualStatus } from '../../modules/debt-collection-stages/api/debt-stage';
import { PlPiDebtCollectionStagesService } from '../../modules/debt-collection-stages/services/pl-pi-debt-collection-stages.service';
import { FilterOperation, FullLoan, SearchQueryBuilder } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';
import { LoanSidebarComponent } from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogueComponent } from '@backoffice-monorepo/commons';
import { PlPiLoanSetDcStageComponent } from '../pl-pi-loan-set-dc-stage/pl-pi-loan-set-dc-stage.component';
import { PlPiLoanMarkFraudComponent } from '../pl-pi-loan-mark-fraud/pl-pi-loan-mark-fraud.component';
import { PlPiLoanManualDebtStatusComponent } from '../pl-pi-loan-manual-debt-status/pl-pi-loan-manual-debt-status.component';
import { DcBuyBackComponent } from '../../modules/debt-collection-stages/components/dc-buy-back/dc-buy-back.component';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-stages',
  templateUrl: './pl-pi-loan-stages.component.html',
  styleUrls: ['./pl-pi-loan-stages.component.scss']
})
export class PlPiLoanStagesComponent implements LoanSidebarComponent, OnInit {

  @Input()
  loan: FullLoan;
  loanStages: DebtStageResponse[];
  debtStageStatus = DebtStageStatus;
  loanDebtStepManualStatus = LoanDebtStepManualStatus;

  constructor(
    private debtCollectionStagesService: PlPiDebtCollectionStagesService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'loanId',
        operation: FilterOperation.EQUALS,
        values: [this.loan.id.toString()]
      })
      .build()

    return this.debtCollectionStagesService.find(searchQuery).pipe(
      take(1)
    ).subscribe((loanStages) => {
      this.loanStages = loanStages.results;
    })
  }

  trackById(index, item){
    return item.id;
  }

  requestPresale() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Request presale";
    modalRef.componentInstance.content = "Are you sure you want to request presale for this loan?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.debtCollectionStagesService.addLoanForPresale(this.loan.id).subscribe(() => {
          this.refresh();
        });
      }, () => {}
    );
  }

  closeAnyActiveLoanStage() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Close any active loan stage";
    modalRef.componentInstance.content = "Are you sure you want to close active stage?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.debtCollectionStagesService.closeAnyActiveLoanStage(this.loan.id).subscribe(() => {
          this.refresh();
        });
      }, () => {}
    );
  }

  closeLoanDcStage(stageType: DebtStageType) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Close loan stage " + stageType;
    modalRef.componentInstance.content = "Are you sure you want to close this stage?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.debtCollectionStagesService.closeLoanDcStage(this.loan.id, stageType).subscribe(() => {
          this.refresh();
        });
      }, () => {}
    );
  }


  setLoanDcStage() {
    const modalRef = this.modalService.open(PlPiLoanSetDcStageComponent);
    modalRef.componentInstance.loanId = this.loan.id;
    modalRef.result.then(
      () => {
        this.refresh();
      }, () => {}
    );
  }

  markAsFraud() {
    const modalRef = this.modalService.open(PlPiLoanMarkFraudComponent);
    modalRef.componentInstance.loanId = this.loan.id;
    modalRef.result.then(
      () => {
        this.refresh();
      }, () => {}
    );
  }

  manualStatus(mode: LoanDebtStepManualStatus) {
    const modalRef = this.modalService.open(PlPiLoanManualDebtStatusComponent);
    modalRef.componentInstance.loanId = this.loan.id;
    modalRef.componentInstance.mode = mode;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.refresh();
        }
      }, () => {}
    );
  }

  buyBack() {
    const modalRef = this.modalService.open(DcBuyBackComponent);
    modalRef.componentInstance.loanId = this.loan.id;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.refresh();
        }
      }, () => {}
    );
  }
}
