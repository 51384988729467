import { Component, OnInit } from '@angular/core';
import {
  AlertsService,
  BasicComponent,
  ClientSidebarComponent,
  ConfirmDialogueComponent, draggableModalOptions,
  TwinoDateAdapterService
} from '@backoffice-monorepo/commons';
import { FilterOperation, FullClient, SearchQueryBuilder, SearchResponse, Task } from '@twino/backoffice-api';
import { NgbDateAdapter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientValueAddedService, ClientValueAddedServiceStatus } from '../../modules/vas/api/vas-definitions';
import { PlPiVasService } from '../../modules/vas/services/pl-pi-vas.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-vas',
  templateUrl: './pl-pi-client-vas.component.html',
  styleUrls: ['./pl-pi-client-vas.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class PlPiClientVasComponent extends BasicComponent implements ClientSidebarComponent, OnInit {

  client: FullClient;
  searchResults: SearchResponse<ClientValueAddedService> | never;
  newPage = 1;
  itemsPerPage = 20;
  protected readonly ClientValueAddedServiceStatus = ClientValueAddedServiceStatus;

  constructor(
    private vasService: PlPiVasService,
    private alertService: AlertsService,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [this.client.savedId.toString()]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()

    return this.vasService.listClientVAS(searchQuery).pipe(
      take(1)
    ).subscribe((vases) => {
      this.searchResults = vases;
    })
  }

  trackById(index, item: Task) {
    return item.id;
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }

  renounceVAS(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent , draggableModalOptions);
    modalRef.componentInstance.header = `Renounce VAS`;
    modalRef.componentInstance.content = `Are you sure you want to renounce VAS #${id}?`;
    modalRef.result.then(
      () => {
        this.vasService.renounceVAS(id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.alertService.notifySuccess(`VAS renounced`);
          this.refresh();
        });
      }, () => {}
    );
  }
}
