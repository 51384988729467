<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client Documents</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>ID</th>
          <th>Type</th>
          <th>Verification status</th>
          <th class="w-25">Description</th>
          <th>Content type</th>
          <th>Source ID</th>
          <th>Source type</th>
          <th>Created</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of documents" (dblclick)="editClientDocument(item)">
          <td></td>
          <td>{{ item.id }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.verificationStatus }}</td>
          <td class="w-25">{{ item.description }}</td>
          <td>{{ item.contentType }}</td>
          <td>{{ item.sourceId }}</td>
          <td>{{ item.sourceType }}</td>
          <td>{{ item.created }}</td>
          <td>
            <button type="button" class="btn btn-outline-primary btn-sm"  *ngIf="showViewDocumentButton(item.contentType)" (click)="viewClientDocument(item.id)">View</button>
          </td>
          <td>
            <a type="button" class="btn btn-outline-primary btn-sm ml-1" href="{{clientDocumentUrl}}{{ item.id }}" target="_blank">Download</a>
          </td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>
