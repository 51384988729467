export class Term {
  billingCycle: TermItem[]
  loanLength: TermItem
}

export class TermItem {
  value: number
  unit: TermUnit
}

export type TermUnit =
  'months'
  | 'days'
  | 'weeks'

