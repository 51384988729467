<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Number" type="string" property="number"></tr>
  <tr filter-element title="First name" type="string" property="firstName"></tr>
  <tr filter-element title="Last name" type="string" property="lastName"></tr>
  <tr filter-element title="Mobile phone" type="string" property="mobilePhone"></tr>
  <tr filter-element title="Email" type="string" property="email"></tr>
  <tr filter-element title="PersonalId" type="string" property="personalId"></tr>
  </tbody>

  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">Id</th>
      <th sortable="number" (sort)="onSort($event)">Number</th>
      <th sortable="firstName" (sort)="onSort($event)">First name</th>
      <th sortable="lastName" (sort)="onSort($event)">Last name</th>
      <th sortable="mobilePhone" (sort)="onSort($event)">Mobile phone</th>
      <th sortable="email" (sort)="onSort($event)">Email</th>
      <th sortable="personalId" (sort)="onSort($event)">PersonalId</th>
      <th>Finished details step</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let client of response?.results; trackBy: trackById" (dblclick)="navigateTo(client.id)">
      <td></td>
      <td>{{client.id}}</td>
      <td>{{client.number}}</td>
      <td>{{client.firstName}}</td>
      <td>{{client.lastName}}</td>
      <td>{{client.mobilePhone}}</td>
      <td>{{client.email}}</td>
      <td>{{client.personalId}}</td>
      <td>{{client.finishedDetailsStep}}</td>
    </tr>
    </tbody>
  </table>
</server-table>
