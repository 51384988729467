import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  EnumResponse,
  PaymentDistribution,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentDistributionService extends BaseService implements SearchQueryAware<PaymentDistribution> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<PaymentDistribution>> {
    return this.post(`/api/payments/distributions`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/payments/distributions/enum-values/${enumClass}`);
  }

  voidPaymentDistribution(id: number) {
    return this.post(`/api/payments/distributions/${id}/void`, {});
  }

  processPaymentDistribution(id: number) {
    return this.post(`/api/payments/distributions/${id}/process`, {});
  }

  deletePaymentDistribution(id: number) {
    return this.post(`/api/payments/distributions/${id}/delete`, {});
  }
}
