import { Address } from './address';

export interface FullClient {
  id: number
  number: string
  mobilePhone: string
  email?: string
  savedId: number
  title?: string
  firstName?: string
  lastName?: string
  fullName?: string
  dateOfBirth?: Date
  address: Address
  updated?: Date
  finishedDetailsStep?: FinishedDetailsStep
  created?: Date
  personalId?: string
  gender?: Gender
  creditLimit?: string
  locale: string
  status: string
  identifiedBy?: string
  userId: number
  lenderIds: string
}

export class UpdateClientPersonalInformationRequest {
  title?: string
  firstName?: string
  lastName?: string
  gender?: Gender
}

export class UpdateClientPersonalIdAndBirthdateRequest {
  dateOfBirth?: Date
  personalId?: string
}

export class UpdateClientMobilePhoneRequest {
  mobilePhone: string
}

export class UpdateClientEmailRequest {
  email?: string
}

export type FinishedDetailsStep =
   0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9;

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export const UserPermissionToTabList: Record<string, number> = {
  'admin.all': 1,
  'client.edit-personal-information': 1,
  'client.edit-personal-id-birthdate': 2,
  'client.edit-mobile-number': 3,
  'client.edit-email': 4
}
