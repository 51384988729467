import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent } from '@backoffice-monorepo/commons';
import { take, takeUntil } from 'rxjs/operators';
import { PlPiOfferService } from '../../services/pl-pi-offer.service';
import { BillingDetail, Offer } from '@twino/backoffice-api';
import { AcceptOfferConfirmationRequest, InitiateAcceptOfferWithConfirmationRequest, OfferValueAddedServiceResponse } from '../../api/pl-pi-offer';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'backoffice-monorepo-pl-pi-accept-offer-confirm',
  templateUrl: './pl-pi-accept-offer-vas.component.html',
  styleUrls: ['./pl-pi-accept-offer-vas.component.scss']
})
export class PlPiAcceptOfferVasComponent extends BasicComponent implements OnInit {

  @Input() offer: Offer;
  @Input() grantedOfferId: number;
  @Input() selectedAmount: number;

  content: string;
  class = "alert-danger";
  icon = "exclamation-octagon-fill";
  billingDetails: BillingDetail;
  offerVases: OfferValueAddedServiceResponse[];
  vasIds: number[] = [];
  showConfirmationCodeInput = false;
  acceptOfferForm = this.formBuilder.group({
    confirmationCode: [
      '',
      [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)
      ]
    ]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private offerService: PlPiOfferService,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getOfferVases();
  }

  submit() {
    if(!this.showConfirmationCodeInput) {
      const request: InitiateAcceptOfferWithConfirmationRequest = {
        currentGrantedOfferId: this.grantedOfferId,
        selectedAmount: this.selectedAmount
      };
      this.offerService.initiateAcceptOfferWithOtpConfirmation(request)
        .pipe(
          takeUntil(this.$destroy)
        )
        .subscribe(response => {
          this.grantedOfferId = response.newOfferId;
          this.showConfirmationCodeInput = true;
          this.acceptOfferForm.get('confirmationCode').enable();
        });
    } else {
      const request: AcceptOfferConfirmationRequest = {
        confirmationCode: this.acceptOfferForm.get('confirmationCode').value,
        initialTransferAmount: 0,
        selectedVasIds: this.vasIds
      }
      this.offerService.confirmAcceptOfferOtp(this.grantedOfferId, request)
        .pipe(
          takeUntil(this.$destroy)
        )
        .subscribe(() => {
          this.alertService.notifySuccess(`Offer accepted`);
          this.activeModal.close(true);
        })
    }
  }


  getOfferVases() {
    this.offerService.getOfferVases(this.grantedOfferId).pipe(
      take(1)
    ).subscribe(response => {
      this.offerVases = response;
      this.vasIds = this.offerVases.map(x => x.id);
    })
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
