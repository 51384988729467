import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoansRoutingModule } from './loans-routing.module';
import { LoansListComponent } from './components/loans-list/loans-list.component';
import { LoanComponent } from './components/loan/loan.component';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbDatepickerModule, NgbNavModule, NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LoanAgreementsComponent } from './components/loan-agreements/loan-agreements.component';
import { LoanPaymentsComponent } from './components/loan-payments/loan-payments.component';
import { LoanPaymentDistributionsComponent } from './components/loan-payment-distributions/loan-payment-distributions.component';
import { LoanTransactionsComponent } from './components/loan-transactions/loan-transactions.component';
import { LoanActivityOverviewComponent } from './components/loan-activity-overview/loan-activity-overview.component';
import { DynamicModule } from 'ng-dynamic-component';
import { LoanAppliedDiscountsComponent } from './components/loan-applied-discounts/loan-applied-discounts.component';
import { LoanRepaymentProposalAddComponent } from './components/loan-repayment-proposal-add/loan-repayment-proposal-add.component';
import { LoanRepaymentProposalsComponent } from './components/loan-repayment-proposals/loan-repayment-proposals.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoanWriteOffComponent } from './components/loan-write-off/loan-write-off.component';
import { LoanDebtComponent } from './components/loan-debt/loan-debt.component';
import { LoanCommissionsViewComponent } from './components/loan-commissions-view/loan-commissions-view.component';
import { LoanScheduleItemsComponent } from './components/loan-schedule-items/loan-schedule-items.component';
import { LoanFeesViewComponent } from './components/loan-fees-view/loan-fees-view.component';


@NgModule({
  declarations: [
    LoansListComponent,
    LoanComponent,
    LoanAgreementsComponent,
    LoanPaymentsComponent,
    LoanPaymentDistributionsComponent,
    LoanTransactionsComponent,
    LoanActivityOverviewComponent,
    LoanAppliedDiscountsComponent,
    LoanRepaymentProposalAddComponent,
    LoanRepaymentProposalsComponent,
    LoanWriteOffComponent,
    LoanDebtComponent,
    LoanCommissionsViewComponent,
    LoanScheduleItemsComponent,
    LoanFeesViewComponent
  ],
	imports: [
		CommonModule,
		LoansRoutingModule,
		CommonsModule,
		NgbNavModule,
		TranslateModule,
		DynamicModule,
		NgbPopoverModule,
		ReactiveFormsModule,
		NgbDatepickerModule,
		DragDropModule,
		NgbPaginationModule
	]
})
export class LoansModule {}
