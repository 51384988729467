import { Component, Input, OnInit } from '@angular/core';
import { AmlService } from '@backoffice-monorepo/commons';
import { AmlClientDataItem, AmlClientResponse, FullClient } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-client-aml-scoring',
  templateUrl: './client-aml-scoring.component.html',
  styleUrls: ['./client-aml-scoring.component.css']
})
export class ClientAmlScoringComponent implements OnInit {

  @Input() client: FullClient;
  amlInfo: AmlClientResponse | null;
  amlClientData: AmlClientDataItem[] = [];

  constructor(
    private amlService: AmlService,
  ) { }

  ngOnInit(): void {
    forkJoin({
      amlInformation: this.amlService.getClientAmlInformation(this.client.savedId).pipe(take(1)),
      amlClientData: this.amlService.getClientData(this.client.savedId).pipe(take(1))
    }).subscribe(({amlInformation, amlClientData}) => {
      this.amlInfo = amlInformation;
      this.amlClientData = amlClientData;
    });
  }

  trackById(index, item) {
    return item.id;
  }
}
