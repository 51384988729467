import { Component, Inject, OnInit, Type } from '@angular/core';
import {
  AlertsService,
  CLIENT,
  ClientService,
  CountryViewComponent,
  DATE,
  ID, LOAN,
  LoanService,
  NamedComponent,
  NUMBER,
  STRING,
  TaskRelatedEntitiesComponentResolver,
  TasksService,
  TransformInstructions,
  TransformInstructionsBuilder
} from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { FullClient, FullLoan, Task, TaskResolution, TaskStatus } from '@backoffice-monorepo/api';
import { TasksCommonService } from '../../services/tasks-common.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent extends NamedComponent implements OnInit {

  id: number;
  task: Task | null;
  client: FullClient | null;
  loan: FullLoan | null;
  showAcceptButton: boolean;
  showOwnerButtons: boolean;
  taskRelatedEntitiesComponentType: Type<CountryViewComponent>;
  taskComment: string | null;

  taskTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Task ID', ID]],
      ['taskStatus', ['Status', STRING]],
      ['topic', ['Topic', STRING]],
      ['taskGroupName', ['Task group', STRING]],
      ['created', ['Created', DATE]],
      ['assignedUser', ['Assignee', STRING]],
      ['assignTime', ['Assign time', STRING]],
      ['nextProcessTime', ['Next process time', DATE]],
      ['attempts', ['Attempts', DATE]],
      ['closeTimestamp', ['Close date', DATE]],
      ['resolution', ['Resolution', STRING]],
      ['', ['Comment', () => `${this.taskComment}`.asString()]],
    ]
  );

  clientTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['savedId', ['Id', CLIENT]],
      ['number', ['Client Number', STRING]],
      ['firstName', ['First Name', STRING]],
      ['lastName', ['Last Name', STRING]],
      ['personalId', ['Personal ID', STRING]],
      ['mobilePhone', ['Mobile Phone', STRING]],
      ['email', ['Email', STRING]],
      ['age', ['Age', STRING]],
      ['dateOfBirth', ['Date Of Birth', DATE]],
      ['gender', ['Gender', STRING]],
      ['creditLimit', ['Credit Limit', STRING]],
      ['finishedDetailsStep', ['Finished Details Step', NUMBER]],
      ['baokimVirtualAccount', ['Baokim Virtual Account', STRING]]
    ]
  );

  loanTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Id', LOAN]],
      ['loanNumber', ['Number', STRING]],
      ['status', ['Status', STRING]],
      ['statusDetail', ['Status Detail', STRING]],
      ['mainStartDate', ['Main Start Date', () => this.loan.mainAgreement.startDate.toString().asString()]],
      ['dueDate', ['Due Date', () => this.loan.currentAgreement.dueDate.toString().asString()]],
      ['dpd', ['Days Past Due', NUMBER]],
      ['currentDebtStep', ['Current Debt Step', NUMBER]]
    ]
  );

  constructor(
    activatedRoute: ActivatedRoute,
    private tasksService: TasksService,
    private loanService: LoanService,
    private clientService: ClientService,
    private tasksCommonService: TasksCommonService,
    private alertService: AlertsService,
    @Inject('TaskRelatedEntitiesComponentResolver') taskRelatedEntitiesComponentResolver: TaskRelatedEntitiesComponentResolver
  ) {
    super(activatedRoute);
    this.taskRelatedEntitiesComponentType = taskRelatedEntitiesComponentResolver.resolve();
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  getName(): string {
    return `Task ${this.id || ''}`;
  }

  refresh() {
    this.tasksService.getTask(this.id).pipe(
      take(1)
    ).subscribe(task => {
      this.task = task;
      this.taskComment = this.commentTransform();
      this.showOwnerButtons = this.isMyOpenTask();
      this.showAcceptButton = !(this.tasksCommonService.isMyTask(this.task) || this.tasksCommonService.isClosedTask(this.task));
      this.clientService.getClient(task.clientId).pipe(
        take(1)
      ).subscribe(client => {
        this.client = client;
      })
      if (task.loanId) {
        this.loanService.getLoan(task.loanId).pipe(
          take(1)
        ).subscribe(loan => {
          this.loan = loan;
        })
      }
    })
  }

  isMyOpenTask(): boolean {
    return this.tasksCommonService.isMyTask(this.task) && this.task.taskStatus === "OPEN";
  }

  acceptTask() {
    this.tasksCommonService.acceptTask().then((result) => {
      if (result === true) {
        this.tasksService.assignTask(this.task.id, true).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.refresh();
        })
      }
    }, () => {});
  }

  closeTaskModal() {
    this.tasksCommonService.closeTask(this.task).then(() => {
      this.alertService.notifySuccess("Task has been closed");
      this.refresh();
    });
  }

  postponeModal() {
    this.tasksCommonService.postpone(this.task).then(() => {
      this.alertService.notifySuccess("Task has been postponed");
      this.refresh();
    });
  }

  releaseToGroup() {
    this.tasksService.releaseToGroup(this.task.id).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess("Task has been released to group");
      this.refresh();
    });
  }

  commentTransform(): string {
    return this.task.taskStatus === TaskStatus.CLOSED ? this.hasPromiseDate(JSON.parse(this.task.inputData)?.comment) : '';
  }

  hasPromiseDate(taskComment: string | null): string {
    const taskPromiseDate = "Promise date: " + JSON.parse(this.task.inputData)?.promiseDate;
    return this.task.resolution === TaskResolution.PROMISE_TO_PAY ? taskPromiseDate : taskComment;
  }
}
