import { SafeHtml } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';

export type TransformResultType = 'string' | 'html'

export class PartialTransformResult {
  value: string | SafeHtml | Observable<string | SafeHtml>
  type: TransformResultType

  constructor(value: string | SafeHtml | Observable<string | SafeHtml>, type: TransformResultType) {
    this.value = value;
    this.type = type;
  }

  static fromStringObservable(observable: Observable<string | null>) {
    return new PartialTransformResult(observable, 'string')
  }

  static fromHtmlObservable(observable: Observable<string | null>) {
    return new PartialTransformResult(observable, 'html')
  }

  toTransformResult(fieldName: string) {
    return new TransformResult(fieldName, this.value, this.type);
  }
}

export const fromStringObservable = PartialTransformResult.fromStringObservable
export const fromHtmlObservable = PartialTransformResult.fromHtmlObservable

export class TransformResult {
  fieldName: string
  value: Observable<string | SafeHtml>
  type: TransformResultType

  constructor(fieldName: string, value: string | SafeHtml | Observable<string | SafeHtml>, type: TransformResultType) {
    this.fieldName = fieldName;
    this.value = value instanceof Observable ? value : of(value);
    this.type = type;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Transformation = (value: any) => PartialTransformResult

export type TransformInstruction = [string, Transformation]
export type TransformInstructions = Map<string, TransformInstruction>

export class TransformInstructionsBuilder {
  static build(values: [string, TransformInstruction][]): TransformInstructions {
    const instructions: TransformInstructions = new Map<string, TransformInstruction>();
    values.forEach(([key, instruction]) => {
      instructions.set(key, instruction);
    });
    return instructions;
  }
}

declare global {
  interface String {
    asHtml(): PartialTransformResult;

    asString(): PartialTransformResult;
  }
}

String.prototype.asHtml = function () {
  return new PartialTransformResult(this, 'html')
}

String.prototype.asString = function () {
  return new PartialTransformResult(this, 'string')
}
