<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [header]="toast.header"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (dblclick)="toastService.remove(toast)"
  (hide)="toastService.remove(toast)"
>
  <button type="button" class="ml-2 mb-2 close" data-dismiss="toast" aria-label="Close"  (click)="toastService.remove(toast)">
    <span aria-hidden="true">&times;</span>
  </button>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
