import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Task } from '@twino/backoffice-api';
import {
  AlertsService,
  BasicComponent,
  draggableModalOptionsLarge,
  TasksService,
  TaskViewComponent,
  TaskViewComponentResolver,
  TaskViewDirective
} from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TasksCommonService } from '../../services/tasks-common.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { TaskEmailSendComponent } from '../task-email-send/task-email-send.component';

@Component({
  selector: 'view-dashboard-task',
  templateUrl: './view-dashboard-task.component.html',
  styleUrls: ['./view-dashboard-task.component.scss']
})
export class ViewDashboardTaskComponent extends BasicComponent implements OnInit {

  @Input()
  task: Task;

  @Output()
  release = new EventEmitter<boolean>();

  @ViewChild(TaskViewDirective, {static: true})
  taskView!: TaskViewDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private alertService: AlertsService,
    private modalService: NgbModal,
    private tasksService: TasksService,
    private taskViewComponentResolver: TaskViewComponentResolver,
    private tasksCommonService: TasksCommonService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadTask();
  }

  loadTask() {
    const componentType = this.taskViewComponentResolver.resolve(this.task?.topic);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.taskView.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<TaskViewComponent>(componentFactory);
    componentRef.instance.task = this.task;
  }

  releaseToGroup() {
    this.tasksService.releaseToGroup(this.task.id).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess("Task has been released to group");
      this.release.emit(true);
    });
  }

  closeTaskModal() {
    this.tasksCommonService.closeTask(this.task).then(() => {
      this.alertService.notifySuccess("Task has been closed");
      this.release.emit(true);
    });
  }

  postponeModal() {
    this.tasksCommonService.postpone(this.task).then(() => {
      this.alertService.notifySuccess("Task has been postponed");
      this.release.emit(true);
    });
  }

  replyToEmail() {
    //this.router.navigate([`/tasks/${this.task.id}/email/${this.task.relatedEntities['communicationId']}`]);
    const modalRef = this.modalService.open(TaskEmailSendComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.task = this.task;
  };

}
