<div class="container-fluid row pt-1 pb-2">
  <p class="ml-3">
    <b>Repayment proposals</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Amount</th>
          <th>From date</th>
          <th>To date</th>
          <th>Is enabled</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of repaymentProposals;  trackBy: trackById">
          <td></td>
          <td>{{ item.amount }}</td>
          <td>{{ item.fromDate }}</td>
          <td>{{ item.toDate }}</td>
          <td>{{ item.isEnabled }}</td>
          <td>
            <button type="button"
                    title="Redirect transfer"
                    class="btn btn-success btn-xs btn-sm"
                    *ngIf="item.isEnabled === false"
                    (click)="enable(item.id)"
                    appRequirePermission="admin.all">
              Enable
            </button>
            <button type="button"
                    title="Redirect transfer"
                    class="btn btn-warning btn-xs btn-sm"
                    *ngIf="item.isEnabled === true"
                    (click)="disable(item.id)"
                    appRequirePermission="admin.all">
              Disable
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>

