<div class="modal-header" [ngClass]="class">
  <h4 class="modal-title">
    <bi [name]="icon"></bi>&nbsp;
    Accept offer without Fastcash
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close(false)">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Are you sure you want to accept this offer with VAS?</p>
  <ul class="list-group mt-2">
    <li class="list-group-item" *ngFor="let vas of offerVases; let i = index; trackBy: trackById">
      VAS: {{vas.amount}}; days: {{vas.daysCount}}; {{vas.name}}
    </li>
  </ul>
  <form [formGroup]="acceptOfferForm" *ngIf="showConfirmationCodeInput" class="mt-2">
    <div class="form-group row">
      <label class="col-sm-1" for="code">Code</label>
      <div class="col-sm-11">
        <input class="form-control" id="code" formControlName="confirmationCode">
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" autofocus class="btn btn-outline-secondary" (click)="activeModal.close(false)">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="submit()" [disabled]="acceptOfferForm.invalid && showConfirmationCodeInput">Confirm</button>
</div>

