<form [formGroup]="riskEvaluationConfigEditForm" (ngSubmit)="submitForm()">
<div class="container-fluid pt-2 col-sm-8">
  <div class="card">
    <div class="card-header">
      #{{stepId}} {{riskEvaluationConfiguration?.name}}
      <span class="float-right">
          <button type="button"
                  appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                  class="btn btn-success mr-1"
                  (click)="addRow()">
            Add rule
          </button>
          <button type="submit"
                  appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                  class="btn btn-outline-danger"
                  [disabled]="!riskEvaluationConfigEditForm.valid">
            Save
          </button>
        </span>
    </div>
      <div class="card-body" formArrayName="cardRows" cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="card mt-2" *ngFor="let group of getFormControls.controls; let i=index" [formGroupName]="i" cdkDrag>
          <div class="card-header">
            <div [ngSwitch]="group.value.isEditableInput === true">
              <div *ngSwitchCase="false" cdkDragHandle>
                <button type="button" class="btn btn-link" (click)="edit(group, 'isEditableInput')">{{group.value.ruleName}}</button>
                <button class="btn btn-outline-danger btn-sm float-right" (click)="deleteRow(i)">Delete</button>
              </div>
              <div *ngSwitchCase="true" class="row" cdkDragHandle [cdkDragHandleDisabled]="true">
                <input type="text" class="form-control form-control-sm col-sm-6 ml-3" id="ruleName" formControlName="ruleName" name="ruleName">
                <button class="btn btn-outline-primary btn-sm float-left ml-2" (click)="close(group, 'isEditableInput')">Close</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="container-fluid row">
              <div class="col-md-4">
                <div class="form-group row">
                  <select class="form-control form-control-sm" id="className" formControlName="className" name="className">
                    <option *ngFor="let key of ruleTypes" [ngValue]="key">{{key}}</option>
                  </select>
                </div>
                <div class="form-group row form-check">
                  <input type="checkbox"
                         class="form-check-input"
                         id='isTestMode_{{i}}'
                         formControlName="isTestMode"
                         name="isTestMode">
                  <label for="isTestMode_{{i}}" class="form-check-label">Test Mode</label>
                </div>
                <div class="form-group row form-check">
                  <input type="checkbox"
                         class="form-check-input"
                         id="isSkipped_{{i}}"
                         formControlName="isSkipped"
                         name="isSkipped">
                  <label for="isSkipped_{{i}}" class="form-check-label">Skipped</label>
                </div>
                <div class="form-group row">
                  <input type="number" class="form-control form-control-sm col-sm-8" id="abPercent" formControlName="abPercent" name="abPercent">
                  <label for="abPercent" class="col-sm-4 control-label">A/B Testing %</label>
                </div>
              </div>
              <div class="col-md-7 ml-4">
                <json-editor [options]="makeOptions()" formControlName="params"></json-editor>
                <input type="hidden" id="description" formControlName="description" name="description">
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</form>
