<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Name" type="string" property="name"></tr>
  <tr filter-element title="Partner name" type="string" property="partnerName"></tr>
  <tr filter-element title="Token" type="string" property="token"></tr>
  <tr filter-element title="Enabled" type="boolean" property="enabled"></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all, affiliates.edit"
                  class="btn btn-success btn-sm"
                  (click)="navigateTo(0)">
            <bi name="plus-circle"></bi>
            Create
          </button>
    </span>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="name" (sort)="onSort($event)">Name</th>
      <th sortable="partnerName" (sort)="onSort($event)">Partner name</th>
      <th sortable="token" (sort)="onSort($event)">Token</th>
      <th>Additional parameter</th>
      <th>Custom tracker</th>
      <th>Redirect url</th>
      <th sortable="enabled" (sort)="onSort($event)">Enabled</th>
      <th sortable="created" (sort)="onSort($event)">Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of response?.results; let i = index; trackBy: trackById"
        (dblclick)="navigateTo(item.id)">
      <td></td>
      <td>{{item.name}}</td>
      <td>{{item.partnerName}}</td>
      <td>{{item.token}}</td>
      <td>{{item.additionalParameterName}}</td>
      <td>{{item.customTracker}}</td>
      <td>{{item.redirectUrl}}</td>
      <td>{{item.enabled}}</td>
      <td>{{item.created}}</td>
    </tr>
    </tbody>
  </table>
</server-table>



