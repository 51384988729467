<div class="modal-header">
  <h4 class="modal-title">
    Create role
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('closed')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="name">Name</label>
    <input class="form-control" id="name" [formControl]="name">
  </div>

  <div class="form-group d-flex flex-row overflow-auto" style="height: 600px">
    <label>Permissions</label>
    <div class="d-flex flex-column ml-2">
      <div *ngFor="let authority of authorities" class="d-flex align-items-center">
        <input
          type="checkbox"
          [checked]="selectedAuthorities.includes(authority.name)"
          (click)="selectAuthority(authority.name)"
        >
        <label class="mt-1 ml-2">{{authority.name}}</label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" [disabled]="name.invalid" (click)="onSaveClick()">Save</button>
  <button type="button" autofocus class="btn btn-outline-secondary" (click)="activeModal.dismiss(false)">Cancel</button>
</div>
