<div class="container-fluid row p-1">
  <aside class="col-md-2">
    <nav class="" >
        <ng-content select="ul[ngbNav]"></ng-content>
    </nav>
  </aside>
  <div class="col-md-10 pl-1 pt-0">
    <ng-content select="main"></ng-content>
  </div>
</div>
