import { ProductConfig } from '@backoffice-monorepo/api';
import { JsonEditorMode } from 'ang-jsoneditor';

export class PlPiProductConfig extends ProductConfig {
  postponeTaskHours = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  languages = ["en", "pl"];
  defaultLanguage = "en";
  jsonEditorModes = ['code', 'text', 'tree', 'view'] as JsonEditorMode[];
  locales = ['en_US','pl_PL'];
  httpTemplateKeyMarker = 'email.body';
  smsSenderName = "NetCredit";
  brand: 'NETCREDIT';
}
