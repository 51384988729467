import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AlertsService,
  LocalesService,
  MessageTemplateService,
  NamedComponent,
  NotificationConfigService, ProductConfigService,
  ViewHistoryService
} from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageTemplate, NotificationCommunicationType, NotificationConfigFormData, NotificationConfigRequest, } from '@twino/backoffice-api';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NotificationConfigsRefreshService } from '../../services/notification-configs-refresh.service';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { MessageTemplateEditComponent } from '../../../message-templates/components/message-template-edit/message-template-edit.component';
import { MessageTemplateAddComponent } from '../../../message-templates/components/message-template-add/message-template-add.component';
import {
  debounceTime,
  distinct,
  distinctUntilChanged,
  filter,
  first,
  map, mergeMap,
  reduce,
  take,
  takeUntil, tap
} from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-notification-config',
  templateUrl: './notification-config.component.html',
  styleUrls: ['./notification-config.component.scss']
})
export class NotificationConfigComponent extends NamedComponent implements OnInit, OnDestroy {
  @ViewChild('typeaheadFilterOptions', {static: true}) instance: NgbTypeahead;
  click$ = new Subject<string>();

  id: number;
  notificationConfigForm: FormGroup;
  addMessageTemplateForm: FormGroup;
  notifiableEvents: Map<string, string>;
  notificationRequest: NotificationConfigRequest;
  locales$: Observable<Array<string>>;
  notificationCommunicationType = NotificationCommunicationType;
  notificationCommunicationTypes = Object.keys(this.notificationCommunicationType);
  messageTemplates: MessageTemplate[] = [];
  productSubTypes: string[];
  brand: string | null = null;

  templateKeyTypeahead?: string;
  title: string;

  private notificationTemplateListRefreshSubscription$: Subscription;
  public productBrandsList$: Observable<string[]>

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private viewHistoryService: ViewHistoryService,
    private notificationConfigService: NotificationConfigService,
    private messageTemplatesService: MessageTemplateService,
    private notificationConfigsRefreshService: NotificationConfigsRefreshService,
    private localesService: LocalesService,
    private modalService: NgbModal,
    private productConfigService: ProductConfigService,
  ) {
    super(activatedRoute);
    this.productSubTypes = activatedRoute.snapshot.data.productSubTypes;
  }

  getName(): string {
    return `Notification config ${this.title}`;
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.title = (this.id > 0) ? '#' + this.id : 'create';
    this.locales$ = this.localesService.getLocales();
    this.productBrandsList$ = this.productConfigService.listBrands();
    this.notificationConfigService.getNotifiableEvents().pipe(first()).subscribe(it => this.notifiableEvents = it);
    this.notificationConfigForm = this.formBuilder.group({
      typeId: ['', Validators.required],
      templateKey: ['', Validators.required],
      attachmentConfigs: ['', Validators.required],
      enabled: [false],
      isSendOncePerDay: [false],
      isSendInCommunicationTimeWindow: [false],
      shouldStoreExternally: [false],
      sendCriterionArguments: [[]],
      sendCriterion: [],
      productSubType: [],
      brand: ['']
    });

    if (this.id > 0) {
      this.populateWithExistingData();
    }

    this.addMessageTemplateForm = this.formBuilder.group({
      communicationType: ['', Validators.required],
      locale: '',
    });
    this.notificationTemplateListRefreshSubscription$ = this.notificationConfigsRefreshService.reloadNotificationTemplateListModel$.subscribe(
      () => {
        this.getMessageTemplatesByKey(this.templateKeyTypeahead);
      });
  }

  ngOnDestroy(): void {
    this.notificationTemplateListRefreshSubscription$.unsubscribe();
  }

  searchNotifiable = (text$: Observable<string>) => {
    let filterOptions;
    const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    return merge(debouncedText$,  clicksWithClosedPopup$).pipe(
      tap(() => {
        if (this.notifiableEvents) filterOptions = Object.values(this.notifiableEvents)
        else return of([]);
      }),
      map((term: string) => term !== '' ? filterOptions.filter(v => v.toLowerCase().indexOf(term.trim().toLowerCase()) > -1) : filterOptions)
    );
  }

  populateTemplateKeys(key: string): Observable<string[]> {
    return this.messageTemplatesService.getMessageTemplatesByBrand(this.brand)
      .pipe(
        first(),
        mergeMap(template => template.results),
        map(template => template.key.split(/\.(email\.[^.]+|push\.[^.]+|sms|tts)$/ig)[0]),
        filter<string>((v) => v.toLowerCase().indexOf(key.toLowerCase()) > -1),
        distinct(),
        take(10),
        reduce<string, string[]>((acc, one) => {
          acc.push(one);
          return acc;
        }, [key])
      )
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap(term => term.length < 2 ? of([]) : this.populateTemplateKeys(term))
    )

  getMessageTemplatesByKey($key: string) {
    this.messageTemplatesService.getMessageTemplatesByKey($key, this.brand)
      .pipe(first())
      .subscribe(messageTemplates => this.messageTemplates = messageTemplates.results)
  }

  addNewTemplate() {
    const modalRef = this.modalService.open(MessageTemplateAddComponent, {size: 'xl'});
    modalRef.componentInstance.communicationType = this.addMessageTemplateForm.value.communicationType;
    modalRef.componentInstance.locale = this.addMessageTemplateForm.value.locale;
    modalRef.componentInstance.key = this.notificationConfigForm.value.templateKey;
    modalRef.result.then(
      () => this.notificationConfigsRefreshService.reloadNotificationTemplateListModel(),
      () => this.notificationConfigsRefreshService.reloadNotificationTemplateListModel()
    );
  }

  editTemplate(id: number) {
    const modalRef = this.modalService.open(MessageTemplateEditComponent, {size: 'xl'});
    modalRef.componentInstance.id = id;
    modalRef.result.then(
      () => this.notificationConfigsRefreshService.reloadNotificationTemplateListModel(),
      () => this.notificationConfigsRefreshService.reloadNotificationTemplateListModel()
    )
  }

  removeTemplates() {
    this.messageTemplates = [];
  }

  submitForm() {
    const formData = this.notificationConfigForm.value;
    const productSubType = (formData.productSubType !== "") ? formData.productSubType : null;

    this.notificationRequest = {
      typeId: this.getKeyByValue(this.notifiableEvents, formData.typeId),
      enabled: formData.enabled,
      templateKey: formData.templateKey,
      attachmentConfigs: JSON.parse(formData.attachmentConfigs),
      sendCriterion: null,
      sendCriterionArguments: [],
      isSendOncePerDay: formData.isSendOncePerDay,
      isSendInCommunicationTimeWindow: formData.isSendInCommunicationTimeWindow,
      shouldStoreExternally: formData.shouldStoreExternally,
      productSubType: productSubType,
      brand: formData.brand
    }

    if (this.id > 0) {
      this.updateNotificationConfig(this.notificationRequest);
    } else {
      this.createNotificationConfig(this.notificationRequest);
    }
  }

  createNotificationConfig(notificationRequest: NotificationConfigRequest) {
    this.notificationConfigService.createNotificationConfig(notificationRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess("Notification configuration is added");
      this.viewHistoryService.closeTab();
      this.notificationConfigsRefreshService.reloadNotificationConfigsListModel();
    })
  }

  updateNotificationConfig(notificationRequest: NotificationConfigRequest) {
    this.notificationConfigService.updateNotificationConfig(this.id, notificationRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess("Notification configuration is updated");
      this.viewHistoryService.closeTab();
      this.notificationConfigsRefreshService.reloadNotificationConfigsListModel();
    })
  }

  setBrand() {
    this.brand = this.notificationConfigForm.value.brand;
  }

  cancel() {
    this.viewHistoryService.closeTab();
  }

  trackByValue(index: number, value: string) {
    return value;
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  private populateWithExistingData() {
    this.notificationConfigService.getNotificationConfig(this.id).pipe(first()).subscribe(notificationConfig => {
      this.notificationConfigForm.setValue(new NotificationConfigFormData(notificationConfig));
      this.getMessageTemplatesByKey(notificationConfig.templateKey);
    });
  }
}
