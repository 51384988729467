import { Component, Input, OnInit } from '@angular/core';
import { Agreement, FullLoan, CommissionRateParameters, ScheduleItemCommission } from '@twino/backoffice-api';
import { LoanService, LoanSidebarLibComponent, draggableModalOptionsLarge } from '@backoffice-monorepo/commons';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanCommissionsViewComponent } from '../loan-commissions-view/loan-commissions-view.component';

@Component({
  selector: 'backoffice-monorepo-loan-agreements',
  templateUrl: './loan-agreements.component.html',
  styleUrls: ['./loan-agreements.component.scss']
})
export class LoanAgreementsComponent implements LoanSidebarLibComponent, OnInit {
  @Input()
  loan: FullLoan;
  agreements: Array<Agreement> | null;
  selectedAgreement: Agreement | null;

  constructor(
    private loanService: LoanService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    return this.loanService.getLoanAgreements(this.loan.id).pipe(
      take(1)
    ).subscribe((agreements) => {
      this.agreements = agreements;
    })
  }

  selectAgreement(agreement: Agreement) {
    this.selectedAgreement = agreement;
  }

  viewCommissions(mode: string, commissions: CommissionRateParameters[] | ScheduleItemCommission[]) {
    const modalRef = this.modalService.open(LoanCommissionsViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.commissions = commissions;
  }

  trackById(index: number, item: any){
    return item.id;
  }

}
