<div class="card p-3 mt-3">
  <p><strong>Received email:</strong></p>
  <ul class="list-group mb-3">
    <li class="list-group-item"><strong>Date: </strong>{{clientCommunication?.created}}</li>
    <li class="list-group-item"><strong>To: </strong>{{clientCommunication?.destination}}</li>
    <li class="list-group-item"><strong>From: </strong>{{clientCommunication?.sender}}</li>
    <li class="list-group-item">
      <strong>Client:</strong>
      <a
        *ngIf="client"
        routerLink="/clients/{{client.savedId}}"
      > {{client.fullName}} </a>
      <button type="button" class="btn btn-sm btn-primary ml-2" (click)="changeClient()">Change</button>
    </li>
    <li class="list-group-item"><strong>Subject: </strong>{{clientCommunication?.subject}}</li>
    <li class="list-group-item" [innerHTML] = "clientCommunication?.content | safe: 'html'"></li>
  </ul>
</div>

<div class="card mt-3 p-3">
  <form [formGroup]="replyEmailSendForm">
      <div class="mb-3">
        <button
          type="submit"
          class="btn btn-sm btn-primary"
          [disabled]="replyEmailSendForm.invalid"
          (click)= "submitForm()"
        >
          Send
        </button>
        <button type="button" class="btn btn-sm btn-primary mr-1 ml-1" (click)="closeTask()">
          Reply not required
        </button>
        <button type="button" class="btn btn-sm btn-primary mr-1" (click)="closeComponent()">
          Cancel
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="assignTask()">
          Assign
        </button>
      </div>

    <div class="mb-3">
      <div class="list-group-item list-group-item-small d-flex flex-row">
        <label
          for="emailFrom"
          class="col-sm-3 control-label">
          <strong>From:</strong>
          <sup class="text-danger">*</sup>
        </label>
        <div class="col-sm-4">
          <select class="form-control form-control-sm" id="emailFrom" formControlName="emailFrom" name="emailFrom">
            <option value=""></option>
            <option *ngFor="let mailbox of mailboxes" [ngValue]="mailbox">{{mailbox}}</option>
          </select>
        </div>
      </div>

      <div class="list-group-item list-group-item-small">
        <div class="col-sm-8">
          <strong>To: </strong>{{clientCommunication?.sender}}
        </div>
      </div>

      <div class="list-group-item list-group-item-small d-flex flex-row">
        <label
          for="subject"
          class="col-sm-2 control-label">
          <strong>Additional recipient: </strong>
        </label>

        <div class="col-sm-3">
          <client-lookup
            formattedProperty="email"
            returnedProperty="email"
            [clearAfterSelected]="true"
            (clientSelected)="addRecipient($event)"
          ></client-lookup>
        </div>

        <div class="col-sm-4">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let recipient of recipients">
              <span id="file-label">{{recipient}}</span>
              <button type="button" class="btn btn-danger btn-sm float-right" (click)="removeRecipient(recipient)">
                <bi name="x"></bi>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div class="list-group-item list-group-item-small d-flex flex-row">
        <label
          for="subject"
          class="col-sm-2 control-label">
          <strong>Subject:</strong>
          <sup class="text-danger">*</sup>
        </label>
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control form-control-sm"
            id="subject"
            formControlName="subject"
            name="subject">
        </div>
      </div>

      <div class="list-group-item list-group-item-small d-flex flex-row">
        <label
          for="templateKey"
          class="col-sm-2 control-label">
          <strong>Email template:</strong>
        </label>
        <div class="col-sm-3">
          <select
            ngbAutofocus
            class="form-control form-control-sm"
            id="templateKey"
            formControlName="templateKey"
            name="templateKey"
          >
            <option value=""></option>
            <option
              *ngFor="let template of messageTemplates$ | async"
              [ngValue]="template.key"
            >{{template.key}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 control-label"><strong>Attachments</strong></label>
      <div class="col-sm-4">
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let file of files">
            <span id="file-label">{{file.name}}</span>
            <button type="button" class="btn btn-danger btn-sm float-right" (click)="removeFile(file.index)">
              <bi name="x"></bi>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group custom-file col-sm-3">
      <input type="file"
            class="custom-file-input"
            id="attachment"
            multiple="multiple"
            formControlName="attachment"
            (change)="addFile($event)"/>
      <label class="custom-file-label" for="attachment">Choose file...</label>
    </div>

    <div class="form-group row mt-3">
      <div class="col-sm-8">
        <editor
          [init]="editorConfig"
          formControlName="body"
        ></editor>
      </div>
    </div>
  </form>
</div>
