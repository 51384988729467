<div class="container-fluid">
  <div class="mt-1">
    <ng-component taskView></ng-component>
  </div>
  <hr/>
  <div class="row justify-content-md-center">
    <div class="mb-1">
      <button
        *ngIf="task.relatedEntities['communicationId']"
        type="button"
        class="btn btn-sm btn-primary"
        (click)="replyToEmail()">
        <bi name="check"></bi>
          Reply to email
      </button>
      <button type="button" class="btn btn-sm btn-primary mr-1 ml-1" (click)="closeTaskModal()">
        <bi name="check"></bi>
        Close
      </button>
      <button type="button" class="btn btn-sm btn-primary mr-1 ml-1" (click)="postponeModal()">
        <bi name="clock"></bi>
        Postpone
      </button>
      <button type="button" class="btn btn-sm btn-primary" (click)="releaseToGroup()">
        <bi name="person-fill" class="bold-svg"></bi>
        Release to group
      </button>
    </div>
  </div>
</div>
