import { PhoneOtpResponse } from './pl-pi-offer';

export interface CardsInfoResponse {
  cards: CardInfo[]
}

export interface CardInfo {
  id: number
  diPocketClientId: number
  accountId: number
  token: string
  status: string
  clientId: number
  /*updated: Date
  statusChangeReason: CardStatusChangeReason
  expirationDate: Date
  orderDate: Date
  activationDate: Date*/
  type: CardIssueType
}

export interface ClientRegisterResponse {
  clientId: number
}

export interface SendPinNotificationRequest {
  cvv: string
}

export interface ClientInfoResult {
  clientId: number
  langCode: string
  firstName: string
  lastName: string
  rStreetLine1: string
  rStreetLine2: string
  rCity: string
  rState: string
  rZip: string
  rCountryCode: string
  mStreetLine1: string
  mStreetLine2: string
  mCity: string
  mState: string
  mZip: string
  mCountryCode: string
  cardHolderName: string
  email: string
  mainPhone: string
  dob: string
  ddStatus: string
  supervisorId: number
}

export enum CardStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  LOST = 'LOST',
  STOLEN = 'STOLEN',
  DESTROYED = 'DESTROYED',
  RESTRICTED_CARD = 'RESTRICTED_CARD',
  NO_CARD = 'NO_CARD'
}

export interface Transaction {
  id: number
  detailsRef: string
  kind: string
  stateId: number
  stateName: string
  eventDate: string
  trnAmount: number
  trnCurrencyCode: string
  feeAmount: number
  feeCurrencyCode: string
  fullName: string
  accAmount: number
}

export interface Account {
  availableBalance: number
  financeBalance: number
  currencyCode: string
}

export interface TransactionListResponse {
  account: Account
  transactions: Transaction[]
}

export interface GetTransactionRequest {
  startDate: Date
  endDate: Date
}

export interface CardTransactionDetailsResponse {
  accAmount?: number
  accCurrencyCode?: string
  category?: string
  convFeeAmount?: number
  convFeeCurrencyCode?: string
  details?: string
  eventDate?: string
  finishDate?: string
  fullName?: string
  maskedPan?: string
  note?: string
  rate?: string
  rateRev?: string
  stateId?: number
  stateMsg?: string
  stateName?: string
  tranTypeId?: number
  kind?: string
  trnAmount?: number
  trnCurrencyCode?: string
  feeAmount?: number
  feeCurrencyCode?: string
}

export interface CardTransactionDetailsRequest {
  detailsRef?: string
}

export interface CardStatusChangeRequest {
  reason: string
}

export interface CardHistoryEntryResponse {
  cardId: number
  status: CardStatus
  reason: string
  created: Date
}

export enum CardChangeState {
  FREEZE = 'FREEZE',
  UNFREEZE  = 'UNFREEZE',
  CLOSE = 'CLOSE'
}

export enum CardIssueType {
  PLASTIC = 'PLASTIC',
  VIRTUAL  = 'VIRTUAL',
}

export interface InitiateCardCreationWithConfirmationResponse {
  otpResponse: PhoneOtpResponse
}

export interface CreateCardConfirmationRequest {
  cardIssueType: CardIssueType
  confirmationCode: string
}

export interface CardCreateResponse {
  token: string
  accountId: number
}

export interface InitiateCardActivationWithConfirmationResponse {
  otpResponse: PhoneOtpResponse
}

export interface ActivateCardConfirmationRequest {
  confirmationCode: string
}

export enum CardState {
  PLASTIC_ACTIVE = 'PLASTIC_ACTIVE',
  PLASTIC_ORDERED = 'PLASTIC_ORDERED',
  VIRTUAL_ACTIVE_PLASTIC_ORDERED = 'VIRTUAL_ACTIVE_PLASTIC_ORDERED',
  VIRTUAL_ACTIVE = 'VIRTUAL_ACTIVE',
  NO_CARD = 'NO_CARD',
}

export interface  UpdateApplicationCardTypeRequest {
  cardIssueType: CardIssueType
}

export enum CardStatusChangeReason {
  BAD_DATA = 'BAD_DATA',
  SUSPICIOUS_CLIENT = 'SUSPICIOUS_CLIENT',
  EXTORTION = 'EXTORTION',
  LIKEHOOD_OF_EXTORTION = 'LIKEHOOD_OF_EXTORTION',
  CONTRACT_TERMINATION = 'CONTRACT_TERMINATION',
  DIPOCKET_TRANSACTION_MONITORING = 'DIPOCKET_TRANSACTION_MONITORING',
  CLIENT_REQUEST = 'CLIENT_REQUEST',
  DIPOCKET_POLICE_CASE_STARTED = 'DIPOCKET_POLICE_CASE_STARTED',
  ACTIVE_DELAY = 'ACTIVE_DELAY',
  CREDIT_CARD_DATA_THEFT = 'CREDIT_CARD_DATA_THEFT',
  DIPOCKET_SUSPICIOUS_TRANSACTIONS_NOT_CONFIRMED_BY_CLIENT = 'DIPOCKET_SUSPICIOUS_TRANSACTIONS_NOT_CONFIRMED_BY',
  DIPOCKET_SUSPICIOUS_TRANSACTIONS_CONFIRMED_BY_CLIENT = 'DIPOCKET_SUSPICIOUS_TRANSACTIONS_CONFIRMED_BY',
  DIPOCKET_END_OF_POLICE_CASE = 'DIPOCKET_END_OF_POLICE_CASE',
  DEBT_REPAYMENT = 'DEBT_REPAYMENT',
  DIPOCKET_FRAUD = 'DIPOCKET_FRAUD',
  NEW_LOAN = 'NEW_LOAN',
  RENOUNCEMENT = 'RENOUNCEMENT',
  PLASTIC_CARD_ACTIVATED = 'PLASTIC_CARD_ACTIVATED',
  OTHER = 'OTHER',
}
