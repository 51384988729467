import { Component, Input, OnInit } from '@angular/core';
import { ActivityOverview, ActivityOverviewCommunicationType, ActivityOverviewType, FullLoan } from '@twino/backoffice-api';
import { ActivityOverviewService, draggableModalOptionsLarge, LoanService, LoanSidebarLibComponent } from '@backoffice-monorepo/commons';
import { CommunicationContentViewComponent } from '../../../communications/components/communication-content-view/communication-content-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-loan-activity-overview',
  templateUrl: './loan-activity-overview.component.html',
  styleUrls: ['./loan-activity-overview.component.scss']
})
export class LoanActivityOverviewComponent implements LoanSidebarLibComponent, OnInit {
  @Input()
  loan: FullLoan;
  activityOverview: ActivityOverview[] | null;
  communicationTypeActivityOverview = ActivityOverviewCommunicationType;
  activityOverviewType = ActivityOverviewType;

  constructor(
    private loanService: LoanService,
    public activityOverviewService: ActivityOverviewService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    return this.activityOverviewService.getActivityOverviewByLoan(this.loan.id).pipe(
      take(1)
    ).subscribe((activityOverview) => {
      this.activityOverview = activityOverview.results;
    })
  }

  viewContent(communicationId) {
    const modalRef = this.modalService.open(CommunicationContentViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.communicationId = communicationId;
  }
}
