import { Component } from '@angular/core';
import {
  AlertsService,
  BasicComponent,
  ConfirmDialogueComponent,
  CountryViewComponent,
  PaymentDistributionService
} from '@backoffice-monorepo/commons';
import { Payment, PaymentDirection, PaymentDistribution, PaymentStatus, TransactionTypes } from '@twino/backoffice-api';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentsRefreshService } from '../../../../../../libs/core/src/lib/modules/payments/services/payments-refresh.service';
import { PlPiPaymentService } from '../../services/pl-pi-payment.service';

@Component({
  selector: 'backoffice-monorepo-pl-pi-payment-distribution-table',
  templateUrl: './pl-pi-payment-distributions-table.component.html',
  styleUrls: ['./pl-pi-payment-distributions-table.component.css']
})
export class PlPiPaymentDistributionsTableComponent extends BasicComponent implements CountryViewComponent {

  data: PaymentDistribution[] | null;
  payment: Payment | null;
  paymentDirection = PaymentDirection;
  paymentStatus = PaymentStatus;
  transactionTypes = TransactionTypes;

  constructor(
    private distributionService: PaymentDistributionService,
    private modalService: NgbModal,
    private paymentsRefreshService: PaymentsRefreshService,
    private paymentServicePlPi: PlPiPaymentService,
    private alertService: AlertsService,
  ) {
    super();
  }

  voidPaymentDistribution(paymentDistributionId: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Void payment distribution";
    modalRef.componentInstance.content = `Are you sure you want to void payment distribution #${paymentDistributionId}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) this.distributionService.voidPaymentDistribution(paymentDistributionId).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {this.paymentsRefreshService.reloadPaymentModel();}
        );
      }, () => {}
    );
  }

  processPaymentDistribution(paymentDistributionId: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Process payment distribution";
    modalRef.componentInstance.content = `Are you sure you want to process payment distribution #${paymentDistributionId}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) this.distributionService.processPaymentDistribution(paymentDistributionId).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {this.paymentsRefreshService.reloadPaymentModel();}
        );
      }, () => {}
    );
  }

  deletePaymentDistribution(paymentDistributionId: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Delete payment distribution";
    modalRef.componentInstance.content = `Are you sure you want to delete payment distribution #${paymentDistributionId}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) this.distributionService.deletePaymentDistribution(paymentDistributionId).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {this.paymentsRefreshService.reloadPaymentModel();}
        );
      }, () => {}
    );
  }

  addReturnPayment() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Create return payment";
    modalRef.componentInstance.content = "Are you sure you want to create return payment?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.paymentServicePlPi.createReturnPayment(this.payment?.id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.alertService.notifySuccess(`Return payment created`);
        });
      }, () => {}
    );
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
