import { Component, OnInit } from '@angular/core';
import { BuildInformationService, NamedComponent } from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends NamedComponent implements OnInit {
  buildInformation$: Observable<any>;

  constructor(
    activatedRoute: ActivatedRoute,
    private buildInformationService: BuildInformationService
  ) {
    super(activatedRoute);
  }

  ngOnInit() {
    this.buildInformation$ = this.buildInformationService.getBuildInformation();
  }

  getName(): string {
    return 'About';
  }

}
