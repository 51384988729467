import { Component, OnInit } from '@angular/core';
// noinspection ES6PreferShortImport
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
// noinspection ES6PreferShortImport
import { ViewHistoryService } from '../../services/view-history.service';

@Component({
  selector: 'backoffice-monorepo-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router,
    private viewHistoryService: ViewHistoryService
  ) {
  }

  ngOnInit(): void {
    this.viewHistoryService.clearHistory();
    sessionStorage.removeItem("phoneAppBrand");
    this.userService.logout().subscribe(() => this.router.navigateByUrl('/login'));
  }

}
