<div class="modal-header">
  <h4 class="modal-title">
    Create authority
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('closed')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="name">Name</label>
    <input class="form-control" id="name" [formControl]="name">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" [disabled]="name.invalid" (click)="onSaveClick()">Save</button>
  <button type="button" autofocus class="btn btn-outline-secondary" (click)="activeModal.dismiss(false)">Cancel</button>
</div>
