import { SortingCriterion } from './sorting';
import { FilterOperation, FilterViewElementType } from './search.filters';

export enum JoinStrategy {
  OR = "OR", AND = "AND"
}

export const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_MAX_COUNT_LIMIT = 1000;
export const MAX_COUNT_LIMIT_DISABLED = null;

/**
 * @property page number of desired page
 * @property pageSize desired number of results per page
 * @property joinStrategy the strategy of applying criteria
 * @property maxCountLimit limit of total count
 * @property sortingCriterion the criterion of sorting
 * @property criteria list of criterions to apply to the search
 */
export class SearchQuery {
  page: number
  pageSize: number
  joinStrategy?: string
  maxCountLimit?: number
  sortingCriterion?: SortingCriterion
  criteria?: FilterDataValues[]

  constructor(page: number, pageSize: number) {
    this.page = page;
    this.pageSize = pageSize;
  }

  static fromBaseQuery() {
    return new SearchQuery(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
  }

  removeCriteriaByPropertyName(propertyName: string) {
    this.criteria = this.criteria.filter(it => it.propertyName !== propertyName);
  }

  addFilter(
    propertyName: string,
    operation: FilterOperation,
    values: Array<string | Date>,
    type: FilterViewElementType = null,
    enumClass: string = null
  ) {
    const filterData = new FilterDataValues(propertyName, operation, values);
    if (type) filterData.type = type;
    if (enumClass) filterData.enumClass = enumClass;
    return this.addCriterion(filterData);
  }

  addFilterReplacing(
    propertyName: string,
    operation: FilterOperation,
    values: string[] | Date[],
    type: FilterViewElementType = null,
    enumClass: string = null
  ) {
    const filterData = new FilterDataValues(propertyName, operation, values);
    if (type) filterData.type = type;
    if (enumClass) filterData.enumClass = enumClass;
    return this.addCriterionReplacing(filterData);
  }

  addCriterion(filterData: FilterDataValues) {
    if (!this.criteria) this.criteria = [];
    this.criteria.push(filterData);
  }

  addCriterionReplacing(filterData: FilterDataValues) {
    this.removeCriteriaByPropertyName(filterData.propertyName);
    this.addCriterion(filterData);
  }
}


export class FilterDataValues {
  propertyName: string
  operation: FilterOperation
  values: Array<string | Date>
  type?: FilterViewElementType
  enumClass?: string


  constructor(
    propertyName: string,
    operation: FilterOperation,
    values: Array<string | Date>
  ) {
    this.propertyName = propertyName;
    this.operation = operation;
    this.values = values;
  }
}

export class SearchQueryBuilder {
  private searchQuery: SearchQuery;

  constructor(searchQuery: SearchQuery = SearchQuery.fromBaseQuery()) {
    this.searchQuery = JSON.parse(JSON.stringify(searchQuery)) as SearchQuery;
  }

  withPage(page: number) {
    this.searchQuery.page = page;
    return this;
  }

  withPageSize(pageSize: number) {
    this.searchQuery.pageSize = pageSize;
    return this;
  }

  withJoinStrategy(joinStrategy: JoinStrategy) {
    this.searchQuery.joinStrategy = joinStrategy;
    return this;
  }

  withSortCriterion(sortingCriterion: SortingCriterion) {
    this.searchQuery.sortingCriterion = new SortingCriterion(sortingCriterion.propertyName, sortingCriterion.sortDirection);
    return this;
  }

  withCriteria(criteria: Array<FilterDataValues>) {
    criteria.forEach((criterion) => this.addFilterData(criterion));
    return this;
  }

  addFilterDataConditionally( condition: boolean, filterData: FilterDataValues) {
    if (condition) {
      return this.addFilterData(filterData);
    }
    return this;
  }


  addFilterData(filterData: FilterDataValues) {
    const newFilterData = new FilterDataValues(filterData.propertyName, filterData.operation, filterData.values);
    if (filterData.type) newFilterData.type = filterData.type;
    if (filterData.enumClass) newFilterData.enumClass = filterData.enumClass;

    if (newFilterData.propertyName === 'referenceId') {
      newFilterData.type = 'uuid';
    }
    if (!this.searchQuery.criteria) this.searchQuery.criteria = [];
    this.searchQuery.criteria.push(newFilterData);

    return this;
  }


  addFilter(
    propertyName: string,
    operation: FilterOperation,
    values: Array<string | Date>,
    type: FilterViewElementType = null,
    enumClass: string = null
  ) {
    const filterData = new FilterDataValues(propertyName, operation, values);
    if (type) filterData.type = type;
    if (enumClass) filterData.enumClass = enumClass;
    return this.addFilterData(filterData);
  }

  build(): SearchQuery {
    if (this.searchQuery.criteria?.length > 1 && !this.searchQuery.joinStrategy) {
      this.searchQuery.joinStrategy = JoinStrategy.OR
    }
    const result = new SearchQuery(this.searchQuery.page, this.searchQuery.pageSize);
    Object.assign(result, JSON.parse(JSON.stringify(this.searchQuery)));
    return result;
  }
}

