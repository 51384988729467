import { FullClient } from '@twino/backoffice-api';
import { ProductSubType } from '../modules/phone-application/api/initial-data';
import { CardState } from './client.card';

export interface PlPiFullClient extends FullClient {
  hasOpenLoanApplication: boolean
  canApplyForRepeatedLoan: boolean
  isMigratedClient: boolean
  repeatedApplicationFinishedStep: number
  canStartNewApplicationFlow: boolean
  canContinueCurrentApplicationFlow: boolean
  clientApplicationFlowType: ClientApplicationFlowType
  applicableProductSubtype: ProductSubType
  refinanceCalculations?: RefinanceCalculations
  repeated: boolean
  cardState: CardState
}

export interface RefinanceCalculations {
  annualPercentageRateOfCharge: number,
  minimumPayment: number,
  nextRepaymentDate: Date,
  payInFull: number,
  extraAmount: number,
  openLoanAmount: number,
  totalAmount: number
}

export enum ClientApplicationFlowType {
  NEW = 'NEW',
  REPEATED = 'REPEATED',
  MIGRATED = 'MIGRATED',
}
