<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Payment distributions</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th>Id</th>
          <th>Payment</th>
          <th>Status</th>
          <th>Transaction type</th>
          <th>Amount</th>
          <th>Surplus amount</th>
          <th>Booking date</th>
          <th>From account</th>
          <th>Client</th>
          <th>Loan</th>
          <th>Revisions</th>
          <th>Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of searchResults?.results; trackBy: trackById">
          <td>{{ item.id }}</td>
          <td>
            <a routerLink="/payments/{{item.paymentId}}">{{item.paymentId}}</a>
          </td>
          <td>{{ item.status }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.surplusAmount }}</td>
          <td>{{ item.bookingDate | date: 'dd.MM.yyyy' }}</td>
          <td>{{ item.fromAccount }}</td>
          <td>{{ item.clientId }}</td>
          <td>
            <a routerLink="/loans/{{item.loanId}}">{{item.loanId}}</a>
          </td>
          <td></td>
          <td>{{ item.created }}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
    <div class="form-inline form-group" *ngIf="(searchResults?.totalRecords >= itemsPerPage)">
      <ngb-pagination class="pages"
                      size="sm"
                      [collectionSize]="searchResults?.totalRecords"
                      [page]="searchResults?.currentPage"
                      [pageSize]="itemsPerPage"
                      [maxSize]="10"
                      [rotate]="false"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                      (pageChange)="pageChange($event)">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
