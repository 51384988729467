import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ratioToPercent'
})
export class RatioToPercentPipe implements PipeTransform {
  constructor() {}

  transform(value: number): number {
    return value * 100;
  }
}
