import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SystemConfigListComponent } from './components/system-config-list/system-config-list.component';
import { SystemConfigEditComponent } from './components/system-config-edit/system-config-edit.component';
import { SystemConfigAddComponent } from './components/system-config-add/system-config-add.component';


const routes: Routes = [
  {path: '', component: SystemConfigListComponent, pathMatch: 'full'},
  {path: 'create', component: SystemConfigAddComponent, pathMatch: 'full'},
  {path: ':id', component: SystemConfigEditComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemConfigsRoutingModule {
}
