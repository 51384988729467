<aside class="container-fluid row">
  <div class="mt-1 d-flex">
    <span>
      <server-refresh (refresh)="agreementType.reset();"></server-refresh>
    </span>
   <span class="ml-3">
        <button type="button" class="btn btn-info">
          Portfolio value <span class="badge badge-light">{{response?.totalAmountSum}} PLN</span>
        </button>
    </span>

    <span class="ml-3">
        <button type="button" class="btn btn-info">
          Report date <span class="badge badge-light">{{response?.reportDate | date:'dd.MM.yyyy'}}</span>
        </button>
    </span>

    <span class="ml-3">
        <button type="button" class="btn btn-info">
          Number of loans <span class="badge badge-light">{{response?.totalRecords}}</span>
        </button>
    </span>

    <span class="ml-3">
        <button type="button" class="btn btn-success" (click)="generateReport()">
          Generate pre-sell report
        </button>
    </span>

    <span class="ml-3">
        <button type="button" class="btn btn-warning" (click)="setPreSoldStatus()">
          Set Pre_Sold status
        </button>
    </span>

    <span class="ml-3">
        <button type="button" class="btn btn-warning" (click)="preSellLoans()">
          Pre-Sell loans
        </button>
    </span>
    <span class="ml-3">
       <select [formControl]="agreementType" class="bg-light form-control form-control-sm h-100">
         <option class="text-secondary" value="" selected disabled hidden>Filter by Agreement</option>
         <option *ngFor="let option of AgreementType | keyvalue" [ngValue]="option.key">{{ option.value }}</option>
       </select>
    </span>
    <span class="ml-3" *ngIf="productBrandsList$ | async as brands">
        <select [formControl]="brandType" class="bg-light form-control form-control-sm h-100">
           <option value="" selected disabled hidden>Filter by brand</option>
           <option *ngFor="let key of brands" [ngValue]="key">{{ key }}</option>
         </select>
    </span>
  </div>
</aside>

<table class="table table-striped table-hover mt-1">
  <thead class="text-light bg-primary">
  <tr>
    <th></th>
    <th>Loan ID</th>
    <th>Total amount</th>
    <th>DPD</th>
    <th>Agreement type</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of response?.results">
    <td></td>
    <td>{{item.loanId}}</td>
    <td>{{item.totalAmount}}</td>
    <td>{{item.dpd}}</td>
    <td>{{item.agreementType}}</td>
    <td>
      <button appRequirePermission="admin.all,loan-sale.edit"
              class="btn btn-danger btn-sm"
              (click)="removeFromPresale(item.loanId)">
        Remove
      </button>
    </td>
  </tr>
  </tbody>
</table>

<nav class="container-fluid row" *ngIf="response">
  <div class="col-md-10">
    <pagination [totalRecords]="response.totalRecords"
                [totalPages]="response.totalPages"
                [currentPage]="response.currentPage"
                [itemsPerPage]="loanSaleSearchQuery?.pageSize"
                (setPage)="refresh($event)"
    ></pagination>
  </div>
  <div class="col-md-2 text-right">Total record(s): {{response?.totalRecords}}</div>
</nav>

