export class Transfer {
  id: number
  paymentId?: number
  clientId?: number
  referenceId: string
  timestamp: Date
  direction: string
  amount: number
  fromAccount: string
  toAccount: string
  status: string
  systemPayload?: string
  providerPayload: string
  purpose?: string
  providerId?: string
  comment?: string
  refreshed: boolean
  created: Date
  updated: Date
  offerId?: number
}

export class CreateTransferRequest {
  clientId: number;
  timestamp: Date
  loanNumber: string
  amount: number
  fromAccount: string
  toAccount: string
  comment: string
}

export class RedirectTransferRequest {
  transferId: number
  destinationLoanId: number
}


export enum TransferStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  EXPIRED = 'EXPIRED',
  RETRY_REQUESTED = 'RETRY_REQUESTED'
}
