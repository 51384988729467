<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Group name" type="string" property="groupName"></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all, task-group.create"
                  class="btn btn-success btn-sm"
                  (click)="createTaskGroup()">
            <bi name="plus-circle"></bi>
            Create task group
          </button>
    </span>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="groupName" (sort)="onSort($event)">Group name</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let taskGroup of response?.results; let i = index; trackBy: trackById">
      <td></td>
      <td>
        <button type="button"
                class="btn btn-outline-danger btn-sm"
                (click)="deleteTaskGroup(taskGroup.id)"
                appRequirePermission="admin.all,task-group.delete">
          Delete
        </button>
      </td>
      <td>{{taskGroup.id}}</td>
      <td>{{taskGroup.groupName}}</td>
    </tr>
    </tbody>
  </table>
</server-table>



