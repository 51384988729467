import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class TwinoDateAdapterService extends NgbDateAdapter<string> {

  fromModel(value: string): NgbDateStruct {
    if (!value)
      return null
    const parts = value.split("-");
    return {year: +parts[0], month: +parts[1], day: +parts[2]}
  }

  toModel(date: NgbDateStruct): string {
    return date ? date.year + "-" + ('0' + date.month).slice(-2) + "-" + ('0' + date.day).slice(-2) : null
  }
}
