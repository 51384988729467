<form [formGroup]="clientDocumentUploadForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Upload client document</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="type" class="col-sm-4 control-label">Type<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select ngbAutofocus class="form-control form-control-sm" id="type" formControlName="type" name="type">
          <option *ngFor="let type of clientDocumentTypes" [ngValue]="type">{{type}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="verificationStatus" class="col-sm-4 control-label">Verification status<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select class="form-control form-control-sm" id="verificationStatus" formControlName="verificationStatus" name="verificationStatus">
          <option *ngFor="let status of clientDocumentVerificationStatuses" [ngValue]="status">{{status}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="sourceId" class="col-sm-4 control-label">Source Id<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="sourceId" formControlName="sourceId" name="sourceId">
      </div>
    </div>
    <div class="form-group row">
      <label for="sourceType" class="col-sm-4 control-label">Source Type<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select class="form-control form-control-sm" id="sourceType" formControlName="sourceType" name="sourceType">
          <option *ngFor="let sourceType of clientDocumentSourceTypes" [ngValue]="sourceType">{{sourceType}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-4 control-label">Description<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <textarea class="form-control form-control-sm"
                  id="description"
                  formControlName="description"
                  name="description"
                  cols="50"></textarea>
      </div>
    </div>
    <div class="form-group custom-file">
      <input type="file"
             class="form-control custom-file-input"
             id="clientFile"
             formControlName="file"
             name="file"
             (change)="fileProgress($event)"/>
      <label class="custom-file-label" for="clientFile">Choose file...</label>
    </div>
    <div *ngIf="fileUploadProgress">
      Upload progress: {{ fileUploadProgress }}
    </div>
    <div class="image-preview mb-3" *ngIf="previewUrl">
      <img [src]="previewUrl" height="300" />
    </div>

    <div class="mb-3" *ngIf="uploadedFilePath">
      {{uploadedFilePath}}
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientDocumentUploadForm.valid">Save</button>
  </div>
</form>





