import { Component, Input, OnInit } from '@angular/core';
import { PlPiDebtCollectionStagesService } from '../../modules/debt-collection-stages/services/pl-pi-debt-collection-stages.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DebtStageType } from '../../modules/debt-collection-stages/api/debt-stage';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-set-dc-stage',
  templateUrl: './pl-pi-loan-set-dc-stage.component.html',
  styleUrls: ['./pl-pi-loan-set-dc-stage.component.css']
})
export class PlPiLoanSetDcStageComponent extends BasicComponent implements OnInit {

  @Input()
  loanId: number;
  setDcStageForm: FormGroup;
  stageType = DebtStageType;
  stageTypeOptions = [];

  constructor(
    private debtCollectionStagesService: PlPiDebtCollectionStagesService,
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.stageTypeOptions = Object.keys(this.stageType);
    this.setDcStageForm = this.formBuilder.group({
      stageType: ['', Validators.required],
      description: '',
    });
  }

  submitForm() {
    this.debtCollectionStagesService.setLoanDcStage(this.loanId, this.setDcStageForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.activeModal.close();
      this.alertService.notifySuccess(`Debt collection stage is set`);
    })
  }
}
