import { Component, Input, OnChanges } from '@angular/core';
import { MailboxService } from '@backoffice-monorepo/commons';
import { take } from 'rxjs/operators';
import { MailboxAddressAndTasksCountResponse } from '@twino/backoffice-api';
import { TasksRefreshService } from '../../services/tasks-refresh.service';

@Component({
  selector: 'backoffice-monorepo-tasks-topic-mailbox',
  templateUrl: './tasks-topic-mailbox.component.html',
  styleUrls: ['./tasks-topic-mailbox.component.css']
})
export class TasksTopicMailboxComponent implements OnChanges {

  @Input() topic: string
  mailboxes: MailboxAddressAndTasksCountResponse[];
  constructor(
    private mailboxService: MailboxService,
    private tasksRefreshService: TasksRefreshService
  ) { }

  ngOnChanges(): void {
    this.mailboxService.getMailboxAddresses(this.topic).pipe(take(1)).subscribe(addresses => {
      this.mailboxes = addresses.mailboxAddresses;
    })
  }

  openTasksForMailbox(mailbox: string) {
    this.tasksRefreshService.openTasksForMailbox(mailbox);
  }
}
