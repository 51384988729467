<div class="modal-header">
  <h4 class="modal-title">View transfer payloads #{{transferId}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="col-md-12">
      <h5 class="pb-3">System payload</h5>

      <div [ngSwitch]="isSystemPayloadJsonString">
        <div *ngSwitchCase="true">
          <pre><code>{{ systemPayload | json }}</code></pre>
        </div>
        <div *ngSwitchCase="false">
          {{ systemPayload }}
        </div>
      </div>

    </div>
    <div class="col-md-12">
      <h5 class="pb-3">Provider payload</h5>

      <div [ngSwitch]="isProviderPayloadString">
        <div *ngSwitchCase="true">
          <pre><code>{{ providerPayload | json }}</code></pre>
        </div>
        <div *ngSwitchCase="false">
          {{ providerPayload }}
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
</div>

