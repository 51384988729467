import { Injectable, Optional, SkipSelf, Type } from '@angular/core';
import { MailboxTaskViewComponent, TaskViewComponent, TaskViewComponentResolver } from '@backoffice-monorepo/commons';
import { PlPiTopic } from '../enums/pl-pi-topic.enum';

@Injectable({
  providedIn: 'root'
})
export class PlPiTaskViewComponentResolverService extends TaskViewComponentResolver {

  constructor(
    @Optional() @SkipSelf() parent?: TaskViewComponentResolver
  ) {
    super()
    if (parent) {
      throw Error("[TaskViewComponentResolver]: trying to create multiple instances, but this service should be a singleton.")
    }
  }

  resolve(topic: string): Type<TaskViewComponent> {
    switch (topic) {
      case PlPiTopic.DEBT_COLLECTION:
      case PlPiTopic.CONTACT:
        return MailboxTaskViewComponent;
      default: return super.defaultResolution(topic);
    }
  }
}
