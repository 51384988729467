export enum  FeeType {
  TARIFF_PLAN_FEE = 'TARIFF_PLAN_FEE',
  ACCESS_TO_ONLINE_CUSTOMER_PANEL = 'ACCESS_TO_ONLINE_CUSTOMER_PANEL',
  PHONE_CUSTOMER_SERVICE = 'PHONE_CUSTOMER_SERVICE',
  TRANSACTION_STATEMENT = 'TRANSACTION_STATEMENT',
  FAST_CASH_SERVICE = 'FAST_CASH_SERVICE',
  CARD_ACTIVATION = 'CARD_ACTIVATION',
  CARD_RENEWAL = 'CARD_RENEWAL',
  CARD_RESTRICTION_REPORT = 'CARD_RESTRICTION_REPORT',
  CARD_CASHLESS_PAYMENT = 'CARD_CASHLESS_PAYMENT',
  CARD_TEMPORARY_BLOCKING = 'CARD_TEMPORARY_BLOCKING',
  CARD_BLOCKING_REPAYMENT_ARREARS = 'CARD_BLOCKING_REPAYMENT_ARREARS',
  CARD_SERVICE_MONTHLY = 'CARD_SERVICE_MONTHLY',
  ATM_CHECKING_BALANCE = 'ATM_CHECKING_BALANCE',
  ACTIVATING_DIGITAL_PAY_SERVICE = 'ACTIVATING_DIGITAL_PAY_SERVICE',
  TAF_ATM_WITHDRAWAL = 'TAF_ATM_WITHDRAWAL',
  TAF_ONLINE_FAST_CASH = 'TAF_ONLINE_FAST_CASH',
  TAF_ONLINE_TRANSFER = 'TAF_ONLINE_TRANSFER',
  TAF_FAST_CASH_HELPLINE = 'TAF_FAST_CASH_HELPLINE',
  TAF_TRANSFER_ORDER_HELPLINE = 'TAF_TRANSFER_ORDER_HELPLINE',
  TAF_ONLINE_REPAYMENT = 'TAF_ONLINE_REPAYMENT',
  MAINTAINING_PAYMENT_ACCOUNT = 'MAINTAINING_PAYMENT_ACCOUNT',
  PC_SHIPPING = 'PC_SHIPPING',
  PC_ISSUANCE_1ST_YEAR = 'PC_ISSUANCE_1ST_YEAR',
  PC_MONTHLY_FROM_2ND_YEAR = 'PC_MONTHLY_FROM_2ND_YEAR',
  PC_ISSUANCE = 'PC_ISSUANCE',
  VC_ISSUANCE_1ST_YEAR = 'VC_ISSUANCE_1ST_YEAR',
  VC_MONTHLY_FROM_2ND_YEAR = 'VC_MONTHLY_FROM_2ND_YEAR',
  VC_ISSUANCE = 'VC_ISSUANCE',
  ISSUING_PAYMENT_ACCOUNT_CERTIFICATE = 'ISSUING_PAYMENT_ACCOUNT_CERTIFICATE',
  PIN_CHANGE_ATM = 'PIN_CHANGE_ATM',
  PIN_CHANGE_CUSTOMER_PANEL = 'PIN_CHANGE_CUSTOMER_PANEL',
  PIN_ASSIGNING = 'PIN_ASSIGNING',
  PIN_SENDING = 'PIN_SENDING',
  DUPLICATE_STATEMENT_ONLINE = 'DUPLICATE_STATEMENT_ONLINE',
  SMS_NOTIFICATION = 'SMS_NOTIFICATION',
  DEDICATED_EXPRESS_HOTLINE = 'DEDICATED_EXPRESS_HOTLINE',
  QUICK_CONSULTATION_ORDER = 'QUICK_CONSULTATION_ORDER',
}
