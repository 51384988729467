import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksRefreshService implements OnDestroy {

  public reloadTaskGroupsListModel$ = new Subject<void>();
  public openTasksForMailbox$ = new Subject<string>();

  reloadTaskGroupListModel() {
    this.reloadTaskGroupsListModel$.next();
  }

  openTasksForMailbox(mailbox: string) {
    this.openTasksForMailbox$.next(mailbox);
  }

  ngOnDestroy(): void {
    this.reloadTaskGroupsListModel$.unsubscribe();
    this.openTasksForMailbox$.unsubscribe();
  }
}
