export * from './lib/core.module';
export * from './lib/modules/loans/loans.module';
export * from './lib/modules/clients/clients.module';
export * from './lib/modules/tasks/tasks.module';
export * from './lib/modules/applications/applications.module';
export * from './lib/modules/payments/payments.module';
export * from './lib/modules/payment-distributions/payment-distributions.module';
export * from './lib/modules/transfers/transfers.module';
export * from './lib/modules/communications/communications.module';
export * from './lib/modules/activity-overview/activity-overview.module';
export * from './lib/modules/system-configs/system-configs.module';
export * from './lib/modules/product-configs/product-configs.module';
export * from './lib/modules/risk-evaluation-configs/risk-evaluation-configs.module';
export * from './lib/modules/message-templates/message-templates.module';
export * from './lib/modules/file-templates/file-templates.module';
export * from './lib/modules/notification-configs/notification-configs.module';
export * from './lib/modules/translation-manager/translation-manager.module';
export * from './lib/modules/discount-campaigns/discount-campaigns.module';
export * from './lib/modules/blacklist/blacklist.module';
export * from './lib/modules/about/about.module';
export * from './lib/modules/konsole/konsole.module';
export * from './lib/modules/batch-job/batch-job.module';
export * from './lib/modules/users/users.module';
export * from './lib/modules/debt-configs/debt-configs.module';
export * from './lib/modules/affiliates/affiliates.module';
export * from './lib/modules/application-without-confirmed-otp/application-without-confirmed-otp.module';
export * from './lib/modules/sale-partners/sale-partners.module';
export * from './lib/modules/loan-sale/loan-sale.module';
