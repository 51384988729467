<form [formGroup]="clientIdentityDocumentAddForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Create client identity document</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="number" class="col-sm-4 control-label">Number<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="number" formControlName="number">
      </div>
    </div>
    <div class="form-group row">
      <label for="issueDate" class="col-sm-4 control-label">Issue date<sup class="text-danger">*</sup></label>
      <div class="input-group col-sm-8">
        <input class="form-control form-control-sm"
               id="issueDate"
               formControlName="issueDate"
               placeholder="yyyy-mm-dd"
               ngbDatepicker
               #issueDate="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="issueDate.toggle()" type="button"></button>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="expiryDate" class="col-sm-4 control-label">Expiry date</label>
      <div class="input-group col-sm-8">
        <input class="form-control form-control-sm"
               id="expiryDate"
               formControlName="expiryDate"
               placeholder="yyyy-mm-dd"
               ngbDatepicker
               #expiryDate="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="expiryDate.toggle()" type="button"></button>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="isWithoutExpiryDate" class="col-sm-4 control-label">Without expiry date</label>
      <div class="col-sm-8">
        <input type="checkbox" class="form-control form-control-sm" id="isWithoutExpiryDate" formControlName="isWithoutExpiryDate">
      </div>
    </div>
    <div class="form-group row">
      <label for="authority" class="col-sm-4 control-label">Authority</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="authority" formControlName="authority">
      </div>
    </div>
    <div class="form-group row">
      <label for="authorityCountry" class="col-sm-4 control-label">Authority country</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control form-control-sm"
               id="authorityCountry"
               formControlName="authorityCountry">
      </div>
    </div>
    <div class="form-group row">
      <label for="authorityCode" class="col-sm-4 control-label">Authority Code</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control form-control-sm"
               id="authorityCode"
               formControlName="authorityCode">
      </div>
    </div>
    <div class="form-group row">
      <label for="active" class="col-sm-4 control-label">Is active</label>
      <div class="col-sm-8">
        <input type="checkbox" class="form-control form-control-sm" id="active" formControlName="active" name="active">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="clientIdentityDocumentAddForm.invalid">Save</button>
  </div>
</form>


