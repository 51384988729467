import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlPiClientCardService } from '../../services/pl-pi-client-card.service';
import { CardChangeState, CardStatusChangeRequest } from '../../api/client.card';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-card-change-status',
  templateUrl: './pl-pi-client-card-change-status.component.html',
  styleUrls: ['./pl-pi-client-card-change-status.component.css']
})
export class PlPiClientCardChangeStatusComponent extends BasicComponent implements OnInit {

  @Input() cardId: number | undefined;
  @Input() state: CardChangeState;
  @Input() title: string;
  reasons: string[];
  cardStatusChangeForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private plPiClientCardService: PlPiClientCardService,
  ) {
    super();
  }

  ngOnInit(): void {
    if(this.state === CardChangeState.UNFREEZE) {
      this.getUnblockReasons();
    } else {
      this.getBlockReasons();
    }
    this.cardStatusChangeForm = this.formBuilder.group({
      reason: ['', Validators.required],
    });
  }

  getBlockReasons() {
    this.plPiClientCardService.getCardStatusBlockingReasons().subscribe(reasons => {
      this.reasons = reasons;
    })
  }

  getUnblockReasons() {
    this.plPiClientCardService.getCardStatusUnblockingReasons().subscribe(reasons => {
      this.reasons = reasons;
    })
  }

  submitForm() {
    const request: CardStatusChangeRequest = {
      reason: this.cardStatusChangeForm.value.reason
    }
    switch(this.state) {
      case CardChangeState.FREEZE: {
        this.plPiClientCardService.freezeCard(this.cardId, request).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.success();
        });
        break;
      }
      case CardChangeState.UNFREEZE: {
        this.plPiClientCardService.unblockCard(this.cardId, request).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.success();
        });
        break;
      }
      case CardChangeState.CLOSE: {
        this.plPiClientCardService.closeCard(this.cardId, request).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.success();
        });
        break;
      }
    }
  }

  success() {
    this.alertService.notifySuccess(`Card status changed`);
    this.activeModal.close();
  }
}
