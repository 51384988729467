<div class="modal-header">
  <h4 class="modal-title">Block client #{{clientId}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="message">Message</label>
    <textarea id="message" class="form-control" [formControl]="messageFormControl"></textarea>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close()">Close</button>
  <button
    type="button"
    class="btn btn-danger"
    [disabled]="messageFormControl.invalid"
    (click)="activeModal.close(messageFormControl.value)"
  >OK</button>
</div>

