import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  EnumResponse, Payment, RepaymentProposal,
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';
import { CreateRepaymentProposalRequest } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class RepaymentProposalService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(loanId: number): Observable<RepaymentProposal[]> {
    return this.get(`/api/repayment-proposals/list/${loanId}`);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/repayment-proposals/enum-values/${enumClass}`);
  }

  createRepaymentProposal(createRepaymentProposalRequest: CreateRepaymentProposalRequest) {
    return this.post(`/api/repayment-proposals/create`, createRepaymentProposalRequest);
  }

  enableRepaymentProposal(repaymentProposalId: number) {
    return this.patch(`/api/repayment-proposals/${repaymentProposalId}/enable`, {});
  }

  disableRepaymentProposal(repaymentProposalId: number) {
    return this.patch(`/api/repayment-proposals/${repaymentProposalId}/disable`, {});
  }

  getMinRepaymentAmount(loanId: number): Observable<number> {
    return this.get(`/api/repayment-proposals/${loanId}/min-amount`);
  }

  hasActiveProposal(loanId: number): Observable<boolean> {
    return this.get(`/api/repayment-proposals/${loanId}/active`);
  }
}
