import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SystemConfigsRoutingModule } from './system-configs-routing.module';
import { SystemConfigListComponent } from './components/system-config-list/system-config-list.component';
import { SystemConfigEditComponent } from './components/system-config-edit/system-config-edit.component';
import { SystemConfigAddComponent } from './components/system-config-add/system-config-add.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';


@NgModule({
  declarations: [SystemConfigListComponent, SystemConfigEditComponent, SystemConfigAddComponent],
  imports: [
    CommonModule,
    CommonsModule,
    TranslateModule,
    ReactiveFormsModule,
    SystemConfigsRoutingModule,
    NgJsonEditorModule
  ]
})
export class SystemConfigsModule { }
