import { Type } from '@angular/core';
// noinspection ES6PreferShortImport
import { DefaultTaskViewComponent } from '../../components/tasks/default-task-view/default-task-view.component';
// noinspection ES6PreferShortImport
import { TaskViewComponent } from '../../components/tasks/task.view.component';
import { DebtTaskViewComponent } from '../../components/tasks/debt-task-view/debt-task-view.component';

export abstract class TaskViewComponentResolver {

  abstract resolve(topic: string): Type<TaskViewComponent>

  protected defaultResolution(topic: string): Type<TaskViewComponent> {
    switch (topic) {
      case "Debt: Call relative":
      case "Debt: Call client":
      case "Debt: Call friend":
      case "Debt: Call employer":
      case "Debt: Call colleague":
      case "Debt: Message FB friends":
      case "Debt: Message client":
      case "Debt: Call to client":
        return DebtTaskViewComponent
      case "ReEvaluate Application":
      case "Risk: Client document verification":
      case "Risk: Client verification calls":
      case "Collect client data":
      case "FAILED transfer":
      case  "unfinished registration":
      case  "auto communication for unfinished application":
      default:
        return DefaultTaskViewComponent
    }
  }
}
