import { ClientSearchOption, FullClient } from '@twino/backoffice-api';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, flatMap, map, take, tap } from 'rxjs/operators';
// noinspection ES6PreferShortImport
import { ClientService } from '../../../services/client.service';
import { Resettable } from '../../resettable-component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'client-lookup',
  templateUrl: './client-lookup.component.html',
  styleUrls: ['./client-lookup.component.scss']
})
export class ClientLookupComponent implements Resettable, OnInit, OnDestroy {

  public static MIN_SYMBOLS = 3;
  public static DEBOUNCE_DELAY = 500;
  public readonly ClientSearchOption = ClientSearchOption;

  public clientSearchForm = new FormGroup({
    searchKey:  new FormControl(''),
    searchValue: new FormControl({value: '', disabled: true}),
  });

  @Input() resetEvent: EventEmitter<void>;
  @Input() formattedProperty = 'number';
  @Input() returnedProperty = 'id';
  @Input() clearAfterSelected = false;
  resetEventSubscription: Subscription;

  modelDisplayName: string;
  loading = false;

  @ViewChild(NgbPopover, {static: false}) popover: NgbPopover;
  @Output() clientSelected = new EventEmitter<string>();

  constructor(private clientService: ClientService) {
  }

  search = (text$: Observable<string>) => text$.pipe(
    debounceTime(ClientLookupComponent.DEBOUNCE_DELAY),
    flatMap(term => {
      if (term.length < ClientLookupComponent.MIN_SYMBOLS) {
        this.loading = false;
        this.popover.open();
        return of({results: []});
      } else {
        this.loading = true;
        this.popover.close();
        const searchKey = this.clientSearchForm.get('searchKey').value, searchValue = this.clientSearchForm.get('searchValue').value.trim();
        return this.clientService.findClientsByText(searchKey, searchValue);
      }
    })
  )
    .pipe(map((response) => response?.results || []))
    .pipe(tap(() => this.loading = false))

  focusOut() {
    this.loading = false;
    this.popover.close();
  }

  formatter = (client: FullClient) => client[this.formattedProperty];

  itemSelected($event: NgbTypeaheadSelectItemEvent, input: HTMLInputElement) {
    if (this.clearAfterSelected) {
      $event.preventDefault();
      input.value = '';
    };
    if ($event.item?.id) this.clientSelected.emit($event.item[this.returnedProperty]);
  }

  resetContent(): void {
    this.modelDisplayName = null;
  }

  ngOnInit(): void {
    this.clientSearchForm.get('searchKey').valueChanges.pipe(take(1))
      .subscribe((value) => {
      if (value) {
        this.clientSearchForm.get('searchValue').enable();
      }
    });

    this.resetEventSubscription = this.resetEvent?.subscribe(() => this.resetContent());
  }

  ngOnDestroy(): void {
    this.resetEventSubscription?.unsubscribe();
  }

  containsSpace(s) {
    return /\s/g.test(s);
  }
}
