import { HttpClient } from '@angular/common/http';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InitialData } from '../modules/phone-application/api/initial-data';
import { PlPiFullClient } from '../api/pl-pi-full-client';
import { CompanyData } from '../api/pl-pi-client-details';
import { InitialInfoResponse, IsOperationChargeableResponse } from '../api/pl-pi-client';
import { PersonalInformationRequest } from '../modules/phone-application/api/personal-information';
import { ConsentsToConfirm } from '../models/PlPiConsents';
import { FeeType } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class PlPiClientService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getClient(id: number): Observable<PlPiFullClient> {
    return this.get(`/api/pl/client/${id}`);
  }

  getClientInitialInfo(id: number): Observable<InitialInfoResponse>  {
    return this.get(`/api/pl/client/${id}/initial-info`);
  }

  getClientPersonalInformation(id: number): Observable<PersonalInformationRequest> {
    return this.get(`/api/pl/client/${id}/personal-info`)
  }

  getClientAddressInformation(id: number): Observable<never> {
    return this.get(`/api/pl/client/${id}/address-info`)
  }

  getClientFinancialInformation(id: number): Observable<never> {
    return this.get(`/api/pl/client/${id}/financial-info`)
  }

  creatingLoanApplication(id: number, data: InitialData): Observable<never> {
    return this.post(`/api/pl/client/${id}/migration/loan-application`, data);
  }

  getClientDataInTheOldSystem(id: number): Observable<never> {
    return this.get(`/api/pl/client/old-ncpl/${id}/client-data`);
  }

  getCompanyData(taxIdentificationNumber: string): Observable<CompanyData> {
    return this.get(`/api/pl/client/ceidg/${taxIdentificationNumber}`);
  }

  getConsentsToConfirm(id: number, brand: string): Observable<ConsentsToConfirm> {
    return this.get(`/api/pl/client/${id}/consents/${brand}`)
  }

  isOperationChargeable(id: number, feeType: FeeType): Observable<IsOperationChargeableResponse> {
    return this.get(`/api/pl/client/${id}/chargeable-operations/${feeType}/is-chargeable`)
  }
}
