import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsRefreshService implements OnDestroy{

  public reloadClientListModel$ = new Subject<void>();
  public reloadClientModel$ = new Subject<void>();
  public reloadClientDetailsModel$ = new Subject<void>();
  public reloadClientCommunicationModel$ = new Subject<void>();
  public reloadClientDocumentModel$ = new Subject<void>();
  public reloadClientAddressModel$ = new Subject<void>();

  reloadClientListModel() {
    this.reloadClientListModel$.next();
  }

  reloadClientModel() {
    this.reloadClientModel$.next();
  }

  reloadClientDetailsModel() {
    this.reloadClientDetailsModel$.next();
  }

  reloadClientCommunicationModel() {
    this.reloadClientCommunicationModel$.next();
  }

  reloadClientDocumentModel() {
    this.reloadClientDocumentModel$.next();
  }

  reloadClientAddressModel() {
    this.reloadClientAddressModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadClientListModel$.unsubscribe();
    this.reloadClientModel$.unsubscribe();
    this.reloadClientDetailsModel$.unsubscribe();
    this.reloadClientCommunicationModel$.unsubscribe();
    this.reloadClientDocumentModel$.unsubscribe();
    this.reloadClientAddressModel$.unsubscribe();
  }
}
