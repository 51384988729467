import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';
import {
  DebtStepConfig, DebtStepConfigRequest,
  DebtStepExecutionResult, EnumResponse,
  PerformDebtOperationRequest, SearchQuery, SearchQueryAware, SearchResponse
} from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class DebtConfigService extends BaseService implements SearchQueryAware<DebtStepConfig> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<DebtStepConfig>> {
    return this.post(`/api/debt/configs/search`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return undefined;
  }

  listDebtConfig(): Observable<Array<DebtStepConfig>> {
    return this.get(`/api/debt/configs`);
  }

  listDebtStepExecutionResults(loanId: number): Observable<Array<DebtStepExecutionResult>> {
    return this.get(`/api/debt/${loanId}`);
  }

  getConfig(id: number): Observable<DebtStepConfig> {
    return this.get(`/api/debt/configs/${id}`);
  }

  listRuleTypes(): Observable<string[]> {
    return this.get(`/api/debt/configs/rule-types`);
  }

  addDebtStep(debtStepConfigRequest: DebtStepConfigRequest): Observable<void> {
    return this.post(`/api/debt/configs`, debtStepConfigRequest);
  }

  updateDebtStep(id: number, debtStepConfigRequest: DebtStepConfigRequest){
    return this.patch(`/api/debt/configs/${id}`, debtStepConfigRequest);
  }

  deleteDebtStepConfig(id: number) {
    return this.delete(`/api/debt/configs/${id}`, {});
  }

  performDebtStepForLoan(request: PerformDebtOperationRequest): Observable<void> {
    return this.post(`/api/debt`, request);
  }
}
