import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileTemplatesRefreshService implements OnDestroy{

  public reloadFileTemplatesListModel$ = new Subject<void>();

  reloadFileTemplatesListModel() {
    this.reloadFileTemplatesListModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadFileTemplatesListModel$.unsubscribe();
  }
}
