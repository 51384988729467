<div class="modal-header">
  <h4 class="modal-title">Commissions - {{mode | uppercase}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <table class="table table-striped table-hover mt-2" *ngIf="mode === 'agreement'">
      <tbody>
      <tr>
        <th>Account type</th>
        <th>Commission name</th>
        <th>Rate per year</th>
        <th>Tax rate</th>
        <th>Rate per day</th>
      </tr>
      <tr *ngFor="let item of commissions; trackBy: trackById">
        <td>{{item.accountType}}</td>
        <td>{{item.commissionName}}</td>
        <td>{{item.ratePerYearInPercent}}</td>
        <td>{{item.taxRate}}</td>
        <td>{{item.ratePerDay}}</td>
      </tr>
      </tbody>
    </table>
    <table class="table table-striped table-hover mt-2" *ngIf="mode === 'schedule'">
      <tbody>
      <tr>
        <th>Account type</th>
        <th>Commission name</th>
        <th>Total amount</th>
        <th>Open amount</th>
        <th>Open tax included amount</th>
        <th>Total tax included amount</th>
      </tr>
      <tr *ngFor="let item of commissions; trackBy: trackById">
        <td>{{item.accountType}}</td>
        <td>{{item.commissionName}}</td>
        <td>{{item.totalAmount}}</td>
        <td>{{item.openAmount}}</td>
        <td>{{item.openTaxIncludedAmount}}</td>
        <td>{{item.totalTaxIncludedAmount}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
</div>




