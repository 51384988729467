import { Component, OnInit } from '@angular/core';
// noinspection ES6PreferShortImport
import { UserService } from '../../services/user.service';
// noinspection ES6PreferShortImport
import { OverlayService } from '../../services/overlay.service';
import { finalize } from 'rxjs/operators';
import { ErrorsService } from '../../services/errors.service';
import { ProjectData, UserCredentials } from '@backoffice-monorepo/api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'backoffice-monorepo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  return: string = null;
  error: string;
  user: UserCredentials = new UserCredentials();
  projectName: string;
  projectVersion: string;
  apiVersion: string;

  constructor(
    private userService: UserService,
    private errorsService: ErrorsService,
    private overlayService: OverlayService,
    private router: Router,
    private route: ActivatedRoute,
    private projectData: ProjectData
  ) {
    this.projectName = projectData.projectName
    this.projectVersion = projectData.backofficeMonorepoVersion
    this.apiVersion = projectData.backofficeMonorepoApiVersion
  }

  login() {
    const formData = {
      username: this.user.name,
      password: this.user.password
    };

    this.error = '';
    this.overlayService.showOverlay();
    this.userService.login(formData)
      .pipe(finalize(() => this.overlayService.hideOverlay()))
      .subscribe(
        (response) => {
          this.userService.authenticate(this.user.name, response);
          this.router.navigateByUrl(this.return);
        },
        (error) => {
          console.error('error', error);
          if (error.status === 401) {
            this.error = 'Failed to login';
          } else if (error.status === 400) {
            this.error = 'Failed to login, bad request';
          } else if (error.status === 500) {
            this.error = `Failed to login, ${error.message}`;
          } else {
            this.error = 'Failed to login, unexpected error';
          }
        })
  }

  ngOnInit() {
    // Get the query params
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/home');
  }
}
