import { Component, Host, OnInit } from '@angular/core';
import { ConfirmDialogueComponent, FilteringService, ListComponent, SalePartnerService } from '@backoffice-monorepo/commons';
import { LoanSalePartner, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalePartnerComponent } from '../sale-partner/sale-partner.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-sale-partners-list',
  templateUrl: './sale-partners-list.component.html',
  styleUrls: ['./sale-partners-list.component.css'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<LoanSalePartner>) => new FilteringService(listService),
      deps: [SalePartnerService]
    }
  ]
})
export class SalePartnersListComponent extends ListComponent<LoanSalePartner> implements OnInit {

  constructor(
    @Host() filteringService: FilteringService<LoanSalePartner>,
    protected router: Router,
    private salePartnerService: SalePartnerService,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  navigateTo(id: string | number) {
    this.router.navigate([`/sale-partners/${id}`]);
  }

  getName() {
    return 'Sale partners';
  }

  addPartner() {
    const modalRef = this.modalService.open(SalePartnerComponent);
    modalRef.componentInstance.isEdit = false;
    modalRef.result.then(
      () => this.refresh(),
      () => {}
    )
  }

  editPartner(partner: LoanSalePartner) {
    const modalRef = this.modalService.open(SalePartnerComponent);
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.partner = partner;
    modalRef.result.then(
      () => this.refresh(),
      () => {}
    )
  }

  deletePartner(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Delete sale partner";
    modalRef.componentInstance.content = `Are you sure you want to delete partner #${id}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
         /* this.salePartnerService.deletePartner(id).pipe(
            takeUntil(this.$destroy)
          )
            .subscribe(() => this.refresh());*/
        }
      }
    );
  }
}
