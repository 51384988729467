import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransferRefreshService implements OnDestroy{

  public reloadTransfersListModel$ = new Subject<void>();

  reloadTransfersListModel() {
    this.reloadTransfersListModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadTransfersListModel$.unsubscribe();
  }
}
