export interface ProductConfigurationList {
  productType: string
  subType: string
  brand: string
}

export interface ProductConfigurationRequest {
  name: string
  data: Partial<ProductConfiguration>
}

export interface ProductConfiguration {
  productType: string
  subType: string
}

export enum ProductType {
  SHORT_TERM_FIRST_LOAN = 'SHORT_TERM_FIRST_LOAN',
  SHORT_TERM = 'SHORT_TERM',
  CREDIT_LINE = 'CREDIT_LINE'
}


export interface ProductConfigurationsToVersionsMatchingCheckResponse {
  productConfigurationsMatchVersions: ProductConfigurationToVersionMatch[]
}

export interface ProductConfigurationToVersionMatch {
  productConfigurationId: number
  productType: ProductType
  subType: string
  brand: string
  matchesProductConfigurationVersion: boolean
}
