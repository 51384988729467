import { Component, OnInit } from '@angular/core';
import { AlertsService, DiscountCampaignService, NamedComponent } from '@backoffice-monorepo/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignShortInfo, ExcelFileProcessingStatus } from '@twino/backoffice-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-discount-campaigns-import',
  templateUrl: './discount-campaigns-import.component.html',
  styleUrls: ['./discount-campaigns-import.component.css']
})
export class DiscountCampaignsImportComponent extends NamedComponent implements OnInit {

  activeCampaigns: CampaignShortInfo[];
  clientListImportForm: FormGroup;
  fileData?: File;
  fileName?: string;
  processingStatus: ExcelFileProcessingStatus | null;

  constructor(
    protected router: Router,
    activatedRoute: ActivatedRoute,
    private discountCampaignService: DiscountCampaignService,
    private formBuilder: FormBuilder,
    private alertService: AlertsService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.refresh();
    this.clientListImportForm = this.formBuilder.group({
      file: ['', Validators.required],
      typeId: ['', Validators.required],
    });
  }

  refresh() {
    forkJoin([
      this.discountCampaignService.listActiveCampaigns(),
      this.discountCampaignService.getStatus(),
    ])
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(([activeCampaigns, processingStatus]) => {
        this.activeCampaigns = activeCampaigns;
        this.processingStatus = processingStatus;
      });
  }

  getName(): string {
    return `Import clients list`;
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  submitForm() {
    this.discountCampaignService.addCampaignToClients(this.clientListImportForm.value.typeId,this.fileData).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(() => {
        this.refresh();
        this.fileData = null;
        this.alertService.notifySuccess(`Clients list imported, will be processed`);
      }, () => { this.fileData = null; })
  }
}
