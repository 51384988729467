export class ClientComment {
  id: number
  clientId: number
  comment: string
  commenterUser: string
  created: Date
}


export class ClientCommentRequest {
  comment: string
  commenterUser: string
  clientId: number
}
