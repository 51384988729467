import { Component } from '@angular/core';
import {
  CountryViewComponent,
  RATIO_AS_PERCENT,
  TransformInstructions,
  TransformInstructionsBuilder
} from '@backoffice-monorepo/commons';
import { Agreement } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-pl-pi-current-loan-agreement',
  templateUrl: './pl-pi-current-loan-agreement.component.html',
  styleUrls: ['./pl-pi-current-loan-agreement.component.css']
})
export class PlPiCurrentLoanAgreementComponent implements CountryViewComponent {

  data: Agreement | never;

  currentAgreementTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['mtpRatio', ['MTP ratio', RATIO_AS_PERCENT]],
    ]
  );

  constructor() { }
}
