
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationWithoutConfirmedOtpListComponent } from './components/application-without-confirmed-otp-list/application-without-confirmed-otp-list.component';

const routes: Routes = [
  { path: '', component: ApplicationWithoutConfirmedOtpListComponent, pathMatch: 'full'}
]

@NgModule ({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ApplicationWithoutConfirmedOtpRoutingModule {
}
