import { ProductConfig } from '@twino/backoffice-api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocalesService {

  constructor(private productConfig: ProductConfig) {}

  getLocales(): Observable<string[]> {
    return new Observable((observer) => observer.next(this.productConfig.locales));
  }
}
