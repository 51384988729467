export class EmploymentInfo {
  clientId: number
  employmentStatus: EmploymentStatus
  workplace?: string
  workPhoneNumber?: string
  colleaguePhoneNumber?: string
  employmentDurationInMonth?: number
  jobTitle?: string
}


export class ClientEmploymentInfoRequest {
  employmentStatus: string
  workplace?: string
  workPhoneNumber?: string
  colleaguePhoneNumber?: string
  employmentDurationInMonth?: number
}


export enum EmploymentStatus {
  WORKING = 'WORKING',
  INDIVIDUAL_ENTERPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  PENSIONER = 'PENSIONER',
  SOCIAL_BENEFICIARIES = 'SOCIAL_BENEFICIARIES',
  STUDENT = 'STUDENT',
  UNEMPLOYED = 'UNEMPLOYED'
}
