<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Client Id" type="number" property="clientId"></tr>
  <tr filter-element title="Loan Id" type="number" property="loanId"></tr>
  <tr filter-element title="Entity Id" type="number" property="entityId"></tr>
  <tr filter-element
      title="Type"
      type="enum"
      property="type"
      enum="type"
  ></tr>
  </tbody>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th>Client ID</th>
      <th>Loan ID</th>
      <th>Type</th>
      <th>Entity ID</th>
      <th>Topic</th>
      <th>Status</th>
      <th>Direction</th>
      <th>Assigned User</th>
      <th>Resolution</th>
      <th>Amount</th>
      <th>Subject</th>
      <th>Content</th>
      <th>Promise date</th>
      <th>Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of response?.results; trackBy: trackById">
      <td></td>
      <td><a *ngIf="item.clientId" routerLink="/clients/{{item.clientId}}">{{item.clientId}}</a></td>
      <td><a *ngIf="item.loanId" routerLink="/loans/{{item.loanId}}">{{item.loanId}}</a></td>
      <td>{{item.type}}</td>
      <td>{{item.entityId}}</td>
      <td>{{item.topic}}</td>
      <td>{{item.status}}</td>
      <td>{{item.direction}}</td>
      <td>{{item.assignedUser}}</td>
      <td>{{item.resolution}}</td>
      <td>{{item.amount}}</td>
      <td>{{item.subject}}</td>
      <td>
        <button type="button"
                *ngIf="item.type.includes('COMMUNICATION')"
                class="btn btn-outline-primary btn-sm"
                (click)="viewContent(item.entityId)"
                title="View content">
          <bi name="envelope" *ngIf="item.type === CommunicationType.EMAIL || item.type === CommunicationType.LETTER"></bi>
          <bi name="chat-square-text" *ngIf="item.type !== CommunicationType.EMAIL && item.type !== CommunicationType.LETTER"></bi>
        </button>
        <button type="button"
                *ngIf="item.type === ActivityOverviewType.COMMENT"
                class="btn btn-outline-primary btn-sm"
                placement="left"
                ngbPopover="{{item.content}}"
                popoverTitle="Comment content">
          <bi name="chat-text"></bi>
        </button>
      </td>
      <td>{{item.promiseDate}}</td>
      <td>{{item.created}}</td>
    </tr>
    </tbody>
  </table>
</server-table>



