import { Component, Input, Optional, SkipSelf } from '@angular/core';
// noinspection ES6PreferShortImport
import { FilteringService } from '../../../services/filtering.service';

@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @Input() id = 'server-search-filter-dropdown';

  constructor(
    @SkipSelf() @Optional() private filteringService: FilteringService<never>
  ) {
  }

  resetFilters() {
    this.filteringService?.reset();
  }

  apply() {
    this.filteringService?.applyFilters();
  }

}
