import { Component, OnInit } from '@angular/core';
import { CountrySpecific } from '../../../../../../libs/commons/src/lib/services/country-specific';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, DateUtils, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransferRefreshService } from '../../../../../../libs/core/src/lib/modules/transfers/services/transfer-refresh.service';
import { PlPiTransferService } from '../../services/pl-pi-transfer.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-transfer-add',
  templateUrl: './pl-pi-transfer-add.component.html',
  styleUrls: ['./pl-pi-transfer-add.component.css'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class PlPiTransferAddComponent extends BasicComponent implements OnInit, CountrySpecific {

  transferAddForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private transferService: PlPiTransferService,
    private formBuilder: FormBuilder,
    private transfersRefreshService: TransferRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.transferAddForm = this.formBuilder.group({
      clientId: ['', Validators.required],
      timestamp: ['', Validators.required],
      amount: ['', Validators.required],
      fromAccount: ['', Validators.required],
      toAccount: ['', Validators.required],
      purpose: ['', Validators.required],
      comment: ['', Validators.required],
      offerId: [''],
    });
  }


  submitForm() {
    this.transferAddForm.value.timestamp = DateUtils.datePlusHoursWithFormat(3, this.transferAddForm.value.timestamp);
    this.transferService.createTransfer(this.transferAddForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Transfer created`);
      this.transfersRefreshService.reloadTransfersListModel();
      this.activeModal.close();
    })
  }
}
