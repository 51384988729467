import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { PlPiLoanService } from '../../services/pl-pi-loan.service';
import { LoansRefreshService } from '../../../../../../libs/core/src/lib/modules/loans/services/loans-refresh-services';
import { LoanStatusDetailForClosure } from '../../api/pl-pi-loan';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-close',
  templateUrl: './pl-pi-loan-close.component.html',
  styleUrls: ['./pl-pi-loan-close.component.css'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class PlPiLoanCloseComponent extends BasicComponent implements OnInit {

  @Input() loanId: number;
  loanClosureForm: FormGroup;
  loanStatusDetailsForClosure = LoanStatusDetailForClosure;
  loanStatusDetailsOptions = [];

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private loanService: PlPiLoanService,
    private formBuilder: FormBuilder,
    private loanRefreshService: LoansRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loanStatusDetailsOptions = Object.keys(this.loanStatusDetailsForClosure);
    this.loanClosureForm = this.formBuilder.group({
      loanStatusDetail: ['', Validators.required],
      terminationStartDate: ['', Validators.required],
    });
  }

  submitForm() {
    this.loanService.createLoanTerminationAgreement(this.loanId, this.loanClosureForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Loan termination agreement created`);
      this.loanRefreshService.reloadLoanModel();
      this.activeModal.close();
    })
  }
}
