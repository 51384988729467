<form [formGroup]="loanProlongationResignationForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Cancel loan prolongation resignation</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
     <div class="form-group row">
      <label for="cancellationCause" class="col-sm-4 control-label">Cancellation cause<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="cancellationCause" formControlName="cancellationCause"
               name="cancellationCause">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!loanProlongationResignationForm.valid">Cancel</button>
  </div>
</form>
