<p class="mt-2 ml-3">
  <b>Loans</b>
</p>
<!-- region buttons -->
<div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
</div>
<!-- endregion -->
<div class="container-fluid row pt-1 pb-2">
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>ID</th>
          <th>Number</th>
          <th>Client ID</th>
          <th>Status</th>
          <th>Status Detail</th>
          <th>Agreement</th>
          <th>Debt Step</th>
          <th>Close date</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of loans; trackBy: trackById ">
          <td></td>
          <td>
            <a routerLink="/loans/{{item.id}}">{{item.id}}</a>
          </td>
          <td>{{ item.loanNumber }}</td>
          <td>{{ item.clientId }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.statusDetail }}</td>
          <td>{{ item.currentAgreementType }}</td>
          <td>{{ item.currentDebtStep }}</td>
          <td>{{ item.closedDate }}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>
