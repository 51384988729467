import { Component, Host, OnInit } from '@angular/core';
import { FilteringService, ListComponent, PaymentDistributionService } from '@backoffice-monorepo/commons';
import { FilterOperation, PaymentDistribution, PaymentStatus, SearchQueryAware, SearchQueryBuilder } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'backoffice-monorepo-payment-distributions-list',
  templateUrl: './payment-distributions-list.component.html',
  styleUrls: ['./payment-distributions-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<PaymentDistribution>) => new FilteringService(listService),
      deps: [PaymentDistributionService]
    }
  ]
})
export class PaymentDistributionsListComponent extends ListComponent<PaymentDistribution> implements OnInit {

  constructor(
    @Host() filteringService: FilteringService<PaymentDistribution>,
    protected router: Router,
    private distributionService: PaymentDistributionService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    const searchQueryBuilder = new SearchQueryBuilder()
      .addFilterData({
        enumClass: "eu.twino.loans.core.payment.api.PaymentDistributionStatus",
        propertyName: "status",
        operation: FilterOperation.NOT_EQUALS,
        type: "enum",
        values: [PaymentStatus.VOIDED]
      })
    this.searchQuery = searchQueryBuilder.build();
    super.ngOnInit();
  }

  getName(): string {
    return 'Payment Distributions';
  }

  navigateTo(id: number) {
    this.router.navigate([`/payments/${id}`]);
  }
}
