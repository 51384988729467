import { Directive, ElementRef, Input } from '@angular/core';
import { UserService } from '../services/user.service';

@Directive({
  selector: '[appRequirePermission]'
})
export class RequirePermissionDirective {

  constructor(
    private elementRef: ElementRef,
    private userService: UserService) {
  }

  @Input() set appRequirePermission(permissions: string) {
    if (this.userService.hasPermission(permissions)) {
      delete this.elementRef.nativeElement.style.display;
    } else {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

}
