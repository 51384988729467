<aside class="container-fluid pt-3 pb-3 pl-1">
  <div class="col-md-12 ml-0 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
  </div>
</aside>
<server-table [totalRecords]="response?.totalRecords"
              [totalPages]="response?.totalPages"
              [currentPage]="response?.currentPage"
              [pageSize]="searchQuery?.pageSize"
              [searchQuery]="searchQuery"
              (setPage)="refresh($event)">
  <table list-table class="table table-striped table-hover mt-1">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th>Package ID</th>
      <th>Total amount</th>
      <th>Sale amount</th>
      <th>Pre-sell date</th>
      <th>Sale date</th>
      <th>Loans count</th>
      <th>New owner</th>
      <th>Pre-sell report</th>
      <th>Portfolio documents</th>
      <th>Status</th>
      <th>Sell</th>
      <th>Revert</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of response?.results">
      <td></td>
      <td>{{item.id}}</td>
      <td>{{item.totalAmount}}</td>
      <td>{{item.saleAmount}}</td>
      <td>{{item.preSaleDate}}</td>
      <td>{{item.saleDate}}</td>
      <td>{{item.loansCount}}</td>
      <td>{{item.loanSalePartnerId}}</td>
      <td>
        <a type="button" class="btn btn-outline-primary btn-sm ml-1" href="{{preSellReportUrl(item.id)}}" target="_blank">Download</a>
      </td>
      <td>
        <a type="button" *ngIf="item.saleFileExternalId" (click)="modalService.open(downloadDocuments); getLoanSaleFileSignedUrl(item.id)" class="btn btn-outline-primary btn-sm ml-1">
          Download
        </a>
      </td>
      <td>{{item.status}}</td>
      <td>
        <button appRequirePermission="admin.all,loan-sale.edit" *ngIf="item.status !== loanSalePortfolioStatus.SOLD"
                class="btn btn-success btn-sm"  (click)="sellPortfolio(item)">
          Sell
        </button>
      </td>
      <td>
        <button appRequirePermission="admin.all,loan-sale.edit" *ngIf="item.status === loanSalePortfolioStatus.PRE_SOLD"
                class="btn btn-danger btn-sm"  (click)="revertPortfolioSelling(item)">
          Revert
        </button>
      </td>
      <td></td>
    </tr>
    </tbody>
  </table>
</server-table>

<ng-template #downloadDocuments>
  <div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Download portfolio documents</h4>
  <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll('Cross click')">
    <span>&times;</span>
  </button>
  </div>
  <div class="modal-body">
    <ul class="list-group">
      <li class="list-group-item list-group-item-action d-flex align-items-center gap-3">
        <span class="mr-3"> Archive Password: </span>
        <form><input type="{{ showPassword ? 'text' : 'password' }}"  class="form-control" id="signInPassword" name="password" [value]="loanSaleFileSignedUrlResponse.archivePassword"></form>
        <i class="bi password-visibility" [ngClass]="{'bi-eye': showPassword, 'bi-eye-slash': !showPassword}" (click)="togglePasswordVisibility()"></i>
      </li>
      <li class="list-group-item list-group-item-action">
        <span class="signed-url">SignedURL:</span>
        <a type="button" [href]="loanSaleFileSignedUrlResponse.saleFileSignedUrl" target="_blank">Link</a>
      </li>
    </ul>
  </div>
  <div class="modal-footer"></div>
</ng-template>
