<div class="card mt-1" [formGroup]="parentForm">
  <div class="card-header">
  <span class="float-right">
          <select class="form-control form-control-sm ml-2"
                  (change)="addRow(selectEvent.value)"
                  #selectEvent
                  appRequirePermission="admin.all,affiliate-reports.export-data">
            <option>Choose event to add report</option>
            <option *ngFor="let key of events" [ngValue]="key" [disabled]="isDisabledEvent(key)">{{key}}</option>
          </select>
  </span>
  </div>
  <div class="card-body" formArrayName="reports">
    <div class="card mt-2" *ngFor="let group of getFormControls.controls; let i=index" [formGroupName]="i">
      <div class="card-header">
        <button class="btn btn-outline-danger btn-sm float-right" (click)="deleteRow(i,group)">Delete</button>
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col">
            <label for="type">Event</label>
            <input type="text"
                   class="form-control form-control-sm"
                   id="event"
                   formControlName="event"
                   name="event"
                   [readonly]="true">
          </div>
          <div class="col">
            <label for="type">Type<sup class="text-danger">*</sup></label>
            <select class="form-control form-control-sm" id="type" formControlName="type" name="type">
              <option *ngFor="let key of reportTypeOptions" [ngValue]="key">{{key}}</option>
            </select>
          </div>
          <div class="col">
            <label for="url">URL<sup class="text-danger">*</sup></label>
            <input type="text"
                   class="form-control form-control-sm"
                   id="url"
                   formControlName="url"
                   name="url">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
