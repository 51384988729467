<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element
      title="Status"
      type="enum"
      property="status"
      enum="status"
  ></tr>
  <tr filter-element
      title="Transaction"
      type="enum"
      property="type"
      enum="type"
  ></tr>
  <tr filter-element title="Amount" type="number" property="amount"></tr>
  <tr filter-element
      title="Booking date"
      type="date"
      property="bookingDate"
  ></tr>
  <tr filter-element title="Client Id" type="number" property="clientId"></tr>
  <tr filter-element title="Client Personal Id" type="number" property="clientPersonalId"></tr>
  <tr filter-element title="Loan Number" type="number" property="loanNumber"></tr>
  <tr filter-element title="Loan Id" type="number" property="loanId"></tr>
  <tr filter-element title="Payment Id" type="number" property="paymentId"></tr>
  <tr filter-element
      title="Create date"
      type="instant"
      property="created"
  ></tr>
  </tbody>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="paymentId" (sort)="onSort($event)">Payment</th>
      <th sortable="status" (sort)="onSort($event)">Status</th>
      <th sortable="type" (sort)="onSort($event)">Transaction type</th>
      <th sortable="amount" (sort)="onSort($event)">Amount</th>
      <th sortable="surplusAmount" (sort)="onSort($event)">Surplus</th>
      <th>Booking date</th>
      <th>From Account</th>
      <th sortable="clientId" (sort)="onSort($event)">Client ID</th>
      <th sortable="loanId" (sort)="onSort($event)">Loan</th>
      <th sortable="created" (sort)="onSort($event)">Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let paymentDistribution of response?.results; trackBy: trackById" (dblclick)="navigateTo(paymentDistribution.paymentId)">
      <td></td>
      <td>{{paymentDistribution.id}}</td>
      <td>{{paymentDistribution.paymentId}}</td>
      <td>{{paymentDistribution.status}}</td>
      <td>{{paymentDistribution.type}}</td>
      <td>{{paymentDistribution.amount}}</td>
      <td>{{paymentDistribution.surplusAmount}}</td>
      <td>{{paymentDistribution.bookingDate | date:'dd.MM.yyyy'}}</td>
      <td>{{paymentDistribution.fromAccount}}</td>
      <td><a *ngIf="paymentDistribution.clientId" routerLink="/clients/{{paymentDistribution.clientId}}">{{paymentDistribution.clientId}}</a></td>
      <td><a *ngIf="paymentDistribution.loanId" routerLink="/loans/{{paymentDistribution.loanId}}">{{paymentDistribution.loanId}}</a></td>
      <td>{{paymentDistribution.created}}</td>
    </tr>
    </tbody>
  </table>
</server-table>


