import { Component, Input, OnInit } from '@angular/core';
import { TaskViewComponent } from '../task.view.component';
import { Client, FilterOperation, FullLoan, SearchQueryBuilder, Task } from '@twino/backoffice-api';
// noinspection ES6PreferShortImport
import { AMOUNT, BOOLEAN, CLIENT, DATE, ID, LOAN, NUMBER, STRING } from '../../../services/transform/transforms';
// noinspection ES6PreferShortImport
import { ClientService } from '../../../services/client.service';
// noinspection ES6PreferShortImport
import { LoanService } from '../../../services/loan.service';
// noinspection ES6PreferShortImport
import { TransformInstructions, TransformInstructionsBuilder } from '../../../services/transform/transform.types';


@Component({
  selector: 'debt-task-view',
  templateUrl: './debt-task-view.component.html',
  styleUrls: ['./debt-task-view.component.scss']
})
export class DebtTaskViewComponent implements TaskViewComponent, OnInit {

  @Input()
  task: Task;

  client: Client;
  loan: FullLoan;

  constructor(
    private clientService: ClientService,
    private loanService: LoanService
  ) { }

  clientTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Id', CLIENT]],
      ['number', ['Number', STRING]],
      ['phone', ['mobilePhone', () => `<a href="tel://${this.client.mobilePhone}">${this.client.mobilePhone}</a>`.asHtml()]],
      ['firstName', ['First Name', STRING]],
      ['lastName', ['Last Name', STRING]],
      ['creditLimit', ['Credit Limit', STRING]],
      ['finishedDetailsStep', ['Finished Details Step', NUMBER]]
    ]
  );

  loanTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Id', LOAN]],
      ['loanNumber', ['Number', STRING]],
      ['status', ['Status', STRING]],
      ['statusDetail', ['Status Detail', STRING]],
      ['mainStartDate', ['Main Start Date', () => this.loan.mainAgreement.startDate.toString().asString()]],
      ['dueDate', ['Due Date', () => this.loan.currentAgreement.dueDate.toString().asString()]],
      ['dpd', ['Days Past Due', NUMBER]],
      ['currentDebtStep', ['Current Debt Step', NUMBER]],
      ['agreementCount', ['Agreement Count', () => this.loan.agreements.length.toString().asString()]],
      ['lenderId', ['Lender Id', NUMBER]],
      ['penaltyGenerationActive', ['Penalty Generation', BOOLEAN]]
    ]
  );

  loanNumbersTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['openAmount', ['Open Amount', AMOUNT]],
      ['principalBalance', ['Principal Balance', AMOUNT]],
      ['openInterest', ['Open Interest', AMOUNT]],
      ['openCommission', ['Open Commission', AMOUNT]],
      ['openPenalty', ['Open Penalty', AMOUNT]],
    ]
  );

  currentAgreementTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Id', ID]],
      ['startDate', ['Start Date', DATE]]
    ]
  );

  ngOnInit(): void {
    const clientSearchQuery = new SearchQueryBuilder()
      .withPageSize(1)
      .addFilter("id", FilterOperation.EQUALS, [this.task.clientId.toString()]).build();
    this.clientService.find<Client>(clientSearchQuery)
      .subscribe(response => this.client = response?.results?.find(() => true));
    this.loanService.getLoan(this.task.loanId)
      .subscribe(result => this.loan = result);
  }

}
