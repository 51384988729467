import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { ClientComponent } from './components/client/client.component';
import { ClientsListComponent } from './components/clients-list/clients-list.component';

const routes: Routes = [
  {path: ':id', component: ClientComponent, resolve: {  clientMainData: 'ClientResolver' }, runGuardsAndResolvers: 'always'},
  {path: '', component: ClientsListComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: 'clientResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {}
    }
  ]
})
export class ClientsRoutingModule {
}
