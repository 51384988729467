<form [formGroup]="abateForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">MTP abatement</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="scheduleItemId" class="col-sm-4 control-label">Schedule item ID<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="scheduleItemId" formControlName="scheduleItemId" name="scheduleItemId">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!abateForm.valid">Save</button>
  </div>
</form>
