<div class="modal-header" [ngClass]="class">
  <h4 class="modal-title">
    <bi [name]="icon"></bi>&nbsp;
    {{header}}
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('closed')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{content}}</p>
</div>
<div class="modal-footer">
  <button type="button" autofocus class="btn btn-outline-secondary" (click)="activeModal.dismiss(false)">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close(true)">Ok</button>
</div>
