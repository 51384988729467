import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationsRoutingModule } from './applications-routing.module';
import { ApplicationsListComponent } from './components/applications-list/applications-list.component';
import { ApplicationComponent } from './components/application/application.component';
import { DynamicModule } from 'ng-dynamic-component';


@NgModule({
  declarations: [ApplicationsListComponent, ApplicationComponent],
	imports: [
		CommonModule,
		ApplicationsRoutingModule,
		CommonsModule,
		NgbNavModule,
		TranslateModule,
		DynamicModule,
		NgbPopoverModule
	]
})
export class ApplicationsModule { }
