import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import {
  ConfirmDialogueComponent,
  draggableModalOptionsLarge,
  FilteringService,
  ListComponent,
  MessageTemplateService
} from '@backoffice-monorepo/commons';
import { MessageTemplate, SearchQueryAware } from '@twino/backoffice-api';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageTemplatesRefreshService } from '../../services/message-templates-refresh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageTemplateAddComponent } from '../message-template-add/message-template-add.component';
import { MessageTemplateEditComponent } from '../message-template-edit/message-template-edit.component';
import { MessageTemplateViewComponent } from '../message-template-view/message-template-view.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-message-templates-list',
  templateUrl: './message-templates-list.component.html',
  styleUrls: ['./message-templates-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<MessageTemplate>) => new FilteringService(listService),
      deps: [MessageTemplateService]
    }
  ]
})
export class MessageTemplatesListComponent extends ListComponent<MessageTemplate> implements OnInit,OnDestroy {

  private reloadMessageTemplatesListModel: Subscription;

  constructor(@Host() filteringService: FilteringService<MessageTemplate>,
              protected router: Router,
              activatedRoute: ActivatedRoute,
              private messageTemplatesService: MessageTemplateService,
              private messageTemplatesRefreshService: MessageTemplatesRefreshService,
              private modalService: NgbModal,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadMessageTemplatesListModel = this.messageTemplatesRefreshService.reloadMessageTemplatesListModel$.subscribe(() => {
      super.ngOnInit();
    })
  }

  ngOnDestroy(): void {
    this.reloadMessageTemplatesListModel.unsubscribe();
  }

  getName(): string {
    return 'Message templates';
  }

  navigateTo(id: number) {
    this.router.navigate([`/message-templates/${id}`]);
  }

  createMessageTemplate() {
    const modalRef = this.modalService.open(MessageTemplateAddComponent, {size: 'xl'});
    modalRef.result.then(
      () => this.messageTemplatesRefreshService.reloadMessageTemplatesListModel(),
      () => this.messageTemplatesRefreshService.reloadMessageTemplatesListModel()
    )
  }

  editMessageTemplate(id) {
    const modalRef = this.modalService.open(MessageTemplateEditComponent, {size: 'xl'});
    modalRef.componentInstance.id = id;
    modalRef.result.then(
      () => this.messageTemplatesRefreshService.reloadMessageTemplatesListModel(),
      () => this.messageTemplatesRefreshService.reloadMessageTemplatesListModel()
    )
  }

  reloadMessageTemplatesList() {
    this.messageTemplatesRefreshService.reloadMessageTemplatesListModel();
  }

  deleteMessageTemplate(id) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Delete message template";
    modalRef.componentInstance.content = `Are you sure you want to delete message template #${id}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.messageTemplatesService.deleteMessageTemplate(id).pipe(
            takeUntil(this.$destroy)
          )
            .subscribe(() => this.messageTemplatesRefreshService.reloadMessageTemplatesListModel());
        }
      }
    );
  }

  viewContent(messageTemplateId) {
    const modalRef = this.modalService.open(MessageTemplateViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.id = messageTemplateId;
  }

}
