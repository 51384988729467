<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">
      <div class="card">
        <div class="card-header text-light bg-primary">
          <bi name="pencil-square"></bi>
          Client
        </div>
        <div class="card-text">
          <transform-table [transformInstructions]="clientTransformations" [entity]="client"></transform-table>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header text-light bg-primary">
          <bi name="briefcase"></bi>
          Loan
        </div>
        <div class="card-text row">
          <div class="col-md-6">
            <transform-table [transformInstructions]="loanTransformations" [entity]="loan"></transform-table>
          </div>
          <div class="col-md-6">
            <transform-table [transformInstructions]="loanNumbersTransformations" [entity]="loan"></transform-table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header text-light bg-primary">
          <bi name="pencil-square"></bi>
          Current loan agreement
        </div>
        <div class="card-text">
          <transform-table [transformInstructions]="currentAgreementTransformations" [entity]="loan?.currentAgreement"></transform-table>
        </div>
      </div>
    </div>
  </div>
</div>
