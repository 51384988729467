import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorsService } from './errors.service';
import { catchError } from 'rxjs/operators';

export abstract class BaseService {

  protected constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
  ) {
  }

  protected baseUrl(url: string): string {
    return '/ws/' + url.replace(/^\/+/, '');
  }

  //TODO: use http interceptor instead
  protected handleError(errorResponse: HttpErrorResponse, autoClearError: boolean, skipErrorDialogueForHttpStatuses: number[]) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error :', errorResponse.error.message);
    } else {
      console.error('Server Side Error :', errorResponse);
    }
    this.errorsService.reportHttpError(errorResponse, autoClearError, skipErrorDialogueForHttpStatuses);
    return throwError(errorResponse);
  }

  protected get<T>(url: string, options = {}, autoClearError = true, ...skipErrorDialogueForHttpStatuses: number[]): Observable<T> {
    return this.http.get<T>(this.baseUrl(url), options)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error, autoClearError, skipErrorDialogueForHttpStatuses)));
  }

  protected post<T>(url: string, body: unknown = {}, options = {}, autoClearError = true, ...skipErrorDialogueForHttpStatuses: number[]): Observable<T> {
    return this.http.post<T>(this.baseUrl(url), body, options)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error, autoClearError, skipErrorDialogueForHttpStatuses)));
  }

  protected put<T>(url: string, body: unknown, options = {}, autoClearError = true, ...skipErrorDialogueForHttpStatuses: number[]): Observable<T> {
    return this.http.put<T>(this.baseUrl(url), body, options)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error, autoClearError, skipErrorDialogueForHttpStatuses)));
  }

  protected patch<T>(url: string, body: unknown, options = {}, autoClearError = true, ...skipErrorDialogueForHttpStatuses: number[]): Observable<T> {
    return this.http.patch<T>(this.baseUrl(url), body, options)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error, autoClearError, skipErrorDialogueForHttpStatuses)));
  }

  protected delete<T>(url: string, options = {}, autoClearError = true, ...skipErrorDialogueForHttpStatuses: number[]): Observable<T> {
    return this.http.delete<T>(this.baseUrl(url), options)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error, autoClearError, skipErrorDialogueForHttpStatuses)));
  }
}
