import { NgModule } from '@angular/core';
import { TranslationManagerModule } from '@backoffice-monorepo/core';
import { schema } from './schema';

@NgModule({
  declarations: [
  ],
  imports: [
    TranslationManagerModule.forRoot({ schema: schema } )
  ]
})
export class LocalTranslationManagerModule {
}
