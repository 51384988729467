import { Injectable, Type } from '@angular/core';
import { CountryViewComponent } from '@backoffice-monorepo/commons';
import {
  CurrentLoanAgreementComponentResolver
} from '@backoffice-monorepo/commons';
import { PlPiCurrentLoanAgreementComponent } from '../components/pl-pi-current-loan-agreement/pl-pi-current-loan-agreement.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiCurrentLoanAgreementComponentResolverService implements CurrentLoanAgreementComponentResolver {
  resolve(): Type<CountryViewComponent> {
    return PlPiCurrentLoanAgreementComponent;
  }
}
