<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element
      title="Direction"
      type="enum"
      property="direction"
      enum="direction"
  ></tr>
  <tr filter-element
      title="Status"
      type="enum"
      property="status"
      enum="status"
  ></tr>
  <tr filter-element title="Amount" type="number" property="amount"></tr>
  <tr filter-element
      title="Booking date"
      type="date"
      property="bookingDate"
  ></tr>
  <tr filter-element title="Channel name" type="string" property="channelName"></tr>
  <tr filter-element title="From account" type="string" property="fromAccount"></tr>
  <tr filter-element title="To account" type="string" property="toAccount"></tr>
  <tr filter-element title="Client Id" type="number" property="clientId"></tr>
  <tr filter-element title="Client Personal Id" type="number" property="clientPersonalId"></tr>
  <tr filter-element title="Loan Number" type="number" property="loanNumber"></tr>
  <tr filter-element
      title="Create date"
      type="instant"
      property="created"
  ></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all, payment.create-manual"
                  class="btn btn-success btn-sm"
                  (click)="createPaymentFromSurplus()">
            <bi name="plus-circle"></bi>
            Surplus payment
          </button>
    </span>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="direction" (sort)="onSort($event)">Direction</th>
      <th sortable="status" (sort)="onSort($event)">Status</th>
      <th sortable="amount" (sort)="onSort($event)">Amount</th>
      <th>Booking date</th>
      <th>Channel</th>
      <th>From Account</th>
      <th>To Account</th>
      <th sortable="clientId" (sort)="onSort($event)">Client ID</th>
      <th>Client Personal ID</th>
      <th sortable="loanId" (sort)="onSort($event)">Loan Number</th>
      <th>Details</th>
      <th>Processing</th>
      <th sortable="created" (sort)="onSort($event)">Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let payment of response?.results; trackBy: trackById" (dblclick)="navigateTo(payment.id)">
      <td></td>
      <td>{{payment.id}}</td>
      <td>{{payment.direction}}</td>
      <td>{{payment.status}}</td>
      <td>{{payment.amount}}</td>
      <td>{{payment.bookingDate | date:'dd.MM.yyyy'}}</td>
      <td>{{payment.channelName}}</td>
      <td>{{payment.fromAccount}}</td>
      <td>{{payment.toAccount}}</td>
      <td><a *ngIf="payment.clientId" routerLink="/clients/{{payment.clientId}}">{{payment.clientId}}</a></td>
      <td>{{payment.clientPersonalId}}</td>
      <td>{{payment.loanNumber}}</td>
      <td>{{payment.details}}</td>
      <td>{{payment.processingTimestamp | date:'dd.MM.yyyy HH:mm:ss'}}</td>
      <td>{{payment.created | date:'dd.MM.yyyy HH:mm:ss'}}</td>
    </tr>
    </tbody>
  </table>
</server-table>

