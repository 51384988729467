<server-table
  [withFilterBlock]="true"
  (refresh)="refresh()">
  <div buttons class="mt-1">
    <button class="btn btn-sm btn-success" (click)="onCreateClick()">
      <bi name="plus-circle"></bi>
      Create
    </button>
  </div>

  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Name" type="string" property="name"></tr>
  </tbody>

  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id">Id</th>
      <th sortable="name">Name</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let role of roles | async; trackBy: trackById" (dblclick)="onEditClick(role.id)">
        <td>
          <button class="btn btn-sm btn-danger" (click)="onDeleteClick(role.id)">Delete</button>
        </td>
        <td>{{role.id}}</td>
        <td>{{role.name}}</td>
      </tr>
    </tbody>
  </table>
</server-table>
