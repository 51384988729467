import { Component, Input, OnInit } from '@angular/core';
import { CardTransactionDetailsRequest, CardTransactionDetailsResponse } from '../../api/client.card';
import { PlPiClientCardService } from '../../services/pl-pi-client-card.service';
import { NUMBER, STRING, TransformInstructions, TransformInstructionsBuilder } from '@backoffice-monorepo/commons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-transaction-details',
  templateUrl: './pl-pi-transaction-details.component.html',
  styleUrls: ['./pl-pi-transaction-details.component.css']
})
export class PlPiTransactionDetailsComponent implements OnInit {
  @Input() clientId: number;
  @Input() detailsRef: string;

  transactionDetails: CardTransactionDetailsResponse | null;

  constructor(
    public activeModal: NgbActiveModal,
    private plPiClientCardService: PlPiClientCardService,
  ) { }

  transactionDetailsTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['accAmount', ['accAmount', NUMBER]],
      ['accCurrencyCode', ['accCurrencyCode', STRING]],
      ['category', ['category', STRING]],
      ['convFeeAmount', ['convFeeAmount', NUMBER]],
      ['convFeeCurrencyCode', ['convFeeCurrencyCode', STRING]],
      ['details', ['details', STRING]],
      ['eventDate', ['eventDate', STRING]],
      ['finishDate', ['finishDate', STRING]],
      ['fullName', ['fullName', STRING]],
      ['maskedPan', ['maskedPan', STRING]],
      ['note', ['note', STRING]],
      ['rate', ['rate', STRING]],
      ['rateRev', ['rateRev', STRING]],
      ['stateId', ['stateId', NUMBER]],
      ['stateMsg', ['stateMsg', STRING]],
      ['stateName', ['stateName', STRING]],
      ['tranTypeId', ['tranTypeId', NUMBER]],
      ['kind', ['kind', STRING]],
      ['trnAmount', ['trnAmount', NUMBER]],
      ['trnCurrencyCode', ['trnCurrencyCode', STRING]],
      ['feeAmount', ['feeAmount', NUMBER]],
      ['feeCurrencyCode', ['feeCurrencyCode', STRING]]
    ]
  );

  ngOnInit(): void {
    const cardTransactionDetailsRequest: CardTransactionDetailsRequest = {
      detailsRef: this.detailsRef
    }
    this.plPiClientCardService.getTransactionDetails(this.clientId, cardTransactionDetailsRequest).pipe(
      take(1)
    ).subscribe(
      transactionDetails => {
        this.transactionDetails = transactionDetails;
      })
  }

}
