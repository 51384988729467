import { Component, Host, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import {
  DateUtils,
  FilteringService, ListComponent,
  MailboxService,
  OverlayService,
  TasksService,
  UserService,
} from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import {
  FilterOperation,
  JoinStrategy,
  SearchQueryAware,
  SearchQueryBuilder,
  Task,
  TaskTopicWithCountResponse
} from '@twino/backoffice-api';
import { finalize, mergeMap, take, takeUntil } from 'rxjs/operators';
import { TasksCommonService } from '../../services/tasks-common.service';
import { Subject, Subscription } from 'rxjs';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TasksRefreshService } from '../../services/tasks-refresh.service';

@Component({
  selector: 'backoffice-monorepo-tasks-dashboard',
  templateUrl: './tasks-dashboard.component.html',
  styleUrls: ['./tasks-dashboard.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Task>) => new FilteringService(listService),
      deps: [TasksService]
    }
  ],
})
export class TasksDashboardComponent extends ListComponent<Task> implements OnInit, OnDestroy {

  readonly allTopic = "All";
  readonly pageSize = 40;
  public readonly FilterOperation = FilterOperation;

  currentOpenTask: Task | null = null;
  activeTopic = this.allTopic;
  topics: TaskTopicWithCountResponse[];
  mailboxes: TaskTopicWithCountResponse[];
  hideFutureTasks = true;
  userName: string;
  isOthersTask = this.tasksCommonService.isOthersTask;
  isMyTask = this.tasksCommonService.isMyTask;
  isGroupTask = this.tasksCommonService.isGroupTask;
  $destroy = new Subject<boolean>();
  private listMailboxTasks: Subscription;
  activeAddress: string;
  isMailbox = false;
  activePanel = -1;

  constructor(
    @Host() filteringService: FilteringService<Task>,
    activatedRoute: ActivatedRoute,
    @SkipSelf() private userService: UserService,
    private tasksService: TasksService,
    private overlayService: OverlayService,
    public tasksCommonService: TasksCommonService,
    private mailboxService: MailboxService,
    private tasksRefreshService: TasksRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    this.userName = this.userService.getUser()?.userName;
    const searchQueryBuilder = new SearchQueryBuilder()
      .withPageSize(this.pageSize)
      .withJoinStrategy(JoinStrategy.AND)
      .addFilter("taskStatus", FilterOperation.NOT_EQUALS, ["CLOSED"], null, "eu.twino.loans.core.task.api.TaskStatus")
      .addFilter("taskStatus", FilterOperation.NOT_EQUALS, ["CANCELLED"], null, "eu.twino.loans.core.task.api.TaskStatus")
      .withSortCriterion({propertyName: "nextProcessingTime", sortDirection: "DESC"});

    if (this.hideFutureTasks) {
      searchQueryBuilder.addFilter("nextProcessingTime", FilterOperation.LESS, [Date.now().toString()]);
    }
    this.searchQuery = searchQueryBuilder.build();
    this.refresh();
    this.listMailboxTasks = this.tasksRefreshService.openTasksForMailbox$.subscribe(mailbox => {
        this.activeAddress = mailbox;
        this.isMailbox = true;
        this.refresh();
    })
  }

  ngOnDestroy(): void {
    this.listMailboxTasks.unsubscribe();
  }

  navigateTo() {}

  getName(): string {
    return 'Tasks dashboard';
  }

  refresh(page = 0) {
    this.searchQuery.removeCriteriaByPropertyName("nextProcessingTime");
    if (this.hideFutureTasks) {
      this.searchQuery.addFilter("nextProcessingTime", FilterOperation.LESS, [DateUtils.dateTimeNow()], 'datetime');
    }
    return this.tasksService.getTaskTopicsWithOpenCount().pipe(
      take(1)
    )
      .subscribe(result => {
        this.topics = result.filter(t => t.isMailBoxTopic === false);
        this.mailboxes = result.filter(t => t.isMailBoxTopic);
        if (page !== 0) {
          this.searchQuery.page = page;
        }
        if(this.isMailbox) {
          this.getMailboxTasks(this.activeAddress);
        } else {
          this.getTasks();
        }
      });
  }

  getTasks() {
    this.searchQuery.sortingCriterion.propertyName = "nextProcessingTime";
    this.tasksService.find(this.searchQuery).pipe(
      take(1)).subscribe(response => {
        this.response = response;
      });
  }

  getMailboxTasks(address: string) {
    this.searchQuery.sortingCriterion.propertyName = "next_processing_time";
    this.mailboxService.list(this.activeTopic, address, this.searchQuery).pipe(take(1)).subscribe(response => {
      this.response = response;
    })
  }

  openTasksForTopic(topicName: string) {
    this.activeTopic = topicName;
    this.activePanel = -1;
    this.isMailbox = false;
    this.searchQuery.page = 1;
    if (topicName === this.allTopic) {
      this.searchQuery.removeCriteriaByPropertyName("topic");
    } else {
      this.searchQuery.addFilterReplacing("topic", FilterOperation.EQUALS, [this.activeTopic]);
    }
    this.refresh();
  }

  setMailboxTopic(topicName: string, index: number) {
    this.activeTopic = topicName;
    this.activePanel = index;
    this.searchQuery.addFilterReplacing("topic", FilterOperation.EQUALS, [this.activeTopic]);
  }

  isNoOpenTasks() {
    return !this.currentOpenTask;
  }

  isOpenTask(task: Task) {
    return this.currentOpenTask?.id === task.id;
  }

  isNotAnOpenTaskWhenThereIsAnOpenOne(task: Task) {
    return !!this.currentOpenTask && this.currentOpenTask.id !== task.id;
  }

  shouldHideOpenTaskButton(task: Task) {
    return !this.tasksCommonService.isMyTask(task) || this.tasksCommonService.isMyTask(task) && this.isOpenTask(task);
  }

  acceptTask(task: Task) {
    if (this.tasksCommonService.isOthersTask(task)) {
      this.tasksCommonService.acceptTask().then((result) => {
        if (result === true) this.assignTask(task, true);
      }, () => {});
    } else {
      this.assignTask(task);
    }
  }

  assignTask(task: Task, isForced: boolean = false) {
    this.overlayService.showOverlay();
    this.tasksService.assignTask(task.id, isForced).pipe(
      mergeMap(() => this.tasksService.getTask(task.id)),
      finalize(() => this.overlayService.hideOverlay()),
      takeUntil(this.$destroy)
    ).subscribe(newTask => {
      Object.assign(task, newTask);
      this.openTask(task);
    });
  }

  openTask(task: Task) {
    this.currentOpenTask = task;
  }

  releaseTask(refresh: boolean = false) {
    this.currentOpenTask = undefined;
    if (refresh) {
      this.refresh();
    }
  }

  beforeChange($event: NgbPanelChangeEvent) {
    $event.preventDefault();
  }
}
