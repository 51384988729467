import { LoanApplicationType } from '@twino/backoffice-api';

export interface ValueAddedServiceResponse {
  id: number
  name: string
  description: string
  active: boolean
  allowedBrands: string[]
  allowedProductTypes: string[]
  allowedProductSubTypes: string[]
  allowedApplicationTypes: LoanApplicationType[]
  ranges: ValueAddedServiceRange[]
  vendorId: number
  transferDetail: string
}

export interface ValueAddedServiceRequest {
  name: string
  description: string
  active: boolean
  allowedBrands: string[]
  allowedProductTypes: string[]
  allowedProductSubTypes: string[]
  allowedApplicationTypes: LoanApplicationType[]
  ranges: ValueAddedServiceRange[]
  transferDetail: string
  vendorId: number
}

export interface ValueAddedServiceRange {
  min: number
  max: number
  amount: number
  daysCount: number
  vendorPackageId: number
}

export interface ClientValueAddedService {
  id: number
  vasId: number
  name?: string
  clientId: number
  loanId?: number
  startDate: Date
  dueDate: Date
  daysCount?: number
  price: number
  vendorId?: number
  vendorName?: string
  vendorPackageId?: string
  vendorPackageName?: string
  status: ClientValueAddedServiceStatus
}

export enum ClientValueAddedServiceStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  RENOUNCED = 'RENOUNCED'
}

export enum  PaymentDetails {
  REFINANCE_COMMISSION_INCOMING = "Prowizja za refinansowanie, numer klienta",
  REPAYMENT_INCOMING= "Spłata zadłużenia, umowa numer",
  REFINANCE_REPAYMENT_OUTGOING = "Spłata pożyczki numer",
  PAYOUT_OUTGOING = "Przelew środków, umowa numer",
  DIPOCKET = "Transakcja z Dipocket, umowa numer",
  FAULTY_OUTGOING = "Zwrot do nadawcy, błędna wpłata",
  SURPLUS_OUTGOING = "Zwrot nadpłaty",
  IDENTIFICATION_OUTGOING = "Zwrot opłaty rejestracyjnej",
  FAULTY_IDENTIFICATION_OUTGOING = "Zwrot opłaty rejestracyjnej",
  REFUND_VAS_PACKAGE = "Zwrot VAS, numer klienta:",
  OTHER = ""
}
