<div *ngIf="offer && !disableButtons" [formGroup]="recalculateOfferForm" class="form-inline m-1">
  <div class="form-group m-1 w-50" *ngIf="showRecalculate()">
    <input
      type="range"
      class="custom-range w-100"
      id="selectedAmount"
      formControlName="selectedAmount"
      [min]="offer.minSelectablePrincipal"
      [max]="offer.maxSelectablePrincipal"
      [step]="step"
      (change)="sliderMove()"
    >
    <h6 class="ml-2">
      Min: {{offer.minSelectablePrincipal}} | Max: {{offer.maxSelectablePrincipal}}
      | New amount: {{recalculateOfferForm.controls.selectedAmount.value}}
    </h6>
  </div>
  <button appRequirePermission="admin.all,loan.admin,offer.accept" *ngIf="showRecalculate()"
          (click)="recalculateOffer()"
          [disabled]="!recalculateOfferForm.valid || !sliderChanged()"
          type="button"
          class="btn btn-success m-1"
  >Recalculate
  </button>
  <button
    appRequirePermission="admin.all,loan.admin,offer.accept"
    class="btn btn-success float-right m-1"
    (click)="acceptOffer(false)"
    [disabled]="disableAcceptButtons"
  >Accept offer
  </button>
  <button
    appRequirePermission="admin.all,loan.admin,offer.accept"
    class="btn btn-success float-right"
    (click)="acceptOfferWithoutFastcash()"
    [disabled]="disableAcceptButtons"
  >Accept offer without fastcash
  </button>
</div>
<div *ngIf="offer && !disableButtons">
  <button *ngFor="let vas of offerVasesEvaluations; let i = index; trackBy: trackById"
          appRequirePermission="admin.all,loan.admin,offer.accept"
          class="btn btn-outline-primary float-left m-1" title="Available VAS: {{vas.amount}}; days: {{vas.daysCount}} - {{vas.name}}"
          [disabled]="disableAcceptButtons"
  >
    VAS: {{vas.amount}}; days: {{vas.daysCount}}
  </button>

  <button *ngIf="vasIds.length > 0"
    appRequirePermission="admin.all,loan.admin,offer.accept"
    class="btn btn-outline-success float-right m-1"
    (click)="setOfferVAS(false,false)"
    [disabled]="disableAcceptButtons"
  >Accept VAS offer without fastcash
  </button>
  <button *ngIf="vasIds.length > 0"
    appRequirePermission="admin.all,loan.admin,offer.accept"
    class="btn btn-outline-success float-right m-1"
    (click)="setOfferVAS(false,true)"
    [disabled]="disableAcceptButtons"
  >Accept VAS offer
  </button>
</div>
<transform-table [transformInstructions]="estimationsTransformations" [entity]="estimatedValues" *ngIf="vasIds.length === 0"></transform-table>
<table class="table table-striped table-hover mt-2" *ngIf="offer && vasIds.length > 0">
  <thead class="text-light bg-info">
  <tr>
    <th></th>
    <th>Without VAS</th>
    <th>With VAS</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>Annual percentage rate of charge</td>
    <td>{{estimatedValues?.annualPercentageRateOfCharge}}</td>
    <td>{{estimatedValuesWithVAS?.annualPercentageRateOfCharge}}</td>
  </tr>
  <tr>
    <td>Minimum payment</td>
    <td>{{estimatedValues?.minimumPayment}}</td>
    <td>{{estimatedValuesWithVAS?.minimumPayment}}</td>
  </tr>
  <tr>
    <td>Next repayment date</td>
    <td>{{estimatedValues?.nextRepaymentDate}}</td>
    <td>{{estimatedValuesWithVAS?.nextRepaymentDate}}</td>
  </tr>
  <tr>
    <td>Next due date</td>
    <td>{{estimatedValues?.nextDueDate}}</td>
    <td>{{estimatedValuesWithVAS?.nextDueDate}}</td>
  </tr>
  <tr>
    <td>Pay in full</td>
    <td>{{estimatedValues?.payInFull}}</td>
    <td>{{estimatedValuesWithVAS?.payInFull}}</td>
  </tr>
  <tr>
    <td>Total amount to be paid during term</td>
    <td>{{estimatedValues?.totalAmountToBePaidDuringTerm}}</td>
    <td>{{estimatedValuesWithVAS?.totalAmountToBePaidDuringTerm}}</td>
  </tr>
  <tr>
    <td>Total amount</td>
    <td>{{offer.principal}}</td>
    <td>{{offer.principal + vasAmount}}</td>
  </tr>
  <tr>
    <td>Sum of costs</td>
    <td>{{estimatedValues?.sumOfCosts}}</td>
    <td>{{estimatedValuesWithVAS?.sumOfCosts}}</td>
  </tr>
  </tbody>
</table>



