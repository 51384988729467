import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CloseTaskRequest, Task, TaskResolution } from '@twino/backoffice-api';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// noinspection ES6PreferShortImport
import { TasksService } from '../../../services/tasks/tasks.service';
import { TwinoDateAdapterService } from '../../../services/dates/twino-date-adapter.service';

@Component({
  selector: 'close-task-modal',
  templateUrl: './close-task-modal.component.html',
  styleUrls: ['./close-task-modal.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class CloseTaskModalComponent implements OnInit {

  @Input()
  task: Task;
  closeTaskForm: FormGroup;
  showPromiseDateInput = false;

  @ViewChild('closeTaskTypeahead', {static: true})
  closeTaskTypeahead: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private tasksService: TasksService
  ) { }

  ngOnInit(): void {
    this.closeTaskForm = this.formBuilder.group({
      resolution: ['', Validators.required],
      comment: ''
    });
  }

  search = (text$: Observable<string>) => {
    const possibleResolutions = this.task?.possibleResolutions
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.closeTaskTypeahead.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (
        term === '' ? possibleResolutions : possibleResolutions.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 20))
    );
  }

  onChangeResolution($key) {
    if ($key === TaskResolution.PROMISE_TO_PAY) {
      this.closeTaskForm.addControl('promiseDate', new FormControl('', Validators.required));
      this.showPromiseDateInput = true;
    } else {
      this.closeTaskForm.removeControl('promiseDate');
      this.showPromiseDateInput = false;
    }
  }

  submitForm() {
    const closeTaskRequest = new CloseTaskRequest();
    closeTaskRequest.resolution = this.closeTaskForm.value.resolution;
    closeTaskRequest.inputData["comment"] = this.closeTaskForm.value.comment;
    if(this.closeTaskForm.value.promiseDate) {
      closeTaskRequest.inputData["promiseDate"] = this.closeTaskForm.value.promiseDate;
    }
    this.tasksService.close(this.task.id, closeTaskRequest).toPromise()
      .then(() => this.modal.close(closeTaskRequest));
  }

}
