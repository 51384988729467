<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Name" type="string" property="name"></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all, system-configuration.edit"
                  class="btn btn-success btn-sm"
                  (click)="navigateToCreate()">
            <bi name="plus-circle"></bi>
            Create
          </button>
    </span>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">Id</th>
      <th sortable="name" (sort)="onSort($event)">Name</th>
      <th class="w-75">Data</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let systemConfig of response?.results; trackBy: trackById" (dblclick)="navigateTo(systemConfig.id)">
      <td></td>
      <td>{{systemConfig.id}}</td>
      <td>{{systemConfig.name}}</td>
      <td class="w-75">{{systemConfig.data}}</td>
    </tr>
    </tbody>
  </table>
</server-table>

