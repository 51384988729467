import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {
  BatchJobService,
  CommonsModule,
  LoadingInterceptor,
  MailboxActionsService,
  PaymentResolver,
  TaskViewComponentResolver
} from '@backoffice-monorepo/commons';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductConfig, ProjectData } from '@backoffice-monorepo/api';
import { PlPiProjectData } from './models/PlPiProjectData';
import { AdminComponent } from './components/admin/admin.component';
import { PlPiTaskViewComponentResolverService } from './services/pl-pi-task-view-component-resolver.service';
import { PlPiProductConfig } from './models/PlPiProductConfig';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { PlPiLoanButtonViewComponentResolverService } from './services/pl-pi-loan-button-view-component-resolver.service';
import { PlPiLoanButtonViewComponent } from './components/pl-pi-loan-button-view/pl-pi-loan-button-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PlPiClientCardViewComponent } from './components/pl-pi-client-card-view/pl-pi-client-card-view.component';
import { PlPiClientSidebarComponentResolver } from './services/pl-pi-client-sidebar-component-resolver.service';
import { PlPiClientCardSendPinComponent } from './components/pl-pi-client-card-send-pin/pl-pi-client-card-send-pin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlPiAcceptOfferModalComponent } from './components/pl-pi-accept-offer-modal/pl-pi-accept-offer-modal.component';
import { PlPiSetRenouncementModalComponent } from './components/pl-pi-set-renouncement-modal/pl-pi-set-renouncement-modal.component';
import { ClientSidebarLibComponentResolver } from '../../../../libs/commons/src/lib/services/client-sidebar-lib-component-resolver';
import { PlPiClientResolver } from './resolvers/pl-pi-client-resolver';
import { LoanSidebarLibComponentResolver } from '../../../../libs/commons/src/lib/services/loan-sidebar-lib-component-resolver';
import { PlPiTransactionDetailsComponent } from './components/pl-pi-transaction-details/pl-pi-transaction-details.component';
import { PlPiAcceptOfferButtonComponent } from './components/pl-pi-accept-offer-button/pl-pi-accept-offer-button.component';
import { PlPiAcceptOfferButtonComponentResolverService } from './services/pl-pi-accept-offer-button-component-resolver.service';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { PlPiLoanApplicationButtonViewComponent } from './components/pl-pi-loan-application-button-view/pl-pi-loan-application-button-view.component';
import { PlPiLoanApplicationButtonsViewComponentResolverService } from './services/pl-pi-loan-application-buttons-view-component-resolver.service';
import { PlPiClientDetailsTableComponentResolverService } from './services/pl-pi-client-details-table-component-resolver.service';
import { PlPiLoanSidebarComponentResolver } from './services/pl-pi-loan-sidebar-component-resolver.service';
import { PlPiClientDetailsEditComponent } from './components/pl-pi-client-details-edit/pl-pi-client-details-edit.component';
import { PlPiClientDetailsTableComponent } from './components/pl-pi-client-details-table/pl-pi-client-details-table.component';
import { PlPiClientButtonViewComponent } from './components/pl-pi-client-button-view/pl-pi-client-button-view.component';
import { PlPiClientButtonViewComponentResolverService } from './services/pl-pi-client-button-view-component-resolver.service';
import { PlPiTopicChangeModalComponent } from './components/pl-pi-topic-change-modal/pl-pi-topic-change-modal.component';
import { PlPiClientChangeModalComponent } from './components/pl-pi-client-change-modal/pl-pi-client-change-modal.component';
import { PlPiMailboxActionsService } from './services/pl-pi-mailbox-actions.service';
import { PlPiClientAddressesComponent } from './components/pl-pi-client-addresses/pl-pi-client-addresses.component';
import { PlPiClientBillingDetailsComponent } from './components/pl-pi-client-billing-details/pl-pi-client-billing-details.component';
import { PlPiClientEmploymentTableComponent } from './components/pl-pi-client-employment-table/pl-pi-client-employment-table.component';
import { PlPiClientEmploymentEditComponent } from './components/pl-pi-client-employment-edit/pl-pi-client-employment-edit.component';
import { PlPiClientEmploymentTableComponentResolverService } from './resolvers/pl-pi-client-employment-table-component-resolver.service';
import { PlPiTaskRelatedEntitiesComponent } from './components/pl-pi-task-related-entities/pl-pi-task-related-entities.component';
import { PlPiTaskRelatedEntitiesComponentResolverService } from './resolvers/pl-pi-task-related-entities-component-resolver.service';
import { PlPiTransferButtonViewComponent } from './components/pl-pi-transfer-button-view/pl-pi-transfer-button-view.component';
import { PlPiTransferAddComponent } from './components/pl-pi-transfer-add/pl-pi-transfer-add.component';
import { PlPiTransferButtonViewComponentResolverService } from './services/pl-pi-transfer-button-view-component-resolver.service';
import { PlPiLoanCloseComponent } from './components/pl-pi-loan-close/pl-pi-loan-close.component';
import { PlPiCompareComponent } from './components/pl-pi-compare/pl-pi-compare.component';
import { PlPiComparePipe } from './pipes/pl-pi-compare.pipe';
import { PlPiTransferOutgoingAddComponent } from './components/pl-pi-transfer-outgoing-add/pl-pi-transfer-outgoing-add.component';
import { PlPiClientCardHistoryComponent } from './components/pl-pi-client-card-history/pl-pi-client-card-history.component';
import { PlPiClientCardChangeStatusComponent } from './components/pl-pi-client-card-change-status/pl-pi-client-card-change-status.component';
import { PlPiPaymentButtonViewComponent } from './components/pl-pi-payment-button-view/pl-pi-payment-button-view.component';
import { PlPiPaymentButtonViewComponentResolverService } from './services/pl-pi-payment-button-view-compnent-resolver.service';
import { PlPiLoanStagesComponent } from './components/pl-pi-loan-stages/pl-pi-loan-stages.component';
import { PlPiLoanSetDcStageComponent } from './components/pl-pi-loan-set-dc-stage/pl-pi-loan-set-dc-stage.component';
import { PlPiOptionalCoreComponentResolver } from './services/pl-pi-optional-core-component-resolver';
import { PlPiLoanMarkFraudComponent } from './components/pl-pi-loan-mark-fraud/pl-pi-loan-mark-fraud.component';
import { PlPiLoanManualDebtStatusComponent } from './components/pl-pi-loan-manual-debt-status/pl-pi-loan-manual-debt-status.component';
import { PlPiLoanViewFieldsComponent } from './components/pl-pi-loan-view-fields/pl-pi-loan-view-fields.component';
import { PlPiLoanViewFieldsComponentResolverService } from './resolvers/pl-pi-loan-view-fields-component-resolver.service';
import { PlPiLoanExtensionFeesComponent } from './components/pl-pi-loan-extension-fees/pl-pi-loan-extension-fees.component';
import { PlPiLoanExtensionFeesComponentResolverService } from './resolvers/pl-pi-loan-extension-fees-resolver.service';
import { PlPiCurrentLoanAgreementComponent } from './components/pl-pi-current-loan-agreement/pl-pi-current-loan-agreement.component';
import { PlPiCurrentLoanAgreementComponentResolverService } from './services/pl-pi-current-loan-agreement-resolver.service';
import { PlPiIncreaseLimitComponent } from './components/pl-pi-increase-limit/pl-pi-increase-limit.component';
import { PlPiLoanMtpAbatementComponent } from './components/pl-pi-loan-mtp-abatement/pl-pi-loan-mtp-abatement.component';
import { PlPiLoanProlongationResignationsComponent } from './components/pl-pi-loan-prolongation-resignations/pl-pi-loan-prolongation-resignations.component';
import { PlPiLoanProlongationResignationsCancelModalComponent } from './components/pl-pi-loan-prolongation-resignations-cancel-modal/pl-pi-loan-prolongation-resignations-cancel-modal.component';
import { PlPiAcceptOfferVasComponent } from './components/pl-pi-accept-offer-vas/pl-pi-accept-offer-vas.component';
import { PlPiClientVasComponent } from './components/pl-pi-client-vas/pl-pi-client-vas.component';
import { PlPiLoanVasComponent } from './components/pl-pi-loan-vas/pl-pi-loan-vas.component';
import {
  PlPiLoanProlongationResignationsResignModalComponent
} from './components/pl-pi-loan-prolongation-resignations-resign-modal/pl-pi-loan-prolongation-resignations-resign-modal.component';
import { PlPiPaymentDistributionsTableComponent } from './components/pl-pi-payment-distributions-table/pl-pi-payment-distributions-table.component';
import { PlPiPaymentDistributionsTableComponentResolverService } from './resolvers/pl-pi-payment-distributions-table-resolver.service';
import { PlPiClientCardOtpComponent } from './components/pl-pi-client-card-otp/pl-pi-client-card-otp.component';
import { PlPiClientCardAddComponent } from './components/pl-pi-client-card-add/pl-pi-client-card-add.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    PlPiLoanButtonViewComponent,
    PlPiClientCardViewComponent,
    PlPiClientCardSendPinComponent,
    PlPiSetRenouncementModalComponent,
    PlPiAcceptOfferModalComponent,
    PlPiClientCardSendPinComponent,
    PlPiTransactionDetailsComponent,
    PlPiAcceptOfferButtonComponent,
    PlPiLoanApplicationButtonViewComponent,
    PlPiClientDetailsTableComponent,
    PlPiClientDetailsEditComponent,
    PlPiLoanApplicationButtonViewComponent,
    PlPiClientButtonViewComponent,
    PlPiTopicChangeModalComponent,
    PlPiClientChangeModalComponent,
    PlPiClientAddressesComponent,
    PlPiClientBillingDetailsComponent,
    PlPiClientEmploymentTableComponent,
    PlPiClientEmploymentEditComponent,
    PlPiTaskRelatedEntitiesComponent,
    PlPiTransferButtonViewComponent,
    PlPiTransferAddComponent,
    PlPiLoanCloseComponent,
    PlPiCompareComponent,
    PlPiComparePipe,
    PlPiTransferOutgoingAddComponent,
    PlPiClientCardHistoryComponent,
    PlPiClientCardChangeStatusComponent,
    PlPiPaymentButtonViewComponent,
    PlPiClientCardChangeStatusComponent,
    PlPiLoanStagesComponent,
    PlPiLoanSetDcStageComponent,
    PlPiLoanMarkFraudComponent,
    PlPiLoanManualDebtStatusComponent,
    PlPiLoanViewFieldsComponent,
    PlPiLoanExtensionFeesComponent,
    PlPiCurrentLoanAgreementComponent,
    PlPiIncreaseLimitComponent,
    PlPiLoanMtpAbatementComponent,
    PlPiLoanProlongationResignationsComponent,
    PlPiLoanProlongationResignationsCancelModalComponent,
    PlPiLoanProlongationResignationsResignModalComponent,
    PlPiAcceptOfferVasComponent,
    PlPiClientVasComponent,
    PlPiLoanVasComponent,
    PlPiPaymentDistributionsTableComponent,
    PlPiClientCardOtpComponent,
    PlPiClientCardAddComponent,
  ],
  imports: [
    BrowserModule,
    CommonsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    NgJsonEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DragDropModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {provide: TaskViewComponentResolver, useClass: PlPiTaskViewComponentResolverService},
    {provide: 'LoanButtonsViewComponentResolver', useClass: PlPiLoanButtonViewComponentResolverService},
    {provide: 'LoanApplicationButtonsViewComponentResolver', useClass: PlPiLoanApplicationButtonsViewComponentResolverService},
    {provide: 'ClientSidebarComponentResolver', useClass: PlPiClientSidebarComponentResolver},
    {provide: ProjectData, useValue: new PlPiProjectData()},
    {provide: ProductConfig, useValue: new PlPiProductConfig()},
    {provide: 'ClientSidebarLibComponentResolver', useClass: ClientSidebarLibComponentResolver},
    {provide: 'ClientResolver', useClass: PlPiClientResolver},
    {provide: 'LoanSidebarLibComponentResolver', useClass: LoanSidebarLibComponentResolver},
    {provide: 'AcceptOfferButtonComponentResolver', useClass: PlPiAcceptOfferButtonComponentResolverService},
    {provide: 'ClientDetailsTableComponentResolver', useClass: PlPiClientDetailsTableComponentResolverService},
    {provide: 'ClientButtonViewComponentResolver', useClass: PlPiClientButtonViewComponentResolverService},
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    {provide: 'LoanSidebarComponentResolver', useClass: PlPiLoanSidebarComponentResolver},
    {provide: MailboxActionsService, useClass: PlPiMailboxActionsService},
    {provide: 'ClientEmploymentTableComponentResolver', useClass: PlPiClientEmploymentTableComponentResolverService},
    {provide: 'TaskRelatedEntitiesComponentResolver', useClass: PlPiTaskRelatedEntitiesComponentResolverService},
    {provide: 'TransferButtonViewComponentResolver', useClass: PlPiTransferButtonViewComponentResolverService},
    {provide: 'PaymentButtonViewComponentResolver', useClass: PlPiPaymentButtonViewComponentResolverService},
    {provide: 'PaymentResolver', useClass: PaymentResolver},
    {provide: 'OptionalCoreComponentResolver', useClass: PlPiOptionalCoreComponentResolver},
    {provide: 'LoanViewFieldsComponentResolver', useClass: PlPiLoanViewFieldsComponentResolverService},
    {provide: 'LoanExtensionFeesComponentResolver', useClass: PlPiLoanExtensionFeesComponentResolverService},
    {provide: 'CurrentLoanAgreementComponentResolver', useClass: PlPiCurrentLoanAgreementComponentResolverService},
    {provide: 'PaymentDistributionsTableComponentResolver', useClass: PlPiPaymentDistributionsTableComponentResolverService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
