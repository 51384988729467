import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransfersListComponent } from './components/transfers-list/transfers-list.component';


const routes: Routes = [
  {path: '', component: TransfersListComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransfersRoutingModule {
}
