<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client AML</b>
  </p>
  <div class="col-md-12">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Scoring</a>
        <ng-template ngbNavContent>
          <backoffice-monorepo-client-aml-scoring [client]="client"></backoffice-monorepo-client-aml-scoring>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Scoring history</a>
        <ng-template ngbNavContent>
          <backoffice-monorepo-client-aml-scoring-history [client]="client"></backoffice-monorepo-client-aml-scoring-history>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2 col-md-8"></div>
  </div>
</div>
