<form [formGroup]="paymentDistributionAddForm" (ngSubmit)="submitForm()">
  <div class="modal-header">
    <h4 class="modal-title">Create payment distribution</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="transactionType" class="col-sm-4 control-label">Type<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select ngbAutofocus
                class="form-control form-control-sm"
                id="transactionType"
                formControlName="transactionType"
                name="transactionType"
                (change)="onChangeTransactionType()">
          <option *ngFor="let key of allowedTransactionTypes$ | async" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="amount" class="col-sm-4 control-label">Amount<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="amount" formControlName="amount" name="amount">
      </div>
    </div>
    <div class="form-group row">
      <label for="surplusAmount" class="col-sm-4 control-label">Surplus amount<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="surplusAmount" formControlName="surplusAmount" name="surplusAmount">
      </div>
    </div>
    <div class="form-group row" *ngIf="showLoanInput">
      <label for="loanId" class="col-sm-4 control-label">Loan Id<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="loanId" formControlName="loanId" name="loanId">
      </div>
    </div>
    <div class="form-group row" *ngIf="showClientInput">
      <label for="clientId" class="col-sm-4 control-label">Client Id<sup class="text-danger" *ngIf="showClientInputRequired">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="clientId" formControlName="clientId" name="clientId">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!paymentDistributionAddForm.valid">Save</button>
  </div>
</form>




