<p class="mt-2 ml-3">
  <b>Loan prolongation resignations</b>
</p>

<!-- region buttons -->
<div class="col-md-12 mb-2">
  <span class="mr-1 float-right">
    <button class="btn btn-success btn-sm" (click)="refresh()">Refresh</button>
  </span>
  <span class="mr-2 float-right">
    <button appRequirePermission="admin.all,loan-prolongation.edit" class="btn btn-danger btn-sm"  (click)="showResignProlongationModal()">
      Resign prolongation
    </button>
  </span>
</div>
<!-- endregion -->

<div class="container-fluid row pt-2 pb-2">
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>ID</th>
          <th>Resignation time</th>
          <th>Cancellation time</th>
          <th>Resignation cause</th>
          <th>Cancellation cause</th>
          <th>Originator</th>
          <th>Document sent time</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of loanProlongationResignations; trackBy: trackById ">
          <td></td>
          <td>{{ item.id }}</td>
          <td>{{ item.resignationTime }}</td>
          <td>{{ item.cancellationTime }}</td>
          <td>{{ item.resignationCause }}</td>
          <td>{{ item.cancellationCause }}</td>
          <td>{{ item.originator }}</td>
          <td>{{ item.documentSentTime }}</td>
          <td>
            <button class="btn btn-danger btn-sm"
                    appRequirePermission="admin.all,loan.admin"
                    (click)="showCancelProlongationResignationModal(item.id, item.originator)"
                    *ngIf="showCancellationButton && item.cancellationTime === null">
              Cancel
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>
