<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
    <tr filter-element title="Id" type="number" property="id"></tr>
    <tr filter-element title="PersonalId" type="string" property="personalId"></tr>
    <tr filter-element title="Name" type="string" property="firstName"></tr>
    <tr filter-element title="Lastname" type="string" property="lastName"></tr>
    <tr filter-element title="Email" type="string" property="email"></tr>
    <tr filter-element title="Ip" type="string" property="ip"></tr>
    <tr filter-element title="Identity document number" type="string" property="identityDocNumber"></tr>
    <tr filter-element title="Bank account number" type="string" property="bankAccountNumber"></tr>
    <tr filter-element title="Bank card number" type="string" property="bankCardNumber"></tr>
    <tr filter-element title="Active" type="boolean" property="active"></tr>
  </tbody>

  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all,personal-id-blacklist.edit"
                  class="btn btn-success btn-sm"
                  (click)="onAddClick()"
          >
            <bi name="plus-circle"></bi>
            Add
          </button>
    </span>
  </div>

  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
      <tr>
        <th></th>
        <th sortable="id" (sort)="onSort($event)">Id</th>
        <th sortable="personalId" (sort)="onSort($event)">PersonalId</th>
        <th sortable="firstName" (sort)="onSort($event)">Name</th>
        <th sortable="lastName" (sort)="onSort($event)">Lastname</th>
        <th sortable="email" (sort)="onSort($event)">Email</th>
        <th sortable="ip" (sort)="onSort($event)">Ip</th>
        <th sortable="identityDocNumber" (sort)="onSort($event)">ID number</th>
        <th sortable="bankAccountNumber" (sort)="onSort($event)">Bank account number</th>
        <th sortable="bankCardNumber" (sort)="onSort($event)">Bank card number</th>
        <th sortable="active" (sort)="onSort($event)">Active</th>
        <th sortable="created" (sort)="onSort($event)">Created</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let record of response?.results; trackBy: trackById" (dblclick)="openEditModal(record)">
      <td></td>
      <td>{{record.id}}</td>
      <td>{{record.personalId}}</td>
      <td>{{record.firstName}}</td>
      <td>{{record.lastName}}</td>
      <td>{{record.email}}</td>
      <td>{{record.ip}}</td>
      <td>{{record.identityDocNumber}}</td>
      <td>{{record.bankAccountNumber}}</td>
      <td>{{record.bankCardNumber}}</td>
      <td>{{record.active}}</td>
      <td>{{record.created}}</td>
    </tr>
    </tbody>
  </table>
</server-table>
