import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildInformationService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getBuildInformation(): Observable<any> {
    return this.get('/api/about/build-info');
  }
}

