<div class="modal-header" cdkDrag cdkDragHandle cdkDragRootElement=".dragged-modal">
  <h4 class="modal-title">View message template content #{{id}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body w-100">
  <div [innerHTML]="messageTemplate?.message | safe: 'html'"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
</div>
