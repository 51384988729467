import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  Campaign,
  CampaignShortInfo,
  CreateCampaignRequest,
  CreateDiscountRequest,
  EnumResponse, ExcelFileProcessingStatus,
  PredicateResponse,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountCampaignService extends BaseService implements SearchQueryAware<Campaign> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<Campaign>> {
    return this.post(`/api/campaigns/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/campaigns/enum-values/${enumClass}`);
  }

  getCampaign(id: number): Observable<Campaign> {
    return this.get<Campaign>(`/api/campaign/${id}`);
  }

  createCampaign(createCampaignRequest: CreateCampaignRequest): Observable<HttpResponse<void>> {
    return this.put(`/api/campaign`, createCampaignRequest, {observe: 'response'});
  }

  createDiscount(locationUri: string, createDiscountRequest: CreateDiscountRequest) {
    locationUri = locationUri.replace("/ws", "");
    return this.put(`${locationUri}/discount`, createDiscountRequest);
  }

  setActive(id: number, isActive: boolean): Observable<CampaignShortInfo> {
    return this.patch(`/api/campaign/${id}/set-active`, {"isActive": isActive});
  }

  getDiscountPredicateScriptInformation(): Observable<PredicateResponse> {
    return this.get(`/api/discount/predicate/script`);
  }

  listActiveCampaigns(): Observable<CampaignShortInfo[]> {
    return this.get(`/api/campaigns/active`);
  }

  addCampaignToClients(campaignId: number, file: File): Observable<void> {
    const formData = new FormData();
    formData.append(
      'file',
      new Blob(
        [],
        {type: "application/json"}
      )
    );
    formData.append('file', file);
    return this.post(`/api/campaign/${campaignId}/clients`, formData);
  }

  getStatus(): Observable<ExcelFileProcessingStatus> {
    return this.get(`/api/excel-processor/status`);
  }
}
