import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OrderAndTransformService } from '../../services/transform/order-and-transform.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
// noinspection ES6PreferShortImport
import { TransformInstructions, TransformResult } from '../../services/transform/transform.types';
import { Utils } from '../../services/utils';

@Component({
  selector: 'transform-table',
  templateUrl: './transform-table.component.html',
  styleUrls: ['./transform-table.component.scss']
})
export class TransformTableComponent implements OnChanges {

  @Input() transformInstructions: TransformInstructions;
  @Input() entity: unknown;
  inputsChanged = new Subject<SimpleChanges>();

  transformed: TransformResult[];

  constructor(
    private transformService: OrderAndTransformService
  ) {
    this.inputsChanged
      .pipe(
        filter((changes) => changes?.entity?.currentValue || this.entity),
        distinctUntilChanged()
      )
      .subscribe((changes) => this.transform(changes));
  }

  transform(changes) {
    const entity = changes?.entity?.currentValue || this.entity;
    if (!!entity && Utils.isNotNullishOrEmpty(entity)) {
      this.transformed = this.transformService.transform(entity, this.transformInstructions);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputsChanged.next(changes);
  }
}
