import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmDialogueComponent, NamedComponent, RiskEvaluationConfigService, RiskEvaluationFlowService } from '@backoffice-monorepo/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RiskEvaluationStepConfigResponse } from '@twino/backoffice-api';
import { RiskEvaluationRefreshService } from '../../services/risk-evaluation-refresh.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { RiskEvaluationFlowStepComponent } from '../risk-evaluation-flow-step/risk-evaluation-flow-step.component';

@Component({
  selector: 'backoffice-monorepo-risk-evaluation-configs-list',
  templateUrl: './risk-evaluation-configs-list.component.html',
  styleUrls: ['./risk-evaluation-configs-list.component.scss'],
})
export class RiskEvaluationConfigsListComponent extends NamedComponent implements OnInit, OnDestroy {

  private reloadRiskEvaluationConfigModel: Subscription;
  riskEvaluationConfigsList: RiskEvaluationStepConfigResponse[];
  flowId: number | undefined;

  constructor(
    protected router: Router,
    private riskEvaluationConfigService: RiskEvaluationConfigService,
    activatedRoute: ActivatedRoute,
    private riskEvaluationConfigRefreshService: RiskEvaluationRefreshService,
    private modalService: NgbModal,
    private riskEvaluationFlowService: RiskEvaluationFlowService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.flowId = this.activatedRoute.snapshot.params['flowId'];
    this.refresh();
    this.reloadRiskEvaluationConfigModel = this.riskEvaluationConfigRefreshService.reloadRiskEvaluationConfigModel$.subscribe(
      () => {
        this.refresh();
      })
  }

  ngOnDestroy(): void {
    this.reloadRiskEvaluationConfigModel.unsubscribe();
  }

  getName(): string {
    return 'Risk evaluation flow steps ' + this.flowId;
  }

  navigateTo(id: number) {
    this.router.navigate([`/risk-evaluation-configs/${this.flowId}/steps/${id}/rules`]);
  }

  refresh() {
   this.riskEvaluationFlowService.getFlowSteps(this.flowId).pipe(
     take(1)
   ).subscribe(riskEvaluationConfigsList => {
     this.riskEvaluationConfigsList = riskEvaluationConfigsList;
   })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.riskEvaluationConfigsList, event.previousIndex, event.currentIndex);
  }

  updateRiskEvaluationOrder() {
    const evaluationOrder = [];
    this.riskEvaluationConfigsList.forEach(riskEvaluationConfig => {
      evaluationOrder.push(riskEvaluationConfig.id);
    });
    const evaluationOrderRequest = {
      flowId:  this.flowId,
      evaluationSequence: evaluationOrder
    }
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Update risk evaluation order";
    modalRef.componentInstance.content = "Are you sure you want to change order?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.riskEvaluationConfigService.updateRiskEvaluationStepsEvaluationSequence(evaluationOrderRequest)
          .pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => {
          this.riskEvaluationConfigRefreshService.reloadRiskEvaluationConfigModel();
        })
      }, () => {}
    );
  }

  addStep() {
    const modalRef = this.modalService.open(RiskEvaluationFlowStepComponent);
    modalRef.componentInstance.isEdit = false;
    modalRef.componentInstance.flowId = this.flowId;
    modalRef.result.then(
      () => this.refresh(),
      () => {}
    )
  }

  editStep(id: number) {
    const modalRef = this.modalService.open(RiskEvaluationFlowStepComponent);
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.flowId = this.flowId;
    modalRef.result.then(
      () => this.refresh(),
      () => {}
    )
  }

  deleteStep(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Delete risk configuration flow step";
    modalRef.componentInstance.content = `Are you sure you want to delete step  #${id}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.riskEvaluationConfigService.deleteRiskEvaluationStep(id).pipe(
            takeUntil(this.$destroy)
          )
            .subscribe(() => this.refresh());
        }
      }
    );
  }

}

