import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  Agreement,
  DebtStepExecutionResult,
  EnumResponse,
  FilterOperation,
  FullLoan,
  JoinStrategy,
  Loan,
  Payment,
  PaymentDistribution,
  SearchCsvExportRequest,
  SearchQuery,
  SearchQueryAware,
  SearchQueryBuilder,
  SearchResponse,
  TransactionEntry,
  WriteOffRequest
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoanService extends BaseService implements SearchQueryAware<Loan> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  findLoansByText(text): Observable<{ results: never[] }> {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(5)
      .withJoinStrategy(JoinStrategy.OR)
      .addFilterData({
        propertyName: 'loanNumber',
        operation: FilterOperation.CONTAINS,
        values: [text]
      })
      .addFilterData({
        propertyName: 'id',
        operation: FilterOperation.EQUALS,
        values: [text]
      })
      .build();

    return this.post(`/api/loans/search`, searchQuery);
  };

  find(searchQuery: SearchQuery): Observable<SearchResponse<Loan>> {
    return this.post(`/api/loans/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/loans/enum-values/${enumClass}`);
  }

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/loans/export-csv`, exportRequest, {'responseType': 'blob'});
  }

  getLoan(id: number): Observable<FullLoan> {
    return this.get<FullLoan>(`/api/loan/${id}`);
  }

  voidLoan(id: number) {
    return this.post(`/api/loan/${id}/void`, {});
  }

  writeOff(id: number, request: WriteOffRequest) {
    return this.post(`/api/loan/${id}/writeoff`, request);
  }

  getLoanAgreements(id: number): Observable<Array<Agreement>> {
    return this.get(`/api/loan/${id}/loan-agreements`);
  }

  getLoanPayments(id: number): Observable<Array<Payment>> {
    return this.get(`/api/loan/${id}/payments`);
  }

  getLoanPaymentDistributions(id: number): Observable<Array<PaymentDistribution>> {
    return this.get(`/api/loan/${id}/payment-distributions`);
  }

  getTransactions(id: number): Observable<SearchResponse<TransactionEntry>> {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(40)
      .addFilterData({
        propertyName: 'loanId',
        operation: FilterOperation.EQUALS,
        values: [id.toString()]
      })
      .withSortCriterion({propertyName: "id", sortDirection: "DESC"})
      .build();

    return this.post(`/api/transactions`, searchQuery);
  }

  getLoanDebtStep(id: number): Observable<DebtStepExecutionResult> {
    return this.get(`/api/loan/${id}/debt-step`);
  }
}
