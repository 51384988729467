import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
  selector: 'input-with-select',
  templateUrl: './input-with-select.component.html',
  styleUrls: ['./input-with-select.component.scss']
})
export class InputWithSelectComponent {
  @Input() label: string;
  @Input() options: Observable<string[]>;
  @Input() control: FormControl;
  @Input() inputName: string;
  @Input() isRequired: boolean;

  select(option: string) {
    this.control.patchValue(option);
  }

  filter(event) {
    this.options = this.options.pipe(
      map(
        (options) => options.filter(option => option.includes(event.target.value))
      )
    );
  }
}
