import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, concat, merge,Observable, Subject } from 'rxjs';
import { concatAll, filter, finalize, map, scan, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { TranslationManagerApiService } from './translation-manager-api.service';
import { RawTranslation, TranslationsInput } from '@backoffice-monorepo/api'
import { TranslationManagerConfigToken } from './translation-manager-config';

@Injectable()
export class TranslationBuilderService implements OnDestroy {
  mainSchema;

  private $hardReset = new Subject<boolean>();

  private $reset = new Subject<boolean>();

  private removeFromServer$ = new BehaviorSubject<RawTranslation>(null);
  public $removeFromServer: Observable<RawTranslation[]> = merge(
    this.$hardReset.pipe(map(() => null)),
    this.removeFromServer$,
  ).pipe(
    scan((acc, lang) => lang ? [...acc, lang]:  [], []),
    shareReplay(1)
  );

  private transitionData$ = new BehaviorSubject<Record<string, unknown>>({});

  private rawTranslationsSource$ = new Subject<RawTranslation[]>();

  private $rawTranslations = merge(
      this.$hardReset.pipe(switchMap(() => this.translationApi.listAll())),
      this.rawTranslationsSource$.asObservable()
    ).pipe(
    shareReplay(1)
  )

  $langs: Observable<{tag: string, isBase: boolean}[]> = this.$rawTranslations
    .pipe(
      map(items => items.map(({languageTag, isBase}) => ({isBase, tag: languageTag}))),
      shareReplay(1)
    );

  $langsList = this.$langs.pipe(
    map(langs => langs.map(l =>l.tag))
  );

  $schema = this.$langsList.pipe(
    map(langs => this.populateSchemaField(langs))
  )

  private $baselang = this.$langs.pipe(
    map(items => items.find(i => i.isBase)?.tag),
  )

  private $dataKeyValue = this.$rawTranslations.pipe(
    map(d => d.reduce((acc, curr) => ({
        ...acc,
        [curr.languageTag]: curr.data
      }), {})
    )
  );

  $dataStructure: Observable< Record<string, unknown>> = combineLatest([
    this.$langsList,
    this.$baselang,
    this.$dataKeyValue
  ]).pipe(
    map(([langs, baselang, inputData]) => TranslationBuilderService.mergeTranslations(inputData, langs, baselang))
  );

  public $transitionData: Observable<Record<string, unknown>> = combineLatest([this.transitionData$, this.$dataStructure]).pipe(
    map(([transition, structure]) => Object.keys(transition).length === 0 ? structure : transition)

  )

  private $destructured: Observable<any> = combineLatest([
    this.$langsList,
    this.$transitionData,
    this.$rawTranslations
  ]).pipe(
    map(([langs, dataStructure, rawData]) => ({...TranslationBuilderService.destructureTranslations(langs, dataStructure), rawData})),
  );

  private static createKeyTranslationsSchemaField(translationSchema: Record<string, unknown>): Record<string, unknown> {
    return {
      type: "object",
      additionalProperties: {
        anyOf: [translationSchema]
      }
    }
  }

  private static createLangSchemaField(langs: string[]): Record<string, unknown> {
    if (!langs || langs.length === 0) {
      throw new Error('TranslationBuilderService: At least one language should be provided.')
    }

    const languages = {anyOf: [{type: 'string'}, {type: 'array', "items": {"type": "string"}}]}

    return {
      "type": "object",
      "properties": Object.assign({}, ...langs.map(s => ({[s]: languages}))),
      "additionalProperties": false,
      "required": langs
    }
  }

  private static mergeTranslations(inputData: TranslationsInput, langs: string[], defaultLang: string): Record<string, unknown> {
    const addTranslations = (input: TranslationsInput) => {
      if (!input[defaultLang]) {
        return null;
      }
      return Object.keys(input[defaultLang])
      .reduce((translationAcc, key) => {

        const mergedTranslationValues = langs.reduce(function (valuesAcc, lang) {
          valuesAcc[lang] = (input?.[lang]?.[key]) ? input[lang][key] : null;
          return valuesAcc;
        }, {});

        if (typeof input[defaultLang][key] !== 'object' || Array.isArray(input[defaultLang][key])) {
          return {...translationAcc, [key]: mergedTranslationValues}
        }
        return {...translationAcc, [key]: addTranslations(mergedTranslationValues)}
      }, {})
    };

    return addTranslations(inputData);
  }

  private static createLang(data: RawTranslation['data'], languageTag: RawTranslation['languageTag'], isBase: RawTranslation['isBase'] = false): RawTranslation {
    return {
      data,
      languageTag: languageTag.toLowerCase(),
      isBase,
      id: Math.round(Math.random() * 1000),
      simpleVersion: -1
    };
  }

  private static destructureTranslations(langs: string[], dataStructure: any): {translations: Record<string, unknown>, structure: Record<string, unknown>} {
    if (langs.length === 0 || !dataStructure) {
      return {
        translations: {},
        structure: {}
      }
    }
    const hasTranslations = (inputData: Record<string, unknown>) =>  {
      if (!inputData || Object.keys(inputData).length === 0) {
        return false;
      }
      return Object.keys(inputData).some((key) => (langs.includes(key)));
    }

    const extract = (source: any, lan: string, structureOnly = false) => {
      const valueOrNull = (value: any) => structureOnly ? null : value;
      if (!source) {
        return null;
      }
      return Object.keys(source).reduce((acc, key) => {
        const hasTranslationsValue = hasTranslations(source[key]);
        acc[key] = (hasTranslationsValue)? valueOrNull(source[key][lan]) : extract(source[key], lan, structureOnly);
        return acc;
      }, {});
    }

    const structure = extract(dataStructure, langs[0], true);
    const translations =  langs.reduce((acc,lang) => ({...acc, [lang]: extract(dataStructure, lang)}), {});

    return {
      translations,
      structure
    }
  }

  constructor(
    private translationApi: TranslationManagerApiService,
    @Inject(TranslationManagerConfigToken) private schema
  ) {
    this.$schema = this.$langsList.pipe(
      filter(langs => langs.length > 0),
      map(langs => this.populateSchemaField(langs))
    );
  }

  public addLang(newLangData: Partial<RawTranslation>): Observable<any[]> {
    return this.$destructured.pipe(
      take(1),
      map(({translations, structure, rawData}) => {
        const newLang: RawTranslation = TranslationBuilderService.createLang(
          newLangData.data !== {} ? newLangData.data : structure,
          newLangData.languageTag,
          rawData.length === 0);

          return [...rawData.map(r => ({...r, data: translations[r.languageTag]})), newLang];
      }),
      tap(d => this.rawTranslationsSource$.next(d))
    );
  }

  public removeLang(lang: string): Observable<{newRawData: any, toRemove: any}> {
    return this.$destructured.pipe(
      take(1),
      map(({translations, rawData}) => {
        const newRawData = rawData
            .filter(d => d.languageTag !== lang)
            .map(r => ({...r, data: translations[r.languageTag]} as RawTranslation));
        const toRemove = rawData.find(d => d.languageTag === lang);

        return {newRawData, toRemove};
      }),
      map(data => {
        if (data.newRawData.length > 0 && !data.newRawData.some(i => i.isBase)) {
          data.newRawData[0].isBase = true;
        }
        return data;
      }),
      tap(d => {
        this.rawTranslationsSource$.next(d.newRawData);
        this.removeFromServer$.next(d.toRemove);
      })
    );
  }

  public setTransitionData(data: Record<string, unknown>): void {
    this.transitionData$.next(data);
  }

  public saveAll(): Observable<any> {
    const saveSequence = (translationList: RawTranslation[]) => translationList.map(data =>
      data.simpleVersion < 0 ? this.translationApi.insert(data).pipe(take(1)) : this.translationApi.update(data).pipe(take(1))
    );

    const $upsertList = this.$destructured.pipe(
      map(({rawData, translations}) =>  [...rawData.map(r => ({...r, data: translations[r.languageTag]}))]),
      map(rawData => saveSequence(rawData)),
      take(1),
      switchMap(d => concat(d).pipe(take(d.length))),
      concatAll()
    )

    const $delete = this.$removeFromServer.pipe(
      map(langList => langList.filter(lang => lang.simpleVersion > -1)),
      map(d => d.map(langData => langData.id)),
      take(1),
      switchMap(d => concat(d.map(id => this.translationApi.remove(id))).pipe(take(d.length))),
      concatAll()
    );


    return $upsertList.pipe(
      switchMap(() => $delete),
      finalize(() => this.reset(true))
    )
  }

  ngOnDestroy() {
    this.rawTranslationsSource$.complete();
  }

  reset(hard: boolean): void {
    const target = hard ? this.$hardReset : this.$reset;
    this.removeFromServer$.next(null);
    target.next(true);
  }


  private populateSchemaField(langs: string[]) {
    const langSchema = TranslationBuilderService.createLangSchemaField(langs);
    const keyTranslationsSchema = TranslationBuilderService.createKeyTranslationsSchemaField(langSchema);

    return  this.schema(langSchema, keyTranslationsSchema);
  }
}
