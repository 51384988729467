<div class="modal-header">
  <h4 class="modal-title">
    Edit user
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('closed')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="name">UserName</label>
    <input class="form-control" id="name" [value]="username" disabled>
  </div>

  <div class="form-group d-flex flex-row overflow-auto" style="max-height: 600px">
    <label>Roles</label>
    <div class="d-flex flex-column ml-2">
      <div *ngFor="let role of roles" class="d-flex align-items-center">
        <input
          type="checkbox"
          [checked]="selectedRoles.includes(role.name)"
          (click)="selectRole(role.name)"
        >
        <label class="mt-1 ml-2">{{role.name}}</label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="onSaveClick()">Save</button>
  <button type="button" autofocus class="btn btn-outline-secondary" (click)="activeModal.dismiss(false)">Cancel</button>
</div>
