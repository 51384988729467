<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client Addresses</b>
  </p>
  <div class="col-md-12">
    <table class="table table-striped table-hover table-sm">
      <thead class="text-light bg-primary">
      <tr>
        <th></th>
        <th>Locality</th>
        <th>Administrative Area</th>
        <th>Street</th>
        <th>House Number</th>
        <th>Apartment Number</th>
        <th>Country</th>
        <th>Postal Code</th>
        <th>Type</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of addresses$ | async">
        <td></td>
        <td>{{ item.locality }}</td>
        <td>{{ item.administrativeArea }}</td>
        <td>{{ item.street }}</td>
        <td>{{ item.houseNumber }}</td>
        <td>{{ item.apartmentNumber }}</td>
        <td>{{ item.postalCountry }}</td>
        <td>{{ item.postalCode }}</td>
        <td>{{ item.type }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


