import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  ActivityOverview,
  EnumResponse,
  FilterOperation,
  SearchCsvExportRequest,
  SearchQuery,
  SearchQueryAware,
  SearchQueryBuilder,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityOverviewService extends BaseService implements SearchQueryAware<ActivityOverview> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<ActivityOverview>> {
    return this.post(`/api/activity-overview`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/activity-overview/enum-values/${enumClass}`);
  }

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/activity-overview/export-csv`, exportRequest, {'responseType': 'blob'});
  }

  getActivityOverviewByClient(clientId: number): Observable<SearchResponse<ActivityOverview>> {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(40)
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [clientId.toString()]
      })
      .withSortCriterion({propertyName: "created", sortDirection: "DESC"})
      .build();

    return this.post(`/api/activity-overview`, searchQuery);
  }

  getActivityOverviewByLoan(loanId: number): Observable<SearchResponse<ActivityOverview>> {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(40)
      .addFilterData({
        propertyName: 'loanId',
        operation: FilterOperation.EQUALS,
        values: [loanId.toString()]
      })
      .withSortCriterion({propertyName: "created", sortDirection: "DESC"})
      .build();

    return this.post(`/api/activity-overview`, searchQuery);
  }
}
