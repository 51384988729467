import { Component, Input, OnInit } from '@angular/core';
import { LoanProlongationResignationsService } from '@backoffice-monorepo/commons';
import { take } from 'rxjs/operators';
import {
  FilterOperation,
  FullLoan,
  LoanProlongationResignationOriginator,
  LoanProlongationResignationsResponse,
  LoanStatusDetail,
  SearchQueryBuilder
} from '@twino/backoffice-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiLoanProlongationResignationsCancelModalComponent } from '../pl-pi-loan-prolongation-resignations-cancel-modal/pl-pi-loan-prolongation-resignations-cancel-modal.component';
import { PlPiLoanProlongationResignationsResignModalComponent } from '../pl-pi-loan-prolongation-resignations-resign-modal/pl-pi-loan-prolongation-resignations-resign-modal.component';
import { LoanSidebarComponent } from '@backoffice-monorepo/commons';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-prolongation-resignations',
  templateUrl: './pl-pi-loan-prolongation-resignations.component.html',
  styleUrls: ['./pl-pi-loan-prolongation-resignations.component.css']
})
export class PlPiLoanProlongationResignationsComponent implements LoanSidebarComponent, OnInit {

  @Input() loan: FullLoan
  showCancellationButton = false;
  loanProlongationResignations: LoanProlongationResignationsResponse[];

  constructor(
    private loanProlongationResignationsService: LoanProlongationResignationsService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.showCancellationButton = this.loan.open && !this.isTerminationPending();
    this.refresh();
  }

  isTerminationPending() {
    return [
      LoanStatusDetail.PENDING_TERMINATION_BY_CLIENT,
      LoanStatusDetail.PENDING_TERMINATION_BY_COMPANY
    ].includes(this.loan.statusDetail)
  }

  showResignProlongationModal() {
    const modalRef = this.modalService.open(PlPiLoanProlongationResignationsResignModalComponent);
    modalRef.componentInstance.loanId = this.loan.id;
    modalRef.result.then(
      () => {
        this.refresh();
      }, () => {}
    );
  }

  showCancelProlongationResignationModal(resignationId: number, originator: LoanProlongationResignationOriginator) {
    const modalRef = this.modalService.open(PlPiLoanProlongationResignationsCancelModalComponent);
    modalRef.componentInstance.resignationId = resignationId;
    modalRef.componentInstance.originator = originator;
    modalRef.componentInstance.loanId = this.loan.id;
    modalRef.result.then(
      () => {
        this.refresh();
      }, () => {}
    );
  }

  trackById(index, item){
    return item.id;
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'loanId',
        operation: FilterOperation.EQUALS,
        values: [this.loan.id.toString()]
      })
      .build()

    return this.loanProlongationResignationsService.find(searchQuery).pipe(
      take(1)
    ).subscribe((loanProlongationResignations) => {
      this.loanProlongationResignations = loanProlongationResignations.results;
    })
  }
}
