import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  EnumResponse, SearchCsvExportRequest,
  SearchQuery,
  SearchQueryAware,
  SearchResponse, TaskGroup
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskGroupService extends BaseService implements SearchQueryAware<TaskGroup> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<TaskGroup>> {
    return this.post(`/api/task/groups/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/task/groups/enum-values/${enumClass}`);
  }

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/task/groups/export-csv`, exportRequest, {'responseType': 'blob'});
  }

  createTaskGroup(groupName?: string): Observable<TaskGroup> {
    return this.post(`/api/task/groups`, {name: groupName});
  }

  deleteTaskGroup(groupId: number) {
    return this.delete(`/api/task/groups/${groupId}`);
  }
}
