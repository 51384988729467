import { Injectable, Type } from '@angular/core';
import { CountryViewComponent } from '@backoffice-monorepo/commons';
import { PlPiClientEmploymentTableComponent } from '../components/pl-pi-client-employment-table/pl-pi-client-employment-table.component';
import { ClientEmploymentTableComponentResolver } from '../../../../../libs/commons/src/lib/services/component-resolvers/ClientEmploymentTableComponentResolver';

@Injectable({
  providedIn: 'root'
})
export class PlPiClientEmploymentTableComponentResolverService implements ClientEmploymentTableComponentResolver {
  resolve(): Type<CountryViewComponent> {
    return PlPiClientEmploymentTableComponent;
  }
}
