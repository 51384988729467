import { Component, Host, Inject, OnDestroy, OnInit, Type } from '@angular/core';
import {
  ConfirmDialogueComponent,
  CountryViewComponent,
  FilteringService,
  ListComponent,
  TransferButtonsViewComponentResolver,
  TransferService
} from '@backoffice-monorepo/commons';
import { PaymentDirection, SearchQueryAware, Transfer, TransferStatus } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TransferRedirectComponent } from '../transfer-redirect/transfer-redirect.component';
import { TransferRefreshService } from '../../services/transfer-refresh.service';
import { TransferPayloadViewComponent } from '../transfer-payload-view/transfer-payload-view.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-transfers-list',
  templateUrl: './transfers-list.component.html',
  styleUrls: ['./transfers-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Transfer>) => new FilteringService(listService),
      deps: [TransferService]
    }
  ]
})
export class TransfersListComponent extends ListComponent<Transfer> implements OnInit, OnDestroy {

  private reloadTransfersListModel: Subscription;
  paymentDirection = PaymentDirection;
  transferStatus = TransferStatus;
  transferButtonViewComponentType: Type<CountryViewComponent>;
  exportModuleName = "transfers";

  constructor(
    @Host() filteringService: FilteringService<Transfer>,
    protected router: Router,
    private transferService: TransferService,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private transferRefreshService: TransferRefreshService,
    @Inject('TransferButtonViewComponentResolver') public transferButtonViewComponentResolver: TransferButtonsViewComponentResolver
  ) {
    super(activatedRoute, filteringService);
    this.transferButtonViewComponentType = transferButtonViewComponentResolver.resolve();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadTransfersListModel = this.transferRefreshService.reloadTransfersListModel$.subscribe(() => {
      super.ngOnInit();
    })
  }

  ngOnDestroy(): void {
    this.reloadTransfersListModel.unsubscribe();
  }

  getName(): string {
    return 'Transfers';
  }

  navigateTo(id: number) {
    this.router.navigate([`/transfers/${id}`]);
  }

  redirectTransfer(transferId) {
    const modalRef = this.modalService.open(TransferRedirectComponent);
    modalRef.componentInstance.transferId = transferId;
  }

  viewPayload(transferId) {
    const modalRef = this.modalService.open(TransferPayloadViewComponent, {size: 'lg'});
    modalRef.componentInstance.transferId = transferId;
  }

  retryTransfer(transfer, index) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Retry transfer #${transfer.id}`;
    modalRef.componentInstance.content = "Are you sure you want to process payment distribution?";
    modalRef.result.then(
      (result) => {
        transfer.refreshed = true;
        if (result === true) this.transferService.retryTransfer(transfer.id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {
            this.transferService.getTransfer(transfer.id).subscribe(refreshedTransfer => {
              this.response.results[index] = refreshedTransfer;
              transfer.refreshed = false;
            });
          }
        );
      }, () => {}
    );
  }

}
