import { Component } from '@angular/core';
import { EmploymentInfo } from '@twino/backoffice-api';
import {
  CountryViewComponent,
  draggableModalOptions,
  STRING,
  TransformInstructions,
  TransformInstructionsBuilder
} from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiClientEmploymentEditComponent } from '../pl-pi-client-employment-edit/pl-pi-client-employment-edit.component';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-employment-table',
  templateUrl: './pl-pi-client-employment-table.component.html',
  styleUrls: ['./pl-pi-client-employment-table.component.css']
})
export class PlPiClientEmploymentTableComponent implements CountryViewComponent {
  data: EmploymentInfo;
  clientId: number;

  employmentInfoTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['employmentStatus', ['Employment Status', STRING]],
      ['workplace', ['Workplace', STRING]],
      ['workPhoneNumber', ['Work Phone Number', STRING]],
      ['employmentDurationInMonth', ['Employment Duration In Month', STRING]],
      ['jobTitle', ['Job Title', STRING]],
      ['employerTaxId', ['Employer tax ID', STRING]]
    ]
  );

  constructor(
    private modalService: NgbModal
  ) { }

  editClientEmployment() {
    const modalRef = this.modalService.open(PlPiClientEmploymentEditComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.clientId;
  }
}
