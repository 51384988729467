import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { PaymentsListComponent } from './components/payments-list/payments-list.component';
import { PaymentComponent } from './components/payment/payment.component';


const routes: Routes = [
  {path: ':id', component: PaymentComponent, resolve: {  payment: 'PaymentResolver' }, runGuardsAndResolvers: 'always'},
  {path: '', component: PaymentsListComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: 'paymentResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {}
    }
  ]
})
export class PaymentsRoutingModule {
}
