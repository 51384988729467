import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'server-refresh',
  templateUrl: './server-refresh.component.html',
  styleUrls: ['./server-refresh.component.scss']
})
export class ServerRefreshComponent implements OnInit, OnDestroy {

  @Output()
  refresh = new EventEmitter<void>();
  @Input()
  refreshInterval = 0;
  @Input()
  refreshText = "";
  @Input()
  refreshAllowed = true;
  lastRefreshTime = new Date();
  timer: number;


  constructor() {
  }

  serverRefresh() {
    this.refresh.emit();
  }

  ngOnInit(): void {
    if (this.refreshInterval) {
      this.timer = setInterval(() => {
          if (this.refreshAllowed) {
            this.serverRefresh();
            this.lastRefreshTime = new Date();
          }
        },
        this.refreshInterval);
    }
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

}
