<form [formGroup]="affiliateConfigForm" (ngSubmit)="submitForm()">
  <div class="container-fluid pt-2 col-sm-8">
    <div class="card">
      <div class="card-header">
       {{tabTitle}} affiliate configuration
        <div class="float-right">
          <button type="submit"
                  appRequirePermission="admin.all, debt-step-configuration.edit"
                  class="btn btn-outline-danger"
                  [disabled]="!affiliateConfigForm.valid">
            Save
          </button>
        </div>
      </div>
      <div class="card-body">

        <div class="form-group row">
          <label for="name" class="col-sm-4 control-label">Name<sup class="text-danger">*</sup></label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control form-control-sm"
                   id="name"
                   formControlName="name"
                   name="name"
                   maxlength="20">
          </div>
        </div>
        <div class="form-group row">
          <label for="token" class="col-sm-4 control-label">Token</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control form-control-sm"
                   id="token"
                   formControlName="token"
                   name="token">
          </div>
        </div>
        <div class="form-group row">
          <label for="partnerName" class="col-sm-4 control-label">Partner name</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control form-control-sm"
                   id="partnerName"
                   formControlName="partnerName"
                   name="partnerName">
          </div>
        </div>
        <div class="form-group row">
          <label for="additionalParameterName" class="col-sm-4 control-label">Additional parameter name</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control form-control-sm"
                   id="additionalParameterName"
                   formControlName="additionalParameterName"
                   name="additionalParameterName">
          </div>
        </div>
        <div class="form-group row">
          <label for="customTracker" class="col-sm-4 control-label">Custom tracker</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control form-control-sm"
                   id="customTracker"
                   formControlName="customTracker"
                   name="customTracker">
          </div>
        </div>
        <div class="form-group row control-label">
          <label class="form-check-label col-sm-4" for="enabled">
            Enabled
          </label>
          <div class="form-check col-sm-8">
            <input class="form-check-input ml-1"
                   type="checkbox"
                   id="enabled"
                   formControlName="enabled"
                   name="enabled">
          </div>
        </div>
        <div class="form-group row">
          <label for="redirectUrl" class="col-sm-4 control-label">Redirect url</label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control form-control-sm"
                   id="redirectUrl"
                   formControlName="redirectUrl"
                   name="redirectUrl">
          </div>
        </div>
      </div>
    </div>
    <affiliate-configuration-report
      [parentForm]="affiliateConfigForm" [data]="affiliatesConfigurationReportsData">
    </affiliate-configuration-report>
  </div>
</form>
