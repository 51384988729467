import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentDistributionsListComponent } from './components/payment-distributions-list/payment-distributions-list.component';


const routes: Routes = [
 {path: '', component: PaymentDistributionsListComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentDistributionsRoutingModule {
}
