import { SearchModel } from './search/search.model';

export interface Loan extends SearchModel {
  id: number
  loanNumber: string
  clientId: number
  status: string
  statusDetail: string
  currentAgreementType: string
  closedDate?: Date
  currentDebtStep?: number
  brand: string
}

export interface WriteOffRequest {
  bookingDate: Date
  principalAmount: number
  interestAmount: number
  loanFeeAmount: number
  penaltyAmount: number
  loanCommissionAmount: number
  reason?: string
}


export enum LoanStatusDetail {
  NEW = 'NEW',
  PAID = 'PAID',
  VOIDED = 'VOIDED',
  PAUSED = 'PAUSED',
  SOLD = 'SOLD',
  RENOUNCED = 'RENOUNCED',
  WITHDRAWN = 'WITHDRAWN',
  REFINANCED = 'REFINANCED',
  REF_ADDITIONAL = 'REF_ADDITIONAL',
  REOPENED = 'REOPENED',
  WRITTEN_OFF = 'WRITTEN_OFF',
  PENDING_RENOUNCEMENT = 'PENDING_RENOUNCEMENT',
  PENDING_TERMINATION_BY_COMPANY = 'PENDING_TERMINATION_BY_COMPANY',
  PENDING_TERMINATION_BY_CLIENT = 'PENDING_TERMINATION_BY_CLIENT',
  DC_PAUSED = 'DC_PAUSED',
  PRE_SOLD = 'PRE_SOLD',
  AML_TERMINATION_BY_COMPANY = 'AML_TERMINATION_BY_COMPANY',
  BUY_BACK = 'BUY_BACK',
}

export enum AccountType {
  PRINCIPAL = 'PRINCIPAL',
  INTEREST = 'INTEREST',
  SCHEDULE_COMMISSION = 'SCHEDULE_COMMISSION',
  LOAN_FEES = 'LOAN_FEES',
  LOAN_EXTENSION_FEE = 'LOAN_EXTENSION_FEE',
  PENALTY = 'PENALTY',
  SURPLUS = 'SURPLUS',
  LOAN_COMMISSION = 'LOAN_COMMISSION',
  LOAN_ANNUAL_COMMISSION = 'LOAN_ANNUAL_COMMISSION',
  PREPARATION_FEE = 'PREPARATION_FEE',
  REFINANCE_COMMISSION = 'REFINANCE_COMMISSION',
  IDENTIFICATION_COMMISSION = 'IDENTIFICATION_COMMISSION',
  OTHER = 'OTHER'
}
