<table  class="table table-striped table-hover table-sm">
  <thead class="text-light bg-primary">
    <tr>
      <th>Id</th>
      <th>Status</th>
      <th>Status detail</th>
      <th>Start date</th>
      <th>Due date</th>
      <th>Balance</th>
      <th>Open principal</th>
      <th>Open interest</th>
      <th>Open commissions sum</th>
      <th>Open fees sum</th>
      <th>Open penalty</th>
      <th>Total principal</th>
      <th>Total Interest</th>
      <th>Total commissions sum</th>
      <th>Total fees sum</th>
      <th>Total Penalty</th>
      <th>Invoice</th>
    </tr>
  </thead>
  <tbody>
    <tr [ngClass]="{'table-warning': DateUtils.isDateBetween(item.startDate, item.dueDate) && scheduleItems.length > 0}" *ngFor="let item of scheduleItems; trackBy: trackById">
      <td>{{item.id}}</td>
      <td>{{item.status}}</td>
      <td>{{item.statusDetail}}</td>
      <td>{{item.startDate | date:'dd.MM.yyyy'}}</td>
      <td>{{item.dueDate | date:'dd.MM.yyyy'}}</td>
      <td>{{item.balance}}</td>
      <td>{{item.openPrincipal}}</td>
      <td>{{item.openInterest}}</td>
      <td>
        <button type="button"
                class="btn btn-sm btn-info"
                (click)="viewCommissions(item.commissions)"
                title="View commissions">
          {{item.commissionsAmountsSum.openAmountSum}}
        </button>
      </td>
      <td>
        <button type="button"
                class="btn btn-sm btn-info"
                (click)="viewFees(item.fees)"
                title="View fees">
          {{item.feesSum.openFeesAmount}}
        </button>
      </td>
      <td>{{item.openPenalty}}</td>
      <td>{{item.principal}}</td>
      <td>{{item.totalInterest}}</td>
      <td>
        <button type="button"
                class="btn btn-sm btn-info"
                (click)="viewCommissions(item.commissions)"
                title="View commissions">
          {{item.commissionsAmountsSum.totalAmountSum}}
        </button>
      </td>
      <td>
        <button type="button"
                class="btn btn-sm btn-info"
                (click)="viewFees(item.fees)"
                title="View fees">
          {{item.feesSum.totalFeesAmount}}
        </button>
      </td>
      <td>{{item.totalPenalty}}</td>
      <td><a target="_blank" href="/ws/api/loan/invoice/schedule/{{item.id}}">download</a></td>
    </tr>
  </tbody>
</table>
