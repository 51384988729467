import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';
import {
  EnumResponse,
  LoanSaleFileSignedUrlResponse,
  LoanSalePortfolio,
  RevertSellingPortfolioRequest,
  SearchQuery,
  SearchQueryAware,
  SearchResponse,
  SellLoanPortfolioRequest
} from '@backoffice-monorepo/api';

@Injectable({
  providedIn: 'root'
})
export class LoanSalePortfolioService extends BaseService implements SearchQueryAware<LoanSalePortfolio> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<LoanSalePortfolio>> {
    return this.post(`/api/loan-sale/portfolio/search`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/loan-sale/portfolio/enum-values/${enumClass}`);
  }

  sellPortfolio(request: SellLoanPortfolioRequest) {
    return this.post(`/api/loan-sale/portfolio/sell`, request);
  }

  revertPortfolioSelling(request: RevertSellingPortfolioRequest) {
    return this.patch(`/api/loan-sale/portfolio/${request.portfolioId}/cancel`, request);
  }

  getLoanSaleFileSignedUrl(id: number): Observable<LoanSaleFileSignedUrlResponse> {
    return this.get(`/api/loan-sale/portfolio/${id}/sale-file-signed-url`);
  }

}
