import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertsService, BasicComponent, RiskEvaluationFlowService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UpdateRiskEvaluationFlowRequest
} from '@twino/backoffice-api';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-risk-evaluation-flow',
  templateUrl: './risk-evaluation-flow.component.html',
  styleUrls: ['./risk-evaluation-flow.component.css']
})
export class RiskEvaluationFlowComponent extends BasicComponent implements OnInit, OnDestroy {

  @Input() id: number | undefined;
  @Input() isEdit: boolean;
  riskEvaluationFlowForm: FormGroup;
  references$: Observable<string[]>;
  title: string | never;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private riskEvaluationFlowService: RiskEvaluationFlowService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.references$ = this.riskEvaluationFlowService.getFlowReferences();
    this.title = (this.isEdit) ? "Edit" : "Add";
    this.riskEvaluationFlowForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
    if (this.isEdit === false) {
      this.riskEvaluationFlowForm.addControl('reference', new FormControl('', Validators.required));
    } else {
      this.riskEvaluationFlowService.getFlow(this.id).pipe(
        take(1)
      ).subscribe(result => {
          const flowData = {
            name: result.name,
            description: result.description
          }
          this.riskEvaluationFlowForm.setValue(flowData);
      })
    }
  }

  submitForm() {
    if (this.isEdit) {
      this.updateFlow();
    } else {
      this.addFlow();
    }
  }

  addFlow() {
    this.riskEvaluationFlowService.addEvaluationFlow(this.riskEvaluationFlowForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Flow ${this.riskEvaluationFlowForm.controls.name.value} added`);
      this.activeModal.close();
    })
  }

  updateFlow() {
    const request: UpdateRiskEvaluationFlowRequest = {
      id: this.id,
      newDescription: this.riskEvaluationFlowForm.controls.description.value,
      newName: this.riskEvaluationFlowForm.controls.name.value,
    }
    this.riskEvaluationFlowService.updateEvaluationFlow(request).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
        this.alertService.notifySuccess(`Flow updated`);
        this.activeModal.close();
      })
  }
}
