import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  SearchCsvExportParameters,
  SearchCsvExportQueryCriteria, SearchCsvExportRequest,
  SearchQuery
} from '@twino/backoffice-api';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../services/utils';
import { Subject } from 'rxjs';
import { FilteringService } from '../../../services/filtering.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'search-export',
  templateUrl: './search-export.component.html',
  styleUrls: ['./search-export.component.css']
})
export class SearchExportComponent implements OnInit, OnDestroy {

  $destroy = new Subject<boolean>();
  @Input() exportModuleName: string;
  @Input() searchQuery: SearchQuery;
  showExport = false;

  constructor(
    protected filteringService: FilteringService<never>,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const modulePermission = this.exportModuleName + ".export-data";
    if (this.userService.hasPermission("admin.all") || this.userService.hasPermission(modulePermission) ||
      this.userService.hasPermission("export-all-data")) {
      this.showExport = true;
    }
  }

  searchCsvExport() {
    const exportQueryCriteria: SearchCsvExportQueryCriteria = {
      joinStrategy: this.searchQuery?.joinStrategy,
      sortingCriterion: this.searchQuery?.sortingCriterion,
      criteria: this.searchQuery?.criteria
    }
    const exportParams: SearchCsvExportParameters = {
      includeHeaders: true,
      columnSeparator: ',',
      quoteChar: '"'
    }
    const exportRequest: SearchCsvExportRequest = {
      queryCriteria: exportQueryCriteria,
      exportParameters: exportParams
    }
    this.filteringService.exportCsv(exportRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe((response: Blob) => {
        Utils.downloadBlob(response, this.exportModuleName + "_reports.csv");
      },
      error => console.error("Error downloading the file.", error))
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
