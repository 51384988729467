import { Component, OnInit } from '@angular/core';
import { CountryViewComponent } from '@backoffice-monorepo/commons';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-extension-fees',
  templateUrl: './pl-pi-loan-extension-fees.component.html',
  styleUrls: ['./pl-pi-loan-extension-fees.component.css']
})
export class PlPiLoanExtensionFeesComponent implements OnInit, CountryViewComponent {

  data: any;

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }
}
