import { Component, Input, OnInit } from '@angular/core';
import { FilterOperation, FullLoan, SearchQueryBuilder, SearchResponse, TransactionEntry } from '@twino/backoffice-api';
import { LoanService, LoanSidebarLibComponent, TransactionService } from '@backoffice-monorepo/commons';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-loan-transactions',
  templateUrl: './loan-transactions.component.html',
  styleUrls: ['./loan-transactions.component.scss']
})
export class LoanTransactionsComponent implements LoanSidebarLibComponent, OnInit {
  @Input()
  loan: FullLoan;
  transactions: TransactionEntry[] | null;
  searchResults: SearchResponse<TransactionEntry> | never;
  newPage = 1;
  itemsPerPage = 20;

  constructor(
    private loanService: LoanService,
    private transactionService: TransactionService
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: "loan.id",
        operation: FilterOperation.EQUALS,
        values: [this.loan.id.toString()]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()

    this.transactionService.find(searchQuery).pipe(
      take(1)
    ).subscribe(transactions => {
      this.searchResults = transactions;
    })
  }

  trackById(index, item){
    return item.id;
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }
}
