import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { ConfirmDialogueComponent, draggableModalOptions, FilteringService, ListComponent, TaskGroupService } from '@backoffice-monorepo/commons';
import { SearchQueryAware, TaskGroup } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TaskGroupAddComponent } from '../task-group-add/task-group-add.component';
import { TasksRefreshService } from '../../services/tasks-refresh.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-task-groups-list',
  templateUrl: './task-groups-list.component.html',
  styleUrls: ['./task-groups-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<TaskGroup>) => new FilteringService(listService),
      deps: [TaskGroupService]
    }
  ]
})
export class TaskGroupsListComponent extends ListComponent<TaskGroup> implements OnInit, OnDestroy {

  private reloadTaskGroupsListModel: Subscription;
  exportModuleName = " task-groups";

  constructor(
    @Host() filteringService: FilteringService<TaskGroup>,
    protected router: Router,
    private taskGroupService: TaskGroupService,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private taskGroupsRefreshService: TasksRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadTaskGroupsListModel = this.taskGroupsRefreshService.reloadTaskGroupsListModel$.subscribe(() => {
      super.ngOnInit();
    })
  }

  getName(): string {
    return 'Task groups';
  }

  navigateTo() { }

  ngOnDestroy(): void {
    this.reloadTaskGroupsListModel.unsubscribe();
  }

  createTaskGroup() {
    this.modalService.open(TaskGroupAddComponent, draggableModalOptions);
  }

  deleteTaskGroup(taskGroupId) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Delete task group #${taskGroupId}`;
    modalRef.componentInstance.content = "Are you sure you want to delete task group?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.taskGroupService.deleteTaskGroup(taskGroupId).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {
            super.ngOnInit();
          }
        );
      }, () => {}
    );
  }
}
