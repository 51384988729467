<div class="modal-header">
  <h4 class="modal-title">Fees</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <table class="table table-striped table-hover mt-2">
      <tbody>
      <tr>
        <th>Account type</th>
        <th>Fee name</th>
        <th>Total amount</th>
        <th>Open amount</th>
        <th>Open tax included amount</th>
        <th>Total tax included amount</th>
      </tr>
      <tr *ngFor="let item of fees; trackBy: trackById">
        <td>{{item.accountType}}</td>
        <td>{{item.feeName}}</td>
        <td>{{item.totalAmount}}</td>
        <td>{{item.openAmount}}</td>
        <td>{{item.openTaxIncludedAmount}}</td>
        <td>{{item.totalTaxIncludedAmount}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
</div>





