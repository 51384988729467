<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Loan VAS</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
        <span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>ID</th>
          <th>Client ID</th>
          <th>Start Date</th>
          <th>Due date</th>
          <th>Price</th>
          <th>Status</th>
          <th>Days count</th>
          <th>Vendor ID</th>
          <th>Vendor name</th>
          <th>Vendor package ID</th>
          <th>Vendor package name</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of searchResults?.results; trackBy: trackById">
          <td></td>
          <td>{{item.id}}</td>
          <td><a *ngIf="item.clientId" routerLink="/clients/{{item.clientId}}">{{item.clientId}}</a></td>
          <td>{{item.startDate}}</td>
          <td>{{item.dueDate}}</td>
          <td>{{item.price}}</td>
          <td>{{item.status}}</td>
          <td>{{item.daysCount}}</td>
          <td>{{item.vendorId}}</td>
          <td>{{item.vendorName}}</td>
          <td>{{item.vendorPackageId}}</td>
          <td>{{item.vendorPackageName}}</td>
          <td>
            <button class="btn btn-danger btn-sm" appRequirePermission="admin.all,value-added-service.edit"
                    (click)="renounceVAS(item.id)" *ngIf="item?.status === ClientValueAddedServiceStatus.OPEN">
              Renounce
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </server-table>
    <div class="form-inline form-group" *ngIf="(searchResults?.totalRecords >= itemsPerPage)">
      <ngb-pagination class="pages"
                      size="sm"
                      [collectionSize]="searchResults?.totalRecords"
                      [page]="searchResults?.currentPage"
                      [pageSize]="itemsPerPage"
                      [maxSize]="10"
                      [rotate]="false"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                      (pageChange)="pageChange($event)">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>

