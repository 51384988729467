<form [formGroup]="transferAddForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Create transfer</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="clientId" class="col-sm-4 control-label">Client ID<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="clientId" formControlName="clientId" name="clientId">
      </div>
    </div>
    <div class="form-group row">
      <label for="amount" class="col-sm-4 control-label">Amount<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="amount" formControlName="amount" name="amount">
      </div>
    </div>
    <div class="form-group row">
      <label for="timestamp" class="col-sm-4 control-label">Timestamp</label>
      <div class="input-group col-sm-8">
        <input class="form-control form-control-sm"
               id="timestamp"
               formControlName="timestamp"
               placeholder="yyyy-mm-dd"
               name="timestamp"
               ngbDatepicker
               #timestamp="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="timestamp.toggle()" type="button"></button>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="fromAccount" class="col-sm-4 control-label">fromAccount<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="fromAccount" formControlName="fromAccount" name="fromAccount">
      </div>
    </div>
    <div class="form-group row">
      <label for="toAccount" class="col-sm-4 control-label">To account<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="toAccount" formControlName="toAccount" name="toAccount">
      </div>
    </div>
    <div class="form-group row">
      <label for="purpose" class="col-sm-4 control-label">Purpose<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="purpose" formControlName="purpose" name="purpose">
      </div>
    </div>
    <div class="form-group row">
      <label for="comment" class="col-sm-4 control-label">Comment<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="comment" formControlName="comment" name="comment">
      </div>
    </div>
    <div class="form-group row">
      <label for="offerId" class="col-sm-4 control-label">Offer Id</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="offerId" formControlName="offerId" name="offerId">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!transferAddForm.valid">Save</button>
  </div>
</form>







