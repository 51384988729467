import { Injectable, Type } from '@angular/core';
import { ClientSidebarLibComponent } from '@backoffice-monorepo/commons';
import { ClientApplicationsComponent } from '../../../../core/src/lib/modules/clients/components/client-applications/client-applications.component';
import { ClientLoansComponent } from '../../../../core/src/lib/modules/clients/components/client-loans/client-loans.component';
import { ClientIdentityDocumentsComponent } from '../../../../core/src/lib/modules/clients/components/client-identity-documents/client-identity-documents.component';
import { ClientPaymentsComponent } from '../../../../core/src/lib/modules/clients/components/client-payments/client-payments.component';
import { ClientPaymentDistributionsComponent } from '../../../../core/src/lib/modules/clients/components/client-payment-distributions/client-payment-distributions.component';
import { ClientCommunicationsComponent } from '../../../../core/src/lib/modules/clients/components/client-communications/client-communications.component';
import { ClientDocumentsComponent } from '../../../../core/src/lib/modules/clients/components/client-documents/client-documents.component';
import { ClientDetailsComponent } from '../../../../core/src/lib/modules/clients/components/client-details/client-details.component';
import { ClientActivityOverviewComponent } from '../../../../core/src/lib/modules/clients/components/client-activity-overview/client-activity-overview.component';
import { ClientConsentsComponent } from '../../../../core/src/lib/modules/clients/components/client-consents/client-consents.component';
import { ClientTasksComponent } from '../../../../core/src/lib/modules/clients/components/client-tasks/client-tasks.component';
import { ClientAmlDashboardComponent } from '../../../../core/src/lib/modules/clients/components/client-aml-dashboard/client-aml-dashboard.component';
import {
  ClientLoginAttemptsComponent
} from '../../../../core/src/lib/modules/clients/components/client-login-attempts/client-login-attempts.component';

export class ClientLibComponentRecord {
  index: number
  title: string
  type: Type<ClientSidebarLibComponent>
}

export interface ClientSidebarLibComponentResolver {
  resolve(): ClientLibComponentRecord[]
}

@Injectable({
  providedIn: 'root'
})
export class ClientSidebarLibComponentResolver implements ClientSidebarLibComponentResolver {

  resolve(): ClientLibComponentRecord[] {
    return [
      {index: 1, title: 'Loan Applications', type: ClientApplicationsComponent},
      {index: 2, title: 'Loans', type: ClientLoansComponent},
      {index: 3, title: 'Identity Documents', type: ClientIdentityDocumentsComponent},
      {index: 4, title: 'Payments', type: ClientPaymentsComponent},
      {index: 5, title: 'Payment Distributions', type: ClientPaymentDistributionsComponent},
      {index: 6, title: 'Client Communications', type: ClientCommunicationsComponent},
      {index: 7, title: 'Client Documents', type: ClientDocumentsComponent},
      {index: 8, title: 'Client Details', type: ClientDetailsComponent},
      {index: 10, title: 'Activity Overview', type: ClientActivityOverviewComponent},
      {index: 11, title: 'Client Consents', type: ClientConsentsComponent},
      {index: 12, title: 'Client Tasks', type: ClientTasksComponent},
      {index: 13, title: 'Client AML', type: ClientAmlDashboardComponent},
      {index: 14, title: 'Client Login Attempts', type: ClientLoginAttemptsComponent},
    ]
  }
}
