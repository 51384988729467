<div class="form-group row">
  <label class="col-sm-1 control-label" [for]="inputName">{{label}}<sup class="text-danger" *ngIf="isRequired">*</sup></label>
  <div ngbDropdown #myDrop="ngbDropdown" class="col-sm-11">
    <input
      ngbDropdownAnchor
      [formControl]="control"
      (input)="filter($event)"
      (focus)="myDrop.open()"
      [id]="inputName"
      class="form-control"
      autocomplete="none">
    <div ngbDropdownMenu>
      <div class="custom-dropdown">
        <button
          ngbDropdownItem
          *ngFor="let option of (options | async)"
          (click)="select(option)"
        >{{option}}</button>
      </div>
    </div>
  </div>
</div>
