import { Component, Host, OnInit } from '@angular/core';
import { DateFilterDefaultValues, JoinStrategy, SearchQueryAware, SearchQueryBuilder, Task } from '@twino/backoffice-api';
import { FilteringService, ListComponent, TasksService } from '@backoffice-monorepo/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterOperation } from '@twino/backoffice-api';


@Component({
  selector: 'backoffice-monorepo-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Task>) => new FilteringService(listService),
      deps: [TasksService]
    }
  ]
})
export class TasksListComponent extends ListComponent<Task> implements OnInit {

  exportModuleName = "tasks";
  readonly FilterOperation = FilterOperation;
  readonly DateFilterValues = DateFilterDefaultValues;

  constructor(
    @Host() filteringService: FilteringService<Task>,
    protected router: Router,
    taskService: TasksService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    const searchQueryBuilder = new SearchQueryBuilder()
      .withJoinStrategy(JoinStrategy.AND);
    this.searchQuery = searchQueryBuilder.build();
    super.ngOnInit();
  }

  getName(): string {
    return 'Tasks list';
  }

  navigateTo(id: number) {
    this.router.navigate([`/tasks/${id}`]);
  }
}
