import { Injectable, Type } from '@angular/core';
import {
  CountryViewComponent,
  LoanApplicationButtonsViewComponentResolver
} from '@backoffice-monorepo/commons';
import { PlPiLoanApplicationButtonViewComponent } from '../components/pl-pi-loan-application-button-view/pl-pi-loan-application-button-view.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiLoanApplicationButtonsViewComponentResolverService implements LoanApplicationButtonsViewComponentResolver {

  resolve(): Type<CountryViewComponent> {
    return PlPiLoanApplicationButtonViewComponent;
  }
}
