import { Component, Inject, OnDestroy, OnInit, Type } from '@angular/core';
import {
  AMOUNT,
  ClientButtonsViewComponentResolver,
  ClientService,
  ClientSidebarComponentResolver,
  ClientSpecificComponentRecord,
  CountryViewComponent,
  draggableModalOptions,
  draggableModalOptionsXl,
  ID,
  NamedComponent,
  STRING,
  TransformInstructions,
  TransformInstructionsBuilder,
  UserService
} from '@backoffice-monorepo/commons';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Application,
  ClientComment,
  ClientCommentRequest,
  FilterOperation,
  FullClient,
  SearchQueryBuilder,
  SearchResponse
} from '@twino/backoffice-api';
import { ClientEditComponent } from '../client-edit/client-edit.component';
import { ClientDocumentUploadComponent } from '../client-document-upload/client-document-upload.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientAddressEditComponent } from '../client-address-edit/client-address-edit.component';
import { forkJoin, Subscription } from 'rxjs';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { ClientSmsSendComponent } from '../client-sms-send/client-sms-send.component';
import { ClientEmailSendComponent } from '../client-email-send/client-email-send.component';
import {
  ClientLibComponentRecord,
  ClientSidebarLibComponentResolver
} from '../../../../../../../commons/src/lib/services/client-sidebar-lib-component-resolver';
import { ClientIncomeEditComponent } from '../client-income-edit/client-income-edit.component';
import { ClientDiscountAddComponent } from '../client-discount-add/client-discount-add.component';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent extends NamedComponent implements OnInit, OnDestroy {

  private reloadClientModel: Subscription;
  private routeDataSubscription: Subscription;

  id: number;
  client?: Partial<FullClient> | null;
  active: any;
  lastApplication: Application | null;
  comments: SearchResponse<ClientComment> | null;
  clientBalances: Map<string, number> | null;
  addCommentForm: FormGroup;
  clientCommentRequest: ClientCommentRequest;
  userName: string;
  countryClientSidebarComponents: ClientSpecificComponentRecord[];
  libClientSidebarComponents: ClientLibComponentRecord[];
  clientTransformations: TransformInstructions;
  clientButtonViewComponentType: Type<CountryViewComponent>;
  newPage = 1;
  itemsPerPage = 20;

  constructor(
    activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private userService: UserService,
    private modalService: NgbModal,
    protected router: Router,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService,
    @Inject('ClientSidebarComponentResolver') public clientSidebarComponentResolver: ClientSidebarComponentResolver,
    @Inject('ClientSidebarLibComponentResolver') public clientSidebarLibComponentResolver: ClientSidebarLibComponentResolver,
    @Inject('ClientButtonViewComponentResolver') public clientButtonViewComponentResolver: ClientButtonsViewComponentResolver
  ) {
    super(activatedRoute);
    this.countryClientSidebarComponents = this.clientSidebarComponentResolver.resolve();
    this.libClientSidebarComponents = this.clientSidebarLibComponentResolver.resolve();
    this.clientButtonViewComponentType = clientButtonViewComponentResolver.resolve();
  }

  lastApplicationTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Application ID', ID]],
      ['clientId', ['Client', STRING]],
      ['askedTerm', ['Asked term', () => `${this.lastApplication.askedTerm.loanLength?.value} ${this.lastApplication.askedTerm.loanLength?.unit}`.asString()]],
      ['askedAmount', ['Asked amount', AMOUNT]],
      ['receiveChannel', ['Receive Channel', STRING]],
      ['status', ['Status', STRING]],
      ['flowState', ['Flow State', STRING]],
      ['flowStateStartDateTime', ['Flow State Start Date', STRING]],
      ['createDate', ['Create Date', () => this.lastApplication.createDate.toString().asString()]],
      ['modifiedDate', ['Modified Date', () => this.lastApplication.modifiedDate.toString().asString()]],
      ['brand', ['Brand', STRING]],
      ['productType', ['Product Type', STRING]],
      ['productSubType', ['Product Subtype', STRING]],
      ['type', ['Type', STRING]],
      ['resolution', ['Resolution', STRING]],
      ['flowStateDetails', ['Flow State Details', STRING]],
      ['creditScore', ['Credit Score', STRING]]
    ]
  );


  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.clientTransformations = this.activatedRoute.snapshot.data.clientMainData.transformations;
    this.client = this.activatedRoute.snapshot.data.clientMainData.client;
    this.getClientData();
    this.reloadClientModel = this.clientsRefreshService.reloadClientModel$.subscribe(() => {
      this.refresh();
    })
    this.userName = this.userService.getUser()?.userName
    this.addCommentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    });

    this.routeDataSubscription = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd && event.snapshot?.data?.clientMainData) {
        this.client = event.snapshot?.data?.clientMainData?.client || this.client;
      }
    });
  }

  ngOnDestroy() {
    this.reloadClientModel.unsubscribe();
    this.routeDataSubscription.unsubscribe();
  }

  getName(): string {
    return `Client ${this.id || ''}`;
  }

  getClientData() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [this.id.toString()]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()
    forkJoin({
      comments: this.clientService.searchClientComments(searchQuery),
      surplusBalance: this.clientService.getClientSurplusBalance(this.id)
    })
      .pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(({comments, surplusBalance}) => {
        this.comments = comments;
        this.clientBalances = surplusBalance.balances;
        this.getLastApplication();
      });
  }

  getLastApplication() {
    this.clientService.getClientLastApplication(this.id).pipe(
      take(1)
    ).subscribe(lastApplication => {
      this.lastApplication = lastApplication;
    })
  }

  refresh() {
    this.router.navigate([`/clients/${this.id}`]);
    this.getClientData();
  }

  editClient() {
    const modalRef = this.modalService.open(ClientEditComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.id;
    modalRef.result.then(() => this.refresh());
  }

  uploadDocument() {
    const modalRef = this.modalService.open(ClientDocumentUploadComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.id;
  }

  editClientAddress() {
    const modalRef = this.modalService.open(ClientAddressEditComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.id;
  }

  editClientIncome() {
    const modalRef = this.modalService.open(ClientIncomeEditComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.client.savedId;
  }

  sendSms() {
    const modalRef = this.modalService.open(ClientSmsSendComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.id;
    modalRef.componentInstance.mobilePhone = this.client.mobilePhone;
  }

  sendEmail() {
    const modalRef = this.modalService.open(ClientEmailSendComponent, {
      ...draggableModalOptionsXl
    });
    modalRef.componentInstance.clientId = this.id;
    modalRef.componentInstance.email = this.client.email;
  }

  addClientDiscount() {
    const modalRef = this.modalService.open(ClientDiscountAddComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.client.savedId;
  }

  submitCommentForm() {
    this.clientCommentRequest = {
      comment: this.addCommentForm.value.comment,
      commenterUser: this.userName,
      clientId: this.id
    }
    this.clientService.addClientComment(this.id, this.clientCommentRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.addCommentForm.controls['comment'].setValue('');
      this.getClientData();
    });
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }

  trackById(index, item){
    return item.id;
  }
}
