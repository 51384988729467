<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Key" type="string" property="key"></tr>
  <tr filter-element title="Message" type="string" property="message"></tr>
  <tr filter-element title="Brand" type="string" property="brand"></tr>
  <tr filter-element title="Disabled" type="boolean" property="isDisabled"></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all, message-templates.edit"
                  class="btn btn-success btn-sm"
                  (click)="createMessageTemplate()">
            <bi name="plus-circle"></bi>
            Create
          </button>
    </span>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="key" (sort)="onSort($event)">Id</th>
      <th>Message</th>
      <th sortable="locale" (sort)="onSort($event)">Locale</th>
      <th sortable="brand" (sort)="onSort($event)">Brand</th>
      <th>Disabled</th>
      <th>Created</th>
      <th>Updated</th>
      <td></td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let messageTemplate of response?.results; trackBy: trackById" (dblclick)="editMessageTemplate(messageTemplate.id)">
      <td></td>
      <td>{{messageTemplate.key}}</td>
      <td>
        <button type="button"
                class="btn btn-outline-primary btn-sm"
                (click)="viewContent(messageTemplate.id)"
                title="View content">
          <bi name="justify-left"></bi>
        </button>
      </td>
      <td>{{messageTemplate.locale}}</td>
      <td>{{messageTemplate.brand}}</td>
      <td>{{messageTemplate.isDisabled}}</td>
      <td>{{messageTemplate.created}}</td>
      <td>{{messageTemplate.updated}}</td>
      <td>
        <button type="submit"
                class="btn btn-outline-danger btn-sm"
                (click)="deleteMessageTemplate(messageTemplate.id)"
                appRequirePermission="admin.all, message-templates.edit"
        >Delete</button>
      </td>
    </tr>
    </tbody>
  </table>
</server-table>


