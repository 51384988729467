import { JsonEditorMode } from 'ang-jsoneditor/jsoneditor/jsoneditoroptions';

export abstract class ProductConfig {
  abstract postponeTaskHours: number[]
  abstract languages: string[]
  abstract defaultLanguage: string
  abstract jsonEditorModes: JsonEditorMode[]
  abstract locales: string[]
  abstract httpTemplateKeyMarker: string;
  abstract smsSenderName: string;
  abstract brand: null | string;
}
