import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Communication } from '@twino/backoffice-api';
import { CommunicationService } from '@backoffice-monorepo/commons';
import { take } from 'rxjs/operators';

@Component({
  selector: 'communication-content-view',
  templateUrl: './communication-content-view.component.html',
  styleUrls: ['./communication-content-view.component.scss']
})
export class CommunicationContentViewComponent implements OnInit {
  @Input() communicationId: number;

  communication: Communication | null;

  constructor(
    public activeModal: NgbActiveModal,
    private communicationService: CommunicationService
  ) { }

  ngOnInit(): void {
    this.communicationService.getClientCommunication(this.communicationId).pipe(
      take(1)
    ).subscribe((communication) => {
      this.communication = communication;
    })
  }

}
