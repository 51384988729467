<div class="card">
  <div class="card-header text-light bg-primary">
    Employment info
    <button appRequirePermission="client.all,client.edit,admin.all" class="btn btn-sm btn-light float-right" (click)="editClientEmployment()">
      <bi name="pencil-square"></bi>
      Edit employment
    </button>
  </div>
  <div class="card-text">
    <transform-table [transformInstructions]="employmentInfoTransformations" [entity]="data"></transform-table>
  </div>
</div>

