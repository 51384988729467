import { Component, Input, OnInit } from '@angular/core';
import { ClientActivitiesService, ClientSidebarLibComponent } from '@backoffice-monorepo/commons';
import { ClientLoginAttemptResponse, FullClient } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-client-login-attempts',
  templateUrl: './client-login-attempts.component.html',
  styleUrls: ['./client-login-attempts.component.scss']
})
export class ClientLoginAttemptsComponent implements ClientSidebarLibComponent,OnInit {

  @Input() client: FullClient;
  loginAttempts: ClientLoginAttemptResponse[];

  constructor(
    private clientActivitiesService: ClientActivitiesService,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.clientActivitiesService.getClientLoginAttempts(this.client.savedId)
     .subscribe(loginAttempts => this.loginAttempts = loginAttempts);
  }
}
