import { Component, OnDestroy, OnInit } from '@angular/core';
// noinspection ES6PreferShortImport
import { ViewHistoryService } from '../../services/view-history.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'view-history',
  templateUrl: './view-history.component.html',
  styleUrls: ['./view-history.component.scss']
})
export class ViewHistoryComponent implements OnInit,OnDestroy {

  private closeTab: Subscription;

  constructor(
    public router: Router,
    private viewHistoryService: ViewHistoryService
  ) {
  }

  ngOnInit(): void {
    this.closeTab = this.viewHistoryService.closeTab$.subscribe(() => {
      this.viewHistoryService.removeHistoryEntry(decodeURIComponent(this.router.url));
      this.router.navigate([this.navigateAfterCloseEntry()]);
    })
  }

  ngOnDestroy(): void {
    this.closeTab.unsubscribe();
  }

  showNav() {
    return this.viewHistoryService.historyHasEntries();
  }

  closeAllExceptCurrent() {
    const current = decodeURIComponent(this.router.url);
    this.viewHistoryService.clearHistory(current, current);
  }

  closeAll() {
    const current = decodeURIComponent(this.router.url);
    this.viewHistoryService.clearHistory(current);
    this.router.navigate(['/']);
  }

  getHistory() {
    return this.viewHistoryService.getHistory();
  }

  preserveSorting() {
    return 0;
  }

  closeHistoryEntry($event: MouseEvent, id: string) {
    const entryId = decodeURIComponent(id);
    const urlWithoutQueryParams = this.router.url.split('?')[0];
    this.viewHistoryService.removeHistoryEntry(entryId);
    if (decodeURIComponent(urlWithoutQueryParams) === entryId) this.router.navigate([this.navigateAfterCloseEntry()]);
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  navigateAfterCloseEntry() {
    const historyEntries = Object.keys(this.viewHistoryService.getHistory());
    return (historyEntries.length === 0) ? '/' : historyEntries[0];
  }
}
