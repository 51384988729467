import { Component, OnInit } from '@angular/core';
import { ClientService, ClientSidebarComponent } from '@backoffice-monorepo/commons';
import { BillingDetail, FullClient } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-billing-details',
  templateUrl: './pl-pi-client-billing-details.component.html',
  styleUrls: ['./pl-pi-client-billing-details.component.css']
})
export class PlPiClientBillingDetailsComponent implements ClientSidebarComponent, OnInit {
  client: FullClient;
  billingDetails: BillingDetail[];

  constructor(
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.clientService.getClientBillingDetails(this.client.savedId).pipe(
      take(1)
    ).subscribe(billingDetails => {
      this.billingDetails = billingDetails;
    })
  }

  trackById(index, item){
    return item.id;
  }
}
