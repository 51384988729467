import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RiskEvaluationConfigsRoutingModule } from './risk-evaluation-configs-routing.module';
import { RiskEvaluationConfigsListComponent } from './components/risk-evaluation-configs-list/risk-evaluation-configs-list.component';
import { RiskEvaluationConfigComponent } from './components/risk-evaluation-config/risk-evaluation-config.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { RiskEvaluationFlowsListComponent } from './components/risk-evaluation-flows-list/risk-evaluation-flows-list.component';
import { RiskEvaluationFlowComponent } from './components/risk-evaluation-flow/risk-evaluation-flow.component';
import { RiskEvaluationFlowStepComponent } from './components/risk-evaluation-flow-step/risk-evaluation-flow-step.component';


@NgModule({
  declarations: [RiskEvaluationConfigsListComponent, RiskEvaluationConfigComponent, RiskEvaluationFlowsListComponent, RiskEvaluationFlowComponent, RiskEvaluationFlowStepComponent],
  imports: [
    CommonModule,
    CommonsModule,
    TranslateModule,
    ReactiveFormsModule,
    RiskEvaluationConfigsRoutingModule,
    DragDropModule,
    NgJsonEditorModule
  ]
})
export class RiskEvaluationConfigsModule { }
