import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ProductConfigsRoutingModule } from './product-configs-routing.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ProductConfigsListComponent } from './components/product-configs-list/product-configs-list.component';
import { ProductConfigAddComponent } from './components/product-config-add/product-config-add.component';
import { ProductConfigEditComponent } from './components/product-config-edit/product-config-edit.component';


@NgModule({
  declarations: [ProductConfigsListComponent, ProductConfigAddComponent, ProductConfigEditComponent],
  imports: [
    CommonModule,
    CommonsModule,
    TranslateModule,
    ReactiveFormsModule,
    ProductConfigsRoutingModule,
    NgJsonEditorModule
  ]
})
export class ProductConfigsModule { }
