export type SortDirection = "ASC" | "DESC" | "";

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

export class SortingCriterion {
  propertyName: string
  sortDirection: SortDirection

  constructor(propertyName: string, sortDirection: SortDirection) {
    this.propertyName = propertyName;
    this.sortDirection = sortDirection;
  }
}
