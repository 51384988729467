// noinspection ES6PreferShortImport
import { SearchModel } from './search/search.model';

export class Client implements SearchModel {
  id: number;
  number: string;
  mobilePhone: string;
  email: string;
  finishedDetailsStep: number
  firstName: string;
  lastName: string;
  personalId?: string;
}

export const ClientSearchOption =  {
  fullName: 'Full name',
  mobilePhone: 'Mobile phone',
  email: 'Email',
  personalId: 'Personal ID',
  id: 'ID',
  number: 'Number',
}
