/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Task } from '@twino/backoffice-api';
import {
  CloseTaskModalComponent,
  ConfirmDialogueComponent,
  OverlayService,
  PostponeTaskModalComponent,
  TasksService,
  UserService,
} from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Injectable({
  providedIn: 'root'
})
export class TasksCommonService {


  constructor(
    private tasksService: TasksService,
    private overlayService: OverlayService,
    private modalService: NgbModal,
    private userService: UserService
  ) {}

  private get userName(): string | undefined | null {
    return this.userService.getUser()?.userName;
  };

  isGroupTask(task: Task): boolean {
    return !task.assignedUser;
  }

  isMyTask(task: Task): boolean {
    return task.assignedUser === this.userName;
  }

  isOthersTask(task: Task): boolean {
    return task.assignedUser && task.assignedUser !== this.userName;
  }

  isClosedTask(task: Task): boolean {
    return task.taskStatus === 'CLOSED';
  }

  closeTask(task: Task): Promise<any> {
    const modalRef = this.modalService.open(CloseTaskModalComponent);
    modalRef.componentInstance.task = task;
    return modalRef.result;
  }

  postpone(task: Task): Promise<any> {
    const modalRef = this.modalService.open(PostponeTaskModalComponent);
    modalRef.componentInstance.task = task;
    return modalRef.result;
  }

  acceptTask(): Promise<any> {
      const modalRef = this.modalService.open(ConfirmDialogueComponent);
      modalRef.componentInstance.header = "Already assigned";
      modalRef.componentInstance.content = "Task is already assigned. Assign this task to you?";
      return modalRef.result;
  }
}
