import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelTransform'
})
export class LabelTransformPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase().replace("_", " ");
  }
}
