import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbNavModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommunicationsRoutingModule } from './communications-routing.module';
import { CommunicationsListComponent } from './components/communications-list/communications-list.component';
import { CommunicationContentViewComponent } from './components/communication-content-view/communication-content-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [CommunicationsListComponent, CommunicationContentViewComponent],
	imports: [
		CommonModule,
		CommunicationsRoutingModule,
		CommonsModule,
		NgbNavModule,
		TranslateModule,
		ReactiveFormsModule,
		NgbToastModule,
		DragDropModule
	],
  exports: [CommunicationContentViewComponent]
})
export class CommunicationsModule { }
