import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmDialogueComponent, NamedComponent } from '@backoffice-monorepo/commons';
import { RiskEvaluationFlowResponse } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { RiskEvaluationRefreshService } from '../../services/risk-evaluation-refresh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { RiskEvaluationFlowService } from '@backoffice-monorepo/commons';
import { Subscription } from 'rxjs';
import { RiskEvaluationFlowComponent } from '../risk-evaluation-flow/risk-evaluation-flow.component';

@Component({
  selector: 'backoffice-monorepo-risk-evaluation-flows-list',
  templateUrl: './risk-evaluation-flows-list.component.html',
  styleUrls: ['./risk-evaluation-flows-list.component.css']
})
export class RiskEvaluationFlowsListComponent extends NamedComponent implements OnInit, OnDestroy {

  riskEvaluationFlowsList: RiskEvaluationFlowResponse[];
  private reloadRiskEvaluationFlowModel: Subscription;

  constructor(
    protected router: Router,
    private riskEvaluationFlowService: RiskEvaluationFlowService,
    activatedRoute: ActivatedRoute,
    private riskEvaluationRefreshService: RiskEvaluationRefreshService,
    private modalService: NgbModal
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.refresh();
    this.reloadRiskEvaluationFlowModel = this.riskEvaluationRefreshService.reloadRiskEvaluationFlowModel$.subscribe(
      () => {
        this.refresh();
      })
  }

  ngOnDestroy(): void {
    this.reloadRiskEvaluationFlowModel.unsubscribe();
  }

  navigateTo(id: number) {
    this.router.navigate([`/risk-evaluation-configs/${id}/steps`]);
  }

  refresh() {
    this.riskEvaluationFlowService.listRiskEvaluationFlow().pipe(
      take(1)
    ).subscribe(riskEvaluationConfigsList => {
      this.riskEvaluationFlowsList = riskEvaluationConfigsList;
    })
  }

  getName(): string {
    return 'Risk evaluation flows';
  }

  addFlow() {
    const modalRef = this.modalService.open(RiskEvaluationFlowComponent);
    modalRef.componentInstance.isEdit = false;
    modalRef.result.then(
      () => this.refresh(),
      () => {}
    )
  }

  editFlow(id: number) {
    const modalRef = this.modalService.open(RiskEvaluationFlowComponent);
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.isEdit = true;
    modalRef.result.then(
      () => this.refresh(),
      () => {}
    )
  }

  deleteFlow(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Delete risk configuration flow";
    modalRef.componentInstance.content = `Are you sure you want to delete flow #${id}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.riskEvaluationFlowService.deleteEvaluationFlow(id).pipe(
            takeUntil(this.$destroy)
          )
            .subscribe(() => this.refresh());
        }
      }
    );
  }
}
