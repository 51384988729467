import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessageTemplatesListComponent } from './components/message-templates-list/message-templates-list.component';
import { MessageTemplateAddComponent } from './components/message-template-add/message-template-add.component';
import { MessageTemplateEditComponent } from './components/message-template-edit/message-template-edit.component';


const routes: Routes = [
   {path: '', component: MessageTemplatesListComponent, pathMatch: 'full'},
   {path: 'create', component: MessageTemplateAddComponent, pathMatch: 'full'},
   {path: ':id', component: MessageTemplateEditComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessageTemplatesRoutingModule {
}
