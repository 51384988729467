import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'backoffice-monorepo-authority-add',
  templateUrl: './authority-add.component.html',
  styleUrls: ['./authority-add.component.scss']
})
export class AuthorityAddComponent {
  name: FormControl = new FormControl('', Validators.required);

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  onSaveClick() {
    this.activeModal.close(this.name.value);
  }

}
