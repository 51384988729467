<div class="modal-header" cdkDrag cdkDragHandle cdkDragRootElement=".dragged-modal">
  <h4 class="modal-title">
    Set loan pending renouncement
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('closed')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="input-group col-sm-10">
    <label class="control-label mr-2" for="timestamp">Renouncement start date: </label>
    <input class="form-control form-control-sm"
           id="timestamp"
           [formControl]="renouncementStartDate"
           placeholder="yyyy-mm-dd"
           name="timestamp"
           ngbDatepicker
           #timestamp="ngbDatepicker">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary calendar" (click)="timestamp.toggle()" type="button"></button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" autofocus class="btn btn-danger" (click)="activeModal.dismiss(false)">Cancel</button>
  <button type="button" class="btn btn-success" [disabled]="renouncementStartDate.invalid" (click)="onOkClick()">Ok</button>
</div>
