import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'client-document-view',
  templateUrl: './client-document-view.component.html',
  styleUrls: ['./client-document-view.component.scss']
})
export class ClientDocumentViewComponent implements OnInit {

  @Input() documentId: number;
  @Input() clientId: number;

  clientDocumentUrl: string;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.clientDocumentUrl = `ws/api/client/${this.clientId}/document/${this.documentId}`;
  }

}
