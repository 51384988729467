<div class="container-fluid row pt-1 pb-2">
  <p class="ml-3">
    <b>Agreements</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-sm table-striped table-hover">
        <thead class="text-light bg-primary">
        <tr>
          <th>ID</th>
          <th>Loan ID</th>
          <th>Status</th>
          <th>Offer ID</th>
          <th>Application ID</th>
          <th>Amount</th>
          <th>New Amount</th>
          <th>Term</th>
          <th>Extension term</th>
          <th>Type</th>
          <th>Max Penalty Coeff</th>
          <th>Penalty Rate Per Day</th>
          <th>Penalty Grace Period Days</th>
          <th>Penalty Generation Period Beginning Days</th>
          <th>Discount Percent</th>
          <th>Interest Rate Per Year</th>
          <th>Annual Percentage Rate</th>
          <th>Loan purpose</th>
          <th>Start date</th>
          <th>Due date</th>
          <th>Sign date</th>
          <th>Closed date</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let agreement of agreements">
          <tr>
            <td>
              <button type="button" class="btn btn-sm btn-info" (click)="selectAgreement(agreement)" title="Select agreement">
                {{agreement.id}}
              </button>
            </td>
            <td>{{agreement.loanId}}</td>
            <td>{{agreement.status}}</td>
            <td>{{agreement.offerId}}</td>
            <td>{{agreement.loanApplicationId}}</td>
            <td>{{agreement.amount}}</td>
            <td>{{agreement.newAmount}}</td>
            <td>{{agreement.term.loanLength?.value}} {{agreement.term.loanLength?.unit}}</td>
            <td>{{agreement.extensionTerm?.loanLength?.value}} {{agreement.extensionTerm?.loanLength?.unit}}</td>
            <td>{{agreement.type}}</td>
            <td>{{agreement.maxPenaltyCoeff}}</td>
            <td>{{agreement.penaltyRatePerDay}}</td>
            <td>{{agreement.penaltyGracePeriodDays}}</td>
            <td>{{agreement.penaltyGenerationPeriodBeginningDays}}</td>
            <td>{{agreement.discountPercent}}</td>
            <td>{{agreement.interestRatePerYear | addPercentSymbol}}</td>
            <td>{{agreement.annualPercentageRate}}</td>
            <td>{{agreement.loanPurpose}}</td>
            <td>{{agreement.startDate | date:'dd.MM.yyyy'}}</td>
            <td>{{agreement.dueDate | date:'dd.MM.yyyy'}}</td>
            <td>{{agreement.signDate | date:'dd.MM.yyyy'}}</td>
            <td>{{agreement.closedDate | date:'dd.MM.yyyy'}}</td>
            <td>
              <button type="button"
                      class="btn btn-sm btn-info"
                      (click)="viewCommissions('agreement', agreement.commissionRateParameters)"
                      title="View commission rate parameters">
               %
              </button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </server-table>
  </div>
  <div class="col-md-12">
    <p>Schedule of selected agreement #{{selectedAgreement?.id}}</p>
    <server-table>
        <backoffice-monorepo-loan-schedule-items list-table [scheduleItems]="selectedAgreement?.scheduleItems"></backoffice-monorepo-loan-schedule-items>
    </server-table>
  </div>
</div>

<div class="container-fluid row pt-1 pb-2">
  <div class="col-md-12">

  </div>
</div>
