import { Injectable, Type } from '@angular/core';
import { CountryViewComponent } from '@backoffice-monorepo/commons';
import { PlPiLoanViewFieldsComponent } from '../components/pl-pi-loan-view-fields/pl-pi-loan-view-fields.component';
import { LoanViewFieldsComponentResolver } from '@backoffice-monorepo/commons';

@Injectable({
  providedIn: 'root'
})
export class PlPiLoanViewFieldsComponentResolverService implements LoanViewFieldsComponentResolver {
  resolve(): Type<CountryViewComponent> {
    return PlPiLoanViewFieldsComponent;
  }
}
