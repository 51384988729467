import { Component, OnInit } from '@angular/core';
import {
  CountryViewComponent,
  draggableModalOptions, draggableModalOptionsLarge,
  draggableModalOptionsXl,
  TransformInstructions,
  TransformInstructionsBuilder,
  ViewJsonDataComponent
} from '@backoffice-monorepo/commons';
import { Task } from '@twino/backoffice-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiCompareComponent } from '../pl-pi-compare/pl-pi-compare.component';

@Component({
  selector: 'backoffice-monorepo-pl-pi-task-related-entities',
  templateUrl: './pl-pi-task-related-entities.component.html',
  styleUrls: ['./pl-pi-task-related-entities.component.css']
})
export class PlPiTaskRelatedEntitiesComponent implements OnInit, CountryViewComponent {

  data: Task;
  manualWarnings: string | unknown;

  taskTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['', ['Manual warnings', () => `${this.manualWarnings}`.asString()]],
    ]
  );

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.manualWarnings = this.manualWarningsTransform();
  }

  manualWarningsTransform() {
    return this.data.relatedEntities['manualWarnings'] ? this.data.relatedEntities['manualWarnings'] : '';
  }

  isManualIdentification(): boolean {
    return !!(this.data.relatedEntities['identificationData']);
  }

  viewRelatedEntities() {
    const modalRef = this.modalService.open(ViewJsonDataComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.data = this.data.relatedEntities;
    modalRef.componentInstance.title = "Task Related Entities";
  }

  viewIdentificationData() {
    const modalRef = this.modalService.open(ViewJsonDataComponent, draggableModalOptions);
    modalRef.componentInstance.data = JSON.parse(this.data.relatedEntities['identificationData']);
    modalRef.componentInstance.title = "Identification Data";
  }

  viewCompareData() {
    const modalRef = this.modalService.open(PlPiCompareComponent, draggableModalOptionsXl);
    modalRef.componentInstance.applicationId = this.data.applicationId;
  }
}
