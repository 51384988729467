import { Injectable, Type } from '@angular/core';
import { CountryViewComponent, LoanExtensionFeesComponentResolver } from '@backoffice-monorepo/commons';
import { PlPiLoanExtensionFeesComponent } from '../components/pl-pi-loan-extension-fees/pl-pi-loan-extension-fees.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiLoanExtensionFeesComponentResolverService implements LoanExtensionFeesComponentResolver {
  resolve(): Type<CountryViewComponent> {
    return PlPiLoanExtensionFeesComponent;
  }
}
