import { Type } from '@angular/core';
import { CountrySpecific } from './country-specific';

export class ClientSpecificComponentRecord {
  index: number
  title: string
  type: Type<CountrySpecific>
}

export interface ClientSidebarComponentResolver extends CountrySpecific {

  resolve(): ClientSpecificComponentRecord[]
}
