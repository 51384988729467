import { Component, Host, OnInit } from '@angular/core';
import { BatchJobService, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import { BatchJobExecutionDetails, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BatchJobRunComponent } from '../batch-job-run/batch-job-run.component';

@Component({
  selector: 'backoffice-monorepo-batch-job-list',
  templateUrl: './batch-job-list.component.html',
  styleUrls: ['./batch-job-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<BatchJobExecutionDetails>) => new FilteringService(listService),
      deps: [BatchJobService]
    }
  ]
})
export class BatchJobListComponent extends ListComponent<BatchJobExecutionDetails> implements OnInit {
  constructor(
    @Host() filteringService: FilteringService<BatchJobExecutionDetails>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getName(): string {
    return 'Batch job executions';
  }

  navigateTo(id: number) {
    this.router.navigate([`/batch-job-executions/${id}`])
  }

  runJob() {
    const modalRef = this.modalService.open(BatchJobRunComponent);
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.refresh();
        }
      }, () => {}
    );
  }
}
