import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  EnumResponse,
  LoanSalePartner,
  LoanSalePartnerRequest,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalePartnerService extends BaseService implements SearchQueryAware<LoanSalePartner> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<LoanSalePartner>> {
    return this.post(`/api/loan-sale/partner/search`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/loan-sale/partner/enum-values/${enumClass}`);
  }

  createPartner(request: LoanSalePartnerRequest) {
    return this.post(`/api/loan-sale/partner`, request);
  }

  updatePartner(id: number, request: LoanSalePartnerRequest) {
    return this.put(`/api/loan-sale/partner/${id}`, request);
  }
}
