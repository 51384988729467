<div class="modal-header">
  <h4 class="modal-title">View campaign #{{id}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        Campaign
      </div>
      <div class="card-body">
        <transform-table [transformInstructions]="campaignTransformations" [entity]="campaignInfo"></transform-table>
        <table class="table table-striped table-hover mt-2">
          <thead class="text-light bg-primary">
          <tr>
            <th>ID</th>
            <th>Discount target</th>
            <th>Discount percent</th>
            <th>Predicate</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let discount of campaignInfo?.discounts; trackBy: trackById">
            <td>{{discount.id}}</td>
            <td>{{discount.discountTarget}}</td>
            <td>{{discount.discountRatio * 100}}</td>
            <td>
              <ul class="list-group">
                <li class="list-group-item" *ngFor="let item of discount.predicate.usedVariables | keyvalue;">
                  {{item.key}}: {{item.value}}
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
</div>


