import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, NamedComponent, ViewHistoryService, ProductConfigService } from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductConfig, ProductConfigurationRequest } from '@twino/backoffice-api';
import { ProductConfigRefreshService } from '../../services/product-config-refresh.service';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { forkJoin, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'product-config-add-component',
  templateUrl: './product-config-add.component.html',
  styleUrls: ['./product-config-add.component.scss']
})
export class ProductConfigAddComponent extends NamedComponent implements OnInit {

  productConfigAddForm: FormGroup;
  productConfigRequest: ProductConfigurationRequest;
  productTypesList: string[];
  productSubTypesList: string[];
  public editorOptions: JsonEditorOptions;
  public productBrandsList$: Observable<string[]>

  @ViewChild(JsonEditorComponent, {static: false}) editor: JsonEditorComponent;

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private productConfigService: ProductConfigService,
    private formBuilder: FormBuilder,
    private productConfigRefreshService: ProductConfigRefreshService,
    private viewHistoryService: ViewHistoryService,
    private productConfig: ProductConfig
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = this.productConfig.jsonEditorModes;
    this.productBrandsList$ = this.productConfigService.listBrands();

    forkJoin({
      productTypesList: this.productConfigService.listProductTypes(),
      productSubTypesList: this.productConfigService.listProductSubTypes()
    })
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(({productTypesList, productSubTypesList}) => {
      this.productTypesList = productTypesList;
      this.productSubTypesList = productSubTypesList;
    });

    this.productConfigAddForm = this.formBuilder.group({
      name: ['', Validators.required],
      subType: ['', Validators.required],
      brand: [''],
      data: ['', Validators.required]
    });
  }

  getName(): string {
    return `Create product configuration record`;
  }

  submitForm() {
    this.productConfigAddForm.value.data.subType = this.productConfigAddForm.value.subType;
    this.productConfigRequest = {
      name: this.productConfigAddForm.value.name,
      data: this.productConfigAddForm.value.data
    };
    const brand = this.productConfigAddForm.value.brand;
    const subtype = this.productConfigRequest.data.subType;

    const updateProductConfig = brand ?  this.productConfigService.updateProductConfigByBrand(subtype, this.productConfigRequest, brand)
      : this.productConfigService.updateProductConfig(subtype, this.productConfigRequest);

    updateProductConfig
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess("Product configuration record is added");
      this.viewHistoryService.closeTab();
      this.productConfigRefreshService.reloadProductConfigsListModel();
    })
  }
}
