import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslationManagerRoutingModule } from './translation-manager-routing.module';
import { TranslationsPage } from './pages/translations/translations.page';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { TranslationBuilderService } from './services/translation-builder.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AddLanguageModalComponent } from './components/add-language-modal/add-language-modal.component';
import { RemoveLanguageModalComponent } from './components/remove-language-modal/remove-language-modal.component';
import { TranslationManagerConfig, TranslationManagerConfigToken } from './services/translation-manager-config';


@NgModule({
  declarations: [
    TranslationsPage,
    AddLanguageModalComponent,
    RemoveLanguageModalComponent
  ],
  imports: [
    CommonModule,
    NgJsonEditorModule,
    TranslationManagerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbModalModule,
    TranslateModule
  ]
})
export class TranslationManagerModule {
  static  forRoot(config: TranslationManagerConfig): ModuleWithProviders<TranslationManagerModule> {
    return {
      ngModule: TranslationManagerModule,
      providers: [
        {
          provide: TranslationManagerConfigToken,
          useValue: config.schema as () => TranslationManagerConfig
        },
        TranslationBuilderService,
        NgbActiveModal
      ]
    }
  }
}
