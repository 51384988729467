export interface ClientDocument {
  id: number
  clientId: number
  externalFileUid: string
  type: string
  verificationStatus: ClientDocumentVerificationStatus
  description?: string
  contentType?: string
  created: Date
  loanId?: number
  sourceId: number
  sourceType: NotificationDataSourceType
}

export interface UpdateClientDocumentRequest {
  type: string
  verificationStatus: string
  description: string
}

export interface UploadClientDocumentRequest {
  type: string
  verificationStatus: string
  description: string
  sourceId: number
  sourceType: NotificationDataSourceType
}

export enum NotificationDataSourceType {
  LOAN = 'LOAN',
  CLIENT = 'CLIENT',
  LOAN_APPLICATION = 'LOAN_APPLICATION',
  LOAN_OFFER = 'LOAN_OFFER',
  CLIENTLESS = 'CLIENTLESS'
}

export enum ClientDocumentVerificationStatus {
  REQUIRED = 'REQUIRED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  OK = 'OK',
  INVALID = 'INVALID',
  CANCELLED = 'CANCELLED'
}
