import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientsRoutingModule } from './clients-routing.module';
import { ClientComponent } from './components/client/client.component';
import { ClientsListComponent } from './components/clients-list/clients-list.component';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbDatepickerModule, NgbNavModule, NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientEditComponent } from './components/client-edit/client-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientIncomeEditComponent } from './components/client-income-edit/client-income-edit.component';
import { ClientIdentityDocumentAddComponent } from './components/client-identity-document-add/client-identity-document-add.component';
import { ClientIdentityDocumentEditComponent } from './components/client-identity-document-edit/client-identity-document-edit.component';
import { ClientDocumentUploadComponent } from './components/client-document-upload/client-document-upload.component';
import { ClientDocumentEditComponent } from './components/client-document-edit/client-document-edit.component';
import { ClientDocumentViewComponent } from './components/client-document-view/client-document-view.component';
import { ClientAddressEditComponent } from './components/client-address-edit/client-address-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClientSmsSendComponent } from './components/client-sms-send/client-sms-send.component';
import { ClientEmailSendComponent } from './components/client-email-send/client-email-send.component';
import { DynamicModule } from 'ng-dynamic-component';
import { ClientApplicationsComponent } from './components/client-applications/client-applications.component';
import { ClientLoansComponent } from './components/client-loans/client-loans.component';
import { ClientIdentityDocumentsComponent } from './components/client-identity-documents/client-identity-documents.component';
import { ClientDocumentsComponent } from './components/client-documents/client-documents.component';
import { ClientDetailsComponent } from './components/client-details/client-details.component';
import { ClientActivityOverviewComponent } from './components/client-activity-overview/client-activity-overview.component';
import { ClientCommunicationsComponent } from './components/client-communications/client-communications.component';
import { ClientPaymentsComponent } from './components/client-payments/client-payments.component';
import { ClientPaymentDistributionsComponent } from './components/client-payment-distributions/client-payment-distributions.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClientBlockComponent } from './components/client-block/client-block.component';
import { ClientConsentsComponent } from './components/client-consents/client-consents.component';
import { ClientTasksComponent } from './components/client-tasks/client-tasks.component';
import { ClientDiscountAddComponent } from './components/client-discount-add/client-discount-add.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ClientAmlDashboardComponent } from './components/client-aml-dashboard/client-aml-dashboard.component';
import { ClientAmlScoringComponent } from './components/client-aml-scoring/client-aml-scoring.component';
import { ClientAmlScoringHistoryComponent } from './components/client-aml-scoring-history/client-aml-scoring-history.component';
import { ClientLoginAttemptsComponent } from './components/client-login-attempts/client-login-attempts.component';


@NgModule({
  declarations: [
    ClientComponent,
    ClientsListComponent,
    ClientEditComponent,
    ClientIncomeEditComponent,
    ClientIdentityDocumentAddComponent,
    ClientIdentityDocumentEditComponent,
    ClientDocumentUploadComponent,
    ClientDocumentEditComponent,
    ClientDocumentViewComponent,
    ClientAddressEditComponent,
    ClientSmsSendComponent,
    ClientEmailSendComponent,
    ClientApplicationsComponent,
    ClientLoansComponent,
    ClientIdentityDocumentsComponent,
    ClientDocumentsComponent,
    ClientDetailsComponent,
    ClientActivityOverviewComponent,
    ClientCommunicationsComponent,
    ClientPaymentsComponent,
    ClientPaymentDistributionsComponent,
    ClientBlockComponent,
    ClientConsentsComponent,
    ClientTasksComponent,
    ClientDiscountAddComponent,
    ClientAmlDashboardComponent,
    ClientAmlScoringComponent,
    ClientAmlScoringHistoryComponent,
    ClientLoginAttemptsComponent,
  ],
	imports: [
		CommonModule,
		ClientsRoutingModule,
		CommonsModule,
		NgbNavModule,
		NgbDatepickerModule,
		FormsModule,
		ReactiveFormsModule,
		NgbPopoverModule,
		TranslateModule,
		DynamicModule,
		DragDropModule,
		NgbPaginationModule,
		EditorModule
	]
})
export class ClientsModule {
}
