import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationConfigsListComponent } from './components/notification-configs-list/notification-configs-list.component';
import { NotificationConfigComponent } from './components/notification-config/notification-config.component';
import { ProductSubTypesResolver } from './resolvers/product-sub-types-resolver';


const routes: Routes = [
   {path: '', component: NotificationConfigsListComponent, pathMatch: 'full'},
   {
     path: ':id',
     component: NotificationConfigComponent,
     resolve: {
       productSubTypes: ProductSubTypesResolver
     }
   }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationConfigsRoutingModule {
}
