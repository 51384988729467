import { Component, Host, OnInit } from '@angular/core';
import { BlacklistService, draggableModalOptions, FilteringService, ListComponent, ModalResult } from '@backoffice-monorepo/commons';
import { SearchQueryAware } from '@twino/backoffice-api';
import { Blacklist } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlacklistAddComponent } from '../blacklist-add/blacklist-add.component';
import { BlacklistEditComponent } from '../blacklist-edit/blacklist-edit.component';

@Component({
  selector: 'backoffice-monorepo-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Blacklist>) => new FilteringService(listService),
      deps: [BlacklistService]
    }
  ]
})
export class BlacklistComponent extends ListComponent<Blacklist> implements OnInit {

  exportModuleName = "personal-id-blacklist";

  constructor(
    @Host() filteringService: FilteringService<Blacklist>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
    private blacklistService: BlacklistService,
    private modalService: NgbModal
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getName(): string {
    return 'Blacklist';
  }

  navigateTo(id: number) {
    this.router.navigate([`/clients/${id}`]);
  }

  onAddClick() {
    const modalRef = this.modalService.open(BlacklistAddComponent, draggableModalOptions);
    modalRef.result.then(result => {
      if (result === ModalResult.SUCCESS) {
        this.refresh();
      }
    });
  }

  openEditModal(record: Blacklist) {
    const modalRef = this.modalService.open(BlacklistEditComponent, draggableModalOptions);
    modalRef.componentInstance.blacklistId = record.id;

    modalRef.result.then(result => {
      if (result === ModalResult.SUCCESS) {
        this.refresh();
      }
    });
  }
}
