import { Component, Input, OnInit } from '@angular/core';
import { FilterOperation, FullClient, Loan, SearchQueryBuilder } from '@twino/backoffice-api';
import { ClientSidebarLibComponent, LoanService } from '@backoffice-monorepo/commons';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-loans',
  templateUrl: './client-loans.component.html',
  styleUrls: ['./client-loans.component.scss']
})
export class ClientLoansComponent implements ClientSidebarLibComponent,OnInit {

  @Input()
  client: FullClient;
  loans: Loan[] | null;

  constructor(
    private loanService: LoanService,
    protected router: Router
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [this.client.savedId.toString()]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .build()

    return this.loanService.find(searchQuery).pipe(
      take(1)
    ).subscribe((loans) => {
      this.loans = loans.results;
    })
  }

  trackById(index, item){
    return item.id;
  }
}
