import { Component, Host, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilteringService, ListComponent, LoanService } from '@backoffice-monorepo/commons';
import { DateFilterDefaultValues, FilterOperation, Loan, SearchQueryAware } from '@twino/backoffice-api';


@Component({
  selector: 'loans-list',
  templateUrl: './loans-list.component.html',
  styleUrls: ['./loans-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Loan>) => new FilteringService(listService),
      deps: [LoanService]
    }
  ]
})
export class LoansListComponent extends ListComponent<Loan> implements OnInit {

  exportModuleName = "affiliate-reports";
  readonly FilterOperation = FilterOperation;
  readonly DateFilterValues = DateFilterDefaultValues;

  constructor(
    @Host() filteringService: FilteringService<Loan>,
    protected router: Router,
    loanService: LoanService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getName(): string {
    return 'Loans';
  }

  navigateTo(id: string | number) {
    this.router.navigate([`/loans/${id}`]);
  }
}
