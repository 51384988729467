import { SearchModel } from './search/search.model';

export class BatchJobExecutionDetails implements SearchModel {
  title: string;
  jobInstanceId: number;
  jobExecutionId: number;
  startTime?: Date;
  endTime?: Date;
  status?: string;
}

export interface BatchJobExecution {
  title: string;
  status: string;
  startTime: Date;
  endTime: Date;
  exitCode: string;
  exitDescription: string;
  jobExecutionId: number;
  jobInstanceId: number;
  stepExecutions: StepExecution[];
}

export interface StepExecution {
  commitCount: number;
  endTime: Date;
  exitCode: string;
  exitDescription: string;
  filterCount: number;
  id: number;
  name: string;
  processSkipCount: number;
  readCount: number;
  readSkipCount: number;
  rollbackCount: number;
  startTime: Date;
  status: string;
  version: number;
  writeCount: number;
  writeSkipCount: number;
}

export interface BatchJobDetails {
  jobName: string
}

export interface BatchJobRunResul {
  executionId: number
  executionSummary: string
  stepExecutionSummary: Map<number, string>
}
