<div class="form-inline form-group" *ngIf="(totalRecords >= itemsPerPage)">
  <ngb-pagination class="pages"
                  size="sm"
                  [collectionSize]="totalRecords"
                  [page]="currentPage"
                  [pageSize]="itemsPerPage"
                  [maxSize]="10"
                  [rotate]="false"
                  [ellipses]="true"
                  [boundaryLinks]="true"
                  (pageChange)="pageChange($event)">
    <ng-template ngbPaginationPrevious>Previous</ng-template>
    <ng-template ngbPaginationNext>Next</ng-template>
    <ng-template ngbPaginationFirst>First</ng-template>
    <ng-template ngbPaginationLast>Last</ng-template>
  </ngb-pagination>
  <div class="input-group input-group-sm mb-3">
    <!--suppress HtmlFormInputWithoutLabel -->
    <input class="goto-page" type="text" [(ngModel)]="newPage"/>
    <div class="input-group-append" *ngIf="(!!newPage && newPage !== currentPage)">
      <button class="btn btn-secondary" (click)="pageChange()" type="button">
        SetPage
      </button>
    </div>
  </div>
</div>
