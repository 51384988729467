export class BillingDetail {
  id: number
  type: string
  clientId: number
  default: boolean
  bankName: string
  bankCode: string
  owner?: string
  number: string
}


export class ClientEditBillingDetailsRequest {
  bankName: string
  bankCode: string
  owner?: string
  number: string
}
