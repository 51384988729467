import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RiskEvaluationRefreshService implements OnDestroy{

  public reloadRiskEvaluationConfigModel$ = new Subject<void>();
  public reloadRiskEvaluationFlowModel$ = new Subject<void>();

  reloadRiskEvaluationConfigModel() {
    this.reloadRiskEvaluationConfigModel$.next();
  }

  reloadRiskEvaluationFlowModel() {
    this.reloadRiskEvaluationConfigModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadRiskEvaluationConfigModel$.unsubscribe();
    this.reloadRiskEvaluationFlowModel$.unsubscribe();
  }
}
