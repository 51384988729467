import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClientDocumentService, ClientService, ClientSidebarLibComponent, draggableModalOptions } from '@backoffice-monorepo/commons';
import { ClientDocument, FullClient } from '@twino/backoffice-api';
import { ClientDocumentEditComponent } from '../client-document-edit/client-document-edit.component';
import { ClientDocumentViewComponent } from '../client-document-view/client-document-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-documents',
  templateUrl: './client-documents.component.html',
  styleUrls: ['./client-documents.component.scss']
})
export class ClientDocumentsComponent implements ClientSidebarLibComponent,OnInit, OnDestroy {

  private reloadClientDocumentModel: Subscription;

  @Input()
  client: FullClient;
  documents: ClientDocument[] | null;
  clientDocumentUrl: string;

  constructor(
    private clientDocumentService: ClientDocumentService,
    private modalService: NgbModal,
    private clientsRefreshService: ClientsRefreshService,
  ) { }

  ngOnInit(): void {
    this.refresh();
    this.clientDocumentUrl = `ws/api/client/${this.client.savedId}/document/`;
    this.reloadClientDocumentModel = this.clientsRefreshService.reloadClientDocumentModel$.subscribe(() => {
      this.refresh();
    })
  }


  refresh() {
    return this.clientDocumentService.getClientDocuments(this.client.savedId).pipe(
      take(1)
    ).subscribe((documents) => {
      this.documents = documents;
    })
  }


  editClientDocument(clientDocument) {
    const modalRef = this.modalService.open(ClientDocumentEditComponent, draggableModalOptions);
    modalRef.componentInstance.clientDocument = clientDocument;
  }


  viewClientDocument(documentId) {
    const modalRef = this.modalService.open(ClientDocumentViewComponent, draggableModalOptions);
    modalRef.componentInstance.documentId = documentId;
    modalRef.componentInstance.clientId = this.client.savedId;
  }

  showViewDocumentButton(contentType) {
    return contentType.match(/(jpeg)|(png)\/*/);
  }

  ngOnDestroy(): void {
    this.reloadClientDocumentModel.unsubscribe();
  }
}
