import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ErrorsService } from './errors.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ClientDocument,
  IdentityDocument,
  IdentityDocumentRequest,
  NotificationDataSourceType,
  UpdateClientDocumentRequest,
  UploadClientDocumentRequest
} from '@backoffice-monorepo/api';

@Injectable({
  providedIn: 'root'
})
export class ClientDocumentService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getClientIdentityDocuments(id: number): Observable<Array<IdentityDocument>> {
    return this.get(`/api/client/${id}/identity-documents`);
  }

  createIdentityDocument(clientId: number, request: IdentityDocumentRequest): Observable<never> {
    request.type = 'ID_CARD';
    return this.post(`/api/client/${clientId}/create-identity-document`, request);
  };

  updateIdentityDocument(clientId: number, request: IdentityDocumentRequest, documentId: number): Observable<never> {
    request.type = 'ID_CARD';
    return this.post(`/api/client/${clientId}/update-identity-document/${documentId}`, request);
  };

  getClientDocuments(id: number): Observable<Array<ClientDocument>> {
    return this.get(`/api/client/${id}/documents`);
  }

  getClientDocumentTypes(): Observable<Array<string>> {
    return this.get(`/api/client/document-types`);
  };

  getClientDocumentVerificationStatuses(): Observable<Array<string>> {
    return this.get(`/api/client/document-verification-statuses`);
  };

  updateClientDocument(clientId: number, request: UpdateClientDocumentRequest, documentId: number): Observable<never> {
    return this.post(`/api/client/${clientId}/document-details/${documentId}`, request);
  };

  uploadClientDocument(clientId: number, request: UploadClientDocumentRequest, file: File): Observable<never> {
    const formData = new FormData();
    formData.append(
      'document',
      new Blob(
        [JSON.stringify(request)],
        {type: "application/json"}
      )
    );
    formData.append('file', file);

    return this.post(`/api/client/${clientId}/upload-document`, formData);
  };

  listDocumentSourceTypes(): Observable<Array<NotificationDataSourceType>> {
    return this.get(`/api/client/document-source-types`);
  };
}
