import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ErrorsService } from './errors.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateRoleRequest, UserRole } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getRoles(): Observable<UserRole[]> {
    return this.get('/api/user-roles');
  }

  getRoleById(id: number): Observable<UserRole> {
    return this.get(`/api/user-role/${id}`);
  }

  createRole(createRoleRequest: CreateRoleRequest): Observable<UserRole> {
    return this.post('/api/user-role', createRoleRequest);
  }

  updateRole(id: number, authorityNames: string[]) {
    return this.post(`/api/user-role/${id}`, authorityNames);
  }

  deleteRole(id: number) {
    return this.delete(`/api/user-role/${id}`);
  }
}
