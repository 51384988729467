<form [formGroup]="loanProlongationResignationForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Loan Prolongation Resignation</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="originator" class="col-sm-4 control-label">Originator<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select
          class="form-control form-control-sm"
          id="originator"
          formControlName="originator"
          name="originator">
          <option *ngFor="let key of originators$ | async" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="resignationCause" class="col-sm-4 control-label">Resignation cause<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="resignationCause" formControlName="resignationCause"
               name="resignationCause">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!loanProlongationResignationForm.valid">Resign</button>
  </div>
</form>
