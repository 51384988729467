import { Injectable, Type } from '@angular/core';
import { LoanSidebarLibComponent } from '@backoffice-monorepo/commons';
import { LoanPaymentsComponent } from '../../../../core/src/lib/modules/loans/components/loan-payments/loan-payments.component';
import { LoanPaymentDistributionsComponent } from '../../../../core/src/lib/modules/loans/components/loan-payment-distributions/loan-payment-distributions.component';
import { LoanTransactionsComponent } from '../../../../core/src/lib/modules/loans/components/loan-transactions/loan-transactions.component';
import { LoanActivityOverviewComponent } from '../../../../core/src/lib/modules/loans/components/loan-activity-overview/loan-activity-overview.component';
import { LoanAgreementsComponent } from '../../../../core/src/lib/modules/loans/components/loan-agreements/loan-agreements.component';

export class LoanLibComponentRecord {
  index: number
  title: string
  type: Type<LoanSidebarLibComponent>
}

export interface LoanSidebarLibComponentResolver {
  resolve(): LoanLibComponentRecord[]
}

@Injectable({
  providedIn: 'root'
})
export class LoanSidebarLibComponentResolver implements LoanSidebarLibComponentResolver {

  resolve(): LoanLibComponentRecord[] {
    return [
      {index: 1, title: 'loan.sidebar.agreements', type: LoanAgreementsComponent},
      {index: 2, title: 'menu.workplace.payments', type: LoanPaymentsComponent},
      {index: 3, title: 'menu.workplace.paymentDistributions', type: LoanPaymentDistributionsComponent},
      {index: 4, title: 'loan.sidebar.transactions', type: LoanTransactionsComponent},
      {index: 5, title: 'menu.workplace.activityOverview', type: LoanActivityOverviewComponent},
    ]
  }

}
