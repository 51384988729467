<form [formGroup]="clientSmsSendForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Send SMS to client #{{clientId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="sender" class="col-sm-4 control-label">Sender<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select ngbAutofocus class="form-control form-control-sm" id="sender" formControlName="sender" name="sender">
          <option *ngFor="let sender of senders | keyvalue" [ngValue]="sender.key">{{sender.value}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="destination" class="col-sm-4 control-label">Phone number</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="destination" formControlName="destination" name="destination">
      </div>
    </div>
    <div class="form-group row">
      <label for="templateKey" class="col-sm-4 control-label">Message template<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select ngbAutofocus class="form-control form-control-sm" id="templateKey" formControlName="templateKey" name="templateKey">
          <option *ngFor="let template of messageTemplates" [ngValue]="template.key">{{template.key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="content" class="col-sm-4 control-label">Content</label>
      <div class="col-sm-8">
          <textarea class="form-control" id="content" formControlName="content" rows="3"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 control-label">Symbols: {{clientSmsSendForm.controls.content.value.length}}</label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientSmsSendForm.valid">Send</button>
  </div>
</form>






