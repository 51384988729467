<ul ngbNav class="nav-tabs view-history" [activeId]="router.url" *ngIf="showNav()">
  <li class="controls">
    <a ngbPopover="Close all exept active tab" triggers="mouseenter:mouseleave" placement="right" (click)="closeAllExceptCurrent()">
      <bi name="x-circle" class="bigger"></bi>
    </a>
  </li>
  <li class="controls last">
    <a class="overlap" ngbPopover="Close all tabs" triggers="mouseenter:mouseleave" placement="right" (click)="closeAll()">
      <bi name="x" class="bold-svg bigger"></bi>
      <sup>
        <bi name="x" class="bold-svg"></bi>
      </sup>
    </a>
  </li>
  <li [ngbNavItem]="historyEntry.key" *ngFor="let historyEntry of getHistory() | keyvalue: preserveSorting">
    <a ngbNavLink [routerLink]="historyEntry.key">{{historyEntry.value?.name | async}}
      <span class="close position-relative pl-2 font-weight-light" (click)="closeHistoryEntry($event, historyEntry.key)">×</span>
    </a>
  </li>
</ul>
