import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertsService, NamedComponent, ProductConfigService } from '@backoffice-monorepo/commons';
import { ProductConfigurationList, ProductConfigurationToVersionMatch } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ProductConfigRefreshService } from '../../services/product-config-refresh.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-product-configs-list',
  templateUrl: './product-configs-list.component.html',
  styleUrls: ['./product-configs-list.component.scss'],
})
export class ProductConfigsListComponent extends NamedComponent implements OnInit, OnDestroy {

  private reloadProductConfigListModel: Subscription;
  productConfigList$: Observable<ProductConfigurationList[]>;
  showCreateVersionButton = false;
  changedProductConfigs: ProductConfigurationToVersionMatch[] = [];

  constructor(
    protected router: Router,
    private productConfigService: ProductConfigService,
    activatedRoute: ActivatedRoute,
    private productConfigRefreshService: ProductConfigRefreshService,
    private alertService: AlertsService,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.listProductConfigs();
    this.checkProductConfigurationVersion();
    this.reloadProductConfigListModel = this.productConfigRefreshService.reloadProductConfigsListModel$.subscribe(() => {
      this.listProductConfigs();
      this.checkProductConfigurationVersion();
    })
  }

  ngOnDestroy(): void {
    this.reloadProductConfigListModel.unsubscribe();
  }

  getName(): string {
    return 'Product Configuration';
  }

  navigateTo(id: number) {
    this.router.navigate([`/product-configuration/${id}`]);
  }

  navigateToConfig(productType: string, productSubType: string, brand: string) {
    this.router.navigate([`/product-configuration/${productType}/sub-type/${productSubType}/${brand}`]);
  }

  navigateToCreate() {
    this.router.navigate([`/product-configuration/create`]);
  }

  listProductConfigs() {
    this.productConfigList$ = this.productConfigService.listProductConfigs();
  }

  createProductVersion() {
    this.productConfigService.saveProductConfigurationState().pipe(
      takeUntil(this.$destroy)).subscribe(() => {
        this.alertService.notifySuccess("Product configuration version is saved");
        this.listProductConfigs();
        this.checkProductConfigurationVersion();
      })
  }

  checkProductConfigurationVersion() {
    this.productConfigService.checkProductConfigurationVersion().pipe(
      take(1)
    ).subscribe(response => {
      this.changedProductConfigs = response.productConfigurationsMatchVersions.filter(item => item.matchesProductConfigurationVersion === false);
      this.showCreateVersionButton = (this.changedProductConfigs.length !== 0);
    })
  }

  isProductConfigChanged(productType: string, subType: string, brand: string): boolean {
    const match = this.changedProductConfigs.find(item =>
      item.productType === productType && item.subType === subType && item.brand === brand);
    return (match !== undefined);
  }
}
