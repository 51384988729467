import { CommunicationService } from '../../../services/communication.service';
import { Component, Input, OnInit } from '@angular/core';
import { Communication, Task } from '@backoffice-monorepo/api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mailbox-task-view',
  templateUrl: './mailbox-task-view.component.html',
  styleUrls: ['./mailbox-task-view.component.css']
})
export class MailboxTaskViewComponent implements OnInit {
  @Input() task: Task;

  clientCommunication: Communication;
  communicationId: number;

  constructor(
    private communicationService: CommunicationService
  ) {}

  ngOnInit(): void {
    this.communicationId = this.task.relatedEntities["communicationId"]
    this.communicationService.getClientCommunication(this.communicationId).pipe(
      take(1)
    )
      .subscribe(data => this.clientCommunication = data)
  }

  trackById(index, item){
    return item.id;
  }

}
