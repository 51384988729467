export class NotificationConfig {
  id?: number
  enabled: boolean
  eventClass: string
  typeId?: string
  templateKey: string
  attachmentConfigs: Set<NotificationAttachmentConfig>
  sendCriterion?: string
  sendCriterionArguments: Array<string>
  isSendOncePerDay: boolean
  isSendInCommunicationTimeWindow: boolean
  shouldStoreExternally: boolean
  created: Date
  productSubType?: string
  brand: string
}

export class NotificationAttachmentConfig {
  templateKey: string
  isEnabled: boolean
  clientDocumentType?: string
}

export class NotificationConfigRequest {
  typeId: string
  enabled: boolean
  templateKey: string
  attachmentConfigs?: Set<NotificationAttachmentConfig>
  sendCriterion?: string
  sendCriterionArguments: Array<string>
  isSendOncePerDay: boolean
  isSendInCommunicationTimeWindow: boolean
  shouldStoreExternally: boolean
  productSubType?: string
  brand: string
}

export class NotificationConfigFormData {
  typeId: string
  enabled: boolean
  templateKey: string
  attachmentConfigs?: string
  sendCriterion?: string
  sendCriterionArguments: Array<string>
  isSendOncePerDay: boolean
  isSendInCommunicationTimeWindow: boolean
  shouldStoreExternally: boolean
  productSubType?: string
  brand: string

  constructor(notificationConfig: NotificationConfig) {
    this.typeId = notificationConfig.typeId
    this.templateKey = notificationConfig.templateKey
    this.attachmentConfigs = JSON.stringify(notificationConfig.attachmentConfigs)
    this.enabled = notificationConfig.enabled
    this.isSendOncePerDay = notificationConfig.isSendOncePerDay
    this.isSendInCommunicationTimeWindow = notificationConfig.isSendInCommunicationTimeWindow
    this.shouldStoreExternally = notificationConfig.shouldStoreExternally
    this.sendCriterionArguments = notificationConfig.sendCriterionArguments
    this.sendCriterion = notificationConfig.sendCriterion
    this.productSubType = notificationConfig.productSubType
    this.brand = notificationConfig.brand
  }
}


export enum NotificationCommunicationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TTS = 'TTS',
}

export enum NotificationCommunicationTypeSuffix {
  EMAIL = '.email.body',
  SMS = '.sms',
  PUSH = '.push.body',
  TTS = '.tts',
}

const CommunicationTypeSuffixList: Record<NotificationCommunicationType, string[]> = {
  EMAIL: ['.email.subject', '.email.body'],
  SMS: ['.sms'],
  PUSH: ['.push.title', '.push.body', '.push.link', '.push.icon'],
  TTS: ['.tts']
}

export function getCommunicationTypeSuffixList(notificationCommunicationType: NotificationCommunicationType | null) {
  switch (notificationCommunicationType) {
    case NotificationCommunicationType.EMAIL:
    case NotificationCommunicationType.SMS:
    case NotificationCommunicationType.PUSH:
    case NotificationCommunicationType.TTS:
      return CommunicationTypeSuffixList[notificationCommunicationType];
    default:
      return [''];
  }
}
