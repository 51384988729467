<td>{{title}}</td>
<td>
  <label>
    <input
      type="text"
      class="form-control form-control-sm text-secondary"
      [(ngModel)]="operation"
      [ngbTypeahead]="searchElementOperation"
      (ngModelChange)="publishFilterData()"
      (focus)="focus$.next($any($event).target.value)"
      [resultTemplate]="typeaheadFilterOptionsTemplate"
      (selectItem)="elementOperationSelected($event)"
      #typeaheadFilterOptions="ngbTypeahead"
    />
  </label>
</td>

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'list'" [ngTemplateOutlet]="list"></ng-container>
  <ng-container *ngSwitchCase="'enum'" [ngTemplateOutlet]="list"></ng-container>

  <ng-container *ngSwitchCase="'date'" [ngTemplateOutlet]="date"></ng-container>
  <ng-container *ngSwitchCase="'instant'" [ngTemplateOutlet]="instant"></ng-container>
  <ng-container *ngSwitchCase="'datetime'" [ngTemplateOutlet]="datetime"></ng-container>
  <ng-container *ngSwitchCase="'custom'" [ngTemplateOutlet]="custom"></ng-container>

  <ng-container *ngSwitchCase="'id'" [ngTemplateOutlet]="text"></ng-container>
  <ng-container *ngSwitchCase="'string'" [ngTemplateOutlet]="text"></ng-container>
  <ng-container *ngSwitchCase="'text'" [ngTemplateOutlet]="text"></ng-container>
  <ng-container *ngSwitchCase="'string-with-digits'" [ngTemplateOutlet]="text"></ng-container>
  <ng-container *ngSwitchCase="'number'" [ngTemplateOutlet]="number"></ng-container>
  <ng-container *ngSwitchCase="'decimal'" [ngTemplateOutlet]="text"></ng-container>
  <ng-container *ngSwitchCase="'boolean'" [ngTemplateOutlet]="boolean"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="text"></ng-container>
</ng-container>

<td (click)="resetContent()">
  <bi name="x" class="bold-svg bigger"></bi>
</td>

<!-- typeahead -->
<ng-template #typeaheadFilterOptionsTemplate let-r="result" let-t="term">
  <ngb-highlight [result]="r" [term]="t">{{r}}</ngb-highlight>
</ng-template>

<!-- templates -->
<ng-template #text>
  <td>
    <label *ngIf="numberOfValues">
        <input type="text"
               class="form-control form-control-sm mb-1"
               [(ngModel)]="value"
               ngModelOptions="{ standalone: true }"
               (ngModelChange)="valueChanged()">
    </label>
  </td>
</ng-template>

<ng-template #number>
  <td>
    <label *ngIf="numberOfValues">
      <div *ngFor="let n of numSequence(numberOfValues); let i = index;">
        <input type="text"
               class="form-control form-control-sm mb-1"
               [(ngModel)]="values[i]"
               ngModelOptions="{ standalone: true }"
               (ngModelChange)="valueChanged()">
      </div>
    </label>
  </td>
</ng-template>

<ng-template #date>
  <td>
    <div class="form-inline" *ngIf="numberOfValues">
      <div class="form-group mb-1" *ngFor="let n of numSequence(numberOfValues); let i = index;">
        <div class="input-group">
          <!--suppress HtmlFormInputWithoutLabel -->
          <input ngbDatepicker class="form-control form-control-sm"
                 placeholder="yyyy-mm-dd"
                 name="dp" [(ngModel)]="values[i]"
                 #filterElementDatepicker="ngbDatepicker"
                 (dateSelect)="valueChanged()"
                 (ngModelChange)="valueChanged()"
          >
          <div class="input-group-append">
            <button class="btn btn-outline-secondary btn-sm calendar" (click)="filterElementDatepicker.toggle()" type="button"></button>
          </div>
        </div>
      </div>
    </div>
  </td>
</ng-template>

<ng-template #instant>
  <td>
    <div class="form-inline" *ngIf="numberOfValues">
      <div class="form-group mb-1" *ngFor="let n of numSequence(numberOfValues); let i = index;">
        <div class="input-group">
          <date-time-picker
            [(ngModel)]="values[i]"
            [date]="instantValue"
            name="dp"
            [propertyName]="property"
            (ngModelChange)="valueChanged()"
          ></date-time-picker>
        </div>
      </div>
    </div>
  </td>
</ng-template>

<ng-template #datetime>
  <td>
    <div class="form-inline" *ngIf="numberOfValues">
      <div class="form-group mb-1">
        <div class="input-group">
          <date-time-picker
            [(ngModel)]="value"
            [date]="value"
            name="dp"
            (ngModelChange)="valueChanged()"
          ></date-time-picker>
        </div>
      </div>
    </div>
  </td>
</ng-template>

<ng-template #list>
  <td>
    <div *ngIf="numberOfValues">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary btn-sm" id="dropdownBasic1" ngbDropdownToggle>{{value}}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="setValue('')">&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button ngbDropdownItem *ngFor="let listItem of listValues" (click)="setValue(listItem)">{{listItem}}</button>
        </div>
      </div>
    </div>
  </td>
</ng-template>

<ng-template #custom>
  <td>
    <ng-content></ng-content>
  </td>
</ng-template>

<ng-template #boolean>
  <td>
    <div *ngIf="numberOfValues">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary btn-sm" id="dropdownBasic2" ngbDropdownToggle>{{value}}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="setValue('')">&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <button ngbDropdownItem *ngFor="let listItem of booleanValues" (click)="setValue(listItem)">{{listItem}}</button>
        </div>
      </div>
    </div>
  </td>
</ng-template>
