import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PlPiApplicationService } from '../../services/pl-pi-application.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogueComponent, CountryViewComponent } from '@backoffice-monorepo/commons';
import { Application } from '@twino/backoffice-api';
import { ApplicationRefreshService } from '../../../../../../libs/core/src/lib/modules/applications/services/application-refresh.service';
import { CardIssueType, UpdateApplicationCardTypeRequest } from '../../api/client.card';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-application-button-view',
  templateUrl: './pl-pi-loan-application-button-view.component.html',
  styleUrls: ['./pl-pi-loan-application-button-view.component.scss']
})
export class PlPiLoanApplicationButtonViewComponent implements CountryViewComponent, OnInit, OnChanges {
  @Input() data: Application;
  applicationCardType: CardIssueType;
  cardIssueType = CardIssueType;
  cardTypeOptions = [];

  constructor(
    private applicationService: PlPiApplicationService,
    private modalService: NgbModal,
    private applicationRefreshService: ApplicationRefreshService,
  ) { }

  ngOnInit(): void {
    this.cardTypeOptions = Object.keys(this.cardIssueType);
  }

  ngOnChanges() {
    if(this.data?.id) {
      this.applicationService.getApplicationCardType(this.data?.id).pipe(take(1)).subscribe(status => {
        this.applicationCardType = status;
      })
    }
  }

  manualCancel() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Cancel application #${this.data.id}`;
    modalRef.componentInstance.content = 'Are you sure ?';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.applicationService.manualCancelApplication(this.data.id).subscribe(() => {
            this.applicationRefreshService.reloadApplicationModel();
          });
        }
      }, () => {}
    );
  }

  changeCardType(event: any) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Change card type #${this.data.id}`;
    modalRef.componentInstance.content = 'Are you sure you want to change card type for this application?';
    modalRef.result.then(
      (result) => {
        if (result) {
          const request: UpdateApplicationCardTypeRequest = {
            cardIssueType: event.target.value
          }
          this.applicationService.updateApplicationCardType(this.data.id, request).subscribe(() => {
            this.applicationRefreshService.reloadApplicationModel();
          });
        }
      }, () => {}
    );
  }
}
