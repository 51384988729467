import { Injectable, Type } from '@angular/core';
import { ClientButtonsViewComponentResolver, CountryViewComponent } from '@backoffice-monorepo/commons';
import { PlPiClientButtonViewComponent } from '../components/pl-pi-client-button-view/pl-pi-client-button-view.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiClientButtonViewComponentResolverService implements ClientButtonsViewComponentResolver {
  resolve(): Type<CountryViewComponent> {
    return PlPiClientButtonViewComponent;
  }
}
