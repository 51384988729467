import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';
import { AmlClientDataItem, AmlClientResponse, AmlScoringHistoryItem, AmlScoringHistoryRequest } from '@backoffice-monorepo/api';

@Injectable({
  providedIn: 'root'
})
export class AmlService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getClientData(clientId: number): Observable<Array<AmlClientDataItem>> {
    return this.get(`/api/client/${clientId}/client-data`)
  }

  getClientAmlInformation(clientId: number): Observable<AmlClientResponse> {
    return this.get(`/api/client/${clientId}/aml`)
  }

  getScoringHistory(clientId: number, scoringHistoryRequest: AmlScoringHistoryRequest): Observable<Array<AmlScoringHistoryItem>> {
    return this.post(`/api/client/${clientId}/scoring-history`, scoringHistoryRequest)
  }
}
