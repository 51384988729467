import { ClientDetails, ClientDetailsRequest } from '@twino/backoffice-api';

export class PlPiClientDetails extends ClientDetails {
  citizenship: string
  companyData: CompanyData
  countryOfBirth: string
  placeOfBirth: string
  taxResidence: string
}

export class PlPiClientDetailsRequest extends ClientDetailsRequest {
  citizenship: string
  countryOfBirth: string
  placeOfBirth: string
  taxResidence: string
  companyData?: CompanyData
}

export class CompanyData {
  name: string
  companyAddress: CompanyAddress
  activityCode: Array<string>
  mainActivityCode: string
  startDate: Date
  status: string
}

export class CompanyAddress {
  street: string
  apartmentNumber: string
  city: string
  province: string
  postalCode: string
}
