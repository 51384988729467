import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private overlayEnabled: boolean;
  private overlayAnnouncementSource = new Subject<boolean>();
  overlayAnnouncement = this.overlayAnnouncementSource.asObservable();

  constructor(@Optional() @SkipSelf() parent?: OverlayService) {
    if (parent) {
      throw Error("[OverlayService]: trying to create multiple instances, but this service should be a singleton.")
    }
  }

  showOverlay() {
    this.overlayEnabled = true;
    this.overlayAnnouncementSource.next(this.overlayEnabled);
  }

  hideOverlay() {
    this.overlayEnabled = false;
    this.overlayAnnouncementSource.next(this.overlayEnabled);
  }
}
