import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChangeTopicRequest, MailboxAddressesResponse,
  ReAssignClientRequest,
  ReplyToMailBoxTaskEmailRequest,
  SearchQuery, SearchResponse, Task
} from '@backoffice-monorepo/api';
import { BaseService } from './base.service';
import { ErrorsService } from './errors.service';
import { FileUpload } from '../models/file.upload';


@Injectable({
  providedIn: 'root'
})
export class MailboxService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
  ) {
    super(http, errorsService)
  }

  replyToEmail(taskId: number, request: ReplyToMailBoxTaskEmailRequest, attachments: Array<FileUpload>) {
    const formData = new FormData();

    formData.append(
      'email',
      new Blob(
        [JSON.stringify(request)],
        {type: "application/json"}
      )
    );

    attachments.forEach(attachment => {
      formData.append('files', attachment.file);
    });
    return this.post(`/api/task/${taskId}/mailbox/reply-to-email`, formData);
  }

  assignTask(taskId: number, changeTopicRequest: ChangeTopicRequest) {
    return this.put(`/api/task/${taskId}/mailbox/change-topic`, changeTopicRequest);
  }

  getMailboxTopics(): Observable<Array<string>> {
    return this.get(`/api/task/mailbox/topics`);
  }

  reAssignClient(taskId: number, reAssignClientRequest: ReAssignClientRequest) {
    return this.put(`/api/task/${taskId}/mailbox/change-client`, reAssignClientRequest);
  }

  getMailboxAddresses(topic: string): Observable<MailboxAddressesResponse> {
    return this.get(`/api/task/mailbox/${topic}/addresses`);
  }

  list(topic: string, address: string, searchQuery: SearchQuery): Observable<SearchResponse<Task>> {
    return this.post(`/api/task/mailbox/${topic}/${address}/list`, searchQuery);
  };
}

