import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@backoffice-monorepo/api';
import { flatMap, map, tap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  private user?: User = null;

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    @Optional() @SkipSelf() parent?: UserService
  ) {
    super(http, errorsService);
    if (parent) {
      throw Error("[UserService]: trying to create multiple instances, but this service should be a singleton.")
    }
  }

  getUser(): User | null {
    return this.user;
  };

  isLoggedIn() {
    return this.get<boolean>('/admin/loggedin', {'withCredentials': true}, true, 401, 403);
  }

  loadUser(): Observable<boolean> {
    return this.get<string>('/admin/loggedin/username', {responseType: 'text'})
      .pipe(flatMap((username: string) => this.loadPermissions()
        .pipe(map((permissions) => this.authenticate(username, permissions)))));
  }

  authenticate(userName, permissions): boolean {
    this.user = new User(userName, permissions);
    return this.user.userName !== null && (typeof this.user.userName) !== undefined && !!this.user.userName;
  }

  loadPermissions() {
    return this.get('/admin/loggedin/authorities');
  }

  hasPermission(permissions: string): boolean {
    return permissions.split(',').some(permission => this.user?.permissions?.indexOf(permission) !== -1);
  }

  isAuthenticated(): boolean {
    return this.user !== null;
  }

  login(formData: { password: string; username: string }) {
    return this.post('/admin/login', formData, {'withCredentials': true}, true, 400);
  }

  logout() {
    return this.http.get('/logout', {'withCredentials': true})
      .pipe(tap(() => this.user = null));
  }
}
