import { Component, EventEmitter, Input, OnDestroy, OnInit, SkipSelf, ViewChild } from '@angular/core';
import {
  DateFilterDefaultValues,
  EnumResponse,
  FilterDataValues,
  FilterOperation,
  FilterType,
  getDummyValueForType,
  NUMBER_OF_FIELDS_FOR_OPERATION,
  provideFilterOperations,
  toFilterViewElementType
} from '@twino/backoffice-api';
import { NgbDateAdapter, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
// noinspection ES6PreferShortImport
import { TwinoDateAdapterService } from '../../../services/dates/twino-date-adapter.service';
// noinspection ES6PreferShortImport
import { FilteringService } from '../../../services/filtering.service';
import { DateTimePickerResetService } from '../../../services/date-time-picker-reset.service';
import * as dayjs from 'dayjs';
import { DateUtils } from '../../../../lib/services/date-utils';


@Component({
  selector: '[filter-element]',
  templateUrl: './filter-element.component.html',
  styleUrls: ['./filter-element.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class FilterElementComponent implements OnInit, OnDestroy {

  filterOperations: FilterOperation[];
  operation: FilterOperation;
  value: string | Date;
  values: Array<string | Date> = [];
  numberOfValues = 1;
  enumResponse: EnumResponse;
  booleanValues = ["true", "false"];

  @Input() type: FilterType;
  @Input() title: string;
  @Input() property: string;
  @Input() customElementOperations: FilterOperation[] = [];
  @Input() customElementLink: string;
  @Input() listValues: string[];
  @Input() enum: string;
  @Input() defaultOperation: FilterOperation;
  @Input() defaultValue: DateFilterDefaultValues | string;
  resetChildren: EventEmitter<void> = new EventEmitter();
  public DateUtils = DateUtils;
  public instantValue: string;

  private resetFiltersSubscription: Subscription;

  @ViewChild('typeaheadFilterOptions', {static: true}) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    @SkipSelf() private filteringService: FilteringService<never>,
    @SkipSelf() private dateTimePickerResetService: DateTimePickerResetService
  ) {
  }

  ngOnInit(): void {
    this.filterOperations = provideFilterOperations(this.type, this.customElementOperations);
    this.resetFiltersSubscription = this.filteringService.resetFilters.subscribe(() => this.resetContent());
    if (this.enum) {
      this.filteringService.getEnum(this.enum)
        .subscribe((enumResponse) => {
          this.enumResponse = enumResponse;
          this.listValues = enumResponse.enumValues
        });
    }

    this.initializeDefaults();
  }

  ngOnDestroy(): void {
    this.resetFiltersSubscription?.unsubscribe();
  }

  searchElementOperation = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
    const inputFocus$ = this.focus$
     .pipe(tap(() => this.operation = null));

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => [null].concat(term === '' || !this.operation ?
        this.filterOperations :
        this.filterOperations.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 20))
    );
  }

  setValue($event: string) {
    this.value = $event;
    if ($event) {
      this.valueChanged();
    }
  }

  valueChanged() {
    if (!this.operation) {
      this.operation = FilterOperation.EQUALS;
    }
    this.publishFilterData();
  }

  elementOperationSelected($event) {
    if (!$event?.item) {
      this.resetContent();
    }
    this.numberOfValues = NUMBER_OF_FIELDS_FOR_OPERATION.get($event?.item);
  }

  resetContent() {
    if(this.type === "instant") {
      this.dateTimePickerResetService.resetFilterContent(this.property);
    }
    this.operation = null;
    this.value = null;
    this.values = [];
    this.resetChildren.emit();
    this.publishFilterData();
  }

  publishFilterData(): void {

    if (!this.operation) {
      return this.filteringService.resetSearchCriterion(this.property);
    }

    const filterData = this.setFilterData();
    if (this.numberOfValues === 0) {
      filterData.values = [getDummyValueForType(filterData.type)];
    }
    switch (this.type) {
      case 'enum':
        filterData.enumClass = this.enumResponse?.enumClass;
        break;
      default:
        break;
    }
    this.filteringService.setSearchCriterion(this.property, filterData);
  }

  transformSearchValue(value: string | Date): string | Date {
    if(this.type === "instant") {
      if (typeof value === "string") {
        return  dayjs(value?.trim()).toDate();
      } else {
        return value;
      }
    } else {
      if (typeof value === "string") {
        return  value?.trim();
      }
    }
  }

  transformSearchValues(values: Array<string | Date>): Array<string | Date> {
    for(let i = 0; i < values.length; i++) {
      values[i] = this.transformSearchValue(values[i]);
    }
    return values;
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  setFilterData(): FilterDataValues {
    if (!this.value) {
      return this.setFilterDataValues();
    } else {
      return this.setFilterDataValue();
    }
  }

  setFilterDataValue(): FilterDataValues {
    const filterData: FilterDataValues = {
      operation: this.operation,
      values: [this.transformSearchValue(this.value)],
      propertyName: this.property
    };
    filterData.type = toFilterViewElementType(this.type);
    return filterData;
  }

  setFilterDataValues(): FilterDataValues {
    const filterData: FilterDataValues = {
      operation: this.operation,
      values: this.transformSearchValues(this.values),
      propertyName: this.property
    };
    filterData.type = toFilterViewElementType(this.type);
    return filterData;
  }


  initializeDefaults() {
    if (!(this.defaultValue || this.defaultOperation)) return;
    if (this.defaultOperation) {
      this.operation = this.defaultOperation;
    }
    if (this.defaultValue) {
      switch (this.type) {
        case 'date':
          this.values = [this.defaultStringIntoValue(this.defaultValue)];
          break;
        case 'instant':
          this.values = [this.defaultStringIntoValue(this.defaultValue, true)];
          this.instantValue = this.values[0] as string;
          break;
        case 'datetime':
          this.value = this.defaultStringIntoValue(this.defaultValue, true);
          break;
        case 'number':
          this.values = [this.defaultValue];
          break;
        default:
          this.value = this.defaultValue;
      }
    }
    this.publishFilterData();
  }

  defaultStringIntoValue (defaultValue: string, time = false) {
      switch (defaultValue) {
        case DateFilterDefaultValues.CURRENT_MONTH_START_DATE:
          return  DateUtils.currentDateWithFixedDay(1, time);
        case DateFilterDefaultValues.PREVIOUS_MONTH_START_DATE:
          return DateUtils.previousMonthWithFixedDay(1, time);
        case DateFilterDefaultValues.DATE_30DAYS_BEFORE_TODAY:
          return DateUtils.getDate30DaysBeforeToday(time);
      }
  }

}
