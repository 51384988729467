import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  AffiliateConfiguration,
  AffiliateConfigurationRequest,
  EnumResponse,
  SearchQuery,
  SearchQueryAware,
  SearchResponse, UpdateAffiliateConfigurationReportsRequest,
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService extends BaseService implements SearchQueryAware<AffiliateConfiguration> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<AffiliateConfiguration>> {
    return this.post(`/api/affiliate/configuration/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/affiliate/configuration/enum-values/${enumClass}`);
  }

  getConfiguration(id: number): Observable<AffiliateConfiguration> {
    return this.get(`/api/affiliate/configuration/${id}`);
  }

  addConfiguration(affiliateConfigurationRequest: AffiliateConfigurationRequest): Observable<number> {
    return this.post(`/api/affiliate/configuration`, affiliateConfigurationRequest);
  }

  updateConfiguration(id: number, affiliateConfigurationRequest: AffiliateConfigurationRequest) {
    return this.put(`/api/affiliate/configuration/${id}`, affiliateConfigurationRequest);
  }

  updateConfigurationReport(id: number, updateAffiliateConfigurationReportsRequest: UpdateAffiliateConfigurationReportsRequest) {
    return this.put(`/api/affiliate/configuration/${id}/reports`, updateAffiliateConfigurationReportsRequest);
  }
}
