import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlPiCompareResponse } from '../api/pl-pi-compare';
import { AdditionalAmountApplicationCreationRequest } from '../api/pl-pi-application';
import { CardIssueType, UpdateApplicationCardTypeRequest } from '../api/client.card';

@Injectable({
  providedIn: 'root'
})
export class PlPiApplicationService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  manualCancelApplication(id: number): Observable<void> {
    return this.put(`/api/pl/loan-application/${id}/manual-cancel`, {});
  }

  getCompareExecutions(id: number): Observable<PlPiCompareResponse[]> {
    return this.get(`/api/pl/loan-application/${id}/compare/`);
  }

  createAdditionalAmountApplication(clientId: number, request: AdditionalAmountApplicationCreationRequest): Observable<void> {
    return this.post(`/api/pl/loan-application/additional-amount/clients/${clientId}`, request);
  }

  getApplicationCardType(applicationId: number): Observable<CardIssueType> {
    return this.get(`/api/pl/loan-application/${applicationId}/card-type`);
  }

  updateApplicationCardType(applicationId: number, request: UpdateApplicationCardTypeRequest): Observable<void> {
    return this.put(`/api/pl/loan-application/${applicationId}/card-type`, request);
  }
}
