import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertsService,
  NamedComponent,
  ProductConfigService,
  ViewHistoryService
} from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductConfig, ProductConfigurationRequest } from '@twino/backoffice-api';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ActivatedRoute } from '@angular/router';
import { ProductConfigRefreshService } from '../../services/product-config-refresh.service';
import { first, take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'product-config-edit',
  templateUrl: './product-config-edit.component.html',
  styleUrls: ['./product-config-edit.component.scss']
})
export class ProductConfigEditComponent extends NamedComponent implements OnInit {

  productType: string;
  productSubType: string | null;
  productBrand: string | null;
  productConfigEditForm: FormGroup;
  productConfigRequest: ProductConfigurationRequest;
  productTypesList: string[];
  productSubTypesList: string[];
  productBrandsList$: Observable<string[]>

  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, {static: false}) editor: JsonEditorComponent;

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private productConfigService: ProductConfigService,
    private formBuilder: FormBuilder,
    private productConfigRefreshService: ProductConfigRefreshService,
    private viewHistoryService: ViewHistoryService,
    private productConfig: ProductConfig,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = this.productConfig.jsonEditorModes;

    this.productType = this.activatedRoute.snapshot.params['id'];
    this.productSubType = this.activatedRoute.snapshot.params['sub-type'];
    this.productBrand = this.activatedRoute.snapshot.params['brand'];
    this.productBrandsList$ = this.productConfigService.listBrands();

    this.productConfigService.listProductTypes().pipe(first()).subscribe(it => this.productTypesList = it);
    this.productConfigService.listProductSubTypes().pipe(first()).subscribe(it => this.productSubTypesList = it);
    this.productConfigEditForm = this.formBuilder.group({
      name: ['', Validators.required],
      subType: ['', Validators.required],
      brand: [''],
      data: ['', Validators.required]
    });

    this.getProductConfigRecord();
  }

  getName(): string {
    return `Edit ${this.productType || ''}`;
  }


  getProductConfigRecord() {
    const productConfigRecord = this.productBrand === "null" ? this.productConfigService.getProductConfigRecord(this.productType, this.productSubType)
      :  this.productConfigService.getProductConfigRecordByBrand(this.productType, this.productSubType, this.productBrand);
    productConfigRecord.pipe(
      take(1)
    ).subscribe(
      configurationData => {
      this.setFormValues(configurationData);
    })
  }

  setFormValues(configurationData) {
    const productConfigurationRecord = {
      name: this.productType,
      subType: this.productSubType,
      brand: this.productBrand,
      data: configurationData
    }
    this.productConfigEditForm.setValue(productConfigurationRecord);
  }

  submitForm() {
    this.productConfigEditForm.value.data.subType = this.productConfigEditForm.value.subType;
    this.productConfigRequest = {
      name: this.productConfigEditForm.value.name,
      data: this.productConfigEditForm.value.data
    }
    const updateProductConfig = this.productBrand === 'null' ? this.productConfigService.updateProductConfig(this.productSubType, this.productConfigRequest)
      : this.productConfigService.updateProductConfigByBrand(this.productSubType, this.productConfigRequest, this.productBrand)
    updateProductConfig.pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess("Product configuration record is updated");
      this.viewHistoryService.closeTab();
      this.productConfigRefreshService.reloadProductConfigsListModel();
    })
  }
}
