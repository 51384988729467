<div class="search-control input-append">
  <div>
    <i class="spinner-border spinner-border-sm text-secondary" *ngIf="loading" role="status">
      <span class="sr-only">Loading...</span>
    </i>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input type="text"
           placeholder="Search loan..."
           [(ngModel)]="modelDisplayName"
           [ngbTypeahead]="search"
           [resultTemplate]="loanLookupTemplate"
           [inputFormatter]="formatter"
           [editable]="false"
           (selectItem)="itemSelected($event)"
           class="form-control form-control-sm"
           (focusout)="focusOut()"/>

  </div>
  <div ngbPopover="Min 3 symbols" placement="right" #loanLookupPopover="ngbPopover"
       [autoClose]="false" triggers="manual"></div>
  <ng-template #loanLookupTemplate let-r="result" let-t="term">
    <a tabindex="-1" style="overflow: hidden;">
      <strong>{{r.loanNumber}}</strong>
      <p><small>{{r.id}} | {{r.status}} | Client: {{r.clientId}}</small></p>
    </a>
  </ng-template>
</div>
