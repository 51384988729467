import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductConfigEditComponent } from './components/product-config-edit/product-config-edit.component';
import { ProductConfigAddComponent } from './components/product-config-add/product-config-add.component';
import { ProductConfigsListComponent } from './components/product-configs-list/product-configs-list.component';


const routes: Routes = [
  {path: '', component: ProductConfigsListComponent, pathMatch: 'full'},
  {path: 'create', component: ProductConfigAddComponent, pathMatch: 'full'},
  {path: ':id/sub-type/:sub-type/:brand', component: ProductConfigEditComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductConfigsRoutingModule {
}
