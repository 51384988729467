<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Key" type="string" property="key"></tr>
  <tr filter-element title="File" type="string" property="file"></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all, file-templates.edit"
                  class="btn btn-success btn-sm"
                  (click)="navigateToCreate()">
            <bi name="plus-circle"></bi>
            Create
          </button>
    </span>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="key" (sort)="onSort($event)">Id</th>
      <th sortable="fileName">File name</th>
      <th sortable="locale" (sort)="onSort($event)">Locale</th>
      <th>Disabled</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let fileTemplate of response?.results; trackBy: trackById" (dblclick)="navigateTo(fileTemplate.id)">
      <td></td>
      <td>{{fileTemplate.key}}</td>
      <td>{{fileTemplate.fileName}}</td>
      <td>{{fileTemplate.locale}}</td>
      <td>{{fileTemplate.isDisabled}}</td>
    </tr>
    </tbody>
  </table>
</server-table>


