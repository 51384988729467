import { Component } from '@angular/core';
import { UserRole } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent {
  username: string;
  roles: UserRole[];
  selectedRoles: string[] = [];

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  onSaveClick() {
    this.activeModal.close([...this.selectedRoles]);
  }

  selectRole(roleName: string) {
    if (this.selectedRoles.includes(roleName)) {
      this.selectedRoles = this.selectedRoles.filter(selectedRoleName => roleName !== selectedRoleName);
    } else {
      this.selectedRoles.push(roleName);
    }
  }
}
