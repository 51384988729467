import { SearchModel } from './search/search.model';

export interface Blacklist extends SearchModel {
  id: number
  personalId: string
  ip: string
  identityDocNumber: string
  bankAccountNumber: string
  active: boolean
  created: Date
  firstName?: string
  lastName?: string
  email?: string
  bankCardNumber?: string
}

export interface BlacklistRequest {
  active: boolean
  personalId?: string
  ip?: string
  identityDocNumber?: string
  bankAccountNumber?: string
  message?: string
  firstName?: string
  lastName?: string
  email?: string
  bankCardNumber?: string
  category?: string
}

export enum BlacklistCategory {
  FRAUD = 'Fraud',
  DIED = 'Died',
  EXTERNAL_BLACKLIST = 'External Blacklist',
  LEGAL_CASES = 'Legal cases',
  SELF_EXCLUDED = 'Self excluded',
  OTHER = 'Other'
}
