<div class="modal-header">
  <h4 class="modal-title">
    {{tariff.name}}
  </h4>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="activeModal.close(false)"
  >
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <h5 class="ml-2">Chargeable operations fees</h5>
    <table class="table table-sm mt-2" >
      <tbody>
      <tr *ngFor="let item of tariff.chargeableOperationsFees  | keyvalue; let i = index; trackBy: trackById">
        <td>
          <h6 class="ml-2">{{utils.enumValueToLabel(item.key)}}</h6>
          <table class="table table-sm table-hover mt-2">
            <thead class="text-light bg-primary">
            <tr>
              <th>Min operations count</th>
              <th>Max operations count</th>
              <th>Fee amount</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let fee of item.value.rangedChargeableOperationsFees; let i = index; trackBy: trackById">
              <td>{{fee.minOperationsCount}}</td>
              <td>{{fee.maxOperationsCount}}</td>
              <td>{{fee.feeAmount}}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
  <h5 class="ml-2">Chargeable transactions fees</h5>

  <table class="table table-sm mt-2" >
    <tbody>
    <tr *ngFor="let item of tariff.chargeableTransactionFees  | keyvalue; let i = index; trackBy: trackById">
      <td>
        <h6 class="ml-2">{{utils.enumValueToLabel(item.key)}}</h6>
        <table class="table table-sm mt-2" *ngIf="item.value.fixedRangedFees.length > 0">
          <thead class="text-light bg-primary">
          <tr>
            <th>Min operations count</th>
            <th>Max operations count</th>
            <th>Fee amount</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let fee of item.value.fixedRangedFees; let i = index; trackBy: trackById">
            <td>{{fee.minOperationsCount}}</td>
            <td>{{fee.maxOperationsCount}}</td>
            <td>{{fee.feeAmount}}</td>
          </tr>
          </tbody>
        </table>
        <table class="table table-sm mt-2" *ngIf="item.value.percentageBasedRangedFees.length > 0">
          <thead class="text-light bg-primary">
          <tr>
            <th>Min operations count</th>
            <th>Max operations count</th>
            <th>Min fee amount</th>
            <th>Fee percentage</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let fee of item.value.percentageBasedRangedFees; let i = index; trackBy: trackById">
            <td>{{fee.minOperationsCount}}</td>
            <td>{{fee.maxOperationsCount}}</td>
            <td>{{fee.minFeeAmount}}</td>
            <td>{{fee.feePercentage}}</td>
          </tr>
          </tbody>
        </table>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button
    type="button"
    autofocus
    class="btn btn-outline-secondary"
    (click)="activeModal.close(false)"
  >Cancel</button>
</div>

