import { Component, Inject, Input, OnInit, Type } from '@angular/core';
import { TaskViewComponent } from '../task.view.component';
import { Application, Client, FilterOperation, SearchQueryBuilder, Task } from '@twino/backoffice-api';
// noinspection ES6PreferShortImport
import { ClientService } from '../../../services/client.service';
// noinspection ES6PreferShortImport
import { CLIENT, NUMBER, STRING } from '../../../services/transform/transforms';
// noinspection ES6PreferShortImport
import { TransformInstructions, TransformInstructionsBuilder } from '../../../services/transform/transform.types';
import { TaskRelatedEntitiesComponentResolver } from '../../../services/component-resolvers/TaskRelatedEntitiesComponentResolver';
import { CountryViewComponent } from '../../countryViewComponent';

@Component({
  selector: 'default-task-view',
  templateUrl: './default-task-view.component.html',
  styleUrls: ['./default-task-view.component.scss']
})
export class DefaultTaskViewComponent implements TaskViewComponent, OnInit {

  @Input()
  task: Task;

  client: Client;
  lastApplication: Application;
  taskRelatedEntitiesComponentType: Type<CountryViewComponent>;

  constructor(
    private clientService: ClientService,
    @Inject('TaskRelatedEntitiesComponentResolver') taskRelatedEntitiesComponentResolver: TaskRelatedEntitiesComponentResolver
  ) {
    this.taskRelatedEntitiesComponentType = taskRelatedEntitiesComponentResolver.resolve();
  }

  clientTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Id', CLIENT]],
      ['number', ['Number', STRING]],
      ['phone', ['mobilePhone', () => `<a href="tel://${this.client.mobilePhone}">${this.client.mobilePhone}</a>`.asHtml()]],
      ['firstName', ['First Name', STRING]],
      ['lastName', ['Last Name', STRING]],
      ['creditLimit', ['Credit Limit', STRING]],
      ['finishedDetailsStep', ['Finished Details Step', NUMBER]],
    ]
  );

  lastApplicationTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['askedTerm', ['Asked term', () => `${this.lastApplication.askedTerm.loanLength?.value} ${this.lastApplication.askedTerm.loanLength?.unit}`.asString()]],
      ['askedAmount', ['Asked amount', STRING]],
      ['productType', ['Product Type', STRING]]
    ]
  );

  ngOnInit(): void {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(1)
      .addFilter("id", FilterOperation.EQUALS, [this.task?.clientId.toString()]).build(); // TODO: throws errors on clientId null
    this.clientService.find<Client>(searchQuery)
      .subscribe(response => {
        this.client = response?.results?.find(() => true);
        this.clientService.getClientLastApplication(this.client.id).subscribe(application => {
          this.lastApplication = application;
        })
      });
  }
}
