<div class="search-control input-append">
  <div>
    <i class="spinner-border spinner-border-sm text-secondary" *ngIf="loading" role="status">
      <span class="sr-only">Loading...</span>
    </i>
    <form class="d-flex" [formGroup]="clientSearchForm">
            <select formControlName="searchKey" class="form-select form-select-sm form-control form-control-sm rounded-0">
              <option selected value="" hidden>Search type</option>
              <option *ngFor="let option of ClientSearchOption | keyvalue" [ngValue]="option.key">{{ option.value }}</option>
            </select>
        <input #input
               placement="bottom"
               type="text"
               placeholder="Search client..."
               formControlName="searchValue"
               class="form-control form-control-sm rounded-0"
               [ngbTypeahead]="search"
               [resultTemplate]="clientLookupTemplate"
               [inputFormatter]="formatter"
               (selectItem)="itemSelected($event, input)"
               (focusout)="focusOut()"/>
    </form>
  </div>
  <div ngbPopover="Min 3 symbols" placement="right" #clientLookupPopover="ngbPopover"
       [autoClose]="false" triggers="manual"></div>
  <ng-template #clientLookupTemplate let-r="result" let-t="term">
    <a tabindex="-1" style="overflow: hidden;">
      <strong>{{r.firstName}} {{r.lastName}}</strong>
      <p><small>{{r.id}} | {{r.number}} | {{r.personalId}}  | {{r.email}} | {{r.mobilePhone}}</small></p>
    </a>
  </ng-template>
</div>
