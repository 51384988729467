import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, ClientService } from '@backoffice-monorepo/commons';
import { ClientsRefreshService } from '../../../../../../libs/core/src/lib/modules/clients/services/clients-refresh.service';
import { PlPiClientDetails, PlPiClientDetailsRequest } from '../../api/pl-pi-client-details';
import { PlPiCountriesService } from '../../modules/phone-application/services/pl-pi-countries.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-details-edit',
  templateUrl: './pl-pi-client-details-edit.component.html',
  styleUrls: ['./pl-pi-client-details-edit.component.css']
})
export class PlPiClientDetailsEditComponent extends BasicComponent implements OnInit {
  @Input() clientId: number;

  editClientDetailsRequest: PlPiClientDetailsRequest;
  clientDetailsEditForm: FormGroup;
  clientDetails: PlPiClientDetails;
  countries: { name: string, code: string, eea: boolean }[];

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService,
    private countriesService: PlPiCountriesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.countriesService.getCountries().pipe(
      take(1)
    ).subscribe(list => {
      this.countries = list;
    })
    this.clientDetailsEditForm = this.formBuilder.group({
      citizenship: '',
      countryOfBirth: '',
      placeOfBirth: 'UNSET',
      taxResidence: '',
    });
    this.clientService.getClientDetails(this.clientId).pipe(
      take(1)
    ).subscribe((detailsData: PlPiClientDetails) => {
      this.clientDetails = detailsData;
      this.editClientDetailsRequest = {
        citizenship: detailsData.citizenship,
        countryOfBirth: detailsData.countryOfBirth,
        placeOfBirth: detailsData.placeOfBirth,
        taxResidence: detailsData.taxResidence,
      }
      this.clientDetailsEditForm.setValue(this.editClientDetailsRequest);
    });
  }

  submitForm() {
    this.clientService.updateClientDetails(this.clientId, {
      ...this.clientDetailsEditForm.value,
      companyData: this.clientDetails.companyData
    }).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Details updated for client ${this.clientId}`);
      this.clientsRefreshService.reloadClientDetailsModel();
      this.activeModal.close();
    })
  }

}
