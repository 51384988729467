import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TransformInstructions, TransformResult } from '@backoffice-monorepo/commons';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderAndTransformService {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(entity: Record<string, unknown | null>, transformationInstructions: TransformInstructions): TransformResult[] {
    const result: TransformResult[] = [];
    transformationInstructions.forEach(([name, transformation], property) => {
      const fieldValue = entity[property] || '';
      const transformResult = transformation(fieldValue).toTransformResult(name);
      if (transformResult.type === 'html') {
        transformResult.value = transformResult.value.pipe(
          map(it => this.sanitizer.sanitize(SecurityContext.HTML, it.toString()))
        )
      }
      result.push(transformResult);
    });
    return result;
  }
}
