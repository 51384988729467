<div class="container-fluid row pt-1 pb-2">
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="container-fluid row pt-2 pb-1">
    <div class="col-md-12 row">
      <div class="col-md-6">
        <ndc-dynamic
          [ndcDynamicComponent]="clientDetailsTableComponentType"
          [ndcDynamicInputs]="{data: details, clientId: client.savedId}"
        ></ndc-dynamic>
      </div>

      <div class="col-md-6">
        <ndc-dynamic
          [ndcDynamicComponent]="clientEmploymentTableComponentType"
          [ndcDynamicInputs]="{data: employmentInfo, clientId: client.savedId}"
        ></ndc-dynamic>
      </div>
    </div>
    <div class="col-md-12 mt-2 row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header text-light bg-primary">
            Income info
            <button appRequirePermission="client.all,client.edit,admin.all" class="btn btn-sm btn-light float-right" (click)="editClientIncome()">
              <bi name="pencil-square"></bi>
              Edit income
            </button>
          </div>
          <div class="card-text">
            <transform-table [transformInstructions]="incomeInfoTransformations" [entity]="incomeInfo"></transform-table>
          </div>
        </div>
      </div>
      <div class="col-md-6">

      </div>
    </div>
  </div>
</div>
