import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentsRefreshService implements OnDestroy{

  public reloadPaymentModel$ = new Subject<void>();
  public reloadPaymentsListModel$ = new Subject<void>();

  reloadPaymentModel() {
    this.reloadPaymentModel$.next();
  }

  reloadPaymentsListModel() {
    this.reloadPaymentsListModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadPaymentModel$.unsubscribe();
    this.reloadPaymentsListModel$.unsubscribe();
  }
}
