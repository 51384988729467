import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, flatMap, map, tap } from 'rxjs/operators';
// noinspection ES6PreferShortImport
import { LoanService } from '../../../services/loan.service';
import { NgbPopover, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Resettable } from '../../resettable-component';

@Component({
  selector: 'loan-lookup',
  templateUrl: './loan-lookup.component.html',
  styleUrls: ['./loan-lookup.component.scss']
})
export class LoanLookupComponent implements Resettable, OnInit, OnDestroy {

  public static MIN_SYMBOLS = 3;
  public static DEBOUNCE_DELAY = 500;

  @Input() resetEvent: EventEmitter<void>;
  resetEventSubscription: Subscription;

  modelDisplayName: string;
  loading = false;

  @ViewChild(NgbPopover, {static: false}) popover: NgbPopover;
  @Output() loanSelected = new EventEmitter<string>();

  constructor(private loanService: LoanService) {
  }

  search = (text$: Observable<string>) => text$.pipe(
    debounceTime(LoanLookupComponent.DEBOUNCE_DELAY),
    flatMap(term => {
      if (term.length < LoanLookupComponent.MIN_SYMBOLS) {
        this.loading = false;
        this.popover.open();
        return of({results: []});
      } else {
        this.loading = true;
        this.popover.close();
        return this.loanService.findLoansByText(term);
      }
    })
  )
    .pipe(map((response) => response?.results || []))
    .pipe(tap(() => this.loading = false))

  focusOut() {
    this.loading = false;
    this.popover.close();
  }

  formatter = (loan: { loanNumber: string }) => loan.loanNumber;

  itemSelected($event: NgbTypeaheadSelectItemEvent) {
    if ($event.item?.id) this.loanSelected.emit($event.item.id);
  }

  resetContent(): void {
    this.modelDisplayName = null;
  }

  ngOnInit(): void {
    this.resetEventSubscription = this.resetEvent?.subscribe(() => this.resetContent());
  }

  ngOnDestroy(): void {
    this.resetEventSubscription?.unsubscribe();
  }
}
