import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationComponent } from './components/application/application.component';
import { ApplicationsListComponent } from './components/applications-list/applications-list.component';


const routes: Routes = [
  {path: ':id', component: ApplicationComponent},
  {path: '', component: ApplicationsListComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplicationsRoutingModule {
}
