import { Component, Input, OnInit } from '@angular/core';
import { ActivityOverviewService, BasicComponent, ClientSidebarLibComponent, draggableModalOptionsLarge } from '@backoffice-monorepo/commons';
import { ActivityOverview, ActivityOverviewCommunicationType, FullClient, ActivityOverviewType } from '@twino/backoffice-api';
import { CommunicationContentViewComponent } from '../../../communications/components/communication-content-view/communication-content-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-activity-overview',
  templateUrl: './client-activity-overview.component.html',
  styleUrls: ['./client-activity-overview.component.scss']
})
export class ClientActivityOverviewComponent extends BasicComponent implements ClientSidebarLibComponent,OnInit {

  @Input()
  client: FullClient;
  activityOverviews: ActivityOverview[] | null;
  CommunicationTypeActivityOverview = ActivityOverviewCommunicationType;
  ActivityOverviewType = ActivityOverviewType;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    public activityOverviewService: ActivityOverviewService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    return this.activityOverviewService.getActivityOverviewByClient(this.client.savedId).pipe(
      take(1)
    ).subscribe((activityOverview) => {
      this.activityOverviews = activityOverview.results;
    })
  }

  viewContent(communicationId) {
    const modalRef = this.modalService.open(CommunicationContentViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.communicationId = communicationId;
  }

  navigateToTask(entityId: number) {
    return this.router.navigateByUrl(`tasks/${entityId}`);
  }

  trackByEntityId(index, item: ActivityOverview){
    return item.entityId;
  }
}
