import { Component, OnInit } from '@angular/core';
import {
  ConfirmDialogueComponent,
  FilteringService,
  LoanSaleDataService,
  NamedComponent,
  ProductConfigService,
  Utils
} from '@backoffice-monorepo/commons';
import {
  FilterOperation, LoanAgreementType,
  LoanSaleData, LoanSaleSearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concatMap, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { from, Observable, of } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-loan-presale-data-list',
  templateUrl: './loan-presale-data-list.component.html',
  styleUrls: ['./loan-presale-data-list.component.css'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<LoanSaleData>) => new FilteringService(listService),
      deps: [LoanSaleDataService]
    }
  ]
})
export class LoanPresaleDataListComponent extends NamedComponent implements OnInit {
  public readonly FilterOperation = FilterOperation;
  newPage = 1;
  itemsPerPage = 20;
  loanSaleSearchQuery: LoanSaleSearchQuery = { agreementType: null, brand: null };
  response: SearchResponse<LoanSaleData>;
  readonly AgreementType = LoanAgreementType;
  agreementType = new FormControl('');
  public productBrandsList$: Observable<string[]>
  brandType = new FormControl('');


  constructor(
    protected router: Router,
    private loanSaleDataService: LoanSaleDataService,
    private productConfigService: ProductConfigService,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.productBrandsList$ = this.productConfigService.listBrands();
    this.buildLoanSearchQuery();
    this.refresh();
    this.onTypeChange();
  }

  buildLoanSearchQuery(){
    this.loanSaleSearchQuery.pageSize = this.itemsPerPage;
    this.loanSaleSearchQuery.page = this.newPage;
  }

  refresh(newPage = null) {
    if (newPage) this.loanSaleSearchQuery.page = newPage;
    this.loanSaleDataService.find(this.loanSaleSearchQuery).subscribe(data => this.response = data)
  }

  onTypeChange() {
    this.agreementType.valueChanges.subscribe(agreementType => {
      this.loanSaleSearchQuery.agreementType = agreementType;
      this.refresh();
    })

    this.brandType.valueChanges.subscribe(brandType => {
      this.loanSaleSearchQuery.brand = brandType;
      this.refresh();
    })

  }
  navigateTo(id: string | number) {
    this.router.navigate([`/loan-sale/${id}`]).then();
  }

  getName() {
    return 'Loan pre-sell';
  }

  removeFromPresale(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Remove loan from pre-sell list`;
    modalRef.componentInstance.content = `Are you sure you want to delete loan #${id}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.loanSaleDataService.deleteLoanFromLoanSaleData(id).pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => this.refresh());
        }
      }
    );
  }

  setPreSoldStatus() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Set pre-sold status`;
    modalRef.componentInstance.content = `Are you sure you want to set pre-sold status?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.loanSaleDataService.setPreSellStatus({agreementType: this.loanSaleSearchQuery.agreementType, brand: this.loanSaleSearchQuery.brand}).pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => this.refresh());
        }
      }
    )
  }

  preSellLoans() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Pre-sell loans`;
    modalRef.componentInstance.content = `Are you sure you want to pre-sell these loans?`;
    const loanSearchQuery = {...this.loanSaleSearchQuery, pageSize: this.response.totalRecords ? this.response.totalRecords : 1  } as LoanSaleSearchQuery;
    this.loanSaleDataService.find(loanSearchQuery)
      .pipe(
        takeUntil(this.$destroy),
        concatMap((res) => from(modalRef.result).pipe(
            concatMap((result) => {
              if (result === true) {
                const loanIds = res.results.map(loan => loan.loanId);
                return this.loanSaleDataService.preSell(loanIds);
              } else {
                return of(null); // Handle case where result is not true
              }
            })
          ))
      )
      .subscribe(() => this.refresh());

  }

  generateReport() {
    this.loanSaleDataService.generatePreviewPreSellReport({agreementType: this.loanSaleSearchQuery.agreementType}).pipe(
      takeUntil(this.$destroy)
    ).subscribe((response: Blob) => {
        Utils.downloadBlob(response, "preSaleReport.csv");
      },
      error => console.error("Error downloading the file.", error))
  }
}
