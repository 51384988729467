import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnumResponse, SearchQuery, SearchQueryAware, SearchResponse } from '@twino/backoffice-api';
import { ValueAddedServiceResponse, ValueAddedServiceRequest, ClientValueAddedService } from '../api/vas-definitions';

@Injectable({
  providedIn: 'root'
})
export class PlPiVasService extends BaseService implements SearchQueryAware<ValueAddedServiceResponse> {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<ValueAddedServiceResponse>> {
    return this.post(`/api/pl/vas/search`, searchQuery);
  }

  listClientVAS(searchQuery: SearchQuery): Observable<SearchResponse<ClientValueAddedService>> {
    return this.post(`/api/pl/client-vas/search`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/pl/vas/enum-values/${enumClass}`);
  }

  getVasById(id: number): Observable<ValueAddedServiceResponse> {
    return this.get(`/api/pl/vas/${id}`);
  }

  createVAS(request: ValueAddedServiceRequest): Observable<unknown> {
    return this.post(`/api/pl/vas`, request);
  }

  updateVAS(id: number, request: ValueAddedServiceRequest): Observable<unknown>  {
    return this.put(`/api/pl/vas/${id}`, request);
  }

  renounceVAS(id: number): Observable<unknown>  {
    return this.post(`/api/pl/client-vas/${id}/renounce`, {});
  }
}
