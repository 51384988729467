<div class="container-fluid pt-2 col-sm-6">
  <div class="card">
    <div class="card-header">
      Notification configuration {{title}}
    </div>
    <div class="card-body">
      <form [formGroup]="notificationConfigForm" (ngSubmit)="submitForm()">
        <div class="form-group row">
          <label for="typeId" class="col-sm-4 control-label">Notifiable event class</label>
          <div class="col-sm-8">
            <input  [ngbTypeahead]="searchNotifiable"
                    (click)="click$.next($any($event).target.value)"
                    #typeaheadFilterOptions="ngbTypeahead"
                    class="form-control form-control-sm" formControlName="typeId" name="typeId" id="typeId">
          </div>
        </div>
        <div class="form-group row">
          <label for="brand" class="col-sm-4 control-label">Brand</label>
          <div class="col-sm-8">
            <select class="form-control form-control-sm" id="brand" formControlName="brand" name="brand" (change)="setBrand()">
              <option *ngFor="let key of productBrandsList$ | async" [ngValue]="key">{{key}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="productSubType" class="col-sm-4 control-label">Product sub type</label>
          <div class="col-sm-8">
            <select class="form-control form-control-sm" formControlName="productSubType" name="productSubType" id="productSubType">
              <option value=""></option>
              <option *ngFor="let productSubType of productSubTypes; trackBy: trackByValue" [ngValue]="productSubType">{{productSubType}}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="templateKey" class="col-sm-4 control-label">Template key<sup class="text-danger">*</sup></label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control form-control-sm"
                   id="templateKey"
                   formControlName="templateKey"
                   name="templateKey"
                   [(ngModel)]="templateKeyTypeahead"
                   [ngbTypeahead]="search"
                   (selectItem)="getMessageTemplatesByKey($event.item)">
          </div>
        </div>
        <div class="form-group row">
          <label for="attachmentConfigs" class="col-sm-4 control-label">Attachment configs<sup class="text-danger">*</sup></label>
          <div class="col-sm-8">
            <textarea class="form-control form-control-sm"
                      id="attachmentConfigs"
                      formControlName="attachmentConfigs"
                      name="attachmentConfigs"
                      cols="50">
            </textarea>
          </div>
        </div>

        <div class="form-group row control-label">
          <label class="form-check-label col-sm-4" for="enabled">
            Enabled
          </label>
          <div class="form-check col-sm-8">
            <input class="form-check-input ml-1"
                   type="checkbox"
                   id="enabled"
                   formControlName="enabled"
                   name="enabled">
          </div>
        </div>
        <div class="form-group row control-label">
          <label class="form-check-label col-sm-4" for="isSendOncePerDay">
            Send once per day
          </label>
          <div class="form-check col-sm-8">
            <input class="form-check-input ml-1"
                   type="checkbox"
                   id="isSendOncePerDay"
                   formControlName="isSendOncePerDay"
                   name="isSendOncePerDay">
          </div>
        </div>
        <div class="form-group row control-label">
          <label class="form-check-label col-sm-4" for="isSendInCommunicationTimeWindow">
            Send in communication time window
          </label>
          <div class="form-check col-sm-8">
            <input class="form-check-input ml-1"
                   type="checkbox"
                   id="isSendInCommunicationTimeWindow"
                   formControlName="isSendInCommunicationTimeWindow"
                   name="isSendInCommunicationTimeWindow">
          </div>
        </div>
        <div class="form-group row control-label">
          <label class="form-check-label col-sm-4" for="shouldStoreExternally">
            Should store externally
          </label>
          <div class="form-check col-sm-8">
            <input class="form-check-input ml-1"
                   type="checkbox"
                   id="shouldStoreExternally"
                   formControlName="shouldStoreExternally"
                   name="shouldStoreExternally">
          </div>
        </div>
        <button type="button" class="btn btn-outline-primary float-right ml-2 mt-2" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-outline-danger float-right ml-2 mt-2" [disabled]="!notificationConfigForm.valid">Save</button>
      </form>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header">
      Message template management
    </div>
    <div class="card-body">
      <form class="form-inline" [formGroup]="addMessageTemplateForm" (ngSubmit)="addNewTemplate()">
        <div class="form-group mb-2">
          <label for="communicationType" class="control-label">Communication type<sup class="text-danger">*</sup></label>
          <select class="form-control form-control-sm ml-2" formControlName="communicationType" name="communicationType" id="communicationType">
            <option *ngFor="let communicationType of notificationCommunicationTypes" [ngValue]="communicationType">{{communicationType}}</option>
          </select>
        </div>
        <div class="form-group mx-sm-3 mb-2">
          <label for="locale" class="control-label">Locale</label>
          <select class="form-control form-control-sm ml-2" formControlName="locale" name="locale" id="locale">
            <option></option>
            <option *ngFor="let locale of locales$ | async" [ngValue]="locale">{{locale}}</option>
          </select>
        </div>
        <button type="submit"
                class="btn btn-primary mb-2"
                [disabled]="!addMessageTemplateForm.valid || !templateKeyTypeahead">Add new template</button>
        <button type="button" class="btn btn-danger mb-2 float-right ml-3" (click)="removeTemplates()">Reset templates</button>
      </form>
      <table class="table table-striped table-hover table-sm mt-2">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Key</th>
          <th>Locale</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let messageTemplate of messageTemplates">
          <td></td>
          <td>{{messageTemplate.key}}</td>
          <td>{{messageTemplate.locale}}</td>
          <td>
            <button type="button" class="btn btn-primary" (click)="editTemplate(messageTemplate.id)">Edit template</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

