import { ProductSubType } from '../../modules/phone-application/api/initial-data';
import { Component, Input, OnInit } from '@angular/core';
import { CountryViewComponent, ProductConfigService } from '@backoffice-monorepo/commons';
import { DetailsNavigationStep } from '../../enums/details-navigation-step.enum';
import { Router } from '@angular/router';
import { createPhoneApplicationUrl, getPhoneApplicationType } from '../../constants/details-step-urls.const';
import { PlPiFullClient } from '../../api/pl-pi-full-client';
import { Application } from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-button-view',
  templateUrl: './pl-pi-client-button-view.component.html',
  styleUrls: ['./pl-pi-client-button-view.component.scss']
})
export class PlPiClientButtonViewComponent implements OnInit, CountryViewComponent {

  @Input()
  data: PlPiFullClient;
  lastApplication: Application;
  public productBrandsList$: Observable<string[]>;
  brand: string | null = null;
  constructor(
    private router: Router,
    private productConfigService: ProductConfigService,
  ) {}

  ngOnInit(): void {
    this.productBrandsList$ = this.productConfigService.listBrands();
  }

  onContinuePhoneApplicationClick() {
    if(!this.data.applicableProductSubtype) {
      this.data.applicableProductSubtype = this.lastApplication.productSubType as ProductSubType;
    }
    sessionStorage.setItem("phoneAppBrand", this.lastApplication.brand);
    const phoneApplicationType = getPhoneApplicationType(this.data);
    const step = this.data.repeated ? this.data.repeatedApplicationFinishedStep : this.data.finishedDetailsStep;

    this.router.navigateByUrl(
      createPhoneApplicationUrl(phoneApplicationType, step + 1) + `/${this.data.savedId}`
    );
  }

  onCreateNewLoanApplicationClick() {
    const phoneApplicationType = getPhoneApplicationType(this.data);
    sessionStorage.setItem("phoneAppBrand", this.brand);
    this.router.navigateByUrl(
      `${createPhoneApplicationUrl(phoneApplicationType, DetailsNavigationStep.INITIAL_INFORMATION)}/${this.data.savedId}`
    );
  }

  setBrand(event: any) {
    this.brand = event.target.value;
  }
}
