<div class="container-fluid row pt-1 pb-2">
  <p class="ml-3">
    <b>Payments</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Id</th>
          <th>Direction</th>
          <th>Status</th>
          <th>Amount</th>
          <th>Booking date</th>
          <th>Channel</th>
          <th>From Account</th>
          <th>To Account</th>
          <th>Client ID</th>
          <th>Client Personal ID</th>
          <th>Loan Number</th>
          <th>Details</th>
          <th>Processing</th>
          <th>Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of searchResults?.results;  trackBy: trackById">
          <td></td>
          <td>
            <a routerLink="/payments/{{item.id}}">{{item.id}}</a>
          </td>
          <td>{{ item.direction }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.bookingDate | date: 'dd.MM.yyyy' }}</td>
          <td>{{ item.channelName }}</td>
          <td>{{ item.fromAccount }}</td>
          <td>{{ item.toAccount }}</td>
          <td>
            <a routerLink="/clients/{{item.clientId}}">{{item.clientId}}</a>
          </td>
          <td>{{ item.clientPersonalId }}</td>
          <td>{{ item.loanNumber }}</td>
          <td>
            {{ item.details | slice:0:10 }}
            <button type="button"
                    class="btn btn-outline-info btn-sm"
                    popoverTitle="Pop title"
                    [autoClose]="'outside'"
                    ngbPopover="{{ item.details }}">
              ...
            </button>
          </td>
          <td>
            {{ item.processingTimestamp | date: 'dd.MM.yyyy HH:mm:ss' }}
          </td>
          <td>{{ item.created | date: 'dd.MM.yyyy HH:mm:ss' }}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
    <div class="form-inline form-group" *ngIf="(searchResults?.totalRecords >= itemsPerPage)">
      <ngb-pagination class="pages"
                      size="sm"
                      [collectionSize]="searchResults?.totalRecords"
                      [page]="searchResults?.currentPage"
                      [pageSize]="itemsPerPage"
                      [maxSize]="10"
                      [rotate]="false"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                      (pageChange)="pageChange($event)">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
