import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertsService, BasicComponent, SalePartnerService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { LoanSalePartner } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-sale-partner',
  templateUrl: './sale-partner.component.html',
  styleUrls: ['./sale-partner.component.css']
})
export class SalePartnerComponent extends BasicComponent implements OnInit, OnDestroy {

  @Input() partner: LoanSalePartner | undefined;
  @Input() isEdit: boolean;
  partnerForm: FormGroup;
  title: string | never;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private salePartnerService: SalePartnerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = (this.isEdit) ? "Edit" : "Add";
    this.partnerForm = this.formBuilder.group({
      name: ['', Validators.required],
      bankAccount: ['', Validators.required]
    });
    if (this.isEdit) {
      const partnerData = {
        name: this.partner.name,
        bankAccount: this.partner.bankAccount
      }
      this.partnerForm.setValue(partnerData);
    }
  }

  submitForm() {
    if (this.isEdit) {
      this.updatePartner();
    } else {
      this.addPartner();
    }
  }

  addPartner() {
    this.salePartnerService.createPartner(this.partnerForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Partner ${this.partnerForm.controls.name.value} added`);
      this.activeModal.close();
    })
  }

  updatePartner() {
    this.salePartnerService.updatePartner(this.partner.id, this.partnerForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Partner updated`);
      this.activeModal.close();
    })
  }
}
