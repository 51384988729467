<form [formGroup]="postponeTaskForm" (ngSubmit)="submitForm()">

  <div class="modal-header">
    <h4 class="modal-title">
      <bi name="check"></bi>&nbsp; Close Task
    </h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('closed')">
      <span>&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="form-group">
      <label for="postponeTaskDate">Date<sup class="text-danger">*</sup></label>
      <div class="input-group">
        <input ngbDatepicker
               id="postponeTaskDate"
               class="form-control"
               placeholder="yyyy-mm-dd"
               name="dp"
               formControlName="postponeTaskDate"
               #postponeTaskDatepicker="ngbDatepicker"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="postponeTaskDatepicker.toggle()" type="button"></button>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="postponeTaskHour">Hour</label>
      <div class="input-group">
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="postponeTaskHour">
          <ng-container id="postponeTaskHour" *ngFor="let hour of postponeTaskHours">
            <label ngbButtonLabel class="btn-primary">
              <input ngbButton type="radio" [value]="hour"> {{hour}}
            </label>
          </ng-container>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" autofocus class="btn btn-outline-secondary" (click)="activeModal.dismiss(false)">Cancel</button>
    <button type="submit" class="btn btn-danger" [disabled]="!postponeTaskForm.valid">Ok</button>
  </div>

</form>
