import { Injectable, Optional, SkipSelf } from '@angular/core';
// noinspection ES6PreferShortImport
import { ToastService } from '../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private toastService: ToastService,
    @Optional() @SkipSelf() parent?: AlertsService
  ) {
    if (parent) {
      throw Error("[AlertsService]: trying to create multiple instances, but this service should be a singleton.")
    }
  }

  notifySuccess(message: string) {
    this.toastService.showAlert(message);
  }

  notifyError(message: string) {
    this.toastService.showError(message);
  }
}
