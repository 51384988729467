<div class="container-fluid row pt-1 pb-2">
  <p class="ml-3">
    <b>Loan activity overview</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th>Client ID</th>
          <th>Loan ID</th>
          <th>Type</th>
          <th>Entity ID</th>
          <th>Topic</th>
          <th>Status</th>
          <th>Direction</th>
          <th>Assigned User</th>
          <th>Resolution</th>
          <th>Amount</th>
          <th>Subject</th>
          <th>Content</th>
          <th>Promise date</th>
          <th>Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of activityOverview">
          <td><a *ngIf="item.clientId" href="#/clients/{{item.clientId}}">{{item.clientId}}</a></td>
          <td>{{item.loanId}}</td>
          <td>{{item.type}}</td>
          <td>{{item.entityId}}</td>
          <td>{{item.topic}}</td>
          <td>{{item.status}}</td>
          <td>{{item.direction}}</td>
          <td>{{item.assignedUser}}</td>
          <td>{{item.resolution}}</td>
          <td>{{item.amount}}</td>
          <td>{{item.subject}}</td>
          <td>
            <button type="button"
                    *ngIf="item.type.includes('COMMUNICATION')"
                    class="btn btn-outline-primary btn-sm"
                    (click)="viewContent(item.entityId)"
                    title="View content">
              <bi name="envelope"
                  *ngIf="item.type === communicationTypeActivityOverview.EMAIL || item.type === communicationTypeActivityOverview.LETTER"></bi>
              <bi name="chat-square-text"
                  *ngIf="item.type !== communicationTypeActivityOverview.EMAIL && item.type !== communicationTypeActivityOverview.LETTER"></bi>
            </button>
            <span *ngIf="item.type === activityOverviewType.PAYMENT || item.type === activityOverviewType.DISCOUNT">{{item.content}}</span>
          </td>
          <td>{{item.promiseDate}}</td>
          <td>{{item.created}}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>
