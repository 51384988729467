import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KonsoleRoutingModule } from './konsole-routing.module';
import { KonsoleComponent } from './components/konsole/konsole.component';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [KonsoleComponent],
	imports: [
		CommonModule,
		KonsoleRoutingModule,
		CommonsModule,
		ReactiveFormsModule
	]
})
export class KonsoleModule { }
