<div class="modal-header" cdkDrag cdkDragHandle cdkDragRootElement=".dragged-modal">
  <h4 class="modal-title">
    Accept offer  #{{offer.id}}
  </h4>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="activeModal.close(false)"
  >
    <span>&times;</span>
  </button>
</div>
<div *ngIf="initialTransferLimits" class="modal-body" [formGroup]="acceptOfferForm">
  <div class="form-group">
    <label class="mb-2" for="amount-slider">Amount: {{acceptOfferForm.controls.initialTransferAmount.value}}</label>
    <input
      type="range"
      class="form-control-range w-100"
      id="amount-slider"
      formControlName="initialTransferAmount"
      [min]="minAmount"
      [max]="maxAmount"
      [step]="step"
    >
    <h6 *ngIf="!isRefinance">Min: {{minAmount}} | Max: {{maxAmount}}</h6>
  </div>
  <div class="card">
    <div class="card-body">
      Client bank account:  {{billingDetails?.number}}
    </div>
  </div>
  <div class="card mt-2" *ngIf="hasVAS">
    <div class="card-body">
      VAS account:  {{accountNumberVAS}}
    </div>
  </div>
  <ul class="list-group mt-2" *ngIf="hasVAS">
    <li class="list-group-item" *ngFor="let vas of offerVases; let i = index; trackBy: trackById">
      VAS: {{vas.amount}}; days: {{vas.daysCount}};  {{vas.name}}
    </li>
  </ul>

  <div class="form-group row mt-4 align-items-center" *ngIf="showConfirmationCodeInput">
    <label class="col-sm-1" for="code">Code</label>
    <div class="col-sm-11">
      <input class="form-control" id="code" formControlName="confirmationCode">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    autofocus
    class="btn btn-outline-secondary"
    (click)="activeModal.close(false)"
  >Cancel</button>
  <button
    [disabled]="acceptOfferForm.invalid"
    (click)="submitForm()"
    type="button"
    class="btn btn-success"
  >Ok</button>
</div>
