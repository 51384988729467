<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()"
>
  <tbody filter-panel class="table-sm">
    <tr filter-element title="Id" type="number" property="id"></tr>
    <tr filter-element title="UserName" type="string" property="username"></tr>
  </tbody>

  <div buttons class="mt-1">
    <button class="btn btn-sm btn-success" (click)="onCreateClick()">
      <bi name="plus-circle"></bi>
      Create
    </button>
  </div>

  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
      <tr>
        <th></th>
        <th sortable="id" (sort)="onSort($event)">Id</th>
        <th sortable="username" (sort)="onSort($event)">UserName</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of response?.results; trackBy: trackById" (dblclick)="onEditClick(user.id)">
        <td>
          <button class="btn btn-sm btn-danger" (click)="onDeleteClick(user.id)">Delete</button>
        </td>
        <td>{{user.id}}</td>
        <td>{{user.username}}</td>
      </tr>
    </tbody>
  </table>
</server-table>
