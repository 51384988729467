import { Component, Input, OnInit } from '@angular/core';
import { ClientSidebarLibComponent, PaymentService } from '@backoffice-monorepo/commons';
import { FilterOperation, FullClient, Payment, SearchQueryBuilder, SearchResponse } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-payments',
  templateUrl: './client-payments.component.html',
  styleUrls: ['./client-payments.component.scss']
})
export class ClientPaymentsComponent implements ClientSidebarLibComponent, OnInit {

  @Input()
  client: FullClient;
  searchResults: SearchResponse<Payment> | never;
  newPage = 1;
  itemsPerPage = 20;

  constructor(
    private paymentService: PaymentService,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }


  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [this.client.savedId.toString()]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()

    return this.paymentService.find(searchQuery).pipe(
      take(1)
    ).subscribe((payments) => {
      this.searchResults = payments;
    })
  }

  trackById(index, item){
    return item.id;
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }
}
