import { ApplicationWithoutConfirmedOtpRoutingModule } from './application-without-confirmed-otp-routing.module';
import { NgModule } from '@angular/core';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { ApplicationWithoutConfirmedOtpListComponent } from './components/application-without-confirmed-otp-list/application-without-confirmed-otp-list.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations:[
    ApplicationWithoutConfirmedOtpListComponent
  ],
  imports:[
    ApplicationWithoutConfirmedOtpRoutingModule,
    CommonsModule,
    CommonModule
  ],
})

export class ApplicationWithoutConfirmedOtpModule {}
