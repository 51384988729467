import { Component, OnInit } from '@angular/core';
import {
  BatchJobService, DATE,
  ID,
  NamedComponent,
  STRING,
  TransformInstructions,
  TransformInstructionsBuilder
} from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { BatchJobExecution } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-batch-job-execution',
  templateUrl: './batch-job-execution.component.html',
  styleUrls: ['./batch-job-execution.component.scss']
})
export class BatchJobExecutionComponent extends NamedComponent implements OnInit {
  id: number;
  batchJobExecution: BatchJobExecution;

  batchJobExecutionTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['title', ['Title', STRING]],
      ['jobInstanceId', ['Job instance id', ID]],
      ['jobExecutionId', ['Job execution id', ID]],
      ['startTime', ['Start time', DATE]],
      ['endTime', ['End time', DATE]],
      ['status', ['Status', STRING]],
      ['exitCode', ['Exit code', STRING]],
      ['exitDescription', ['Exit description', STRING]]
    ]
  );

  constructor(
    activatedRoute: ActivatedRoute,
    private batchJobService: BatchJobService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.id  = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  getName(): string {
    return `Execution: ${this.id}`;
  }

  refresh() {
    this.batchJobService.getBatchJobExecution(this.id).pipe(
      take(1)
    ).subscribe(batchJobExecution => {
      this.batchJobExecution = batchJobExecution;
    })
  }

}
