<div class="container-fluid pb-2">
  <div class="col-md-12 pl-1 ml-2 mb-3 row">
    <!-- region buttons -->
    <span class="mr-2 ml-3" *ngIf="showRegisterButton">
      <loading-button
        appRequirePermission="cards.admin-all,admin.all"
        buttonClass="btn btn-success btn-sm"
        iconName="pencil-square"
        [isLoading]="registerClientIsLoading"
        (click)="registerClient()"
      >Register client</loading-button>
    </span>
    <span class="mr-2 ml-1 row" *ngIf="(clientCardState === cardState.NO_CARD || clientCardState === cardState.VIRTUAL_ACTIVE)
    && !showRegisterButton && hasOpenLoans">
      <select (change)="setCardType($event)">
          <option>Choose the type</option>
          <option *ngFor="let key of cardTypeOptions" [ngValue]="key"
                  [disabled]="key === cardIssueType.VIRTUAL && clientCardState === cardState.VIRTUAL_ACTIVE">{{key}}</option>
      </select>
      <button appRequirePermission="cards.admin-all,admin.all"
              class="btn btn-sm btn-success ml-1"
              [disabled]="selectedCardType === null"
              (click)="createCard()">
                  <bi name="plus-circle"></bi>
                  Create card
      </button>
    </span>
    <span class="ml-2" *ngIf="clientCardState !== cardState.NO_CARD">
                 <button appRequirePermission="cards.admin-all,admin.all"
                         class="btn btn-sm btn-primary"
                         (click)="sendPin()">
                  <bi name="chat-square-text"></bi>
                  Send pin
                </button>
    </span>
    <span class="ml-2">
            <button class="btn btn-info btn-sm" (click)="viewCardHistory()">
              <bi name="clock"></bi> Card history
            </button>
    </span>

  </div>
  <!-- endregion -->
  <div class="container-fluid">
    <server-table>
      <table list-table class="table table-striped table-hover">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Type</th>
          <th>DiPocket Client Id</th>
          <th>DiPocket Account Id</th>
          <th>Card token</th>
          <th>Status</th>
          <th>Action</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let card of clientCardsInfo;">
          <td></td>
          <td>{{card.type}}</td>
          <td>{{card.diPocketClientId}}</td>
          <td>{{card.accountId}}</td>
          <td>{{card.token}}</td>
          <td>{{card.status}}</td>
          <td>
            <div class="d-flex">
              <span class="mr-2" *ngIf="card.status === cardStatus.ACTIVE">
                <button appRequirePermission="cards.admin-all,admin.all" class="btn btn-warning btn-sm" (click)="freezeCard(card.id)">
                  <bi name="x"></bi> Freeze
                </button>
              </span>
              <span class="mr-2" *ngIf="card.status === cardStatus.LOST">
                <button appRequirePermission="cards.admin-all,admin.all" class="btn btn-success btn-sm" (click)="unfreezeCard(card.id)">
                  <bi name="pencil-square"></bi> Unfreeze
                </button>
              </span>
              <span class="mr-2" *ngIf="card.status === cardStatus.ACTIVE || card.status === cardStatus.INACTIVE">
                <button appRequirePermission="cards.admin-all,admin.all" class="btn btn-danger btn-sm" (click)="closeCard(card.id)">
                  <bi name="x-circle"></bi> Close
                </button>
              </span>
              <span class="mr-2"
                    *ngIf="showActivateCardButton(card)">
                <button appRequirePermission="cards.admin-all,admin.all" class="btn btn-outline-success btn-sm" (click)="activateCard()">
                  <bi name="check"></bi> Activate
                </button>
              </span>
              <span class="mr-2"  *ngIf="card.status === cardStatus.ACTIVE && card.type === cardIssueType.PLASTIC">
                <button appRequirePermission="cards.admin-all,admin.all" class="btn btn-danger btn-sm" (click)="renounceCard()">
                  <bi name="x-circle"></bi> Renounce
                </button>
              </span>

            </div>

          </td>
          <td></td>
        </tr>
        </tbody>
      </table>

    </server-table>
  </div>
  <!-- endregion -->
  <div class="container-fluid row pb-1 mt-2 ml-1" *ngIf="showTransactionsTable">
    <div class="card">
      <div class="card-header text-light bg-primary">
        <bi name="briefcase"></bi>
        Transactions
      </div>
      <div class="card-text row">
        <div class="col-md-12">
          <form class="form-inline pb-2 pt-2" [formGroup]="transactionsDatesForm" (ngSubmit)="getTransactions()">
            <div class="input-group mx-sm-3 mb-2">
              <input class="form-control form-control-sm"
                     id="startDate"
                     formControlName="startDate"
                     placeholder="yyyy-mm-dd"
                     name="startDate"
                     ngbDatepicker
                     #startDate="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="startDate.toggle()" type="button"></button>
              </div>
            </div>
            <div class="input-group mx-sm-3 mb-2">
              <input class="form-control form-control-sm inline"
                     id="endDate"
                     formControlName="endDate"
                     placeholder="yyyy-mm-dd"
                     name="endDate"
                     ngbDatepicker
                     #endDate="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="endDate.toggle()" type="button"></button>
              </div>
            </div>
            <button type="submit" class="btn btn-primary mb-2" [disabled]="!transactionsDatesForm.valid">Get transactions</button>
          </form>
        </div>
        <div class="col-md-12">
          <server-table>
            <table list-table class="table table-striped table-hover table-sm">
              <thead class="text-light bg-primary">
              <tr>
                <th></th>
                <th>Even date</th>
                <th>Kind</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>State</th>
                <th>Full name</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of transactions">
                <td></td>
                <td>{{ item.eventDate }}</td>
                <td>{{ item.kind }}</td>
                <td>{{ item.trnAmount }}</td>
                <td>{{ item.trnCurrencyCode }}</td>
                <td>{{ item.stateName }}</td>
                <td>{{ item.fullName }}</td>
                <td>
                  <button type="button"
                          class="btn btn-outline-primary btn-sm"
                          (click)="viewTransactionDetails(item.detailsRef)"
                          title="View details">
                    <bi name="info-circle"></bi>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </server-table>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mb-3">
    <div class="card">
      <div class="card-header text-light bg-primary">
        <bi name="pencil-square"></bi>
        Client data in card system
        <button appRequirePermission="cards.admin-all,admin.all"
                *ngIf="showUpdateButton"
                class="btn btn-sm btn-light float-right mr-1"
                (click)="updateDiPocketClient()">
          <bi name="pencil-square"></bi>
          Update
        </button>
      </div>
      <div class="card-text row">

        <div class="col-md-6">
          <transform-table [transformInstructions]="clientInfoCardSystemTransform1" [entity]="clientInfoCardSystem"></transform-table>
        </div>
        <div class="col-md-6">
          <transform-table [transformInstructions]="clientInfoCardSystemTransform2" [entity]="clientInfoCardSystem"></transform-table>
        </div>

      </div>
    </div>
  </div>
</div>
