import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DebtConfigsListComponent } from './components/debt-configs-list/debt-configs-list.component';
import { DebtConfigComponent } from './components/debt-config/debt-config.component';


const routes: Routes = [
  {path: '', component: DebtConfigsListComponent, pathMatch: 'full'},
  {path: ':id', component: DebtConfigComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DebtConfigsRoutingModule {
}
