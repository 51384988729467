<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Number" type="string" property="loanNumber"></tr>
  <tr filter-element
      title="Status"
      type="enum"
      property="status"
      enum="status"
  ></tr>
  <tr filter-element
      title="Flow state"
      type="enum"
      property="flowState"
      enum="flowState"
  ></tr>
  <tr filter-element
      title="Product type"
      type="enum"
      property="productType"
      enum="productType"
  ></tr>
  <tr filter-element
      title="Type"
      type="enum"
      property="type"
      enum="type"
  ></tr>
  <tr filter-element
      title="Resolution"
      type="enum"
      property="resolution"
      enum="resolution"
  ></tr>
  <tr filter-element
      title="Reason"
      type="string"
      property="rejectReason"
  ></tr>
  <tr filter-element title="Client Id" type="number" property="clientId"></tr>
  <tr filter-element title="Credit score" type="string" property="creditScore"></tr>
  <tr filter-element title="Brand" type="string" property="productDetails.brand"></tr>
  </tbody>

  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="clientId" (sort)="onSort($event)">Client ID</th>
      <th>Asked Term</th>
      <th sortable="askedAmount" (sort)="onSort($event)">Asked Amount</th>
      <th>Receive Channel</th>
      <th>Brand</th>
      <th sortable="status" (sort)="onSort($event)">Status</th>
      <th sortable="flowState" (sort)="onSort($event)">Flow state</th>
      <th sortable="productType" (sort)="onSort($event)">Product type</th>
      <th sortable="productSubType" (sort)="onSort($event)">Product subtype</th>
      <th sortable="type" (sort)="onSort($event)">Type</th>
      <th sortable="resolution" (sort)="onSort($event)">Resolution</th>
      <th>Flow State Details</th>
      <th sortable="creditScore" (sort)="onSort($event)">Credit score</th>
      <th>Flow State Date</th>
      <th>Modified Date</th>
      <th>Create Date</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let application of response?.results; trackBy: trackById" (dblclick)="navigateTo(application.id)">
      <td></td>
      <td>{{application.id}}</td>
      <td>{{application.clientId}}</td>
      <td>{{application.askedTerm.loanLength?.value}} {{application.askedTerm.loanLength?.unit}}</td>
      <td>{{application.askedAmount}}</td>
      <td>{{application.receiveChannel}}</td>
      <td>{{application.brand}}</td>
      <td>{{application.status}}</td>
      <td>{{application.flowState}}</td>
      <td>{{application.productType}}</td>
      <td>{{application.productSubType}}</td>
      <td>{{application.type}}</td>
      <td>
        <button type="button"
                *ngIf="application.resolution"
                class="btn btn-outline-primary btn-sm"
                placement="left"
                ngbPopover="{{application.rejectReason}}"
                popoverTitle="Reject reason">
          {{application.resolution}}
        </button>
      </td>
      <td>{{application.flowStateDetails}}</td>
      <td>{{application.creditScore}}</td>
      <td>{{application.flowStateStartDateTime | date:'dd.MM.yyyy'}}</td>
      <td>{{application.modifiedDate | date:'dd.MM.yyyy'}}</td>
      <td>{{application.createDate | date:'dd.MM.yyyy'}}</td>
    </tr>
    </tbody>
  </table>
</server-table>

