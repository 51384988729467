import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AffiliateConfigsListComponent } from './components/affiliate-configs-list/affiliate-configs-list.component';
import { AffiliateReportListComponent } from './components/affiliate-report-list/affiliate-report-list.component';
import { AffiliateConfigComponent } from './components/affiliate-config/affiliate-config.component';


const routes: Routes = [
  {path: '', component: AffiliateConfigsListComponent, pathMatch: 'full'},
  {path: 'report', component: AffiliateReportListComponent, pathMatch: 'full'},
  {path: ':id', component: AffiliateConfigComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AffiliatesRoutingModule {
}
