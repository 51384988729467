<div class="mt-2 mb-2">
    <span class="">
          <button appRequirePermission="admin.all, product-configuration.edit"
                  class="btn btn-success btn-sm"
                  (click)="navigateToCreate()">
            <bi name="plus-circle"></bi>
            Create
          </button>
    </span>
    <span class="ml-2" *ngIf="showCreateVersionButton">
          <button appRequirePermission="admin.all, product-configuration.edit"
                  class="btn btn-outline-success btn-sm"
                  (click)="createProductVersion()">
            Create product version
          </button>
    </span>
</div>
<server-table>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th>Product type</th>
      <th>Subtype</th>
      <th>Brand</th>
      <th>Is changed</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let productConfig of productConfigList$ | async" (dblclick)="navigateToConfig(productConfig.productType, productConfig.subType, productConfig.brand)">
      <td></td>
      <td>{{productConfig.productType}}</td>
      <td>{{productConfig.subType}}</td>
      <td>{{productConfig.brand}}</td>
      <td>{{isProductConfigChanged(productConfig.productType, productConfig.subType, productConfig.brand)}}</td>
      <td></td>
    </tr>
    </tbody>
  </table>
</server-table>


