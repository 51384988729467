import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicComponent, BlacklistService, ModalResult } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlacklistCategory } from '@twino/backoffice-api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-blacklist-add',
  templateUrl: './blacklist-add.component.html',
  styleUrls: ['./blacklist-add.component.scss']
})
export class BlacklistAddComponent extends BasicComponent implements OnInit {

  blacklistAddForm: FormGroup;
  categories = BlacklistCategory;
  categoryOptions = [];

  constructor(
    public activeModal: NgbActiveModal,
    private blacklistService: BlacklistService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.categoryOptions = Object.values(this.categories);
    this.blacklistAddForm = this.formBuilder.group({
      personalId: ['', Validators.required],
      message: ['', Validators.required],
      active: [false],
      category: [null],
      ip: [null],
      identityDocNumber: [null],
      bankAccountNumber: [null],
      firstName: [null],
      lastName: [null],
      email: [null],
      bankCardNumber: [null],
    });
  }

  submitForm() {
    this.blacklistService.addToBlacklist(this.blacklistAddForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.activeModal.close(ModalResult.SUCCESS);
    });
  }

}
