<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">
      <div class="card">
        <div class="card-header text-light bg-primary">
          <bi name="pencil-square"></bi>
          Client
        </div>
        <div class="card-text">
          <transform-table [transformInstructions]="clientTransformations" [entity]="client"></transform-table>

          <ndc-dynamic *ngIf="task"
                       [ndcDynamicComponent]="taskRelatedEntitiesComponentType"
                       [ndcDynamicInputs]="{data: task}"
          ></ndc-dynamic>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header text-light bg-primary">
          <bi name="pencil-square"></bi>
          Last application
        </div>
        <div class="card-text">
          <transform-table [transformInstructions]="lastApplicationTransformations" [entity]="lastApplication"></transform-table>
        </div>
      </div>
    </div>
  </div>
</div>
