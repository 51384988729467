import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageTemplateService } from '@backoffice-monorepo/commons';
import { MessageTemplate } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-message-template-view',
  templateUrl: './message-template-view.component.html',
  styleUrls: ['./message-template-view.component.css']
})
export class MessageTemplateViewComponent implements OnInit {

  @Input() id: number;
  messageTemplate: MessageTemplate

  constructor(
    public activeModal: NgbActiveModal,
    private messageTemplateService: MessageTemplateService
  ) { }

  ngOnInit(): void {
    this.messageTemplateService.getMessageTemplate(this.id).pipe(
      take(1)
    ).subscribe(template => {
      this.messageTemplate = template;
    })
  }

}
