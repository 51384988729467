<aside class="container-fluid pt-3 pb-3 pl-1">
  <div class="col-md-12 ml-0 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
    <span class="mr-2 ml-3">
            <button appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                    class="btn btn-success btn-sm"
                    (click)="addFlow()">
              <bi name="pencil-square"></bi>
              Add flow
            </button>
      </span>
  </div>
</aside>
<server-table>
  <table list-table class="table table-striped table-hover mt-1">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th>Id</th>
      <th>Flow name</th>
      <th>Description</th>
      <th>Reference</th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let riskEvaluationFlow of riskEvaluationFlowsList"
        (dblclick)="navigateTo(riskEvaluationFlow.id)">
      <td></td>
      <td>{{riskEvaluationFlow.id}}</td>
      <td>{{riskEvaluationFlow.name}}</td>
      <td>{{riskEvaluationFlow.description}}</td>
      <td>{{riskEvaluationFlow.reference}}</td>
      <td>
        <button appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                class="btn btn-danger btn-sm"
                (click)="deleteFlow(riskEvaluationFlow.id)">
          Delete
        </button>
      </td>
      <td>
        <button appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                class="btn btn-success btn-sm"
                (click)="editFlow(riskEvaluationFlow.id)">
          <bi name="pencil-square"></bi>
          Edit
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</server-table>




