import { Component, Input, OnInit } from '@angular/core';
import { AmlService, DateUtils } from '@backoffice-monorepo/commons';
import { AmlScoringHistoryItem, AmlScoringHistoryRequest, FullClient } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-aml-scoring-history',
  templateUrl: './client-aml-scoring-history.component.html',
  styleUrls: ['./client-aml-scoring-history.component.css']
})
export class ClientAmlScoringHistoryComponent implements OnInit {

  @Input() client: FullClient;
  scoringHistory: AmlScoringHistoryItem[] = [];
  scoringHistoryLoaded: boolean;

  constructor(
    private amlService: AmlService,
  ) { }

  ngOnInit(): void {
    this.loadMoreScoringHistory(this.scoringHistory);
  }

  loadMoreScoringHistory(scoringHistory: AmlScoringHistoryItem[]) {
    this.amlService.getScoringHistory(
      this.client.savedId,
      new AmlScoringHistoryRequest(this.getBeforeDate(scoringHistory))
    ).pipe(
      take(1)
    ).subscribe(scoringHistoryData => {
      if (scoringHistoryData.length > 0) {
        this.scoringHistory = this.scoringHistory.concat(scoringHistoryData);
        this.scoringHistoryLoaded = false;
      } else {
        this.scoringHistoryLoaded = true;
      }
    });
  }

  private getBeforeDate(scoringHistory: AmlScoringHistoryItem[]): string {
    let beforeDate = new Date();
    if (scoringHistory.length > 0) {
      beforeDate = new Date(scoringHistory[scoringHistory.length - 1].scoredAt)
    }
    return DateUtils.formatDateTimeFrom(beforeDate);
  }
}
