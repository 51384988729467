import { NgModule } from '@angular/core';

import { BatchJobRoutingModule } from './batch-job-routing.module';
import { BatchJobListComponent } from './components/batch-job-list/batch-job-list.component';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { CommonModule } from '@angular/common';
import { BatchJobExecutionComponent } from './components/batch-job-execution/batch-job-execution.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { BatchJobRunComponent } from './components/batch-job-run/batch-job-run.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [BatchJobListComponent, BatchJobExecutionComponent, BatchJobRunComponent],
  imports: [
    CommonModule,
    CommonsModule,
    BatchJobRoutingModule,
    NgbPopoverModule,
    DragDropModule,
    ReactiveFormsModule
  ]
})
export class BatchJobModule { }
