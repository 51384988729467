import { Component, Input, OnInit } from '@angular/core';
import { DebtStepExecutionResult } from '@twino/backoffice-api';
import { LoanService, OptionalCoreComponent } from '@backoffice-monorepo/commons';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-loan-debt',
  templateUrl: './loan-debt.component.html',
  styleUrls: ['./loan-debt.component.css']
})
export class LoanDebtComponent implements OnInit, OptionalCoreComponent {

  @Input()
  loanId: number;
  loanDebtStep: DebtStepExecutionResult | null;

  constructor(
    private loanService: LoanService,
  ) { }

  ngOnInit(): void {
    this.loanService.getLoanDebtStep(this.loanId).pipe(
      take(1)
    ).subscribe(loanDebtStep => {
      this.loanDebtStep = loanDebtStep;
    })
  }

  trackById(index, item){
    return item.id;
  }
}
