<form [formGroup]="closeTaskForm" (ngSubmit)="submitForm()">

  <div class="modal-header">
    <h4 class="modal-title">
      <bi name="check"></bi>&nbsp; Close Task
    </h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('closed')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form-group">
      <label for="closeTaskTypeahead">Resolution<sup class="text-danger">*</sup></label>
      <input
        id="closeTaskTypeahead"
        type="text"
        class="form-control"
        formControlName="resolution"
        [ngbTypeahead]="search"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value);"
        (selectItem)="onChangeResolution($event.item)"
        #closeTaskTypeahead="ngbTypeahead"
      />
    </div>

    <div class="form-group" *ngIf="showPromiseDateInput">
      <label for="promiseDate">Promise date<sup class="text-danger">*</sup></label>
      <div class="input-group">
        <input ngbDatepicker
               id="promiseDate"
               class="form-control"
               placeholder="yyyy-mm-dd"
               name="dp"
               formControlName="promiseDate"
               #promiseDatepicker="ngbDatepicker"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="promiseDatepicker.toggle()" type="button"></button>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="closeTaskComment">Comment</label>
      <textarea class="form-control" id="closeTaskComment" formControlName="comment" rows="3"></textarea>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" autofocus class="btn btn-outline-secondary" (click)="modal.dismiss(false)">Cancel</button>
    <button type="submit" class="btn btn-danger" [disabled]="!closeTaskForm.valid">Ok</button>
  </div>

</form>
