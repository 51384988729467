import { Component, OnInit } from '@angular/core';
import { AlertsService, FileTemplateService, LocalesService, NamedComponent, ViewHistoryService } from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileTemplatesRefreshService } from '../../services/file-templates-refresh.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-file-template-add',
  templateUrl: './file-template-add.component.html',
  styleUrls: ['./file-template-add.component.scss']
})
export class FileTemplateAddComponent extends NamedComponent implements OnInit {

  fileTemplateAddForm: FormGroup;
  locales$: Observable<Array<string>>;
  fileData: File;

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private viewHistoryService: ViewHistoryService,
    private fileTemplatesService: FileTemplateService,
    private fileTemplatesRefreshService: FileTemplatesRefreshService,
    private localesService: LocalesService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.locales$ = this.localesService.getLocales();
    this.fileTemplateAddForm = this.formBuilder.group({
      key: ['', Validators.required],
      fileName: ['', Validators.required],
      locale: '',
      isDisabled: [false],
      file: ['', Validators.required],
    });
  }

  getName(): string {
    return `Create file template`;
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.fileTemplateAddForm.controls['fileName'].setValue(this.fileData.name);
  }

  submitForm() {
    this.fileTemplatesService.createFileTemplate(this.fileTemplateAddForm.value, this.fileData).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess("File template is added");
      this.viewHistoryService.closeTab();
      this.fileTemplatesRefreshService.reloadFileTemplatesListModel();
    })
  }

  cancel() {
    this.viewHistoryService.closeTab();
  }
}
