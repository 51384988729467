import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';
import {
  UpdateRiskEvaluationStepsEvaluationSequenceRequest,
  UpdateRiskEvaluationRequest,
  RiskEvaluationStepConfigResponse,
  UpdateRiskEvaluationStepRequest,
  AddRiskEvaluationStepRequest
} from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class RiskEvaluationConfigService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  listRuleTypes(): Observable<string[]> {
    return this.get(`/api/risks/evaluation-rules/types`);
  }

  getFlowStep(stepId: number): Observable<RiskEvaluationStepConfigResponse> {
    return this.get(`/api/risks/evaluation-steps/${stepId}`);
  }

  addRiskEvaluationStep(addRequest: AddRiskEvaluationStepRequest){
    return this.post(`/api/risks/evaluation-steps`, addRequest);
  }

  updateRiskEvaluationStepRules(stepId: number, updateConfigRequest: UpdateRiskEvaluationRequest){
    return this.put(`/api/risks/evaluation-steps/${stepId}/rules`, updateConfigRequest);
  }

  updateRiskEvaluationStep(updateRequest: UpdateRiskEvaluationStepRequest){
    return this.patch(`/api/risks/evaluation-steps`, updateRequest);
  }

  updateRiskEvaluationStepsEvaluationSequence(updateOrderRequest: UpdateRiskEvaluationStepsEvaluationSequenceRequest){
    return this.put(`/api/risks/evaluation-steps`, updateOrderRequest);
  }

  deleteRiskEvaluationStep(stepId: number): Observable<never> {
    return this.delete(`/api/risks/evaluation-steps/${stepId}`, {});
  }
}
