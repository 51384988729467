export interface ClientLoginAttemptResponse {
  ipAddress: string
  authenticationResult?: ClientAuthenticationResult
  loginAttemptDateTime: Date
}

export interface LoanApplicationIpAddressDataResponse {
  applicationId: number
  ipAddress: string
}

export enum ClientAuthenticationResult {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
