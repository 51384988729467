<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client Billing Details</b>
  </p>
  <div class="col-md-12">
    <table class="table table-striped table-hover table-sm">
      <thead class="text-light bg-primary">
      <tr>
        <th></th>
        <th>Type</th>
        <th>Owner</th>
        <th>Number</th>
        <th>Is default</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of billingDetails; trackBy: trackById">
        <td></td>
        <td>{{ item.type }}</td>
        <td>{{ item.owner }}</td>
        <td>{{ item.number }}</td>
        <td>{{ item.default }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>



