export interface DebtStepConfig {
  id: number
  debtStepName: string
  dpd?: number
  dli?: number
  debtRules: DebtStepRuleConfig[]
  isEnabled: boolean
  promiseDay?: number
  dcVersion: number
  odpd?: number
}

export interface DebtStepRuleConfig {
  ruleName: string
  debtRuleClassName: string
  params: Map<string, never>
}


export interface DebtStepConfigRequest {
  debtStepName: string
  dpd?: number
  dli?: number
  debtRules?: DebtStepRuleConfig[]
  isEnabled: boolean
  promiseDay?: number
  dcVersion: number
  odpd?: number
}


export interface PerformDebtOperationRequest {
  loanId: number
  operationDate: Date
}


export interface DebtStepExecutionResult {
  debtStepName: string
  loanId: number
  executedRules: RuleExecutionResult[]
  executionDate?: Date
}

export interface RuleExecutionResult {
  ruleName: string
  ruleClassName: string
  executionStatus: ExecutionStatus
}

export enum ExecutionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED'
}
