import { Component, OnInit } from '@angular/core';
import { CountryViewComponent, draggableModalOptions } from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiTransferAddComponent } from '../pl-pi-transfer-add/pl-pi-transfer-add.component';
import { PlPiTransferService } from '../../services/pl-pi-transfer.service';
import { PlPiBluemediaBalance } from '../../api/pl-pi-transfer';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-transfer-button-view',
  templateUrl: './pl-pi-transfer-button-view.component.html',
  styleUrls: ['./pl-pi-transfer-button-view.component.css']
})
export class PlPiTransferButtonViewComponent implements CountryViewComponent, OnInit {
  data: never;
  bluemediaBalance: PlPiBluemediaBalance | never;

  constructor(
    private modalService: NgbModal,
    private transferService: PlPiTransferService
  ) { }

  ngOnInit(): void {
    this.transferService.getBluemediaBalance().pipe(
      take(1)
    ).subscribe(balance => {
      this.bluemediaBalance = balance;
    })
  }

  createTransfer() {
    this.modalService.open(PlPiTransferAddComponent, draggableModalOptions);
  }
}
