import { Term } from './term';
import { CommissionRateParameters, CurrencySettings, DiscountShortInfo, DiscountTarget, ScheduleItem, SearchModel } from '@twino/backoffice-api';

export interface Agreement extends SearchModel {
  id: number
  loanId: number
  offerId: number
  loanApplicationId: number
  amount: number
  newAmount: number
  term: Term
  extensionTerm?: Term
  status: string
  type: string
  startDate: Date
  dueDate: Date
  maxPenaltyCoeff: number
  penaltyRatePerDay: number
  penaltyGracePeriodDays: number
  penaltyGenerationPeriodBeginningDays: number
  discountPercent: number
  interestRatePerYear: number
  annualPercentageRate: number
  scheduleItems: ScheduleItem[]
  current: boolean
  signDate?: Date
  closedDate?: Date
  loanPurpose?: string
  currencySettings?: CurrencySettings
  lenderId?: number
  statusDetail?: string
  appliedDiscounts: Map<DiscountTarget, DiscountShortInfo>
  commissionRateParameters: CommissionRateParameters[]
}

export enum LoanAgreementType {
  MAIN = 'MAIN',
  ADDITIONAL_AMOUNT = 'ADDITIONAL_AMOUNT',
  EXTENSION = 'EXTENSION',
  DEBT_COLLECTION = 'DEBT_COLLECTION',
  RENOUNCEMENT = 'RENOUNCEMENT',
  EARLY_PARTIAL_REPAY = 'EARLY_PARTIAL_REPAY',
  TERMINATION = 'TERMINATION',
  CDS = 'CDS',
  CDS_FRAUD = 'CDS_FRAUD',
  COSTS_WRITEOFF = 'COSTS_WRITEOFF',
  RENOUNCEMENT_CDS = 'RENOUNCEMENT_CDS',
  DC_TERMINATION = 'DC_TERMINATION',
  PRE_SOLD = 'PRE_SOLD',
  PROLONGATION = 'PROLONGATION',
  AML_TERMINATION = "AML_TERMINATION",
}
