import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoanSaleDataListComponent } from './components/loan-sale-data-list/loan-sale-data-list.component';
import { LoanPresaleDataListComponent } from './components/loan-presale-data-list/loan-presale-data-list.component';

const routes: Routes = [
  {path: '', component: LoanSaleDataListComponent, pathMatch: 'full'},
  {path: 'presale', component: LoanPresaleDataListComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoanSaleRoutingModule {
}
