import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'backoffice-monorepo-client-block',
  templateUrl: './client-block.component.html',
  styleUrls: ['./client-block.component.scss']
})
export class ClientBlockComponent {
  clientId: number;
  messageFormControl = new FormControl('', Validators.required);

  constructor(
    public activeModal: NgbActiveModal
  ) { }
}
