<form [formGroup]="loanWriteOffForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Write off loan #{{loanId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger"><strong>Write off operation can't be UNDONE, please check amounts in advance!</strong></p>
    <div class="form-group row">
      <label for="principalAmount" class="col-sm-4 control-label">Principal amount</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="principalAmount" formControlName="principalAmount" name="principalAmount">
      </div>
    </div>
    <div class="form-group row">
      <label for="interestAmount" class="col-sm-4 control-label">Interest amount</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="interestAmount" formControlName="interestAmount" name="interestAmount">
      </div>
    </div>
    <div class="form-group row">
      <label for="loanFeeAmount" class="col-sm-4 control-label">Loan fee amount</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="loanFeeAmount" formControlName="loanFeeAmount" name="loanFeeAmount">
      </div>
    </div>
    <div class="form-group row">
      <label for="penaltyAmount" class="col-sm-4 control-label">Penalty amount</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="penaltyAmount" formControlName="penaltyAmount" name="penaltyAmount">
      </div>
    </div>
    <div class="form-group row">
      <label for="loanCommissionAmount" class="col-sm-4 control-label">Loan commission amount</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="loanCommissionAmount" formControlName="loanCommissionAmount" name="loanCommissionAmount">
      </div>
    </div>
    <div class="form-group row">
      <label for="reason" class="col-sm-4 control-label">Reason</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="reason" formControlName="reason" name="reason">
      </div>
    </div>
    <div class="form-group row">
      <label for="bookingDate" class="col-sm-4 control-label">Booking date<sup class="text-danger">*</sup></label>
      <div class="input-group col-sm-8">
        <input class="form-control form-control-sm"
               id="bookingDate"
               formControlName="bookingDate"
               placeholder="yyyy-mm-dd"
               name="bookingDate"
               ngbDatepicker
               #bookingDate="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="bookingDate.toggle()" type="button"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!loanWriteOffForm.valid">Submit</button>
  </div>
</form>








