<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Client Id" type="number" property="clientId"></tr>
  <tr filter-element title="Name" type="string" property="name"></tr>
  <tr filter-element
      title="Type"
      type="enum"
      property="type"
      enum="type"
  ></tr>
  <tr filter-element title="Token" type="string" property="token"></tr>
  <tr filter-element title="Partner name" type="string" property="partnerName"></tr>
  <tr filter-element title="Additional parameter" type="string" property="additionalParameter"></tr>
  <tr filter-element title="Loans count" type="string" property="loansCount"></tr>
  <tr filter-element
      title="Create date"
      type="instant"
      property="created"
  ></tr>
  </tbody>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="clientId" (sort)="onSort($event)">Client</th>
      <th sortable="name" (sort)="onSort($event)">Name</th>
      <th sortable="type" (sort)="onSort($event)">Type</th>
      <th>Token</th>
      <th sortable="partnerName" (sort)="onSort($event)">Partner name</th>
      <th>Additional parameter</th>
      <th>Custom tracker</th>
      <th sortable="loansCount" (sort)="onSort($event)">Loans count</th>
      <th>Postback url</th>
      <th sortable="created" (sort)="onSort($event)">Created</th>
      <th sortable="updated" (sort)="onSort($event)">Updated</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of response?.results; let i = index; trackBy: trackById">
      <td></td>
      <td>{{item.id}}</td>
      <td><a routerLink="/clients/{{item.clientId}}">{{item.clientId}}</a></td>
      <td>{{item.name}}</td>
      <td>{{item.type}}</td>
      <td>{{item.token}}</td>
      <td>{{item.partnerName}}</td>
      <td>{{item.additionalParameter}}</td>
      <td>{{item.customTracker}}</td>
      <td>{{item.loansCount}}</td>
      <td>{{item.postbackUrl}}</td>
      <td>{{item.created}}</td>
      <td>{{item.updated}}</td>
    </tr>
    </tbody>
  </table>
</server-table>




