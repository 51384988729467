import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { noop, Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NgbDateStruct, NgbPopoverConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTimePickerResetService } from '../../services/date-time-picker-reset.service';
import * as dayjs from 'dayjs'
import * as toArray from 'dayjs/plugin/toArray';
dayjs.extend(toArray)

@Component({
  selector: 'date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor {

  @Input() propertyName: string;
  @Input() date: string | never;
  inputDatetimeFormat = 'YYYY-MM-dd HH:mm:ss';
  hourStep = 1;
  minuteStep = 10;
  secondStep = 10;
  seconds = true;


  private onTouched: () => void = noop;
  private onChange: (_: string) => void = noop;
  private resetFilterContent: Subscription;

  dateStruct: string;
  timeStruct: NgbTimeStruct = {hour: 0, minute: 0, second: 0};
  ngControl: NgControl;

  constructor(
    private config: NgbPopoverConfig,
    private inj: Injector,
    private dateTimePickerResetService: DateTimePickerResetService
  ) {
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
    this.resetFilterContent = this.dateTimePickerResetService.resetFilterContent$.subscribe(inputName => {
      if(this.propertyName === inputName) {
        this.resetContent();
      }
    })
  }

  registerOnChange(fn: never): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }

  inputBlur($event) {
    this.onTouched();
  }

  onInputChange($event: Event) {}

  onDateChange(event: NgbDateStruct) {
    this.setDateStringModel();
  }

  onTimeChange(event: NgbTimeStruct) {
    this.setDateStringModel();
  }

  setDateStringModel() {
    if (this.dateStruct) {

      const selectedDate = dayjs(this.dateStruct).toArray();
      const selectedDateTime = new Date(
        selectedDate[0],
        selectedDate[1],
        selectedDate[2],
        this.timeStruct.hour,
        this.timeStruct.minute,
        this.timeStruct.second
      );
      this.date = dayjs(selectedDateTime).format('YYYY-MM-DD HH:mm:ss');
      this.onChange(this.date);
    }
  }

  writeValue(model: string): void {

  }

  resetContent() {
    this.date = null;
  }
}
