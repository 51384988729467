import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertsService, BasicComponent, RiskEvaluationConfigService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { UpdateRiskEvaluationStepRequest } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-risk-evaluation-flow-step',
  templateUrl: './risk-evaluation-flow-step.component.html',
  styleUrls: ['./risk-evaluation-flow-step.component.css']
})
export class RiskEvaluationFlowStepComponent extends BasicComponent implements OnInit, OnDestroy {

  @Input() id: number | undefined;
  @Input() flowId: number | undefined;
  @Input() isEdit: boolean;
  riskEvaluationFlowStepForm: FormGroup;
  title: string | never;


  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private riskEvaluationConfigService: RiskEvaluationConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = (this.isEdit) ? "Edit" : "Add";
    if (this.isEdit === false) {
      this.riskEvaluationFlowStepForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        continueEvaluation: [false],
        evaluationSequence: ['', Validators.required],
        flowId: this.flowId
      });
    } else {
      this.riskEvaluationFlowStepForm = this.formBuilder.group({
        newName: ['', Validators.required],
        newDescription: ['', Validators.required]
      });
      this.riskEvaluationConfigService.getFlowStep(this.id).pipe(
        take(1)
      ).subscribe(result => {
        const flowData = {
          newName: result.name,
          newDescription: result.description
        }
        this.riskEvaluationFlowStepForm.setValue(flowData);
      })
    }
  }

  submitForm() {
    if (this.isEdit) {
      this.updateFlowStep();
    } else {
      this.addFlowStep();
    }
  }

  addFlowStep() {
    this.riskEvaluationConfigService.addRiskEvaluationStep(this.riskEvaluationFlowStepForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Flow step ${this.riskEvaluationFlowStepForm.controls.name.value} added`);
      this.activeModal.close();
    })
  }

  updateFlowStep() {
    const request: UpdateRiskEvaluationStepRequest = {
      id: this.id,
      newDescription: this.riskEvaluationFlowStepForm.controls.newDescription.value,
      newName: this.riskEvaluationFlowStepForm.controls.newName.value,
    }
    this.riskEvaluationConfigService.updateRiskEvaluationStep(request).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Flow Step updated`);
      this.activeModal.close();
    })
  }
}
