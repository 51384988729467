import { Component, Input } from '@angular/core';
import { ScheduleItemFee } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-loan-fees-view',
  templateUrl: './loan-fees-view.component.html',
  styleUrls: ['./loan-fees-view.component.css']
})
export class LoanFeesViewComponent  {
  @Input() fees: ScheduleItemFee[];

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  trackById(index: number, item: ScheduleItemFee){
    return item.accountType;
  }
}
