import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-content-dialogue',
  templateUrl: './content-dialogue.component.html',
  styleUrls: ['./content-dialogue.component.css']
})
export class ContentDialogueComponent {
  @Input() content: string;

  constructor(public activeModal: NgbActiveModal) {}
}
