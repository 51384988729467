export class MessageTemplate {
  id: number
  key: string
  locale: string
  isDisabled: boolean
  message: string
  entityVersion?: number
  created: Date
  updated: Date
  brand?: string
}

export class MessageTemplateRequest {
  key: string
  locale?: string
  isDisabled: boolean
  message: string
  brand?: string

  constructor(key: string, locale: string = null, isDisabled: boolean, message: string, brand: string) {
    this.key = key;
    this.locale = locale;
    this.isDisabled = isDisabled;
    this.message = message;
    this.brand = brand;
  }
}
