import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-confirm-dialogue',
  templateUrl: './confirm-dialogue.component.html',
  styleUrls: ['./confirm-dialogue.component.scss']
})
export class ConfirmDialogueComponent {

  @Input() content: string;
  @Input() header = "Are you sure?";
  @Input() class = "alert-danger";
  @Input() icon = "exclamation-octagon-fill";

  constructor(public activeModal: NgbActiveModal) {
  }

}
