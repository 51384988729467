import { SortDirection } from '@twino/backoffice-api';
import { QueueList } from '../../../../../apps/vamo-vn/src/app/api/vamo-tasks';

export class Utils {

  constructor() {
  }

  static basicClone(original) {
    return JSON.parse(JSON.stringify(original));
  }

  static parseInt(number: string | null | undefined): string {
    if (!number) {
      return "0";
    } else {
      return Number.parseInt(number, 10).toString();
    }
  }

  static parseAmount(number: string | null | undefined): string {
    if (!number) {
      return "0.00";
    } else {
      return Number.parseFloat(number).toFixed(2).toString();
    }
  }

  static isNullishOrEmpty(obj) {
    if (obj === null || typeof obj === undefined) return true;
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key))
        return false;
    }
    return true;
  }

  static isNotNullishOrEmpty(obj) {
    return !Utils.isNullishOrEmpty(obj);
  }

  static downloadBlob(response: Blob, fileName) {
    const file = new File([response], encodeURI(fileName), {type: "binary/octet-stream"});
    const fileURL = URL.createObjectURL(file);

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.style.display = "none";
    link.href = fileURL;
    link.download = fileName;
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(fileURL);
  }

  static toPercentString(number: number): string {
    return `${number}%`;
  }

  static convertRatioToPercent(ratio: number): string {
    return Utils.toPercentString(ratio * 100);
  }

  static sortById(idA: number, idB: number, sortDirection: SortDirection = 'ASC'): number {
    switch (sortDirection) {
      case 'ASC':
        return idA - idB;
      case 'DESC':
        return idB - idA;
      default:
        return 0;
    }
  }

  static transformLabel(label: string): string {
    return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase().replace("_", " ");
  }

  static convertConfigToArray(configObject: Map<string, string>): QueueList[] {
    const result = [];
    Object.keys(configObject).map(function(key){
      result.push({ value : configObject[key], label : key})
    });
    return result;
  }

  static isNullOrEmptyVar(input: string) {
    return input === null || input === undefined || input === "undefined";
  }

  static roundAmount(amount: number): string {
    return amount.toFixed(2);
  }

  static enumValueToLabel(value: string): string {
    return value.replace(/_/g, ' ');
  }
}
