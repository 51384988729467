import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, DateUtils, PaymentService, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { PaymentsRefreshService } from '../../services/payments-refresh.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'payment-from-surplus-add',
  templateUrl: './payment-from-surplus-add.component.html',
  styleUrls: ['./payment-from-surplus-add.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class PaymentFromSurplusAddComponent extends BasicComponent implements OnInit {

  paymentFromSurplusAddForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private paymentsRefreshService: PaymentsRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.paymentFromSurplusAddForm = this.formBuilder.group({
      loanNumber: ['', Validators.required],
      amount: ['', Validators.required],
      timestamp: ['', Validators.required],
      comment: ['', Validators.required]
    });
  }

  submitForm() {
    this.paymentFromSurplusAddForm.value.timestamp = DateUtils.datePlusHoursWithFormat(3, this.paymentFromSurplusAddForm.value.timestamp);
    this.paymentService.createPaymentFromSurplus(this.paymentFromSurplusAddForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Payment surplus added`);
      this.paymentsRefreshService.reloadPaymentsListModel();
      this.activeModal.close();
    })
  }
}
