export class FileTemplate {
  id: number
  key: string
  fileName: string
  locale: string
  isDisabled: boolean
  file: ArrayBuffer
  entityVersion?: number
}

export class FileTemplateRequest {
  key: string
  fileName?: string
  locale: string
  isDisabled: boolean
}

export class FileTemplateRevision {
  revisionId: number
  timestamp: Date
  user: string
  key: string
  fileName: string
  locale: string
  isDisabled: boolean
}
