import { HttpClient } from '@angular/common/http';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { Injectable } from '@angular/core';
import { LoanRenouncementRequest } from '../api/loan-renouncement';
import { AbateMtpRequest, CreateLoanTerminationAgreementRequest } from '../api/pl-pi-loan';
import { Observable } from 'rxjs';
import { OfferValueAddedServiceResponse } from '../api/pl-pi-offer';

@Injectable({
  providedIn: 'root'
})
export class PlPiLoanService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  pauseLoan(loanId: number) {
    return this.put(`/api/pl/loan/${loanId}/pause`, {});
  }

  resumeLoan(loanId: number) {
    return this.put(`/api/pl/loan/${loanId}/resume`, {});
  }

  renounceLoan(loanId: number, request: LoanRenouncementRequest) {
    return this.put(`/api/pl/loan/${loanId}/renounce`, request);
  }

  createLoanTerminationAgreement(loanId: number, request: CreateLoanTerminationAgreementRequest) {
    return this.post(`/api/pl/loan/${loanId}/termination-agreement`, request);
  }

  abateMtpPrincipal(loanId: number, request: AbateMtpRequest) {
    return this.post(`/api/pl/loan/${loanId}/abate-mtp-principal`, request);
  }

  getCardBalance(loanId: number): Observable<number> {
    return this.get(`/api/pl/loan/${loanId}/card-balance`, {}, true, 409);
  }
}
