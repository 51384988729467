import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { EnumResponse, FileTemplateRequest, FileTemplateRevision, SearchQuery, SearchQueryAware, SearchResponse } from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';
import { FileTemplate } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class FileTemplateService extends BaseService implements SearchQueryAware<FileTemplate> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<FileTemplate>> {
    return this.post(`/api/template/file-templates/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/template/file-templates/enum-values/${enumClass}`);
  }

  getFileTemplates(): Observable<SearchResponse<FileTemplate>> {
    return this.post(`/api/template/file-templates/list`, {});
  }

  getFileTemplate(id: number): Observable<FileTemplate> {
    return this.get(`/api/template/file-template/${id}`);
  }

  getFileOfTemplate(id: number): Observable<Blob> {
    return this.get(`/api/template/file-template/${id}/file`, {'responseType': 'blob'});
  }

  createFileTemplate(fileTemplateRequest: FileTemplateRequest, file: File): Observable<void> {
    const formData = new FormData();
    formData.append(
      'fileTemplate',
      new Blob(
        [JSON.stringify(fileTemplateRequest)],
        {type: "application/json"}
      )
    );
    formData.append('file', file);

    return this.post(`/api/template/file-template`, formData);
  }

  updateFileTemplate(id: number, fileTemplateRequest: FileTemplateRequest, file?: File): Observable<void> {
    const formData = new FormData();
    formData.append(
      'fileTemplate',
      new Blob(
        [JSON.stringify(fileTemplateRequest)],
        {type: "application/json"}
      )
    );
    if (file) {
      formData.append('file', file);
    }

    return this.post(`/api/template/file-template/${id}`, formData);
  }

  deleteFileTemplate(id: number) {
    return this.delete(`/api/template/file-template/${id}`, {});
  }

  getRevisions(id: number): Observable<FileTemplateRevision[]> {
    return this.get(`/api/template/file-template/${id}/revisions`, {});
  }

  getRevisionFile(id: number, revisionId: number): Observable<Blob> {
    return this.get(`/api/template/file-template/${id}/revision/${revisionId}/file`, {'responseType': 'blob'});
  }
}
