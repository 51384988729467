<server-table>
  <table list-table class="table table-striped table-sm table-hover mt-3">
    <thead class="text-light bg-primary">
    <tr>
      <th>Id</th>
      <th>File name</th>
      <th>Locale</th>
      <th>Disabled</th>
      <th>User</th>
      <th>Date</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let revision of revisionHistory">
      <td>{{ revision.revisionId }}</td>
      <td>{{ revision.fileName }}</td>
      <td>{{ revision.locale }}</td>
      <td>{{ revision.isDisabled }}</td>
      <td>{{ revision.user }}</td>
      <td>{{ revision.timestamp | date:'dd.MM.yyyy HH:mm:ss'}}</td>
      <td>
        <button type="button" class="btn btn-link float-left mt-2" (click)="downloadFile(revision.fileName,revision.revisionId)">
          {{revision.fileName}}
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</server-table>








