import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicComponent, TaskGroupService } from '@backoffice-monorepo/commons';
import { TasksRefreshService } from '../../services/tasks-refresh.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'task-group-add',
  templateUrl: './task-group-add.component.html',
  styleUrls: ['./task-group-add.component.scss']
})
export class TaskGroupAddComponent extends BasicComponent implements OnInit {

  taskGroupAddForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private taskGroupService: TaskGroupService,
    private formBuilder: FormBuilder,
    private taskGroupRefreshService: TasksRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.taskGroupAddForm = this.formBuilder.group({
      groupName: ['', Validators.required]
    });
  }

  submitForm() {
    this.taskGroupService.createTaskGroup(this.taskGroupAddForm.value.groupName).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.taskGroupRefreshService.reloadTaskGroupListModel();
      this.activeModal.close();
    })
  }
}
