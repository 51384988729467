export class SystemConfiguration {
  id: number
  name: string
  data: string
}

export class SystemConfigurationRequest {
  name: string
  data: string
}
