export class SearchAndEnumResponse<T> {
  response?: SearchResponse<T>
  enumValuesResponse?: { [key: string]: EnumResponse }
}

/**
 * @property results the list of results
 * @property totalRecords number of total records
 * @property countLimitReached boolean whether 'real' totalRecords > maxCountLimit
 * @property totalPages number of total pages
 * @property currentPage number of current page
 */
export class SearchResponse<T> {
  results: T[]
  totalRecords: number
  countLimitReached? : boolean
  totalPages: number
  currentPage: number
  totalAmountSum? : number
  loansCount?: number
  reportDate?: Date
}

/**
 * @property enumClass FQN of enum class
 * @property enumValues values of enum
 */
export class EnumResponse {
  enumClass: string
  enumValues: string[]
}
