import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AffiliatesRoutingModule } from './affiliates-routing.module';
import { AffiliateConfigsListComponent } from './components/affiliate-configs-list/affiliate-configs-list.component';
import { AffiliateReportListComponent } from './components/affiliate-report-list/affiliate-report-list.component';
import { AffiliateConfigComponent } from './components/affiliate-config/affiliate-config.component';
import { AffiliateConfigurationReportComponent } from './components/affiliate-configuration-report/affiliate-configuration-report.component';


@NgModule({
  declarations: [AffiliateConfigsListComponent, AffiliateReportListComponent, AffiliateConfigComponent, AffiliateConfigurationReportComponent],
  imports: [
    CommonModule,
    AffiliatesRoutingModule,
    CommonsModule,
    NgbNavModule,
    TranslateModule,
    ReactiveFormsModule,
  ]
})
export class AffiliatesModule { }

