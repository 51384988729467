import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  EnumResponse,
  SearchQuery,
  SearchQueryAware,
  SearchResponse, SystemConfiguration, SystemConfigurationRequest,
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigService extends BaseService implements SearchQueryAware<SystemConfiguration> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<SystemConfiguration>> {
    return this.post(`/api/system-configurations/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/system-configuration/enum-values/${enumClass}`);
  }

  getSystemConfigRecord(id: number): Observable<SystemConfiguration> {
    return this.get(`/api/system-configuration/${id}`);
  }

  getSystemConfigRecordByName(configName: string): Observable<SystemConfiguration> {
    return this.get(`/api/system-configuration/get-by-name/${configName}`);
  }

  createSystemConfig(systemConfigurationRequest: SystemConfigurationRequest) {
    return this.post(`/api/system-configuration`, systemConfigurationRequest);
  }

  updateSystemConfig(systemConfigurationRequest: SystemConfigurationRequest) {
    return this.post(`/api/system-configuration/${systemConfigurationRequest.name}`, JSON.stringify(systemConfigurationRequest.data));
  }
}

