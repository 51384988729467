<form [formGroup]="discountCampaignForm" (ngSubmit)="submitForm()">
  <div class="container-fluid pt-2 col-sm-5">
    <div class="card">
      <div class="card-header">
        Campaign
        <span class="float-right">
          <button type="submit" class="btn btn-outline-danger" [disabled]="!discountCampaignForm.valid">Save</button>
        </span>
      </div>
      <div class="card-body">
        <div class="form-group row">
          <label for="name" class="col-sm-3 control-label">Name<sup class="text-danger">*</sup></label>
          <div class="col-sm-6">
            <input type="text" class="form-control form-control-sm" id="name" formControlName="name" name="name">
          </div>
        </div>
        <div class="form-group row">
          <label for="fromDate" class="col-sm-3 control-label">From date<sup class="text-danger">*</sup></label>
          <div class="col-sm-6 input-group">
            <input class="form-control form-control-sm"
                   id="fromDate"
                   formControlName="fromDate"
                   placeholder="yyyy-mm-dd"
                   name="fromDate"
                   ngbDatepicker
                   #fromDate="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="fromDate.toggle()" type="button"></button>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="toDate" class="col-sm-3 control-label">To date<sup class="text-danger">*</sup></label>
          <div class="col-sm-6 input-group">
            <input class="form-control"
                   id="toDate"
                   formControlName="toDate"
                   placeholder="yyyy-mm-dd"
                   name="toDate"
                   ngbDatepicker
                   #toDate="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="toDate.toggle()" type="button"></button>
            </div>
          </div>
        </div>
        <div class="form-group row control-label">
          <label class="form-check-label col-sm-4" for="isActive">
            Is Active
          </label>
          <div class="form-check col-sm-6">
            <input class="form-check-input ml-1"
                   type="checkbox"
                   id="isActive"
                   formControlName="isActive"
                   name="isActive">
          </div>
        </div>
        <div class="form-group row control-label">
          <label class="form-check-label col-sm-4" for="isSingleUse">
            Is single use
          </label>
          <div class="form-check col-sm-6">
            <input class="form-check-input ml-1"
                   type="checkbox"
                   id="isSingleUse"
                   formControlName="isSingleUse"
                   name="isSingleUse">
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-header">
        Discount
        <span class="float-right">
          <button type="button" appRequirePermission="admin.all"
                  class="btn btn-success mr-1"
                  (click)="addDiscountRow()">
            Add discount
          </button>
        </span>
      </div>
      <div class="card-body" formArrayName="discountRows">
        <div class="card mt-2" *ngFor="let group of getDiscountControls.controls; let i=index" [formGroupName]="i">
          <div class="card-header">
            <button class="btn btn-outline-danger btn-sm float-right" (click)="deleteDiscountRow(i)">Delete</button>
          </div>
          <div class="card-body">
            <div class="container">
              <div class="row">
                <div class="col-sm p-1">
                  <div class="form-group row">
                    <label for="discountTarget" class="col-sm-6 control-label">Discount target</label>
                    <select class="form-control form-control-sm col-sm-8" id="discountTarget" formControlName="discountTarget" name="discountTarget">
                      <option *ngFor="let key of discountTargetOptions" [ngValue]="key">{{key}}</option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="discountPercent" class="col-sm-6 control-label">Discount percent</label>
                    <input type="number"
                           class="form-control form-control-sm col-sm-8"
                           id="discountPercent"
                           formControlName="discountPercent"
                           name="discountPercent">
                  </div>
                </div>
                <div class="col-sm p-1">
                    <div formArrayName="predicateRows">
                      <div *ngFor="let group of predicateScriptInformation.variables; let i=index" [formGroupName]="i">
                        <div class="form-group row">
                          <label for="predicateValue" class="col-sm-6 control-label">{{group}}</label>
                          <input type="hidden"
                                 class="form-control"
                                 id="predicateKey"
                                 formControlName="predicateKey"
                                 name="predicateKey">
                          <input type="text"
                                 class="form-control form-control-sm col-sm-8"
                                 id="predicateValue"
                                 formControlName="predicateValue"
                                 name="predicateValue">
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

