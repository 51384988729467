import { Injectable, Optional, SkipSelf } from '@angular/core';
import { UserService } from './user.service';
import { ErrorsService } from './errors.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginPollService {

  checkTimeout = 10 * 60 * 1000;
  timer;

  constructor(
    private errorsService: ErrorsService,
    private userService: UserService,
    private router: Router,
    @Optional() @SkipSelf() parent?: LoginPollService
  ) {
    if (parent) {
      throw Error("[LoginPollService]: trying to create multiple instances, but this service should be a singleton.")
    }
  }

  checkSession(): void {
    this.userService.isLoggedIn().subscribe((response) => {
      if (response === true && !this.userService.isAuthenticated()) {
        this.userService.loadUser();
      }
      if (response === false && this.userService.isAuthenticated()) {
        this.errorsService.reportError('sessionExpired');
      }
    }, (response) => {
      if (response.status === 401 || response.status === 403) {
        this.errorsService.reportError('sessionExpired');
      } else if (response.status === 0 || response.status >= 404 || response.status >= 500) {
        this.errorsService.reportError('connectionLost');
      }
    });
  }

  refresh(): void {
    const service = this;
    if (this.router.url.includes('/login')) {
      return;
    }
    try {
      service.checkSession();
    } finally {
    }
  }

  startPolling(): void {
    this.timer = setInterval(() => this.refresh(), this.checkTimeout);
  }

  stopPolling(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
