import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentsRoutingModule } from './payments-routing.module';
import { PaymentsListComponent } from './components/payments-list/payments-list.component';
import { PaymentComponent } from './components/payment/payment.component';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbDatepickerModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentDistributionAddComponent } from './components/payment-distribution-add/payment-distribution-add.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentFromSurplusAddComponent } from './components/payment-from-surplus-add/payment-from-surplus-add.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DynamicModule } from 'ng-dynamic-component';


@NgModule({
  declarations: [PaymentsListComponent, PaymentComponent, PaymentDistributionAddComponent, PaymentFromSurplusAddComponent],
	imports: [
		CommonModule,
		PaymentsRoutingModule,
		CommonsModule,
		NgbNavModule,
		TranslateModule,
		ReactiveFormsModule,
		NgbDatepickerModule,
		DragDropModule,
		DynamicModule
	]
})
export class PaymentsModule { }
