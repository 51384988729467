import { Component, Input, OnInit } from '@angular/core';
import { AlertsService, BasicComponent, DateUtils, LoanProlongationResignationsService } from '@backoffice-monorepo/commons';
import { CountrySpecific } from '../../../../../../libs/commons/src/lib/services/country-specific';
import { ResignProlongationRequest } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-prolongation-resignations-resign-modal',
  templateUrl: './pl-pi-loan-prolongation-resignations-resign-modal.component.html',
  styleUrls: ['./pl-pi-loan-prolongation-resignations-resign-modal.component.css']
})
export class PlPiLoanProlongationResignationsResignModalComponent extends BasicComponent implements OnInit, CountrySpecific {

  @Input()
  loanId: number;
  loanProlongationResignationForm: FormGroup;
  originators$: Observable<string[]>;

  constructor(
    private loanProlongationResignationsService: LoanProlongationResignationsService,
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.originators$ = this.loanProlongationResignationsService.getOriginators();
    this.loanProlongationResignationForm = this.formBuilder.group({
      originator: ['', Validators.required],
      resignationCause: ['', Validators.required]
    });
  }

  submitForm() {
    const resignProlongationRequest: ResignProlongationRequest = {
      loanId: this.loanId,
      originator: this.loanProlongationResignationForm.value.originator,
      resignationCause: this.loanProlongationResignationForm.value.resignationCause,
      onTime: DateUtils.dateTimeNowAsDate()
    }
    this.loanProlongationResignationsService.resignProlongation(resignProlongationRequest)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.activeModal.close();
      this.alertService.notifySuccess(`Loan prolongation resigned`);
    })
  }
}
