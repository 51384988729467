<form [formGroup]="messageTemplateEditForm" (ngSubmit)="submitForm()">
  <div class="modal-header">
    <h4 class="modal-title">Edit template #{{id}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="key" class="col-sm-2 control-label">Key<sup class="text-danger">*</sup></label>
      <div class="col-sm-10">
        <input type="text" class="form-control form-control-sm" id="key" formControlName="key" name="key" ngbAutofocus>
      </div>
    </div>
    <div class="form-group row">
      <label for="brand" class="col-sm-2 control-label">Brand</label>
      <div class="col-sm-10">
        <select class="form-control form-control-sm" id="brand" formControlName="brand" name="brand" ngbAutofocus>
          <option *ngFor="let key of productBrandsList$ | async" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <div class="d-flex flex-column col-sm-2">
        <label for="message" class="control-label">Message<sup class="text-danger">*</sup></label>
        <button
          type="button"
          class="btn btn-sm btn-outline-primary"
          (click)="switchTextarea()"
        >{{useTinyMCE ? 'Switch to textarea' : 'Switch to TinyMCE'}}</button>
      </div>
      <div class="col-sm-10">
        <editor
          *ngIf="useTinyMCE"
          formControlName="message"
          [init]="editorConfig"
        ></editor>

        <textarea
          *ngIf="!useTinyMCE"
          class="form-control form-control-sm"
          id="message"
          formControlName="message"
          name="message"
          cols="50"
        ></textarea>
      </div>
    </div>
    <div class="form-group row">
      <label for="locale" class="col-sm-2 control-label">Locale</label>
      <div class="col-sm-10">
        <select class="form-control form-control-sm" id="locale" formControlName="locale" name="locale">
          <option *ngFor="let locale of locales$ | async" [ngValue]="locale">{{locale}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row control-label">
      <label class="form-check-label col-sm-2" for="isDisabled">
        Disabled
      </label>
      <div class="form-check col-sm-10">
        <input class="form-check-input ml-1"
               type="checkbox"
               id="isDisabled"
               formControlName="isDisabled"
               name="isDisabled">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger float-right ml-2" [disabled]="!messageTemplateEditForm.valid">Save</button>
  </div>
</form>


