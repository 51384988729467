import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductConfig } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'pl-pi';

  constructor(
    private translateService: TranslateService,
    private productCofig: ProductConfig
  ) {
    translateService.addLangs(productCofig.languages);
    translateService.setDefaultLang(productCofig.defaultLanguage);
  }
}
