import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService, UserService } from '@backoffice-monorepo/commons';
import { ProjectData } from '@backoffice-monorepo/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'backoffice-monorepo-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  userName: string;
  isNavbarCollapsed = true;
  projectName = 'Netcard Backoffice';
  constructor(
    private router: Router,
    private userService: UserService,
    private projectData: ProjectData,
    private translateService: TranslateService,
    private themeService: ThemeService
  ) {
  }

  ngOnInit(): void {
    this.projectName = this.projectData.projectName;
    this.userName = this.userService.getUser()?.userName || 'unauthorized';
    this.loadLanguage();
  }

  getLangs(): Array<string> {
    return this.translateService.getLangs();
  }

  loadStyle(styleName: string) {
    this.themeService.loadStyle(styleName)
  }

  loadLanguage(langName: string = localStorage.getItem('user-language') || 'en') {
    localStorage.setItem('user-language', langName);
    this.translateService.use(langName);
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  openClient(clientId) {
    this.router.navigate(['/clients', clientId]);
  };

  openLoan(loanId) {
    this.router.navigate(['/loans', loanId]);
  };

  setBrand(brand: string) {
    sessionStorage.setItem("phoneAppBrand", brand);
    this.router.navigate(['/phone-application/client-new']);
  }
}
