<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
    <tr filter-element title="Id" type="number" property="id"></tr>
    <tr filter-element title="Client Id" type="number" property="clientId"></tr>
    <tr filter-element title="Approved Date Time" type="instant" property="emailVerificationRequestedDateTime"></tr>
  </tbody>
    <table list-table class="table table-striped table-hover fle">
      <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th sortable="id" (sort)="onSort($event)">ID</th>
          <th sortable="clientId" (sort)="onSort($event)">Client</th>
          <th sortable="emailVerificationRequestedDateTime" (sort)="onSort($event)">Email verification requested date time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of response?.results; let i = index; trackBy: trackById">
          <td></td>
          <td><a routerLink="/applications/{{item.id}}">{{item.id}}</a></td>
          <td><a routerLink="/clients/{{item.clientId}}">{{item.clientId}}</a></td>
          <td>{{item.emailVerificationRequestedDateTime}}</td>
        </tr>
      </tbody>
    </table>
</server-table>
