<div class="col-md-4 mt-4">
  <div class="card">
    <div class="card-header text-light bg-primary">
      <bi name="briefcase"></bi>
      Application build information
    </div>
    <div class="card-text row">
      <div class="col-md-12">
        <div class="p-3">
          <pre *ngIf="(buildInformation$ | async) as buildInformation">{{buildInformation | json}}</pre>
        </div>
      </div>
    </div>
  </div>
</div>
