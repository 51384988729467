<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Payment Id" type="number" property="paymentId"></tr>
  <tr filter-element title="Client Id" type="number" property="clientId"></tr>
  <tr filter-element title="Reference Id" type="number" property="referenceId"></tr>
  <tr filter-element
      title="Direction"
      type="enum"
      property="direction"
      enum="direction"
  ></tr>
  <tr filter-element title="System payload" type="string" property="systemPayload"></tr>
  <tr filter-element
      title="Status"
      type="enum"
      property="status"
      enum="status"
  ></tr>
  <tr filter-element title="Amount" type="number" property="amount"></tr>
  <tr filter-element title="Purpose" type="string" property="purpose"></tr>
  <tr filter-element title="From account" type="string" property="fromAccount"></tr>
  <tr filter-element title="To account" type="string" property="toAccount"></tr>
  <tr filter-element title="Provider payload" type="string" property="providerPayload"></tr>
  <tr filter-element
      title="Created"
      type="instant"
      property="created"
  ></tr>
  </tbody>
  <div buttons class="mt-1">
    <ndc-dynamic
      [ndcDynamicComponent]="transferButtonViewComponentType"
      [ndcDynamicInputs]="{data: null}"
    ></ndc-dynamic>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="paymentId" (sort)="onSort($event)">Payment</th>
      <th sortable="clientId" (sort)="onSort($event)">Client</th>
      <th sortable="referenceId" (sort)="onSort($event)">Reference ID</th>
      <th sortable="direction" (sort)="onSort($event)">Direction</th>
      <th>Payloads</th>
      <th sortable="amount" (sort)="onSort($event)">Amount</th>
      <th>From Account</th>
      <th>To Account</th>
      <th>Purpose</th>
      <th sortable="status" (sort)="onSort($event)">Status</th>
      <th>Comment</th>
      <th>Created</th>
      <th>Updated</th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let transfer of response?.results; let i = index; trackBy: trackById">
      <td></td>
      <td>{{transfer.id}}</td>
      <td><a *ngIf="transfer.paymentId" routerLink="/payments/{{transfer.paymentId}}">{{transfer.paymentId}}</a></td>
      <td><a *ngIf="transfer.clientId" routerLink="/clients/{{transfer.clientId}}">{{transfer.clientId}}</a></td>
      <td>{{transfer.referenceId}}</td>
      <td>{{transfer.direction}}</td>
      <td>
        <button type="button"
                class="btn btn-outline-primary btn-sm"
                (click)="viewPayload(transfer.id)"
                title="View payload">
          <bi name="book"></bi>
        </button>
      </td>
      <td>{{transfer.amount}}</td>
      <td>{{transfer.fromAccount}}</td>
      <td>{{transfer.toAccount}}</td>
      <td>{{transfer.purpose}}</td>
      <td>{{transfer.status}}</td>
      <td>{{transfer.comment}}</td>
      <td>{{transfer.created}}</td>
      <td>{{transfer.updated}}</td>
      <td>
        <button type="button"
                title="Retry transfer"
                class="btn btn-success btn-xs btn-sm"
                *ngIf="transfer.direction === paymentDirection.OUTGOING && transfer.status === transferStatus.FAILURE"
                [disabled]="transfer.refreshed"
                (click)="retryTransfer(transfer, i)"
                appRequirePermission="admin.all,transfer.retry">
          <i *ngIf="transfer.refreshed" class="spinner-border spinner-border-sm text-secondary"></i>
          <bi name="arrow-repeat"></bi>
        </button>
      </td>
      <td>
        <button type="button"
                title="Redirect transfer"
                class="btn btn-success btn-xs btn-sm"
                *ngIf="transfer.direction === paymentDirection.INCOMING && transfer.status === transferStatus.SUCCESS"
                (click)="redirectTransfer(transfer.id)"
                appRequirePermission="admin.all,transfer.redirect">
          <bi name="arrow-up-right"></bi>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</server-table>


