import { Component, Input, OnInit } from '@angular/core';
import { PostponeTaskRequest, ProductConfig, Task } from '@twino/backoffice-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
// noinspection ES6PreferShortImport
import { TasksService } from '../../../services/tasks/tasks.service';
// noinspection ES6PreferShortImport
import { TwinoDateAdapterService } from '../../../services/dates/twino-date-adapter.service';
import { DateUtils } from '../../../services/date-utils';

@Component({
  selector: 'postpone-task-modal',
  templateUrl: './postpone-task-modal.component.html',
  styleUrls: ['./postpone-task-modal.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class PostponeTaskModalComponent implements OnInit {

  @Input()
  task: Task;

  postponeTaskForm: FormGroup;
  postponeTaskHours: number[];

  constructor(
    public activeModal: NgbActiveModal,
    private productConfig: ProductConfig,
    private formBuilder: FormBuilder,
    private tasksService: TasksService
  ) {
    this.postponeTaskHours = productConfig.postponeTaskHours;
  }

  ngOnInit(): void {
    this.postponeTaskForm = this.formBuilder.group(
      {
        postponeTaskDate: ['', Validators.required],
        postponeTaskHour: ''
      }
    );
  }

  submitForm() {
    const postponeTaskRequest: PostponeTaskRequest = {
      postponeDate: DateUtils.datePlusHoursWithFormat(this.postponeTaskForm.value.postponeTaskHour, this.postponeTaskForm.value.postponeTaskDate)
    }
    this.tasksService.postpone(this.task.id, postponeTaskRequest).toPromise()
      .then(() => this.activeModal.close(postponeTaskRequest));
  }

}
