import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { FilteringService, ListComponent, SystemConfigService } from '@backoffice-monorepo/commons';
import { SearchQueryAware, SystemConfiguration } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SystemConfigRefreshService } from '../../services/system-config-refresh.service';

@Component({
  selector: 'backoffice-monorepo-system-config-list',
  templateUrl: './system-config-list.component.html',
  styleUrls: ['./system-config-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<SystemConfiguration>) => new FilteringService(listService),
      deps: [SystemConfigService]
    }
  ]
})
export class SystemConfigListComponent extends ListComponent<SystemConfiguration> implements OnInit,OnDestroy {

  private reloadSystemConfigListModel: Subscription;

  constructor(@Host() filteringService: FilteringService<SystemConfiguration>,
              protected router: Router,
              systemConfigurationService: SystemConfigService,
              activatedRoute: ActivatedRoute,
              private systemConfigRefreshService: SystemConfigRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadSystemConfigListModel = this.systemConfigRefreshService.reloadSystemConfigListModel$.subscribe(() => {
      super.ngOnInit();
    })
  }

  ngOnDestroy(): void {
    this.reloadSystemConfigListModel.unsubscribe();
  }

  getName(): string {
    return 'System Configuration';
  }

  navigateTo(id: number) {
    this.router.navigate([`/system-configuration/${id}`]);
  }

  navigateToCreate() {
    this.router.navigate([`/system-configuration/create`]);
  }
}
