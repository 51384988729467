import { Component, Host, OnInit } from '@angular/core';
import { ConfirmDialogueComponent, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import { LoanSaleFileSignedUrlResponse, LoanSalePortfolio, LoanSalePortfolioStatus, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';
import { LoanSalePortfolioService } from '../../../../../../../commons/src/lib/services/loan-sale-portfolio.service';
import { LoanSellPackageComponent } from '../loan-sell-package/loan-sell-package.component';

@Component({
  selector: 'backoffice-monorepo-loan-sale-data-list',
  templateUrl: './loan-sale-data-list.component.html',
  styleUrls: ['./loan-sale-data-list.component.css'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<LoanSalePortfolio>) => new FilteringService(listService),
      deps: [LoanSalePortfolioService]
    }
  ]
})
export class LoanSaleDataListComponent extends ListComponent<LoanSalePortfolio> implements OnInit {

  loanSalePortfolioStatus = LoanSalePortfolioStatus;
  loanSaleFileSignedUrlResponse: LoanSaleFileSignedUrlResponse;
  showPassword = false;
  constructor(
    @Host() filteringService: FilteringService<LoanSalePortfolio>,
    protected router: Router,
    private loanSalePortfolioService: LoanSalePortfolioService,
    activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  navigateTo(id: string | number) {
    this.router.navigate([`/loan-sale/${id}`]);
  }

  getName() {
    return 'Loan sale';
  }

  preSellReportUrl(portfolioId: number): string {
    return  `/ws/api/loan-sale/portfolio/${portfolioId}/pre-sell-report/`;
  }

  downloadPortfolioDocumentsPackage(portfolioId: number) {
    return  `/ws/api/loan-sale/portfolio/${portfolioId}/portfolio-documents/`;
  }

  sellPortfolio(portfolio: LoanSalePortfolio) {
    const modalRef = this.modalService.open(LoanSellPackageComponent);
    modalRef.componentInstance.portfolio = portfolio;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.refresh();
        }
      }
    );
  }

  revertPortfolioSelling(portfolio: LoanSalePortfolio) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Revert portfolio";
    modalRef.componentInstance.content = "Are you sure you want to revert selling of this portfolio?";
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.loanSalePortfolioService.revertPortfolioSelling({portfolioId: portfolio.id})
            .subscribe(() =>  this.refresh())
        }
      }
    );
  }


  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  getLoanSaleFileSignedUrl(id: number) {
    this.showPassword = false;
    this.loanSalePortfolioService.getLoanSaleFileSignedUrl(id).pipe(
      tap(res => this.loanSaleFileSignedUrlResponse = res)
    ).subscribe();
  }


}
