<table class="table table-striped table-hover table-sm mb-0">
  <colgroup>
    <col class="table-primary"/>
    <col/>
  </colgroup>
  <tbody>
  <tr *ngFor="let item of transformed">
    <td class="text-right w-50">{{item.fieldName}}</td>
    <td *ngIf="item.type === 'string'">
      <backoffice-monorepo-limited-field [value]="item.value | async" maxLength="40"></backoffice-monorepo-limited-field>
    </td>
    <td *ngIf="item.type === 'html'" [innerHTML]="item.value | async"></td>
  </tr>
  </tbody>
</table>
