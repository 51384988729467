import { Component, OnInit } from '@angular/core';
import { AuthorityService, ConfirmDialogueComponent, FilteringService, NamedComponent } from '@backoffice-monorepo/commons';
import { SearchQueryAware, UserAuthority } from '@twino/backoffice-api';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorityAddComponent } from '../authority-add/authority-add.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-authority-list',
  templateUrl: './authorities-list.component.html',
  styleUrls: ['./authorities-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<UserAuthority>) => new FilteringService(listService),
      deps: [AuthorityService]
    }
  ]
})
export class AuthoritiesListComponent extends NamedComponent implements OnInit {
  authorities: Observable<UserAuthority[]>;

  constructor(
    activatedRoute: ActivatedRoute,
    private authorityService: AuthorityService,
    private modalService: NgbModal
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.getAuthorities();
  }

  getName(): string {
    return 'Authorities';
  }

  refresh() {
    this.getAuthorities();
  }

  getAuthorities() {
    this.authorities = this.authorityService.getAuthorities();
  }

  trackById(index: number, authority: UserAuthority) {
    return authority.id;
  }

  onCreateClick() {
    const modalRef = this.modalService.open(AuthorityAddComponent);
    modalRef.result.then(result => {
      if (result) {
        this.authorityService.createAuthority(result).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.refresh();
        });
      }
    })
  }

  onDeleteClick(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);

    modalRef.componentInstance.header = 'Delete authorities';
    modalRef.componentInstance.content = 'Are You Sure you want to delete authorities?';

    modalRef.result.then(result => {
      if (result) {
        this.authorityService.deleteAuthority(id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.refresh();
        });
      }
    });
  }
}
