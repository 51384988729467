<form [formGroup]="sellForm" (ngSubmit)="submitForm()">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Sell portfolio</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="partnerId" class="col-sm-4 control-label">Partner<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select class="form-control form-control-sm" id="partnerId" formControlName="partnerId" name="partnerId">
          <option *ngFor="let item of partners" [ngValue]="item.id">{{item.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="saleAmount" class="col-sm-4 control-label">Sale amount<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="saleAmount" formControlName="saleAmount" name="saleAmount">
      </div>
    </div>
    <div class="form-group row">
      <label for="onDate" class="col-sm-4 control-label">Date<sup class="text-danger">*</sup></label>
      <div class="col-sm-8 input-group">
        <input class="form-control form-control-sm"
               id="onDate"
               formControlName="onDate"
               placeholder="yyyy-mm-dd"
               name="onDate"
               ngbDatepicker
               #onDate="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="onDate.toggle()" type="button"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!sellForm.valid">Save</button>
  </div>
</form>
