<div class="container-fluid p-3">
  <div class="btn-toolbar mb-3" role="toolbar" aria-label="Task dashboard buttons">
    <div class="btn-group btn-group-sm mr-2" role="group" aria-label="First group">
      <server-refresh
        (refresh)="refresh()"
      ></server-refresh>
    </div>
    <div class="input-group input-group-sm">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" [(ngModel)]="hideFutureTasks" aria-label="Hide Future Tasks"/>
        <label class="form-check-label">Hide Future Tasks</label>
      </div>
    </div>
  </div>
</div>

<div class="container col-md-12">
  <div class="row">
<div class="col-md-2">
  <ngb-accordion activeIds="mailbox-{{activePanel}}">
    <ngb-panel *ngFor="let mailbox of mailboxes; let i = index;" id="mailbox-{{i}}">
      <ng-template ngbPanelHeader >
        <div class="d-flex align-items-center justify-content-between" (click)="setMailboxTopic(mailbox.topic, i)">
          <button ngbPanelToggle class="btn btn-link p-0">{{mailbox.topic}}</button>
          <span class="badge badge-secondary float-right">{{mailbox.toProcessCount}}</span>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <backoffice-monorepo-tasks-topic-mailbox [topic]="mailbox.topic"></backoffice-monorepo-tasks-topic-mailbox>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <ngb-accordion (panelChange)="beforeChange($event)">
    <ngb-panel *ngFor="let topic of topics; let i = index;">
      <ng-template ngbPanelHeader >
        <div class="d-flex align-items-center justify-content-between" (click)="openTasksForTopic(topic.topic)">
          <button ngbPanelToggle class="btn btn-link p-0">{{topic.topic}}</button>
          <span class="badge badge-secondary float-right">{{topic.toProcessCount}}</span>
        </div>
      </ng-template>
      <ng-template ngbPanelContent></ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <span class="float-left" (click)="openTasksForTopic(allTopic)">All</span>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  </div>
  <div class="container-fluid pt-1 pb-2 col-md-10">

      <!-- region buttons -->
      <div class="col-md-12"></div>
      <!-- endregion -->

      <!-- region tasks -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-header text-light bg-primary">
            <bi name="pencil-square"></bi>
            {{activeTopic}}: {{response?.totalRecords || '0'}}
          </div>
          <div class="card-text">
            <server-table
              [totalRecords]="response?.totalRecords"
              [totalPages]="response?.totalPages"
              [currentPage]="response?.currentPage"
              [pageSize]="searchQuery?.pageSize"
              [searchQuery]="searchQuery"
              [withFilterBlock]="false"
              (setPage)="refresh($event)"
            >
              <table list-table class="table table-sm"
                     [class.table-striped]="isNoOpenTasks()"
                     [class.table-hover]="isNoOpenTasks()"
              >
                <thead class="text-light bg-primary">
                <tr>
                  <th></th>
                  <th sortable="id" (sort)="onSort($event)">Id</th>
                  <th>Topic</th>
                  <th sortable="brand" (sort)="onSort($event)">Brand</th>
                  <th>Task group</th>
                  <th>Assignee</th>
                  <th>Assigned Time</th>
                  <th sortable="nextProcessingTime" (sort)="onSort($event)">Next Process Time</th>
                  <th>Age</th>
                  <th>Postpone times</th>
                  <th sortable="created" (sort)="onSort($event)">Created Time</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let task of (response?.results || [])">
                  <tr
                    [class.text-muted]="isNotAnOpenTaskWhenThereIsAnOpenOne(task)"
                    [class.table-active]="isNotAnOpenTaskWhenThereIsAnOpenOne(task)"
                  >
                    <td class="p2">
                      <button class="btn btn-sm btn-info"
                              appRequirePermission="admin.all,task.read"
                              [class.btn-warning]="isOthersTask(task)"
                              [hidden]="isMyTask(task)"
                              (click)="acceptTask(task)"
                      >
                        Accept task
                      </button>
                      <button class="btn btn-sm btn-info"
                              appRequirePermission="admin.all,task.read"
                              [hidden]="shouldHideOpenTaskButton(task)"
                              (click)="openTask(task)"
                      >
                        Open task
                      </button>
                    </td>
                    <td>{{task.id}}</td>
                    <td>{{task.topic}}</td>
                    <td>{{task.brand}}</td>
                    <td>{{task.taskGroupName}}</td>
                    <td>
                      <bi
                        [class.text-danger]="isOthersTask(task)"
                        [class.text-info]="isMyTask(task)"
                        [hidden]="isGroupTask(task)"
                        name="person-fill"
                      ></bi>
                      {{task.assignedUser}}
                    </td>
                    <td>{{task.assignedTime}}</td>
                    <td>{{task.nextProcessTime}}</td>
                    <td>{{task.nextProcessTime | amTimeAgo:true}}</td>
                    <td>{{task.attempts}}</td>
                    <td>{{task.created}}</td>
                  </tr>
                  <tr *ngIf="isOpenTask(task)">
                    <td colspan="10">
                      <view-dashboard-task [task]="task" (release)="releaseTask($event)"></view-dashboard-task>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </server-table>
          </div>
        </div>
        <!--endregion -->

      </div>
    </div>
  </div>
</div>


