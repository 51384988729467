import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { AffiliateService, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import { AffiliateConfiguration, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { AffiliatesRefreshService } from '../../services/affiliates-refresh.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-affiliate-configs-list',
  templateUrl: './affiliate-configs-list.component.html',
  styleUrls: ['./affiliate-configs-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<AffiliateConfiguration>) => new FilteringService(listService),
      deps: [AffiliateService]
    }
  ]
})
export class AffiliateConfigsListComponent extends ListComponent<AffiliateConfiguration> implements OnInit, OnDestroy {

  private reloadAffiliateConfigsModel: Subscription;

  constructor(
    @Host() filteringService: FilteringService<AffiliateConfiguration>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
    private affiliateConfigService: AffiliateService,
    private affiliatesRefreshService: AffiliatesRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadAffiliateConfigsModel = this.affiliatesRefreshService.reloadAffiliateConfigsModel$.subscribe(
      () =>  super.ngOnInit())
  }

  ngOnDestroy(): void {
    this.reloadAffiliateConfigsModel.unsubscribe();
  }

  getName(): string {
    return 'Affiliate configurations';
  }

  navigateTo(id: number) {
    this.router.navigate([`/affiliates/${id}`]);
  }

}
