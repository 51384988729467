import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-view-json-data',
  templateUrl: './view-json-data.component.html',
  styleUrls: ['./view-json-data.component.css']
})
export class ViewJsonDataComponent  {
  @Input() data: never;
  @Input() title: string;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

}
