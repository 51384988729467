export class ActivityOverview {
  clientId: number
  type: string
  entityId: number
  content: string
  created: Date
  loanId?: number
  topic?: string
  status?: string
  direction?: string
  assignedUser?: string
  resolution?: string
  amount?: number
  subject?: string
  promiseDate?: string
}

export enum ActivityOverviewCommunicationType {
  EMAIL = 'COMMUNICATION.EMAIL',
  LETTER = 'COMMUNICATION.LETTER'
}

export enum ActivityOverviewType {
  COMMENT = 'COMMENT',
  COMMUNICATION = 'COMMUNICATION',
  TASK = 'TASK',
  PAYMENT = 'PAYMENT',
  DISCOUNT = 'DISCOUNT'
}
