import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { FilteringService, ListComponent, FileTemplateService } from '@backoffice-monorepo/commons';
import { FileTemplate, SearchQueryAware } from '@twino/backoffice-api';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FileTemplatesRefreshService } from '../../services/file-templates-refresh.service';

@Component({
  selector: 'backoffice-monorepo-file-templates-list',
  templateUrl: './file-templates-list.component.html',
  styleUrls: ['./file-templates-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<FileTemplate>) => new FilteringService(listService),
      deps: [FileTemplateService]
    }
  ]
})
export class FileTemplatesListComponent extends ListComponent<FileTemplate> implements OnInit,OnDestroy {

  private reloadFileTemplatesListModel: Subscription;

  constructor(@Host() filteringService: FilteringService<FileTemplate>,
              protected router: Router,
              activatedRoute: ActivatedRoute,
              fileTemplatesService: FileTemplateService,
              private fileTemplatesRefreshService: FileTemplatesRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadFileTemplatesListModel = this.fileTemplatesRefreshService.reloadFileTemplatesListModel$.subscribe(() => {
      super.ngOnInit();
    })
  }

  ngOnDestroy(): void {
    this.reloadFileTemplatesListModel.unsubscribe();
  }

  getName(): string {
    return 'File templates';
  }

  navigateTo(id: number) {
    this.router.navigate([`/file-templates/${id}`]);
  }

  navigateToCreate() {
    this.router.navigate([`/file-templates/create`]);
  }

}
