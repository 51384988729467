<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
    <tr filter-element title="Job execution Id" type="number" property="id"></tr>
    <tr filter-element enum="status" title="Status" type="enum" property="status"></tr>
    <tr filter-element title="Job title" type="string" property="jobInstance.jobName"></tr>
  </tbody>

  <div buttons class="m-1">
    <button class="btn btn-sm btn-primary" (click)="runJob()">
      <bi name="caret-right"></bi>Run
    </button>
  </div>

  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="title" (sort)="onSort($event)">Title</th>
      <th sortable="jobInstanceId" (sort)="onSort($event)">Job instance Id</th>
      <th sortable="jobExecutionId" (sort)="onSort($event)">Job execution Id</th>
      <th sortable="startTime" (sort)="onSort($event)">Start time</th>
      <th sortable="endTime" (sort)="onSort($event)">End time</th>
      <th sortable="status" (sort)="onSort($event)">Status</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let batchJob of response?.results; trackBy: trackById" (dblclick)="navigateTo(batchJob.jobExecutionId)">
      <td></td>
      <td>{{batchJob.title}}</td>
      <td>{{batchJob.jobInstanceId}}</td>
      <td>{{batchJob.jobExecutionId}}</td>
      <td>{{batchJob.startTime}}</td>
      <td>{{batchJob.endTime}}</td>
      <td>{{batchJob.status}}</td>
    </tr>
    </tbody>
  </table>
</server-table>
