import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoansListComponent } from './components/loans-list/loans-list.component';
import { LoanComponent } from './components/loan/loan.component';


const routes: Routes = [
  {path: ':id', component: LoanComponent},
  {path: '', component: LoansListComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoansRoutingModule {
}
