import { AfterContentInit, Directive, OnDestroy } from '@angular/core';
// noinspection ES6PreferShortImport
import { ViewHistoryService } from '../services/view-history.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

/**
 * @description
 * For the loans that are stored in cache in view history nav (navigation) we need this to provide componentName for respective tab
 * to do so, just extend `getName()` method and provide the name
 * also it is needed to implement `AfterContentInit` in your component just override `afterInit()` method instead
 *
 * @see `ViewHistoryService`
 *
 * @publicApi
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class NamedComponent implements AfterContentInit, OnDestroy {

  $destroy = new Subject<boolean>();

  protected constructor(protected activatedRoute: ActivatedRoute) {
  }

  abstract getName(): string

  protected afterInit() {
  }

  ngAfterContentInit(): void {
    this.afterInit();
    const routeDefinition = ViewHistoryService.buildRouteDefinition(this.activatedRoute.snapshot);
    this.activatedRoute.snapshot.data[routeDefinition].next(this.getName());
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
