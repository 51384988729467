import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FraudType } from '../../modules/debt-collection-stages/api/debt-stage';
import { PlPiDebtCollectionStagesService } from '../../modules/debt-collection-stages/services/pl-pi-debt-collection-stages.service';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-mark-fraud',
  templateUrl: './pl-pi-loan-mark-fraud.component.html',
  styleUrls: ['./pl-pi-loan-mark-fraud.component.css'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class PlPiLoanMarkFraudComponent extends BasicComponent implements OnInit {

  @Input()
  loanId: number;
  markAsFraudForm: FormGroup;
  fraudType = FraudType;
  fraudTypeOptions = [];

  constructor(
    private debtCollectionStagesService: PlPiDebtCollectionStagesService,
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fraudTypeOptions = Object.keys(this.fraudType);
    this.markAsFraudForm = this.formBuilder.group({
      fraudType: ['', Validators.required],
      onDate: ['', Validators.required],
    });
  }

  submitForm() {
    this.debtCollectionStagesService.markLoanAsFraud(this.loanId, this.markAsFraudForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.activeModal.close();
      this.alertService.notifySuccess(`Loan is marked as fraud!`);
    })
  }
}
