import { HttpClient } from '@angular/common/http';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlPiPaymentService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  createReturnPayment(id: number): Observable<unknown> {
    return this.post(`/api/pl/payments/${id}/return`, {});
  }

}
