<span class="mr-2" *ngIf="data?.canContinueCurrentApplicationFlow">
  <button appRequirePermission="admin.all,client.all,client.edit" class="btn btn-success btn-sm" (click)="onContinuePhoneApplicationClick()">
    <bi name="plus-circle"></bi>
    Continue Phone Application
  </button>
</span>

<span class="mr-2 row w-25 float-right" *ngIf="data?.canStartNewApplicationFlow">
  <select (change)="setBrand($event)">
      <option>Choose the brand</option>
      <option *ngFor="let key of productBrandsList$ | async" [ngValue]="key">{{key}}</option>
  </select>
  <button appRequirePermission="admin.all,client.all,client.edit" class="btn btn-success btn-sm ml-1"
          [disabled]="brand === null"
          (click)="onCreateNewLoanApplicationClick()">
    <bi name="plus-circle"></bi>
    Create New Loan Application
  </button>
</span>

