import { Component, Inject, OnDestroy, OnInit, Optional, Type } from '@angular/core';
import {
  AcceptOfferButtonComponentResolver,
  AlertsService,
  ApplicationService,
  CLIENT,
  COMMISSIONS,
  CountryViewComponent,
  DATE,
  ID,
  LoanApplicationButtonsViewComponentResolver,
  NamedComponent,
  NUMBER,
  PERCENT,
  STRING,
  TransformInstructions,
  TransformInstructionsBuilder,
  AMOUNT,
  ClientActivitiesService
} from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { Application, EvaluationResult, Offer } from '@twino/backoffice-api';
import { take, takeUntil } from 'rxjs/operators';
import { ApplicationRefreshService } from '../../services/application-refresh.service';

@Component({
  selector: 'backoffice-monorepo-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent extends NamedComponent implements OnInit, OnDestroy {
  id: number;
  application: Application | null;
  offer: Offer;
  grantedOfferId: number | null;
  evaluationResults: EvaluationResult[] | null;
  isOpenResolutions: boolean;
  isOpenResolutionsTree: boolean;
  disableReevaluationButton: boolean;
  showReevaluationButton: boolean;
  showOfferAcceptButton: boolean;
  acceptOfferButtonComponentType: Type<CountryViewComponent>;
  loanApplicationButtonsViewComponentType: Type<CountryViewComponent>;
  ipAddress: string | null;

  private reloadOfferModel: Subscription;
  private reloadApplicationModel: Subscription;

  loanApplicationTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Application ID', ID]],
      ['clientId', ['Client', CLIENT]],
      ['askedTerm', ['Asked term', () => `${this.application.askedTerm.loanLength?.value} ${this.application.askedTerm.loanLength?.unit}`.asString()]],
      ['askedAmount', ['Asked amount', AMOUNT]],
      ['receiveChannel', ['Receive Channel', STRING]],
      ['status', ['Status', STRING]],
      ['flowState', ['Flow State', STRING]],
      ['flowStateStartDateTime', ['Flow State Start Date', DATE]],
      ['createDate', ['Create Date', DATE]],
      ['modifiedDate', ['Modified Date', DATE]],
      ['productType', ['Product Type', STRING]],
      ['productSubType', ['Product Subtype', STRING]],
      ['type', ['Type', STRING]],
      ['resolution', ['Resolution', STRING]],
      ['rejectReason', ['Reason', STRING]],
      ['flowStateDetails', ['Flow State Details', STRING]],
      ['creditScore', ['Credit Score', NUMBER]],
      ['brand', ['Brand', STRING]],
    ]
  );

  offerTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['commissionRateParameters', ['Commission rates', COMMISSIONS]],
      ['interestRatePerYear', ['Interest rate per year', PERCENT]],
      ['monthlyPayment', ['Monthly payment', AMOUNT]],
      ['principal', ['Principal', AMOUNT]],
      ['productType', ['Product type', STRING]],
      ['startDate', ['Start date', STRING]],
      ['dueDate', ['Due date', STRING]],
      ['totalAmount', ['Total amount', AMOUNT]],
      ['totalInterestAmount', ['Total interest amount', AMOUNT]]
    ]
  );

  constructor(
    activatedRoute: ActivatedRoute,
    private applicationService: ApplicationService,
    private alertService: AlertsService,
    private applicationRefreshService: ApplicationRefreshService,
    private clientActivities: ClientActivitiesService,
    @Optional() @Inject('LoanApplicationButtonsViewComponentResolver') public loanApplicationButtonsViewComponentResolver: LoanApplicationButtonsViewComponentResolver,
    @Optional() @Inject('AcceptOfferButtonComponentResolver') public acceptOfferButtonComponentResolver: AcceptOfferButtonComponentResolver,
  ) {
    super(activatedRoute);
    if (acceptOfferButtonComponentResolver) {
      this.acceptOfferButtonComponentType = acceptOfferButtonComponentResolver.resolve();
    }

    if (loanApplicationButtonsViewComponentResolver) {
      this.loanApplicationButtonsViewComponentType = loanApplicationButtonsViewComponentResolver.resolve();
    }
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
    this.reloadOfferModel = this.applicationRefreshService.reloadOfferModel$.subscribe(offerPreview => {
      this.offer = offerPreview;
    })
    this.reloadApplicationModel = this.applicationRefreshService.reloadApplicationModel$.subscribe(() => {
      setTimeout(() => {
        this.refresh();
      }, 300);
    })
  }

  ngOnDestroy() {
    this.reloadOfferModel.unsubscribe();
    this.reloadApplicationModel.unsubscribe();
  }

  getName(): string {
    return `Loan application ${this.id || ''}`;
  }

  refresh() {
    forkJoin({
      application: this.applicationService.getApplication(this.id),
      offers: this.applicationService.getApplicationGrantedOffers(this.id),
      evaluationResults: this.applicationService.getApplicationEvaluationResults(this.id)
    }).pipe(
      takeUntil(this.$destroy)
    ).subscribe(({application, offers, evaluationResults}) => {
      this.application = application;
      this.offer = offers[0];
      if(this.offer?.id) {
        this.grantedOfferId = this.offer.id;
      }
      this.evaluationResults = evaluationResults;
      this.showReevaluationButton = this.showReevaluation();
      this.showOfferAcceptButton = this.showOfferAccept();
      this.getIpAddresses();
    });
  }

  openResolutions() {
    this.isOpenResolutionsTree = false;
    this.isOpenResolutions = true;
  }

  openResolutionTree() {
    this.isOpenResolutionsTree = true;
    this.isOpenResolutions = false;
  }


  showReevaluation() {
    return this.application.flowState === 'EVALUATION_ERROR' || this.application.flowState === 'OFFER_GRANTED';
  }

  showOfferAccept(): boolean {
    return this.isOfferGrantedFlowState(this.application.flowState);
  }

  reEvaluateApplication() {
    this.applicationService.reEvaluateApplication(this.id).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess('Application reevaluated');
      this.refresh();
    });
  }

  private isOfferGrantedFlowState(flowState: string): boolean {
    return flowState === 'OFFER_GRANTED';
  }

  getIpAddresses() {
    this.clientActivities.getClientApplicationsIpAddresses(this.application.clientId).pipe(
      take(1)
    ).subscribe(ipAddresses => {
       const ipData = ipAddresses.find(ipAddress => ipAddress.applicationId === this.application.id);
       this.ipAddress = ipData?.ipAddress;
    })
  }
}
