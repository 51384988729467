import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentDialogueComponent } from '../dialogues/content-dialogue/content-dialogue.component';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'backoffice-monorepo-limited-field',
  templateUrl: './limited-field.component.html',
  styleUrls: ['./limited-field.component.scss']
})
export class LimitedFieldComponent {
  @Input() value: string | SafeHtml | null;
  @Input() maxLength: number;

  constructor(
    private modalService: NgbModal
  ) {}

  onClick() {
    const modalRef = this.modalService.open(ContentDialogueComponent, {size: 'xl', scrollable: true});
    modalRef.componentInstance.content = this.value;
  }
}
