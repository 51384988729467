import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPollService } from './services/login-poll.service';
import { HttpClientModule } from '@angular/common/http';
import { RootComponent } from './components/root/root.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { BootstrapIconsModule } from 'ng-bootstrap-icons';
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ArrowRepeat,
  ArrowUpRight,
  ArrowUp,
  Book,
  Briefcase,
  Brush,
  CardText,
  CaretRight,
  ChatSquareText,
  ChatText,
  Check,
  Clock,
  Download,
  Envelope,
  ExclamationOctagonFill,
  ExclamationTriangle,
  FunnelFill,
  JustifyLeft,
  InfoCircle,
  PencilSquare,
  PeopleFill,
  PersonFill,
  PlusCircle,
  Telephone,
  Upload,
  X,
  XCircle,
  XSquare,
  QuestionCircle
} from 'ng-bootstrap-icons/icons';
import { LogoutComponent } from './components/logout/logout.component';
import { RequirePermissionDirective } from './directives/require-permission.directive';
import { LoanLookupComponent } from './components/search/loan-lookup/loan-lookup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientLookupComponent } from './components/search/client-lookup/client-lookup.component';
import { ViewHistoryComponent } from './components/view-history/view-history.component';
import { RouterModule } from '@angular/router';
import { ServerTableComponent } from './components/search/server-table/server-table.component';
import { ThSortableDirective } from './directives/th-sortable.directive';
import { PaginationComponent } from './components/search/pagination/pagination.component';
import { ServerRefreshComponent } from './components/search/server-refresh/server-refresh.component';
import { SearchFilterComponent } from './components/search/search-filter/search-filter.component';
import { FilterElementComponent } from './components/search/filter-element/filter-element.component';
import { ErrorDialogueComponent } from './components/dialogues/error-dialogue/error-dialogue.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { ConfirmDialogueComponent } from './components/dialogues/confirm-dialogue/confirm-dialogue.component';
import { TransformTableComponent } from './components/tranform-table/transform-table.component';
import { TasksService } from './services/tasks/tasks.service';
import { CloseTaskModalComponent } from './components/tasks/close-task-modal/close-task-modal.component';
import { PostponeTaskModalComponent } from './components/tasks/postpone-task-modal/postpone-task-modal.component';
import { ToastsContainerComponent } from './components/toasts-container/toasts-container.component';
import { DefaultTaskViewComponent } from './components/tasks/default-task-view/default-task-view.component';
import { TaskViewDirective } from './directives/task-view.directive';
import { DebtTaskViewComponent } from './components/tasks/debt-task-view/debt-task-view.component';
import { SafePipe } from './services/safe.pipe';
import { JsonParsePipe } from './services/json.pipe';
import { CountryLoanButtonViewDirective } from './directives/country-loan-button-view.directive';
import { LimitedFieldComponent } from './components/limited-field/limited-field.component';
import { ContentDialogueComponent } from './components/dialogues/content-dialogue/content-dialogue.component';
import { RatioToPercentPipe } from './pipes/ratio-to-percent.pipe';
import { AddPercentSymbolPipe } from './pipes/add-percent-symbol.pipe';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { InputWithSelectComponent } from './components/input-with-select/input-with-select.component';
import { MailboxTaskViewComponent } from './components/tasks/mailbox-task-view/mailbox-task-view.component';
import { ViewJsonDataComponent } from './components/view-json-data/view-json-data.component';
import { DynamicModule } from 'ng-dynamic-component';
import { LabelTransformPipe } from './pipes/label-transform.pipe';
import { SearchExportComponent } from './components/search/search-export/search-export.component';

const icons = {
  ArrowClockwise,
  ArrowCounterclockwise,
  ArrowRepeat,
  ArrowUpRight,
  ArrowUp,
  Book,
  Briefcase,
  Brush,
  CardText,
  CaretRight,
  ChatSquareText,
  ChatText,
  Check,
  Clock,
  Download,
  Envelope,
  ExclamationOctagonFill,
  ExclamationTriangle,
  FunnelFill,
  JustifyLeft,
  InfoCircle,
  PencilSquare,
  PeopleFill,
  PersonFill,
  PlusCircle,
  Telephone,
  Upload,
  X,
  XCircle,
  XSquare,
  QuestionCircle
};

const bootstrapIconsModule: ModuleWithProviders<BootstrapIconsModule> = BootstrapIconsModule.pick(icons);

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    bootstrapIconsModule,
    RouterModule,
    ReactiveFormsModule,
    DynamicModule
  ],
  providers: [
    LoginPollService,
    TasksService
  ],
  exports: [
    RootComponent,
    BootstrapIconsModule,
    RequirePermissionDirective,
    TaskViewDirective,
    ThSortableDirective,
    ServerTableComponent,
    LoginComponent,
    HomeComponent,
    LogoutComponent,
    LoanLookupComponent,
    ClientLookupComponent,
    ViewHistoryComponent,
    FilterElementComponent,
    ConfirmDialogueComponent,
    ServerRefreshComponent,
    SideBarComponent,
    TransformTableComponent,
    SafePipe,
    JsonParsePipe,
    CountryLoanButtonViewDirective,
    LimitedFieldComponent,
    RatioToPercentPipe,
    AddPercentSymbolPipe,
    LoadingButtonComponent,
    InputWithSelectComponent,
    MailboxTaskViewComponent,
    ViewJsonDataComponent,
    DateTimePickerComponent,
    LabelTransformPipe,
    PaginationComponent
  ],
  declarations: [
    RootComponent,
    LoginComponent,
    HomeComponent,
    LogoutComponent,
    RequirePermissionDirective,
    TaskViewDirective,
    ThSortableDirective,
    ServerTableComponent,
    LoanLookupComponent,
    ClientLookupComponent,
    ViewHistoryComponent,
    ThSortableDirective,
    PaginationComponent,
    ServerRefreshComponent,
    SearchFilterComponent,
    FilterElementComponent,
    ErrorDialogueComponent,
    ConfirmDialogueComponent,
    SideBarComponent,
    TransformTableComponent,
    CloseTaskModalComponent,
    PostponeTaskModalComponent,
    ToastsContainerComponent,
    DefaultTaskViewComponent,
    DebtTaskViewComponent,
    SafePipe,
    JsonParsePipe,
    CountryLoanButtonViewDirective,
    LimitedFieldComponent,
    ContentDialogueComponent,
    RatioToPercentPipe,
    AddPercentSymbolPipe,
    LoadingButtonComponent,
    DateTimePickerComponent,
    InputWithSelectComponent,
    MailboxTaskViewComponent,
    ViewJsonDataComponent,
    LabelTransformPipe,
    SearchExportComponent,
  ]
})
export class CommonsModule {
}
