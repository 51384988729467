<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Template Key" type="string" property="templateKey"></tr>
  <tr filter-element title="Enabled" type="boolean" property="enabled"></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all, notifications.edit"
                  class="btn btn-success btn-sm"
                  (click)="navigateTo(0)">
            <bi name="plus-circle"></bi>
            Create
          </button>
    </span>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">Id</th>
      <th sortable="templateKey">Template Key</th>
      <th sortable="eventClass" (sort)="onSort($event)">Event Class</th>
      <th sortable="brand" (sort)="onSort($event)">Brand</th>
      <th sortable="productSubType" (sort)="onSort($event)">Product sub type</th>
      <th>Enabled</th>
      <th>Send In Communication Time Window</th>
      <th>Send Once Per Day</th>
      <th>Should Store Externally</th>
      <th>Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let notificationConfig of response?.results; trackBy: trackById" (dblclick)="navigateTo(notificationConfig.id)">
      <td></td>
      <td>{{notificationConfig.id}}</td>
      <td>{{notificationConfig.templateKey}}</td>
      <td>{{notificationConfig.eventClass}}</td>
      <td>{{notificationConfig.brand}}</td>
      <td>{{notificationConfig.productSubType}}</td>
      <td>{{notificationConfig.enabled}}</td>
      <td>{{notificationConfig.isSendInCommunicationTimeWindow}}</td>
      <td>{{notificationConfig.isSendOncePerDay}}</td>
      <td>{{notificationConfig.shouldStoreExternally}}</td>
      <td>{{notificationConfig.created}}</td>
    </tr>
    </tbody>
  </table>
</server-table>



