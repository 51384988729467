export class IdentityDocument{
  id: number
  clientId: number
  number: string
  type: string
  active: boolean
  issueDate?: Date
  expiryDate?: Date
  isWithoutExpiryDate: boolean
  authority?: string
  authorityCode?: string
  authorityCountry?: string
}

export class IdentityDocumentRequest {
  number: string
  type?: string
  active: boolean
  issueDate?: Date
  expiryDate?: Date
  isWithoutExpiryDate: boolean
  authority?: string
  authorityCode?: string
  authorityCountry?: string
}
