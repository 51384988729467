import { Component, Input, OnInit } from '@angular/core';
import { Transfer } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferService } from '@backoffice-monorepo/commons';
import { take } from 'rxjs/operators';

@Component({
  selector: 'transfer-payload-view',
  templateUrl: './transfer-payload-view.component.html',
  styleUrls: ['./transfer-payload-view.component.scss']
})
export class TransferPayloadViewComponent implements OnInit {
  @Input() transferId: number;

  transfer: Transfer | never;
  systemPayload: string | never;
  providerPayload: string | never;
  isSystemPayloadJsonString: boolean;
  isProviderPayloadString: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private transferService: TransferService
  ) { }

  ngOnInit(): void {
    this.transferService.getTransfer(this.transferId).pipe(
      take(1)
    ).subscribe((transfer) => {
      this.transfer = transfer;
      this.isSystemPayloadJsonString = this.IsJsonString(transfer.systemPayload);
      this.isProviderPayloadString = this.IsJsonString(transfer.providerPayload);
      this.systemPayload = (this.isSystemPayloadJsonString) ? JSON.parse(transfer.systemPayload) : transfer.systemPayload;
      this.providerPayload = (this.isProviderPayloadString) ? JSON.parse(transfer.providerPayload) : transfer.providerPayload;
    })
  }

  IsJsonString(payload: string): boolean {
    try {
      JSON.parse(payload);
    } catch (e) {
      return false;
    }
    return true;
  }

}
