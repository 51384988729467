import { Injectable } from '@angular/core';
import { Task } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export abstract class MailboxActionsService {  //TODO move to core
  abstract openChangeTopicModal(task: Task): Promise<unknown>;
  abstract openChangeClientModal(task: Task): Promise<unknown>;
}
