import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from './toast.service';
import { FieldError } from '@twino/backoffice-api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService implements OnDestroy {

  @Output() errorDialogueAnnouncement = new EventEmitter<HttpErrorResponse | string>();

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  reportError(message: string) {
    this.toastService.showError(message);
  }

  reportHttpError(error: HttpErrorResponse, autoClearError: boolean = false, skipErrorDialogueForHttpStatuses: number[]) {
    if (!error || Object.keys(error).length === 0) {
      return;
    }
    let name;
    switch (error.status) {
      case 404:
        name = "notFound";
        break;
      case 401:
      case 403:
        name = "forbidden";
        break;
      case 400:
      case 500:
      default:
        name = "requestFailed";
        break;
    }
    if (error.status > 0 && skipErrorDialogueForHttpStatuses.indexOf(error.status) < 0) {
      this.showErrorDialogue(new HttpErrorResponse(JSON.parse(JSON.stringify(error))));
    }

    if (!autoClearError) {
      this.reportError(`${name}: ${error.message}`);
    }
  }

  showErrorDialogue(errorContent: HttpErrorResponse | string) {
    this.errorDialogueAnnouncement.emit(errorContent);
  }

  showClientErrorMessages({error}) {
    this.toastService.removeAll();

    error.fieldErrors.forEach((fieldError: FieldError) => {
      const translatedError = this.translateService.instant(`validation.client.${fieldError.error}`);
      this.reportError(translatedError);
    });
  }

  ngOnDestroy() {
    this.errorDialogueAnnouncement.unsubscribe();
  }
}
