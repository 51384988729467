import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import {
  Gender, UpdateClientEmailRequest,
  UpdateClientMobilePhoneRequest,
  UpdateClientPersonalIdAndBirthdateRequest,
  UpdateClientPersonalInformationRequest, UserPermissionToTabList
} from '@twino/backoffice-api';
import { AlertsService, BasicComponent, ClientService, UserService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class ClientEditComponent extends BasicComponent implements OnInit {
  @Input() clientId: number;

  clientEditPersonalInformationForm: FormGroup;
  clientEditPersonalIdAndBirthdateForm: FormGroup;
  clientEditMobilePhoneForm: FormGroup;
  clientEditEmailForm: FormGroup;
  gender = Gender;
  genderOptions = [];
  activeTabItem = 4;
  userPermissionToTabList = UserPermissionToTabList;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.activeTabItem = this.setActiveTabByPermission();
    this.genderOptions = Object.keys(this.gender);
    this.clientEditPersonalInformationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      title: '',
      gender: ['', Validators.required]
    });
    this.clientEditPersonalIdAndBirthdateForm = this.formBuilder.group({
      personalId: ['', Validators.required],
      dateOfBirth: ['', Validators.required]
    });
    this.clientEditMobilePhoneForm = this.formBuilder.group({
      mobilePhone: ['', Validators.required]
    });
    this.clientEditEmailForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
    this.clientService.getClient(this.clientId).pipe(
      take(1)
    ).subscribe(clientData => {
      const editClientPersonalInformationData: UpdateClientPersonalInformationRequest = {
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        title: clientData.title,
        gender: clientData.gender
      }
      const editClientPersonalIdAndBirthdateData: UpdateClientPersonalIdAndBirthdateRequest = {
        personalId: clientData.personalId,
        dateOfBirth: clientData.dateOfBirth
      }
      const editClientMobilePhoneData: UpdateClientMobilePhoneRequest = {
        mobilePhone: clientData.mobilePhone
      }
      const editClientEmailData: UpdateClientEmailRequest = {
        email: clientData.email
      }
      this.clientEditPersonalInformationForm.setValue(editClientPersonalInformationData);
      this.clientEditPersonalIdAndBirthdateForm.setValue(editClientPersonalIdAndBirthdateData);
      this.clientEditMobilePhoneForm.setValue(editClientMobilePhoneData);
      this.clientEditEmailForm.setValue(editClientEmailData);
    })
  }


  updateClientPersonalInfo() {
    this.clientService.updateClientPersonalInfo(this.clientId, this.clientEditPersonalInformationForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Personal information updated for client ${this.clientId}`);
      this.activeModal.close();
    })
  }

  updateClientPersonalIdAndBirthDate() {
    this.clientService.updateClientPersonalIdAndBirthDate(
      this.clientId,
      this.clientEditPersonalIdAndBirthdateForm.value
    ).pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Personal ID and birthdate updated for client ${this.clientId}`);
      this.clientsRefreshService.reloadClientModel();
      this.activeModal.close();
    })
  }

  updateClientPhone() {
    this.clientService.updateClientPhone(this.clientId, this.clientEditMobilePhoneForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Phone updated for client ${this.clientId}`);
      this.clientsRefreshService.reloadClientModel();
      this.activeModal.close();
    })
  }

  updateClientEmail() {
    this.clientService.updateClientEmail(this.clientId, this.clientEditEmailForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Email updated for client ${this.clientId}`);
      this.clientsRefreshService.reloadClientModel();
      this.activeModal.close();
    })
  }


  setActiveTabByPermission(): number {
      const user = this.userService.getUser();
      const clientEditPermissions = Object.keys(this.userPermissionToTabList);
      const intersections = user.permissions.filter(element => clientEditPermissions.includes(element));
      return this.userPermissionToTabList[intersections[0]];
  }

}
