import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KonsoleComponent } from './components/konsole/konsole.component';

const routes: Routes = [
  {path: '', component: KonsoleComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KonsoleRoutingModule { }
