import { ProductType, TermItem } from '@twino/backoffice-api';

export interface InitialData {
  phoneNumber: string;
  email: string;
  applicationAmount: number;
  loanLength: TermItem;
  brand: string
  productType: ProductType;
  productSubType: ProductSubType;
  consents: {[key: string]: boolean}
}

export interface InitialDataResponse {
  clientId: number;
}

export enum ProductSubType {
  NEW = 'NEW',
  REPEATED = 'REPEATED',
  REFINANCED = 'REFINANCED'
}
