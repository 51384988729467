<form [formGroup]="topicForm" (ngSubmit)="submitForm()">
  <div class="modal-header">
    <h4 class="modal-title">
      Choose task topic
    </h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('closed')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <label
        for="templateKey"
        class="col-sm-4 control-label">
        <strong>Task topic:</strong>
        <sup class="text-danger">*</sup>
      </label>
      <div class="col-sm-5">
        <select
          ngbAutofocus
          class="form-control form-control-sm"
          id="newTopic"
          formControlName="newTopic"
          name="newTopic">
          <option
            *ngFor="let topic of topics"
            [value]="topic">
            {{topic}}
          </option>
        </select>
      </div>
  </div>

  <div class="modal-footer">
    <button type="button" autofocus class="btn btn-outline-secondary" (click)="modal.dismiss(false)">Cancel</button>
    <button type="submit" class="btn btn-danger" [disabled]="topicForm.invalid">Ok</button>
  </div>
</form>

