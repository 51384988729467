/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Utils } from './utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewHistoryService implements RouteReuseStrategy,OnDestroy {

  private history: { [key: string]: { name: Observable<string>, handle: DetachedRouteHandle } } = {};
  private recentlyDeletedEntry = "";
  public closeTab$ = new Subject<void>();

  constructor() {
  }

  ngOnDestroy(): void {
    this.closeTab$.unsubscribe();
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const futureDefinition = ViewHistoryService.buildRouteDefinition(future);
    const currDefinition = ViewHistoryService.buildRouteDefinition(curr);
    return future.data['reuse'] && curr.data['reuse'] && futureDefinition === currDefinition;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return ViewHistoryService.isRouteStorable(route);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const routeDefinition = ViewHistoryService.buildRouteDefinition(route);
    return ViewHistoryService.isRouteStorable(route) ? this.history[routeDefinition]?.handle : undefined;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const routeDefinition = ViewHistoryService.buildRouteDefinition(route);
    if (ViewHistoryService.isRouteStorable(route)) {
      if (this.history[routeDefinition]) {
        return true;
      } else {
        this.history[routeDefinition] = {name: ViewHistoryService.prepareAndGetName(route, routeDefinition), handle: undefined}
        return false;
      }
    } else {
      return false;
    }
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    const routeDefinition = ViewHistoryService.buildRouteDefinition(route);
    if (!!ViewHistoryService.isRouteStorable(route) && !!handle && this.recentlyDeletedEntry !== routeDefinition) {
      this.history[routeDefinition].handle = handle;
    }
    this.recentlyDeletedEntry = "";
  }

  historyHasEntries(): boolean {
    return Utils.isNotNullishOrEmpty(this.history);
  }

  getHistory() {
    return this.history;
  }

  removeHistoryEntry(id: string) {
    const idWithoutQueryParams = id.split('?')[0];
    if (this.history[idWithoutQueryParams]) {
      delete this.history[idWithoutQueryParams];
      this.recentlyDeletedEntry = idWithoutQueryParams;
    }
  }

  clearHistory(current: string | null = null, except: string | null = null) {
    const exception = except && this.history[except];
    this.history = {};
    if (exception) this.history[except] = exception;
    this.recentlyDeletedEntry = current;
  }

  closeTab() {
    this.closeTab$.next();
  }

  static buildRouteDefinition(route: ActivatedRouteSnapshot) {
    if (ViewHistoryService.isNotRouteStorable(route)) return "";
    const urlList: Array<string> = [];
    this.extractUrlList(route, urlList);
    return "/" + urlList.reverse().join("/");
  }

  private static prepareAndGetName(route: ActivatedRouteSnapshot, routeDefinition: string): Observable<string> {
    route.data[routeDefinition] = route.data[routeDefinition] || new BehaviorSubject<string>('');
    return route.data[routeDefinition].asObservable();
  }

  private static isRouteStorable(route: ActivatedRouteSnapshot): boolean {
    return route.data?.reuse && !route.firstChild;
  }

  private static isNotRouteStorable(route: ActivatedRouteSnapshot): boolean {
    return !ViewHistoryService.isRouteStorable(route);
  }

  private static extractUrlList(route: ActivatedRouteSnapshot, urlList: Array<string>) {
    route.url?.map((it) => it.path).reverse().forEach((it) => urlList.push(it));
    if (route.parent) {
      this.extractUrlList(route.parent, urlList);
    }
  }
}
