import { Component, OnInit } from '@angular/core';
import { AffiliateService, AlertsService, NamedComponent, ViewHistoryService } from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AffiliateConfigurationReport,
  AffiliateConfigurationReportForm,
  AffiliateConfigurationRequest,
  AffiliateEvent,
  UpdateAffiliateConfigurationReportsRequest
} from '@twino/backoffice-api';
import { first, takeUntil } from 'rxjs/operators';
import { AffiliatesRefreshService } from '../../services/affiliates-refresh.service';

@Component({
  selector: 'affiliate-config',
  templateUrl: './affiliate-config.component.html',
  styleUrls: ['./affiliate-config.component.scss']
})
export class AffiliateConfigComponent extends NamedComponent implements OnInit {

  id: number | null;
  tabTitle: string;
  affiliateConfigForm: FormGroup;
  affiliateConfigurationReportsFormData: AffiliateConfigurationReportForm[];
  affiliatesConfigurationReportsData: Map<AffiliateEvent, AffiliateConfigurationReport> | never;

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private viewHistoryService: ViewHistoryService,
    private affiliateConfigService: AffiliateService,
    private affiliatesRefreshService: AffiliatesRefreshService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.tabTitle = (this.id > 0) ? `Update` : `Create`;
    this.affiliateConfigForm = this.formBuilder.group({
      name: ['', Validators.required],
      token: '',
      partnerName: '',
      additionalParameterName: '',
      customTracker: '',
      enabled: [false],
      redirectUrl: '',
      reports: this.formBuilder.array([])
    });
    if (this.id > 0) {
      this.populateWithExistingData();
    }
  }

  getName(): string {
    return `${this.tabTitle} affiliate config`;
  }

  private populateWithExistingData() {
    this.affiliateConfigService.getConfiguration(this.id).pipe(first()).subscribe(affiliateConfig => {
      this.affiliateConfigForm.setValue({
        name: affiliateConfig.name,
        token: affiliateConfig.token,
        partnerName: affiliateConfig.partnerName,
        additionalParameterName: affiliateConfig.additionalParameterName,
        customTracker: affiliateConfig.customTracker,
        enabled: affiliateConfig.enabled,
        redirectUrl: affiliateConfig.redirectUrl,
        reports: []
      });
      this.affiliatesConfigurationReportsData = affiliateConfig.reports
    });
  }

  submitForm() {
    this.affiliateConfigurationReportsFormData = this.affiliateConfigForm.value.reports;
    const affiliateConfigRequest: AffiliateConfigurationRequest = {
      name: this.affiliateConfigForm.value.name,
      token: this.affiliateConfigForm.value.token,
      partnerName: this.affiliateConfigForm.value.partnerName,
      additionalParameterName: this.affiliateConfigForm.value.additionalParameterName,
      customTracker: this.affiliateConfigForm.value.customTracker,
      enabled: this.affiliateConfigForm.value.enabled,
      redirectUrl: this.affiliateConfigForm.value.redirectUrl
    }

    if (this.id > 0) {
      this.updateConfiguration(affiliateConfigRequest);
    } else {
      this.addConfiguration(affiliateConfigRequest);
    }
  }

  addConfiguration(affiliateConfigRequest: AffiliateConfigurationRequest) {
    this.affiliateConfigService.addConfiguration(affiliateConfigRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(newAffiliateConfigId => {
      this.id = newAffiliateConfigId;
      this.alertService.notifySuccess(`Add new affiliate configuration #${this.id}`);
      this.updateReports();
    })
  }

  updateConfiguration(affiliateConfigRequest: AffiliateConfigurationRequest) {
    this.affiliateConfigService.updateConfiguration(this.id, affiliateConfigRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Affiliate configuration updated #${this.id}`);
      this.updateReports();
    })
  }

  updateReports() {
    const reportConfigurations: Record<string, AffiliateConfigurationReport> = {};
      this.affiliateConfigurationReportsFormData.forEach(
        report => {reportConfigurations[report.event] = {type: report.type, url: report.url}}
      )
    const updateAffiliateConfigurationReportsRequest: UpdateAffiliateConfigurationReportsRequest = {
      reportConfigurations: reportConfigurations
    }
    this.affiliateConfigService.updateConfigurationReport(this.id, updateAffiliateConfigurationReportsRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Affiliate configuration reports updated`);
      this.affiliatesRefreshService.reloadAffiliateConfigsModel();
      this.viewHistoryService.closeTab();
    }, () => {})
  }
}
