import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FullLoan, RepaymentProposal } from '@twino/backoffice-api';
import { AlertsService, BasicComponent, ConfirmDialogueComponent, RepaymentProposalService } from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoansRefreshService } from '../../services/loans-refresh-services';
import { Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-loan-repayment-proposals',
  templateUrl: './loan-repayment-proposals.component.html',
  styleUrls: ['./loan-repayment-proposals.component.scss']
})
export class LoanRepaymentProposalsComponent extends BasicComponent implements OnInit, OnDestroy {

  @Input()
  loan: FullLoan;
  repaymentProposals: RepaymentProposal[];

  private reloadRepaymentProposalsModel: Subscription;

  constructor(
    private repaymentProposalService: RepaymentProposalService,
    private modalService: NgbModal,
    private alertService: AlertsService,
    private loanRefreshService: LoansRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.refresh();
    this.reloadRepaymentProposalsModel = this.loanRefreshService.reloadLoanRepaymentProposalsModel$.subscribe(() => {
      this.refresh();
    })
  }

  ngOnDestroy(): void {
    this.reloadRepaymentProposalsModel.unsubscribe();
  }

  refresh() {
    this.repaymentProposalService.find(this.loan.id).pipe(
      take(1)
    ).subscribe(proposals => {
      this.repaymentProposals = proposals;
    })
  }

  trackById(index, item){
    return item.id;
  }

  enable(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Enable repayment proposal #${id} `;
    modalRef.componentInstance.content = "Are you sure you want to enable repayment proposal?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.repaymentProposalService.enableRepaymentProposal(id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {
            this.alertService.notifySuccess(`Repayment proposal #${id} is enabled`);
            this.refresh();
          }
        );
      }, () => {}
    );
  }

  disable(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Disable repayment proposal #${id} `;
    modalRef.componentInstance.content = "Are you sure you want to disable repayment proposal?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.repaymentProposalService.disableRepaymentProposal(id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {
            this.alertService.notifySuccess(`Repayment proposal #${id} is disabled`);
            this.refresh();
          }
        );
      }, () => {}
    );
  }
}
