import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductConfig, SystemConfigurationRequest } from '@twino/backoffice-api';
import { AlertsService, NamedComponent, SystemConfigService, ViewHistoryService } from '@backoffice-monorepo/commons';
import { SystemConfigRefreshService } from '../../services/system-config-refresh.service';
import { ActivatedRoute } from '@angular/router';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-system-config-add',
  templateUrl: './system-config-add.component.html',
  styleUrls: ['./system-config-add.component.scss']
})
export class SystemConfigAddComponent extends NamedComponent implements OnInit {

  systemConfigurationAddForm: FormGroup;
  systemConfigurationRequest: SystemConfigurationRequest;
  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, {static: false}) editor: JsonEditorComponent;
  isInvalidJson = false;

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private systemConfigurationService: SystemConfigService,
    private formBuilder: FormBuilder,
    private systemConfigurationRefreshService: SystemConfigRefreshService,
    private viewHistoryService: ViewHistoryService,
    private productConfig: ProductConfig
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = this.productConfig.jsonEditorModes;
    this.editorOptions.onChange = () => {
      this.editor.onChange({});
      this.isInvalidJson = !this.editor.isValidJson();
    }

    this.systemConfigurationAddForm = this.formBuilder.group({
      name: ['', Validators.required],
      data: ['', Validators.required]
    });
  }

  getName(): string {
    return `Create system configuration record`;
  }

  submitForm() {
    this.systemConfigurationRequest = {
      name: this.systemConfigurationAddForm.value.name,
      data: JSON.stringify(this.systemConfigurationAddForm.value.data)
    }
    this.systemConfigurationService.createSystemConfig(this.systemConfigurationRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess("System configuration record is added");
      this.viewHistoryService.closeTab();
      this.systemConfigurationRefreshService.reloadSystemConfigListModel();
    })
  }
}
