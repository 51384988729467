import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { NotificationConfigsRoutingModule } from './notification-configs-routing.module';
import { NotificationConfigsListComponent } from './components/notification-configs-list/notification-configs-list.component';
import { NgbNavModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationConfigComponent } from './components/notification-config/notification-config.component';


@NgModule({
  declarations: [NotificationConfigsListComponent, NotificationConfigComponent],
  imports: [
    CommonModule,
    CommonsModule,
    TranslateModule,
    ReactiveFormsModule,
    NotificationConfigsRoutingModule,
    NgJsonEditorModule,
    NgbNavModule,
    NgbTypeaheadModule
  ]
})
export class NotificationConfigsModule { }
