<form *ngIf="showForm" [formGroup]="blacklistEditForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Blacklist #{{blacklistId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="category" class="col-sm-4 control-label">Category</label>
      <div class="col-sm-8">
        <select class="form-control form-control-sm" id="category" formControlName="category" name="category">
          <option *ngFor="let key of categoryOptions" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="personalId" class="col-sm-4 control-label">Personal ID</label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="personalId" formControlName="personalId" name="personalId">
      </div>
    </div>
    <div class="form-group row">
      <label for="ip" class="col-sm-4 control-label">First name</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="firstName" formControlName="firstName" name="firstName">
      </div>
    </div>
    <div class="form-group row">
      <label for="ip" class="col-sm-4 control-label">Last name</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="lastName" formControlName="lastName" name="lastName">
      </div>
    </div>
    <div class="form-group row">
      <label for="ip" class="col-sm-4 control-label">Ip</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="ip" formControlName="ip" name="ip">
      </div>
    </div>
    <div class="form-group row">
      <label for="ip" class="col-sm-4 control-label">Email</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="email" formControlName="email" name="email">
      </div>
    </div>
    <div class="form-group row">
      <label for="identityDocNumber" class="col-sm-4 control-label">ID number</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="identityDocNumber" formControlName="identityDocNumber" name="identityDocNumber">
      </div>
    </div>
    <div class="form-group row">
      <label for="bankAccountNumber" class="col-sm-4 control-label">Bank account number</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="bankAccountNumber" formControlName="bankAccountNumber" name="bankAccountNumber">
      </div>
    </div>
    <div class="form-group row">
      <label for="ip" class="col-sm-4 control-label">Bank card number</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="bankCardNumber" formControlName="bankCardNumber" name="bankCardNumber">
      </div>
    </div>
    <div class="form-group row">
      <label for="message" class="col-sm-4 control-label">Comments</label>
      <div class="col-sm-8">
        <textarea type="text" class="form-control form-control-sm" id="message" formControlName="message" name="message"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <label for="active" class="col-sm-4 control-label">Active</label>
      <div class="col-sm-8">
        <input type="checkbox" id="active" formControlName="active" name="active">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="blacklistEditForm.invalid">Update</button>
  </div>
</form>






