
<div class="modal-header">
    <h4 class="modal-title">
        Add language
    </h4>
    <button
        type="button"
        class="close"
        aria-describedby="modal-title"
        (click)="modal.close()"
    >
        <span>&times;</span>
    </button>
    </div>
    <div  class="modal-body">
        <form [formGroup]="form"
         >
            <label class="mb-0" for="language-code">New language code</label>
            <input
                type="text"
                class="form-control-range w-100 mb-2"
                id="language-code"
                formControlName="langCode"
            >
            <ng-container *ngIf="($langs | async).length === 0">
                <label class="mb-0"  for="addTranslationJsonEditor">Data structure</label>
                <json-editor 
                    [options]="editorOptions"
                    formControlName="translationJSON"
                    id="addTranslationJsonEditor"
                ></json-editor>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
    <button
        type="button"
        autofocus
        class="btn btn-outline-secondary"
        (click)="modal.close()"
    >Cancel</button>
    <button
        [disabled]="form.status !== 'VALID'"
        (click)="modal.close(form.value)"
        type="button"
        class="btn btn-success"
    >Add</button>
    </div>
