import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicComponent, BlacklistService } from '@backoffice-monorepo/commons';
import { BlacklistCategory } from '@twino/backoffice-api';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-blacklist-edit',
  templateUrl: './blacklist-edit.component.html',
  styleUrls: ['./blacklist-edit.component.scss']
})
export class BlacklistEditComponent extends BasicComponent implements OnInit {

  blacklistId: number;
  blacklistEditForm: FormGroup;
  categories = BlacklistCategory;
  categoryOptions = [];
  showForm = false;

  constructor(
    public activeModal: NgbActiveModal,
    private blacklistService: BlacklistService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.categoryOptions = Object.values(this.categories);
    this.blacklistEditForm = this.formBuilder.group({
      personalId: [{value: null, disabled: true}],
      message: [null],
      active: [false],
      category: [{value: null, disabled: true}],
      ip: [{value: null, disabled: true}],
      identityDocNumber: [{value: null, disabled: true}],
      bankAccountNumber: [{value: null, disabled: true}],
      firstName: [{value: null, disabled: true}],
      lastName: [{value: null, disabled: true}],
      email: [{value: null, disabled: true}],
      bankCardNumber: [{value: null, disabled: true}],
    });
    this.blacklistService.getById(this.blacklistId).pipe(
      take(1)
    ).subscribe(blacklist => {
      this.blacklistEditForm.patchValue(blacklist);
      this.showForm = true;
    })
  }

  submitForm() {
    this.blacklistService.updateBlacklistItem(this.blacklistId, this.blacklistEditForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.activeModal.close('Success');
    });
  }

}
