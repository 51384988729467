import { Component, OnInit } from '@angular/core';
import { AlertsService, DiscountCampaignService, NamedComponent, TwinoDateAdapterService, ViewHistoryService } from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateCampaignRequest, CreateDiscountRequest, DiscountTarget, PredicateParams, PredicateResponse } from '@twino/backoffice-api';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DiscountCampaignRefreshService } from '../../services/discount-campaigns-refresh.service';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'backoffice-monorepo-discount-campaign',
  templateUrl: './discount-campaign.component.html',
  styleUrls: ['./discount-campaign.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class DiscountCampaignComponent extends NamedComponent implements OnInit {

  id: number;
  discountCampaignForm: FormGroup;
  discountTargetOptions: string[] = [];
  predicateScriptInformation: PredicateResponse | never;

  discountPercentMinAmount = 1;
  discountPercentMaxAmount = 100;

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private viewHistoryService: ViewHistoryService,
    private discountCampaignService: DiscountCampaignService,
    private discountCampaignRefreshService: DiscountCampaignRefreshService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.discountTargetOptions = Object.keys(DiscountTarget);
    this.discountCampaignService.getDiscountPredicateScriptInformation().pipe(
      take(1)
    ).subscribe(predicates => {
      this.predicateScriptInformation = predicates
    });
    this.discountCampaignForm = this.formBuilder.group({
      name: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      isActive: [false],
      isSingleUse: [false],
      discountRows: this.formBuilder.array([])
    });
  }

  getName(): string {
    return `Create campaign ${this.id || ''}`;
  }

  createForm(): FormGroup {
    const predicateParams = this.formBuilder.array([]);
     for (let i = 0; i < this.predicateScriptInformation.variables.length; i++) {
        predicateParams.push(this.formBuilder.group({
          predicateKey: [this.predicateScriptInformation.variables[i], Validators.required],
          predicateValue: ['', Validators.required]
        }));
      }

    return this.formBuilder.group({
      discountTarget: '',
      discountPercent: '',
      predicateRows: predicateParams
    });
  }

  get getDiscountControls() {
    return this.discountCampaignForm.get('discountRows') as FormArray;
  }

  addDiscountRow() {
    const control = this.discountCampaignForm.get('discountRows') as FormArray;
    control.push(this.createForm());
  }

  deleteDiscountRow(index: number) {
    const control = this.discountCampaignForm.get('discountRows') as FormArray;
    control.removeAt(index);
  }

  submitForm() {
    const campaignRequest: CreateCampaignRequest = {
      campaignName: this.discountCampaignForm.value.name,
      fromDate: this.discountCampaignForm.value.fromDate,
      toDate: this.discountCampaignForm.value.toDate,
      isActive: this.discountCampaignForm.value.isActive,
      isSingleUse: this.discountCampaignForm.value.isSingleUse,
    }

    this.discountCampaignService.createCampaign(campaignRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(resp => {
      const headerLocation = resp.headers.get("Location");
      this.alertService.notifySuccess("Campaign is added");
      this.discountCampaignForm.value.discountRows.forEach((discount) => {
       const discountRequest: CreateDiscountRequest = {
            discountTarget: discount.discountTarget,
            discountPercent: discount.discountPercent,
            predicateScriptVariables: this.mapPredicateValues(discount.predicateRows)
          }

        this.discountCampaignService.createDiscount(headerLocation, discountRequest).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.alertService.notifySuccess("Discount is added");
        })
      })
      this.discountCampaignRefreshService.reloadDiscountCampaignsListModel();
      this.viewHistoryService.closeTab();
    });

  }

  mapPredicateValues(predicates: PredicateParams[]): Map<string, string> {
    return Object.assign({}, ...predicates.map(s => ({[s.predicateKey]: s.predicateValue})));
  }

}
