<div class="modal-header" [ngClass]="class">
  <h4 class="modal-title">
    <bi [name]="icon"></bi>&nbsp;
    Create card
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('closed')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Are you sure you want to create {{cardType}}  card? </p>
  <table class="table table-sm mt-2" *ngIf="chargeableFees.length > 0">
    <tbody>
    <tr>
      <td>
        <h6 class="ml-2">{{utils.enumValueToLabel(issuance_1st_year)}}</h6>
        <table class="table table-sm table-hover mt-2">
          <thead class="text-light bg-primary">
          <tr>
            <th>Min operations count</th>
            <th>Max operations count</th>
            <th>Fee amount</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let fee of chargeableFees">
            <td>{{fee.minOperationsCount}}</td>
            <td>{{fee.maxOperationsCount}}</td>
            <td>{{fee.feeAmount}}</td>
          </tr>
          </tbody>
        </table>
      </td>
    </tr>
    </tbody>
  </table>
  <table class="table table-sm table-hover mt-2" *ngIf="chargeableFees.length > 0">
    <thead class="text-light bg-primary">
    <tr>
      <th>Fee type</th>
      <th>Fee amount</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{utils.enumValueToLabel(monthly_from_second_year)}}</td>
      <td> {{monthlyFees.monthlyFee}}</td>
    </tr>
    </tbody>
  </table>
  <table class="table table-sm table-hover mt-2" *ngIf="chargeableFees.length == 0">
    <thead class="text-light bg-primary">
    <tr>
      <th>Fee type</th>
      <th>Fee amount</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{utils.enumValueToLabel(issuance_1st_year)}}</td>
      <td>{{chargeableFeeNoTariff}}</td>
    </tr>
    </tbody>
  </table>
  <form [formGroup]="confirmForm" *ngIf="showConfirmationCodeInput" class="mt-2">
    <div class="form-group row">
      <label class="col-sm-2" for="code">Code</label>
      <div class="col-sm-10">
        <input class="form-control w-50" id="code" formControlName="confirmationCode">
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" autofocus class="btn btn-outline-secondary" (click)="activeModal.close(false)">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="submitForm()" [disabled]="confirmForm.invalid && showConfirmationCodeInput">Confirm</button>
</div>
