import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { DebtConfigsRoutingModule } from './debt-configs-routing.module';
import { DebtConfigsListComponent } from './components/debt-configs-list/debt-configs-list.component';
import { DebtConfigComponent } from './components/debt-config/debt-config.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [DebtConfigsListComponent, DebtConfigComponent],
  imports: [
    CommonModule,
    CommonsModule,
    TranslateModule,
    ReactiveFormsModule,
    DebtConfigsRoutingModule,
    NgJsonEditorModule,
    DragDropModule
  ]
})
export class DebtConfigsModule { }

