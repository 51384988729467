import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiskEvaluationRefreshService } from '../../services/risk-evaluation-refresh.service';
import { AlertsService, NamedComponent, RiskEvaluationConfigService, ViewHistoryService } from '@backoffice-monorepo/commons';
import {
  ProductConfig, RiskEvaluationRuleResponse,
  RiskEvaluationStepConfigResponse,
  UpdateRiskEvaluationRequest
} from '@twino/backoffice-api';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-risk-evaluation-config',
  templateUrl: './risk-evaluation-config.component.html',
  styleUrls: ['./risk-evaluation-config.component.scss']
})
export class RiskEvaluationConfigComponent extends NamedComponent implements OnInit {

  stepId: number;
  riskEvaluationConfigEditForm: FormGroup;
  control: FormArray;
  ruleTypes: string[];
  riskEvaluationConfiguration: RiskEvaluationStepConfigResponse;
  riskEvaluationRequest: UpdateRiskEvaluationRequest;

  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, {static: false}) configEditor: JsonEditorComponent;

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private riskEvaluationConfigService: RiskEvaluationConfigService,
    private riskEvaluationConfigRefreshService: RiskEvaluationRefreshService,
    private viewHistoryService: ViewHistoryService,
    private productConfig: ProductConfig,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.stepId = this.activatedRoute.snapshot.params['id'];
    this.riskEvaluationConfigService.listRuleTypes().pipe(
      take(1)
    ).subscribe((names) => {
      this.ruleTypes = names;
    })
    this.riskEvaluationConfigEditForm = this.formBuilder.group({
      cardRows: this.formBuilder.array([])
    });
    this.setRows();
  }

  getName(): string {
    return `Flow step rules #${this.stepId || ''}`;
  }

  makeOptions = () => {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = this.productConfig.jsonEditorModes;
    return this.editorOptions;
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      ruleName: ['empty', Validators.required],
      description: ['empty'],
      className: ['', Validators.required],
      isTestMode: [false],
      isSkipped: [false],
      params: '',
      abPercent: ['', Validators.required],
      isEditableInput: [false]
    });
  }

  setFormGroupValues(values: RiskEvaluationRuleResponse) {
    const formGroupRule = this.createForm();
    formGroupRule.setValue({
      ruleName: values.name,
      description: values.description,
      className: values.className,
      isTestMode: values.isTestMode,
      isSkipped: values.isSkipped,
      params: JSON.parse(values.parameters),
      abPercent: values.abPercent,
      isEditableInput: false
    });
    return formGroupRule;
  }

  get  getFormControls() {
    return this.riskEvaluationConfigEditForm?.get('cardRows') as FormArray;
  }

  setRows() {
    this.riskEvaluationConfigService.getFlowStep(this.stepId).pipe(
      take(1)
    ).subscribe(
      (riskEvaluationConfiguration) => {
        const formControl = this.riskEvaluationConfigEditForm.get('cardRows') as FormArray;
        this.riskEvaluationConfiguration = riskEvaluationConfiguration;
        if (riskEvaluationConfiguration.rules) {
          riskEvaluationConfiguration.rules.forEach((values) => {
            formControl.push(this.setFormGroupValues(values));
          });
        }
      })
  }

  addRow() {
    const control = this.riskEvaluationConfigEditForm.get('cardRows') as FormArray;
    control.push(this.createForm());
  }

  deleteRow(index: number) {
    const control = this.riskEvaluationConfigEditForm.get('cardRows') as FormArray;
    control.removeAt(index);
  }

  edit(group: AbstractControl, controlName: string) {
    group.get(controlName).setValue(true);
  }

  close(group: AbstractControl, controlName: string) {
    group.get(controlName).setValue(false);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getFormControls.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.riskEvaluationConfigEditForm.value.cardRows, event.previousIndex, event.currentIndex);
  }

  submitForm() {
    const ruleConfigs = [];
    this.riskEvaluationConfigEditForm.value.cardRows.forEach((rule) => {
      ruleConfigs.push({
        ruleName: rule.ruleName,
        description: rule.description,
        className: rule.className,
        isTestMode: rule.isTestMode,
        isSkipped: rule.isSkipped,
        params: JSON.stringify(rule.params),
        abPercent: rule.abPercent,
      });
    });
    this.riskEvaluationRequest = {
      ruleConfig: ruleConfigs
    }
    this.riskEvaluationConfigService.updateRiskEvaluationStepRules(
      this.stepId,
      this.riskEvaluationRequest)
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(() => {
        this.alertService.notifySuccess(this.stepId + " is updated");
        this.viewHistoryService.closeTab();
        this.riskEvaluationConfigRefreshService.reloadRiskEvaluationConfigModel();
      })
  }
}
