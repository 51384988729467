import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, ClientDocumentService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'client-identity-document-add',
  templateUrl: './client-identity-document-add.component.html',
  styleUrls: ['./client-identity-document-add.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class ClientIdentityDocumentAddComponent extends BasicComponent implements OnInit {

  @Input() clientId: number;

  clientIdentityDocumentAddForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientDocumentService: ClientDocumentService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientIdentityDocumentAddForm = this.formBuilder.group({
      number: ['', Validators.required],
      active: [false],
      issueDate: ['', Validators.required],
      expiryDate: [null],
      isWithoutExpiryDate: [false],
      authority: [null],
      authorityCode: [null],
      authorityCountry: [null]
    });
  }

  submitForm() {
    this.clientDocumentService.createIdentityDocument(this.clientId, this.clientIdentityDocumentAddForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.clientsRefreshService.reloadClientModel();
      this.alertService.notifySuccess(`Create identity document added for client ${this.clientId}`);
      this.activeModal.close(true);
    })
  }

}
