<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client login attempts</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>IP address</th>
          <th>Authentication result</th>
          <th>Login attempt date </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of loginAttempts">
          <td></td>
          <td>{{item.ipAddress}}</td>
          <td>{{item.authenticationResult}}</td>
          <td>{{item.loginAttemptDateTime}}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>

