import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BOOLEAN, DiscountCampaignService, STRING, TransformInstructions, TransformInstructionsBuilder } from '@backoffice-monorepo/commons';
import { Campaign } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'discount-campaign-view',
  templateUrl: './discount-campaign-view.component.html',
  styleUrls: ['./discount-campaign-view.component.scss']
})
export class DiscountCampaignViewComponent implements OnInit {
  @Input() id: number;
  campaignInfo: Campaign;

  campaignTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['name', ['Name', STRING]],
      ['fromDate', ['From date', STRING]],
      ['toDate', ['To date', STRING]],
      ['isActive', ['Active', BOOLEAN]]
    ]
  );

  constructor(
    public activeModal: NgbActiveModal,
    private discountCampaignService: DiscountCampaignService,
  ) { }

  ngOnInit(): void {
    this.discountCampaignService.getCampaign(this.id).pipe(
      take(1)
    ).subscribe(campaign => {
      this.campaignInfo = campaign;
    })

  }

  trackById(index, item){
    return item.id;
  }

}
