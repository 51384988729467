<div class="modal-header" cdkDrag cdkDragHandle cdkDragRootElement=".dragged-modal">
  <h4 class="modal-title">
    Run job
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close(false)">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="jobForm">
    <div class="form-group row">
      <label for="jobName" class="col-sm-4 control-label">Job name<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select class="form-control form-control-sm" id="jobName" formControlName="jobName">
          <option *ngFor="let key of jobList$ | async" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" [disabled]="jobForm.invalid" (click)="submitForm()">Run</button>
  <button type="button" autofocus class="btn btn-outline-secondary" (click)="activeModal.close(false)">Cancel</button>
</div>
