import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DiscountCampaignsRoutingModule } from './discount-campaigns-routing.module';
import { DiscountCampaignsListComponent } from './components/discount-campaigns-list/discount-campaigns-list.component';
import { DiscountCampaignComponent } from './components/discount-campaign/discount-campaign.component';
import { DiscountCampaignViewComponent } from './components/discount-campaign-view/discount-campaign-view.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DiscountCampaignsImportComponent } from './components/discount-campaigns-import/discount-campaigns-import.component';


@NgModule({
  declarations: [DiscountCampaignsListComponent, DiscountCampaignComponent, DiscountCampaignViewComponent, DiscountCampaignsImportComponent],
  imports: [
    CommonModule,
    DiscountCampaignsRoutingModule,
    CommonsModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbDatepickerModule
  ]
})
export class DiscountCampaignsModule { }
