import { Component, Inject, OnDestroy, OnInit, Type } from '@angular/core';
import {
  AMOUNT,
  ConfirmDialogueComponent,
  CountryViewComponent,
  DATE,
  ID,
  NamedComponent,
  NUMBER,
  PaymentButtonsViewComponentResolver,
  PaymentDistributionService,
  PaymentDistributionsTableComponentResolver,
  PaymentService,
  STRING,
  TransformInstructions,
  TransformInstructionsBuilder
} from '@backoffice-monorepo/commons';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FilterOperation,
  JoinStrategy,
  Payment,
  PaymentDirection,
  PaymentDistribution,
  PaymentStatus,
  SearchQueryBuilder
} from '@twino/backoffice-api';
import { Subscription } from 'rxjs';
import { PaymentDistributionAddComponent } from '../payment-distribution-add/payment-distribution-add.component';
import { PaymentsRefreshService } from '../../services/payments-refresh.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent extends NamedComponent implements OnInit, OnDestroy {

  private reloadPaymentModel: Subscription;
  private routeDataSubscription: Subscription;

  id: number;
  payment: Payment | null;
  distributions: PaymentDistribution[] | null;
  showVoidPaymentButton: boolean;
  showCreateDistributionButton: boolean;
  paymentButtonViewComponentType: Type<CountryViewComponent>;
  paymentDistributionsTableComponentType: Type<CountryViewComponent>;
  paymentStatus = PaymentStatus;

  paymentTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['id', ['Payment ID', ID]],
      ['direction', ['Direction', STRING]],
      ['channelName', ['Channel', STRING]],
      ['status', ['Status', STRING]],
      ['amount', ['Amount', AMOUNT]],
      ['details', ['Details', STRING]],
      ['fromAccount', ['From account', STRING]],
      ['toAccount', ['To account', STRING]],
      ['bookingDate', ['Booking date', DATE]],
      ['processingTimestamp', ['Processing', DATE]],
      ['clientId', ['Client ID', NUMBER]],
      ['loanNumber', ['Loan Number', NUMBER]],
      ['created', ['Created', DATE]],
      ['clientPersonalId', ['Client Personal ID', NUMBER]]
    ]
  );


  constructor(
    activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private distributionService: PaymentDistributionService,
    private modalService: NgbModal,
    private paymentsRefreshService: PaymentsRefreshService,
    protected router: Router,
    @Inject('PaymentButtonViewComponentResolver') public paymentButtonViewComponentResolver: PaymentButtonsViewComponentResolver,
    @Inject('PaymentDistributionsTableComponentResolver') public paymentDistributionsTableComponentResolver: PaymentDistributionsTableComponentResolver
  ) {
    super(activatedRoute);
    this.paymentButtonViewComponentType = paymentButtonViewComponentResolver.resolve();
    this.paymentDistributionsTableComponentType = paymentDistributionsTableComponentResolver.resolve();
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.payment = this.activatedRoute.snapshot.data.payment;
    this.getDistributions();
    this.reloadPaymentModel = this.paymentsRefreshService.reloadPaymentModel$.subscribe(() => {
      this.refresh();
    })
    this.routeDataSubscription = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd && event.snapshot?.data?.payment) {
        this.id = event.snapshot?.params?.id;
        this.payment = event.snapshot?.data?.payment || this.payment;
      }
    });
  }

  ngOnDestroy(): void {
    this.reloadPaymentModel.unsubscribe();
    this.routeDataSubscription.unsubscribe();
  }

  getName(): string {
    return `Payment ${this.id || ''}`;
  }

  showPaymentActionButtons() {
    return (this.payment.status === PaymentStatus.UNPROCESSED && this.payment.direction !== PaymentDirection.OUTGOING)
      || this.payment.status === PaymentStatus.MANUAL_PROCESSING;
  }


  createDistribution() {
    const modalRef = this.modalService.open(PaymentDistributionAddComponent);
    modalRef.componentInstance.paymentId = this.id;
    modalRef.componentInstance.paymentStatus = this.payment.status;
  }

  voidPayment() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Void payment";
    modalRef.componentInstance.content = "Are you sure you want to void this payment?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.paymentService.voidPayment(this.id).pipe(
          takeUntil(this.$destroy)
        ).subscribe();
      }, () => {}
    );
  }



  refresh() {
    this.router.navigate([`/payments/${this.id}`]).then(r => {
      this.getDistributions();
    });
  }

  getDistributions() {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(40)
      .withJoinStrategy(JoinStrategy.AND)
      .addFilterData({
        enumClass: "eu.twino.loans.core.payment.api.PaymentDistributionStatus",
        propertyName: "status",
        operation: FilterOperation.NOT_EQUALS,
        type: "enum",
        values: [PaymentStatus.VOIDED.toString()]
      })
      .addFilterData({
        propertyName: "paymentId",
        operation: FilterOperation.EQUALS,
        values: [this.id.toString()]
      })
      .build();

    this.distributionService.find(searchQuery).pipe(
      takeUntil(this.$destroy)
    ).subscribe(paymentDistributions => {
      this.distributions = paymentDistributions.results;
      this.showVoidPaymentButton = this.showPaymentActionButtons();
      this.showCreateDistributionButton = this.showPaymentActionButtons();
    });
  }
}
