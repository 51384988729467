export interface AmlClientDataItem {
  amlClientId: string
  externalId: number,
  legalId: string,
  legalType: string,
  fullName: string,
  dateOfBirth: string,
  occupation: string,
  isPep: boolean,
  phoneCountryCode: string,
  phoneNumber: number,
  email: string,
  ipAddress: string,
  score: number,
  dataProcessingDisabled: boolean,
}

export interface AmlClientResponse {
  amlClientId?: string
  score?: number
  rating?: AmlRating
  ruleGroups?: Map<string, AmlRule[]>
}

export class AmlScoringHistoryRequest {
  constructor(before: string) {
    this.before = before
  }

  before: string;
  dateFrom?: Date;
  dateTo?: Date;
  limit?: number;
  scoreFrom?: number;
  scoreTo?: number;
}

export interface AmlScoringHistoryItem {
  scoredAt: Date
  rules: string[]
  score: number;
}

export enum AmlRating {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export interface AmlRule {
  name: string
  score: number
  error?: string
}
