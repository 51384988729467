import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Task } from '@backoffice-monorepo/api';
import { BasicComponent, MailboxService } from '@backoffice-monorepo/commons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-change-modal',
  templateUrl: './pl-pi-client-change-modal.component.html',
  styleUrls: ['./pl-pi-client-change-modal.component.css']
})
export class PlPiClientChangeModalComponent extends BasicComponent implements OnInit {
  @Input() task: Task;

  clientForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private mailboxService: MailboxService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientForm = this.formBuilder.group({
      newClientId: ['', Validators.required],
    });
  }

  selectClient(clientId: number) {
    this.clientForm.controls['newClientId'].patchValue(clientId);
  }

  submitForm() {
    this.mailboxService.reAssignClient(this.task.id, this.clientForm.value)
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(() => this.modal.close())
  }
}
