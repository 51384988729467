import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BatchJobListComponent } from './components/batch-job-list/batch-job-list.component';
import { BatchJobExecutionComponent } from './components/batch-job-execution/batch-job-execution.component';

const routes: Routes = [
  { path: '', component: BatchJobListComponent },
  {
    path: ':id',
    component: BatchJobExecutionComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BatchJobRoutingModule { }
