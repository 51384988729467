<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element title="Name" type="string" property="name"></tr>
  <tr filter-element title="From" type="number" property="fromDate"></tr>
  <tr filter-element title="To" type="string" property="toDate"></tr>
  <tr filter-element title="Is single use" type="boolean" property="isSingleUse"></tr>
  <tr filter-element title="Active" type="boolean" property="isActive"></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="m-1">
          <button appRequirePermission="admin.all,discount-campaign.all,discount-campaign.create"
                  class="btn btn-success btn-sm"
                  (click)="navigateToCreate()">
            <bi name="plus-circle"></bi>
            Create campaign
          </button>
    </span>
    <span class="m-1">
          <button appRequirePermission="admin.all,discount-campaign.all,discount-campaign.create,base.import-excel"
                  class="btn btn-success btn-sm"
                  (click)="navigateToImport()">
            <bi name="plus-circle"></bi>
            Import clients
          </button>
    </span>
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="fromDate" (sort)="onSort($event)">Name</th>
      <th sortable="toDate" (sort)="onSort($event)">From</th>
      <th sortable="clientId" (sort)="onSort($event)">To</th>
      <th></th>
      <th>Is single use</th>
      <th>Active</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let campaign of response?.results; let i = index; trackBy: trackById">
      <td></td>
      <td>{{campaign.id}}</td>
      <td>{{campaign.name}}</td>
      <td>{{campaign.fromDate}}</td>
      <td>{{campaign.toDate}}</td>
      <td>
        <button type="button"
                class="btn btn-outline-primary btn-sm"
                (click)="viewCampaign(campaign.id)"
                title="View Campaign">
          <bi name="book"></bi>
        </button>
      </td>
      <td>{{campaign.isSingleUse}}</td>
      <td>{{campaign.isActive}}</td>
      <td>
        <button type="button"
                title="Activate discount campaign"
                class="btn btn-success btn-xs btn-sm"
                *ngIf="campaign.isActive === false"
                (click)="changeStatus(campaign.id, true)"
                appRequirePermission="admin.all,discount-campaign.all,discount-campaign.edit">
          Activate
        </button>
        <button type="button"
                title="Deactivate discount campaign"
                class="btn btn-warning btn-xs btn-sm"
                *ngIf="campaign.isActive === true"
                (click)="changeStatus(campaign.id,false)"
                appRequirePermission="admin.all,discount-campaign.all,discount-campaign.edit">
          Deactivate
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</server-table>



