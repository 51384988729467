import { Component, Input, OnInit } from '@angular/core';
import {
  BasicComponent,
  ClientSidebarComponent,
  ConfirmDialogueComponent,
  draggableModalOptions,
  draggableModalOptionsLarge, LoanService,
  NUMBER,
  STRING,
  TransformInstructions,
  TransformInstructionsBuilder,
  TwinoDateAdapterService
} from '@backoffice-monorepo/commons';
import { PlPiClientCardService } from '../../services/pl-pi-client-card.service';
import { NgbDateAdapter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CardChangeState,
  CardInfo,
  CardIssueType,
  CardState,
  CardStatus,
  ClientInfoResult,
  Transaction
} from '../../api/client.card';
import { PlPiClientCardSendPinComponent } from '../pl-pi-client-card-send-pin/pl-pi-client-card-send-pin.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlPiTransactionDetailsComponent } from '../pl-pi-transaction-details/pl-pi-transaction-details.component';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { PlPiClientCardChangeStatusComponent } from '../pl-pi-client-card-change-status/pl-pi-client-card-change-status.component';
import { PlPiClientCardHistoryComponent } from '../pl-pi-client-card-history/pl-pi-client-card-history.component';
import { PlPiClientCardOtpComponent } from '../pl-pi-client-card-otp/pl-pi-client-card-otp.component';
import { PlPiClientService } from '../../services/pl-pi-client.service';
import { PlPiFullClient } from '../../api/pl-pi-full-client';
import { forkJoin } from 'rxjs';
import { PlPiClientCardAddComponent } from '../pl-pi-client-card-add/pl-pi-client-card-add.component';
import { FilterOperation, JoinStrategy, SearchQueryBuilder } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-card-view',
  templateUrl: './pl-pi-client-card-view.component.html',
  styleUrls: ['./pl-pi-client-card-view.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class PlPiClientCardViewComponent extends BasicComponent implements ClientSidebarComponent, OnInit {

  @Input()
  client: PlPiFullClient;
  clientCardsInfo?: CardInfo[];
  clientInfoCardSystem?: ClientInfoResult | null;
  clientCardState: CardState | null;
  showRegisterButton: boolean;
  showUpdateButton: boolean;
  showTransactionsTable: boolean;
  cardStatus = CardStatus;
  transactionsDatesForm: FormGroup
  transactions: Transaction[] | null;
  cardState = CardState;
  registerClientIsLoading = false;
  cardIssueType = CardIssueType;
  cardTypeOptions = [];
  selectedCardType: CardIssueType | null = null;
  hasOpenLoans = false;

  constructor(
    private clientCardService: PlPiClientCardService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private clientService: PlPiClientService,
    private loanService: LoanService,
  ) {
    super();
  }

  clientInfoCardSystemTransform1: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['clientId', ['Client Id', NUMBER]],
      ['langCode', ['Language', STRING]],
      ['firstName', ['Name', STRING]],
      ['lastName', ['Lastname', STRING]],
      ['rStreetLine1', ['Street 1', STRING]],
      ['rCity', ['City', STRING]],
      ['rState', ['State', STRING]],
      ['rZip', ['Zip', STRING]],
      ['rCountryCode', ['Country code', STRING]],
      ['mStreetLine1', ['Street 1', STRING]],
    ]
  );

  clientInfoCardSystemTransform2: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['mCity', ['City', STRING]],
      ['mState', ['State', STRING]],
      ['mZip', ['Zip', STRING]],
      ['mCountryCode', ['Country code', STRING]],
      ['cardHolderName', ['Card holder name', STRING]],
      ['email', ['Email', STRING]],
      ['mainPhone', ['Main phone', STRING]],
      ['dob', ['dob', STRING]],
      ['ddStatus', ['ddStatus', STRING]],
      ['supervisorId', ['Supervisor Id', NUMBER]]
    ]
  );

  ngOnInit(): void {
    this.cardTypeOptions = Object.keys(this.cardIssueType);
    this.clientCardState = this.client.cardState;
    this.transactionsDatesForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
    this.refresh();
  }


  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [this.client.savedId.toString()]
      })
      .addFilterData({
        propertyName: 'status',
        type: "enum",
        enumClass: "eu.twino.loans.core.loan.model.loan.LoanStatus",
        operation: FilterOperation.EQUALS,
        values: ["OPEN"]
      })
      .withJoinStrategy(JoinStrategy.AND)
      .build()

    forkJoin([
      this.clientCardService.getCardsInfo(this.client?.savedId),
      this.clientCardService.getClientInfoCardSystem(this.client?.savedId),
      this.clientService.getClient(this.client?.savedId),
      this.loanService.find(searchQuery)
    ])
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(([getCards, clientInfoCardSystem, client, loans]) => {
        this.clientCardState = client.cardState;
        this.hasOpenLoans = (loans.totalRecords > 0);
        this.showRegisterButton = getCards.cards.length === 0;
        this.clientCardsInfo = getCards.cards;
        this.clientInfoCardSystem = clientInfoCardSystem;
        if(this.clientCardsInfo.length > 0) {
          this.showTransactionsTable = true;
          this.showUpdateButton = true;
        }
      });
  }


  registerClient() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Register client";
    modalRef.componentInstance.content = "Are you sure you want to register this client?";
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.registerClientIsLoading = true;
          this.clientCardService.registerClient(this.client?.savedId)
            .pipe(
              finalize(() => {
                this.registerClientIsLoading = false;
              }),
              takeUntil(this.$destroy)
            )
            .subscribe(() => {
              this.refresh();
            });
        }
      }, () => {}
    );
  }

  createCard() {
    const modalRef = this.modalService.open(PlPiClientCardAddComponent);
    modalRef.componentInstance.clientId = this.client?.savedId;
    modalRef.componentInstance.cardType = this.selectedCardType;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.refresh();
        }
      }, () => {}
    );
  }

  freezeCard(cardId: number) {
    const modalRef = this.modalService.open(PlPiClientCardChangeStatusComponent);
    modalRef.componentInstance.cardId = cardId;
    modalRef.componentInstance.state = CardChangeState.FREEZE;
    modalRef.componentInstance.title = "Freeze card";
    modalRef.result.then(
      () => {
        this.refresh();
      }, () => {}
    );
  }

  unfreezeCard(cardId: number) {
    const modalRef = this.modalService.open(PlPiClientCardChangeStatusComponent);
    modalRef.componentInstance.cardId = cardId;
    modalRef.componentInstance.state = CardChangeState.UNFREEZE;
    modalRef.componentInstance.title = "Unfreeze card";
    modalRef.result.then(
      () => {
        this.refresh();
      }, () => {}
    );
  }

  closeCard(cardId: number) {
    const modalRef = this.modalService.open(PlPiClientCardChangeStatusComponent);
    modalRef.componentInstance.cardId = cardId;
    modalRef.componentInstance.state = CardChangeState.CLOSE;
    modalRef.componentInstance.title = "Close card";
    modalRef.result.then(
      () => {
        this.refresh();
      }, () => {}
    );
  }


  renounceCard() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.title = "Renounce card";
    modalRef.componentInstance.content = "Are you sure you want to renounce card?";
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.clientCardService.renounceCard(this.client?.savedId)
            .pipe(
              takeUntil(this.$destroy)
            )
            .subscribe(() => {
              this.refresh();
            });
        }
      }, () => {}
    );
  }

  activateCard() {
    const modalRef = this.modalService.open(PlPiClientCardOtpComponent);
    modalRef.componentInstance.clientId = this.client?.savedId;
    modalRef.result.then(
      () => {
        this.refresh();
      }, () => {}
    );
  }

  sendPin() {
    const modalRef = this.modalService.open(PlPiClientCardSendPinComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.client?.savedId;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.refresh();
        }
      }, () => {}
    );
  }

  updateDiPocketClient() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Update client";
    modalRef.componentInstance.content = "Are you sure you want to update client?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.clientCardService.updateDiPocketClient(this.client?.savedId).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.refresh();
        });
      }, () => {}
    );
  }

  viewTransactionDetails(detailsRef: string) {
    const modalRef = this.modalService.open(PlPiTransactionDetailsComponent);
    modalRef.componentInstance.detailsRef = detailsRef;
    modalRef.componentInstance.clientId = this.client?.savedId;
  }

  viewCardHistory() {
    const modalRef = this.modalService.open(PlPiClientCardHistoryComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.clientId = this.client?.savedId;
  }

  getTransactions() {
    this.clientCardService.getTransactions(
      this.client?.savedId,
      this.transactionsDatesForm.value
    ).pipe(
      take(1)
    ).subscribe(transactionsInfo => {
      this.transactions = transactionsInfo.transactions;
    })
  }

  setCardType(event: any) {
    this.selectedCardType = event.target.value;
  }

  showActivateCardButton(card: CardInfo): boolean {
    return card.type === this.cardIssueType.PLASTIC &&
      (this.clientCardState === this.cardState.PLASTIC_ORDERED || this.clientCardState === this.cardState.VIRTUAL_ACTIVE_PLASTIC_ORDERED)
  }
}
