import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { OfferPreview } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class ApplicationRefreshService implements OnDestroy{

  public reloadOfferModel$ = new Subject<OfferPreview>();
  public reloadApplicationModel$ = new Subject<void>();

  reloadOfferModel(offerPreview: OfferPreview) {
    this.reloadOfferModel$.next(offerPreview);
  }

  reloadApplicationModel() {
    this.reloadApplicationModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadOfferModel$.unsubscribe();
    this.reloadApplicationModel$.unsubscribe();
  }
}
