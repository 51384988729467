import { Component, Input, OnInit } from '@angular/core';
import { AlertsService, BasicComponent } from '@backoffice-monorepo/commons';
import { CountrySpecific } from '../../../../../../libs/commons/src/lib/services/country-specific';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { PlPiLoanService } from '../../services/pl-pi-loan.service';
import { FullLoan } from '@twino/backoffice-api';
import { LoansRefreshService } from '../../../../../../libs/core/src/lib/modules/loans/services/loans-refresh-services';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-mtp-abatement',
  templateUrl: './pl-pi-loan-mtp-abatement.component.html',
  styleUrls: ['./pl-pi-loan-mtp-abatement.component.css']
})
export class PlPiLoanMtpAbatementComponent extends BasicComponent implements OnInit, CountrySpecific {

  @Input() loan: FullLoan
  abateForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private loanService: PlPiLoanService,
    private formBuilder: FormBuilder,
    private loansRefreshService: LoansRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.abateForm = this.formBuilder.group({
      scheduleItemId: ['', Validators.required],
    });
  }

  submitForm() {
    this.loanService.abateMtpPrincipal(this.loan.id, this.abateForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Abatement successful`);
      this.loansRefreshService.reloadLoanModel();
      this.activeModal.close();
    })
  }
}
