import { Injectable, Type } from '@angular/core';
import { CountryViewComponent, PaymentButtonsViewComponentResolver } from '@backoffice-monorepo/commons';
import { PlPiPaymentButtonViewComponent } from '../components/pl-pi-payment-button-view/pl-pi-payment-button-view.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiPaymentButtonViewComponentResolverService implements PaymentButtonsViewComponentResolver {

  resolve(): Type<CountryViewComponent> {
    return PlPiPaymentButtonViewComponent;
  }
}
