import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, PaymentService } from '@backoffice-monorepo/commons';
import { CreatePaymentDistributionRequest, PaymentStatus, TransactionTypes } from '@twino/backoffice-api';
import { PaymentsRefreshService } from '../../services/payments-refresh.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'payment-distribution-create',
  templateUrl: './payment-distribution-add.component.html',
  styleUrls: ['./payment-distribution-add.component.scss']
})
export class PaymentDistributionAddComponent extends BasicComponent implements OnInit, OnDestroy {
  @Input() paymentId: number;
  @Input() paymentStatus: PaymentStatus;

  paymentDistributionAddForm: FormGroup;
  transactionTypes = TransactionTypes;
  transactionTypesOptions = [];
  showLoanInput = false;
  showClientInput = false;
  showClientInputRequired = true;
  allowedTransactionTypes$: Observable<TransactionTypes>;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private paymentsRefreshService: PaymentsRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allowedTransactionTypes$ = this.paymentService.getAllowedTransactionTypes();
    this.transactionTypesOptions = Object.keys(this.transactionTypes);
    this.paymentDistributionAddForm = this.formBuilder.group({
      transactionType: ['', Validators.required],
      amount: ['', Validators.required],
      surplusAmount: ['', Validators.required]
    });
    this.paymentDistributionAddForm.get('surplusAmount').setValue(0);

    if (this.paymentStatus === PaymentStatus.MANUAL_PROCESSING) {
      this.paymentDistributionAddForm.addControl('clientId', new FormControl('', Validators.required));
      this.showClientInput = true;
    }
  }

  onChangeTransactionType() {
    const selectedValue = this.paymentDistributionAddForm.value.transactionType;
    if (selectedValue === TransactionTypes.SOLD_LOAN || selectedValue === TransactionTypes.SURPLUS || selectedValue === TransactionTypes.REPAYMENT) {
      this.paymentDistributionAddForm.addControl('loanId', new FormControl('', Validators.required));
      this.showLoanInput = true;
    } else {
      this.paymentDistributionAddForm.removeControl('loanId');
      this.showLoanInput = false;
    }

    if (this.paymentStatus === PaymentStatus.MANUAL_PROCESSING) {
      if (selectedValue === TransactionTypes.FAULTY ||
        selectedValue === TransactionTypes.INTRA_COMPANY_TRANSFER ||
        selectedValue === TransactionTypes.INVALID_PAYMENT || selectedValue === TransactionTypes.BANK_COMMISSION) {
        this.showClientInputRequired = false;
        this.paymentDistributionAddForm.get('clientId').clearValidators();
      } else {
        this.showClientInputRequired = true;
        this.paymentDistributionAddForm.get("clientId").setValidators([Validators.required]);
      }
    }
  }

  submitForm() {
    const formValues = this.paymentDistributionAddForm.value;
    const createPaymentDistributionRequest: CreatePaymentDistributionRequest = {
      transactionType: formValues.transactionType,
      amount: formValues.amount,
      surplusAmount: formValues.surplusAmount,
      clientId: formValues.clientId,
      loanId: formValues.loanId ? formValues.loanId : null,
    }
    this.paymentService.createDistribution(this.paymentId, createPaymentDistributionRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Payment distribution added`);
      this.paymentsRefreshService.reloadPaymentModel();
      this.activeModal.close();
    })
  }
}
