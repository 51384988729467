export interface CreateLoanTerminationAgreementRequest {
  loanStatusDetail: string
  terminationStartDate: Date
}

export enum LoanStatusDetailForClosure {
  PENDING_TERMINATION_BY_COMPANY = 'PENDING_TERMINATION_BY_COMPANY',
  PENDING_TERMINATION_BY_CLIENT = 'PENDING_TERMINATION_BY_CLIENT',
  AML_TERMINATION_BY_COMPANY = 'AML_TERMINATION_BY_COMPANY'
}

export interface AbateMtpRequest {
  scheduleItemId: number
}

export enum PlBrand {
  NETCREDIT = 'NETCREDIT',
  HALVO = 'HALVO'
}
