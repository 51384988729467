import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersListComponent } from './components/users-list/users-list.component';
import { AuthoritiesListComponent } from './components/authorities/authorities-list/authorities-list.component';
import { RolesListComponent } from './components/roles/roles-list/roles-list.component';

const routes: Routes = [
  {path: '', component: UsersListComponent},
  {
    path: 'authorities',
    component: AuthoritiesListComponent
  },
  {
    path: 'roles',
    component: RolesListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
