import { Injectable, Type } from '@angular/core';
import { CountryViewComponent } from '@backoffice-monorepo/commons';
import { PlPiPaymentDistributionsTableComponent } from '../components/pl-pi-payment-distributions-table/pl-pi-payment-distributions-table.component';
import {
  PaymentDistributionsTableComponentResolver
} from '@backoffice-monorepo/commons';


@Injectable({
  providedIn: 'root'
})
export class PlPiPaymentDistributionsTableComponentResolverService implements PaymentDistributionsTableComponentResolver {

  resolve(): Type<CountryViewComponent> {
    return PlPiPaymentDistributionsTableComponent;
  }
}
