import { Component, ViewChild } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { map, startWith, take } from 'rxjs/operators';
import { TranslationBuilderService } from '../../services/translation-builder.service';

@Component({
  selector: 'backoffice-monorepo-add-language-modal',
  templateUrl: './add-language-modal.component.html',
  styleUrls: ['./add-language-modal.component.scss']
})
export class AddLanguageModalComponent {
  form: FormGroup;
  editorOptions: JsonEditorOptions;
  
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  $langs = this.translationBuilder.$langs.pipe(
    startWith([])
  )

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private translationBuilder: TranslationBuilderService) {
      this.createForm();
      this.setEditorOptions();
  }

  private createForm() {
    const existingLangValidator: AsyncValidatorFn = (control) => this.translationBuilder.$langs.pipe(
      map(langs => langs.includes(control.value) ? {languageExists: true} : null),
      take(1),
    );

    this.form = this.formBuilder.group({
      langCode: new FormControl('', {validators: [Validators.minLength(2), Validators.maxLength(5), Validators.required], asyncValidators: [existingLangValidator]}),
      translationJSON: new FormControl({})
    });
  }

  private setEditorOptions() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes =  ['code', 'tree'];
    this.editorOptions.mode = 'code';
  }
}
