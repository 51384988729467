import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, ClientService, SystemConfigService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { AddDiscountCampaignToClientRequest } from '@twino/backoffice-api';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-discount-add',
  templateUrl: './client-discount-add.component.html',
  styleUrls: ['./client-discount-add.component.css']
})
export class ClientDiscountAddComponent extends BasicComponent implements OnInit {

  @Input() clientId: number;

  clientDiscountAddForm: FormGroup;
  termDaysOptions: Array<number> | null;
  discountPercentOptions:  Array<number> | null;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private clientsRefreshService: ClientsRefreshService,
    private systemConfigService: SystemConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientDiscountAddForm = this.formBuilder.group({
      termDays: ['', Validators.required],
      discountPercent: ['', Validators.required]
    });
    this.systemConfigService.getSystemConfigRecordByName('client_manual_campaign_config').pipe(
      take(1)
    )
      .subscribe(manualCampaignConfig => {
        this.termDaysOptions = JSON.parse(manualCampaignConfig.data)['allowedTerms'];
        this.discountPercentOptions = JSON.parse(manualCampaignConfig.data)['allowedPercents'];
        const addDiscountCampaignToClientRequest: AddDiscountCampaignToClientRequest = {
          termDays: this.termDaysOptions[0],
          discountPercent: this.discountPercentOptions[0]
        };
        this.clientDiscountAddForm.setValue(addDiscountCampaignToClientRequest);
      })
  }

  submitForm() {
    this.clientService.assignDiscountCampaignToClient(this.clientId, this.clientDiscountAddForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Discount assigned to client`);
      this.clientsRefreshService.reloadClientModel();
      this.activeModal.close();
    })
  }
}
