<span class="mr-2" *ngIf="data?.status !== 'CLOSED'">
  <button appRequirePermission="admin.all,loan-application.edit" class="btn btn-danger btn-sm" (click)="manualCancel()">
    <bi name="x-square"></bi>
    Cancel
  </button>
</span>

<span class="mr-2"  *ngIf="data?.flowState === 'OFFER_ACCEPTED'">
  <button appRequirePermission="admin.all,loan-application.edit"
          class="btn btn-success btn-sm"
          routerLink="/tariff/application/{{data?.id}}">
    <bi name="check"></bi>
    Activate tariff
  </button>
</span>

<span class="mr-3"  *ngIf="data?.flowState === 'OFFER_GRANTED'">
  <select (change)="changeCardType($event)">
          <option>Choose the type</option>
          <option *ngFor="let key of cardTypeOptions" [ngValue]="key" [selected]="key === applicationCardType">{{key}}</option>
  </select>
</span>
