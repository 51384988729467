import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { RxStomp } from '@stomp/rx-stomp';
import { v4 as uuid_v4 } from "uuid";
import { pluck, takeWhile } from 'rxjs/operators';
import { Observable } from 'rxjs';

const TERMINATE_MESSAGE = "0ca58e2b-terminate-4201-830f-4a62d9234ff3";
const protocolPrefix = (window.location.protocol === 'https:') ? 'wss:' : 'ws:';

@Injectable({
  providedIn: 'root'
})
export class KonsoleService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  private static isNotTerminationMessage(message): boolean {
    return message.body !== TERMINATE_MESSAGE;
  }

  executeScript(script): Observable<string> {
    const executionId = uuid_v4();

    const stompClient = new RxStomp()
    stompClient.configure({
      brokerURL: `${protocolPrefix}//${window.location.hostname}:${window.location.port}/ws/konsole`,
      debug: window.console.debug,
      reconnectDelay: 200,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000
    });

    stompClient.activate();

    stompClient.publish({
      destination: `/execute/${executionId}`,
      body: script
    });

    return stompClient.watch(`/topic/${executionId}`)
      .pipe(
        takeWhile(KonsoleService.isNotTerminationMessage)
      )
      .pipe(
        pluck('body')
      );
  };
}
