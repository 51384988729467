import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {
  @Input() isLoading: boolean;
  @Input() iconName: string;
  @Input() buttonClass: string;
  @Input() disabled: boolean;
  @Input() spinnerClass = 'spinner-border spinner-border-sm text-secondary';
}
