import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import {
  EnumResponse,
  SearchQuery,
  SearchQueryAware,
  SearchResponse,
  BatchJobExecutionDetails,
  BatchJobExecution,
  BatchJobDetails,
  BatchJobRunResul
} from '@backoffice-monorepo/api';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';

@Injectable({
  providedIn: 'root'
})
export class BatchJobService extends BaseService implements SearchQueryAware<BatchJobExecutionDetails> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<BatchJobExecutionDetails>> {
    return this.post(`/api/batch/job/execution/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/batch/job/enum-values/${enumClass}`);
  }

  getBatchJobExecution(id: number): Observable<BatchJobExecution> {
    return this.get(`/api/batch/job/execution/${id}`);
  }

  getJobList(): Observable<string[]> {
    return this.get('/api/batch/job/list');
  }

  runJob(request: BatchJobDetails): Observable<BatchJobRunResul> {
    return this.post(`/api/batch/job/run`, request);
  }

  stopJob(request: BatchJobDetails): Observable<BatchJobRunResul> {
    return this.post(`/api/batch/job/stop`, request);
  }
}

