<aside class="container-fluid pt-3 pb-3 pl-0">
  <div class="col-md-12 pl-1 ml-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
    <!-- region buttons -->
      	<span class="mr-2 ml-3">
            <button appRequirePermission="admin.all,client.all,client.edit-personal-information,client.edit-personal-id-birthdate,client.edit-mobile-number,client.edit-email"
                    class="btn btn-primary btn-sm"
                    (click)="editClient()">
              <bi name="pencil-square"></bi>
              Edit
            </button>
        </span>
        <span class="mr-2">
            <button appRequirePermission="client.all,client.edit,admin.all" class="btn btn-primary btn-sm" (click)="editClientAddress()">
              <bi name="pencil-square"></bi>
              Edit Address
            </button>
        </span>
        <span class="mr-2">
            <button appRequirePermission="client.all,client.edit,admin.all" class="btn btn-primary btn-sm" (click)="editClientIncome()">
              <bi name="pencil-square"></bi>
              Edit Income
            </button>
        </span>
        <span class="mr-2">
            <button appRequirePermission="client.all,client.edit,admin.all" class="btn btn-primary btn-sm" (click)="sendSms()">
              <bi name="chat-square-text"></bi>
              Send SMS
            </button>
        </span>
        <span class="mr-2">
            <button appRequirePermission="client.all,client.edit,admin.all" class="btn btn-primary btn-sm" (click)="sendEmail()">
              <bi name="envelope"></bi>
              Send Email
            </button>
        </span>
        <span class="mr-2">
            <button appRequirePermission="client.all,client.edit,admin.all" class="btn btn-primary btn-sm" (click)="uploadDocument()">
              <bi name="upload"></bi>
              Upload Document
            </button>
        </span>
        <span class="mr-2">
            <button appRequirePermission="admin.all,discount-campaign.all,client.discount-campaign.create"
                    class="btn btn-success btn-sm"
                    (click)="addClientDiscount()">
              <bi name="plus-circle"></bi>
              Add Discount
            </button>
        </span>
        <ndc-dynamic
          [ndcDynamicComponent]="clientButtonViewComponentType"
          [ndcDynamicInputs]="{data: client, lastApplication: lastApplication}"
        ></ndc-dynamic>
  </div>
  <!-- endregion -->
</aside>

<side-bar>
  <ul ngbNav class="nav nav-pills flex-column" #clientNav="ngbNav" [(activeId)]="active">
    <li ngbNavItem="Main data">
      <a ngbNavLink>{{ 'common.mainData' | translate }}</a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="clientMain"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="component.title" *ngFor="let component of libClientSidebarComponents">
      <a ngbNavLink>{{component.title}}</a>
      <ng-template ngbNavContent>
        <ndc-dynamic
          [ndcDynamicComponent]="component.type"
          [ndcDynamicInputs]="{client: client}"
        ></ndc-dynamic>
      </ng-template>
    </li>
    <li [ngbNavItem]="component.title" *ngFor="let component of countryClientSidebarComponents">
      <a ngbNavLink>{{component.title}}</a>
      <ng-template ngbNavContent>
        <ndc-dynamic
          [ndcDynamicComponent]="component.type"
          [ndcDynamicInputs]="{client: client}"
        ></ndc-dynamic>
      </ng-template>
    </li>
  </ul>
  <main [ngbNavOutlet]="clientNav"></main>
</side-bar>

<ng-template #clientMain>
  <div class="container-fluid row pb-2">
    <div class="container-fluid row pb-1">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header text-light bg-primary">
            <bi name="briefcase"></bi>
            Client
          </div>
          <div class="card-text row">
            <div class="col-md-12">
              <transform-table [transformInstructions]="clientTransformations" [entity]="client"></transform-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header text-light bg-primary">
            <bi name="pencil-square"></bi>
            Last application
          </div>
          <div class="card-text">
            <transform-table [transformInstructions]="lastApplicationTransformations" [entity]="lastApplication"></transform-table>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid row pt-2 pb-1">
      <div class="col-md-6">
              <div class="card">
                <div class="card-header text-light bg-primary">
                  <bi name="briefcase"></bi>
                  Client comments
                </div>
                <div class="card-text row">
                  <div class="col-md-12">
                    <form class="form-inline pb-2 pt-2" [formGroup]="addCommentForm" (ngSubmit)="submitCommentForm()">
                      <div class="form-group mx-sm-3 mb-2">
                        <textarea class="form-control form-control-sm"
                                  id="comment"
                                  formControlName="comment"
                                  name="comment"
                                  placeholder="Comment text"
                                  rows="1"
                                  cols="30"></textarea>
                      </div>
                      <button type="submit" class="btn btn-primary mb-2" [disabled]="!addCommentForm.valid">Add comment</button>
                    </form>
                  </div>
                </div>
              </div>

              <server-table>
                <table list-table class="table table-striped table-hover table-sm">
                  <thead class="text-light bg-primary">
                  <tr>
                    <th></th>
                    <th>{{ 'common.created' | translate }} </th>
                    <th>Created by </th>
                    <th>Comment</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of comments?.results; trackBy: trackById">
                    <td></td>
                    <td>{{ item.created }}</td>
                    <td>{{ item.commenterUser }}</td>
                    <td>{{ item.comment }}</td>
                  </tr>
                  </tbody>
                </table>
              </server-table>
              <div class="form-inline form-group" *ngIf="(comments?.totalRecords >= itemsPerPage)">
                <ngb-pagination class="pages"
                                size="sm"
                                [collectionSize]="comments?.totalRecords"
                                [page]="comments?.currentPage"
                                [pageSize]="itemsPerPage"
                                [maxSize]="10"
                                [rotate]="false"
                                [ellipses]="true"
                                [boundaryLinks]="false"
                                (pageChange)="pageChange($event)">
                  <ng-template ngbPaginationPrevious>Previous</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
              </div>

      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header text-light bg-primary">
            <bi name="pencil-square"></bi>
            Surplus balance
          </div>
          <div class="card-text">
            <table class="table table-striped table-hover table-sm mb-0">
              <colgroup>
                <col class="table-primary col-md-5"/>
                <col/>
              </colgroup>
              <tbody>
              <tr *ngFor="let item of clientBalances | keyvalue">
                <td class="text-right">{{item.key}}</td>
                <td>{{item.value}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
