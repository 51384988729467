<aside class="container-fluid pt-3 pb-3">
  <div class="col-md-12 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
    <!-- region buttons -->
    <span class="mr-2 ml-3">
          <button appRequirePermission="admin.all,payment.void"
                  class="btn btn-danger btn-sm"
                  *ngIf="showVoidPaymentButton"
                  (click)="voidPayment()">
            <bi name="pencil-square"></bi>
            Void payment
          </button>
    </span>
    <span class="mr-2 ml-3">
          <button appRequirePermission="admin.all,payment.create-manual,payment-distribution.create-manual"
                  class="btn btn-success btn-sm"
                  *ngIf="showCreateDistributionButton"
                  (click)="createDistribution()">
            <bi name="plus-circle"></bi>
            Create distribution
          </button>
    </span>
    <ndc-dynamic
      [ndcDynamicComponent]="paymentButtonViewComponentType"
      [ndcDynamicInputs]="{data: payment}"
    ></ndc-dynamic>
    <!-- endregion -->
  </div>
</aside>
<!-- region Payment -->
<div class="container-fluid row pt-1 pb-2">
  <div class="col-md-4">
    <div class="card">
      <div class="card-header text-light bg-primary">
        <bi name="briefcase"></bi>
        Payment
      </div>
      <div class="card-text">
        <transform-table [transformInstructions]="paymentTransformations" [entity]="payment"></transform-table>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <!-- region distributions -->
    <ndc-dynamic
      [ndcDynamicComponent]="paymentDistributionsTableComponentType"
      [ndcDynamicInputs]="{data: distributions, payment: payment}"
    ></ndc-dynamic>

    <!-- endregion -->
  </div>
</div>
<!--endregion -->






