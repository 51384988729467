import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DebtConfigRefreshService implements OnDestroy{

  public reloadDebtConfigModel$ = new Subject<void>();


  reloadDebtConfigModel() {
    this.reloadDebtConfigModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadDebtConfigModel$.unsubscribe();
  }
}
