import { ApplicationModule, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoansModule } from './modules/loans/loans.module';
import { ClientsModule } from './modules/clients/clients.module';
import { TasksModule } from './modules/tasks/tasks.module';
import { PaymentsModule } from './modules/payments/payments.module';
import { PaymentDistributionsModule } from './modules/payment-distributions/payment-distributions.module';
import { TransfersModule } from './modules/transfers/transfers.module';
import { CommunicationsModule } from './modules/communications/communications.module';
import { ActivityOverviewModule } from './modules/activity-overview/activity-overview.module';
import { SystemConfigsModule } from './modules/system-configs/system-configs.module';
import { ProductConfigsModule } from './modules/product-configs/product-configs.module';
import { RiskEvaluationConfigsModule } from './modules/risk-evaluation-configs/risk-evaluation-configs.module';
import { MessageTemplatesModule } from './modules/message-templates/message-templates.module';
import { FileTemplatesModule } from './modules/file-templates/file-templates.module';
import { NotificationConfigsModule } from './modules/notification-configs/notification-configs.module';
import { TranslationManagerModule } from './modules/translation-manager/translation-manager.module';
import { DiscountCampaignsModule } from './modules/discount-campaigns/discount-campaigns.module';
import { BlacklistModule } from './modules/blacklist/blacklist.module';
import { AboutModule } from './modules/about/about.module';
import { BatchJobModule } from './modules/batch-job/batch-job.module';
import { ApplicationWithoutConfirmedOtpModule } from './modules/application-without-confirmed-otp/application-without-confirmed-otp.module';
import { KonsoleModule } from './modules/konsole/konsole.module';


@NgModule({
  imports: [CommonModule],
  exports: [
    ClientsModule,
    LoansModule,
    TasksModule,
    ApplicationModule,
    PaymentsModule,
    PaymentDistributionsModule,
    TransfersModule,
    CommunicationsModule,
    ActivityOverviewModule,
    SystemConfigsModule,
    ProductConfigsModule,
    RiskEvaluationConfigsModule,
    MessageTemplatesModule,
    FileTemplatesModule,
    NotificationConfigsModule,
    TranslationManagerModule,
    DiscountCampaignsModule,
    BlacklistModule,
    AboutModule,
    KonsoleModule,
    BatchJobModule,
    ApplicationWithoutConfirmedOtpModule
  ]
})
export class CoreModule {}
