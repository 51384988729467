import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateTimePickerResetService implements OnDestroy{

  public resetFilterContent$ = new Subject<string>();

  resetFilterContent(inputName: string) {
    this.resetFilterContent$.next(inputName);
  }

  ngOnDestroy(): void {
    this.resetFilterContent$.unsubscribe();
  }
}
