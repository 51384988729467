import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RiskEvaluationConfigsListComponent } from './components/risk-evaluation-configs-list/risk-evaluation-configs-list.component';
import { RiskEvaluationConfigComponent } from './components/risk-evaluation-config/risk-evaluation-config.component';
import { RiskEvaluationFlowsListComponent } from './components/risk-evaluation-flows-list/risk-evaluation-flows-list.component';


const routes: Routes = [
  {path: '', component: RiskEvaluationFlowsListComponent, pathMatch: 'full'},
  {path: ':flowId/steps', component: RiskEvaluationConfigsListComponent},
  {path: ':flowId/steps/:id/rules', component: RiskEvaluationConfigComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RiskEvaluationConfigsRoutingModule {
}
