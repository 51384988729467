import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ErrorsService } from './errors.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAuthority } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getAuthorities(): Observable<UserAuthority[]> {
    return this.get('/api/user-authorities');
  }

  createAuthority(name: string) {
    return this.post('/api/user-authority', name);
  }

  deleteAuthority(id: number) {
    return this.delete(`/api/user-authority/${id}`);
  }
}
