import { Component, Host, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SearchQueryAware,
  ApplicationWithUnconfirmedEmailOtpResponse
} from '@backoffice-monorepo/api';
import {
  FilteringService,
  ListComponent,
  ApplicationWithoutConfirmedOtpService
} from '@backoffice-monorepo/commons';

@Component({
  selector: 'backoffice-monorepo-application-without-confirmed-otp-list',
  templateUrl: './application-without-confirmed-otp-list.component.html',
  styleUrls: ['./application-without-confirmed-otp-list.component.scss'],
  providers: [
    {
    provide: FilteringService,
    useFactory: (listService: SearchQueryAware<ApplicationWithUnconfirmedEmailOtpResponse>) => new FilteringService(listService),
    deps: [ApplicationWithoutConfirmedOtpService]
  }
]
})
export class ApplicationWithoutConfirmedOtpListComponent extends ListComponent<ApplicationWithUnconfirmedEmailOtpResponse> implements OnInit {
  constructor(
    @Host() filteringService: FilteringService<ApplicationWithUnconfirmedEmailOtpResponse>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
 }

  getName(): string {
    return 'Application without confirmed otp';
  }

  navigateTo(id: number) {
    this.router.navigate([`/application-without-confirmed-otp/${id}`]);
  }
}
