<div class="container-fluid row pt-1 pb-2 pl-0">
  <div class="col-md-12 pl-0">
      <div class="card">
        <div class="card-header text-light bg-primary card">
          Risk Level
        </div>
        <div class="card-body">
          <table class="table table-hover mt-2">
            <tbody>
            <tr>
              <td>AML client ID</td>
              <td>{{amlInfo?.amlClientId}}</td>
            </tr>
            <tr>
              <td>AML points</td>
              <td>{{amlInfo?.score}}</td>
            </tr>
            <tr>
              <td>Risk level</td>
              <td>{{amlInfo?.rating}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header text-light bg-primary card">
          AML rules
        </div>
        <div class="card-body">
          <div class="m-2" *ngFor="let item of amlInfo?.ruleGroups | keyvalue; trackBy: trackById">
            <p class="m-2">
              <b>{{item.key}}</b>
            </p>
            <table class="table table-striped table-hover mt-2">
              <thead class="text-light bg-primary">
              <tr>
                <th>Rule name</th>
                <th>Rule error</th>
                <th>Points</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let rule of item.value; trackBy: trackById">
                <td>{{rule.name}}</td>
                <td>{{rule.error}}</td>
                <td>{{rule.score}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header text-light bg-primary card">
          AML client data
        </div>
        <div class="card-body">
          <div class="alert-danger mb-3 text-center" *ngIf="amlClientData.length > 1">
            More than one client data found in AML
          </div>
          <div *ngFor="let amlClientDataItem of amlClientData">
            <p class="mb-2">
              <b>{{amlClientDataItem.amlClientId}}</b>
            </p>
            <table class="table table-hover mb-4">
              <tbody>
              <tr>
                <td>Data processing disabled</td>
                <td>{{amlClientDataItem.dataProcessingDisabled}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</div>

