import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertsService, DebtConfigService, NamedComponent, ViewHistoryService } from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import {
  DebtStepConfig,
  DebtStepConfigRequest,
  DebtStepRuleConfig,
  FilterOperation, JoinStrategy,
  ProductConfig,
  SearchQueryBuilder,
  SearchResponse
} from '@twino/backoffice-api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DebtConfigRefreshService } from '../../services/debt-config-refresh.service';
import { take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-debt-config',
  templateUrl: './debt-config.component.html',
  styleUrls: ['./debt-config.component.scss']
})
export class DebtConfigComponent extends NamedComponent implements OnInit, OnDestroy {

  id: number | null;
  debtConfigForm: FormGroup;
  control: FormArray;
  ruleTypes: string[];
  debtStepConfig: DebtStepConfig;
  debtConfigRequest: DebtStepConfigRequest;
  debtStepName: string | null;
  tabTitle: string;
  $destroy = new Subject<boolean>();
  dcVersionCounter = Array;

  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, {static: false}) configEditor: JsonEditorComponent;

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private viewHistoryService: ViewHistoryService,
    private debtConfigService: DebtConfigService,
    private debtConfigRefreshService: DebtConfigRefreshService,
    private productConfig: ProductConfig,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.tabTitle = (this.id > 0) ? `Update #${this.id}` : `Create`;
    this.debtConfigService.listRuleTypes().pipe(
      take(1)
    ).subscribe((ruleNames) => {
      this.ruleTypes = ruleNames;
    })
    this.debtConfigForm = this.formBuilder.group({
      debtStepName: ['', Validators.required],
      dpd: '',
      dli: '',
      odpd: '',
      isEnabled: [false],
      promiseDay: '',
      dcVersion:  ['', Validators.required],
      cardRows: this.formBuilder.array([])
    });
    if (this.id > 0) {
      this.debtConfigService.getConfig(this.id).pipe(
        take(1)
      ).subscribe(debtStepConfigById => {
        this.debtStepConfig = debtStepConfigById;
        this.debtStepName = debtStepConfigById.debtStepName;
        const debtStepConfigData = {
          debtStepName: debtStepConfigById.debtStepName,
          dpd: debtStepConfigById.dpd,
          dli: debtStepConfigById.dli,
          odpd: debtStepConfigById.odpd,
          isEnabled: debtStepConfigById.isEnabled,
          promiseDay: debtStepConfigById.promiseDay,
          dcVersion: debtStepConfigById.dcVersion,
          cardRows: []
        }
        this.debtConfigForm.setValue(debtStepConfigData);
        this.setRows(debtStepConfigById);
      })
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  getName(): string {
    return `${this.tabTitle} debt step config`;
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      ruleName: ['empty', Validators.required],
      debtRuleClassName: ['', Validators.required],
      params: '',
      isEditableInput: [false]
    });
  }

  setFormGroupValues(values: DebtStepRuleConfig) {
    const formGroupRule = this.createForm();
    formGroupRule.setValue({
      ruleName: values.ruleName,
      debtRuleClassName: values.debtRuleClassName,
      params: values.params,
      isEditableInput: false
    });
    return formGroupRule;
  }

  get getFormControls() {
    return this.debtConfigForm.get('cardRows') as FormArray;
  }

  setRows(debtStepConfiguration: DebtStepConfig) {
    const formControl = this.debtConfigForm.get('cardRows') as FormArray;
    this.debtStepConfig = debtStepConfiguration;
    if (debtStepConfiguration.debtRules) {
      debtStepConfiguration.debtRules.forEach((values) => {
        formControl.push(this.setFormGroupValues(values));
      });
    }
  }

  addRow() {
    const control = this.debtConfigForm.get('cardRows') as FormArray;
    control.push(this.createForm());
  }

  deleteRow(index: number) {
    const control = this.debtConfigForm.get('cardRows') as FormArray;
    control.removeAt(index);
  }

  edit(group: AbstractControl, controlName: string) {
    group.get(controlName).setValue(true);
  }

  close(group: AbstractControl, controlName: string) {
    group.get(controlName).setValue(false);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getFormControls.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.debtConfigForm.value.cardRows, event.previousIndex, event.currentIndex);
  }

  makeOptions = () => {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = this.productConfig.jsonEditorModes;
    return this.editorOptions;
  }

  submitForm() {
    const ruleConfigs = this.debtConfigForm.value.cardRows;
    ruleConfigs.forEach((rule) => {
      delete rule.isEditableInput;
    });
    this.debtConfigRequest = {
      debtStepName: this.debtConfigForm.value.debtStepName,
      dpd: this.debtConfigForm.value.dpd,
      dli: this.debtConfigForm.value.dli,
      odpd: this.debtConfigForm.value.odpd,
      isEnabled: this.debtConfigForm.value.isEnabled,
      debtRules: ruleConfigs,
      promiseDay: this.debtConfigForm.value.promiseDay,
      dcVersion: this.debtConfigForm.value.dcVersion
    }

    if (this.id > 0) {
      this.updateDebtStepConfig();
    } else {
      this.validateDpd().subscribe((checkDpd) => {
        if (checkDpd.results.length === 0) {
          this.addDebtStepConfig();
        } else {
          this.alertService.notifyError(
            `DPD with value ${this.debtConfigForm.value.dpd} already exists for DC version ${this.debtConfigForm.value.dcVersion}!`
          );
        }
      })
    }
  }

  addDebtStepConfig() {
    this.debtConfigService.addDebtStep(this.debtConfigRequest).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(() => {
        this.alertService.notifySuccess(`Debt step configuration  ${this.debtConfigForm.value.debtStepName} added`);
        this.viewHistoryService.closeTab();
        this.debtConfigRefreshService.reloadDebtConfigModel();
      })
  }

  updateDebtStepConfig() {
    this.debtConfigService.updateDebtStep(
      this.id,
      this.debtConfigRequest).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(() => {
        this.alertService.notifySuccess(`Debt step configuration  ${this.debtConfigForm.value.debtStepName} updated`);
        this.viewHistoryService.closeTab();
        this.debtConfigRefreshService.reloadDebtConfigModel();
      })
  }

  trackById(index, item){
    return item.value.ruleName;
  }

  validateDpd():  Observable<SearchResponse<DebtStepConfig>> {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'dcVersion',
        operation: FilterOperation.EQUALS,
        values: [this.debtConfigForm.value.dcVersion.toString()]
      })

    if(this.debtConfigForm.value.dpd) {
      searchQuery.addFilterData({
        propertyName: 'dpd',
        operation: FilterOperation.EQUALS,
        values: [this.debtConfigForm.value.dpd.toString()]
      }).withJoinStrategy(JoinStrategy.AND)
    }

    if(this.debtConfigForm.value.odpd) {
      searchQuery.addFilterData({
        propertyName: 'odpd',
        operation: FilterOperation.EQUALS,
        values: [this.debtConfigForm.value.odpd.toString()]
      }).withJoinStrategy(JoinStrategy.AND)
    }

    return this.debtConfigService.find(searchQuery.build()).pipe(
      take(1)
    )
  }

  trackByIndex = (index: number): number => index;
}
