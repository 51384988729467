import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserRole } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent {
  form = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  })
  roles: UserRole[];
  selectedRoles: string[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) { }

  onSaveClick() {
    this.activeModal.close({
      ...this.form.value,
      roles: [...this.selectedRoles]
    });
  }

  selectRole(roleName: string) {
    if (this.selectedRoles.includes(roleName)) {
      this.selectedRoles = this.selectedRoles.filter(selectedRoleName => roleName !== selectedRoleName);
    } else {
      this.selectedRoles.push(roleName);
    }
  }
}
