import { Component, OnInit } from '@angular/core';
import { ClientSidebarComponent, TasksService } from '@backoffice-monorepo/commons';
import { FilterOperation, FullClient, SearchQueryBuilder, SearchResponse, Task } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-tasks',
  templateUrl: './client-tasks.component.html',
  styleUrls: ['./client-tasks.component.scss']
})
export class ClientTasksComponent implements ClientSidebarComponent, OnInit {
  client: FullClient;
  searchResults: SearchResponse<Task> | never;
  newPage = 1;
  itemsPerPage = 20;

  constructor(
    private tasksService: TasksService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [this.client.savedId.toString()]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()

    return this.tasksService.find(searchQuery).pipe(
      take(1)
    ).subscribe((tasks) => {
      this.searchResults = tasks;
    })
  }

  trackById(index, item: Task) {
    return item.id;
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }
}
