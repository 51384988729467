<form [formGroup]="clientDetailsEditForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Edit client details #{{clientId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="citizenship" class="col-sm-4 control-label">Citizenship</label>
      <div class="col-sm-8">
        <select
          ngbAutofocus
          class="form-control"
          id="citizenship"
          formControlName="citizenship"
        >
          <option
            *ngFor="let country of countries"
            [value]="country.code"
          >{{country.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="countryOfBirth" class="col-sm-4 control-label">Country Of Birth</label>
      <div class="col-sm-8">
        <select
          class="form-control"
          id="countryOfBirth"
          formControlName="countryOfBirth"
        >
          <option
            *ngFor="let country of countries"
            [value]="country.code"
          >{{country.name}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="taxResidence" class="col-sm-4 control-label">Tax Residence</label>
      <div class="col-sm-8">
        <input
          class="form-control form-control-sm"
          id="taxResidence"
          formControlName="taxResidence"
          name="taxResidence"
        >
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientDetailsEditForm.valid">Save</button>
  </div>
</form>

