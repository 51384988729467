import { AccountType, DiscountShortInfo, DiscountTarget, ProductType, Term } from '@twino/backoffice-api';

export interface Offer {
  id?: number
  clientId?: number
  applicationId?: number
  loanNumber?: string
  productConfigId: number
  productType: string
  startDate: Date
  purpose?: LoanOfferPurpose
  type?: LoanOfferType
  dueDate: Date
  principal: number
  minSelectablePrincipal?: number,
  maxSelectablePrincipal?: number,
  totalInterestAmount: number
  totalAmount: number
  term: Term
  schedule: OfferScheduleItemDto
  totalInterestDiscountAmount: number,
  appliedDiscounts: Map<DiscountTarget, DiscountShortInfo>
  interestRatePerYear: number
  commissionRateParameters: CommissionRateParameters[]
  annualPercentageRate: number
  monthlyPayment: number
  extensionFees: OfferExtensionFeeDto
  status: string
  lenderId: number
  productSubType?: string
  refinanceCommissionAmount?: number
  dcVersion: number
  brand: string
}

export interface OfferScheduleItemDto {
  principal: number
  interest: number
  loanCommission: number
  totalAmount: number
  discountAmount: number
  startDate: Date
  dueDate: Date
}

export interface OfferExtensionFeeDto {
  fee: number
  term: Term
  discounted: boolean
  extensionCommission: number
}

export interface CommissionRateParameters {
  accountType: AccountType
  commissionName: string
  ratePerYearInPercent: number
  taxRate: number
  ratePerDay: number
  ratePerMonthInPercent: number
}

export enum LoanOfferType  {
  BIGGER = 'BIGGER',
  SMALLER = 'SMALLER',
  ASKED = 'ASKED'
}

export enum LoanOfferPurpose {
  MAIN_AMOUNT = 'MAIN_AMOUNT',
  ADDITIONAL_AMOUNT = 'ADDITIONAL_AMOUNT',
  EXTENSION = 'EXTENSION'
}

export interface OfferPreview {
  clientId: number
  applicationId: number
  loanNumber?: string
  productConfigId: number
  productType: ProductType
  startDate: Date
  purpose: LoanOfferPurpose
  type?: LoanOfferType
  dueDate: Date
  principal: number
  minSelectablePrincipal?: number
  maxSelectablePrincipal?: number
  totalInterestAmount: number
  totalAmount: number
  term: Term
  schedule: OfferScheduleItemDto
  totalInterestDiscountAmount: number
  appliedDiscounts: Map<DiscountTarget, DiscountShortInfo>
  interestRatePerYear: number
  commissionRateParameters: CommissionRateParameters[]
  annualPercentageRate: number
  monthlyPayment: number
  extensionFees: OfferExtensionFeeDto
  status: string
  lenderId: number
  productSubType: string
  refinanceCommissionAmount?: number
  dcVersion: number
  brand: string
}
