import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OverlayService } from '../services/overlay.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  waitingResponses: Array<string> = [];

  constructor(
    private overlayService: OverlayService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.waitingResponses.length === 0) {
      this.overlayService.showOverlay();
    }

    this.waitingResponses.push(request.url);

    return next.handle(request).pipe(
      finalize(() => {
        this.waitingResponses = this.waitingResponses.filter(url => request.url !== url);
        if (this.waitingResponses.length === 0) {
          this.overlayService.hideOverlay()
        }
      })
    );
  }
}
