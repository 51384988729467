<form [formGroup]="clientForm" (ngSubmit)="submitForm()">
  <div class="modal-header">
    <h4 class="modal-title">
      Choose new client
    </h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('closed')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
      <label
        for="templateKey"
        class="col-sm-4 control-label">
        <strong>Client id</strong>
        <sup class="text-danger">*</sup>
      </label>
      <client-lookup (clientSelected)="selectClient($event)"></client-lookup>
  </div>

  <div class="modal-footer">
    <button type="button" autofocus class="btn btn-outline-secondary" (click)="modal.dismiss(false)">Cancel</button>
    <button type="submit" class="btn btn-danger" [disabled]="clientForm.invalid">Ok</button>
  </div>
</form>
