import { Injectable, Type } from '@angular/core';
import { CountryViewComponent, LoanButtonsViewComponentResolver } from '@backoffice-monorepo/commons';
import { PlPiLoanButtonViewComponent } from '../components/pl-pi-loan-button-view/pl-pi-loan-button-view.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiLoanButtonViewComponentResolverService implements LoanButtonsViewComponentResolver {

  resolve(): Type<CountryViewComponent> {
    return PlPiLoanButtonViewComponent;
  }
}
