import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FileTemplatesListComponent } from './components/file-templates-list/file-templates-list.component';
import { FileTemplateAddComponent } from './components/file-template-add/file-template-add.component';
import { FileTemplateEditComponent } from './components/file-template-edit/file-template-edit.component';
import { FileTemplateRevisionsComponent } from './components/file-template-revisions/file-template-revisions.component';


const routes: Routes = [
   {path: '', component: FileTemplatesListComponent, pathMatch: 'full'},
   {path: 'create', component: FileTemplateAddComponent, pathMatch: 'full'},
   {path: ':id', component: FileTemplateEditComponent},
   {path: 'revisions/:id', component: FileTemplateRevisionsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FileTemplatesRoutingModule {
}
