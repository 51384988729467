import { Component, Input, OnInit } from '@angular/core';
import { ClientEmploymentInfoRequest, EmploymentStatus } from '@twino/backoffice-api';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, ClientService } from '@backoffice-monorepo/commons';
import { ClientsRefreshService } from '../../../../../../libs/core/src/lib/modules/clients/services/clients-refresh.service';
import { PlPiClientService } from '../../services/pl-pi-client.service';
import { forkJoin } from 'rxjs';
import { CompanyData, PlPiClientDetails, PlPiClientDetailsRequest } from '../../api/pl-pi-client-details';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-employment-edit',
  templateUrl: './pl-pi-client-employment-edit.component.html',
  styleUrls: ['./pl-pi-client-employment-edit.component.css']
})
export class PlPiClientEmploymentEditComponent extends BasicComponent implements OnInit {
  @Input() clientId: number;

  clientEmploymentInfoRequest: ClientEmploymentInfoRequest;
  clientEmploymentEditForm: FormGroup;
  employmentStatus = EmploymentStatus;
  employmentStatusOptions = [];
  showTaxIdField = false;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService,
    private plPiClientService: PlPiClientService
  ) {
    super();
  }


  ngOnInit(): void {
    this.employmentStatusOptions = Object.values(this.employmentStatus);
    this.clientEmploymentEditForm = this.formBuilder.group({
      employmentStatus: '',
      workplace: '',
      workPhoneNumber: '',
      employmentDurationInMonth: ''
    });
    this.clientService.getClientEmploymentInfo(this.clientId).pipe(
      take(1)
    ).subscribe(employmentData => {
      this.clientEmploymentInfoRequest = {
        employmentStatus: employmentData.employmentStatus,
        workplace: employmentData.workplace,
        workPhoneNumber: employmentData.workPhoneNumber,
        employmentDurationInMonth: employmentData.employmentDurationInMonth,

      }
      this.clientEmploymentEditForm.setValue(this.clientEmploymentInfoRequest);
      if (employmentData.employmentStatus === EmploymentStatus.SELF_EMPLOYED) {
        this.clientEmploymentEditForm.addControl('employerTaxId', new FormControl('', Validators.required));
      }
    });
  }


  onChangeEmploymentStatus() {
    const selectedValue = this.clientEmploymentEditForm.value.employmentStatus;
    if (selectedValue === EmploymentStatus.SELF_EMPLOYED) {
      this.clientEmploymentEditForm.addControl('employerTaxId', new FormControl('', Validators.required));
      this.showTaxIdField = true;
    } else {
      this.clientEmploymentEditForm.removeControl('employerTaxId');
      this.showTaxIdField = false;
    }
  }

  submitForm() {
    this.clientService.updateClientEmploymentInfo(this.clientId, this.clientEmploymentEditForm.value).subscribe(() => {
      if (this.clientEmploymentEditForm.value.employerTaxId) {
        this.updateSelfEmployedClientCompanyData(this.clientEmploymentEditForm.value.employerTaxId);
      } else {
        this.refreshAndClose();
      }
    })
  }

  updateSelfEmployedClientCompanyData(employerTaxId) {
    forkJoin([
      this.clientService.getClientDetails(this.clientId),
      this.plPiClientService.getCompanyData(employerTaxId)
    ])
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(([clientDetails, companyData]: [PlPiClientDetails, CompanyData]) => {
        const updateClientDetailsRequest: PlPiClientDetailsRequest = {
          citizenship: clientDetails.citizenship,
          countryOfBirth: clientDetails.countryOfBirth,
          placeOfBirth: clientDetails.countryOfBirth,
          taxResidence: clientDetails.taxResidence,
          companyData: companyData
        }
        this.clientService.updateClientDetails(this.clientId, updateClientDetailsRequest).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.alertService.notifySuccess(`Client company data updated`);
          this.refreshAndClose();
        })
      });
  }

  refreshAndClose() {
    this.alertService.notifySuccess(`Employment information updated`);
    this.clientsRefreshService.reloadClientDetailsModel();
    this.activeModal.close();
  }
}
