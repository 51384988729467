export interface AffiliateConfiguration {
  id: number
  name: string
  token?: string
  partnerName?: string
  additionalParameterName?: string
  enabled: boolean
  redirectUrl?: string
  customTracker?: string
  created: Date
  reports: Map<AffiliateEvent, AffiliateConfigurationReport>
}

export interface AffiliateConfigurationReport {
  type: AffiliateConfigurationReportType
  url: string
}

export interface AffiliateConfigurationReportForm {
  event: AffiliateEvent,
  type: AffiliateConfigurationReportType
  url: string
}

export enum AffiliateConfigurationReportType {
  LOCAL = 'LOCAL',
  BACKEND = 'BACKEND'
}


export interface AffiliateConfigurationRequest {
  name: string
  token?: string
  partnerName?: string
  additionalParameterName?: string
  enabled: boolean
  redirectUrl?: string
  customTracker?: string
}


export interface AffiliateReport {
  id: number,
  clientId: number,
  name: string
  type: AffiliateEvent,
  token?: string
  partnerName?: string
  additionalParameter?: string
  loansCount: number
  created: Date
  updated: Date
  postbackUrl?: string
  customTracker?: string
}


export interface  UpdateAffiliateConfigurationReportsRequest {
  reportConfigurations?: Record<string, AffiliateConfigurationReport>
}

export enum AffiliateEvent {
  REGISTERED = 'REGISTERED',
  ALL_STEPS_FINISHED = 'ALL_STEPS_FINISHED',
  IDENTIFIED = 'IDENTIFIED',
  APPLICATION_APPROVED = 'APPLICATION_APPROVED',
  APPLICATION_REJECTED = 'APPLICATION_REJECTED',
  APPLICATION_CANCELLED = 'APPLICATION_CANCELLED',
  FIRST_LOAN = 'FIRST_LOAN',
  SECOND_LOAN = 'SECOND_LOAN',
  REPEATED_LOAN = 'REPEATED_LOAN',
  PHONE_CONFIRMED = 'PHONE_CONFIRMED',
  OUTGOING_PAYMENT = 'OUTGOING_PAYMENT',
  REPEATED_APPLICATION = 'REPEATED_APPLICATION'
}
