<div class="modal-header" cdkDrag cdkDragHandle cdkDragRootElement=".dragged-modal">
  <h4 class="modal-title">Edit client #{{clientId}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <ul ngbNav #nav="ngbNav" class="nav-tabs" id="addressTab" [(activeId)]="activeTabItem">
    <li [ngbNavItem]="1" appRequirePermission="client.all,admin.all,client.edit-personal-information">
      <a ngbNavLink>Personal info</a>
      <ng-template ngbNavContent>
        <div class="p-3">
          <form [formGroup]="clientEditPersonalInformationForm" (ngSubmit)="updateClientPersonalInfo()">
            <div class="form-group row">
              <label for="firstName" class="col-sm-4 control-label">First name<sup class="text-danger">*</sup></label>
              <div class="col-sm-8">
                <input ngbAutofocus type="text" class="form-control form-control-sm" id="firstName" formControlName="firstName" name="firstName">
              </div>
            </div>
            <div class="form-group row">
              <label for="lastName" class="col-sm-4 control-label">Last name<sup class="text-danger">*</sup></label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="lastName" formControlName="lastName" name="lastName">
              </div>
            </div>
            <div class="form-group row">
              <label for="title" class="col-sm-4 control-label">Title</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="title" formControlName="title" name="title">
              </div>
            </div>
            <div class="form-group row">
              <label for="gender" class="col-sm-4 control-label">Gender<sup class="text-danger">*</sup></label>
              <div class="col-sm-8">
                <select class="form-control form-control-sm" id="gender" formControlName="gender" name="gender">
                  <option *ngFor="let key of genderOptions" [ngValue]="key">{{key}}</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <button type="submit" class="btn btn-outline-danger" [disabled]="!clientEditPersonalInformationForm.valid">Save</button>
            </div>
          </form>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" appRequirePermission="client.all,admin.all,client.edit-personal-id-birthdate">
      <a ngbNavLink>PersonalId/birthdate</a>
      <ng-template ngbNavContent>
        <div class="p-3">
          <form [formGroup]="clientEditPersonalIdAndBirthdateForm" (ngSubmit)="updateClientPersonalIdAndBirthDate()">
            <div class="form-group row">
              <label for="personalId" class="col-sm-4 control-label">Personal ID<sup class="text-danger">*</sup></label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="personalId" formControlName="personalId" name="personalId">
              </div>
            </div>
            <div class="form-group row">
              <label for="dateOfBirth" class="col-sm-4 control-label">Date of birth<sup class="text-danger">*</sup></label>
              <div class="input-group col-sm-8">
                <input class="form-control form-control-sm"
                       id="dateOfBirth"
                       formControlName="dateOfBirth"
                       placeholder="yyyy-mm-dd"
                       name="dateOfBirth"
                       ngbDatepicker
                       #dateOfBirth="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="dateOfBirth.toggle()" type="button"></button>
                </div>
              </div>
            </div>
            <div class="form-row">
              <button type="submit" class="btn btn-outline-danger" [disabled]="!clientEditPersonalIdAndBirthdateForm.valid">Save</button>
            </div>
          </form>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" appRequirePermission="client.all,admin.all,client.edit-mobile-number">
      <a ngbNavLink>Phone</a>
      <ng-template ngbNavContent>
        <div class="p-3">
          <form [formGroup]="clientEditMobilePhoneForm" (ngSubmit)="updateClientPhone()">
            <div class="form-group row">
              <label for="mobilePhone" class="col-sm-4 control-label">Mobile phone<sup class="text-danger">*</sup></label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm"
                       id="mobilePhone"
                       formControlName="mobilePhone"
                       name="mobilePhone">
              </div>
            </div>
            <div class="form-row">
              <button type="submit" class="btn btn-outline-danger" [disabled]="!clientEditMobilePhoneForm.valid">Save</button>
            </div>
          </form>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" appRequirePermission="client.all,admin.all,client.edit-email">
      <a ngbNavLink>Email</a>
      <ng-template ngbNavContent>
        <div class="p-3">
          <form [formGroup]="clientEditEmailForm" (ngSubmit)="updateClientEmail()">
            <div class="form-group row">
              <label for="email" class="col-sm-4 control-label">Email address</label>
              <div class="col-sm-8">
                <input type="email" class="form-control form-control-sm"
                       id="email"
                       placeholder="name@example.com"
                       formControlName="email"
                       name="email">
              </div>
            </div>
            <div class="form-row">
              <button type="submit" class="btn btn-outline-danger" [disabled]="!clientEditEmailForm.valid">Save</button>
            </div>
          </form>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
</div>

