import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientIncomeDataRequest } from '@twino/backoffice-api';
import { AlertsService, BasicComponent, ClientService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'client-income-edit',
  templateUrl: './client-income-edit.component.html',
  styleUrls: ['./client-income-edit.component.scss']
})
export class ClientIncomeEditComponent extends BasicComponent implements OnInit {
  @Input() clientId: number;

  clientIncomeDataRequest: ClientIncomeDataRequest;
  clientIncomeEditForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService
  ) {
    super();
  }


  ngOnInit(): void {
    this.clientIncomeEditForm = this.formBuilder.group({
      monthlyIncome: ['', Validators.required],
      monthlyLiabilities: ['', Validators.required]
    });
    this.clientService.getClientIncomeInfo(this.clientId)
      .pipe(
        take(1)
      ).subscribe(incomeData => {
      this.clientIncomeDataRequest = {
        monthlyIncome: incomeData.monthlyIncome,
        monthlyLiabilities: incomeData.monthlyLiabilities
      }
      this.clientIncomeEditForm.setValue(this.clientIncomeDataRequest);
    });
  }

  submitForm() {
    this.clientService.updateClientIncomeData(this.clientId, this.clientIncomeEditForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.clientsRefreshService.reloadClientDetailsModel();
      this.activeModal.close();
    })
  }

}
