import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FileTemplatesRoutingModule } from './file-templates-routing.module';
import { FileTemplatesListComponent } from './components/file-templates-list/file-templates-list.component';
import { FileTemplateAddComponent } from './components/file-template-add/file-template-add.component';
import { FileTemplateEditComponent } from './components/file-template-edit/file-template-edit.component';
import { FileTemplateRevisionsComponent } from './components/file-template-revisions/file-template-revisions.component';


@NgModule({
  declarations: [FileTemplatesListComponent, FileTemplateAddComponent, FileTemplateEditComponent, FileTemplateRevisionsComponent],
  imports: [
    CommonModule,
    FileTemplatesRoutingModule,
    CommonsModule,
    NgbNavModule,
    TranslateModule,
    ReactiveFormsModule
  ]
})
export class FileTemplatesModule { }
