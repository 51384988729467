import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  newPage: number;
  @Input() currentPage: number;
  @Input() totalRecords: number;
  @Input() totalPages: number;
  @Input() itemsPerPage: number;
  @Output() setPage = new EventEmitter<number>();

  constructor() {
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    if (!!this.newPage && this.currentPage !== this.newPage) {
      this.setPage.emit(this.newPage);
    }
  }
}
