import { Component, Input, OnInit } from '@angular/core';
import { ClientDocumentService, ClientSidebarLibComponent, draggableModalOptions } from '@backoffice-monorepo/commons';
import { FullClient, IdentityDocument } from '@twino/backoffice-api';
import { ClientIdentityDocumentAddComponent } from '../client-identity-document-add/client-identity-document-add.component';
import { ClientIdentityDocumentEditComponent } from '../client-identity-document-edit/client-identity-document-edit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-identity-documents',
  templateUrl: './client-identity-documents.component.html',
  styleUrls: ['./client-identity-documents.component.scss']
})
export class ClientIdentityDocumentsComponent implements ClientSidebarLibComponent,OnInit {

  @Input()
  client: FullClient;
  identityDocuments: IdentityDocument[] | null;

  constructor(
    private clientDocumentService: ClientDocumentService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    return this.clientDocumentService.getClientIdentityDocuments(this.client.savedId).pipe(
      take(1)
    ).subscribe((identityDocuments) => {
      this.identityDocuments = identityDocuments;
    })
  }

  createIdentityDocument(clientId: number) {
    const modalRef = this.modalService.open(ClientIdentityDocumentAddComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = clientId;
    modalRef.result.then(
      (result) => {
        if(result === true) {
          this.refresh();
        }
      }, () => {}
    );
  }

  editIdentityDocument(identityDocument: IdentityDocument) {
    const modalRef = this.modalService.open(ClientIdentityDocumentEditComponent, draggableModalOptions);
    modalRef.componentInstance.identityDocument = identityDocument;
    modalRef.result.then(
      (result) => {
        if(result === true) {
          this.refresh();
        }
      }, () => {}
    );
  }

}
