import { Component, Host, OnInit } from '@angular/core';
import { AffiliateReportService, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import { AffiliateReport, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'backoffice-monorepo-affiliate-report-list',
  templateUrl: './affiliate-report-list.component.html',
  styleUrls: ['./affiliate-report-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<AffiliateReport>) => new FilteringService(listService),
      deps: [AffiliateReportService]
    }
  ]
})
export class AffiliateReportListComponent extends ListComponent<AffiliateReport> implements OnInit {

  exportModuleName = "affiliate-reports";

  constructor(
    @Host() filteringService: FilteringService<AffiliateReport>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getName(): string {
    return 'Affiliate report';
  }

  navigateTo(id: number) {
    this.router.navigate([`/affiliates/report/${id}`]);
  }

  trackById(index, item){
    return item.id;
  }
}
