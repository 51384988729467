import { Component, Input, OnInit } from '@angular/core';
import { FullLoan, LoanStatusDetail } from '@twino/backoffice-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicComponent, ConfirmDialogueComponent, CountryViewComponent, draggableModalOptions } from '@backoffice-monorepo/commons';
import { PlPiLoanService } from '../../services/pl-pi-loan.service';
import { PlPiSetRenouncementModalComponent } from '../pl-pi-set-renouncement-modal/pl-pi-set-renouncement-modal.component';
import { LoanRenouncementRequest } from '../../api/loan-renouncement';
import { PlPiLoanCloseComponent } from '../pl-pi-loan-close/pl-pi-loan-close.component';
import { PlPiTransferOutgoingAddComponent } from '../pl-pi-transfer-outgoing-add/pl-pi-transfer-outgoing-add.component';
import { Subject } from 'rxjs';
import { PlPiIncreaseLimitComponent } from '../pl-pi-increase-limit/pl-pi-increase-limit.component';
import { PlPiLoanMtpAbatementComponent } from '../pl-pi-loan-mtp-abatement/pl-pi-loan-mtp-abatement.component';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-button-view',
  templateUrl: './pl-pi-loan-button-view.component.html',
  styleUrls: ['./pl-pi-loan-button-view.component.scss']
})
export class PlPiLoanButtonViewComponent extends BasicComponent implements CountryViewComponent, OnInit {

  @Input()
  data: FullLoan;
  showButtons = false;
  showResumeButton = false;
  $destroy = new Subject<boolean>();

  constructor(private modalService: NgbModal,
              private plPiLoanService: PlPiLoanService) {
    super();
  }

  ngOnInit() {
    this.showButtons = this.data.open && !this.isClosedLoan();
    this.showResumeButton = this.data.paused && !this.isClosedLoan();
  }

  closeLoan() {
    const modalRef = this.modalService.open(PlPiLoanCloseComponent);
    modalRef.componentInstance.loanId = this.data.id;
  }

  pauseLoan() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Pause loan";
    modalRef.componentInstance.content = "Loan will be paused";
    modalRef.result.then(
      (result) => {
        if (result === true) this.plPiLoanService.pauseLoan(this.data.id).subscribe();
      }, () => {}
    );
  }

  resumeLoan() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Resume loan";
    modalRef.componentInstance.content = "Loan will be resume";
    modalRef.result.then(
      (result) => {
        if (result === true) this.plPiLoanService.resumeLoan(this.data.id).subscribe();
      }, () => {}
    );
  }

  setRenouncement() {
    const modalRef = this.modalService.open(PlPiSetRenouncementModalComponent, draggableModalOptions);
    modalRef.result.then(
      (result: LoanRenouncementRequest) => {
        if (result) this.plPiLoanService.renounceLoan(this.data.id, result).subscribe();
      }, () => {}
    );
  }

  sendTransferToClient() {
    const modalRef = this.modalService.open(PlPiTransferOutgoingAddComponent);
    modalRef.componentInstance.loanId = this.data.id;
  }

  increaseLimit() {
    const modalRef = this.modalService.open(PlPiIncreaseLimitComponent);
    modalRef.componentInstance.loan = this.data;
  }

  mtpAbatement() {
    const modalRef = this.modalService.open(PlPiLoanMtpAbatementComponent);
    modalRef.componentInstance.loan = this.data;
  }

  isClosedLoan() {
    return this.data.statusDetail === LoanStatusDetail.PENDING_TERMINATION_BY_CLIENT
      || this.data.statusDetail === LoanStatusDetail.PENDING_TERMINATION_BY_COMPANY
    || this.data.statusDetail === LoanStatusDetail.AML_TERMINATION_BY_COMPANY;
  }
}
