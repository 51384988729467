
<div class="modal-header">
    <h4 class="modal-title">
        Remove language
    </h4>
    <button
        type="button"
        class="close"
        aria-describedby="modal-title"
        (click)="modal.close()"
    >
        <span>&times;</span>
    </button>
    </div>
    <div  class="modal-body">
        <form [formGroup]="form">
            <label class="w-100" *ngFor="let lang of ($langs |async)">
                <input
                    [value]="lang.tag"
                    type="radio"
                    class="mb-2"
                    formControlName="language"
                />
                {{lang.tag}}</label>
        </form>
    </div>
    <div class="modal-footer">
    <button
        type="button"
        autofocus
        class="btn btn-outline-secondary"
        (click)="modal.close()"
    >Cancel</button>
    <button
        [disabled]="form.status !== 'VALID'"
        (click)="modal.close(form.value.language)"
        type="button"
        class="btn btn-danger"
    >Remove</button>
    </div>
