import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicComponent, MailboxService } from '@backoffice-monorepo/commons';
import { Task } from '@backoffice-monorepo/api';
import { map, take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-pl-pi-topic-list-modal',
  templateUrl: './pl-pi-topic-change-modal.component.html',
  styleUrls: ['./pl-pi-topic-change-modal.component.css']
})
export class PlPiTopicChangeModalComponent extends BasicComponent implements OnInit {
  @Input() task: Task;
  topicForm: FormGroup;
  topics: Array<string>

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private mailboxService: MailboxService
  ) {
    super();
  }

  ngOnInit(): void {
    this.topicForm = this.formBuilder.group({
      newTopic: ['', Validators.required],
    });
    this.getTaskTopicsList()
      .pipe(
        map(topics => topics.filter(topic => topic !== this.task.topic)),
        take(1)
      )
      .subscribe(topics => this.topics = topics);
  }

  getTaskTopicsList(): Observable<Array<string>> {
    return this.mailboxService.getMailboxTopics()
  }

  submitForm() {
    this.mailboxService.assignTask(this.task.id, this.topicForm.value).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(() => this.modal.close())
  }
}
