import { Component, Input } from '@angular/core';
import { BasicComponent, Utils } from '@backoffice-monorepo/commons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TariffResponseClient } from '../../api/tariff-client';

@Component({
  selector: 'backoffice-monorepo-tariff-client-modal-all',
  templateUrl: './tariff-client-modal-all.component.html',
  styleUrls: ['./tariff-client-modal-all.component.scss']
})
export class TariffClientModalAllComponent extends BasicComponent {

  @Input() tariff: TariffResponseClient;
  utils = Utils;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
    super();
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
