<form [formGroup]="paymentFromSurplusAddForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Create payment from surplus</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="loanNumber" class="col-sm-4 control-label">Loan number<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="loanNumber" formControlName="loanNumber" name="loanNumber">
      </div>
    </div>
    <div class="form-group row">
      <label for="amount" class="col-sm-4 control-label">Amount<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="amount" formControlName="amount" name="amount">
      </div>
    </div>
    <div class="form-group row">
      <label for="timestamp" class="col-sm-4 control-label">Timestamp</label>
      <div class="input-group col-sm-8">
        <input class="form-control form-control-sm"
               id="timestamp"
               formControlName="timestamp"
               placeholder="yyyy-mm-dd"
               name="timestamp"
               ngbDatepicker
               #timestamp="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="timestamp.toggle()" type="button"></button>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="comment" class="col-sm-4 control-label">Comment<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="comment" formControlName="comment" name="comment">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!paymentFromSurplusAddForm.valid">Save</button>
  </div>
</form>





