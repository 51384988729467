import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiOfferService } from '../../services/pl-pi-offer.service';
import { take, takeUntil } from 'rxjs/operators';
import { ProductSubType } from '../../modules/phone-application/api/initial-data';
import { AlertsService, BasicComponent, ClientService } from '@backoffice-monorepo/commons';
import {
  AcceptOfferConfirmationRequest,
  InitialTransferLimitsResponse,
  InitiateAcceptOfferWithConfirmationRequest,
  OfferValueAddedServiceResponse,
  RecalculateOfferRequest
} from '../../api/pl-pi-offer';
import { BillingDetail, Offer } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-pl-pi-accept-offer-modal',
  templateUrl: './pl-pi-accept-offer-modal.component.html',
  styleUrls: ['./pl-pi-accept-offer-modal.component.scss']
})
export class PlPiAcceptOfferModalComponent extends BasicComponent implements OnInit {

  @Input() offer: Offer;
  @Input() selectedAmount: number;
  @Input() grantedOfferId: number;
  @Input() hasVAS: boolean;

  acceptOfferForm: FormGroup = this.formBuilder.group({
    initialTransferAmount: [''],
    confirmationCode: [
      {value: '', disabled: true},
      [Validators.required, Validators.maxLength(4), Validators.minLength(4)]
    ]
  });
  initialTransferLimits: InitialTransferLimitsResponse | never;
  productSubType: ProductSubType;
  billingDetails: BillingDetail;
  offerVases: OfferValueAddedServiceResponse[];
  vasIds: number[] = [];
  accountNumberVAS: string
  minAmount: number;
  maxAmount: number;
  step = 10;
  showConfirmationCodeInput = false;
  isRefinance = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private offerService: PlPiOfferService,
    private clientService: ClientService,
    private alertService: AlertsService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.hasVAS) {
      this.getOfferVases();
      this.getVasParams();
    } else {
      this.getLimits();
    }
  }

  getLimits() {
    const request: RecalculateOfferRequest = {
      currentGrantedOfferId: this.grantedOfferId,
      selectedAmount: this.selectedAmount,
      selectedVasIds: (this.hasVAS) ? this.vasIds : []
    }
    this.getClientBillingDetails(this.offer?.clientId);
    this.offerService.getInitialTransferLimits(request).pipe(
      take(1)
    ).subscribe(loanOfferPreview => {
      this.initialTransferLimits = loanOfferPreview.initialTransferLimits;
      this.minAmount = loanOfferPreview.initialTransferLimits.minAmount;
      this.maxAmount = loanOfferPreview.initialTransferLimits.maxAmount;

      this.isRefinance = (this.productSubType === ProductSubType.REFINANCED);

      if (this.isRefinance) {
        this.offerService.getRefinanceLoanOfferDetails(this.grantedOfferId).subscribe(refinanceLoanOfferDetails => {
          this.acceptOfferForm.get('initialTransferAmount').patchValue(refinanceLoanOfferDetails.openLoanAmount);
          this.acceptOfferForm.get('initialTransferAmount').disable();
        })
      } else {
        this.acceptOfferForm.get('initialTransferAmount').setValidators([
          Validators.required,
          Validators.min(this.minAmount),
          Validators.max(this.maxAmount)
        ]);
        this.acceptOfferForm.get('initialTransferAmount').patchValue(this.initialTransferLimits.defaultAmount);
      }
    })
  }

  submitForm() {
    if (!this.showConfirmationCodeInput) {
      const request: InitiateAcceptOfferWithConfirmationRequest = {
        currentGrantedOfferId: this.grantedOfferId,
        selectedAmount: this.selectedAmount
      };
      this.offerService.initiateAcceptOfferWithOtpConfirmation(request)
        .pipe(
          takeUntil(this.$destroy)
        )
        .subscribe(response => {
          this.grantedOfferId = response.newOfferId;
          this.showConfirmationCodeInput = true;
          this.acceptOfferForm.get('confirmationCode').enable();
          if (!this.isRefinance) {
            this.acceptOfferForm.get('initialTransferAmount').disable();
          }
        });
    } else {
      const request: AcceptOfferConfirmationRequest = {
        confirmationCode: this.acceptOfferForm.get('confirmationCode').value,
        initialTransferAmount: this.acceptOfferForm.get('initialTransferAmount').value,
        selectedVasIds: (this.hasVAS) ? this.vasIds : []
      }
      this.offerService.confirmAcceptOfferOtp(this.grantedOfferId, request)
        .pipe(
          takeUntil(this.$destroy)
        )
        .subscribe(() => {
          this.alertService.notifySuccess(`Offer accepted`);
          this.activeModal.close(true);
        })
    }
  }

  getClientBillingDetails(clientId: number) {
    this.clientService.getClientBillingDetails(clientId).pipe(
      take(1)
    ).subscribe(billingDetails => {
      this.billingDetails = billingDetails.find(x => x.default);
    })
  }

  getOfferVases() {
    this.offerService.getOfferVases(this.grantedOfferId).pipe(
      take(1)
    ).subscribe(response => {
      this.offerVases = response;
      this.vasIds = this.offerVases.map(x => x.id);
      this.getLimits();
    })
  }

  getVasParams() {
    this.offerService.getVasParams().pipe(
      take(1)
    ).subscribe(response => {
      this.accountNumberVAS = response.accountNumber;
    })
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
