import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlacklistComponent } from './components/blacklist/blacklist.component';
import { BlacklistRoutingModule } from './blacklist-routing.module';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { BlacklistAddComponent } from './components/blacklist-add/blacklist-add.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BlacklistEditComponent } from './components/blacklist-edit/blacklist-edit.component';
import { DragDropModule } from '@angular/cdk/drag-drop';



@NgModule({
  declarations: [BlacklistComponent, BlacklistAddComponent, BlacklistEditComponent],
	imports: [
		CommonModule,
		BlacklistRoutingModule,
		CommonsModule,
		ReactiveFormsModule,
		DragDropModule
	]
})
export class BlacklistModule { }
