import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import {
  CloseTaskRequest,
  EnumResponse, FilterOperation,
  PostponeTaskRequest, SearchCsvExportRequest,
  SearchQuery,
  SearchQueryAware,
  SearchQueryBuilder,
  SearchResponse,
  Task,
  TaskDefinition, TaskTopicWithCountResponse
} from '@twino/backoffice-api';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from '../errors.service';
import { mergeMap, tap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TasksService extends BaseService implements SearchQueryAware<Task> {

  cachedTaskDefinitions: TaskDefinition[];

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<Task>> {
    return this.post('/api/tasks/list', searchQuery);
  };

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/tasks/enum-values/${enumClass}`);
  };

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/tasks/export-csv`, exportRequest, {'responseType': 'blob'});
  }

  getTaskDefinitions(): Observable<TaskDefinition[]> {
    if (this.cachedTaskDefinitions) {
      return of(this.cachedTaskDefinitions);
    }

    return this.getTaskTopics()
      .pipe(
        mergeMap(topics => topics),
        mergeMap(topic => this.getTaskDefinition(topic)),
        toArray(),
        tap(taskDefinitions => this.cachedTaskDefinitions = taskDefinitions)
      );
  }

  getMyAllOpenTasks(searchQuery: SearchQuery) {
    return this.post(`/api/tasks/my-all-open-tasks`, searchQuery);
  };

  assignTask(taskId: number, forceAssign: boolean = false) {
    return this.post(`/api/task/${taskId}/assign`, {forceAssign: forceAssign});
  };

  releaseToGroup(taskId: number) {
    return this.post(`/api/task/${taskId}/release-to-group`);
  };

  postpone(taskId: number, postponeRequest: PostponeTaskRequest) {
    return this.post(`/api/task/${taskId}/postpone`, postponeRequest);
  };

  close(taskId: number, closeRequest: CloseTaskRequest) {
    return this.post(`/api/task/${taskId}/close`, closeRequest);
  };

  getTask(taskId: number): Observable<Task> {
    return this.get(`/api/task/${taskId}`);
  };

  getTaskTopics(): Observable<string[]> {
    return this.get(`/api/tasks/topics`);
  };

  getTaskTopicsWithOpenCount(): Observable<TaskTopicWithCountResponse[]> {
    return this.get(`/api/tasks/topics-with-count`);
  };

  getTaskDefinition(topic: string): Observable<TaskDefinition> {
    return this.get(`/api/tasks/${topic}/definition`);
  };

  getTasksByClientId(clientId: number) {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(40)
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [clientId.toString()]
      })
      .withSortCriterion({propertyName: "created", sortDirection: "DESC"})
      .build();

    return this.find(searchQuery);
  }
}
