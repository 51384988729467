export class Payment {
  id: number
  direction: string
  channelName: string
  amount: string
  status: string
  details: string
  fromAccount: string
  toAccount: string
  clientPersonalId?: string
  bookingDate: Date
  processingTimestamp?: Date
  created: Date
  clientNumber: string
  loanNumber: string
  clientId?: number
  lenderId?: number
  loanId?: number
  initiatedByClient?: number
}

export class CreatePaymentFromSurplusRequest {
  paymenttimestamp: Date
  paymentloanNumber: string
  paymentamount: number
  paymentcomment?: string
}

export enum TransactionTypes {
  GENERATED_PENALTY = 'GENERATED_PENALTY',
  LOAN_EXTENSION = 'LOAN_EXTENSION',
  LOAN_MAIN_AMOUNT = 'LOAN_MAIN_AMOUNT',
  REPAYMENT = 'REPAYMENT',
  SURPLUS = 'SURPLUS',
  WRITEOFF = 'WRITEOFF',
  IDENTIFICATION = 'IDENTIFICATION',
  BANK_ACCOUNT_CHANGE = 'BANK_ACCOUNT_CHANGE',
  SOLD_LOAN = 'SOLD_LOAN',
  INTRA_COMPANY_TRANSFER = 'INTRA_COMPANY_TRANSFER',
  FAULTY = 'FAULTY',
  FAULTY_IDENTIFICATION = 'FAULTY_IDENTIFICATION',
  FAULTY_IDENTIFICATION_RETURN = 'FAULTY_IDENTIFICATION_RETURN',
  INVALID_PAYMENT = 'INVALID_PAYMENT',
  BANK_COMMISSION = 'BANK_COMMISSION'
}

export enum PaymentDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING'
}

export enum PaymentStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  PROCESSED = 'PROCESSED',
  UNPROCESSED = 'UNPROCESSED',
  MANUAL_PROCESSING = 'MANUAL_PROCESSING',
  VOIDED = 'VOIDED'
}
