import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicComponent, TransferService } from '@backoffice-monorepo/commons';
import { TransferRefreshService } from '../../services/transfer-refresh.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'transfer-redirect',
  templateUrl: './transfer-redirect.component.html',
  styleUrls: ['./transfer-redirect.component.scss']
})
export class TransferRedirectComponent extends BasicComponent implements OnInit {
  @Input() transferId: number;

  transferRedirectForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private transferService: TransferService,
    private formBuilder: FormBuilder,
    private transfersRefreshService: TransferRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.transferRedirectForm = this.formBuilder.group({
      loanId: ['', Validators.required]
    });
  }

  submitForm() {
    const redirectTransferRequest = {
      transferId: this.transferId,
      destinationLoanId: this.transferRedirectForm.value.loanId
    }
   this.transferService.redirectTransfer(redirectTransferRequest).pipe(
     takeUntil(this.$destroy)
   ).subscribe(() => {
      this.transfersRefreshService.reloadTransfersListModel();
      this.activeModal.close();
    })
  }
}
