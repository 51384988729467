import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentDistributionsRoutingModule } from './payment-distributions-routing.module';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentDistributionsListComponent } from './components/payment-distributions-list/payment-distributions-list.component';


@NgModule({
  declarations: [PaymentDistributionsListComponent],
  imports: [
    CommonModule,
    PaymentDistributionsRoutingModule,
    CommonsModule,
    NgbNavModule,
    TranslateModule,
    ReactiveFormsModule
  ]
})
export class PaymentDistributionsModule { }
