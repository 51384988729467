import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';
import {
  Blacklist,
  BlacklistRequest,
  EnumResponse,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class BlacklistService extends BaseService implements SearchQueryAware<Blacklist> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<Blacklist>> {
    return this.post(`/api/blacklist/list`, searchQuery);
  }

  getById(blacklistId: number): Observable<Blacklist> {
    return this.get(`/api/blacklist/${blacklistId}`);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/blacklist/enum-values/${enumClass}`);
  }

  addToBlacklist(addToBlacklistRequest: BlacklistRequest) {
    return this.post('/api/blacklist/add', addToBlacklistRequest);
  }

  updateBlacklistItem(blacklistId: number, blacklist: BlacklistRequest) {
    return this.post(`/api/blacklist/${blacklistId}`, blacklist);
  }
}

