import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { FilteringService, ListComponent, NotificationConfigService } from '@backoffice-monorepo/commons';
import { NotificationConfig, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationConfigsRefreshService } from '../../services/notification-configs-refresh.service';

@Component({
  selector: 'backoffice-monorepo-notification-configs-list',
  templateUrl: './notification-configs-list.component.html',
  styleUrls: ['./notification-configs-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<NotificationConfig>) => new FilteringService(listService),
      deps: [NotificationConfigService]
    }
  ]
})
export class NotificationConfigsListComponent extends ListComponent<NotificationConfig> implements OnInit, OnDestroy {

  private reloadNotificationConfigsListModel: Subscription;

  constructor(@Host() filteringService: FilteringService<NotificationConfig>,
              protected router: Router,
              activatedRoute: ActivatedRoute,
              notificationConfigService: NotificationConfigService,
              private notificationConfigsRefreshService: NotificationConfigsRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadNotificationConfigsListModel = this.notificationConfigsRefreshService.reloadNotificationConfigsListModel$.subscribe(() => {
      super.ngOnInit();
    })
  }

  ngOnDestroy(): void {
    this.reloadNotificationConfigsListModel.unsubscribe();
  }

  getName(): string {
    return 'Notification configurations';
  }

  navigateTo(id: number) {
    this.router.navigate([`/notification-configs/${id}`]);
  }
}
