import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@backoffice-monorepo/commons';

@Pipe({
  name: 'plPiCompare'
})
export class PlPiComparePipe implements PipeTransform {

  transform(value: unknown, index: number): unknown {

    switch (index) {
      case 7:
      case 8:
      case 15:
        return (value) ? this.transformDateTimeValue(value) : '';
      case 17:
        return (value) ? this.transformDateValue(value) : '';
      case 12:
       return '';
      default:
        return value;
    }
  }

  transformDateTimeValue(value: unknown): string {
    if (Array.isArray(value)) {
      return DateUtils.dateArray2DateTime(value);
    } else {
      return DateUtils.formatDateTimeFrom(value.toString());
    }
  }

  transformDateValue(value: unknown): string {
    if (Array.isArray(value)) {
      return DateUtils.dateArray2Date(value);
    } else {
      return DateUtils.formatDateFrom(value.toString());
    }
  }
}
