<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Loan Applications</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Application ID</th>
          <th>Asked Term</th>
          <th>Asked Amount</th>
          <th>Brand</th>
          <th>Product type</th>
          <th>Product subtype</th>
          <th>Status</th>
          <th>Type</th>
          <th>Resolution</th>
          <th>Credit score</th>
          <th>Flow state</th>
          <th>Flow state start date</th>
          <th>Created</th>
          <th>Modified</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of applications">
          <td></td>
          <td><a *ngIf="item.id" href="#/applications/{{item.id}}">{{item.id}}</a></td>
          <td>{{ item.askedTerm.loanLength.value }} {{ item.askedTerm.loanLength.unit }}</td>
          <td>{{ item.askedAmount }}</td>
          <td>{{ item.brand }}</td>
          <td>{{ item.productType }}</td>
          <td>{{ item.productSubType }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.resolution }}</td>
          <td>{{ item.creditScore }}</td>
          <td>{{ item.flowState }}</td>
          <td>{{ item.flowStateStartDateTime | date: 'dd.MM.yyyy' }}</td>
          <td>{{ item.createDate }}</td>
          <td>{{ item.modifiedDate }}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>
