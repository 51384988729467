import { HttpClient } from '@angular/common/http';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { Injectable } from '@angular/core';
import {
  ConfirmBankTransferRequest,
  PlPiBluemediaBalance,
  PlPiCreateTransferRequest,
  SubmitBankTransferRequest,
  SubmitBankTransferResponse
} from '../api/pl-pi-transfer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlPiTransferService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  createTransfer(createTransferRequest: PlPiCreateTransferRequest) {
    return this.post(`/api/pl/transfer/create-manual`, createTransferRequest);
  }

  getBluemediaBalance(): Observable<PlPiBluemediaBalance> {
    return this.get(`/api/pl/bluemedia/balance`);
  }

  submitBankTransfer(loanId: number, submitBankTransferRequest: SubmitBankTransferRequest): Observable<SubmitBankTransferResponse> {
    return this.post(`/api/pl/loans/${loanId}/bank-transfer/submit`, submitBankTransferRequest);
  }

  confirmBankTransfer(loanId: number, confirmBankTransferRequest: ConfirmBankTransferRequest) {
    return this.post(`/api/pl/loans/${loanId}/bank-transfer/confirm`, confirmBankTransferRequest);
  }
}
