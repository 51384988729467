import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private readonly DEFAULT = 'default'

  constructor(
    @Inject(DOCUMENT) private document
  ) {
    this.loadStyle();
  }

  loadStyle(styleName: string = localStorage.getItem('user-theme') || this.DEFAULT) {
    localStorage.setItem('user-theme', styleName);
    const themeLink = this.document.getElementById('user-theme') as HTMLLinkElement;
    const newStyle = `${styleName}.css`;
    if (!themeLink.href.endsWith(newStyle)) {
      themeLink.href = `${styleName}.css`;
    }
  }
}
