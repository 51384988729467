<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client consents</b>
  </p>
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
          <tr>
            <th>Consent name</th>
            <th>Brand</th>
            <th>Is active</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of consents">
            <td>{{item.name}}</td>
            <td>{{item.brand}}</td>
            <td>
              <input
                appRequirePermission="client.all,client.edit,admin.all"
                type="checkbox"
                class="btn"
                [checked]="item.value"
                (click)="updateConsents(item.name, $any($event).target.checked, item.brand)">
            </td>
          </tr>
        </tbody>
      </table>
    </server-table>
    <span>
      <select (change)="setBrand($event)" class="p-1 mt-0">
        <option>Choose the brand</option>
        <option *ngFor="let key of productBrandsList$ | async" [ngValue]="key">{{key}}</option>
      </select>
      <button
        appRequirePermission="client.all,client.edit,admin.all" class="btn btn-danger btn-sm ml-1 mt-0"
        [disabled]="brand === null"
        (click)="renounceAllConsents()"
      >
        <bi name="x-square"></bi>Renounce all consents</button>
    </span>
  </div>
</div>
