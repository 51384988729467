import { map, pluck, switchMap, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  ClientService,
  CommunicationService,
  FileUpload,
  MailboxActionsService,
  MailboxService,
  MessageTemplateService,
  NamedComponent,
  tinyMceEditorConfig,
  TasksService,
  ViewHistoryService, SystemConfigService,
} from '@backoffice-monorepo/commons';
import {
  Communication,
  EmailCommunicatiomConfig,
  EmailRequest,
  FullClient,
  MessageTemplate, ReplyToMailBoxTaskEmailRequest,
  Task
} from '@backoffice-monorepo/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TasksCommonService } from '../../services/tasks-common.service';
import { forkJoin, Observable, of } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-pl-pi-task-send-email',
  templateUrl: './task-send-email.component.html',
  styleUrls: ['./task-send-email.component.css']
})
export class TaskSendEmailComponent extends NamedComponent implements OnInit {

  private emailId: number;
  clientCommunication: Communication;
  replyEmailSendForm: FormGroup;
  client: FullClient;
  messageTemplates$: Observable<MessageTemplate[]>;
  replyRequest: ReplyToMailBoxTaskEmailRequest;
  emailRequest: EmailRequest;
  taskId: number;
  task: Task;
  files: Array<FileUpload> = [];
  filesIndex = 0;
  recipients: Array<string> = [];
  brand: string;
  allMailboxes: Map<string, EmailCommunicatiomConfig>;
  mailboxes: string[] = [];

  editorConfig = {
    ...tinyMceEditorConfig,
    menubar: '',
    plugins: 'codesample code table link image lists',
    toolbar: 'bold italic bullist numlist link image'
  };

  constructor(
    activatedRoute: ActivatedRoute,
    private communicationService: CommunicationService,
    private clientService: ClientService,
    private mailboxService: MailboxService,
    private mailboxActionsService: MailboxActionsService,
    protected router: Router,
    private formBuilder: FormBuilder,
    private messageTemplateService: MessageTemplateService,
    private tasksCommonService: TasksCommonService,
    private tasksService: TasksService,
    private viewHistoryService: ViewHistoryService,
    private systemConfigService: SystemConfigService,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.taskId = this.activatedRoute.snapshot.params.id;
    this.emailId = this.activatedRoute.snapshot.params.communicationId;
    this.systemConfigService.getSystemConfigRecordByName('communication_config').pipe(
      take(1)
    ).subscribe(communicationConfig => {
      this.allMailboxes = JSON.parse(communicationConfig.data)['senderEmailAddressesByBrand'];
      const mailboxList = [];
      Object.values(this.allMailboxes).forEach(function (brandEmails){
        mailboxList.push(brandEmails.noreplyEmailAddress);
        mailboxList.push(...brandEmails.emailAddresses.map(email => email.emailAddress));
      });
      this.mailboxes = [...new Set(mailboxList)];
    })
    this.refresh();
    this.replyEmailSendForm = this.formBuilder.group({
      emailFrom : ['', Validators.required],
      subject: ['re:', Validators.required],
      templateKey: [''],
      attachment: [''],
      body: ['', Validators.required],
    });


    this.messageTemplates$ = this.messageTemplateService.getManualMessageTemplates(this.task.brand)
      .pipe(
        pluck('results'),
        take(1)
      );

    this.replyEmailSendForm.get('templateKey').valueChanges
      .pipe(
        switchMap(key => {
          if (key) {
            return this.messageTemplateService.getMessageTemplatesByKey(key, this.task.brand)
              .pipe(
                pluck('results'),
                map(templates => templates[0])
              )
          }
          return of(null);
        })
      )
      .subscribe((template: MessageTemplate) => {
        if (template) {
          this.replyEmailSendForm.get('body').patchValue(template.message)
        }
      })


  }

  refresh() {
    forkJoin({
      task: this.tasksService.getTask(this.taskId),
      communication: this.communicationService.getClientCommunication(this.emailId)
    })
      .pipe(
        switchMap(({task, communication}) => {
          if (!task.clientId) {
            return of({
              client: null,
              communication,
              task
            })
          }
          return this.clientService.getClient(task.clientId).pipe(
            map(client => ({
              task,
              communication,
              client
            }))
          )
        }),
        take(1)
      )
      .subscribe(({communication, client, task}) => {
        this.client = client;
        this.clientCommunication = communication;
        this.task = task;
      })
  }

  getName(): string {
    return `Email: ${this.emailId}`;
  }

  submitForm():void {
    this.emailRequest = {
      destination: this.clientCommunication.sender,
      body: this.replyEmailSendForm.controls.body.value,
      subject: this.replyEmailSendForm.controls.subject.value,
      isHtml: true,
      from: this.replyEmailSendForm.controls.emailFrom.value,
      cc: '',
      category: '',
      attachments: [],
      destinations: [...this.recipients],
      hasCc: false,
      ccs: []
    };

    this.replyRequest = {
      email: this.emailRequest,
      templateKey: this.replyEmailSendForm.controls.templateKey.value,
      shouldStoreExternally: false,
      shouldSendInCommunicationTimeWindow: true,
      comment: "",
      brand: this.task.brand,
    };

    this.mailboxService
      .replyToEmail(this.taskId, this.replyRequest, this.files)
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(() => this.closeComponent());
  }

  addFile(fileInput: any):void {
    const fileList = <FileList>fileInput.target.files;
    Array.from(fileList).forEach((fileData) => {
      this.files.push({
        index: this.filesIndex,
        file: fileData,
        name: fileData.name,
      });
      this.filesIndex++;
    })
  }

  removeFile(index):void {
    this.files = this.files.filter(file => file.index !== index);
    this.replyEmailSendForm.controls.attachment.reset();
  }

  closeComponent() {
    this.viewHistoryService.closeTab();
    this.router.navigate(['/tasks/dashboard']);
  }

  closeTask() {
    this.tasksCommonService.closeTask(this.task).then(() => this.closeComponent());
  }

  assignTask() {
    this.mailboxActionsService.openChangeTopicModal(this.task)
      .then(() => {
        this.refresh();
      });
  }

  changeClient() {
    this.mailboxActionsService.openChangeClientModal(this.task)
      .then(() => {
        this.refresh();
      });
  }

  addRecipient(email: string) {
    if (!this.recipients.includes(email)) {
      this.recipients.push(email);
    }
  }

  removeRecipient(email: string) {
    this.recipients = this.recipients.filter(recipient => recipient !== email)
  }
}
