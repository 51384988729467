<transform-table [transformInstructions]="loanTransformations" [entity]="data"></transform-table>
<table class="table table-striped table-hover table-sm mb-0">
  <colgroup>
    <col class="table-primary"/>
    <col/>
  </colgroup>
  <tbody>
  <tr>
    <td class="text-right w-50">DiPocket Balance</td>
    <td>{{diPocketBalance}}</td>
  </tr>
  </tbody>
</table>
