import { Component, Input, OnInit } from '@angular/core';
import { PlPiClientCardService } from '../../services/pl-pi-client-card.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { BasicComponent } from '@backoffice-monorepo/commons';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-card-send-pin',
  templateUrl: './pl-pi-client-card-send-pin.component.html',
  styleUrls: ['./pl-pi-client-card-send-pin.component.css']
})
export class PlPiClientCardSendPinComponent extends BasicComponent implements OnInit {
  @Input() clientId: number;

  clientSendPinForm: FormGroup;

  constructor(
    private plPiClientCardService: PlPiClientCardService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientSendPinForm = this.formBuilder.group({
      cvv: ['', Validators.required]
    });
  }

  submitForm() {
    this.plPiClientCardService.sendPin(this.clientId, this.clientSendPinForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.activeModal.close();
    })
  }

}
