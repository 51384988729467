<form [formGroup]="clientDiscountAddForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Add client discount</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="termDays" class="col-sm-4 control-label">Term days<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select ngbAutofocus class="form-control form-control-sm" id="termDays" formControlName="termDays" name="termDays">
          <option *ngFor="let key of termDaysOptions" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="discountPercent" class="col-sm-4 control-label">Discount percent<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select class="form-control form-control-sm" id="discountPercent" formControlName="discountPercent" name="discountPercent">
          <option *ngFor="let key of discountPercentOptions" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientDiscountAddForm.valid">Save</button>
  </div>
</form>



