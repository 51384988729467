import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  EnumResponse,
  SearchQuery,
  SearchQueryAware,
  SearchResponse,
  TransactionEntry,
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionService extends BaseService implements SearchQueryAware<TransactionEntry> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<TransactionEntry>> {
    return this.post(`/api/transactions`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/transactions/enum-values/${enumClass}`);
  }
}
