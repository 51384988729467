import { Component, Input, OnInit } from '@angular/core';
import {
  AlertsService,
  BasicComponent,
  LocalesService,
  MessageTemplateService,
  ProductConfigService,
  tinyMceEditorConfig,
} from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageTemplateRequest, ProductConfig } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'message-template-edit',
  templateUrl: './message-template-edit.component.html',
  styleUrls: ['./message-template-edit.component.scss']
})
export class MessageTemplateEditComponent extends BasicComponent implements OnInit {

  @Input() id: number;

  messageTemplateEditForm: FormGroup;
  locales$: Observable<Array<string>>;
  useTinyMCE = false;
  public productBrandsList$: Observable<string[]>

  editorConfig = tinyMceEditorConfig;

  constructor(
    private alertService: AlertsService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private messageTemplatesService: MessageTemplateService,
    private localesService: LocalesService,
    private productConfig: ProductConfig,
    private productConfigService: ProductConfigService,
  ) {
    super();
  }


  ngOnInit(): void {
    this.locales$ = this.localesService.getLocales();
    this.productBrandsList$ = this.productConfigService.listBrands();
    this.messageTemplateEditForm = this.formBuilder.group({
      key: ['', Validators.required],
      message: ['', Validators.required],
      locale: '',
      isDisabled: '',
      brand: [''],
    });
    this.messageTemplatesService.getMessageTemplate(this.id).pipe(
      take(1)
    ).subscribe(messageTemplate => {
      if (messageTemplate.key.includes(this.productConfig.httpTemplateKeyMarker)) {
        this.useTinyMCE = true;
      }

      const messageTemplateData: MessageTemplateRequest = {
        key: messageTemplate.key,
        message: messageTemplate.message,
        locale: messageTemplate.locale,
        isDisabled: messageTemplate.isDisabled,
        brand: (messageTemplate?.brand) ? messageTemplate?.brand : null
      }
      this.messageTemplateEditForm.setValue(messageTemplateData);
    })
  }

  getName(): string {
    return `Edit message template ${this.id || ''}`;
  }

  submitForm() {
    this.messageTemplatesService.updateMessageTemplate(this.id, this.messageTemplateEditForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Message template ${this.messageTemplateEditForm.value.key} updated`);
      this.activeModal.close();
    })
  }

  switchTextarea() {
    this.useTinyMCE = !this.useTinyMCE;
  }
}
