import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAuthority } from '@twino/backoffice-api';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'backoffice-monorepo-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.scss']
})
export class RoleAddComponent {
  name = new FormControl('', Validators.required);
  authorities: UserAuthority[];
  selectedAuthorities: string[] = [];

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  onSaveClick() {
    this.activeModal.close({
      name: this.name.value,
      authorities: [...this.selectedAuthorities]
    });
  }

  selectAuthority(authorityName: string) {
    if (this.selectedAuthorities.includes(authorityName)) {
      this.selectedAuthorities = this.selectedAuthorities.filter(selectedAuthorityName => authorityName !== selectedAuthorityName);
    } else {
      this.selectedAuthorities.push(authorityName);
    }
  }
}
