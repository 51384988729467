<div class="modal-header">
  <h4 class="modal-title">View client card history</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <table class="table table-striped table-hover mt-2">
      <thead class="text-light bg-primary">
      <tr>
        <th>Card ID</th>
        <th>Status</th>
        <th>Reason</th>
        <th>Created</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of history;">
        <td>{{item.cardId}}</td>
        <td>{{item.status}}</td>
        <td>{{item.reason}}</td>
        <td>{{item.created}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
</div>



