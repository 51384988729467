<form [formGroup]="cardStatusChangeForm" (ngSubmit)="submitForm()">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="reason" class="col-sm-4 control-label">Reason<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select class="form-control form-control-sm" id="reason" formControlName="reason" name="reason">
          <option *ngFor="let key of reasons" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!cardStatusChangeForm.valid">Save</button>
  </div>
</form>

