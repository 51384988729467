import { Injectable, TemplateRef } from '@angular/core';

export class Toast {
  header: string
  classname: string
  delay: number
  textOrTpl: TemplateRef<any> | string
  action?: 'delete' | 'hide'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: Toast[] = [];

  constructor() { }

  showError(message: string) {
    this.toasts.push({classname: 'bg-danger text-light', textOrTpl: message, delay: 60_000} as Toast);
  }

  showAlert(message: string) {
    this.toasts.push({classname: 'bg-success text-light', textOrTpl: message} as Toast);
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  removeAll() {
    this.toasts = [];
  }

}
