import { Injectable, Type } from '@angular/core';
import { ClientDetailsTableComponentResolver, CountryViewComponent } from '@backoffice-monorepo/commons';
import { PlPiClientDetailsTableComponent } from '../components/pl-pi-client-details-table/pl-pi-client-details-table.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiClientDetailsTableComponentResolverService implements ClientDetailsTableComponentResolver {
  resolve(): Type<CountryViewComponent> {
    return PlPiClientDetailsTableComponent;
  }
}
