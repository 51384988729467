import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AlertsService,
  BasicComponent,
  ClientService,
  FileUpload,
  MessageTemplateService,
  ProductConfigService,
  SystemConfigService,
  tinyMceEditorConfig
} from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommunicationRequest, EmailCommunicatiomConfig, EmailConfig, EmailRequest, MessageTemplate } from '@twino/backoffice-api';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { map, pluck, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'client-email-send',
  templateUrl: './client-email-send.component.html',
  styleUrls: ['./client-email-send.component.scss']
})
export class ClientEmailSendComponent extends BasicComponent implements OnInit {
  @Input() clientId: number;
  @Input() email: string;

  files: Array<FileUpload> = [];
  filesIndex = 0;
  clientEmailSendForm: FormGroup;
  messageTemplates: MessageTemplate[];
  communicationRequest: CommunicationRequest;
  emailRequest: EmailRequest;
  allMailboxes: Map<string, EmailCommunicatiomConfig>;
  mailboxes: EmailConfig[];
  brand: string | null = null;
  public productBrandsList$: Observable<string[]>;

  editorConfig = {
    ...tinyMceEditorConfig,
    menubar: '',
    plugins: 'codesample code table link image lists',
    toolbar: 'bold italic bullist numlist link image'
  };

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService,
    private messageTemplateService: MessageTemplateService,
    private systemConfigService: SystemConfigService,
    private productConfigService: ProductConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    this.productBrandsList$ = this.productConfigService.listBrands();
    this.getManualMessageTemplates();
    this.systemConfigService.getSystemConfigRecordByName('communication_config').pipe(
      take(1)
    ).subscribe(communicationConfig => {
      this.allMailboxes = JSON.parse(communicationConfig.data)['senderEmailAddressesByBrand'];
    })

    this.clientEmailSendForm = this.formBuilder.group({
      brand: ['', Validators.required],
      from: ['', Validators.required],
      destination: [{value: this.email, disabled: true}, Validators.required],
      subject: ['', Validators.required],
      templateKey: ['', Validators.required],
      body: ['', Validators.required],
    });

    this.clientEmailSendForm.get('brand').valueChanges
      .subscribe((brand: string) => {
        this.brand = brand;
        this.mailboxes = this.allMailboxes[this.brand]['emailAddresses'];
        this.getManualMessageTemplates();
      });


    this.clientEmailSendForm.get('templateKey').valueChanges
      .pipe(
        switchMap(key => {
          if (key) {
            return this.messageTemplateService.getMessageTemplatesByKey(key, this.brand)
              .pipe(
                pluck('results'),
                map(templates => templates[0])
              )
          }
          return of(null);
        }),
        takeUntil(this.$destroy)
      )
      .subscribe((template: MessageTemplate) => {
        if (template) {
          this.clientEmailSendForm.get('body').patchValue(template.message)
        }
      })
  }

  getManualMessageTemplates() {
    this.messageTemplateService.getManualMessageTemplates(this.brand)
      .pipe(take(1))
      .subscribe(messageTemplates => {
        this.messageTemplates = messageTemplates?.results;
      })
  }

  addFile(fileInput: any) {
    const fileList = <FileList>fileInput.target.files;
    Array.from(fileList).forEach((fileData) => {
      this.files.push({
        index: this.filesIndex,
        file: fileData,
        name: fileData.name,
      });
      this.filesIndex++;
    })
  }

  removeFile(index: number) {
    this.files = this.files.filter(p => p.index !== index);
  }

  submitForm() {
    this.emailRequest = {
      destination: this.clientEmailSendForm.controls.destination.value,
      body: this.clientEmailSendForm.controls.body.value,
      subject: this.clientEmailSendForm.controls.subject.value,
      isHtml: true,
      from: this.clientEmailSendForm.controls.from.value,
      cc: '',
      category: '',
      attachments: [],
    }
    this.communicationRequest = {
      sms: null,
      tts: null,
      email: this.emailRequest,
      push: null,
      templateKey: this.clientEmailSendForm.controls.templateKey.value,
      shouldStoreExternally: false,
      shouldSendInCommunicationTimeWindow: true
    };
      this.clientService.sendEmail(this.clientId, this.communicationRequest, this.files).pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
        this.alertService.notifySuccess(`E-mail message sent`);
        this.clientsRefreshService.reloadClientCommunicationModel();
        this.activeModal.close();
      })
  }
}
