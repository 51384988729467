import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchQuery } from '@twino/backoffice-api';

@Component({
  selector: 'server-table',
  templateUrl: './server-table.component.html',
  styleUrls: ['./server-table.component.scss']
})
export class ServerTableComponent {
  @Input() totalPages: number;
  @Input() totalRecords: number;
  @Input() currentPage: number;
  @Input() pageSize: number = SearchQuery.fromBaseQuery().pageSize;
  @Input() countLimitReached = false;
  @Input() searchQuery: SearchQuery;
  @Input() withFilterBlock = false;
  @Input() withRefreshButton = true;
  @Input() withExportButton = false;
  @Input() exportModuleName?: string;
  @Output() setPage = new EventEmitter<number>();
  @Output() refresh = new EventEmitter<void>();
  // TODO: after angular version upgrade, this should be combined so that each server-table use
  //  dont need to listen to this event
  @Output() removeCountLimit = new EventEmitter<void>();

  constructor() {
  }

  pageChanged($event) {
    this.setPage.emit($event);
  }

  serverRefresh() {
    this.refresh.emit();
  }

  countAll() {
    this.removeCountLimit.emit();
  }

}
