import { Component } from '@angular/core';
import { UserAuthority } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent {
  name: string;
  authorities: UserAuthority[];
  selectedAuthorities: string[] = [];

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  onSaveClick() {
    this.activeModal.close([...this.selectedAuthorities]);
  }

  selectAuthority(authorityName: string) {
    if (this.selectedAuthorities.includes(authorityName)) {
      this.selectedAuthorities = this.selectedAuthorities.filter(selectedAuthorityName => authorityName !== selectedAuthorityName);
    } else {
      this.selectedAuthorities.push(authorityName);
    }
  }
}
