import { Component, Input, OnInit } from '@angular/core';
import { FullLoan } from '@twino/backoffice-api';
import { AlertsService, BasicComponent, SystemConfigService} from '@backoffice-monorepo/commons';
import { take, takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RawConsent } from '../../models/PlPiConsents';
import { PlPiApplicationService } from '../../services/pl-pi-application.service';
import { AdditionalAmountApplicationCreationRequest } from '../../api/pl-pi-application';

@Component({
  selector: 'backoffice-monorepo-pl-pi-increase-limit',
  templateUrl: './pl-pi-increase-limit.component.html',
  styleUrls: ['./pl-pi-increase-limit.component.css']
})
export class PlPiIncreaseLimitComponent extends BasicComponent implements OnInit {

  @Input() loan: FullLoan
  configConsents:  Map<string, RawConsent>;
  checked = new Map();

  constructor(
    private systemConfigService: SystemConfigService,
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private applicationService: PlPiApplicationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.systemConfigService.getSystemConfigRecordByName('client_consent_config').pipe(
      take(1)
    ).subscribe(config => {
      this.configConsents = JSON.parse(config.data)['consentsByBrand'][this.loan.brand]['Additional'];
    })
  }

  submitForm() {
    if(this.checked.size !== 0) {
      const request: AdditionalAmountApplicationCreationRequest = {
        consents: this.checked
      }
      this.applicationService.createAdditionalAmountApplication(this.loan.clientId, request).pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
        this.alertService.notifySuccess(`Additional amount application created`);
        this.activeModal.close();
      })
    } else {
      this.alertService.notifyError('Mandatory consents should be checked!');
    }
  }

  checkConsent(event) {
    if(event.target.checked) {
      this.checked.set(event.target.value, true);
    } else {
      this.checked.delete(event.target.value);
    }
  }
}
