<div class="mt-1 mb-1">
    <button (click)="save()" class="btn btn-success  mr-2" [disabled]="!($showEditor | async)">Save</button>
    <button (click)="openAddLanguageModal()" class="btn btn-primary mr-2">Add language</button>
    <button (click)="openRemoveLanguageModal()" class="btn btn-danger mr-2" [disabled]="!($showEditor | async)">Remove</button> 
    <button (click)="init()" class="btn btn-outline-secondary" [disabled]="!($showEditor | async)">Reset</button> 
    <ng-container *ngIf="$tobeRemoved | async as tobeRemoved">
        To confirm to remove: "
        <ng-container *ngFor="let lang of tobeRemoved; let index = index">
            {{lang.languageTag}}
            <ng-container *ngIf="index !== tobeRemoved.length - 1">
                ,
            </ng-container>
        </ng-container>
        ", press save!
    </ng-container>
</div>

<json-editor
    name="translationJsonEditor"
    *ngIf="($showEditor | async)"
    (change)="onChange($event)"
    [data]="$data | async"
></json-editor>

<ng-template #noLangAddedTmpl>
    <h3>Please add at least one language!</h3>
</ng-template>