import { Injectable } from '@angular/core';
import { MailboxActionsService } from '@backoffice-monorepo/commons';
import { Task } from '@twino/backoffice-api';
import { PlPiTopicChangeModalComponent } from '../components/pl-pi-topic-change-modal/pl-pi-topic-change-modal.component';
import { PlPiClientChangeModalComponent } from '../components/pl-pi-client-change-modal/pl-pi-client-change-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class PlPiMailboxActionsService extends MailboxActionsService {
  constructor(
    private modalService: NgbModal
  ) {
    super();
  }

  openChangeTopicModal(task: Task): Promise<unknown> {
    const modalRef = this.modalService.open(PlPiTopicChangeModalComponent);
    modalRef.componentInstance.task = task;
    return modalRef.result;
  }
  openChangeClientModal(task: Task): Promise<unknown> {
    const modalRef = this.modalService.open(PlPiClientChangeModalComponent);
    modalRef.componentInstance.task = task;
    return modalRef.result;
  };
}
