import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, BatchJobService } from '@backoffice-monorepo/commons';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-batch-job-run',
  templateUrl: './batch-job-run.component.html',
  styleUrls: ['./batch-job-run.component.scss']
})
export class BatchJobRunComponent extends BasicComponent implements OnInit {

  jobForm: FormGroup;
  jobList$: Observable<string[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private batchJobService: BatchJobService,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.jobList$ = this.batchJobService.getJobList();
    this.jobForm = this.formBuilder.group({
      jobName: ['', Validators.required],
    });
  }

  submitForm() {
    this.batchJobService.runJob(this.jobForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Batch job run request is successful`);
      this.activeModal.close();
    })
  }
}
