import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationConfigsRefreshService implements OnDestroy{

  public reloadNotificationConfigsListModel$ = new Subject<void>();
  public reloadNotificationTemplateListModel$ = new Subject<void>();

  reloadNotificationConfigsListModel() {
    this.reloadNotificationConfigsListModel$.next();
  }

  reloadNotificationTemplateListModel() {
    this.reloadNotificationTemplateListModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadNotificationConfigsListModel$.unsubscribe();
  }
}
