import { Component, Input, OnInit } from '@angular/core';
import { ApplicationService, ClientSidebarLibComponent } from '@backoffice-monorepo/commons';
import { Application, FilterOperation, FullClient, SearchQueryBuilder } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-applications',
  templateUrl: './client-applications.component.html',
  styleUrls: ['./client-applications.component.scss']
})
export class ClientApplicationsComponent implements ClientSidebarLibComponent,OnInit {

  @Input()
  client: FullClient;
  applications: Application[] | null;

  constructor(
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [this.client.savedId.toString()]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .build()

    return this.applicationService.find(searchQuery).pipe(
      take(1)
    ).subscribe((applications) => {
      this.applications = applications.results;
    })
  }
}
