import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { concatMap, delay, share, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountdownService {
  private list: {[key: string]: Observable<number>} = {};

  getCountdown(token: string, duration: number): Observable<number> {
    const sessionStorageToken = this.createSessionStorageKey(token);
    if (this.list[sessionStorageToken]) {
      return this.list[sessionStorageToken];
    }

    const leftoverTime = this.getLeftoverTime(sessionStorageToken, duration);
    const countdown = this.createCountdown(leftoverTime);
    this.list[sessionStorageToken] = countdown;

    return countdown;
  }

  removeTimer(token: string) {
    const sessionStorageToken = this.createSessionStorageKey(token);
    this.deleteFromSessionStorage(sessionStorageToken);
    delete this.list[sessionStorageToken];
  }

  deleteFromSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  getTime(token: string, saveIfMissing = true) {
    const savedValue = this.getSavedPoint(token);
    const time = (savedValue) ? Number(savedValue) : Date.now();

    if (!savedValue && saveIfMissing) {
      this.saveStartingPoint(token, time);
    }
    return time;
  }

  getSavedPoint(token: string): string | number {
    return sessionStorage.getItem(token);
  }

  saveStartingPoint(token: string, time: number) {
    sessionStorage.setItem(token, String(time));
  }

  private createCountdown(leftoverTime: number): Observable<number> {
    const leftoverTimeInSeconds = Math.floor((leftoverTime / 1000));
    const values: Array<number> = [];

    for (let i = leftoverTimeInSeconds; i >= 0; i--) {
      values.push(i);
    }

    return from(values).pipe(
      concatMap(value => of(value).pipe(
        delay(1000)
      )),
      startWith(leftoverTimeInSeconds),
      share()
    );
  }

  private getLeftoverTime(token: string, duration: number, saveIfMissing = true) {
    const savedTime = this.getTime(token, saveIfMissing);
    const endTime = savedTime + duration;
    const leftover = endTime - Date.now();

    return (leftover < 0) ? 0 : leftover;
  }

  createSessionStorageKey(token: string): string {
    return `countdown_${token}`;
  }
}
