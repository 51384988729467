export interface Communication {
  id: number
  clientId?: number
  type: string
  direction: string
  destination: string
  sender: string
  templateKey: string
  subject?: string
  content?: string
  locale?: string
  attachmentsNames: Array<string>
  emailProperties?: EmailStorageProperties
  contentExternalFileUid?: string
  created: Date
  notificationSourceType?: string
  notificationSourceId?: number
}

export interface EmailStorageProperties {
  contentUid?: string
  attachments: Array<EmailStorageAttachmentProperties>
}

export interface EmailStorageAttachmentProperties {
  fileName: string
  uid: string
}

export enum CommunicationType {
  EMAIL = 'EMAIL',
  LETTER = 'LETTER'
}

export interface CommunicationRequest {
  sms?: SMS
  tts: boolean
  email?: EmailRequest
  push: boolean
  templateKey: string
  shouldStoreExternally: boolean
  shouldSendInCommunicationTimeWindow: boolean
  comment?: string
  brand?: string
}

export interface SMS {
  sender: string
  destination: string
  content: string
}

export interface EmailRequest {
  destination: string
  body: string
  subject: string
  isHtml: boolean
  from: string
  cc: string
  category: string
  attachments: any
  destinations?: Array<string>
  hasCc?: boolean
  ccs?: Array<string>
}

export interface ChangeTopicRequest {
  newTopic: string
}

export interface ReAssignClientRequest {
  newClientId: number
}

export interface EmailCommunicatiomConfig {
  emailAddresses: EmailConfig[]
  noreplyEmailAddress: string
}
export interface EmailConfig {
  emailAddress: string
  isDefault: boolean
}

export interface  ReplyToMailBoxTaskEmailRequest {
  email?: EmailRequest
  templateKey: string
  shouldStoreExternally: boolean
  shouldSendInCommunicationTimeWindow: boolean
  comment?: string
  brand?: string
}
