import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, ClientService, MessageTemplateService, SystemConfigService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { CommunicationRequest, MessageTemplate, SMS } from '@twino/backoffice-api';
import { of } from 'rxjs';
import { map, pluck, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'client-sms-send',
  templateUrl: './client-sms-send.component.html',
  styleUrls: ['./client-sms-send.component.scss']
})
export class ClientSmsSendComponent extends BasicComponent implements OnInit {
  @Input() clientId: number;
  @Input() mobilePhone: string;

  senders: string[];
  clientSmsSendForm: FormGroup;
  messageTemplates: MessageTemplate[] | undefined;
  smsRequest: SMS;
  communicationRequest: CommunicationRequest;
  brand: string | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService,
    private messageTemplateService: MessageTemplateService,
    private systemConfigService: SystemConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.systemConfigService.getSystemConfigRecordByName('communication_config').pipe(
      take(1)
    ).subscribe(communicationConfig => {
      this.senders = JSON.parse(communicationConfig.data)['smsSenderNamesByBrand'];
    })
    this.getManualMessageTemplates();
    this.clientSmsSendForm = this.formBuilder.group({
      sender: ['', Validators.required],
      destination: [{value: this.mobilePhone, disabled: true}, Validators.required],
      templateKey: ['', Validators.required],
      content: ''
    });

    this.clientSmsSendForm.get('templateKey').valueChanges
      .pipe(
        switchMap(key => {
          if (key) {
            return this.messageTemplateService.getMessageTemplatesByKey(key, this.brand)
              .pipe(
                pluck('results'),
                map(templates => templates[0])
              )
          }
          return of(null);
        }),
        takeUntil(this.$destroy)
      )
      .subscribe((template: MessageTemplate) => {
        if (template) {
          this.clientSmsSendForm.get('content').patchValue(template.message)
        }
      })

    this.clientSmsSendForm.get('sender').valueChanges
      .subscribe((sender: string) => {
        this.brand = sender.toUpperCase();
        this.getManualMessageTemplates();
      });
  }


  getManualMessageTemplates() {
    this.messageTemplateService.getManualMessageTemplates(this.brand).pipe(
      take(1)
    ).subscribe((templates) => {
      this.messageTemplates = templates?.results;
    })
  }

  submitForm() {
    this.smsRequest = {
      sender: this.clientSmsSendForm.controls.sender.value,
      destination: this.mobilePhone,
      content: this.clientSmsSendForm.controls.content.value
    };
    this.communicationRequest = {
      sms: this.smsRequest,
      tts: null,
      email: null,
      push: null,
      templateKey: this.clientSmsSendForm.controls.templateKey.value,
      shouldStoreExternally: false,
      shouldSendInCommunicationTimeWindow: true
    };
    this.clientService.sendClientCommunication(this.clientId, this.communicationRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Sms message sent`);
      this.clientsRefreshService.reloadClientCommunicationModel();
      this.activeModal.close();
    })
  }
}
