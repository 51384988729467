import { Injectable } from '@angular/core';
import { ClientSidebarComponentResolver, ClientSpecificComponentRecord } from '@backoffice-monorepo/commons';
import { PlPiClientCardViewComponent } from '../components/pl-pi-client-card-view/pl-pi-client-card-view.component';
import { PlPiClientAddressesComponent } from '../components/pl-pi-client-addresses/pl-pi-client-addresses.component';
import { PlPiClientBillingDetailsComponent } from '../components/pl-pi-client-billing-details/pl-pi-client-billing-details.component';
import { PlPiClientVasComponent } from '../components/pl-pi-client-vas/pl-pi-client-vas.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiClientSidebarComponentResolver implements ClientSidebarComponentResolver {

  resolve(): ClientSpecificComponentRecord[] {
    return [
      {index: 1, title: 'Client Addresses', type: PlPiClientAddressesComponent},
      {index: 2, title: 'Client Billing Details', type: PlPiClientBillingDetailsComponent},
      {index: 3, title: 'Client Cards', type: PlPiClientCardViewComponent},
      {index: 4, title: 'VAS', type: PlPiClientVasComponent},
    ]
  }

}
