import { Injectable, Type } from '@angular/core';
import { CountryViewComponent, TransferButtonsViewComponentResolver } from '@backoffice-monorepo/commons';
import { PlPiTransferButtonViewComponent } from '../components/pl-pi-transfer-button-view/pl-pi-transfer-button-view.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiTransferButtonViewComponentResolverService implements TransferButtonsViewComponentResolver {

  resolve(): Type<CountryViewComponent> {
    return PlPiTransferButtonViewComponent;
  }
}
