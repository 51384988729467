import { Component, Input } from '@angular/core';
import { CommissionRateParameters, ScheduleItemCommission } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-loan-commissions-view',
  templateUrl: './loan-commissions-view.component.html',
  styleUrls: ['./loan-commissions-view.component.css']
})
export class LoanCommissionsViewComponent  {

  @Input() mode: string;
  @Input() commissions: CommissionRateParameters[] | ScheduleItemCommission[];

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  trackById(index: number, item: CommissionRateParameters | ScheduleItemCommission){
    return item.accountType;
  }
}
