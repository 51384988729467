import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslationBuilderService } from '../../services/translation-builder.service';

@Component({
  selector: 'backoffice-monorepo-remove-language-modal',
  templateUrl: './remove-language-modal.component.html',
  styleUrls: ['./remove-language-modal.component.scss']
})
export class RemoveLanguageModalComponent  {
  form: FormGroup;
  $langs = this.translationBuilder.$langs;

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private translationBuilder: TranslationBuilderService
  ) { 
    this.form = this.formBuilder.group({
      language: []
    });
  }
}
