import { Component, Input, OnInit } from '@angular/core';
import { Offer } from '@twino/backoffice-api';
import {
  AlertsService, AMOUNT,
  BasicComponent, ConfirmDialogueComponent,
  CountryViewComponent, DATE,
  draggableModalOptions, STRING, TransformInstructions, TransformInstructionsBuilder,
} from '@backoffice-monorepo/commons';
import { PlPiOfferService } from '../../services/pl-pi-offer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiAcceptOfferModalComponent } from '../pl-pi-accept-offer-modal/pl-pi-accept-offer-modal.component';
import {
  AcceptOfferWithoutConfirmationRequest,
  BackofficeCalculateEstimatedValuesRequest,
  BackofficeEstimatedValuesResponse,
  OfferValueAddedServiceResponse,
  RecalculateOfferRequest
} from '../../api/pl-pi-offer';
import { take, takeUntil } from 'rxjs/operators';
import { ApplicationRefreshService } from '../../../../../../libs/core/src/lib/modules/applications/services/application-refresh.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PlPiAcceptOfferVasComponent } from '../pl-pi-accept-offer-vas/pl-pi-accept-offer-vas.component';
import { Router } from '@angular/router';

@Component({
  selector: 'backoffice-monorepo-pl-pi-accept-offer-button',
  templateUrl: './pl-pi-accept-offer-button.component.html',
  styleUrls: ['./pl-pi-accept-offer-button.component.scss']
})
export class PlPiAcceptOfferButtonComponent extends BasicComponent implements OnInit, CountryViewComponent {
  @Input() data: Offer;
  @Input() grantedOfferId: number;
  offer: Offer | null;
  recalculateOfferForm: FormGroup;
  step = 50;
  selectedAmount: number;
  vasAmount: number;
  disableButtons = false;
  disableAcceptButtons = false;
  estimatedValues: BackofficeEstimatedValuesResponse | null;
  estimatedValuesWithVAS: BackofficeEstimatedValuesResponse | null;
  offerVasesEvaluations: OfferValueAddedServiceResponse[] | null;
  vasIds: number[] = [];
  clientId: number;
  applicationId: number;

  constructor(
    private offerService: PlPiOfferService,
    private modalService: NgbModal,
    private applicationRefreshService: ApplicationRefreshService,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    protected router: Router,
  ) {
    super();
  }

  estimationsTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['annualPercentageRateOfCharge', ['Annual percentage rate of charge', AMOUNT]],
      ['minimumPayment', ['Minimum payment', AMOUNT]],
      ['nextRepaymentDate', ['Next repayment date', DATE]],
      ['nextDueDate', ['Next due date', DATE]],
      ['payInFull', ['Pay in full', STRING]],
      ['totalAmountToBePaidDuringTerm', ['Total amount to be paid during term', STRING]],
      ['sumOfCosts', ['Sum of costs', AMOUNT]],
    ]
  );

  ngOnInit(): void {
    this.offer = this.data;
    this.applicationId = this.data?.applicationId;
    this.clientId = this.offer?.clientId;
    this.selectedAmount = this.offer?.totalAmount;
    if (this.offer) {
      this.recalculateOfferForm = this.formBuilder.group({
        selectedAmount: [this.offer.totalAmount, [Validators.required,
          Validators.min(this.offer.minSelectablePrincipal),
          Validators.max(this.offer.maxSelectablePrincipal)]],
      });
      this.getEstimateCalculations(this.selectedAmount, false);
      this.evaluateOfferVases(this.selectedAmount);
      //this.acceptTariffView();
    }
  }

  sliderMove() {
    this.disableAcceptButtons = true;
  }

  sliderChanged() {
    return this.recalculateOfferForm.controls.selectedAmount.value !== this.offer.totalAmount;
  }

  showRecalculate() {
    return this.offer.minSelectablePrincipal !== this.offer.maxSelectablePrincipal;
  }

  getEstimateCalculations(amount: number, withVAS: boolean) {
    let getEstimate: Observable<BackofficeEstimatedValuesResponse>
    const request: BackofficeCalculateEstimatedValuesRequest = {
      amount: amount,
      clientId: this.offer.clientId,
      brand: this.offer.brand,
      selectedVasIds: (withVAS === true) ? this.vasIds : []
    }
    if (this.offer.purpose === "MAIN_AMOUNT") {
      getEstimate = this.offerService.calculateEstimatedValues(request);
    } else {
      getEstimate = this.offerService.calculateEstimatedValuesForCreditIncreaseFromBackoffice(request);
    }
    getEstimate.pipe(
      take(1)
    ).subscribe(response => {
      if(withVAS === true) {
        this.estimatedValuesWithVAS = response;
      } else {
        this.estimatedValues = response;
      }
    })
  }


  evaluateOfferVases(amount: number) {
    this.offerService.evaluateOfferVases(this.offer.applicationId, amount).pipe(
      take(1)
    ).subscribe(response => {
      this.offerVasesEvaluations = response;
      this.vasIds = this.offerVasesEvaluations.map(x => x.id);
      this.vasAmount = this.calculateOfferVAS();
      this.getEstimateCalculations(this.selectedAmount, true);
    })
  }

  recalculateOffer() {
    this.disableAcceptButtons = false;
    this.selectedAmount = this.recalculateOfferForm.controls.selectedAmount.value;
    const request: RecalculateOfferRequest = {
      currentGrantedOfferId: this.grantedOfferId,
      selectedAmount: this.recalculateOfferForm.controls.selectedAmount.value,
      selectedVasIds: this.vasIds
    }
    this.offerService.recalculateOffer(request).pipe(
      takeUntil(this.$destroy)
    ).subscribe(offerPreview => {
      this.offer = offerPreview;
      this.getEstimateCalculations(this.recalculateOfferForm.controls.selectedAmount.value, false);
      this.evaluateOfferVases(this.recalculateOfferForm.controls.selectedAmount.value);
      this.alertService.notifySuccess('Offer recalculated');
      this.applicationRefreshService.reloadOfferModel(offerPreview);
    });
  }

  acceptOffer(hasVAS: boolean) {
    const modalRef = this.modalService.open(PlPiAcceptOfferModalComponent);
    modalRef.componentInstance.offer = this.offer;
    modalRef.componentInstance.grantedOfferId = this.grantedOfferId;
    modalRef.componentInstance.selectedAmount = this.selectedAmount;
    modalRef.componentInstance.hasVAS = hasVAS;
    modalRef.result.then(
      (result) => {
        if(result === true) {
          this.applicationRefreshService.reloadApplicationModel();
          this.offer = null;
          this.acceptTariffView();
        } else {
          this.setOfferVAS(true, true);
        }
      }, () => {}
    );
  }

  acceptOfferWithoutFastcash() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent , draggableModalOptions);
    modalRef.componentInstance.header = `Accept offer without fastcash`;
    modalRef.componentInstance.content = `Are you sure?`;
    modalRef.componentInstance.offer = this.offer;
    modalRef.result.then(
      () => {
        const request: AcceptOfferWithoutConfirmationRequest ={
          currentGrantedOfferId: this.grantedOfferId,
          selectedAmount: this.selectedAmount,
        }
        this.offerService.acceptOfferWithoutOtpConfirmation(request).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.alertService.notifySuccess(`Offer without Fastcash accepted`);
          this.disableButtons = true;
          this.applicationRefreshService.reloadApplicationModel();
          this.offer = null;
          this.acceptTariffView();
        });
      }, () => {}
    );
  }

  acceptOfferWithoutFastcashVAS() {
    const modalRef = this.modalService.open(PlPiAcceptOfferVasComponent, draggableModalOptions);
    modalRef.componentInstance.header = `Accept offer without Fastcash`;
    modalRef.componentInstance.content = `Are you sure?`;
    modalRef.componentInstance.offer = this.offer;
    modalRef.componentInstance.grantedOfferId = this.grantedOfferId;
    modalRef.componentInstance.selectedAmount = this.selectedAmount;
    modalRef.result.then(
      (result) => {
        if(result === true) {
          this.disableButtons = true;
          this.applicationRefreshService.reloadApplicationModel();
          this.offer = null;
          this.acceptTariffView();
        } else {
          this.setOfferVAS(true, false);
        }

      }, () => {}
    );
  }

  acceptTariffView() {
    this.router.navigate([`/tariff/application/${this.applicationId}`]);
  }

  setOfferVAS(reset: boolean, fastCash: boolean) {
    const vasIDs = (reset === true)? [] : this.vasIds;
    this.offerService.setOfferVases(this.grantedOfferId, vasIDs).pipe(
      take(1)
    ).subscribe(response => {
      if(reset === false) {
        if(fastCash) {
          this.acceptOffer(true)
        } else {
          this.acceptOfferWithoutFastcashVAS();
        }
      }
    })
  }

  calculateOfferVAS(): number {
    return this.offerVasesEvaluations.reduce((acc, val) => {
      if (val.id !== 2) return acc + val.amount;
      return acc;
    }, 0);
  }

  trackById(index: number, item: any){
    return item.id;
  }
}
