<form [formGroup]="loanClosureForm" (ngSubmit)="submitForm()">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Close loan #{{loanId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="loanStatusDetail" class="col-sm-4 control-label">Loan status detail<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <select class="form-control form-control-sm" id="loanStatusDetail" formControlName="loanStatusDetail" name="loanStatusDetail">
          <option *ngFor="let key of loanStatusDetailsOptions" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="terminationStartDate" class="col-sm-4 control-label">Termination date<sup class="text-danger">*</sup></label>
      <div class="col-sm-8 input-group">
        <input class="form-control form-control-sm"
               id="terminationStartDate"
               formControlName="terminationStartDate"
               placeholder="yyyy-mm-dd"
               name="terminationStartDate"
               ngbDatepicker
               #terminationStartDate="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="terminationStartDate.toggle()" type="button"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!loanClosureForm.valid">Save</button>
  </div>
</form>
