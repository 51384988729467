import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { SortDirection, SortEvent } from '@twino/backoffice-api';

const rotateSort: { [key: string]: SortDirection } = {'': 'DESC', 'DESC': 'ASC', 'ASC': ''};

@Directive({
  selector: 'th[sortable]'
})
export class ThSortableDirective {

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  @HostBinding('class.asc') get asc() { return this.direction === 'ASC'; }

  @HostBinding('class.desc') get desc() { return this.direction === 'DESC'; }

  @HostListener('click')
  rotateSort() {
    this.direction = rotateSort[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}
