<div class="modal-header">
  <h4 class="modal-title">View transaction details</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <transform-table [transformInstructions]="transactionDetailsTransformations" [entity]="transactionDetails"></transform-table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
</div>

