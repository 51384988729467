<form cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Increase limit</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Consent</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of configConsents | keyvalue">
          <td class="w-25 text-center">
            <input
              type="checkbox"
              class="btn"
              value="{{item.key}}"
              (change)="checkConsent($event)">
          </td>
          <td class="w-75">{{item.value.text}}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" (click)="submitForm()">Submit</button>
  </div>
</form>
