<span class="limited-span" [ngStyle]="{'max-width.ch': maxLength}">{{value}}</span>
<button
  *ngIf="(value?.toString()?.length || 0) >= maxLength"
  [ngbPopover]="value"
  class="btn btn-sm"
  placement="bottom"
  triggers="mouseenter:mouseleave"
  (click)="onClick()"
>
  ...
</button>
