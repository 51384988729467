<aside class="container-fluid pt-3 pb-3">
  <div class="col-md-12 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
    <!-- region buttons -->
    <span class="mr-2 ml-3">
          <button appRequirePermission="admin.all,loan-application.edit"
                  class="btn btn-danger btn-sm"
                  *ngIf="showReevaluationButton"
                  [disabled]="disableReevaluationButton"
                  (click)="reEvaluateApplication()">
            <bi name="pencil-square"></bi>
            Reevaluate
          </button>
    </span>
    <ndc-dynamic
      [ndcDynamicComponent]="loanApplicationButtonsViewComponentType"
      [ndcDynamicInputs]="{data: application, grantedOfferId: grantedOfferId}"
    ></ndc-dynamic>
    <!-- endregion -->
  </div>
</aside>
  <!-- region loanApplication -->
  <div class="container-fluid row pt-1 pb-2">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header text-light bg-primary">
            <bi name="briefcase"></bi>
            Application
            <button appRequirePermission="admin.all,scoring.read"
                    class="btn btn-sm btn-light float-right mr-1"
                    (click)="openResolutions()">
              All resolutions
            </button>
            <button appRequirePermission="admin.all,scoring.read"
                    class="btn btn-sm btn-light float-right mr-1"
                    (click)="openResolutionTree()">
              Resolutions in tree
            </button>
          </div>
          <div class="card-text">
              <transform-table [transformInstructions]="loanApplicationTransformations" [entity]="application"></transform-table>
            <table class="table table-striped table-hover table-sm mb-0">
              <colgroup>
                <col class="table-primary"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <td class="text-right w-50">IP</td>
                <td>{{ipAddress}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-header text-light bg-primary">
            <bi name="pencil-square"></bi>
            Offer
          </div>
          <div class="card-text">
            <ndc-dynamic
              *ngIf="showOfferAcceptButton"
              [ndcDynamicComponent]="acceptOfferButtonComponentType"
              [ndcDynamicInputs]="{data: offer, grantedOfferId: grantedOfferId}"
            ></ndc-dynamic>
            <transform-table [transformInstructions]="offerTransformations" [entity]="offer"></transform-table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- region resolutions -->
        <div class="card" *ngIf="isOpenResolutions">
          <div class="card-header text-light bg-primary">
            <bi name="card-text"></bi>
            Resolutions
          </div>
          <div class="card-text">
            <table class="table mt-2 p-1">
              <thead>
              <tr>
                <th>Evaluation Name</th>
                <th>Rule Results</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of evaluationResults">
                <td>{{ item.evaluationName }}</td>
                <td>
                  <table class="table table-striped w-100">
                    <thead class="text-light bg-primary">
                    <tr>
                      <th class="w-75">Rule Name</th>
                      <th class="w-25 text-left">Resolution</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let ruleResult of item.ruleResults">
                      <td class="w-75">{{ ruleResult.ruleName }}</td>
                      <td class="w-25 text-left">{{ ruleResult.resolution }}</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card" *ngIf="isOpenResolutionsTree">
          <div class="card-header text-light bg-primary">
            <bi name="card-text"></bi>
            Resolutions Tree View
          </div>
          <div class="card-text">
            <div class="col-md-12 mt-2 bg-light">
              <pre><code>{{ evaluationResults | json }}</code></pre>
            </div>
          </div>
        </div>
        <!-- endregion -->
      </div>
  </div>
  <!--endregion -->





