import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  AlertsService,
  BasicComponent,
  DateUtils,
  RepaymentProposalService,
  SystemConfigService,
  TwinoDateAdapterService
} from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateRepaymentProposalRequest } from '@twino/backoffice-api';
import { LoansRefreshService } from '../../services/loans-refresh-services';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'loan-repayment-proposal-add',
  templateUrl: './loan-repayment-proposal-add.component.html',
  styleUrls: ['./loan-repayment-proposal-add.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class LoanRepaymentProposalAddComponent extends BasicComponent implements OnInit {

  @Input() loanId: number;

  loanRepaymentProposalAddForm: FormGroup;
  createRepaymentProposalRequest: CreateRepaymentProposalRequest;
  minRepaymentAmount: number;

  now = new Date();
  fromDateValue = DateUtils.dateNow();
  minDate = this.datePickerRangeConfig(this.fromDateValue);
  maxDate: NgbDateStruct;
  maxDays: number | null;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private repaymentProposalService: RepaymentProposalService,
    private loanRefreshService: LoansRefreshService,
    private systemConfigService: SystemConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.systemConfigService.getSystemConfigRecordByName('repayment_proposal_config').pipe(
      take(1)
    ).subscribe(repaymentProposalConfig => {
      this.maxDays = JSON.parse(repaymentProposalConfig.data)['maxDays'];
      this.maxDate = this.datePickerRangeConfig(DateUtils.dateNowPlusDays(this.maxDays));
    })
    this.loanRepaymentProposalAddForm = this.formBuilder.group({
      amount: ['', Validators.required],
      fromDate: [{value: this.fromDateValue, disabled: true}],
      toDate: ['', Validators.required],
      isEnabled: false
    });
    this.repaymentProposalService.getMinRepaymentAmount(this.loanId).pipe(
      take(1)
    ).subscribe(minAmount => {
      this.minRepaymentAmount = minAmount;
    })
  }

  submitForm() {
    const createRepaymentProposalRequest: CreateRepaymentProposalRequest = {
      loanId: this.loanId,
      amount: this.loanRepaymentProposalAddForm.value.amount,
      fromDate : this.now,
      toDate: this.loanRepaymentProposalAddForm.value.toDate,
      isEnabled: this.loanRepaymentProposalAddForm.value.isEnabled
    }
    this.repaymentProposalService.createRepaymentProposal(createRepaymentProposalRequest)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.loanRefreshService.reloadLoanRepaymentProposalsModel();
      this.alertService.notifySuccess("Repayment proposal is added");
      this.activeModal.close();
    })
  }

  datePickerRangeConfig(dateString: string): NgbDateStruct {
    const dateArray = dateString.split("-");
    return {year: +dateArray[0], month: +dateArray[1], day: +dateArray[2]};
  }
}
