<form [formGroup]="transferRedirectForm" (ngSubmit)="submitForm()">
  <div class="modal-header">
    <h4 class="modal-title">Redirect transfer #{{transferId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="loanId" class="col-sm-4 control-label">Loan<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="hidden" class="form-control form-control-sm" id="loanId" formControlName="loanId" name="loanId">
        <loan-lookup (loanSelected)="transferRedirectForm.controls.loanId.setValue($event)"></loan-lookup>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!transferRedirectForm.valid">Save</button>
  </div>
</form>





