import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  AcceptOfferConfirmationRequest,
  AcceptOfferWithoutConfirmationRequest,
  InitiateAcceptOfferWithConfirmationResponse,
  BackofficeCalculateEstimatedValuesRequest,
  BackofficeEstimatedValuesResponse,
  InitiateAcceptOfferWithConfirmationRequest,
  LoanOfferPreviewWithInitialTransferLimitsResponse,
  OfferValueAddedServiceResponse,
  RecalculateOfferRequest,
  RefinanceLoanOfferDetailsResponse,
  VasParamsResponse
} from '../api/pl-pi-offer';
import { OfferPreview } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class PlPiOfferService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getInitialTransferLimits(request: RecalculateOfferRequest): Observable<LoanOfferPreviewWithInitialTransferLimitsResponse> {
    return this.post(`/api/pl/offer/initial/transfer/limits`, request);
  }

  initiateAcceptOfferWithOtpConfirmation(request: InitiateAcceptOfferWithConfirmationRequest): Observable<InitiateAcceptOfferWithConfirmationResponse> {
    return this.post(`/api/pl/offer/initiate-accept-with-confirmation`, request);
  }

  confirmAcceptOfferOtp(offerId: number, request: AcceptOfferConfirmationRequest): Observable<void> {
    return this.post(`/api/pl/offer/${offerId}/accept-confirmation`, request);
  }

  acceptOfferWithoutOtpConfirmation(request: AcceptOfferWithoutConfirmationRequest): Observable<void> {
    return this.put(`/api/pl/offer/accept-without-confirmation`, request);
  }

  getRefinanceLoanOfferDetails(offerId: number): Observable<RefinanceLoanOfferDetailsResponse> {
    return this.get(`/api/pl/offer/${offerId}/refinance-details`);
  }

  recalculateOffer(request: RecalculateOfferRequest): Observable<OfferPreview> {
    return this.post(`/api/pl/offer/preview/recalculate-offer`, request);
  }

  calculateEstimatedValues(
    request: BackofficeCalculateEstimatedValuesRequest
  ): Observable<BackofficeEstimatedValuesResponse> {
    return this.post(`/api/pl/estimations/calculate`, request);
  }
  calculateEstimatedValuesForCreditIncreaseFromBackoffice(
    request: BackofficeCalculateEstimatedValuesRequest
  ): Observable<BackofficeEstimatedValuesResponse> {
    return this.post(`/api/pl/estimations/calculate-credit-limit-increase`, request);
  }

  getOfferVases(offerId: number): Observable<OfferValueAddedServiceResponse[]> {
    return this.get(`/api/pl/offer/${offerId}/vases`);
  }

  evaluateOfferVases(applicationId: number, amount: number): Observable<OfferValueAddedServiceResponse[]> {
    return this.get(`/api/pl/offer/application/${applicationId}/calculate-vases?amount=${amount}`);
  }

  setOfferVases(offerId: number, vasIds: number[]): Observable<unknown> {
    return this.post(`/api/pl/offer/${offerId}/vases`, vasIds);
  }

  getVasParams(): Observable<VasParamsResponse> {
    return this.get(`/api/pl/vas/params`);
  }
}
