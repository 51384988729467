import { DetailsNavigationStep } from '../enums/details-navigation-step.enum';
import { ClientApplicationFlowType, PlPiFullClient } from '../api/pl-pi-full-client';

const prefix = '/phone-application/';

export enum PhoneApplicationType {
  CLIENT_NEW = 'client-new',
  CLIENT_REPEATED = 'client-repeated',
  MIGRATED_NEW = 'migrated-new',
  MIGRATED_REFINANCED = 'migrated-refinanced',
  MIGRATED_REPEATED = 'migrated-repeated'
}

export const DetailsStepUrls: Record<DetailsNavigationStep, string> = {
  [DetailsNavigationStep.INITIAL_INFORMATION]: 'client-data',
  [DetailsNavigationStep.OTP]: 'otp',
  [DetailsNavigationStep.PERSONAL_INFORMATION]: 'personal-information',
  [DetailsNavigationStep.ADDRESS]: 'address',
  [DetailsNavigationStep.FINANCIAL_INFORMATION]: 'financial-information',
}

export function createPhoneApplicationUrl(phoneApplicationType: PhoneApplicationType, detailsStep: DetailsNavigationStep): string {
  return `${prefix}${phoneApplicationType}/${DetailsStepUrls[detailsStep]}`;
}

export function getPhoneApplicationType(client: PlPiFullClient): PhoneApplicationType {
  const {clientApplicationFlowType, applicableProductSubtype} = client;

  if (clientApplicationFlowType === ClientApplicationFlowType.NEW && !applicableProductSubtype) {
    return PhoneApplicationType.CLIENT_NEW;
  }

  if ([ClientApplicationFlowType.NEW, ClientApplicationFlowType.REPEATED].includes(clientApplicationFlowType)) {
    return PhoneApplicationType[`CLIENT_${applicableProductSubtype}`];
  }

  return PhoneApplicationType[`${clientApplicationFlowType}_${applicableProductSubtype}`];
}
