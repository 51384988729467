import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  ProductConfigurationList,
  ProductConfigurationRequest,
  ProductConfigurationsToVersionsMatchingCheckResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  listProductConfigs(): Observable<Array<ProductConfigurationList>> {
    return this.get('/api/product-configurations/list');
  }

  listProductTypes(): Observable<string[]> {
    return this.get('/api/product-configuration/product-types');
  }

  listProductSubTypes(): Observable<string[]> {
    return this.get('/api/product-configuration/product-sub-types');
  }

  listBrands(): Observable<string[]> {
    return this.get('/api/product-configuration/brands');
  }

  updateProductConfig(productSubType: string, productConfigurationRequest: ProductConfigurationRequest) {
    return this.post(
      `/api/product-configuration/${productConfigurationRequest.name}/${productSubType}`,
      productConfigurationRequest.data
    );
  }

  updateProductConfigByBrand(productSubType: string, productConfigurationRequest: ProductConfigurationRequest, brand: string) {
    return this.post(
      `/api/product-configuration/${productConfigurationRequest.name}/${productSubType}/${brand}`,
      productConfigurationRequest.data
    );
  }

  getProductConfigRecord(productType: string, productSubType: string): Observable<Record<string, unknown | null>> {
    return this.get(`/api/product-configuration/${productType}/${productSubType}`);
  }

  getProductConfigRecordByBrand(productType: string, productSubType: string, brand: string): Observable<Record<string, unknown | null>> {
    return this.get(`/api/product-configuration/${productType}/${productSubType}/${brand}`);
  }

  saveProductConfigurationState(): Observable<unknown> {
    return this.post(
      `/api/product-configurations/versions`, {}
    );
  }

  checkProductConfigurationVersion(): Observable<ProductConfigurationsToVersionsMatchingCheckResponse> {
    return this.get(`/api/product-configurations/product-configuration-to-version-matches`);
  }
}

