import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NamedComponent } from '@backoffice-monorepo/commons';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { TranslationBuilderService } from '../../services/translation-builder.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddLanguageModalComponent } from '../../components/add-language-modal/add-language-modal.component';
import { from, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RawTranslation } from '@backoffice-monorepo/api';
import { RemoveLanguageModalComponent } from '../../components/remove-language-modal/remove-language-modal.component';

@Component({
  selector: 'backoffice-monorepo-translations',
  templateUrl: './translations.page.html',
  styleUrls: ['./translations.page.scss']
})
export class TranslationsPage extends NamedComponent implements AfterViewInit {
  $data: Observable<Record<string, unknown>>;
  $showEditor = this.translationBuilder.$schema.pipe(
    tap((schema) => this.setEditorOptions(schema)),
    map(schema => !!schema)
  );

  $tobeRemoved = this.translationBuilder.$removeFromServer;
  
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  constructor(
    private translationBuilder: TranslationBuilderService,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    super(activatedRoute);

    this.$data = this.translationBuilder.$dataStructure;
  }

  ngAfterViewInit() {
      this.init()
  }
  
  init() {
    this.translationBuilder.reset(true);
  }

  getName() {
    return 'Translation manager'
  }

  private setEditorOptions(schema: any) {
    setTimeout(() => {
      const editorOptions = new JsonEditorOptions();
      editorOptions.modes = ['code', 'tree', 'view', 'form'];
      editorOptions.schema = schema;
      this.editor.setOptions(editorOptions)
    }, 0)
  }

  openAddLanguageModal() {
    from(this.modalService.open(AddLanguageModalComponent).result as Promise<{langCode: string, translationJSON: any}>).pipe(
      filter(result => (!!result && !!result.langCode)),
      switchMap((result) => this.addLang(result.langCode, result.translationJSON !== {} ? result.translationJSON : false))
    ).subscribe();
  }

  openRemoveLanguageModal() {
    from(this.modalService.open(RemoveLanguageModalComponent).result as Promise<string>).pipe(
      filter(res => !!res),
      switchMap(res => this.translationBuilder.removeLang(res))
    ).subscribe()
  }

  addLang(lang: string, translationJSON: Record<string, unknown>) {
    const newLang:  Partial<RawTranslation> = {
      data: Object.keys(translationJSON).length === 0 ? null : translationJSON,
      isBase: false,
      languageTag: lang
    };
    return this.translationBuilder.addLang(newLang);
  }

  onChange(e: Record<string, unknown>) {
    if (e instanceof Event) {
      return;
    }
    this.translationBuilder.setTransitionData(e);
  }

  save() {
    this.translationBuilder.saveAll().subscribe();
  }
}
