import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RawApiTranslation, RawTranslation } from '@backoffice-monorepo/api';

@Injectable({
  providedIn: 'root'
})
export class TranslationManagerApiService extends BaseService {

  constructor(http: HttpClient, errors: ErrorsService) {
    super(http, errors)
  }

  insert({languageTag, isBase, data}: RawTranslation): Observable<any> {
    return this.http.post(this.baseUrl('api/languagedata'), {languageTag, isBase, data: JSON.stringify(data)});
  }

  update({id, data, isBase, simpleVersion}: RawTranslation): Observable<any> {
    const replacer = (key, value) => typeof value === 'undefined' ? null : value;
    return this.http.put(this.baseUrl(`api/languagedata/${id}`), { data: JSON.stringify(data, replacer), isBase, startingSimpleVersion: simpleVersion})
  }

  remove(id: RawTranslation['id']): Observable<any> {
    return this.http.delete(this.baseUrl(`api/languagedata/${id}`));
  }

  listAll(): Observable<RawTranslation[]> {
    return this.http.get<RawApiTranslation[]>(this.baseUrl('api/languagedata/list')).pipe(
      map(res => res.map(langData => ({...langData, data: JSON.parse(langData.data)}))),
    );
  }
}
