<div id="overlay" *ngIf="overlayEnabled$ | async">
  <div class="loading">
    <i class="spinner-border text-secondary"></i>
  </div>
</div>

<div id="root-container">
  <div class="global-error fade-in alert alert-danger" *ngIf="globalErrors.forbidden">
    <section>
      Access forbidden
    </section>
  </div>
  <div class="global-error fade-in alert alert-danger" *ngIf="globalErrors.notFound">
    <section>
      Resource not found: {{globalErrors.notFound.error?.path}} {{globalErrors.notFound.message}}
    </section>
  </div>
  <div class="global-error fade-in alert alert-danger" *ngIf="globalErrors.requestFailed">
    <section>
      Request failed: {{globalErrors.requestFailed.error?.path}} {{globalErrors.requestFailed.message}}
    </section>
  </div>
  <div class="global-error fade-in alert alert-danger" *ngIf="globalErrors.requestTooLarge">
    <section>
      Request is too large: {{globalErrors.requestTooLarge.error?.path}}
    </section>
  </div>
  <div class="global-error fade-in alert alert-danger" *ngIf="globalErrors.uploadFailed">
    <section>
      Failed to upload file: {{globalErrors.uploadFailed.error?.path}} {{globalErrors.uploadFailed.message}}
    </section>
  </div>
  <div class="global-error fade-in" *ngIf="globalErrors.newVersion">
    <section>
      Application updated. <a href="" (click)="reloadPage()"><strong>Click here to refresh</strong></a>
    </section>
  </div>
  <div class="global-error fade-in alert alert-danger" *ngIf="globalErrors.sessionExpired">
    <section>
      Session expired. <a href="" (click)="reloadPage()"><strong>Click here to refresh</strong></a>
    </section>
  </div>
  <div class="global-error fade-in alert alert-danger" *ngIf="globalErrors.connectionLost">
    <section>
      Lost connection to the server
    </section>
  </div>
</div>

<toasts-container aria-live="polite" aria-atomic="true"></toasts-container>

