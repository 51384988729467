import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  Application,
  ApplicationWebData,
  EnumResponse,
  EvaluationResult,
  Offer, SearchCsvExportRequest,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends BaseService implements SearchQueryAware<Application> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<Application>> {
    return this.post(`/api/loan-applications/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/loan-applications/enum-values/${enumClass}`);
  }

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/loan-applications/export-csv`, exportRequest, {'responseType': 'blob'});
  }

  getApplication(id: number): Observable<Application> {
    return this.get<Application>(`/api/loan-application/${id}`);
  }

  getApplicationAllOffers(id: number): Observable<Array<Offer>> {
    return this.get(`/api/loan-application/${id}/all-offers`);
  }

  getApplicationGrantedOffers(id: number): Observable<Array<Offer>> {
    return this.get(`/api/loan-application/${id}/granted-offers`);
  }

  getApplicationWebData(id: number): Observable<ApplicationWebData> {
    return this.get<ApplicationWebData>(`/api/loan-application/${id}/web-data`);
  }

  getApplicationEvaluationResults(id: number): Observable<Array<EvaluationResult>> {
    return this.get(`/api/loan-application/${id}/evaluation-results`);
  }

  getApplicationDetails(id: number): Observable<Application> {
    return this.get<Application>(`/api/loan-application/${id}/details`);
  }

  reEvaluateApplication(id: number) {
    return this.post(`/api/loan-application/${id}/re-evaluate`, {});
  }
}
