import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  EnumResponse,
  FilterOperation, JoinStrategy,
  MessageTemplateRequest,
  SearchQuery,
  SearchQueryAware,
  SearchQueryBuilder,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';
import { MessageTemplate } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService extends BaseService implements SearchQueryAware<MessageTemplate> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<MessageTemplate>> {
    return this.post(`/api/template/message-templates/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/template/message-templates/enum-values/${enumClass}`);
  }

  getMessageTemplates(): Observable<SearchResponse<MessageTemplate>> {
    return this.post(`/api/template/message-templates/list`, {});
  }

  getMessageTemplate(id: number): Observable<MessageTemplate> {
    return this.get(`/api/template/message-template/${id}`, {});
  }

  createMessageTemplate(messageTemplateRequest: MessageTemplateRequest) {
    return this.post(`/api/template/message-template`, messageTemplateRequest);
  }

  updateMessageTemplate(id: number, messageTemplateRequest: MessageTemplateRequest) {
    return this.post(`/api/template/message-template/${id}`, messageTemplateRequest);
  }

  deleteMessageTemplate(id: number): Observable<unknown> {
    return this.delete(`/api/template/message-template/${id}`, {});
  }

  getMessageTemplatesByKey(key: string, brand: string | null): Observable<SearchResponse<MessageTemplate>> {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(60)
      .addFilterData({
        propertyName: 'key',
        operation: FilterOperation.CONTAINS,
        values: [key]
      });

    if(brand !== null) {
      searchQuery.addFilterData({
        propertyName: 'brand',
        operation: FilterOperation.EQUALS,
        values: [brand]
      }).withJoinStrategy(JoinStrategy.AND)
    }
    return this.post(`/api/template/message-templates/list`, searchQuery.build());
  }

  getManualMessageTemplates(brand: string | null): Observable<SearchResponse<MessageTemplate>> {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(60)
      .addFilterData({
        propertyName: 'key',
        operation: FilterOperation.CONTAINS,
        values: ['manual']
      });

    if(brand !== null) {
      searchQuery.addFilterData({
        propertyName: 'brand',
        operation: FilterOperation.EQUALS,
        values: [brand]
      }).withJoinStrategy(JoinStrategy.AND)
    }

    return this.post(`/api/template/message-templates/list`, searchQuery.build());
  }

  getMessageTemplatesByBrand(brand: string | null): Observable<SearchResponse<MessageTemplate>> {
    if(brand !== null) {
      const searchQuery = new SearchQueryBuilder()
        .withPageSize(60)
        .addFilterData({
          propertyName: 'brand',
          operation: FilterOperation.EQUALS,
          values: [brand]
        });

      return this.post(`/api/template/message-templates/list`, searchQuery.build());
    } else {
      return this.post(`/api/template/message-templates/list`, {});
    }
  }
}
