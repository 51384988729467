<aside class="container-fluid pt-3 pb-3">
  <div class="col-md-12 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
  </div>
</aside>

<div class="container-fluid row pt-1 pb-2">
  <div class="col-md-3">
    <div class="card">
      <div class="card-header text-light bg-primary">
        Job execution
      </div>
      <div class="card-text">
        <transform-table [transformInstructions]="batchJobExecutionTransformations" [entity]="batchJobExecution"></transform-table>
      </div>
    </div>
  </div>

  <div class="col-md-9">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th>Id</th>
          <th>Version</th>
          <th>Name</th>
          <th>Status</th>
          <th>Start time</th>
          <th>End time</th>
          <th>Exit code</th>
          <th>Exit description</th>
          <th>Read count</th>
          <th>Write count</th>
          <th>Commit count</th>
          <th>Rollback count</th>
          <th>Read skip count</th>
          <th>Process skip count</th>
          <th>Write skip count</th>
          <th>Filter count</th>
        </tr>
        </thead>
        <tbody *ngIf="batchJobExecution">
          <tr *ngFor="let stepExecution of batchJobExecution.stepExecutions">
            <td>{{ stepExecution.id }}</td>
            <td>{{ stepExecution.version }}</td>
            <td>{{ stepExecution.name }}</td>
            <td>{{ stepExecution.status }}</td>
            <td>{{ stepExecution.startTime }}</td>
            <td>{{ stepExecution.endTime }}</td>
            <td>{{ stepExecution.exitCode }}</td>
            <td>
              <backoffice-monorepo-limited-field [value]="stepExecution.exitDescription" maxLength="15"></backoffice-monorepo-limited-field>
            </td>
            <td>{{ stepExecution.readCount }}</td>
            <td>{{ stepExecution.writeCount }}</td>
            <td>{{ stepExecution.commitCount }}</td>
            <td>{{ stepExecution.rollbackCount }}</td>
            <td>{{ stepExecution.readSkipCount }}</td>
            <td>{{ stepExecution.processSkipCount }}</td>
            <td>{{ stepExecution.writeSkipCount }}</td>
            <td>{{ stepExecution.filterCount }}</td>
          </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>
