import { Component, Host, OnInit } from '@angular/core';
import {
  ActivityOverviewService,
  draggableModalOptionsLarge,
  FilteringService,
  ListComponent
} from '@backoffice-monorepo/commons';
import {
  ActivityOverview,
  ActivityOverviewCommunicationType, ActivityOverviewType,
  SearchQueryAware,
  SearchQueryBuilder
} from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationContentViewComponent } from '../../../communications/components/communication-content-view/communication-content-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-activity-overview-list',
  templateUrl: './activity-overview-list.component.html',
  styleUrls: ['./activity-overview-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<ActivityOverview>) => new FilteringService(listService),
      deps: [ActivityOverviewService]
    }
  ]
})
export class ActivityOverviewListComponent extends ListComponent<ActivityOverview> implements OnInit {

  CommunicationType = ActivityOverviewCommunicationType;
  ActivityOverviewType = ActivityOverviewType;
  exportModuleName = "activity-overview";

  constructor(
    @Host() filteringService: FilteringService<ActivityOverview>,
    protected router: Router,
    private activityOverviewService: ActivityOverviewService,
    private modalService: NgbModal,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    const searchQueryBuilder = new SearchQueryBuilder()
      .withSortCriterion({propertyName: "created", sortDirection: "DESC"});
    this.searchQuery = searchQueryBuilder.build();

    super.ngOnInit();
  }

  getName(): string {
    return 'Activity overview';
  }

  navigateTo(id: number) {
    this.router.navigate([`/activity-overview/${id}`]);
  }

  viewContent(communicationId) {
    const modalRef = this.modalService.open(CommunicationContentViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.communicationId = communicationId;
  }

}
