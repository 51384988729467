import { Component, Input } from '@angular/core';
import {
  BasicComponent,
  ConfirmDialogueComponent,
  CountryViewComponent,
  PaymentService
} from '@backoffice-monorepo/commons';
import { Payment, PaymentDirection, PaymentStatus } from '@twino/backoffice-api';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'backoffice-monorepo-pl-pi-payment-button-view',
  templateUrl: './pl-pi-payment-button-view.component.html',
  styleUrls: ['./pl-pi-payment-button-view.component.css']
})
export class PlPiPaymentButtonViewComponent extends BasicComponent implements CountryViewComponent {

  @Input()
  data: Payment;

  constructor(
    private paymentService: PaymentService,
    private modalService: NgbModal,
  ) {
    super();
  }


  processPayment() {
   const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Process payment";
    modalRef.componentInstance.content = "Are you sure you want to process this payment?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.paymentService.processPayment(this.data.id).pipe(
          takeUntil(this.$destroy)
        ).subscribe();
      }, () => {}
    );
  }

  showProcessOutgoingButton() {
    return this.data.direction === PaymentDirection.OUTGOING && this.data.status === PaymentStatus.MANUAL_PROCESSING
  }
}
