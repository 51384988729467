import { Component, Input, OnInit } from '@angular/core';
import {
  FilterOperation,
  FullLoan,
  JoinStrategy,
  PaymentDistribution,
  PaymentStatus,
  SearchQueryBuilder,
  SearchResponse
} from '@twino/backoffice-api';
import { LoanSidebarLibComponent, PaymentDistributionService } from '@backoffice-monorepo/commons';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-loan-payment-distributions',
  templateUrl: './loan-payment-distributions.component.html',
  styleUrls: ['./loan-payment-distributions.component.scss']
})
export class LoanPaymentDistributionsComponent implements LoanSidebarLibComponent, OnInit {
  @Input()
  loan: FullLoan;
  searchResults: SearchResponse<PaymentDistribution> | never;
  newPage = 1;
  itemsPerPage = 20;

  constructor(
    private paymentDistributionService: PaymentDistributionService,
    protected router: Router
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .withJoinStrategy(JoinStrategy.AND)
      .addFilterData({
        propertyName: 'loanId',
        operation: FilterOperation.EQUALS,
        values: [this.loan.id.toString()]
      })
      .addFilterData({
        enumClass: "eu.twino.loans.core.payment.api.PaymentDistributionStatus",
        propertyName: "status",
        operation: FilterOperation.NOT_EQUALS,
        type: "enum",
        values: [PaymentStatus.VOIDED]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()

    return this.paymentDistributionService.find(searchQuery).pipe(
      take(1)
    ).subscribe((distributions) => {
      this.searchResults = distributions;
    });
  }

  trackById(index, item){
    return item.id;
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }
}
