import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ActivityOverviewRoutingModule } from './activity-overview-routing.module';
import { ActivityOverviewListComponent } from './components/activity-overview-list/activity-overview-list.component';


@NgModule({
  declarations: [ActivityOverviewListComponent],
	imports: [
		CommonModule,
		ActivityOverviewRoutingModule,
		CommonsModule,
		NgbNavModule,
		TranslateModule,
		NgbPopoverModule
	]
})
export class ActivityOverviewModule { }
