import { Component, OnInit } from '@angular/core';
import { FileTemplateService, NamedComponent, Utils } from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { FileTemplateRevision } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-file-template-revisions',
  templateUrl: './file-template-revisions.component.html',
  styleUrls: ['./file-template-revisions.component.scss']
})
export class FileTemplateRevisionsComponent extends NamedComponent implements OnInit {

  id: number;
  revisionHistory: FileTemplateRevision[];

  constructor(
    activatedRoute: ActivatedRoute,
    private fileTemplatesService: FileTemplateService,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.fileTemplatesService.getRevisions(this.id).pipe(
      take(1)
    ).subscribe((revisionHistory) => {
     this.revisionHistory = revisionHistory;
    })
  }

  getName(): string {
    return `File template revisions ${this.id || ''}`;
  }

  downloadFile(fileName, revisionId) {
    this.fileTemplatesService.getRevisionFile(this.id, revisionId).pipe(
      take(1)
    ).subscribe((response: Blob) => {
        Utils.downloadBlob(response, fileName);
      },
      error => console.error("Error downloading the file.", error));
  }

}
