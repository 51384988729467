import { Component, Input } from '@angular/core';
import {
  CountryViewComponent,
  draggableModalOptions,
  STRING,
  Transformation,
  TransformInstructionsBuilder
} from '@backoffice-monorepo/commons';
import { CompanyData, PlPiClientDetails } from '../../api/pl-pi-client-details';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiClientDetailsEditComponent } from '../pl-pi-client-details-edit/pl-pi-client-details-edit.component';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-details-table',
  templateUrl: './pl-pi-client-details-table.component.html',
  styleUrls: ['./pl-pi-client-details-table.component.css']
})
export class PlPiClientDetailsTableComponent implements CountryViewComponent {
  @Input() clientId: number;
  @Input() data: PlPiClientDetails;

  clientDetailsTransformations = TransformInstructionsBuilder.build(
    [
      ['citizenship', ['Citizenship', STRING]],
      ['countryOfBirth', ['Country Of Birth', STRING]],
      ['placeOfBirth', ['Place Of Birth', STRING]],
      ['taxResidence', ['Tax Residence', STRING]],
      ['companyData', ['Company Data', COMPANY_DATA]],
    ]
  );

  constructor(
    private modalService: NgbModal
  ) {}

  editClientDetails() {
    const modalRef = this.modalService.open(PlPiClientDetailsEditComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.clientId;
  }
}

const COMPANY_DATA: Transformation = (value) => transformCompanyData(value).asHtml()

function transformCompanyData(companyData: CompanyData): string {
  if (companyData) {
    let tableRows = '';
    Object.entries(companyData).forEach(([key, value]) => {
      if (key === 'companyAddress') {
        return;
      }

      tableRows += `<tr><td>${key}</td><td>${value}</td></tr>`;
    });

    Object.entries(companyData?.companyAddress).forEach(([key, value]) => {
      tableRows += `<tr><td>${key}</td><td>${value}</td></tr>`;
    });
    return '<table class="table table-sm">' + tableRows + '</table>';
  }

  return '';
}
