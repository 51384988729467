<div *ngIf="loanDebtStep" class="col-md-14 mt-2">
  <div class="card">
    <div class="card-header text-light bg-primary">
      Loan debt step
    </div>
    <div class="card-text">
      <table class="table table-striped table-hover table-sm">
        <thead>
        <tr>
          <th></th>
          <th>Debt Step</th>
          <th>Execution Date</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td></td>
          <td>{{loanDebtStep.debtStepName}}</td>
          <td>{{loanDebtStep.executionDate}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="card mt-2">
    <div class="card-header text-light bg-primary">
      Executed rules
    </div>
    <div class="card-text">
      <table class="table table-striped table-hover table-sm">
        <thead>
        <tr>
          <th></th>
          <th>Rule name</th>
          <th>Rule class name</th>
          <th>Execution status</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of loanDebtStep.executedRules; trackBy: trackById">
          <td></td>
          <td>{{item.ruleName}}</td>
          <td>{{item.ruleClassName}}</td>
          <td>{{item.executionStatus}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


