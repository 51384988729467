import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicComponent, ClientDocumentService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { takeUntil } from 'rxjs/operators';
import { UploadClientDocumentRequest } from '@twino/backoffice-api';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'client-document-upload',
  templateUrl: './client-document-upload.component.html',
  styleUrls: ['./client-document-upload.component.scss']
})
export class ClientDocumentUploadComponent extends BasicComponent implements OnInit {

  @Input() clientId: number;

  clientDocumentUploadForm: FormGroup;
  clientDocumentRequest: UploadClientDocumentRequest;
  clientDocumentTypes: Array<string>;
  clientDocumentVerificationStatuses: Array<string>;
  fileData: File;
  previewUrl: string | ArrayBuffer | null;
  fileUploadProgress: string;
  uploadedFilePath: string ;
  clientDocumentSourceTypes: Array<string>;

  constructor(
    public activeModal: NgbActiveModal,
    private clientDocumentService: ClientDocumentService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    forkJoin([
      this.clientDocumentService.getClientDocumentTypes(),
      this.clientDocumentService.getClientDocumentVerificationStatuses(),
      this.clientDocumentService.listDocumentSourceTypes()
    ])
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(([documentTypes, verificationStatuses, sourceTypes]) => {
        this.clientDocumentTypes = documentTypes;
        this.clientDocumentVerificationStatuses = verificationStatuses;
        this.clientDocumentSourceTypes = sourceTypes;
      });
    this.clientDocumentUploadForm = this.formBuilder.group({
      type: ['', Validators.required],
      verificationStatus: ['', Validators.required],
      description: '',
      sourceId: ['', Validators.required],
      sourceType: ['', Validators.required],
      file: ['', Validators.required]
    });
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.previewDocumentImage();
  }

  previewDocumentImage() {
    const mimeType = this.fileData.type;
    if (mimeType.match(/(jpeg)|(png)\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = () => {
      this.previewUrl = reader.result;
    }
  }


  submitForm() {
    this.clientDocumentRequest = {
      type: this.clientDocumentUploadForm.value.type,
      verificationStatus: this.clientDocumentUploadForm.value.verificationStatus,
      description: this.clientDocumentUploadForm.value.description,
      sourceId: this.clientDocumentUploadForm.value.sourceId,
      sourceType: this.clientDocumentUploadForm.value.sourceType,
    }
    this.clientDocumentService.uploadClientDocument(this.clientId, this.clientDocumentRequest, this.fileData)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
        this.clientsRefreshService.reloadClientDocumentModel();
        this.activeModal.close();
      })
  }
}

