<form [formGroup]="clientEmploymentEditForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Edit client employment #{{clientId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="employmentStatus" class="col-sm-4 control-label">Employment status</label>
      <div class="col-sm-8">
        <select ngbAutofocus
                class="form-control form-control-sm"
                id="employmentStatus"
                formControlName="employmentStatus"
                name="employmentStatus"
                (change)="onChangeEmploymentStatus()">
          <option *ngFor="let key of employmentStatusOptions" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="workplace" class="col-sm-4 control-label">Workplace</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="workplace" formControlName="workplace" name="workplace">
      </div>
    </div>
    <div class="form-group row">
      <label for="workPhoneNumber" class="col-sm-4 control-label">Work phone number</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control form-control-sm"
               id="workPhoneNumber"
               formControlName="workPhoneNumber"
               name="workPhoneNumber">
      </div>
    </div>
    <div class="form-group row">
      <label for="employmentDurationInMonth" class="col-sm-4 control-label">Employment duration in month</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control form-control-sm"
               id="employmentDurationInMonth"
               formControlName="employmentDurationInMonth"
               name="employmentDurationInMonth">
      </div>
    </div>
    <div class="form-group row" *ngIf="showTaxIdField">
      <label for="employerTaxId" class="col-sm-4 control-label">NIP<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="employerTaxId" formControlName="employerTaxId" name="employerTaxId">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientEmploymentEditForm.valid">Save</button>
  </div>
</form>

