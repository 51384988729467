<aside class="container-fluid pt-3 pb-3 pl-1">
  <div class="col-md-12 ml-0 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
  <!-- region buttons -->
    	<span class="mr-2 ml-3" *ngIf="loan?.open">
        <button appRequirePermission="admin.all,loan.admin" class="btn btn-danger btn-sm" (click)="voidLoan()">
          <bi name="pencil-square"></bi>
          Void Loan
        </button>
			</span>
      <span class="mr-2 ml-3" *ngIf="loan?.open">
        <button appRequirePermission="admin.all,loan.admin" class="btn btn-danger btn-sm" (click)="writeOff()">
          <bi name="pencil-square"></bi>
          Write Off
        </button>
			</span>
      <ng-template countryLoanButtonView></ng-template>
  </div>
  <!-- endregion -->
</aside>

<side-bar>
  <ul ngbNav class="nav nav-pills flex-column" #loanNav="ngbNav" [(activeId)]="active">
    <li ngbNavItem="Main data">
      <a ngbNavLink>{{ 'common.mainData' | translate }}</a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="loanMain"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="component.title" *ngFor="let component of libLoanSidebarComponents">
      <a ngbNavLink>{{component.title | translate}}</a>
      <ng-template ngbNavContent>
        <ndc-dynamic
          [ndcDynamicComponent]="component.type"
          [ndcDynamicInputs]="{loan: loan}"
        ></ndc-dynamic>
      </ng-template>
    </li>
    <li [ngbNavItem]="component.title" *ngFor="let component of countryLoanSidebarComponents">
      <a ngbNavLink>{{component.title}}</a>
      <ng-template ngbNavContent>
        <ndc-dynamic
          [ndcDynamicComponent]="component.type"
          [ndcDynamicInputs]="{loan: loan}"
        ></ndc-dynamic>
      </ng-template>
    </li>
  </ul>

  <main [ngbNavOutlet]="loanNav"></main>

</side-bar>

<ng-template #loanMain>
  <!-- region loanMain -->
  <div class="container-fluid row pb-2">
<!-- region tables -->
    <div class="container-fluid row pb-1">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header text-light bg-primary">
            <bi name="briefcase"></bi>
            {{ 'loan.loanLabel' | translate }}
          </div>
          <div class="card-text row">
            <div class="col-md-6">
              <transform-table [transformInstructions]="loanTransformations" [entity]="loan"></transform-table>

              <ndc-dynamic *ngIf="loan"
                           [ndcDynamicComponent]="loanViewFieldsComponentType"
                           [ndcDynamicInputs]="{data: loan}"
              ></ndc-dynamic>
            </div>
            <div class="col-md-6">
              <transform-table [transformInstructions]="loanAmountsTransformations" [entity]="loan"></transform-table>
              <table class="table table-striped table-hover table-sm mb-0">
                <colgroup>
                  <col class="table-primary"/>
                  <col/>
                </colgroup>
                <tbody>
                <tr *ngFor="let item of loan?.openCommissionAmounts | keyvalue">
                  <td class="text-right w-50">{{item.key | labelTransform}}</td>
                  <td>{{item.value}}</td>
                </tr>
                </tbody>
              </table>
              <transform-table [transformInstructions]="loanNextAmountsTransformations" [entity]="loan"></transform-table>

              <ndc-dynamic *ngIf="loan"
                           [ndcDynamicComponent]="loanExtensionFeesComponentType"
                           [ndcDynamicInputs]="{data: loan}"
              ></ndc-dynamic>
            </div>
          </div>
        </div>
        <!-- region debt -->
        <div *ngIf="showLoanDebtComponent()" class="col-md-12">
          <backoffice-monorepo-loan-debt [loanId]="id"></backoffice-monorepo-loan-debt>
        </div>
        <!-- endregion -->
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header text-light bg-primary">
            <bi name="pencil-square"></bi>
            {{ 'loan.currentLoanAgreement' | translate }}
            <button appRequirePermission="admin.all, loan.admin"
                    class="btn btn-light btn-sm float-right"
                    (click)="viewAppliedDiscounts()"
                    *ngIf="appliedDiscountsNumber !== 0">
              Applied discounts
            </button>
          </div>
          <div class="card-text">
            <transform-table [transformInstructions]="currentAgreementTransformations" [entity]="loan?.currentAgreement"></transform-table>
            <ndc-dynamic *ngIf="loan?.currentAgreement"
                         [ndcDynamicComponent]="currentLoanAgreementComponentType"
                         [ndcDynamicInputs]="{data: loan?.currentAgreement}"
            ></ndc-dynamic>
          </div>
        </div>
      </div>
    </div>
    <!-- endregion -->



    <!-- region schedule -->
    <div class="container-fluid row mt-2 pt-1 pb-2">
      <div class="col-md-12">
        <p> {{ 'loan.schedule' | translate }}</p>
        <server-table>
            <backoffice-monorepo-loan-schedule-items list-table [scheduleItems]="loan?.currentAgreement?.scheduleItems"></backoffice-monorepo-loan-schedule-items>
        </server-table>
      </div>
    </div>
    <!--endregion -->

  </div>
  <!--endregion -->
</ng-template>


