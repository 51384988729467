<div class="container-fluid pt-2 col-sm-9">
  <div class="card">
    <div class="card-header">
      Edit Product configuration record {{productType}} {{productSubType}}
    </div>
    <div class="card-body">
      <form [formGroup]="productConfigEditForm" (ngSubmit)="submitForm()">
        <div class="form-group row">
          <label for="name" class="col-sm-1 control-label">Type<sup class="text-danger">*</sup></label>
          <div class="col-sm-11">
            <select class="form-control form-control-sm col-sm-4" id="name" formControlName="name" name="name">
              <option *ngFor="let key of productTypesList" [ngValue]="key">{{key}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="subType" class="col-sm-1 control-label">Subtype<sup class="text-danger">*</sup></label>
          <div class="col-sm-11">
            <select class="form-control form-control-sm col-sm-4" id="subType" formControlName="subType" name="subType">
              <option *ngFor="let key of productSubTypesList" [ngValue]="key">{{key}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-sm-1 control-label">Brand</label>
          <div class="col-sm-11">
            <select class="form-control form-control-sm col-sm-4" id="brand" formControlName="brand" name="brand">
              <option *ngFor="let key of productBrandsList$ | async" [ngValue]="key">{{key}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <json-editor [options]="editorOptions" formControlName="data"></json-editor>
          </div>
        </div>
        <button type="submit" class="btn btn-outline-danger" [disabled]="!productConfigEditForm.valid">Save</button>
      </form>
    </div>
  </div>
</div>


