<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client tasks</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
        <span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th>Loan ID</th>
          <th>Task ID</th>
          <th>Brand</th>
          <th>Topic</th>
          <th>Status</th>
          <th>Assigned User</th>
          <th>Resolution</th>
          <th>Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of searchResults?.results; trackBy: trackById">
          <td><a *ngIf="item.loanId" routerLink="/loans/{{item.loanId}}">{{item.loanId}}</a></td>
          <td>
            <a
              appRequirePermission="admin.all,task.read"
              routerLink="/tasks/{{item.id}}"
            >{{item.id}}</a>
          </td>
          <td>{{item.brand}}</td>
          <td>{{item.topic}}</td>
          <td>{{item.taskStatus}}</td>
          <td>{{item.assignedUser}}</td>
          <td>{{item.resolution}}</td>
          <td>{{item.created}}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
    <div class="form-inline form-group" *ngIf="(searchResults?.totalRecords >= itemsPerPage)">
      <ngb-pagination class="pages"
                      size="sm"
                      [collectionSize]="searchResults?.totalRecords"
                      [page]="searchResults?.currentPage"
                      [pageSize]="itemsPerPage"
                      [maxSize]="10"
                      [rotate]="false"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                      (pageChange)="pageChange($event)">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
