<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
    <tr filter-element title="Id" type="number" property="id" ></tr>
    <tr filter-element title="Number" type="string" property="loanNumber"></tr>
    <tr filter-element
        title="Status"
        type="enum"
        property="status"
        enum="status"
    ></tr>
    <tr filter-element
        title="Status detail"
        type="enum"
        property="statusDetail"
        enum="statusDetail"
    ></tr>
    <tr filter-element title="Client Id" type="number" property="clientId"></tr>
    <tr filter-element
        title="Close date"
        type="date"
        property="closedDate"
    ></tr>
    <tr filter-element title="Brand" type="string" property="brand"></tr>
  </tbody>

  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="loanNumber" (sort)="onSort($event)">Number</th>
      <th>Client</th>
      <th>Brand</th>
      <th sortable="status" (sort)="onSort($event)">Status</th>
      <th sortable="statusDetail" (sort)="onSort($event)">Status Detail</th>
      <th>Agreement</th>
      <th>Debt Step</th>
      <th>Close date</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let loan of response?.results; trackBy: trackById" (dblclick)="navigateTo(loan.id)">
      <td></td>
      <td>{{loan.id}}</td>
      <td>{{loan.loanNumber}}</td>
      <td><a routerLink="/clients/{{loan.clientId}}">{{loan.clientId}}</a></td>
      <td>{{loan.brand}}</td>
      <td>{{loan.status}}</td>
      <td>{{loan.statusDetail}}</td>
      <td>{{loan.currentAgreementType}}</td>
      <td>{{loan.currentDebtStep}}</td>
      <td>{{loan.closedDate | date:'dd.MM.yyyy'}}</td>
    </tr>
    </tbody>
  </table>
</server-table>
