// noinspection ES6PreferShortImport
import { NamedComponent } from '../named-component';
import {
  DEFAULT_MAX_COUNT_LIMIT,
  DEFAULT_PAGE,
  FilterDataValues,
  FilterOperation, MAX_COUNT_LIMIT_DISABLED,
  SearchModel,
  SearchQuery,
  SearchResponse,
  SortEvent,
  SortingCriterion
} from '@twino/backoffice-api';
import { ActivatedRoute } from '@angular/router';
import { Directive, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ThSortableDirective } from '../../directives/th-sortable.directive';
// noinspection ES6PreferShortImport
import { FilteringService } from '../../services/filtering.service';
import { of, Subject } from 'rxjs';
import { delay, switchMap, take } from 'rxjs/operators';

/**
 * You need to provide the type of entity that will be listable with this
 *
 * It extends NamedComponent, because it is needed for storing names of tabs in view-history
 * Also the component extending it should be the @Host of the FilteringService so all the children should have this one's instance of FilteringService
 *
 * @see NamedComponent
 * @see SearchQueryAware
 * @see https://angular.io/guide/dependency-injection-in-action
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ListComponent<T extends SearchModel> extends NamedComponent implements OnInit {


  EQUALS = FilterOperation.EQUALS;
  NOT_EQUALS = FilterOperation.NOT_EQUALS;


  @ViewChildren(ThSortableDirective)
  sortables: QueryList<ThSortableDirective>;

  searchQuery: SearchQuery = SearchQuery.fromBaseQuery();
  response: SearchResponse<T>;

  maxCountLimitEnabled = false;
  currentMaxCountLimit = MAX_COUNT_LIMIT_DISABLED;

  private searchResult$: Subject<void> = new Subject();

  /**
   * In the implementation filteringService should be injected as by @Host
   *
   * @param activatedRoute ActivatedRoute
   * @param filteringService should be injected as by @Host
   *
   * @see https://angular.io/guide/dependency-injection-in-action
   */
  constructor(
    activatedRoute: ActivatedRoute,
    protected filteringService: FilteringService<T>
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.filteringService.applyFiltersToSearchCriteria.subscribe(filters => {
      this.searchWithFilters(filters)
    });

    this.delayedMethod().subscribe(() => {
      if(!this.filteringService.searchCriteria.size) {
        this.refresh();
      } else {
        this.filteringService.applyFilters();
      }
    })

  }

  abstract navigateTo(id: string | number);

  /**
   * Requires service that implements `SearchQueryAware` interface to be passed as a listService
   *
   */

  refresh(page = 0, refreshDoneCallback: () => void = null) {
    if (page !== 0) {
      this.searchQuery.page = page;
    }

    if (this.maxCountLimitEnabled){
      this.searchQuery.maxCountLimit = this.currentMaxCountLimit;
    }

    this.searchResult$
      .pipe(
        switchMap(() => this.filteringService.find(this.searchQuery)),
        take(1)
      )
      .subscribe(response => {
        this.response = response;
        if (refreshDoneCallback) refreshDoneCallback();
      });

    this.searchResult$.next();
  }

  removeCountLimit() {
    this.currentMaxCountLimit = MAX_COUNT_LIMIT_DISABLED;
    this.refresh()
  }

  searchWithFilters(filters: FilterDataValues[]) {
    this.searchQuery.criteria = filters;
    this.searchQuery.page = DEFAULT_PAGE;
    this.refresh();
  }

  onSort({column, direction}: SortEvent) {
    this.clearSortableStates(column);
    if (direction === '') {
      this.searchQuery.sortingCriterion = undefined;
    } else {
      this.searchQuery.sortingCriterion = new SortingCriterion(column, direction);
    }
    this.refresh();
  }

  trackById(index: number, item) {
    return item.id;
  }

  private clearSortableStates(column) {
    this.sortables.forEach(sortable => {
      if (sortable.sortable !== column) {
        sortable.direction = '';
      }
    });
  }

  delayedMethod() {
    return of(null).pipe(delay(1));
  }

  // TODO: after angular version upgrade this solution should be moved to server-table
  enableMaxCountLimit() {
    this.maxCountLimitEnabled = true;
    this.currentMaxCountLimit = DEFAULT_MAX_COUNT_LIMIT;
  }
}
