import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiCompareResponse } from '../../api/pl-pi-compare';
import { PlPiApplicationService } from '../../services/pl-pi-application.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-compare',
  templateUrl: './pl-pi-compare.component.html',
  styleUrls: ['./pl-pi-compare.component.css']
})
export class PlPiCompareComponent implements OnInit {

  @Input()
  applicationId: number;
  compareData: PlPiCompareResponse | never;

  constructor(
    public activeModal: NgbActiveModal,
    private applicationService: PlPiApplicationService
  ) { }


  ngOnInit(): void {
    this.applicationService.getCompareExecutions(this.applicationId).pipe(
      take(1)
    ).subscribe(response => {
      this.compareData = response[0];
    })
  }
}
