import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Payment } from '@twino/backoffice-api';
import { PaymentService } from '../services/payment.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentResolver implements Resolve<Observable<Payment>> {

  constructor(
    private paymentService: PaymentService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Payment> {
    const paymentId = route.params['id'];
    return this.paymentService.getPayment(paymentId).pipe(
      take(1),
      map(payment => payment)
    )
  }
}
