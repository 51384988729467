<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client activity overview</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th>Loan ID</th>
          <th>Type</th>
          <th>Entity ID</th>
          <th>Topic</th>
          <th>Status</th>
          <th>Direction</th>
          <th>Assigned User</th>
          <th>Resolution</th>
          <th>Amount</th>
          <th>Subject</th>
          <th>Content</th>
          <th>Promise date</th>
          <th>Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of activityOverviews; trackBy: trackByEntityId">
          <td><a *ngIf="item.loanId" routerLink="/loans/{{item.loanId}}">{{item.loanId}}</a></td>
          <td>{{item.type}}</td>
          <td>
            <button
              *ngIf="item.type === ActivityOverviewType.TASK else ifNotTask"
              appRequirePermission="admin.all,task.read"
              type="button"
              class="btn btn-sm btn-info"
              (click)="navigateToTask(item.entityId)"
            >{{item.entityId}}</button>

            <ng-template #ifNotTask>
              {{item.entityId}}
            </ng-template>
          </td>
          <td>{{item.topic}}</td>
          <td>{{item.status}}</td>
          <td>{{item.direction}}</td>
          <td>{{item.assignedUser}}</td>
          <td>{{item.resolution}}</td>
          <td>{{item.amount}}</td>
          <td>{{item.subject}}</td>
          <td>
            <button type="button"
                    *ngIf="item.type.includes('COMMUNICATION')"
                    class="btn btn-outline-primary btn-sm"
                    (click)="viewContent(item.entityId)"
                    title="View content">
              <bi name="envelope"
                  *ngIf="item.type === CommunicationTypeActivityOverview.EMAIL || item.type === CommunicationTypeActivityOverview.LETTER"></bi>
              <bi name="chat-square-text"
                  *ngIf="item.type !== CommunicationTypeActivityOverview.EMAIL && item.type !== CommunicationTypeActivityOverview.LETTER"></bi>
            </button>
            <button type="button"
                    *ngIf="item.type === ActivityOverviewType.COMMENT"
                    class="btn btn-outline-primary btn-sm"
                    placement="left"
                    ngbPopover="{{item.content}}"
                    popoverTitle="Comment content">
              <bi name="chat-text"></bi>
            </button>
          </td>
          <td>{{item.promiseDate}}</td>
          <td>{{item.created}}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>
