import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import {
  EnumResponse,
  FilterDataValues,
  SearchCsvExportRequest,
  SearchModel,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@twino/backoffice-api';
import { Observable } from 'rxjs';

@Injectable()
export class FilteringService<T extends SearchModel> implements OnDestroy {

  @Output() resetFilters = new EventEmitter();
  @Output() applyFiltersToSearchCriteria = new EventEmitter<FilterDataValues[]>();


  listService: SearchQueryAware<T>;
  random: number;
  searchCriteria: Map<string, FilterDataValues> = new Map<string, FilterDataValues>();

  constructor(listService: SearchQueryAware<T>) {
    this.listService = listService;
    this.random = Math.round(Math.random() * 10000);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<T>> {
    return this.listService.find(searchQuery);
  }

  getEnum(fieldName: string): Observable<EnumResponse> {
    return this.listService.listEnumValues(fieldName);
  }

  applyFilters() {
    const filterData = Array.from(this.searchCriteria, ([, values]) => values);
    this.applyFiltersToSearchCriteria.emit(filterData);
  }

  setSearchCriterion(fieldName: string, filterData: FilterDataValues) {
    this.searchCriteria.set(fieldName, filterData);
  }

  resetSearchCriterion(fieldName: string) {
    this.searchCriteria.delete(fieldName);
  }

  reset() {
    this.resetFilters.emit();
  }

  exportCsv(exportRequest: SearchCsvExportRequest) {
    return this.listService.exportCsv(exportRequest);
  }

  ngOnDestroy(): void {
    this.resetFilters.unsubscribe();
    this.applyFiltersToSearchCriteria.unsubscribe();
  }

}
