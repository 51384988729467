<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client Communications</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Id</th>
          <th>Type</th>
          <th>Direction</th>
          <th>TemplateKey</th>
          <th>Subject</th>
          <th>Content</th>
          <th>Sender</th>
          <th>Destination</th>
          <th>Attachments</th>
          <th>Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of searchResults?.results; trackBy: trackById">
          <td></td>
          <td>{{item.id}}</td>
          <td>{{item.type}}</td>
          <td>{{item.direction}}</td>
          <td>{{item.templateKey}}</td>
          <td>{{item.subject}}</td>
          <td>
            <button type="button"
                    class="btn btn-outline-primary btn-sm"
                    (click)="viewContent(item.id)"
                    title="View content"
                    [ngSwitch]="item.type === communicationType.EMAIL">
              <bi name="envelope" *ngSwitchCase="true"></bi>
              <bi name="chat-square-text" *ngSwitchCase="false"></bi>
            </button>
          </td>
          <td>{{item.sender}}</td>
          <td>{{item.destination}}</td>
          <td>
            <ul class="list-group" *ngIf="item.emailProperties">
              <li class="list-group-item" *ngFor="let attachment of item.emailProperties.attachments">
                <a href="/ws/api/communication/{{item.id}}/attachment/{{attachment.uid}}">{{attachment.fileName}}</a>
              </li>
            </ul>
            <span *ngIf="!item.emailProperties">
	        	    {{item.attachmentsNames}}
              </span>
          </td>
          <td>{{item.created}}</td>
        </tr>
        </tbody>
      </table>
    </server-table>
    <div class="form-inline form-group" *ngIf="(searchResults?.totalRecords >= itemsPerPage)">
      <ngb-pagination class="pages"
                      size="sm"
                      [collectionSize]="searchResults?.totalRecords"
                      [page]="searchResults?.currentPage"
                      [pageSize]="itemsPerPage"
                      [maxSize]="10"
                      [rotate]="false"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                      (pageChange)="pageChange($event)">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
