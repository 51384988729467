<div class="container-fluid pt-2 col-sm-6">
  <div class="card">
    <div class="card-header">
      Create file template
    </div>
    <div class="card-body">
      <form [formGroup]="fileTemplateAddForm" (ngSubmit)="submitForm()">
        <div class="form-group row">
          <label for="key" class="col-sm-2 control-label">Key<sup class="text-danger">*</sup></label>
          <div class="col-sm-10">
            <input type="text" class="form-control form-control-sm" id="key" formControlName="key" name="key">
          </div>
        </div>
        <div class="form-group row">
          <label for="fileName" class="col-sm-2 control-label">File name<sup class="text-danger">*</sup></label>
          <div class="col-sm-10">
            <input type="text" class="form-control form-control-sm" id="fileName" formControlName="fileName" name="fileName">
          </div>
        </div>
        <div class="form-group row">
          <label for="locale" class="col-sm-2 control-label">Locale</label>
          <div class="col-sm-10">
            <select class="form-control form-control-sm" id="locale" formControlName="locale" name="locale">
              <option *ngFor="let locale of locales$ | async" [ngValue]="locale">{{locale}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row control-label">
          <label class="form-check-label col-sm-2" for="isDisabled">
            Disabled
          </label>
          <div class="form-check col-sm-10">
              <input class="form-check-input ml-1"
                     type="checkbox"
                     id="isDisabled"
                     formControlName="isDisabled"
                     name="isDisabled">
          </div>
        </div>
        <div class="form-group custom-file">
          <input type="file"
                 class="form-control custom-file-input"
                 id="templateFile"
                 formControlName="file"
                 name="file"
                 accept=".pdf, .odt, .html"
                 (change)="fileProgress($event)"/>
          <label class="custom-file-label" for="templateFile">Choose file...</label>
        </div>
        <button type="button" class="btn float-left mt-3" *ngIf="fileData?.name">{{fileData?.name}}</button>
        <button type="button" class="btn btn-outline-primary float-right ml-2 mt-3" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-outline-danger float-right ml-2 mt-3" [disabled]="!fileTemplateAddForm.valid">Save</button>
      </form>
    </div>
  </div>
</div>
