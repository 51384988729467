<form [formGroup]="clientIncomeEditForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Edit client income #{{clientId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="monthlyIncome" class="col-sm-4 control-label">Monthly income<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="monthlyIncome" formControlName="monthlyIncome" name="monthlyIncome">
      </div>
    </div>
    <div class="form-group row" >
      <label for="monthlyLiabilities" class="col-sm-4 control-label">Monthly expenses<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control form-control-sm"
               id="monthlyLiabilities"
               formControlName="monthlyLiabilities"
               name="monthlyLiabilities">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientIncomeEditForm.valid">Save</button>
  </div>
</form>

