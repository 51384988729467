<form [formGroup]="clientSendPinForm" (ngSubmit)="submitForm()">
  <div class="modal-header">
    <h4 class="modal-title">Send Pin to client #{{clientId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="cvv" class="col-sm-4 control-label">CVV</label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="cvv" formControlName="cvv" name="cvv">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientSendPinForm.valid">Send</button>
  </div>
</form>







