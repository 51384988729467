export class Address {
  type: AddressType
  active: boolean
  postalCode?: string
  postalCountry: string
  administrativeArea?: string
  locality?: string
  dependentLocality?: string
  addressLine?: string
  street?: string
  houseNumber?: string
  apartmentNumber?: string
}

export class AddressRequest {
  type: AddressType
  postalCode?: string
  postalCountry: string
  administrativeArea?: string
  locality?: string
  dependentLocality?: string
  addressLine?: string
  active?: boolean
}

export type AddressType = 'ACTUAL' | 'DECLARED';
