import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonsModule } from '@backoffice-monorepo/commons';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MessageTemplatesRoutingModule } from './message-templates-routing.module';
import { MessageTemplatesListComponent } from './components/message-templates-list/message-templates-list.component';
import { MessageTemplateAddComponent } from './components/message-template-add/message-template-add.component';
import { MessageTemplateEditComponent } from './components/message-template-edit/message-template-edit.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MessageTemplateViewComponent } from './components/message-template-view/message-template-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
	declarations: [MessageTemplatesListComponent, MessageTemplateAddComponent, MessageTemplateEditComponent, MessageTemplateViewComponent],
	exports: [
		MessageTemplateEditComponent
	],
	imports: [
		CommonModule,
		MessageTemplatesRoutingModule,
		CommonsModule,
		NgbNavModule,
		TranslateModule,
		ReactiveFormsModule,
		EditorModule,
		DragDropModule,
	]
})
export class MessageTemplatesModule { }
