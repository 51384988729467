import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { SearchQueryAware, UserRole } from '@twino/backoffice-api';
import { ActivatedRoute } from '@angular/router';
import {
  AuthorityService,
  ConfirmDialogueComponent,
  FilteringService,
  NamedComponent,
  RoleService
} from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleAddComponent } from '../role-add/role-add.component';
import { RoleEditComponent } from '../role-edit/role-edit.component';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<UserRole>) => new FilteringService(listService),
      deps: [RoleService]
    }
  ]
})
export class RolesListComponent extends NamedComponent implements OnInit {
  roles: Observable<UserRole[]>;

  constructor(
    activatedRoute: ActivatedRoute,
    private roleService: RoleService,
    private authorityService: AuthorityService,
    private modalService: NgbModal
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.getRoles();
  }

  getName(): string {
    return 'Roles';
  }

  refresh() {
    this.getRoles();
  }

  getRoles() {
    this.roles = this.roleService.getRoles();
  }

  trackById(index: number, role: UserRole) {
    return role.id;
  }

  onCreateClick() {
    this.authorityService.getAuthorities().pipe(
      take(1)
    ).subscribe(authorities => {
      const modalRef = this.modalService.open(RoleAddComponent);

      modalRef.componentInstance.authorities = authorities;
      modalRef.result.then(result => {
        if (result) {
          this.roleService.createRole(result).pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => {
            this.refresh();
          });
        }
      })
    })
  }

  onEditClick(id: number) {
    forkJoin({
      authorities: this.authorityService.getAuthorities(),
      role: this.roleService.getRoleById(id)
    }).pipe(
      takeUntil(this.$destroy)
    ).subscribe(({authorities, role}) => {
      const modalRef = this.modalService.open(RoleEditComponent);

      modalRef.componentInstance.authorities = authorities;
      modalRef.componentInstance.selectedAuthorities = role.authorities.map(authority => authority.name);
      modalRef.componentInstance.name = role.name;

      modalRef.result.then(result => {
        if (result) {
          this.roleService.updateRole(id, result).subscribe(() => {
            this.refresh();
          });
        }
      });
    })
  }

  onDeleteClick(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);

    modalRef.componentInstance.header = 'Delete role';
    modalRef.componentInstance.content = 'Are You Sure you want to delete role?';

    modalRef.result.then(result => {
      if (result) {
        this.roleService.deleteRole(id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.refresh();
        });
      }
    });
  }
}
