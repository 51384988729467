<aside class="container-fluid row m-2" *ngIf="withFilterBlock">
  <span class="m-1" *ngIf="withRefreshButton">
    <server-refresh (refresh)="serverRefresh()"></server-refresh>
  </span>
  <span class="m-1">
    <search-filter>
      <ng-content select="tbody[filter-panel]"></ng-content>
    </search-filter>
  </span>
  <span class="m-1" *ngIf="withExportButton">
    <search-export [searchQuery]="searchQuery" [exportModuleName]="exportModuleName"></search-export>
  </span>
  <ng-content select="div[buttons]"></ng-content>
</aside>

<ng-content select="[list-table]"></ng-content>

<nav class="container-fluid row" *ngIf="totalRecords">
  <div class="col-md-9">
    <pagination [totalRecords]="totalRecords"
                [totalPages]="totalPages"
                [currentPage]="currentPage"
                [itemsPerPage]="pageSize"
                (setPage)="pageChanged($event)"></pagination>
  </div>
  <div class="col-md-3 text-right">
    <span>Total record(s): {{totalRecords}}</span>
    <span *ngIf="countLimitReached || false">+. <a href="#" (click)="countAll();false">Count all</a></span>
  </div>
</nav>
