import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';
import {
  CancelProlongationResignationRequest,
  LoanProlongationResignationsResponse,
  ResignProlongationRequest,
  SearchQuery,
  SearchResponse
} from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class LoanProlongationResignationsService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getOriginators(): Observable<string[]> {
    return this.get(`/api/loans/prolongation/resignation/originators`);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<LoanProlongationResignationsResponse>> {
    return this.post(`/api/loans/prolongation/resignation/list`, searchQuery);
  }

  resignProlongation(resignProlongationRequest: ResignProlongationRequest) {
    return this.post(`/api/loans/prolongation/resignation/resign`, resignProlongationRequest);
  }

  cancelProlongationResignation(cancelProlongationResignationRequest: CancelProlongationResignationRequest) {
    return this.post(`/api/loans/prolongation/resignation/cancel`, cancelProlongationResignationRequest);
  }
}
