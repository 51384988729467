export class IncomeInfo {
  clientId: number
  monthlyIncome: number
  monthlyLiabilities: number
}

export class ClientIncomeDataRequest {
  monthlyIncome: number
  monthlyLiabilities: number
}
