import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService, NamedComponent, SystemConfigService, ViewHistoryService } from '@backoffice-monorepo/commons';
import { ProductConfig, SystemConfigurationRequest } from '@twino/backoffice-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SystemConfigRefreshService } from '../../services/system-config-refresh.service';
import { ActivatedRoute } from '@angular/router';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-system-config-edit',
  templateUrl: './system-config-edit.component.html',
  styleUrls: ['./system-config-edit.component.scss']
})
export class SystemConfigEditComponent extends NamedComponent implements OnInit {

  id: number;
  systemConfigurationEditForm: FormGroup;
  systemConfigurationRequest: SystemConfigurationRequest;
  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, {static: false}) editor: JsonEditorComponent;
  isInvalidJson = false;


  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private systemConfigurationService: SystemConfigService,
    private formBuilder: FormBuilder,
    private systemConfigurationRefreshService: SystemConfigRefreshService,
    private viewHistoryService: ViewHistoryService,
    private productConfig: ProductConfig,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = this.productConfig.jsonEditorModes;
    this.editorOptions.onChange = () => {
      this.editor.onChange({});
      this.isInvalidJson = !this.editor.isValidJson();
    }

    this.id = this.activatedRoute.snapshot.params['id'];
    this.systemConfigurationEditForm = this.formBuilder.group({
      name: ['', Validators.required],
      data: ['', Validators.required]
    });
    this.systemConfigurationService.getSystemConfigRecord(this.id).pipe(
      take(1)
    ).subscribe((configurationData) => {
      const systemConfigurationRecord = {
        name: configurationData.name,
        data: JSON.parse(configurationData.data)
      }
      this.systemConfigurationEditForm.setValue(systemConfigurationRecord);
    })
  }

  getName(): string {
    return `Edit system configuration record ${this.id || ''}`;
  }

  submitForm() {
    this.systemConfigurationRequest = {
      name: this.systemConfigurationEditForm.value.name,
      data: JSON.stringify(this.systemConfigurationEditForm.value.data)
    }
    this.systemConfigurationService.updateSystemConfig(this.systemConfigurationRequest)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess("System configuration record is updated");
      this.viewHistoryService.closeTab();
      this.systemConfigurationRefreshService.reloadSystemConfigListModel();
    })
  }
}
