<form [formGroup]="loanRepaymentProposalAddForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Create loan repayment proposal</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="amount" class="col-sm-4 control-label">Amount<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input ngbAutofocus type="text" class="form-control form-control-sm" id="amount" formControlName="amount" name="amount">
        <small class="form-text text-muted">min: {{minRepaymentAmount}}</small>
      </div>
    </div>
    <div class="form-group row">
      <label for="fromDate" class="col-sm-4 control-label">From date<sup class="text-danger">*</sup></label>
      <div class="input-group col-sm-8">
        <input type="text" class="form-control form-control-sm" id="fromDate" formControlName="fromDate" name="fromDate">
      </div>
    </div>
    <div class="form-group row">
      <label for="toDate" class="col-sm-4 control-label">To date<sup class="text-danger">*</sup></label>
      <div class="input-group col-sm-8">
        <input class="form-control form-control-sm"
               id="toDate"
               formControlName="toDate"
               placeholder="yyyy-mm-dd"
               name="toDate"
               ngbDatepicker
               #toDate="ngbDatepicker"
               [minDate]="minDate"
               [maxDate]="maxDate">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="toDate.toggle()" type="button"></button>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="isEnabled" class="col-sm-4 control-label">Is enabled</label>
      <div class="col-sm-8">
        <input type="checkbox" class="form-control form-control-sm" id="isEnabled" formControlName="isEnabled" name="isEnabled">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!loanRepaymentProposalAddForm.valid">Save</button>
  </div>
</form>



