<form [formGroup]="partnerForm" (ngSubmit)="submitForm()">
  <div class="modal-header">
    <h4 class="modal-title">{{title}} sale partner</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="name" class="col-sm-4 control-label">Partner name<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="name" formControlName="name" name="name">
      </div>
    </div>
    <div class="form-group row">
      <label for="bankAccount" class="col-sm-4 control-label">Bank account<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="bankAccount" formControlName="bankAccount" name="bankAccount">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!partnerForm.valid">Save</button>
  </div>
</form>

