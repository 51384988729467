import { Component, OnInit } from '@angular/core';
import { BasicComponent, ClientService, ClientSidebarLibComponent, ProductConfigService } from '@backoffice-monorepo/commons';
import { ClientConsent, FullClient, UpdateClientConsentsRequest } from '@twino/backoffice-api';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-client-consents',
  templateUrl: './client-consents.component.html',
  styleUrls: ['./client-consents.component.scss']
})
export class ClientConsentsComponent extends BasicComponent implements ClientSidebarLibComponent, OnInit {

  client: FullClient;
  consents: ClientConsent[];
  public productBrandsList$: Observable<string[]>;
  brand: string | null = null;

  constructor(
    private clientService: ClientService,
    private productConfigService: ProductConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.productBrandsList$ = this.productConfigService.listBrands();
    this.refresh();
  }

  refresh() {
    return this.clientService.getClientConsents(this.client.savedId).pipe(
      take(1)
    ).subscribe(consents => {
      this.consents = consents;
    })
  }

  renounceAllConsents() {
    const consents = this.consents.filter(c => c.brand === this.brand).map((consent: ClientConsent) => ({
      ...consent,
      value: false
    }));
    return this.clientService.updateClientConsents(this.client.savedId, {
      consents: consents, brand: this.brand
    }).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.refresh()
    }, () => {
      this.refresh()
    });
  }

  updateConsents(name: string, value: boolean, brand: string) {
    const consentsRequest: UpdateClientConsentsRequest = {
      consents: [
        {name, value}
      ],
      brand: brand
    };
    return this.clientService.updateClientConsents(this.client.savedId, consentsRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.refresh()
    }, () => {
      this.refresh()
    });
  }

  setBrand(event: any) {
    this.brand = event.target.value;
  }
}
