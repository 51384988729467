import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  EnumResponse,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';
import { NotificationConfigRequest, NotificationConfig } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class NotificationConfigService extends BaseService implements SearchQueryAware<NotificationConfig> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<NotificationConfig>> {
    return this.post(`/api/notification/notification-configs/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/notification/notification-configs/enum-values/${enumClass}`);
  }

  getNotificationConfigs(): Observable<SearchResponse<NotificationConfig>> {
    return this.post(`/api/notification/notification-configs/list`, {});
  }

  getNotifiableEvents(): Observable<Map<string, string>> {
    return this.get(`/api/notification/notification-config/notifiable_events`, {});
  }

  getNotificationConfig(id: number): Observable<NotificationConfig> {
    return this.get(`/api/notification/notification-config/${id}`, {});
  }

  createNotificationConfig(notificationConfigRequest: NotificationConfigRequest) {
    return this.post(`/api/notification/notification-config`, notificationConfigRequest);
  }

  updateNotificationConfig(id: number, notificationConfigRequest: NotificationConfigRequest) {
    return this.post(`/api/notification/notification-config/${id}`, notificationConfigRequest);
  }

  deleteNotificationConfig(id: number): Observable<any> {
    return this.delete(`/api/notification/notification-config/${id}`, {});
  }
}
