<p class="mt-2 ml-3">
  <b>Loan debt collection stages</b>
</p>
<!-- region buttons -->
<div class="col-md-12 mb-2">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-step-configuration.edit" class="btn btn-danger btn-sm"  (click)="closeAnyActiveLoanStage()">
              Close any stage
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-step-configuration.edit"  class="btn btn-success btn-sm" (click)="setLoanDcStage()">
              Add DC stage
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-stage.edit" class="btn btn-danger btn-sm"  (click)="markAsFraud()">
              Fraud
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-stage.edit" class="btn btn-danger btn-sm"
                    (click)="manualStatus(loanDebtStepManualStatus.POLICE)">
              Police
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-stage.edit" class="btn btn-danger btn-sm"
                    (click)="manualStatus(loanDebtStepManualStatus.DECEASE)">
              Deceased
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-stage.edit" class="btn btn-warning btn-sm"
                    (click)="manualStatus(loanDebtStepManualStatus.INSOLVENCY)">
              Insolvent
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-stage.edit" class="btn btn-warning btn-sm"
                    (click)="manualStatus(loanDebtStepManualStatus.REMOVE_FOR_SALE)">
              Remove from sale
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,loan.admin,loan-sale.edit" class="btn btn-warning btn-sm"
                    (click)="requestPresale()">
              Send to presale
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-stage.edit" class="btn btn-warning btn-sm"
                    (click)="manualStatus(loanDebtStepManualStatus.PRE_BUY_BACK)">
              Pre buy back
            </button>
        </span>

        <span class="mr-2 float-right">
            <button appRequirePermission="admin.all,debt-stage.edit" class="btn btn-warning btn-sm"
                    (click)="buyBack()">
              Buy back
            </button>
        </span>

</div>
<!-- endregion -->
<div class="container-fluid row pt-2 pb-2">
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>ID</th>
          <th>Loan ID</th>
          <th>Stage type</th>
          <th>Status</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Description</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of loanStages; trackBy: trackById ">
          <td></td>
          <td>{{item.id}}</td>
          <td>{{ item.loanId }}</td>
          <td>{{ item.stageType }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.startDate }}</td>
          <td>{{ item.endDate }}</td>
          <td>{{ item.description }}</td>
          <td>
            <button class="btn btn-danger btn-sm"
                    appRequirePermission="admin.all,debt-step-configuration.edit"
                    (click)="closeLoanDcStage(item.stageType)"
                    *ngIf="item.status === debtStageStatus.ACTIVE">
              Close stage
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>

