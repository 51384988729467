import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  Communication,
  EnumResponse, SearchCsvExportRequest,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService extends BaseService implements SearchQueryAware<Communication> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<Communication>> {
    return this.post(`/api/communications/search`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/communications/enum-values/${enumClass}`);
  }

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/communications/export-csv`, exportRequest, {'responseType': 'blob'});
  }

  getClientCommunications(clientId: number): Observable<Array<Communication>> {
    return this.get(`/api/communications/by-client-id/${clientId}`);
  }

  getClientCommunication(id: number): Observable<Communication> {
    return this.get(`/api/communication/${id}`);
  }

}
