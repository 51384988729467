<div class="container-fluid pt-2 col-sm-12">
  <div class="card">
    <div class="card-header">
      Execute script
    </div>
    <div class="card-body">
      <form [formGroup]="executeScriptForm">
        <div class="form-group row">
          <div class="col-sm-12">
                          <textarea
                            class="form-control form-control-sm"
                            ngbAutofocus
                            id="script"
                            formControlName="script"
                            name="script"
                            cols="50"
                            rows="20"
                          ></textarea>
          </div>
        </div>
        <button type="button" [disabled]="executing" (click)="executeScript()" class="btn btn-outline-danger">Execute</button>
        <i *ngIf="executing" class="spinner-border spinner-border-sm text-secondary"></i>
        <div class="form-group row">
          <div class="col-sm-12">
                          <textarea
                            class="form-control form-control-sm"
                            id="result"
                            formControlName="result"
                            name="result"
                            cols="50"
                            rows="20"
                          ></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
