<transform-table [transformInstructions]="taskTransformations" [entity]="data"></transform-table>
<button  type="button" class="btn btn-sm btn-primary p-2 m-2 float-right" (click)="viewRelatedEntities()">
  View Related Entities
</button>

<button  type="button" class="btn btn-sm btn-primary p-2 m-2 float-right" (click)="viewIdentificationData()" *ngIf="isManualIdentification()">
  View Identification Data
</button>

<button  type="button" class="btn btn-sm btn-primary p-2 m-2 float-right" (click)="viewCompareData()">
  Compare
</button>
