<aside class="container-fluid pt-3 pb-3">
  <div class="col-md-12 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
    <!-- region buttons -->
    <span class="mr-2 ml-3">
          <button appRequirePermission="admin.all,task.close"
                  class="btn btn-danger btn-sm"
                  *ngIf="showOwnerButtons"
                  (click)="closeTaskModal()">
            <bi name="pencil-square"></bi>
            Close task
          </button>
    </span>
    <span class="mr-2 ml-3">
          <button appRequirePermission="admin.all,task.postpone"
                  class="btn btn-primary btn-sm"
                  *ngIf="showOwnerButtons"
                  (click)="postponeModal()">
            <bi name="plus-circle"></bi>
            Postpone task
          </button>
    </span>
    <span class="mr-2 ml-3">
        <button type="button"
                class="btn btn-sm btn-primary"
                *ngIf="showOwnerButtons"
                (click)="releaseToGroup()">
          <bi name="person-fill" class="bold-svg"></bi>
          Release to group
        </button>
    </span>
    <span class="mr-2 ml-3">
          <button appRequirePermission="admin.all,task.read"
                  class="btn btn-success btn-sm"
                  *ngIf="showAcceptButton"
                  (click)="acceptTask()">
            <bi name="plus-circle"></bi>
            Accept task
          </button>
    </span>
    <!-- endregion -->
  </div>
</aside>
<!-- region taskView -->
<div class="container-fluid row pt-1 pb-2">
  <div class="col-md-4">
    <div class="card">
      <div class="card-header text-light bg-primary">
        <bi name="briefcase"></bi>
        Task
      </div>
      <div class="card-text">
        <transform-table [transformInstructions]="taskTransformations" [entity]="task"></transform-table>

        <ndc-dynamic *ngIf="task"
          [ndcDynamicComponent]="taskRelatedEntitiesComponentType"
          [ndcDynamicInputs]="{data: task}"
        ></ndc-dynamic>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-header text-light bg-primary">
        <bi name="briefcase"></bi>
        Client
      </div>
      <div class="card-text">
        <transform-table [transformInstructions]="clientTransformations" [entity]="client"></transform-table>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-header text-light bg-primary">
        <bi name="briefcase"></bi>
        Loan
      </div>
      <div class="card-text">
        <transform-table [transformInstructions]="loanTransformations" [entity]="loan"></transform-table>
      </div>
    </div>
  </div>
</div>
<!--endregion -->







