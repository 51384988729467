import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {
  BOOLEAN,
  ClientResolver,
  ClientWithTransformInstructions,
  DATE,
  DATE_TIME,
  ID,
  NUMBER,
  STRING,
  TransformInstructionsBuilder
} from '@backoffice-monorepo/commons';
import { TranslateService } from '@ngx-translate/core';
import { FullClient } from '@twino/backoffice-api';
import { PlPiClientService } from '../services/pl-pi-client.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PlPiClientResolver implements ClientResolver<FullClient> {

  constructor(
    private plPiClientService: PlPiClientService,
    private translateService: TranslateService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClientWithTransformInstructions<FullClient>> {
    const clientId = route.params['id'];
    return this.plPiClientService.getClient(clientId)
      .pipe(
        map((client) => this.mapToClientWithTransformInstruction(client))
      )
  }

  private mapToClientWithTransformInstruction(client: FullClient): ClientWithTransformInstructions<FullClient> {
    return {
      client: client,
      transformations: TransformInstructionsBuilder.build(
        [
          ['savedId', ['Id', ID]],
          ['number', ['Client Number', STRING]],
          ['firstName', ['First Name', STRING]],
          ['lastName', ['Last Name', STRING]],
          ['personalId', ['Personal ID', STRING]],
          ['mobilePhone', ['Mobile Phone', STRING]],
          ['email', ['Email', STRING]],
          ['dateOfBirth', ['Date Of Birth', DATE]],
          ['gender', ['Gender', STRING]],
          ['status', ['Status', STRING]],
          ['creditLimit', ['Credit Limit', STRING]],
          ['finishedDetailsStep', ['Finished Details Step', NUMBER]],
          ['created', [this.translateService.instant('common.created'), DATE_TIME]],
          ['hasOpenLoanApplication', ['Has Open Loan Application', BOOLEAN]],
          ['canApplyForRepeatedLoan', ['Can Apply For Repeated Loan', BOOLEAN]],
          ['isMigratedClient', ['Is Migrated Client', BOOLEAN]],
          ['repeatedApplicationFinishedStep', ['Repeated Application Finished Step', NUMBER]],
          ['cardState', ['Card State', STRING]],
        ]
      )
    }
  }
}
