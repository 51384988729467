import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export const draggableModalOptions: NgbModalOptions = {
  windowClass: 'dragged-modal modal-windows',
  backdrop: false,
  keyboard: false,
  scrollable: false
}

export const draggableModalOptionsLarge: NgbModalOptions = {
  windowClass: 'dragged-modal modal-windows modal-windows-lg',
  backdrop: false,
  keyboard: false,
  scrollable: true,
  size: 'lg'
}

export const draggableModalOptionsSmall: NgbModalOptions = {
  windowClass: 'dragged-modal modal-windows modal-windows-sm',
  backdrop: false,
  keyboard: false,
  scrollable: false,
  size: 'sm'
}

export const draggableModalOptionsXl: NgbModalOptions = {
  windowClass: 'dragged-modal modal-windows modal-windows-xl',
  backdrop: false,
  keyboard: false,
  scrollable: true,
  size: 'xl'
}
