import { Component, Input } from '@angular/core';
import { DateUtils, draggableModalOptionsLarge } from '@backoffice-monorepo/commons';
import { ScheduleItem, ScheduleItemCommission, ScheduleItemFee } from '@twino/backoffice-api';
import { LoanCommissionsViewComponent } from '../loan-commissions-view/loan-commissions-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanFeesViewComponent } from '../loan-fees-view/loan-fees-view.component';

@Component({
  selector: 'backoffice-monorepo-loan-schedule-items',
  templateUrl: './loan-schedule-items.component.html',
  styleUrls: ['./loan-schedule-items.component.scss']
})
export class LoanScheduleItemsComponent {
  protected readonly DateUtils = DateUtils;
  @Input() scheduleItems: ScheduleItem[];
  constructor(private modalService: NgbModal,
  ) { }

  viewCommissions(commissions: ScheduleItemCommission[]) {
    const modalRef = this.modalService.open(LoanCommissionsViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.mode = "schedule";
    modalRef.componentInstance.commissions = commissions;
  }

  viewFees(fees: ScheduleItemFee[]) {
    const modalRef = this.modalService.open(LoanFeesViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.fees = fees;
  }

  trackById(index: number, item: ScheduleItem){
    return item.id;
  }
}
