import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertsService, BasicComponent, CountdownService } from '@backoffice-monorepo/commons';
import { FormBuilder, Validators } from '@angular/forms';
import { PlPiClientCardService } from '../../services/pl-pi-client-card.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivateCardConfirmationRequest } from '../../api/client.card';
import { catchError, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { TIME_TO_RESEND } from '../../constants/time-to-resend';
@Component({
  selector: 'backoffice-monorepo-pl-pi-client-card-otp',
  templateUrl: './pl-pi-client-card-otp.component.html',
  styleUrls: ['./pl-pi-client-card-otp.component.scss']
})
export class PlPiClientCardOtpComponent extends BasicComponent implements OnInit, OnDestroy {

  @Input() clientId: number;
  otpConfirmForm = this.formBuilder.group({
    confirmationCode: [
      '',
      [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)
      ]
    ]
  });
  subscription: Subscription;
  $resendCountdown: Observable<number>;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private clientCardService: PlPiClientCardService,
    private countdownService: CountdownService
  ) {
    super();
  }

  ngOnInit(): void {
    this.restartCountdown();
  }

  submit() {
    const otpRequest: ActivateCardConfirmationRequest = {
      confirmationCode: this.otpConfirmForm.get('confirmationCode').value
    };
    this.clientCardService.confirmCardActivationWithOtp(this.clientId, otpRequest).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(() => {
        this.alertService.notifySuccess(`Card confirmed`);
        this.activeModal.close(true);
      });
  }

  resendOtp() {
    this.restartCountdown();
    this.alertService.notifySuccess('Code sent');
  }

  private restartCountdown() {
    this.countdownService.removeTimer(this.clientId.toString());
    this.$resendCountdown = this.clientCardService.initiateCardActivationWithOtpConfirmation(this.clientId).pipe(
      catchError(() => of(0)),
      switchMap(() => this.countdownService.getCountdown(this.clientId.toString(), TIME_TO_RESEND)),
      share()
    )
  }
}
