<div class="row">
  <div class="col">
    <div class="d-inline-block"
         ngbDropdown
         #serverTableDropdown="ngbDropdown"
         [autoClose]="'outside'"
    >
      <button class="btn btn-primary btn-sm" [attr.id]="id" ngbDropdownToggle>
        <bi name="funnel-fill"></bi>
        Filter
      </button>
      <div ngbDropdownMenu [attr.aria-labelledby]="id">
        <table class="table filter-panel-table">
          <colgroup>
            <col class="table-primary"/>
            <col/>
            <col/>
            <col/>
          </colgroup>
        <ng-content></ng-content>
        </table>

        <div class="dropdown-divider"></div>
        <div class="container-fluid text-right">
          <button type="button" class="btn btn-primary btn-sm mr-1" (click)="apply(); serverTableDropdown.close();">
            <bi name="funnel-fill"></bi>
            Apply
          </button>
          <button type="button" class="btn btn-outline-secondary btn-sm mr-1" (click)="resetFilters()">
            <bi name="arrow-counterclockwise"></bi>
            Reset
          </button>
          <button type="button" class="btn btn-outline-secondary btn-sm" (click)="serverTableDropdown.close()">
            <bi name="x" class="bold-svg"></bi>
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
