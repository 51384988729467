import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  CreateTransferRequest,
  EnumResponse,
  RedirectTransferRequest, SearchCsvExportRequest,
  SearchQuery,
  SearchQueryAware,
  SearchResponse,
  Transfer
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransferService extends BaseService implements SearchQueryAware<Transfer> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<Transfer>> {
    return this.post(`/api/transfers/search`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/transfers/enum-values/${enumClass}`);
  }

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/transfers/export-csv`, exportRequest, {'responseType': 'blob'});
  }

  getTransfer(id: number): Observable<Transfer> {
    return this.get<Transfer>(`/api/transfers/${id}`);
  }

  createTransfer(createTransferRequest: CreateTransferRequest) {
    return this.post(`/api/transfers`, createTransferRequest);
  }

  retryTransfer(id: number) {
    return this.post(`/api/transfers/${id}/retry`, {});
  }

  redirectTransfer(redirectTransferRequest: RedirectTransferRequest) {
    return this.post(`/api/transfers/redirect`, redirectTransferRequest);
  }
}
