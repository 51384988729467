import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClientSidebarLibComponent, CommunicationService, draggableModalOptionsLarge } from '@backoffice-monorepo/commons';
import { Communication, FilterOperation, FullClient, NotificationCommunicationType, SearchQueryBuilder, SearchResponse } from '@twino/backoffice-api';
import { Subscription } from 'rxjs';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import {
  CommunicationContentViewComponent
} from '../../../communications/components/communication-content-view/communication-content-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-communications',
  templateUrl: './client-communications.component.html',
  styleUrls: ['./client-communications.component.scss']
})
export class ClientCommunicationsComponent implements ClientSidebarLibComponent, OnInit, OnDestroy {

  private reloadClientCommunicationModel: Subscription;

  @Input()
  client: FullClient;
  communicationType = NotificationCommunicationType;
  searchResults: SearchResponse<Communication> | never;
  newPage = 1;
  itemsPerPage = 20;

  constructor(
    private communicationService: CommunicationService,
    private clientsRefreshService: ClientsRefreshService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.refresh();
    this.reloadClientCommunicationModel = this.clientsRefreshService.reloadClientCommunicationModel$.subscribe(() => {
      this.refresh();
    })
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'clientId',
        operation: FilterOperation.EQUALS,
        values: [this.client.savedId.toString()]
      })
      .withSortCriterion({
        propertyName: 'created',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()

    return this.communicationService.find(searchQuery).pipe(
      take(1)
    ).subscribe((communications) => {
      this.searchResults = communications;
    })
  }

  ngOnDestroy(): void {
    this.reloadClientCommunicationModel.unsubscribe();
  }

  viewContent(communicationId) {
    const modalRef = this.modalService.open(CommunicationContentViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.communicationId = communicationId;
  }

  trackById(index, item){
    return item.id;
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }
}
