import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { Observable } from 'rxjs';
import {
  CardsInfoResponse,
  ClientInfoResult,
  ClientRegisterResponse,
  SendPinNotificationRequest,
  TransactionListResponse,
  GetTransactionRequest,
  CardTransactionDetailsResponse,
  CardTransactionDetailsRequest,
  CardHistoryEntryResponse,
  CardStatusChangeRequest,
  CreateCardConfirmationRequest,
  CardCreateResponse,
  InitiateCardActivationWithConfirmationResponse,
  ActivateCardConfirmationRequest, InitiateCardCreationWithConfirmationResponse
} from '../api/client.card';

@Injectable({
  providedIn: 'root'
})
export class PlPiClientCardService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  registerClient(clientId: number): Observable<ClientRegisterResponse> {
    return this.post(`/api/pl/client/${clientId}/cards/register-client`, {});
  }

  createCard(clientId: number): Observable<InitiateCardCreationWithConfirmationResponse> {
    return this.post(`/api/pl/client/${clientId}/cards/initiate-creation`, {});
  }

  confirmCardCreation(clientId: number,  request: CreateCardConfirmationRequest): Observable<CardCreateResponse> {
    return this.post(`/api/pl/client/${clientId}/cards/confirm-creation`, request);
  }

  unblockCard(cardId: number, request: CardStatusChangeRequest): Observable<void> {
    return this.post(`/api/pl/client/cards/${cardId}/unblock`, request);
  }

  freezeCard(cardId: number, request: CardStatusChangeRequest): Observable<void> {
    return this.post(`/api/pl/client/cards/${cardId}/freeze`, request);
  }

  closeCard(cardId: number, request: CardStatusChangeRequest): Observable<void> {
    return this.post(`/api/pl/client/cards/${cardId}/close`, request);
  }

  getCardsInfo(clientId: number): Observable<CardsInfoResponse> {
    return this.get(`/api/pl/client/${clientId}/cards/info`);
  }

  getClientInfoCardSystem(clientId: number): Observable<ClientInfoResult> {
    return this.get(`/api/pl/client/${clientId}/cards/client-info`);
  }

  sendPin(clientId: number, request: SendPinNotificationRequest): Observable<void> {
    return this.post(`/api/pl/client/${clientId}/cards/send-pin`, request);
  }

  updateDiPocketClient(clientId: number): Observable<void> {
    return this.post(`/api/pl/client/${clientId}/cards/client-update`, {});
  }

  getTransactions(clientId: number, request: GetTransactionRequest): Observable<TransactionListResponse> {
    const httpParams = new HttpParams().set("startDate", request.startDate.toString())
      .set("endDate", request.endDate.toString());
    return this.get(`/api/pl/client/${clientId}/cards/transactions`, { params: httpParams});
  }

  getTransactionDetails(clientId: number, request: CardTransactionDetailsRequest): Observable<CardTransactionDetailsResponse> {
    return this.post(`/api/pl/client/${clientId}/cards/transaction-details`, request);
  }

  getCardStatusBlockingReasons(): Observable<string[]> {
    return this.get(`/api/pl/client/cards/blocking-reasons`);
  }

  getCardStatusUnblockingReasons(): Observable<string[]> {
    return this.get(`/api/pl/client/cards/unblocking-reasons`);
  }

  getCardsHistory(clientId: number): Observable<CardHistoryEntryResponse[]> {
    return this.get(`/api/pl/client/${clientId}/cards/history`);
  }

  initiateCardActivationWithOtpConfirmation(clientId: number): Observable<InitiateCardActivationWithConfirmationResponse> {
    return this.post(`/api/pl/client/${clientId}/cards/initiate-activation`, {});
  }

  confirmCardActivationWithOtp(clientId: number, request: ActivateCardConfirmationRequest): Observable<void> {
    return this.post(`/api/pl/client/${clientId}/cards/confirm-activation`, request);
  }

  renounceCard(clientId: number): Observable<void> {
    return this.put(`/api/pl/client/${clientId}/cards/renounce`, {});
  }
}
