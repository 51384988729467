<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element
      title="Status"
      type="enum"
      property="taskStatus"
      enum="taskStatus"
  ></tr>
  <tr filter-element title="Brand" type="string" property="brand"></tr>
  <tr filter-element title="Assigned user" type="string" property="assignedUser"></tr>
  <tr filter-element
      title="Assigned"
      type="datetime"
      property="assignedTime"
      [defaultOperation]="FilterOperation.MORE"
      [defaultValue]="DateFilterValues.CURRENT_MONTH_START_DATE"
  ></tr>
  <tr filter-element title="Resolution" type="string" property="resolution"></tr>
  <tr filter-element title="Attempts count" type="string" property="attempts"></tr>
  <tr filter-element title="Application Id" type="string" property="applicationId"></tr>
  <tr filter-element title="Client Id" type="number" property="clientId"></tr>
  <tr filter-element title="Loan Id" type="number" property="loanId"></tr>
  <tr filter-element
      title="Create date"
      type="instant"
      property="created"
      [defaultOperation]="FilterOperation.MORE"
      [defaultValue]="DateFilterValues.CURRENT_MONTH_START_DATE"
  ></tr>
  </tbody>
  <div buttons class="mt-1">
  </div>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th sortable="taskStatus" (sort)="onSort($event)">Status</th>
      <th sortable="topic" (sort)="onSort($event)">Topic</th>
      <th sortable="brand" (sort)="onSort($event)">Brand</th>
      <th>Assigned user</th>
      <th sortable="assignedTime" (sort)="onSort($event)">Assigned</th>
      <th>Resolution</th>
      <th sortable="nextProcessTime" (sort)="onSort($event)">Next process time</th>
      <th>Attempts count</th>
      <th sortable="applicationId" (sort)="onSort($event)">Application Id</th>
      <th sortable="loanId" (sort)="onSort($event)">Loan</th>
      <th sortable="clientId" (sort)="onSort($event)">Client</th>
      <th sortable="created" (sort)="onSort($event)">Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let tasks of response?.results; trackBy: trackById" (dblclick)="navigateTo(tasks.id)">
      <td></td>
      <td>{{tasks.id}}</td>
      <td>{{tasks.taskStatus}}</td>
      <td>{{tasks.topic}}</td>
      <td>{{tasks.brand}}</td>
      <td>{{tasks.assignedUser}}</td>
      <td>{{tasks.assignedTime}}</td>
      <td>{{tasks.resolution}}</td>
      <td>{{tasks.nextProcessTime}}</td>
      <td>{{tasks.attempts}}</td>
      <td>{{tasks.applicationId}}</td>
      <td><a *ngIf="tasks.loanId" routerLink="/loans/{{tasks.loanId}}">{{tasks.loanId}}</a></td>
      <td><a *ngIf="tasks.clientId" routerLink="/clients/{{tasks.clientId}}">{{tasks.clientId}}</a></td>
      <td>{{tasks.created}}</td>
    </tr>
    </tbody>
  </table>
</server-table>


