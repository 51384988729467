import { Component, OnInit } from '@angular/core';
import {
  AlertsService,
  ConfirmDialogueComponent,
  FileTemplateService,
  LocalesService,
  NamedComponent,
  Utils,
  ViewHistoryService
} from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileTemplatesRefreshService } from '../../services/file-templates-refresh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-file-template-edit',
  templateUrl: './file-template-edit.component.html',
  styleUrls: ['./file-template-edit.component.scss']
})
export class FileTemplateEditComponent extends NamedComponent implements OnInit {

  id: number;
  fileTemplateEditForm: FormGroup;
  locales$: Observable<Array<string>>;
  fileData?: File;
  fileName?: string;

  constructor(
    activatedRoute: ActivatedRoute,
    protected router: Router,
    private alertService: AlertsService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private viewHistoryService: ViewHistoryService,
    private fileTemplatesService: FileTemplateService,
    private fileTemplatesRefreshService: FileTemplatesRefreshService,
    private localesService: LocalesService,
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.locales$ = this.localesService.getLocales();
    this.fileTemplateEditForm = this.formBuilder.group({
      key: ['', Validators.required],
      fileName: ['', Validators.required],
      locale: '',
      isDisabled: '',
      file: ''
    });
    this.fileTemplatesService.getFileTemplate(this.id).pipe(
      take(1)
    ).subscribe((fileTemplate) => {
      this.fileName = fileTemplate.fileName;
      const fileTemplateData = {
        key: fileTemplate.key,
        fileName: fileTemplate.fileName,
        locale: fileTemplate.locale,
        isDisabled: fileTemplate.isDisabled,
        file: ''
      }
      this.fileTemplateEditForm.setValue(fileTemplateData);
    })
  }

  getName(): string {
    return `Edit file template ${this.id || ''}`;
  }

  deleteFileTemplate() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Delete file template";
    modalRef.componentInstance.content = `Are you sure you want to delete file template #${this.id}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) this.fileTemplatesService.deleteFileTemplate(this.id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {
            this.viewHistoryService.closeTab();
            this.fileTemplatesRefreshService.reloadFileTemplatesListModel();
          }
        );
      }, () => {}
    );
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.fileTemplateEditForm.controls['fileName'].setValue(this.fileData.name);
  }

  downloadFile(fileName) {
    this.fileTemplatesService.getFileOfTemplate(this.id).pipe(
      take(1)
    ).subscribe((response: Blob) => {
        Utils.downloadBlob(response, fileName);
      },
      error => console.error("Error downloading the file.", error));
  }

  submitForm() {
    this.fileTemplatesService.updateFileTemplate(this.id, this.fileTemplateEditForm.value, this.fileData)
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(() => {
        this.alertService.notifySuccess(`File template ${this.fileTemplateEditForm.value.key} updated`);
        this.viewHistoryService.closeTab();
        this.fileTemplatesRefreshService.reloadFileTemplatesListModel();
      })
  }

  cancel() {
    this.viewHistoryService.closeTab();
  }

  navigateToRevisions() {
    this.router.navigate([`/file-templates/revisions/${this.id}`]);
  }

}
