<div class="input-group mr-2">
  <input
    placeholder="{{inputDatetimeFormat}}"
    class="form-control form-control-sm"
    (blur)="inputBlur($event)"
    [ngModel]="date"
    (change)="onInputChange($event)"
  />

  <div class="input-group-append">
    <button
      class="btn btn-outline-secondary btn-sm calendar"
      [ngbPopover]="calendarContent"
      type="button"
    >
    </button>
  </div>
</div>

<ng-template #calendarContent class="time-picker-input">
    <div>
      <div>
        <ngb-datepicker
          name="datepicker"
          [(ngModel)]="dateStruct"
          (ngModelChange)="onDateChange($event)"
        ></ngb-datepicker>
        <ngb-timepicker
          name="timepicker"
          [(ngModel)]="timeStruct"
          (ngModelChange)="onTimeChange($event)"
          [seconds]="seconds"
          [hourStep]="hourStep"
          [minuteStep]="minuteStep"
          [secondStep]="secondStep"
        ></ngb-timepicker>
      </div>
    </div>
</ng-template>
