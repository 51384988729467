<div class="modal-header">
  <h4 class="modal-title">Loan #{{loanId}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        Applied Discounts
      </div>
      <div class="card-body">
        <table class="table table-striped table-hover mt-2">
          <tbody>
          <tr>
            <th>Discount target</th>
            <th>Campaign</th>
            <th>Discount ratio</th>
          </tr>
          <tr *ngFor="let discount of appliedDiscounts | keyvalue; trackBy: trackById">
            <td>{{discount.key}}</td>
            <td>#{{discount.value.campaignId}}</td>
            <td>{{discount.value.discountRatio | ratioToPercent | addPercentSymbol}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
</div>



