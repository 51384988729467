import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Address, FullClient } from '@twino/backoffice-api';
import { Observable, Subscription } from 'rxjs';
import { ClientService, ClientSidebarComponent } from '@backoffice-monorepo/commons';
import { ClientsRefreshService } from '../../../../../../libs/core/src/lib/modules/clients/services/clients-refresh.service';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-addresses',
  templateUrl: './pl-pi-client-addresses.component.html',
  styleUrls: ['./pl-pi-client-addresses.component.css']
})
export class PlPiClientAddressesComponent implements ClientSidebarComponent, OnInit, OnDestroy {

  @Input() client: FullClient;
  addresses$: Observable<Array<Address>>;

  private reloadClientAddressModel: Subscription;


  constructor(
    private clientService: ClientService,
    private clientsRefreshService: ClientsRefreshService,
  ) { }

  ngOnInit(): void {
    this.refresh();
    this.reloadClientAddressModel = this.clientsRefreshService.reloadClientAddressModel$.subscribe(() => {
      this.refresh();
    })
  }

  ngOnDestroy(): void {
    this.reloadClientAddressModel.unsubscribe();
  }

  refresh() {
    this.addresses$ = this.clientService.getClientAddresses(this.client.savedId);
  }
}
