import { Component } from '@angular/core';
import { AlertsService, KonsoleService, NamedComponent } from '@backoffice-monorepo/commons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'backoffice-monorepo-konsole',
  templateUrl: './konsole.component.html',
  styleUrls: ['./konsole.component.scss']
})
export class KonsoleComponent extends NamedComponent {

  executeScriptForm = this.formBuilder.group({
    script: ['', Validators.required],
    result: ''
  });

  executing = false;
  resultText = '';

  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private konsoleService: KonsoleService,
  ) {
    super(activatedRoute);
  }

  getName(): string {
    return `Konsole`;
  }

  updateOutputUi = (text) => {
    this.resultText += text
    this.executeScriptForm.get('result').setValue(this.resultText)
  };

  doneCallback = () => {
    this.executing = false;
  };

  executeScript() {
    this.resultText = ""
    this.executeScriptForm.get('result').setValue(this.resultText)
    this.executing = true;
    this.konsoleService.executeScript(this.executeScriptForm.get('script').value).subscribe({
      next: this.updateOutputUi,
      error: undefined,
      complete: this.doneCallback,
    })
  }
}
