import { Component, Host, OnInit } from '@angular/core';
import { ClientService, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import { Client, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsRefreshService } from '../../services/clients-refresh.service';

@Component({
  selector: 'backoffice-monorepo-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Client>) => new FilteringService(listService),
      deps: [ClientService]
    }
  ]
})
export class ClientsListComponent extends ListComponent<Client> implements OnInit {

  exportModuleName = "clients";

  constructor(
    @Host() filteringService: FilteringService<Client>,
    protected router: Router,
    clientService: ClientService,
    activatedRoute: ActivatedRoute,
    private clientsRefreshService: ClientsRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.clientsRefreshService.reloadClientListModel$.subscribe(() => {
      this.refresh();
    });
  }

  getName(): string {
    return 'Clients';
  }

  navigateTo(id: number) {
    this.router.navigate([`/clients/${id}`]);
  }

}
