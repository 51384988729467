<form [formGroup]="clientEmailSendForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Send Email to client #{{clientId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="brand" class="col-sm-3 control-label">Brand<sup class="text-danger">*</sup></label>
      <div class="col-sm-9">
        <select ngbAutofocus class="form-control form-control-sm" id="brand" formControlName="brand" name="brand">
          <option *ngFor="let key of productBrandsList$ | async" [ngValue]="key">{{key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="from" class="col-sm-3 control-label">From<sup class="text-danger">*</sup></label>
      <div class="col-sm-9">
        <select class="form-control form-control-sm" id="from" formControlName="from" name="from">
          <option *ngFor="let mailbox of mailboxes" [ngValue]="mailbox.emailAddress">{{mailbox.emailAddress}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="destination" class="col-sm-3 control-label">To</label>
      <div class="col-sm-9">
        <input type="text" class="form-control form-control-sm" id="destination" formControlName="destination" name="destination">
      </div>
    </div>
    <div class="form-group row">
      <label for="subject" class="col-sm-3 control-label">Subject<sup class="text-danger">*</sup></label>
      <div class="col-sm-9">
        <input type="text" class="form-control form-control-sm" id="subject" formControlName="subject" name="subject">
      </div>
    </div>
    <div class="form-group row">
      <label for="templateKey" class="col-sm-3 control-label">Message template<sup class="text-danger">*</sup></label>
      <div class="col-sm-9">
        <select class="form-control form-control-sm" id="templateKey" formControlName="templateKey" name="templateKey">
          <option *ngFor="let template of messageTemplates" [ngValue]="template.key">{{template.key}}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="body" class="col-sm-3 control-label">Description<sup class="text-danger">*</sup></label>
      <div class="col-sm-9">
        <editor
          [init]="editorConfig"
          formControlName="body"
          id="body"
        ></editor>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 control-label">Attachments</label>
      <div class="col-sm-9">
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let file of files">
            <span id="file-label">{{file.name}}</span>
            <button type="button" class="btn btn-danger btn-sm float-right" (click)="removeFile(file.index)">
              <bi name="x"></bi>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group custom-file">
      <input type="file"
             class="custom-file-input"
             id="attachment"
             multiple="multiple"
             (change)="addFile($event)"/>
      <label class="custom-file-label" for="attachment">Choose file...</label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientEmailSendForm.valid">Send</button>
  </div>
</form>







