import { Component, Host, OnInit } from '@angular/core';
import { ConfirmDialogueComponent, FilteringService, ListComponent, RoleService, UserAccountService } from '@backoffice-monorepo/commons';
import { SearchQueryAware, UserAccount } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAddComponent } from '../user-add/user-add.component';
import { forkJoin } from 'rxjs';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<UserAccount>) => new FilteringService(listService),
      deps: [UserAccountService]
    }
  ]
})
export class UsersListComponent extends ListComponent<UserAccount> implements OnInit {

  constructor(
    @Host() filteringService: FilteringService<UserAccount>,
    protected router: Router,
    private modalService: NgbModal,
    private userAccountService: UserAccountService,
    private roleService: RoleService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getName(): string {
    return 'Users';
  }

  navigateTo(id: number) {
  }

  onCreateClick() {
    this.roleService.getRoles().subscribe(roles => {
      const modalRef = this.modalService.open(UserAddComponent);

      modalRef.componentInstance.roles = roles;
      modalRef.result.then(result => {
        if (result) {
          this.userAccountService.createUser(result).pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => {
            this.refresh();
          });
        }
      })
    })
  }

  onEditClick(id: number) {
    forkJoin({
      user: this.userAccountService.getUserById(id),
      userRoles: this.userAccountService.getUserRoles(id),
      roles: this.roleService.getRoles()
    }).pipe(
      takeUntil(this.$destroy)
    ).subscribe(({user, userRoles, roles}) => {
      const modalRef = this.modalService.open(UserEditComponent);

      modalRef.componentInstance.roles = roles;
      modalRef.componentInstance.selectedRoles = userRoles.map(role => role.name);
      modalRef.componentInstance.username = user.username;

      modalRef.result.then(result => {
        if (result) {
          this.userAccountService.updateUserRoles(id, result).pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => {
            this.refresh();
          });
        }
      });
    })
  }

  onDeleteClick(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);

    modalRef.componentInstance.header = 'Delete user';
    modalRef.componentInstance.content = 'Are You Sure you want to delete user?';

    modalRef.result.then(result => {
      if (result) {
        this.userAccountService.deleteUser(id).pipe(
          takeUntil(this.$destroy)
        ).subscribe(() => {
          this.refresh();
        });
      }
    });
  }

}
