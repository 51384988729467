import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicComponent, ClientDocumentService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientDocument, UpdateClientDocumentRequest } from '@twino/backoffice-api';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'client-document-edit',
  templateUrl: './client-document-edit.component.html',
  styleUrls: ['./client-document-edit.component.scss']
})
export class ClientDocumentEditComponent extends BasicComponent implements OnInit {

  @Input() clientDocument: ClientDocument;

  clientDocumentEditForm: FormGroup;
  clientDocumentRequest: UpdateClientDocumentRequest;
  clientDocumentTypes: Array<string>;
  clientDocumentVerificationStatuses: Array<string>;

  constructor(
    public activeModal: NgbActiveModal,
    private clientDocumentService: ClientDocumentService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    forkJoin([
      this.clientDocumentService.getClientDocumentTypes(),
      this.clientDocumentService.getClientDocumentVerificationStatuses()
    ])
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(([documentTypes, verificationStatuses]) => {
        this.clientDocumentTypes = documentTypes;
        this.clientDocumentVerificationStatuses = verificationStatuses;
      });
    this.clientDocumentEditForm = this.formBuilder.group({
      type: ['', Validators.required],
      verificationStatus: ['', Validators.required],
      description: '',
    });
    this.clientDocumentRequest = {
      type: this.clientDocument.type,
      verificationStatus: this.clientDocument.verificationStatus,
      description: this.clientDocument.description,
    }
    this.clientDocumentEditForm.setValue(this.clientDocumentRequest);
  }

  submitForm() {
    this.clientDocumentService.updateClientDocument(this.clientDocument.clientId, this.clientDocumentEditForm.value, this.clientDocument.id)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
        this.clientsRefreshService.reloadClientDocumentModel();
        this.activeModal.close();
    })
  }
}

