<aside class="container-fluid pt-3 pb-3 pl-1">
  <div class="col-md-12 ml-0 pl-0">
      <server-refresh (refresh)="refresh()"></server-refresh>
      <span class="mr-2 ml-3">
            <button appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                    class="btn btn-danger btn-sm"
                    (click)="updateRiskEvaluationOrder()">
              <bi name="pencil-square"></bi>
              Change evaluation order
            </button>
      </span>
    <span class="mr-2 ml-3">
            <button appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                    class="btn btn-success btn-sm"
                    (click)="addStep()">
              <bi name="pencil-square"></bi>
              Add step
            </button>
      </span>
  </div>
</aside>
<server-table>
  <table list-table class="table table-striped table-hover mt-1">
    <thead class="text-light bg-primary">
      <tr>
        <th></th>
        <th>Id</th>
        <th>Evaluation Name</th>
        <th>Description</th>
        <th>Version</th>
        <th>Continue evaluation</th>
        <th>Order</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let riskEvaluationFlowStep of riskEvaluationConfigsList"
          (dblclick)="navigateTo(riskEvaluationFlowStep.id)" cdkDrag>
        <td></td>
        <td>{{riskEvaluationFlowStep.id}}</td>
        <td>{{riskEvaluationFlowStep.name}}</td>
        <td>{{riskEvaluationFlowStep.description}}</td>
        <td>{{riskEvaluationFlowStep.version}}</td>
        <td>{{riskEvaluationFlowStep.continueEvaluation}}</td>
        <td>{{riskEvaluationFlowStep.evaluationSequence}}</td>
        <td>
          <button appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                  class="btn btn-danger btn-sm"
                  (click)="deleteStep(riskEvaluationFlowStep.id)">
            Delete
          </button>
        </td>
        <td>
          <button appRequirePermission="admin.all, risk-evaluation-configuration.edit"
                  class="btn btn-success btn-sm"
                  (click)="editStep(riskEvaluationFlowStep.id)">
            <bi name="pencil-square"></bi>
            Edit
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</server-table>



