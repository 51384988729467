import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { HomeComponent, LoginComponent, LoginGuard, LogoutComponent, ViewHistoryService } from '@backoffice-monorepo/commons';
import { AdminComponent } from './components/admin/admin.component';
import { LocalTranslationManagerModule } from './modules/translation-manager/local-translation-manager.module';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {
    path: '', component: AdminComponent, canActivate: [LoginGuard], children: [
      {path: 'home', component: HomeComponent},
      {
        path: 'loans',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.LoansModule)
      },
      {
        path: 'clients',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.ClientsModule)
      },
      {
        path: 'tasks',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.TasksModule)
      },
      {
        path: 'applications',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.ApplicationsModule)
      },
      {
        path: 'payments',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.PaymentsModule)
      },
      {
        path: 'payment-distributions',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.PaymentDistributionsModule)
      },
      {
        path: 'transfers',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.TransfersModule)
      },
      {
        path: 'communications',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.CommunicationsModule)
      },
      {
        path: 'activity-overview',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.ActivityOverviewModule)
      },
      {
        path: 'system-configuration',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.SystemConfigsModule)
      },
      {
        path: 'product-configuration',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.ProductConfigsModule)
      },
      {
        path: 'risk-evaluation-configs',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.RiskEvaluationConfigsModule)
      },
      {
        path: 'message-templates',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.MessageTemplatesModule)
      },
      {
        path: 'file-templates',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.FileTemplatesModule)
      },
      {
        path: 'notification-configs',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.NotificationConfigsModule)
      },
      {
        path: 'translation-manager',
        data: { reuse: true},
        loadChildren: () => LocalTranslationManagerModule
      },
      {
        path: 'discount-campaigns',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.DiscountCampaignsModule)
      },
      {
        path: 'creditg-personal-id-blacklist-entries',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.BlacklistModule)
      },
      {
        path: 'about-app',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.AboutModule)
      },
      {
        path: 'konsole',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.KonsoleModule)
      },
      {
        path: 'batch-job-executions',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.BatchJobModule)
      },
      {
        path: 'users',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.UsersModule)
      },
      {
        path: 'phone-application',
        loadChildren: () => import('./modules/phone-application/phone-application.module').then(
          (m) => m.PhoneApplicationModule),
        data: {reuse: true}
      },
      {
        path: 'debt-step-configs',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.DebtConfigsModule)
      },
      {
        path: 'affiliates',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.AffiliatesModule)
      },
      {
        path: 'application-without-confirmed-otp',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.ApplicationWithoutConfirmedOtpModule)
      },
      {
        path: 'payments-import-export',
        loadChildren: () => import('./modules/banks/banks.module').then((m) => m.BanksModule),
        data: {reuse: true}
      },
      {
        path: 'debt-collection-stages',
        loadChildren: () => import('./modules/debt-collection-stages/debt-collection-stages.module').then(
          (m) => m.DebtCollectionStagesModule),
        data: {reuse: true}
      },
      {
        path: 'sale-partners',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.SalePartnersModule)
      },
      {
        path: 'loan-sale',
        data: {reuse: true},
        loadChildren: () => import('@backoffice-monorepo/core').then((m) => m.LoanSaleModule)
      },
      {
        path: 'vas',
        loadChildren: () => import('./modules/vas/vas.module').then(
          (m) => m.VasModule),
        data: {reuse: true}
      },
      {
        path: 'tariff',
        loadChildren: () => import('./modules/tariff/tariff.module').then(
          (m) => m.TariffModule),
        data: {reuse: true}
      },
    ]
  },
  {path: '**', redirectTo: '/login'},
];

@NgModule({
  providers: [
    {provide: RouteReuseStrategy, useExisting: ViewHistoryService}
  ],
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'reload'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
