import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DiscountCampaignsListComponent } from './components/discount-campaigns-list/discount-campaigns-list.component';
import { DiscountCampaignComponent } from './components/discount-campaign/discount-campaign.component';
import { DiscountCampaignsImportComponent } from './components/discount-campaigns-import/discount-campaigns-import.component';


const routes: Routes = [
  {path: '', component:DiscountCampaignsListComponent, pathMatch: 'full'},
  {path: 'create', component: DiscountCampaignComponent, pathMatch: 'full'},
  {path: 'import', component: DiscountCampaignsImportComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DiscountCampaignsRoutingModule {
}
