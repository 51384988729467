<div id="welcome" class="bg-dark">
  <div class="container-sm bg-white box">
    <header>
      <h1>{{projectName}}</h1>
      <h6>version: {{projectVersion}}</h6>
      <h6>api: {{apiVersion}}</h6>
      <hr />
    </header>

    <form role="form">
      <div class="form-group">
        <label for="signInLogin" class="control-label">Login</label>

        <input type="text" class="form-control" name="login" id="signInLogin"
               placeholder="Enter your login" [(ngModel)]="user.name" autofocus>
      </div>
      <div class="form-group">
        <label for="signInPassword" class="control-label">Password</label>

        <input type="password" class="form-control" id="signInPassword" name="password"
               placeholder="Password" [(ngModel)]="user.password">
      </div>

      <div class="form-group" *ngIf="error">
        <label class="text-danger">{{error}}</label>
      </div>

      <div class="form-group text-center">
        <button type="submit" (click)="login()" class="btn btn-warning">Login</button>
      </div>
    </form>
  </div>
</div>
