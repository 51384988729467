<server-table>
  <table list-table class="table table-striped table-hover table-sm">
    <thead class="text-light bg-primary">
    <tr>
      <th>Id</th>
      <th>Status</th>
      <th>Transaction type</th>
      <th>Amount</th>
      <th>Surplus amount</th>
      <th>Booking date</th>
      <th>From account</th>
      <th>Client</th>
      <th>Loan</th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of data; trackBy: trackById;">
      <td>{{ item.id }}</td>
      <td>{{ item.status }}</td>
      <td>{{ item.type }}</td>
      <td>{{ item.amount }}</td>
      <td>{{ item.surplusAmount }}</td>
      <td>{{ item.bookingDate | date: 'dd.MM.yyyy' }}</td>
      <td>{{ item.fromAccount }}</td>
      <td><a *ngIf="item.clientId" routerLink="/clients/{{item.clientId}}">{{item.clientId}}</a></td>
      <td><a *ngIf="item.clientId" routerLink="/loans/{{item.loanId}}">{{item.loanId}}</a></td>
      <td>
        <button type="button"
                class="btn btn-outline-danger btn-sm"
                *ngIf="item.status === paymentStatus.PROCESSED && payment.direction === paymentDirection.INCOMING"
                (click)="voidPaymentDistribution(item.id)"
                appRequirePermission="admin.all,payment-distribution.void">
          Void
        </button>
      </td>
      <td>
        <button type="button"
                class="btn btn-outline-primary btn-sm"
                *ngIf="item.status === paymentStatus.NEW && payment.direction === paymentDirection.INCOMING"
                (click)="processPaymentDistribution(item.id)"
                appRequirePermission="admin.all,payment-distribution.processing">
          Process
        </button>
      </td>
      <td>
        <button type="button"
                class="btn btn-outline-danger btn-sm"
                *ngIf="item.status === paymentStatus.NEW && payment.direction === paymentDirection.INCOMING"
                (click)="deletePaymentDistribution(item.id)"
                appRequirePermission="admin.all,payment-distribution.delete">
          Delete
        </button>
      </td>
      <td>
        <button type="button" appRequirePermission="admin.all,payment.processing,payment.create-manual,loan.admin"
                class="btn btn-outline-success btn-sm" title="Return payment"
                (click)="addReturnPayment()"
                *ngIf="item.type === transactionTypes.FAULTY_IDENTIFICATION">
          Return
        </button>
      </td>
      <td></td>
    </tr>
    </tbody>
  </table>
</server-table>
