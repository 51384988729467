import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { AlertsService, ConfirmDialogueComponent, DiscountCampaignService, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import { Campaign, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiscountCampaignViewComponent } from '../discount-campaign-view/discount-campaign-view.component';
import { DiscountCampaignRefreshService } from '../../services/discount-campaigns-refresh.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-discount-campaigns-list',
  templateUrl: './discount-campaigns-list.component.html',
  styleUrls: ['./discount-campaigns-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Campaign>) => new FilteringService(listService),
      deps: [DiscountCampaignService]
    }
  ]
})
export class DiscountCampaignsListComponent extends ListComponent<Campaign> implements OnInit, OnDestroy {

  private reloadDiscountCampaignsListModel: Subscription;
  exportModuleName = "campaigns";

  constructor(
    @Host() filteringService: FilteringService<Campaign>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
    private discountCampaignService: DiscountCampaignService,
    private modalService: NgbModal,
    private discountCampaignRefreshService: DiscountCampaignRefreshService,
    private alertService: AlertsService,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadDiscountCampaignsListModel = this.discountCampaignRefreshService.reloadDiscountCampaignsListModel$.subscribe(() => {
      super.ngOnInit();
    })
  }

  getName(): string {
    return 'Discount campaigns';
  }

  navigateTo(id: number) {
    this.router.navigate([`/discount-campaigns/${id}`]);
  }

  navigateToCreate() {
    this.router.navigate([`/discount-campaigns/create`]);
  }

  navigateToImport() {
    this.router.navigate([`/discount-campaigns/import`]);
  }

  ngOnDestroy(): void {
    this.reloadDiscountCampaignsListModel.unsubscribe();
  }

  viewCampaign(id: number) {
    const modalRef = this.modalService.open(DiscountCampaignViewComponent, {size: 'lg'});
    modalRef.componentInstance.id = id;
  }

  changeStatus(id: number, isActive: boolean) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Change campaign #${id} status`;
    modalRef.componentInstance.content = "Are you sure you want to change campaign status?";
    modalRef.result.then(
      (result) => {
        if (result === true) this.discountCampaignService.setActive(id, isActive).pipe(
          takeUntil(this.$destroy)
        ).subscribe(
          () => {
            this.alertService.notifySuccess(`Status of campaign #${id} is changed`);
            super.ngOnInit();
          }
        );
      }, () => {}
    );
  }
}
