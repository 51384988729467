import { Component, Host, OnInit } from '@angular/core';
import {
  CommunicationService,
  draggableModalOptionsLarge,
  FilteringService,
  ListComponent
} from '@backoffice-monorepo/commons';
import { Communication, CommunicationType, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationContentViewComponent } from '../communication-content-view/communication-content-view.component';

@Component({
  selector: 'backoffice-monorepo-communications-list',
  templateUrl: './communications-list.component.html',
  styleUrls: ['./communications-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Communication>) => new FilteringService(listService),
      deps: [CommunicationService]
    }
  ]
})
export class CommunicationsListComponent extends ListComponent<Communication> implements OnInit {

  communicationType = CommunicationType;
  exportModuleName = "communications";

  constructor(
    @Host() filteringService: FilteringService<Communication>,
    protected router: Router,
    private communicationService: CommunicationService,
    private modalService: NgbModal,
    activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getName(): string {
    return 'Communications';
  }

  navigateTo(id: number) {
    this.router.navigate([`/communications/${id}`]);
  }

  viewContent(communicationId) {
    const modalRef = this.modalService.open(CommunicationContentViewComponent, draggableModalOptionsLarge);
    modalRef.componentInstance.communicationId = communicationId;
  }
}
