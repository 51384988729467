import { Term } from './term';

export interface Application {
  id: number
  clientId: number
  askedTerm: Term
  askedAmount: string
  receiveChannel: string
  status: string
  confirmed: boolean
  flowState: string
  flowStateStartDateTime: string
  createDate: Date
  closeDate?: Date
  modifiedDate: Date
  productType: string
  productSubType: string
  resolution?: string
  flowStateDetails: string
  rejectReason?: string
  creditScore: string
  confirmationDate: Date
  usedPromoCode?: string
  type: LoanApplicationType
  brand: string
}

export interface ApplicationWebData {
  ip: string
  cookies: Map<string, string>
  params: Map<string, string>
  applicationFlowStateOnCreation?: string

}

export interface EvaluationResult {
  evaluationId: number
  applicationId: number
  evaluationName: string
  finalDecision: string
  configVersion: number
  ruleResults: RiskRuleResult[]
}

export interface RiskRuleResult {
  ruleName: string
  resolution: string
  testMode: boolean
  version: number
  resolutionMessage?: string
  resolutionData?: string
}

export enum LoanApplicationType {
  MAIN_AMOUNT = 'MAIN_AMOUNT',
  ADDITIONAL_AMOUNT = 'ADDITIONAL_AMOUNT'
}
