export interface Discount {
  id: number
  campaignId: number
  createdDate: Date
  discountTarget: DiscountTarget
  discountRatio: number
  predicate: Predicate
}

export interface DiscountShortInfo {
  id: number
  campaignId: number
  discountTarget: DiscountTarget
  discountRatio: number
}

export interface Campaign {
  id: number
  name: string
  fromDate: Date
  toDate: Date
  isActive: boolean
  isSingleUse: boolean
  type: CampaignType
  discounts: Discount[]
}

export interface CampaignShortInfo {
  id: number
  name: string
  fromDate: Date
  toDate: Date
  isActive: boolean
  isSingleUse: boolean
  type: CampaignType
}

export interface CreateCampaignRequest {
  campaignName: string
  fromDate: Date
  toDate: Date
  isActive: boolean
  isSingleUse: boolean
}

export interface CreateDiscountRequest {
  discountTarget?: DiscountTarget
  discountPercent?: number
  predicateScriptVariables: Map<string, string>
}

export interface Predicate {
  predicateScript: string
  usedVariables: Map<string, string>
}

export interface PredicateResponse {
  script: string
  variables: string[]
}

export interface PredicateParams {
  predicateKey: string
  predicateValue: string
}

export enum DiscountTarget {
  INTEREST = 'INTEREST',
  LOAN_COMMISSION = 'LOAN_COMMISSION',
  EXTENSION_FEE = 'EXTENSION_FEE',
  EXTENSION_COMMISSION = 'EXTENSION_COMMISSION',
  PENALTY = 'PENALTY'
}

export enum CampaignType {
  MANUAL = 'MANUAL',
  RETENTION_AUTO = 'RETENTION_AUTO'
}

export interface ExcelFileProcessingStatus {
  isActive: boolean
  currIndex: number
  totalRows: number
  errorMessage?: string
}

export interface AddDiscountCampaignToClientRequest {
  termDays?: number
  discountPercent?: number
}


