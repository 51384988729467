/* eslint-disable no-console,no-restricted-syntax */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
// noinspection ES6PreferShortImport
import { UserService } from '../services/user.service';
import { catchError, flatMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return of(this.userService.isAuthenticated())
      .pipe(flatMap((response) => response ? of(response) : this.userService.isLoggedIn()))
      .pipe(flatMap((response) => response ? this.userService.loadUser() : of(response)))
      .pipe(catchError((errorResponse) => {
        console.debug(errorResponse);
        return of(false);
      }))
      .pipe(tap((response) => {
        console.debug('user is authenticated or logged in', response);
        if (response === false) {
          return this.router.navigate(['/login'], {queryParams: {return: state.url}});
        }
      }));
  }
}
