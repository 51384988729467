<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [searchQuery]="searchQuery"
  [withFilterBlock]="true"
  [withExportButton]="true"
  [exportModuleName]="exportModuleName"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Id" type="number" property="id"></tr>
  <tr filter-element
      title="Type"
      type="enum"
      property="type"
      enum="type"
  ></tr>
  <tr filter-element
      title="Direction"
      type="enum"
      property="direction"
      enum="direction"
  ></tr>
  <tr filter-element title="Destination" type="string" property="destination"></tr>
  <tr filter-element title="Sender" type="string" property="sender"></tr>
  <tr filter-element title="Template Key" type="string" property="templateKey"></tr>
  <tr filter-element title="Subject" type="string" property="subject"></tr>
  <tr filter-element title="Client Id" type="number" property="clientId"></tr>
  <tr filter-element
      title="Create date"
      type="instant"
      property="created"
  ></tr>
  </tbody>
  <table list-table class="table table-striped table-hover">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th>ID</th>
      <th sortable="clientId" (sort)="onSort($event)">ClientId</th>
      <th>Type</th>
      <th>Direction</th>
      <th>TemplateKey</th>
      <th>Subject</th>
      <th>Content</th>
      <th>Sender</th>
      <th>Destination</th>
      <th>Attachments</th>
      <th sortable="created" (sort)="onSort($event)">Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let communications of response?.results; let i = index; trackBy: trackById">
      <td></td>
      <td>{{communications.id}}</td>
      <td>
        <a *ngIf="communications.clientId" routerLink="/clients/{{communications.clientId}}">{{communications.clientId}}</a>
      </td>
      <td>{{communications.type}}</td>
      <td>{{communications.direction}}</td>
      <td>{{communications.templateKey}}</td>
      <td>{{communications.subject}}</td>
      <td>
        <button type="button"
                class="btn btn-outline-primary btn-sm"
                (click)="viewContent(communications.id)"
                title="View content">
          <bi name="envelope" *ngIf="communications.type === communicationType.EMAIL || communications.type === communicationType.LETTER"></bi>
          <bi name="chat-square-text" *ngIf="communications.type !== communicationType.EMAIL && communications.type !== communicationType.LETTER"></bi>
        </button>
      </td>
      <td>{{communications.sender}}</td>
      <td>{{communications.destination}}</td>
      <td>
        <ul class="list-group" *ngIf="communications.emailProperties != null && communications.emailProperties.attachments.length>0">
          <li class="list-group-item" *ngFor="let attachment of communications.emailProperties.attachments; trackBy: trackById">
            <a href="/ws/api/communication/{{communications.id}}/attachment/{{attachment.uid}}">{{attachment.fileName}}</a>
          </li>
        </ul>
        <span *ngIf="!communications.emailProperties">
	        	 {{communications.attachmentsNames}}
        </span>
      </td>
      <td>{{communications.created}}</td>
    </tr>
    </tbody>
  </table>
</server-table>



