import { HttpErrorResponse } from '@angular/common/http';

export class GlobalErrors {
  forbidden: HttpErrorResponse
  notFound: HttpErrorResponse
  requestFailed: HttpErrorResponse
  requestTooLarge: HttpErrorResponse
  uploadFailed: HttpErrorResponse
  newVersion: HttpErrorResponse
  sessionExpired: HttpErrorResponse
  connectionLost: HttpErrorResponse
}
