export const schema = (langSchema, keyTranslationsSchema) => ({
    "$schema": "http://json-schema.org/schema#",
    definitions: {
      keyTranslations: keyTranslationsSchema,
      translation: langSchema,
      'form-page': {
        type: 'object',
        properties: {
          title: { $ref: '#/definitions/translation' },
          subtitle: { $ref: '#/definitions/translation' },
          linkTitle: { $ref: '#/definitions/translation' },
          mobile: { $ref: '#/definitions/form-page' },
          form: { type: 'object' },
          errors: { type: 'object' },
          buttons: { $ref: '#/definitions/keyTranslations' },
          modals: { $ref: '#/definitions/modal' },
          labels: { $ref: '#/definitions/keyTranslations' },
        },
        additionalProperties: false,
        required: ['title', 'subtitle', 'form']
      },
      page: {
        type: 'object',
        properties: {
          title: { $ref: '#/definitions/translation' },
          subtitle: { $ref: '#/definitions/translation' }
        },
        required: ['title', 'subtitle'],
        additionalProperties: false
      },
      "info-page": {
        type: 'object',
        properties: {
          title: { $ref: '#/definitions/translation' },
          subtitle: { $ref: '#/definitions/translation' },
          icon: { $ref: '#/definitions/translation' },
          buttonTitle: { $ref: '#/definitions/translation' },
          linkTitle: { $ref: '#/definitions/translation' },
          commentary: {$ref: '#/definitions/translation'},
          additionalText: {$ref: '#/definitions/translation'},
        },
        required: ['title', 'subtitle'],
        additionalProperties: false
      },
      notification: {
        type: 'object',
        properties: {
          title: { $ref: '#/definitions/translation' },
          subtitle: { $ref: '#/definitions/translation' }
        },
        required: ['title', 'subtitle'],
        additionalProperties: false
      },
      modal: {
        type: 'object',
        additionalProperties: {
          anyOf: [
            {
              type: 'object',
              properties: {
                title: { $ref: '#/definitions/translation' },
                subtitle: { $ref: '#/definitions/translation' },
                buttons: { $ref: '#/definitions/keyTranslations' },
              },
              required: ['title', 'subtitle'],
              additionalProperties: false
            }
          ]
        }
      }
    },
    "title": "Translations",
    "description": "All translations",
    "type": "object",
    "properties": {
      action: { $ref: '#/definitions/keyTranslations' },
      errors: { $ref: '#/definitions/keyTranslations' },
      common: { $ref: '#/definitions/keyTranslations' },
      buttons: { $ref: '#/definitions/keyTranslations' },
      'document-types': { $ref: '#/definitions/keyTranslations' },
      menu: { $ref: '#/definitions/keyTranslations' },
      education: { $ref: '#/definitions/keyTranslations' },
      employment: { $ref: '#/definitions/keyTranslations' },
      calculator: { $ref: '#/definitions/keyTranslations' },
      'available-funds-card': { $ref: '#/definitions/keyTranslations' },
      'working-hours': {
        type: 'object',
        properties: {
          'default': { $ref: '#/definitions/keyTranslations' },
          'master-card': { $ref: '#/definitions/keyTranslations' },
        },
        additionalProperties: false
      },
      footer: {
        oneOf: [{ $ref: '#/definitions/keyTranslations' },
        {
          properties: {
            'links-section': { $ref: '#/definitions/keyTranslations' },
          },
        }
        ],
        required: ['links-section']
      },
      'form-pages': {
        type: 'object',
        properties: {
          otp: {
            allOf: [{ $ref: '#/definitions/form-page' },
            {
              properties: {
                modals: {
                  type: 'object',
                  additionalProperties: true
                },

              }
            }],
            required: ['modals']

          },
          "financial-validation": {
            type: 'object',
            extras:  {
              type: 'object',
              properties: {
                explains: { $ref: '#/definitions/translation' },
                options: {
                  type: 'object',
                  properties: {
                    BlueMediaScreenscrapper:  { $ref: '#/definitions/translation' },
                    BlueMediaPennyTransfer:  { $ref: '#/definitions/translation' },
                    ManualPennyTransfer:  { $ref: '#/definitions/translation' },
                  }
                }
              },
            }
          },
          "repayment": {
            type: 'object',
            extras:  {
              type: 'object',
              modal: {$ref: '#/definitions/modal'}
            }
          }

        },
        additionalProperties: { $ref: '#/definitions/form-page' }
      },
      pages: {
        type: 'object',
        properties: {
          "traditional-payment": {
            type: 'object',
            properties: {
              labels: { $ref: '#/definitions/keyTranslations' }
            },


              required: ['labels'],
          },
          additionalProperties: {allOf: [{ $ref: '#/definitions/page'}]}
        },
      },
      notifications: {
        type: 'object',
        additionalProperties: { oneOf: [{ $ref: '#/definitions/notification' }] },
      },
      "info-pages": {
        type: 'object',
        additionalProperties: { allOf: [{ $ref: '#/definitions/info-page' }] },
      },
      cards: {
        type: 'object',
        properties: {
          "credit-line": {
            type: 'object',
            properties: {
              applyingFor: { $ref: '#/definitions/translation' },
              creditLine: { $ref: '#/definitions/translation' },
              withCreditLine: { $ref: '#/definitions/translation' },
            },
            additionalProperties: false,
            required: ['applyingFor', 'creditLine', 'withCreditLine']
          },
          "sms-notification": {
            type: 'object',
            properties: {
              message: { $ref: '#/definitions/translation' },
              commentary: { $ref: '#/definitions/translation' }
            },
            additionalProperties: false,
            required: ['message', 'commentary']
          },
          "business-card": {
            type: 'object',
            properties: {
              businessName: { $ref: '#/definitions/translation' },
              address: { $ref: '#/definitions/translation' }
            },
            additionalProperties: false,
            required: ['businessName', 'address']
          },
          "transfer-card": {
            type: 'object',
            properties: {
              title: { $ref: '#/definitions/translation' },
              chooseAccountLink: { $ref: '#/definitions/translation' }
            },
            additionalProperties: false,
            required: ['title', 'chooseAccountLink']
          },
        },
        additionalProperties: false,
        required: ['credit-line', 'sms-notification' ]
      }
    },
    additionalProperties: false,
  });
