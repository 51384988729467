<div class="container-fluid pt-2 col-sm-9">
  <div class="card">
    <div class="card-header">
      System configuration record #{{id}}
    </div>
    <div class="card-body">
      <form [formGroup]="systemConfigurationEditForm" (ngSubmit)="submitForm()">
        <div class="form-group row">
          <label for="name" class="col-sm-1 control-label">Name<sup class="text-danger">*</sup></label>
          <div class="col-sm-11">
            <input ngbAutofocus type="text" class="form-control form-control-sm" id="name" formControlName="name" name="name">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <json-editor [options]="editorOptions" formControlName="data"></json-editor>
          </div>
        </div>
        <button type="submit" class="btn btn-outline-danger" [disabled]="systemConfigurationEditForm.invalid || isInvalidJson">Save</button>
      </form>
    </div>
  </div>
</div>
