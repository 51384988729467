export interface ResignProlongationRequest {
  loanId: number
  originator: LoanProlongationResignationOriginator
  resignationCause: string
  onTime: Date
}

export interface CancelProlongationResignationRequest {
  resignationId: number
  originator: LoanProlongationResignationOriginator
  cancellationCause: string
  onTime: Date
}

export interface LoanProlongationResignationsResponse {
  id: number
  resignationTime: Date
  cancellationTime?: Date
  resignationCause: string
  cancellationCause?: string
  loanId: number
  originator: LoanProlongationResignationOriginator
  documentSentTime?: Date
}

export enum LoanProlongationResignationOriginator {
  CLIENT = 'CLIENT',
  LENDER = 'LENDER'
}
