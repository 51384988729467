import { Injectable } from '@angular/core';
import { OptionalCoreComponentResolver } from '../../../../../libs/commons/src/lib/resolvers/optional-core-component.resolver';
import { OptionalCoreComponent } from '@backoffice-monorepo/commons';

@Injectable({
  providedIn: 'root'
})
export class PlPiOptionalCoreComponentResolver implements OptionalCoreComponentResolver {

  allowedOptionalCoreComponents = []

  resolve(): OptionalCoreComponent[] {
    return  this.allowedOptionalCoreComponents;
  }
}
