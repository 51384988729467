<span class="mr-2 ml-3">
  <button appRequirePermission="admin.all, transfer.create" class="btn btn-success btn-sm"
          (click)="createTransfer()">
            <bi name="plus-circle"></bi>
            Create transfer
          </button>
</span>

<span class="ml-3">
  <button type="button" class="btn btn-info" *ngIf="bluemediaBalance">
    Current balance <span class="badge badge-light">{{bluemediaBalance.currentBalance}}</span>
  </button>
</span>

<span class="ml-3">
  <button type="button" class="btn btn-info" *ngIf="bluemediaBalance">
    Available balance <span class="badge badge-light">{{bluemediaBalance.availableBalance}}</span>
  </button>
</span>
