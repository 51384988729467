<div class="card">
  <div class="card-header text-light bg-primary">
    <bi name="briefcase"></bi>
    Client details
    <button appRequirePermission="client.all,client.edit,admin.all"
            class="btn btn-sm btn-light float-right mr-1"
            (click)="editClientDetails()">
      <bi name="pencil-square"></bi>
      Edit details
    </button>
  </div>
  <div class="card-text">
    <transform-table [transformInstructions]="clientDetailsTransformations" [entity]="data"></transform-table>
  </div>
</div>
