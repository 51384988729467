import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigRefreshService implements OnDestroy{

  public reloadSystemConfigListModel$ = new Subject<void>();


  reloadSystemConfigListModel() {
    this.reloadSystemConfigListModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadSystemConfigListModel$.unsubscribe();
  }
}
