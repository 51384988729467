import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalePartnersListComponent } from './components/sale-partners-list/sale-partners-list.component';
import { SalePartnersRoutingModule } from './sale-partners-routing.module';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SalePartnerComponent } from './components/sale-partner/sale-partner.component';



@NgModule({
  declarations: [SalePartnersListComponent, SalePartnerComponent],
  imports: [
    CommonModule,
    SalePartnersRoutingModule,
    CommonsModule,
    NgbNavModule,
    TranslateModule,
    ReactiveFormsModule,
  ]
})
export class SalePartnersModule { }
