import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksRoutingModule } from './tasks-routing.module';
import { TasksDashboardComponent } from './components/tasks-dashboard/tasks-dashboard.component';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbAccordionModule, NgbModule, NgbNavModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewDashboardTaskComponent } from './components/view-dashboard-task/view-dashboard-task.component';
import { MomentModule } from 'ngx-moment';
import { TaskGroupsListComponent } from './components/task-groups-list/task-groups-list.component';
import { TaskGroupAddComponent } from './components/task-group-add/task-group-add.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { TaskComponent } from './components/task/task.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TaskSendEmailComponent } from './components/task-send-email/task-send-email.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DynamicModule } from 'ng-dynamic-component';
import { TaskEmailSendComponent } from './components/task-email-send/task-email-send.component';
import { TasksTopicMailboxComponent } from './components/tasks-topic-mailbox/tasks-topic-mailbox.component';


@NgModule({
  declarations: [
    TasksDashboardComponent,
    ViewDashboardTaskComponent,
    TaskGroupsListComponent,
    TaskGroupAddComponent,
    TasksListComponent,
    TaskComponent,
    TaskSendEmailComponent,
    TaskEmailSendComponent,
    TasksTopicMailboxComponent
  ],
  imports: [
    CommonModule,
    TasksRoutingModule,
    CommonsModule,
    NgbNavModule,
    FormsModule,
    MomentModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    DragDropModule,
    EditorModule,
    RouterModule,
    DynamicModule,
    NgbModule,
    NgbAccordionModule,
  ]
})
export class TasksModule {}
