import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { EnumResponse, SearchQuery, SearchQueryAware, SearchResponse, UserAccount, UserRegistrationRequest, UserRole } from '@twino/backoffice-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService extends BaseService implements SearchQueryAware<UserAccount> {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find<L>(searchQuery: SearchQuery): Observable<SearchResponse<L>> {
    return this.post(`/api/user-accounts/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/user-accounts/enum-values/${enumClass}`);
  }

  getUserById(id: number): Observable<UserAccount> {
    return this.get(`/api/user-account/${id}`);
  }

  getUserRoles(id: number): Observable<UserRole[]> {
    return this.get(`/api/user-account/${id}/roles`);
  }

  deleteUser(id: number) {
    return this.delete(`/api/user-account/${id}`);
  }

  createUser(userRegistrationRequest: UserRegistrationRequest) {
    return this.post('/api/user-account', userRegistrationRequest);
  }

  updateUserRoles(id: number, roles: string[]) {
    return this.post(`/api/user-account/${id}/roles`, roles);
  }
}
