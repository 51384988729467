import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AffiliateConfigurationReport, AffiliateConfigurationReportType, AffiliateEvent } from '@twino/backoffice-api';
import { AffiliateReportService } from '@backoffice-monorepo/commons';
import { take } from 'rxjs/operators';

@Component({
  selector: 'affiliate-configuration-report',
  templateUrl: './affiliate-configuration-report.component.html',
  styleUrls: ['./affiliate-configuration-report.component.scss']
})
export class AffiliateConfigurationReportComponent implements OnInit, OnChanges {
  @Input()
  public parentForm: FormGroup;
  @Input()
  public data: Map<AffiliateEvent, AffiliateConfigurationReport> | never;

  reportTypes = AffiliateConfigurationReportType;
  reportTypeOptions = [];
  events: string[] | never;
  selectedEvents = [];

  constructor(
    private formBuilder: FormBuilder,
    private affiliateReportService: AffiliateReportService
  ) { }

  ngOnInit(): void {
    this.affiliateReportService.listEnumValues("type").pipe(
      take(1)
    ).subscribe(enumResponse => {
      this.events = enumResponse.enumValues;
    })
    this.reportTypeOptions = Object.keys(this.reportTypes);
    this.parentForm.addControl('reports', new FormArray([]));
  }

  ngOnChanges() {
    if(this.data) {
      this.setRows();
    }
  }


  setFormGroupValues(event: string, reportData: Record<string,string>) {
    const formGroupReport = this.createForm(event);
    formGroupReport.setValue({
      event: event,
      type: reportData.type,
      url: reportData.url
    });
    return formGroupReport;
  }

  setRows() {
    const formControl = this.parentForm.get('reports') as FormArray;
    Object.entries(this.data).forEach(
      ([key, value]) => {
        this.selectedEvents.push(key);
        formControl.push(this.setFormGroupValues(key,value));
      }
    );
  }

  createForm(event: string): FormGroup {
    return this.formBuilder.group({
      event: event,
      type: ['', Validators.required],
      url: ''
    });
  }

  get getFormControls() {
    return this.parentForm.get('reports') as FormArray;
  }

  addRow(event: string) {
    const control = this.parentForm.get('reports') as FormArray;
    control.push(this.createForm(event));
    this.disableEvent(event);
  }

  deleteRow(index: number, group: AbstractControl) {
    const control = this.parentForm.get('reports') as FormArray;
    this.enableEvent(group.value.event);
    control.removeAt(index);
  }

  disableEvent(event: string) {
    this.selectedEvents.push(event);
  }

  enableEvent(event: string) {
    this.selectedEvents.forEach((value, index)=>{
      if(value === event) this.selectedEvents.splice(index, 1);
    });
  }

  isDisabledEvent(event) {
    return this.selectedEvents.includes(event);
  }
}
