<div class="container-fluid row pt-1 pb-2 pl-0">
  <div class="col-md-12 pl-0">
    <table class="table table-striped table-hover mt-4">
      <thead>
      <tr>
        <th>Scoring date</th>
        <th>Rules</th>
        <th>Score</th>
      </tr>
      </thead>
      <tbody *ngIf="scoringHistory.length === 0">
      <tr>
        <td colspan="3" class="text-center">Client doesn't have scoring history records</td>
      </tr>
      </tbody>
      <tbody *ngIf="scoringHistory.length > 0">
      <tr *ngFor="let historyRecord of scoringHistory">
        <td>{{historyRecord.scoredAt}}</td>
        <td>
          <ul style="margin-bottom: 0;">
            <li *ngFor="let rule of historyRecord.rules" style="list-style-type: none;">
              {{rule}}
            </li>
          </ul>
        </td>
        <td>{{historyRecord.score}}</td>
      </tr>
      </tbody>
    </table>
    <button *ngIf="scoringHistory.length > 0" (click)="loadMoreScoringHistory(scoringHistory)" class="btn btn-primary btn-default pull-left" type="button"><i
      class="fa fa-database"></i> Load more
    </button>
    <div *ngIf="scoringHistoryLoaded && scoringHistory.length > 0" class="pull-left ml-4 bold" style="padding: 6px 0 6px 0;">No more records</div>
  </div>
</div>
