import { Component } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { LoanRenouncementRequest } from '../../api/loan-renouncement';

@Component({
  selector: 'backoffice-monorepo-pl-pi-set-renouncement-modal',
  templateUrl: './pl-pi-set-renouncement-modal.component.html',
  styleUrls: ['./pl-pi-set-renouncement-modal.component.scss']
})
export class PlPiSetRenouncementModalComponent {
  renouncementStartDate = new FormControl('', Validators.required);

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  onOkClick() {
    const {day, month, year} = this.renouncementStartDate.value as NgbDateStruct;
    this.activeModal.close({
      renouncementStartDate: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    } as LoanRenouncementRequest);
  }

}
