import { Injectable, Type } from '@angular/core';
import { AcceptOfferButtonComponentResolver, CountryViewComponent } from '@backoffice-monorepo/commons';
import { PlPiAcceptOfferButtonComponent } from '../components/pl-pi-accept-offer-button/pl-pi-accept-offer-button.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiAcceptOfferButtonComponentResolverService implements AcceptOfferButtonComponentResolver {

  resolve(): Type<CountryViewComponent> {
    return PlPiAcceptOfferButtonComponent;
  }
}
