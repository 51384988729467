<aside class="container-fluid pt-3 pb-3">
  <div class="col-md-12 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
  </div>
</aside>
<div class="container-fluid pt-2 col-sm-4">
  <div class="card">
    <div class="card-header">
      Import discount client list
    </div>
    <div class="card-body">
      <form class="pb-2 pt-2" [formGroup]="clientListImportForm" (ngSubmit)="submitForm()">
        <div class="form-group row">
          <label for="typeId" class="col-sm-4 control-label">Campaign ID</label>
          <div class="col-sm-8">
            <select class="form-control form-control-sm" formControlName="typeId" name="typeId" id="typeId">
              <option *ngFor="let item of activeCampaigns" [ngValue]="item.id">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group custom-file">
          <input type="file"
                 class="form-control form-control-sm custom-file-input"
                 id="file"
                 formControlName="file"
                 name="file"
                 accept=".xlsx, .xls, .csv"
                 (change)="fileProgress($event)"/>
          <label class="custom-file-label" for="file">Choose file...</label>
        </div>
        <button type="button" class="btn btn-link float-left mt-3" *ngIf="fileData?.name">{{fileData?.name}}</button>
        <button type="submit" class="btn btn-primary float-right ml-2 mt-3" [disabled]="!clientListImportForm.valid">Import</button>
      </form>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header">
      Processing status
    </div>
    <div class="card-body">
      <table list-table class="table table-striped table-hover">
        <thead class="text-light bg-primary">
        <tr>
          <th>Is active</th>
          <th>Current index</th>
          <th>Total rows</th>
          <th>Error message</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{processingStatus?.isActive}}</td>
          <td>{{processingStatus?.currIndex}}</td>
          <td>{{processingStatus?.totalRows}}</td>
          <td>{{processingStatus?.errorMessage}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

