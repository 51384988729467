<form [formGroup]="clientAddressEditForm" (ngSubmit)="submitForm()" cdkDrag cdkDragRootElement=".dragged-modal">
  <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Edit client address #{{clientId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <ul ngbNav #nav="ngbNav" class="nav-tabs" id="addressTab" [(activeId)]="activeTabItem">
        <li formArrayName="addressTabs" *ngFor="let address of getFormControls.controls ; let i=index" [ngbNavItem]="i">
          <a ngbNavLink>Edit {{tabsTitles[i]}} address</a>
          <ng-template ngbNavContent [formGroupName]="i">
            <input type="hidden" id="type" formControlName="type" name="type">
            <div class="form-group row mt-4">
              <label for="addressLine" class="col-sm-4 control-label">Address</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="addressLine" formControlName="addressLine" name="addressLine">
              </div>
            </div>
            <div class="form-group row">
              <label for="street" class="col-sm-4 control-label">Street</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="street" formControlName="street" name="street">
              </div>
            </div>
            <div class="form-group row">
              <label for="houseNumber" class="col-sm-4 control-label">House number</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="houseNumber" formControlName="houseNumber" name="houseNumber">
              </div>
            </div>
            <div class="form-group row">
              <label for="apartmentNumber" class="col-sm-4 control-label">Apartment number</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="apartmentNumber" formControlName="apartmentNumber" name="apartmentNumber">
              </div>
            </div>
            <div class="form-group row">
              <label for="dependentLocality" class="col-sm-4 control-label">Dependent locality</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm"
                       id="dependentLocality"
                       formControlName="dependentLocality"
                       name="dependentLocality">
              </div>
            </div>
            <div class="form-group row">
              <label for="locality" class="col-sm-4 control-label">Locality</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="locality" formControlName="locality" name="locality">
              </div>
            </div>
            <div class="form-group row">
              <label for="administrativeArea" class="col-sm-4 control-label">Administrative area</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm"
                       id="administrativeArea"
                       formControlName="administrativeArea"
                       name="administrativeArea">
              </div>
            </div>
            <div class="form-group row">
              <label for="postalCountry" class="col-sm-4 control-label">Country<sup class="text-danger">*</sup></label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="postalCountry" formControlName="postalCountry" name="postalCountry">
              </div>
            </div>
            <div class="form-group row">
              <label for="postalCode" class="col-sm-4 control-label">Postal code</label>
              <div class="col-sm-8">
                <input type="text" class="form-control form-control-sm" id="postalCode" formControlName="postalCode" name="postalCode">
              </div>
            </div>
          </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!clientAddressEditForm.valid">Save</button>
  </div>
</form>
