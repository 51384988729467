import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ErrorsService } from '../../services/errors.service';
import { DOCUMENT } from '@angular/common';
// noinspection ES6PreferShortImport
import { AlertsService } from '../../services/alerts.service';
import { GlobalErrors } from '@backoffice-monorepo/api';
import { LoginPollService } from '../../services/login-poll.service';
// noinspection ES6PreferShortImport
import { OverlayService } from '../../services/overlay.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogueComponent } from '../dialogues/error-dialogue/error-dialogue.component';
import { ToastService } from '../../services/toast.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'backoffice-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit, OnDestroy {

  overlayEnabled$: Observable<boolean>;
  globalErrors = new GlobalErrors();
  subscriptions: Subscription[] = [];

  constructor(
    private errorsService: ErrorsService,
    private alertsService: AlertsService,
    private loginPollService: LoginPollService,
    private overlayService: OverlayService,
    private modalService: NgbModal,
    private toastService: ToastService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit(): void {
    this.overlayEnabled$ = this.overlayService.overlayAnnouncement.pipe(
      delay(0)
    );
    this.loginPollService.startPolling();
    this.errorsService.errorDialogueAnnouncement.subscribe((errorContent) => this.showErrorDialogue(errorContent));
  }

  reloadPage() {
    this.document.defaultView.location.reload();
  }

  ngOnDestroy(): void {
    this.loginPollService.stopPolling();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private showErrorDialogue(errorContent: HttpErrorResponse | string) {
    const modalRef = this.modalService.open(ErrorDialogueComponent, {scrollable: true});
    modalRef.componentInstance.errorContent = errorContent;
  }
}
