import { Component, Input, OnInit } from '@angular/core';
import { FilterOperation, FullLoan, Payment, SearchQueryBuilder, SearchResponse } from '@twino/backoffice-api';
import { LoanSidebarLibComponent, PaymentService } from '@backoffice-monorepo/commons';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-loan-payments',
  templateUrl: './loan-payments.component.html',
  styleUrls: ['./loan-payments.component.scss']
})
export class LoanPaymentsComponent implements LoanSidebarLibComponent, OnInit {
  @Input()
  loan: FullLoan;
  payments: Array<Payment> | null;
  searchResults: SearchResponse<Payment> | never;
  newPage = 1;
  itemsPerPage = 20;

  constructor(
    private paymentService: PaymentService,
    protected router: Router
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .addFilterData({
        propertyName: 'loanId',
        operation: FilterOperation.EQUALS,
        values: [this.loan.id.toString()]
      })
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()

    return this.paymentService.find(searchQuery).pipe(
      take(1)
    ).subscribe((payments) => {
      this.searchResults = payments;
    })
  }


  trackById(index, item){
    return item.id;
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }
}
