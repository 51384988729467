import { Component, Host, OnInit } from '@angular/core';
import { ApplicationService, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import { Application, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'applications-list',
  templateUrl: './applications-list.component.html',
  styleUrls: ['./applications-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Application>) => new FilteringService(listService),
      deps: [ApplicationService]
    }
  ]
})
export class ApplicationsListComponent extends ListComponent<Application> implements OnInit {

  exportModuleName = "loan-applications";

  constructor(
    @Host() filteringService: FilteringService<Application>,
    protected router: Router,
    applicationService: ApplicationService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getName(): string {
    return 'Loan Applications';
  }

  navigateTo(id: number | string) {
    this.router.navigate([`/applications/${id}`]);
  }

}
