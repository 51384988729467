import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  ClientLoginAttemptResponse,
  EnumResponse,
  LoanApplicationIpAddressDataResponse,
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientActivitiesService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/client/enum-values/${enumClass}`);
  }

  getClientApplicationsIpAddresses(id: number): Observable<LoanApplicationIpAddressDataResponse[]> {
    return this.get(`/api/client/${id}/applications/ip-addresses`);
  }

  getClientLoginAttempts(id: number): Observable<ClientLoginAttemptResponse[]> {
    return this.get(`/api/client/${id}/login-attempts`);
  }
}
