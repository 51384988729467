import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';
import {
  AddRiskEvaluationFlowRequest,
  RiskEvaluationFlowResponse,
  RiskEvaluationStepConfigResponse,
  UpdateRiskEvaluationFlowRequest,
} from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class RiskEvaluationFlowService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  listRiskEvaluationFlow(): Observable<RiskEvaluationFlowResponse[]> {
    return this.get(`/api/risks/evaluation-flows`);
  }

  getFlow(id: number): Observable<RiskEvaluationFlowResponse> {
    return this.get(`/api/risks/evaluation-flows/${id}`);
  }

  getFlowSteps(id: number): Observable<RiskEvaluationStepConfigResponse[]> {
    return this.get(`/api/risks/evaluation-flows/${id}/steps`);
  }

  getFlowReferences(): Observable<string[]> {
    return this.get(`/api/risks/evaluation-flows/references`);
  }

  addEvaluationFlow(addRiskEvaluationFlowRequest: AddRiskEvaluationFlowRequest){
    return this.post(`/api/risks/evaluation-flows`, addRiskEvaluationFlowRequest);
  }

  updateEvaluationFlow(updateRiskEvaluationFlowRequest: UpdateRiskEvaluationFlowRequest){
    return this.patch(`/api/risks/evaluation-flows`, updateRiskEvaluationFlowRequest);
  }

  deleteEvaluationFlow(id: number): Observable<never> {
    return this.delete(`/api/risks/evaluation-flows/${id}`, {});
  }
}
