import { Component, Inject, Input, OnDestroy, OnInit, Type } from '@angular/core';
import { ClientDetails, EmploymentInfo, FullClient, IncomeInfo } from '@twino/backoffice-api';
import {
  BasicComponent,
  ClientDetailsTableComponentResolver,
  ClientService,
  ClientSidebarLibComponent,
  CountryViewComponent,
  draggableModalOptions,
  NUMBER,
  STRING,
  TransformInstructions,
  TransformInstructionsBuilder
} from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { forkJoin, Subscription } from 'rxjs';
import { ClientIncomeEditComponent } from '../client-income-edit/client-income-edit.component';
import { ClientEmploymentTableComponentResolver } from '../../../../../../../commons/src/lib/services/component-resolvers/ClientEmploymentTableComponentResolver';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent extends BasicComponent implements ClientSidebarLibComponent, OnInit, OnDestroy {

  private reloadClientDetailsModel: Subscription;

  @Input()
  client: FullClient;
  details: ClientDetails | null;
  incomeInfo: IncomeInfo | null;
  employmentInfo: EmploymentInfo | null;
  clientDetailsTableComponentType: Type<CountryViewComponent>;
  clientEmploymentTableComponentType: Type<CountryViewComponent>;


  incomeInfoTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['clientId', ['Client Id', NUMBER]],
      ['monthlyIncome', ['Monthly Income', STRING]],
      ['monthlyLiabilities', ['Monthly Liabilities', STRING]]
    ]
  );

  constructor(
    private clientService: ClientService,
    private modalService: NgbModal,
    private clientsRefreshService: ClientsRefreshService,
    @Inject('ClientDetailsTableComponentResolver') clientDetailsTableComponentResolver: ClientDetailsTableComponentResolver,
    @Inject('ClientEmploymentTableComponentResolver') clientEmploymentTableComponentResolver: ClientEmploymentTableComponentResolver
  ) {
    super();
    this.clientDetailsTableComponentType = clientDetailsTableComponentResolver.resolve();
    this.clientEmploymentTableComponentType = clientEmploymentTableComponentResolver.resolve();
  }

  ngOnInit(): void {
    this.refresh();
    this.reloadClientDetailsModel = this.clientsRefreshService.reloadClientDetailsModel$.subscribe(() => {
      this.refresh();
    })
  }

  refresh() {
    forkJoin([
      this.clientService.getClientDetails(this.client.savedId),
      this.clientService.getClientEmploymentInfo(this.client.savedId),
      this.clientService.getClientIncomeInfo(this.client.savedId)
    ])
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(([clientDetails, employmentInfo, incomeInfo]) => {
        this.details = clientDetails;
        this.employmentInfo = employmentInfo;
        this.incomeInfo = incomeInfo;
      });
  }

  ngOnDestroy(): void {
    this.reloadClientDetailsModel.unsubscribe();
  }

  editClientIncome() {
    const modalRef = this.modalService.open(ClientIncomeEditComponent, draggableModalOptions);
    modalRef.componentInstance.clientId = this.client.savedId;
  }
}
