import { Component, Input, OnInit } from '@angular/core';
import { AlertsService, BasicComponent, DateUtils, LoanProlongationResignationsService } from '@backoffice-monorepo/commons';
import { CountrySpecific } from '../../../../../../libs/commons/src/lib/services/country-specific';
import { CancelProlongationResignationRequest, LoanProlongationResignationOriginator } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-prolongation-resignations-cancel-modal',
  templateUrl: './pl-pi-loan-prolongation-resignations-cancel-modal.component.html',
  styleUrls: ['./pl-pi-loan-prolongation-resignations-cancel-modal.component.css']
})
export class PlPiLoanProlongationResignationsCancelModalComponent extends BasicComponent implements OnInit, CountrySpecific {

  @Input()
  resignationId: number;
  @Input()
  originator: LoanProlongationResignationOriginator;
  @Input()
  loanId: number;
  loanProlongationResignationForm: FormGroup;

  constructor(
    private loanProlongationResignationsService: LoanProlongationResignationsService,
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.loanProlongationResignationForm = this.formBuilder.group({
      cancellationCause: ['', Validators.required]
    });
  }

  submitForm() {
    const cancelProlongationResignationRequest: CancelProlongationResignationRequest = {
      resignationId: this.resignationId,
      originator: this.originator,
      cancellationCause: this.loanProlongationResignationForm.value.cancellationCause,
      onTime: DateUtils.dateTimeNowAsDate()
    }
    this.loanProlongationResignationsService.cancelProlongationResignation(cancelProlongationResignationRequest)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.activeModal.close();
      this.alertService.notifySuccess(`Loan prolongation resignation cancelled`);
    })
  }
}
