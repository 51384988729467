<form [formGroup]="debtConfigForm" (ngSubmit)="submitForm()">
  <div class="container-fluid pt-2 col-sm-8">
    <div class="card">
      <div class="card-header">
         {{debtStepName}}
         <span class="float-right">
          <button type="button"
                  appRequirePermission="admin.all, debt-step-configuration.edit"
                  class="btn btn-success mr-1"
                  (click)="addRow()">
            Add rule
          </button>
          <button type="submit"
                  appRequirePermission="admin.all, debt-step-configuration.edit"
                  class="btn btn-outline-danger"
                  [disabled]="!debtConfigForm.valid">
            Save
          </button>
        </span>
      </div>
      <div class="card-body">

        <div class="form-group row">
          <label for="debtStepName" class="col-sm-4 control-label">Debt Step Name<sup class="text-danger">*</sup></label>
          <div class="col-sm-8">
            <input type="text"
                   class="form-control form-control-sm"
                   id="debtStepName"
                   formControlName="debtStepName"
                   name="debtStepName">
          </div>
        </div>
        <div class="form-group row">
          <label for="dpd" class="col-sm-4 control-label">DPD</label>
          <div class="col-sm-8">
            <input type="number"
                   class="form-control form-control-sm"
                   id="dpd"
                   formControlName="dpd"
                   name="dpd">
          </div>
        </div>
        <div class="form-group row">
          <label for="dli" class="col-sm-4 control-label">DLI</label>
          <div class="col-sm-8">
            <input type="number"
                   class="form-control form-control-sm"
                   id="dli"
                   formControlName="dli"
                   name="dli">
          </div>
        </div>
        <div class="form-group row">
          <label for="odpd" class="col-sm-4 control-label">ODPD</label>
          <div class="col-sm-8">
            <input type="number"
                   class="form-control form-control-sm"
                   id="odpd"
                   formControlName="odpd"
                   name="odpd">
          </div>
        </div>
        <div class="form-group row">
          <label for="promiseDay" class="col-sm-4 control-label">Promise Day</label>
          <div class="col-sm-8">
            <input type="number"
                   class="form-control form-control-sm"
                   id="promiseDay"
                   formControlName="promiseDay"
                   name="promiseDay">
          </div>
        </div>
        <div class="form-group row">
          <label for="dcVersion" class="col-sm-4 control-label">DC version<sup class="text-danger">*</sup></label>
          <div class="col-sm-8">
            <select class="form-control form-control-sm"
                    id="dcVersion"
                    formControlName="dcVersion"
                    name="dcVersion">
              <option *ngFor="let n of dcVersionCounter(10); let i = index; trackBy: trackByIndex" [ngValue]="i+1">
                {{ i + 1 }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row control-label">
          <label class="form-check-label col-sm-4" for="isEnabled">
            Enabled
          </label>
          <div class="form-check col-sm-8">
            <input class="form-check-input ml-1"
                   type="checkbox"
                   id="isEnabled"
                   formControlName="isEnabled"
                   name="isEnabled">
          </div>
        </div>

      </div>
    </div>

    <div class="card mt-1">
      <div class="card-body" formArrayName="cardRows" cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="card mt-2" *ngFor="let group of getFormControls.controls; trackBy: trackById; let i=index" [formGroupName]="i" cdkDrag>
          <div class="card-header">
            <div [ngSwitch]="group.value.isEditableInput === true">
              <div *ngSwitchCase="false" cdkDragHandle>
                <button type="button" class="btn btn-link" (click)="edit(group, 'isEditableInput')">{{group.value.ruleName}}</button>
                <button class="btn btn-outline-danger btn-sm float-right" (click)="deleteRow(i)">Delete</button>
              </div>
              <div *ngSwitchCase="true" class="row" cdkDragHandle [cdkDragHandleDisabled]="true">
                <input type="text" class="form-control form-control-sm col-sm-6 ml-3" id="ruleName" formControlName="ruleName" name="ruleName">
                <button class="btn btn-outline-primary btn-sm float-left ml-2" (click)="close(group, 'isEditableInput')">Close</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="container-fluid row">
              <div class="col-md-4">
                <div class="form-group row">
                  <select class="form-control form-control-sm" id="debtRuleClassName" formControlName="debtRuleClassName" name="debtRuleClassName">
                    <option *ngFor="let key of ruleTypes" [ngValue]="key">{{key}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-7 ml-4">
                <json-editor [options]="makeOptions()" formControlName="params"></json-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

