import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class User {
  userName: string
  permissions: string[]

  constructor(userName?: string, permissions?: string[]) {
    if (userName) this.userName = userName;
    if (permissions) this.permissions = permissions;
  }
}
