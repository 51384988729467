import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageTemplatesRefreshService implements OnDestroy{

  public reloadMessageTemplatesListModel$ = new Subject<void>();

  reloadMessageTemplatesListModel() {
    this.reloadMessageTemplatesListModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadMessageTemplatesListModel$.unsubscribe();
  }
}
