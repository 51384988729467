 <div class="modal-header" cdkDragHandle>
    <h4 class="modal-title">Compare Data</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
 </div>
 <div class="modal-body">
   <p>
     Execution date time: <strong>{{compareData?.endExecutionTime}}</strong>;
     Execution duration: <strong>{{compareData?.executionDuration}}</strong>;
     CompareId: <strong>{{compareData?.compareId}}</strong>
   </p>
   <table class="table table-striped table-hover mt-2">
     <thead class="text-light bg-primary">
       <tr>
         <th>Categories</th>
         <th>Product name</th>
         <th>Client ID</th>
         <th *ngFor="let item of compareData?.results?.attributeHeaders;">{{item.name}}</th>
       </tr>
       <tr>
         <td></td>
         <td>{{compareData?.results.requestProfile.productName}}</td>
         <td>{{compareData?.results.requestProfile.clientId}}</td>
         <td *ngFor="let item of compareData?.results?.requestProfile?.attributes; let i = index">{{item | plPiCompare : i}}</td>
       </tr>
     </thead>
     <tbody>
        <tr *ngFor="let comparedProfile of compareData?.results?.comparedProfiles;">
          <td>
            {{comparedProfile.categories.toString()}}
          </td>
          <td>{{comparedProfile.productName}}</td>
          <td>
            {{comparedProfile.clientId}}
          </td>
          <td *ngFor="let item of comparedProfile.attributes; let i = index"
              [ngClass]="{ 'bg-warning' : comparedProfile.warningAttributes.includes(i)}">
              {{item | plPiCompare : i}}
          </td>
        </tr>
     </tbody>
    </table>
  </div>
  <div class="modal-footer"></div>


