import { Injectable, Type } from '@angular/core';
import { CountryViewComponent, TaskRelatedEntitiesComponentResolver } from '@backoffice-monorepo/commons';
import { PlPiTaskRelatedEntitiesComponent } from '../components/pl-pi-task-related-entities/pl-pi-task-related-entities.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiTaskRelatedEntitiesComponentResolverService implements TaskRelatedEntitiesComponentResolver {
  resolve(): Type<CountryViewComponent> {
    return PlPiTaskRelatedEntitiesComponent;
  }
}
