import { LoanAgreementType } from './agreement';

export interface LoanSaleData {
  id: number
  loanId: number
  preSaleDate?: number
  saleDate?: number
  totalAmount: number
  saleAmount?: number
  dpd?: number
  portfolioId?: number
  agreementType: LoanAgreementType
}

export interface LoanSalePortfolio {
  id: number
  status: LoanSalePortfolioStatus
  loanSalePartnerId?: number
  presaleFileExternalId?: number
  saleFileExternalId?: string
  totalAmount: number
  saleAmount?: number
  preSaleDate?: Date
  saleDate?: Date
  loansCount: number
}

export enum LoanSalePortfolioStatus {
  PRE_SOLD = 'PRE_SOLD',
  SOLD = 'SOLD'
}

export interface LoanSalePortfolioWithPartnerResponse {
  id: number
  status: LoanSalePortfolioStatus
  loanSalePartnerId?: number
  loanSalePartnerName?: number
  presaleFileExternalId?: number
  saleFileExternalId?: string
  totalAmount: number
  saleAmount?: number
  preSaleDate?: Date
  saleDate?: Date
  loansCount?: number
}

export interface SellLoanPortfolioRequest {
  portfolioId: number
  saleAmount: number
  onDate: Date
  partnerId: number
}

export interface RevertSellingPortfolioRequest {
  portfolioId: number
}

export interface LoanSaleFileSignedUrlResponse {
  archivePassword: string;
  saleFileSignedUrl: string;
}

export interface LoanSaleSearchQuery {
  page?: number,
  pageSize?: number,
  loanId?: number | null,
  agreementType?: LoanAgreementType | null,
  brand?: string | null
}
