import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransfersRoutingModule } from './transfers-routing.module';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbDatepickerModule, NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TransfersListComponent } from './components/transfers-list/transfers-list.component';
import { TransferRedirectComponent } from './components/transfer-redirect/transfer-redirect.component';
import { TransferPayloadViewComponent } from './components/transfer-payload-view/transfer-payload-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DynamicModule } from 'ng-dynamic-component';


@NgModule({
  declarations: [TransfersListComponent, TransferRedirectComponent, TransferPayloadViewComponent],
	imports: [
		CommonModule,
		TransfersRoutingModule,
		CommonsModule,
		NgbNavModule,
		TranslateModule,
		ReactiveFormsModule,
		NgbDatepickerModule,
		NgbPopoverModule,
		DragDropModule,
		DynamicModule
	]
})
export class TransfersModule { }
