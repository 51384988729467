import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TasksDashboardComponent } from './components/tasks-dashboard/tasks-dashboard.component';
import { TaskGroupsListComponent } from './components/task-groups-list/task-groups-list.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { TaskComponent } from './components/task/task.component';
import { TaskSendEmailComponent } from './components/task-send-email/task-send-email.component';

const routes: Routes = [
  {path: '', component: TasksListComponent, pathMatch: 'full'},
  {path: 'dashboard', component: TasksDashboardComponent},
  {path: 'groups', component: TaskGroupsListComponent, pathMatch: 'full'},
  {path: ':id', component: TaskComponent},
  {path: ':id/email/:communicationId', component: TaskSendEmailComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule {}
