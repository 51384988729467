import { Component } from '@angular/core';
import { ClientSidebarComponent } from '@backoffice-monorepo/commons';
import { FullClient } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-client-aml-dashboard',
  templateUrl: './client-aml-dashboard.component.html',
  styleUrls: ['./client-aml-dashboard.component.css']
})
export class ClientAmlDashboardComponent implements ClientSidebarComponent {

  client: FullClient;
  activeTabId = 1;

  constructor() { }
}
