export enum FilterOperation {
  CONTAINS = "contains",
  NOT_CONTAINS = 'not contains',
  DEFINED = 'is defined',
  UNDEFINED = 'not defined',
  EQUALS = "=",
  NOT_EQUALS = "<>",
  EMPTY = 'empty',
  MORE = ">",
  LESS = "<",
  MORE_OR_EQUALS = ">=",
  LESS_OR_EQUALS = "<=",
  RANGE_INCLUDING = 'range including',
  RANGE = 'range',
  STARTS_WITH = 'starts with',
  BETWEEN = 'between'
}

export enum DateFilterDefaultValues {
  CURRENT_MONTH_START_DATE = 'currentMonthStartDate',
  PREVIOUS_MONTH_START_DATE = 'previousMonthStartDate',
  DATE_30DAYS_BEFORE_TODAY = 'date30DaysBeforeToday'
}

export type FilterType =
  'id'
  | 'uuid'
  | 'string'
  | 'text'
  | 'string-with-digits'
  | 'number'
  | 'decimal'
  | 'custom'
  | 'date'
  | 'instant'
  | 'datetime'
  | 'list'
  | 'boolean'
  | 'enum';

export type FilterViewElementType =
  'string'
  | 'date'
  | 'instant'
  | 'datetime'
  | 'uuid'
  | 'enum'
  | 'boolean';


export const BASIC_FILTER_OPERATIONS = [
  FilterOperation.EQUALS,
  FilterOperation.NOT_EQUALS,
  FilterOperation.DEFINED,
  FilterOperation.UNDEFINED
];

export const NUMBER_OF_FIELDS_FOR_OPERATION: Map<FilterOperation, number> = new Map([
  [FilterOperation.CONTAINS, 1],
  [FilterOperation.NOT_CONTAINS, 1],
  [FilterOperation.DEFINED, 0],
  [FilterOperation.UNDEFINED, 0],
  [FilterOperation.EMPTY, 0],
  [FilterOperation.EQUALS, 1],
  [FilterOperation.NOT_EQUALS, 1],
  [FilterOperation.MORE, 1],
  [FilterOperation.LESS, 1],
  [FilterOperation.MORE_OR_EQUALS, 1],
  [FilterOperation.LESS_OR_EQUALS, 1],
  [FilterOperation.RANGE_INCLUDING, 2],
  [FilterOperation.RANGE, 2],
  [FilterOperation.BETWEEN, 2],
  [FilterOperation.STARTS_WITH, 1],
  [undefined, 1],
  [null, 1]
]);

export function getDummyValueForType(type: FilterViewElementType): string {
  switch (type) {
    case 'date':
      return "2020-01-01";
    case 'instant':
      return "2020-01-01 00:00";
    default:
      return "";
  }
}

export function toFilterViewElementType(type: FilterType): FilterViewElementType {
  switch (type) {
    case 'string':
    case 'string-with-digits':
      return 'string';
    case 'date':
      return 'date';
    case 'instant':
      return 'instant';
    case 'datetime':
      return 'datetime';
    case 'uuid':
      return 'uuid';
    case 'enum':
      return 'enum';
    case 'boolean':
      return 'boolean';
    default:
      return null;
  }
}

export function provideFilterOperations(type: FilterType, customElementOperations: FilterOperation[]): Array<FilterOperation> {
  let additionalOperations = [];
  switch (type) {
    case 'date':
    case 'instant':
      additionalOperations = [
        FilterOperation.MORE,
        FilterOperation.MORE_OR_EQUALS,
        FilterOperation.LESS,
        FilterOperation.LESS_OR_EQUALS,
        FilterOperation.BETWEEN
      ]
      break;
    case 'list':
      break;
    case 'string':
    case 'uuid':
    case 'string-with-digits':
      additionalOperations = [
        FilterOperation.CONTAINS,
        FilterOperation.NOT_CONTAINS,
        FilterOperation.EMPTY,
        FilterOperation.STARTS_WITH,
        FilterOperation.MORE,
        FilterOperation.MORE_OR_EQUALS,
        FilterOperation.LESS,
        FilterOperation.LESS_OR_EQUALS
      ]
      break;
    case 'number':
      additionalOperations = [
        FilterOperation.MORE,
        FilterOperation.MORE_OR_EQUALS,
        FilterOperation.LESS,
        FilterOperation.LESS_OR_EQUALS,
        FilterOperation.BETWEEN
      ]
      break;
    case 'decimal':
      additionalOperations = [
        FilterOperation.MORE,
        FilterOperation.MORE_OR_EQUALS,
        FilterOperation.LESS,
        FilterOperation.LESS_OR_EQUALS,
        FilterOperation.BETWEEN,
      ]
      break;
    case 'enum':
      break;
    case 'boolean':
      break;
    default:
      break;
  }
  return [...new Set<FilterOperation>(BASIC_FILTER_OPERATIONS.concat(additionalOperations).concat(customElementOperations))];
}

