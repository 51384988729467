<form [formGroup]="riskEvaluationFlowStepForm" (ngSubmit)="submitForm()" *ngIf="!isEdit">
  <div class="modal-header">
    <h4 class="modal-title">{{title}} flow step</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="name" class="col-sm-4 control-label">Step name<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="name" formControlName="name" name="name">
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-4 control-label">Step description<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="description" formControlName="description" name="description">
      </div>
    </div>
    <div class="form-group row">
      <label for="evaluationSequence" class="col-sm-4 control-label">Evaluation order<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="number"
               class="form-control form-control-sm"
               id="evaluationSequence"
               formControlName="evaluationSequence"
               name="evaluationSequence"
              [max]="20">
      </div>
    </div>
    <div class="form-group row control-label">
      <label class="form-check-label col-sm-4" for="continueEvaluation">
        Continue evaluation
      </label>
      <div class="form-check col-sm-8">
        <input class="form-check-input ml-1"
               type="checkbox"
               id="continueEvaluation"
               formControlName="continueEvaluation"
               name="continueEvaluation">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!riskEvaluationFlowStepForm.valid">Save</button>
  </div>
</form>

<form [formGroup]="riskEvaluationFlowStepForm" (ngSubmit)="submitForm()" *ngIf="isEdit">
  <div class="modal-header">
    <h4 class="modal-title">{{title}} flow step</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="newName" class="col-sm-4 control-label">Step name<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="newName" formControlName="newName" name="newName">
      </div>
    </div>
    <div class="form-group row">
      <label for="newDescription" class="col-sm-4 control-label">Step description<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="newDescription" formControlName="newDescription" name="newDescription">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!riskEvaluationFlowStepForm.valid">Save</button>
  </div>
</form>

