import { Component, Input, OnInit } from '@angular/core';
import { AlertsService, BasicComponent, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { PlPiDebtCollectionStagesService } from '../../services/pl-pi-debt-collection-stages.service';
import { BuyBackReasons } from '../../api/debt-stage';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-dc-buy-back',
  templateUrl: './dc-buy-back.component.html',
  styleUrls: ['./dc-buy-back.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class DcBuyBackComponent extends BasicComponent implements OnInit {

  @Input() loanId: number;
  buyBackForm: FormGroup;
  buyBackReasons = BuyBackReasons;
  buyBackReasonsOptions = [];


  constructor(
    public activeModal: NgbActiveModal,
    private debtCollectionStagesService: PlPiDebtCollectionStagesService,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.buyBackReasonsOptions = Object.keys(this.buyBackReasons);
    this.buyBackForm = this.formBuilder.group({
      reason: ['', Validators.required],
      onDate: ['', Validators.required],
    });
  }

  submitForm() {
    this.debtCollectionStagesService.buyBack(this.loanId, this.buyBackForm.value)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Buy-back request sent`);
      this.activeModal.close(true);
    })
  }

}

