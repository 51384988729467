import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigRefreshService implements OnDestroy{

  public reloadProductConfigsListModel$ = new Subject<void>();


  reloadProductConfigsListModel() {
    this.reloadProductConfigsListModel$.next();
  }

  ngOnDestroy(): void {
    this.reloadProductConfigsListModel$.unsubscribe();
  }
}
