<div class="modal-header alert-danger">
  <h4 class="modal-title">
    <bi name="exclamation-octagon-fill"></bi>&nbsp;
    Error!
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="isHttpErrorResponse()">
    <p><b>Status:&nbsp;</b>{{errorContent.status}}</p>
    <p><b>StatusText:&nbsp;</b>{{errorContent.statusText}}</p>
    <p><b>Message:&nbsp;</b><span class="text-danger">{{errorContent.message}}</span></p>
    <p><b>HTML:&nbsp;</b></p>
    <p>
      <button type="button" autofocus class="btn btn-outline-primary" (click)="toggleErrorContent($event)" aria-controls="collapseExample">
        Show error response
      </button>
    </p>
    <div id="errorDialogueCollapsed" [ngbCollapse]="isCollapsed">
      <div class="card">
        <div class="card-body">
          {{(errorContent?.html || errorContent?.error) | json}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isNotHttpErrorResponse()">
    <p>{{errorContent}}</p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">Close</button>
</div>
