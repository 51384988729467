import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  AffiliateReport,
  EnumResponse,
  SearchCsvExportRequest,
  SearchQuery,
  SearchQueryAware,
  SearchResponse
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AffiliateReportService extends BaseService implements SearchQueryAware<AffiliateReport> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<AffiliateReport>> {
    return this.post(`/api/affiliate-reports/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/affiliate-reports/enum-values/${enumClass}`);
  }

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/affiliate-reports/export-csv`, exportRequest, {'responseType': 'blob'});
  }
}
