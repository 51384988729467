import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'modal-error-dialogue',
  templateUrl: './error-dialogue.component.html',
  styleUrls: ['./error-dialogue.component.scss']
})
export class ErrorDialogueComponent {

  @Input() errorContent: HttpErrorResponse | string;
  isCollapsed = true;

  constructor(public activeModal: NgbActiveModal) {
  }

  isHttpErrorResponse() {
    return this.errorContent instanceof HttpErrorResponse;
  }

  isNotHttpErrorResponse() {
    return !this.isHttpErrorResponse();
  }

  toggleErrorContent($event: MouseEvent) {
    $event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }
}
