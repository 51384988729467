import { Type } from '@angular/core';
import { CountrySpecific } from './country-specific';

export class LoanSpecificComponentRecord {
  index: number
  title: string
  type: Type<CountrySpecific>
}

export interface LoanSidebarComponentResolver extends CountrySpecific {

  resolve(): LoanSpecificComponentRecord[]
}
