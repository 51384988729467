import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlPiClientCardService } from '../../services/pl-pi-client-card.service';
import { CardHistoryEntryResponse } from '../../api/client.card';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-card-history',
  templateUrl: './pl-pi-client-card-history.component.html',
  styleUrls: ['./pl-pi-client-card-history.component.scss']
})
export class PlPiClientCardHistoryComponent implements OnInit {

  @Input() clientId: number;
  history: CardHistoryEntryResponse[]

  constructor(
    public activeModal: NgbActiveModal,
    private plPiClientCardService: PlPiClientCardService,
  ) { }

  ngOnInit(): void {
    this.plPiClientCardService.getCardsHistory(this.clientId).pipe(
      take(1)
    ).subscribe(history =>{
      this.history = history;
    })
  }
}
