import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, LoanService, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoansRefreshService } from '../../services/loans-refresh-services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-loan-write-off',
  templateUrl: './loan-write-off.component.html',
  styleUrls: ['./loan-write-off.component.css'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class LoanWriteOffComponent extends BasicComponent implements OnInit {
  @Input() loanId: number;

  loanWriteOffForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private loanRefreshService: LoansRefreshService,
    private loanService: LoanService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loanWriteOffForm = this.formBuilder.group({
      principalAmount: 0,
      interestAmount: 0,
      loanFeeAmount: 0,
      penaltyAmount: 0,
      loanCommissionAmount: 0,
      reason: '',
      bookingDate: ['', Validators.required],
    });
  }

  submitForm() {
    this.loanService.writeOff(this.loanId, this.loanWriteOffForm.value).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Write Off done loan ${this.loanId}`);
      this.loanRefreshService.reloadLoanModel();
      this.activeModal.close();
    })
  }
}
