<div class="modal-header">
  <h4 class="modal-title">
    <bi name="question-circle"></bi>&nbsp;
    Content
  </h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close()">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{content}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close()">Ok</button>
</div>
